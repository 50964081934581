export enum Key {
	Backspace = 8,
	Tab = 9,
	Enter = 13,
	Shift = 16,
	Escape = 27,
	ArrowLeft = 37,
	ArrowRight = 39,
	ArrowUp = 38,
	ArrowDown = 40
}
