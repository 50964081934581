import { Component, OnInit } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { UserType } from "@classes/user";
import { MenuBuilder } from "@services/navmenu.service";

@Component({
	"selector": "admin-dashboard",
	"styleUrls": ["./admindashboard.component.scss"],
	"templateUrl": "./admindashboard.component.html"
})
export class AdminDashboardComponent extends PrivateComponent implements OnInit {

	constructor() {
		super();
		this.allowedUserTypes = [UserType.Admin];
	}

	get userId(): string {
		return this.user ? this.user.id : undefined;
	}

	ngOnInit() {
		super.ngOnInit()

		const menuBuilder = new MenuBuilder();
		// menuBuilder.addHome();
		menuBuilder.addRoute("cog", "Account Details", "/account");
		menuBuilder.addRoute("lock", "Change Password", "/password/change");
		menuBuilder.addRoute("plus-circle", "Create User", "/createuser", undefined, () => this.hasPermission('Users', 'Create Account') );
		menuBuilder.addRoute("list-ul", "List Accounts", "/listaccounts", undefined, () => this.hasPermission('Users', 'List Accounts') );
		menuBuilder.addRoute("plus", "New Bill", "/billing/new", undefined, () => this.hasPermission('Billing', 'Write own content') );

		menuBuilder.done();
	}
}
