import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { User, UserType } from "@classes/user";
import { Plan, PlanStatus } from "@classes/plans";
import { OverlayService } from "@services/overlay.service";
import { AdminService } from "@services/admin.service";
import { ErrorUtils } from "@classes/errors";
import moment from "moment";

@Component({
	"selector": "account-credentials",
	"styleUrls": ["./accountcredentials.component.scss"],
	"templateUrl": "./accountcredentials.component.html"
})
export class AccountCredentialsComponent extends PrivateComponent implements OnInit {

	private _account: User;
	private _plans: Plan[] = [];

	readonly userType = {
		"admin": UserType.Admin,
		"supportCoordinator": UserType.SupportCoordinator,
		"participant": UserType.Participant,
		"user": UserType.User
	};

	@Input()
	set account(value: User) {
		this._account = value;
	}

	@Input()
	set plans(value: Plan[]) {
		this._plans = value;
	}

	@Output()
	onUpdate = new EventEmitter<void>();

	get accountType(): UserType {
		// Trap for young players here... UserType.Admin === 0,
		// therefore this.currentUser.accountType || undefined will return undefined for administrators
		return this._account ? this._account.accountType : undefined;
	}

	get hasCognitoAccount(): boolean {
		return this._account ? !!this._account.identityId : false;
	}

	get uniqueEmail(): boolean {
		return this._account ? this._account.uniqueEmail : false;
	}

	get autocreateAccount(): boolean {
		return this._account ? this._account.autocreateAccount : false;
	}

	get accountError(): boolean {
		return this._account ? this._account.accountError : false;
	}

	get userLoaded(): boolean {
		return !!this._account;
	}

	get lastAccessTime(): string {
		if (!this._account || !this._account.lastAccess) {
			return "Unknown";
		}

		const access = moment(this._account.lastAccess);
		const now = moment();

		return moment.duration(access.diff(now)).humanize(true);
	}

	get canDeleteCredentials(): boolean {
		if (this.accountType === UserType.Admin) {
			return this.hasPermission('Users', 'Delete Admin Credentials');
		}
		return this.hasPermission('Users', 'Delete Credentials');
	}

	constructor(private adminService: AdminService) {
		super();
	}

	ngOnInit() {
		super.ngOnInit();
	}

	get hasCurrentPlan(): boolean {
		return this._plans.some( plan => plan.status === PlanStatus.current )
	}

	private deleteCredentials() {
		OverlayService.show();
		this.adminService.deleteCredentials(this._account.id).then( success => {

			if (!success) {
				return Promise.reject({"errorMessage": "Unable to delete login credentials"});
			}

			return Promise.resolve();

		}).then( () => {

			return this.userService.loadUser(this._account.id, true);

		}).then( account => {

			this._account = account;
			this.onUpdate.next();
			OverlayService.hide();

		}).catch( err => {

			OverlayService.hide();
			OverlayService.showError("Unable to delete credentials", ErrorUtils.getErrorMessage(err, "Please contact technical support for assistance"));

		});
	}

	private createEmailAccount() {
		OverlayService.show();

		this.userService.enableCognito(this._account.id).then( success => {

			if (!success) {
				return Promise.reject({"errorMessage": "Unable to generate login credentials"});
			}
		}).then( () => {

			return this.userService.loadUser(this._account.id, true);

		}).then( account => {

			this._account = account;
			this.onUpdate.next();
			OverlayService.hide();

		}).catch( err => {

			OverlayService.hide();
			OverlayService.showError("Unable to enable login", err.errorMessage || "Please contact technical support for assistance");

		});
	}

	public confirmCreateEmailAccount() {
		OverlayService.showDialog("Create login credentials?", `<strong class="text-danger">Warning!</strong> This action will send login credentials to ${this._account.firstName} ${this._account.lastName } via email.<br />You cannot undo this process if you press the "Confirm" button.<br /><br />Are you sure you wish to proceed?`, [
			{"text": "Cancel", "handler": () => {
				OverlayService.hide();
			}},
			{"text": "Confirm", "handler": () => {
				OverlayService.hide();
				this.createEmailAccount();
			}}
		]);
	}

	public confirmDeleteCredentials() {
		OverlayService.showDialog("Delete login credentials?", `<strong class="text-danger">Warning!</strong> This action will delete the login credentials for ${this._account.firstName} ${this._account.lastName }.<br />This user will no longer be able to log in to their account.<br /><br />Are you sure you wish to proceed?`, [
			{"text": "Cancel", "handler": () => {
				OverlayService.hide();
			}},
			{"text": "Confirm", "handler": () => {
				OverlayService.hide();
				this.deleteCredentials();
			}}
		]);
	}

}
