import { Injectable } from '@angular/core';
import { RestService, API } from './rest.service';
import { HttpClient } from '@angular/common/http';

@Injectable({ "providedIn": 'root' })
export class ClientUploadService {

	constructor(private restService: RestService, private httpClient: HttpClient) {
	}

	public async testUpload(file: File, isReimbursement: boolean): Promise<any> {
		const postData: any = {
			"invoice": {
				"filesize": file.size,
				"mimeType": file.type,
				"isReimbursement": isReimbursement
			}
		};

		const uploadRequest = await this.restService.post(API.attachments, "client/invoice/upload", postData);
		try {

			// Use Fetch API, because the Angular HttpClient adds a bunch of headers that stops the upload working correctly.
			const response = await fetch(uploadRequest.url, {
				"method": "PUT",
				"mode": "cors",
				"cache": "no-cache",
				"headers": {
					"Content-Type": file.type
				},
				"body": file
			});
			await this.restService.put(API.attachments, `client/invoice/upload/${uploadRequest.fileId}`, {});
		}
		catch (e) {
			console.log(e);
		}
	}

	public async load(): Promise<any> {
		return await this.restService.get(API.attachments, 'client/invoices', {});
	}
}
