export enum RelationshipDescription {
Advocate, 
AuntUncle, 
CaseManager, 
Child, 
ChildSafetyProtectionRep, 
FamilyInLaw, 
FamilyOther, 
FosterCarer, 
Friend, 
GrandParent, 
LAC, 
Parent, 
ParentStep, 
Partner, 
PostedStatements, 
PreviousSupportCoordinator, 
PublicGuardianOPG, 
Self, 
ServiceProvider, 
Sibling, 
SupportCoordinator, 
PlanNominee, 
Guardian, 
UnknownAlternativeContact, 
Other
}

export namespace RelationshipDescription {

	export function allValues(): RelationshipDescription[] {
		return [
			RelationshipDescription.Advocate,
			RelationshipDescription.AuntUncle,
			RelationshipDescription.CaseManager,
			RelationshipDescription.Child,
			RelationshipDescription.ChildSafetyProtectionRep,
			RelationshipDescription.FamilyInLaw,
			RelationshipDescription.FamilyOther,
			RelationshipDescription.FosterCarer,
			RelationshipDescription.Friend,
			RelationshipDescription.GrandParent,
			RelationshipDescription.LAC,
			RelationshipDescription.Parent,
			RelationshipDescription.ParentStep,
			RelationshipDescription.Partner,
			RelationshipDescription.PostedStatements,
			RelationshipDescription.PreviousSupportCoordinator,
			RelationshipDescription.PublicGuardianOPG,
			RelationshipDescription.Self,
			RelationshipDescription.ServiceProvider,
			RelationshipDescription.Sibling,
			RelationshipDescription.SupportCoordinator,
			RelationshipDescription.PlanNominee,
			RelationshipDescription.Guardian,
			RelationshipDescription.UnknownAlternativeContact,
			RelationshipDescription.Other
		]
	}

	export function parse(src: string): RelationshipDescription {
		switch (src) {
			case 'Advocate' : return RelationshipDescription.Advocate;
			case 'Aunt/Uncle' : return RelationshipDescription.AuntUncle;
			case 'Case Manager' : return RelationshipDescription.CaseManager;
			case 'Child' : return RelationshipDescription.Child;
			case 'Child Safety/Protection/Rep' : return RelationshipDescription.ChildSafetyProtectionRep;
			case 'Family (In Law)' : return RelationshipDescription.FamilyInLaw;
			case 'Family (Other)' : return RelationshipDescription.FamilyOther;
			case 'Foster Carer' : return RelationshipDescription.FosterCarer;
			case 'Friend' : return RelationshipDescription.Friend;
			case 'Grand Parent' : return RelationshipDescription.GrandParent;
			case 'LAC' : return RelationshipDescription.LAC;
			case 'Parent' : return RelationshipDescription.Parent;
			case 'Parent (Step)' : return RelationshipDescription.ParentStep;
			case 'Partner' : return RelationshipDescription.Partner;
			case 'Posted Statements' : return RelationshipDescription.PostedStatements;
			case 'Previous Support Coordinator' : return RelationshipDescription.PreviousSupportCoordinator;
			case 'Public Guardian (OPG)' : return RelationshipDescription.PublicGuardianOPG;
			case 'Self' : return RelationshipDescription.Self;
			case 'Service Provider' : return RelationshipDescription.ServiceProvider;
			case 'Sibling' : return RelationshipDescription.Sibling;
			case 'Support Coordinator' : return RelationshipDescription.SupportCoordinator;
			case 'Plan Nominee' : return RelationshipDescription.PlanNominee;
			case 'Guardian' : return RelationshipDescription.Guardian;
			case 'Unknown/Alternative Contact' : return RelationshipDescription.UnknownAlternativeContact;
			case 'Other' : return RelationshipDescription.Other;
			default: return undefined;
		}
	}

	export function toString(src: RelationshipDescription): string {
		switch (src) {
			case RelationshipDescription.Advocate: return 'Advocate';
			case RelationshipDescription.AuntUncle: return 'Aunt/Uncle';
			case RelationshipDescription.CaseManager: return 'Case Manager';
			case RelationshipDescription.Child: return 'Child';
			case RelationshipDescription.ChildSafetyProtectionRep: return 'Child Safety/Protection/Rep';
			case RelationshipDescription.FamilyInLaw: return 'Family (In Law)';
			case RelationshipDescription.FamilyOther: return 'Family (Other)';
			case RelationshipDescription.FosterCarer: return 'Foster Carer';
			case RelationshipDescription.Friend: return 'Friend';
			case RelationshipDescription.GrandParent: return 'Grand Parent';
			case RelationshipDescription.LAC: return 'LAC';
			case RelationshipDescription.Parent: return 'Parent';
			case RelationshipDescription.ParentStep: return 'Parent (Step)';
			case RelationshipDescription.Partner: return 'Partner';
			case RelationshipDescription.PostedStatements: return 'Posted Statements';
			case RelationshipDescription.PreviousSupportCoordinator: return 'Previous Support Coordinator';
			case RelationshipDescription.PublicGuardianOPG: return 'Public Guardian (OPG)';
			case RelationshipDescription.Self: return 'Self';
			case RelationshipDescription.ServiceProvider: return 'Service Provider';
			case RelationshipDescription.Sibling: return 'Sibling';
			case RelationshipDescription.SupportCoordinator: return 'Support Coordinator';
			case RelationshipDescription.PlanNominee: return 'Plan Nominee';
			case RelationshipDescription.Guardian: return 'Guardian';
			case RelationshipDescription.UnknownAlternativeContact: return 'Unknown/Alternative Contact';
			case RelationshipDescription.Other: return 'Other';
			default: return undefined;
		}
	}

	export function toJSON(src: RelationshipDescription): string {
		switch (src) {
			case RelationshipDescription.Advocate: return 'Advocate';
			case RelationshipDescription.AuntUncle: return 'Aunt/Uncle';
			case RelationshipDescription.CaseManager: return 'Case Manager';
			case RelationshipDescription.Child: return 'Child';
			case RelationshipDescription.ChildSafetyProtectionRep: return 'Child Safety/Protection/Rep';
			case RelationshipDescription.FamilyInLaw: return 'Family (In Law)';
			case RelationshipDescription.FamilyOther: return 'Family (Other)';
			case RelationshipDescription.FosterCarer: return 'Foster Carer';
			case RelationshipDescription.Friend: return 'Friend';
			case RelationshipDescription.GrandParent: return 'Grand Parent';
			case RelationshipDescription.LAC: return 'LAC';
			case RelationshipDescription.Parent: return 'Parent';
			case RelationshipDescription.ParentStep: return 'Parent (Step)';
			case RelationshipDescription.Partner: return 'Partner';
			case RelationshipDescription.PostedStatements: return 'Posted Statements';
			case RelationshipDescription.PreviousSupportCoordinator: return 'Previous Support Coordinator';
			case RelationshipDescription.PublicGuardianOPG: return 'Public Guardian (OPG)';
			case RelationshipDescription.Self: return 'Self';
			case RelationshipDescription.ServiceProvider: return 'Service Provider';
			case RelationshipDescription.Sibling: return 'Sibling';
			case RelationshipDescription.SupportCoordinator: return 'Support Coordinator';
			case RelationshipDescription.PlanNominee: return 'Plan Nominee';
			case RelationshipDescription.Guardian: return 'Guardian';
			case RelationshipDescription.UnknownAlternativeContact: return 'Unknown/Alternative Contact';
			case RelationshipDescription.Other: return 'Other';
			default: return undefined;
		}
	}
}
