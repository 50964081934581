import { Component, OnInit, Input } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { UserType } from "@classes/user";
import { PlanSupportCategory, PlanSupportItem } from "@classes/plans";


@Component({
	"selector": "plan-support-item",
	// "styleUrls": ["./planSupportItem.component.scss"],
	"templateUrl": "./planSupportItem.component.html"
})
export class PlanSupportItemComponent extends PrivateComponent implements OnInit {

	private _supportItem: PlanSupportItem;
	private _supportCategory: PlanSupportCategory;

	@Input()
	set supportCategory(value: PlanSupportCategory) {
		this._supportCategory = value;
	}

	@Input()
	set supportItem(value: PlanSupportItem) {
		this._supportItem = value;
	}

	get item(): PlanSupportItem {
		return this._supportItem;
	}

	constructor() {
		super();
		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Account Details', 'Edit Plans');
	}

	ngOnInit() {
		super.ngOnInit()
	}

}
