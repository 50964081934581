import { Injectable } from '@angular/core';
import { RestService, API } from './rest.service';
import { Cache } from "@classes/cache";
import { AttachedFile, FileDownloader } from '@classes/files';
import { Region } from "@classes/regions";
import { SupportCategory, SupportItem } from "@classes/supports";
import { Plan } from "@classes/plans";
import { CacheSignalService } from "@services/cachesignal.service";
import { Invoice } from "@classes/invoices";
import { SignalReceiver } from "@classes/signalreceiver.class";
import { Settings } from '@classes/settings';
import { SupportsService } from "@services/supports.service";
import { ServiceAgreement, ServiceDelivered } from "@classes/serviceAgreement";
import { assert } from "@classes/errors";
import { ProviderSpend } from "@classes/providerSpend";
import moment from "moment";

export interface DateRange {
	dateFrom: Date;
	dateTo: Date;
}

type uuid = string;

const promiseSerial = funcs =>
	funcs.reduce((promise, func) =>
		promise.then(result => func().then(Array.prototype.concat.bind(result))), Promise.resolve([]));


@Injectable({ "providedIn": 'root' })
export class PlanService extends SignalReceiver implements FileDownloader {

	private _clientPlans: Map<string, Cache<Plan>> = new Map<string, Cache<Plan>>();
	private _planSupportCache: Map<string, Cache<SupportItem>> = new Map<string, Cache<SupportItem>>();

	constructor(private restService: RestService, private supportsService: SupportsService, signalService: CacheSignalService) {
		super(signalService);
	}

	private invoiceSignalHandler(value: any): void {
		const clientId = (<Invoice>value).clientId;
		this._clientPlans.delete(clientId);
	}

	protected setupSubscriptions() {

		// Delete all plans for a client when an invoice is saved or deleted. This will force fresh data to be loaded
		// allowing totals to be reflected correctly.
		this._subscriptions.push(this.signalService.observable('Invoice Saved').subscribe( invoice => this.invoiceSignalHandler(invoice) ) );

		// Delete all plans for a client when an invoice is saved or deleted. This will force fresh data to be loaded
		// allowing totals to be reflected correctly.
		this._subscriptions.push(this.signalService.observable('Invoice Deleted').subscribe( invoice => this.invoiceSignalHandler(invoice) ) );
	}

	private newPlanExclusionFromWidgetDays(): number {
		const settings = Settings.instance;
		const defaultDays = 90;

		if (settings.has('planConsumption')) {
			const value = settings.get('planConsumption') || {};
			return value.newPlanExclusionFromWidgetDays || defaultDays;
		}

		return defaultDays;
	}

	private getPlanSupportItemCache(planId: string): Cache<SupportItem> {
		if (!this._planSupportCache.has(planId)) {
			this._planSupportCache.set(planId, new Cache<SupportItem>());
		}

		return this._planSupportCache.get(planId);
	}

	private getClientPlanCache(clientId: string): Cache<Plan> {
		if (!this._clientPlans.has(clientId)) {
			this._clientPlans.set(clientId, new Cache<Plan>());
		}

		return this._clientPlans.get(clientId);
	}

	public removeFiles(planId: string, deletedFiles: string[]): Promise<any> {
		if (!deletedFiles.length) {
			return Promise.resolve();
		}

		// Use the attachment microservice
		return Promise.all( deletedFiles.map( fileId => this.restService.delete(API.attachments, `${fileId}`) ) );
	}

	public saveFiles(plan: Plan, files: AttachedFile[]): Promise<any> {

		const promiseFuncs = files.map( file => {

			return () => {
				return file.content.then( content => {
					return {
						"file": {
							"name": file.name,
							"mimeType": file.mimeType,
							"content": content.replace(/^.*,/, ''), // strip out the "data:[<mediatype>][;base64]," header info
							"size": file.size,
							"md5": file.md5
						},
						"metadata": {
							"description": null,
							"targets": [{
								"id": plan.client,
								"type": "client",
							}, {
								"id": plan.id,
								"type": "plan",
							}]
						}
					};
				}).then( postData => {

					return this.restService.put(API.attachments, "", postData);

				}).then( result => {

					return Promise.resolve(result);

				});
			}
		});

		return promiseSerial(promiseFuncs);
	}

	private calcPlanExclusionFromWidgetDays(plan: Plan): Date {
		// See if the startDate is set and if the dashboardInclusionDate is NOT set
		// If so, set the dashboardInclusionDate date to a future date based on system settings
		const planStartMoment = moment(plan.startDate);

		if ( planStartMoment.isValid() && !moment(plan.dashboardInclusionDate).isValid() ) {
			return planStartMoment.add(this.newPlanExclusionFromWidgetDays(), 'days').toDate();
		}

		return null;
	}

	public async update(plan: Plan, serviceAgreements?: ServiceAgreement[]): Promise<[Plan, ServiceAgreement[]]> {

		plan.dashboardInclusionDate = this.calcPlanExclusionFromWidgetDays(plan);

		const postData: any = {
			"plan": Plan.toJSON(plan),
			"serviceAgreements": (serviceAgreements || []).map( ServiceAgreement.toJSON )
		};

		const response = await this.restService.post(API.plans, `plan`, postData);
		const returnedPlan = Plan.parse(response.plan);
		this._clientPlans.delete(plan.client);

		return [returnedPlan, returnedPlan.serviceAgreements];
	}

	/**
	* Sets a plan to draft, allowing it to be edited.
	*/
	public async toDraft(planId: string): Promise<[Plan, ServiceAgreement[]]> {
		const response = await this.restService.patch(API.plans, `${planId}`, {});
		const returnedPlan = Plan.parse(response.plan);
		this._clientPlans.delete(returnedPlan.client);

		return [returnedPlan, returnedPlan.serviceAgreements];
	}



	/**
	* NB This method is now only called in response to creating a new plan. Attached files are not handled
	* through this method any more.
	*/
	public save(plan: Plan, newFiles?: AttachedFile[], deletedFiles?: string[]): Promise<Plan> {

		plan.dashboardInclusionDate = this.calcPlanExclusionFromWidgetDays(plan);
		const planJson: any = Plan.toJSON(plan);
		delete planJson.categories;

		const postData: any = {
			"plan": planJson
		};

		let returnedPlan;
		return this.restService.post(API.plans, "plan", postData).then( response => {

			returnedPlan = Plan.parse(response.plan);
			return newFiles === undefined ? Promise.resolve() : this.saveFiles(returnedPlan, newFiles);

		}).then( () => {

			return deletedFiles === undefined ? Promise.resolve() : this.removeFiles(returnedPlan.id, deletedFiles);

		}).then( () => {

			this._clientPlans.delete(plan.client);
			return Promise.resolve( returnedPlan );

		});
	}

	public savePlanCategories(plan: Plan) {
		if (!plan.id) {
			return Promise.reject("Can't update categories on this plan");
		}

		const planJson: any = Plan.toJSON(plan);
		const postData: any = {
			"plan": planJson,
			//"planId": plan.id,
			"categories": planJson.category
		};

		return this.restService.post(API.plans, "plan", postData).then( response => {

			const returnedPlan = Plan.parse(response.plan);
			this._clientPlans.delete(plan.client);
			return Promise.resolve( returnedPlan );

		});
	}

	public load(planId: string): Promise<Plan> {
		return this.restService.get(API.plans, `plan/${planId}`).then( response => {
			const plan: Plan = Plan.parse(response);
			return Promise.resolve(plan);
		});
	}

	public async siblingPlans(planId: string): Promise<Plan[]> {
		const response = await this.restService.get(API.plans, `plans/siblings/${planId}`);
		return response.map( src => Plan.parse(src) );
	}

	public mergePlans(srcPlan: string, destPlan: string, clientId: string): Promise<boolean> {
		const postData = {
			"src": srcPlan,
			"dest": destPlan,
			"clientId": clientId
		};

		return this.restService.post(API.plans, 'merge', postData).then( response => {
			if (!!response.success) {
				const cache = this.getClientPlanCache(clientId);
				cache.items = response.plans;
			}
			return Promise.resolve(!!response.success);
		});
	}

	public updateCache(clientId: string, plans: Plan[]) {
		const cache = this.getClientPlanCache(clientId);
		cache.items = plans;
	}

	public listPlans(clientId: string, forceReload: boolean = false): Promise<Plan[]> {
		const cache = this.getClientPlanCache(clientId);
		if (forceReload) {
			cache.invalidate();
		}

		if (cache.valid) {
			return Promise.resolve( cache.items );
		}

		return this.restService.get(API.plans, `plans/${clientId}`).then( response => {

			// Response is in the form {"plans": any[], "supports": any[]}
			// In order to parse the plans properly, we need all of the support items that are involved.
			// Import these first into the cache prior to reading the list of plans
			cache.items = response.map( data => Plan.parse(data) );
			return Promise.resolve(cache.items);
		});
	}

	public newPlan(): Promise<Plan> {
		return Promise.resolve( Plan.newPlan() );
	}

	public async getSupportCategories(date: Date): Promise<SupportCategory[]> {
		const dateString = moment(date).format("YYYYMMDD");
		return await this.restService.get(API.plans, `supportcategories/${dateString}`);
	}

	public async getSupportItemsForRegion(region: Region, date: Date, pace: boolean): Promise<SupportItem[]> {
		return await this.supportsService.getSupportsFor(region, date, undefined, undefined, pace);
	}

	public async getSupportItems(plan: Plan): Promise<SupportItem[]> {
		return await this.supportsService.getSupportsFor(plan.region, plan.startDate);
	}

	private formatDateForUrl(date: Date): string {
		return moment(date).format('YYYYMMDD');
	}

	public async getSupportDateRanges(): Promise<DateRange[]> {
		return await this.restService.get(API.plans, "pricingdates");
	}

	public loadAttachment(attachmentId: string): Promise<any> {
		return this.restService.get(API.attachments, `${attachmentId}`).then( async response => {

			const download = await fetch(response.url, {
				"method": "GET",
				"mode": "cors",
				"cache": "no-cache",
				"headers": {}
			});

			return await download.arrayBuffer();
		});
	}

	public deletePlan(plan: Plan): Promise<void> {
		return this.restService.delete(API.plans, `${plan.id}`).then( result => {
			if (result.success) {
				this._clientPlans.delete(plan.client);
				return Promise.resolve();
			}

			return Promise.reject(false);
		});
	}

	public saveQuarantinedFunds(plan: Plan): Promise<Plan> {
		if (!plan.id) {
			return Promise.reject("Can't update this plan");
		}

		const planJson: any = Plan.toJSON(plan);
		const postData: any = {
			"planId": plan.id,
			"categories": planJson.category
		};

		return this.restService.post(API.plans, "quarantined-funds", postData).then( response => {

			const returnedPlan = Plan.parse(response.plan);
			this._clientPlans.delete(plan.client);
			return Promise.resolve( returnedPlan );

		});
	}

	public transactionReport(plan: Plan, startDate: Date, endDate: Date): Promise<any> {
		const postData: any = {
			"planId": plan.id,
			"clientId": plan.client,
			"startDate": moment(startDate).format("YYYY-MM-DD"),
			"endDate": moment(endDate).format("YYYY-MM-DD")
		};

		return this.restService.post(API.plans, "report", postData).then( response => {
			return Promise.resolve(response.content);
		});
	}

	public async serviceAgreements(plan: Plan): Promise<ServiceAgreement[]> {
		const response = await this.restService.get(API.plans, `service-agreements/${plan.id}`);
		return (response || []).map( ServiceAgreement.parse );
	}

	public async saveServiceAgreement(serviceAgreement: ServiceAgreement): Promise<ServiceAgreement> {
		assert(!!serviceAgreement, "Param `serviceAgreement` is not defined");

		const postData = {
			"serviceAgreement": ServiceAgreement.toJSON(serviceAgreement)
		};

		const response = await this.restService.post(API.plans, `service-agreement`, postData);

		const cache = this.getClientPlanCache(serviceAgreement.client.id);
		cache.invalidate();

		return ServiceAgreement.parse(response);
	}

	public async deleteServiceAgreement(serviceAgreement: ServiceAgreement): Promise<void> {
		assert(!!serviceAgreement, "Missing param `serviceAgreement`");
		assert(!!serviceAgreement.id, "Cannot delete a new service agreement");

		await this.restService.delete(API.plans, `service-agreement/${serviceAgreement.id}`, {});
	}

	public async getAllocatedFunds(planId: uuid, providerId: uuid, supportCategoryNumber: number): Promise<ServiceDelivered[]> {
		const data = await this.restService.get(API.plans, `allocatedfunds/${planId}/${providerId}/${supportCategoryNumber}`);
		return data.map( ServiceDelivered.parse );
	}

	public async getProviderSpend(planId: uuid, showReimbursements: boolean = true): Promise<ProviderSpend[]> {
		const data = await this.restService.get(API.plans, `providerspend/${planId}?reimbursements=${showReimbursements}`);
		return data.map( ProviderSpend.parse );
	}
}
