import { Component } from '@angular/core';
import moment from "moment";

@Component({
	"selector": "dashboard-message",
	"styleUrls": [],
	"templateUrl": "./dashboardmessage.component.html"
})
export class DashboardMessageComponent {

	public get showMessage(): boolean {
		//return false;
		// months start at '0', days count from 1
		const startDate = moment([2022, 4, 26]);
		const endDate = moment([2022, 5, 14]);
		return moment().isBetween(startDate, endDate);
	}

}
