import { Component, OnInit, AfterViewInit, Output, EventEmitter, ViewChild, HostListener, ElementRef, Input } from '@angular/core';
import { Invoice, InvoiceStatus } from "@classes/invoices";
import { PrivateComponent } from "@classes/private.component";
import { OverlayService } from "@services/overlay.service";

@Component({
	"selector": "reopen-bill-dialog",
	"styleUrls": ["./reopendialog.component.scss"],
	"templateUrl": "./reopendialog.component.html"
})
export class BillReopenDialogComponent extends PrivateComponent implements OnInit, AfterViewInit {

	@ViewChild('btnCancel') private btnCancel: ElementRef;
	@ViewChild('dialog')    private dialog: ElementRef;

	private _bill: Invoice;

	/**
	* Map of keyboard shortcuts, and their associated handler functions
	*/
	private readonly keyMap: Map<string, () => void> = new Map<string, () => void>([
		['i', this.toInvestigation]
	]);


	@HostListener('window:keydown', ['$event'])
	interceptBrowserShortcuts(event: KeyboardEvent) {

		if (event.key === 'Escape') {
			event.preventDefault();
			event.stopPropagation();
			this.closeDialogWindow();
		}

		// Make sure the keyboard focus can't leave the dialog box
		if (event.key === 'Tab') {

			const activeElement = document.activeElement;
			const els = Array.from(this.dialog.nativeElement.querySelectorAll('button:not([disabled])'));
			const first = els[0];
			const last = els[els.length - 1];

			if (!event.shiftKey && activeElement === last) {
				this.focusControl(first, event);
			}
			else if (event.shiftKey && activeElement === first) {
				this.focusControl(last, event);
			}
		}

		const shortcutKeys = Array.from(this.keyMap.keys());
		if (shortcutKeys.includes(event.key)) {
			this.cancelEvent(event);
		}
	}

	/**
	* Handles keyUp events to implement keyboard shortcuts on page
	*/
	@HostListener('window:keyup', ['$event'])
	keyboardShortcut(event: KeyboardEvent) {

		// Handle any other keyboard shortcuts here
		const handler = this.keyMap.get(event.key);
		if (handler !== undefined) {
			this.cancelEvent(event);
			handler.call(this);
		}
	}

	get billStatus(): InvoiceStatus {
		if (!this._bill) {
			return undefined;
		}

		return this._bill.status;
	}

	public readonly invoiceStatus = {
		"locked": InvoiceStatus.locked,
		"submitted": InvoiceStatus.submitted,
		"reconciled": InvoiceStatus.reconciled,
		"paid": InvoiceStatus.paid
	};

	protected toInvestigation(): void {
		this.closeDialogWindow(InvoiceStatus.investigation);
	}

	@Input()
	set bill(value: Invoice) {
		this._bill = value;
	}

	@Output()
	dialogClosed = new EventEmitter<InvoiceStatus>();

	/**
	* Sets the focus to the specified UI form field. Cancels event propagation from a keyboard event if supplied.
	* Initially coded to handle keyboard shortcuts to allow quick focus of controls.
	*
	* @param {ElementRef|any} control The UI control to focus
	* @param {KeyboardEvent} event Optional keyboard event that will have propagation terminated
	*/
	private focusControl(control: ElementRef|any, event?: KeyboardEvent) {
		if (event) {
			this.cancelEvent(event);
		}
		if (control.nativeElement) {
			setTimeout(() => { control.nativeElement.focus(); }, 0);
		}
		else {
			setTimeout(() => { control.focus(); }, 0);
		}
	}

	/**
	* Stops propagation and default behaviour of a keyboard event
	*
	* @param {KeyboardEvent} event
	*/
	private cancelEvent(event: KeyboardEvent): void {
		event.stopImmediatePropagation();
		event.stopPropagation();
		event.preventDefault();
	}

	closeDialogWindow(status?: InvoiceStatus): void {
		//const allowedStatuses = [InvoiceStatus.locked, InvoiceStatus.submitted, InvoiceStatus.reconciled, InvoiceStatus.paid];
		const allowedStatuses = [InvoiceStatus.investigation];
		if (status !== undefined && allowedStatuses.includes(status)) {
			this.dialogClosed.emit(status);
		}
		else {
			this.dialogClosed.emit();
		}
		OverlayService.hide();
	}

	ngOnInit() {
		super.ngOnInit()
	}

	constructor() {
		super();
	}

	ngAfterViewInit() {
		this.focusFirstControl();
	}

	private focusFirstControl(): void {
		this.focusControl(this.btnCancel);
	}
}
