import { Component, Input } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { Plan, PlanStatus, PlanBudget, PlanSupportItem, PlanSupportCategory } from "@classes/plans";
import { Invoice, InvoiceLineItem } from "@classes/invoices";
import { SupportItem } from "@classes/supports";
import { SupportsService } from "@services/supports.service";
import { OverlayService } from "@services/overlay.service";
import { UserType } from "@classes/user";
import { RegionUtils } from "@classes/regions";
import moment from 'moment';

@Component({
	"selector": "client-plans-dialog",
	"styleUrls": ["plansdialog.component.scss"],
	"templateUrl": "./plansdialog.component.html"
})
export class PlansDialogComponent extends PrivateComponent {

	private _clientPlans: Plan[] = undefined;
	private _invoice: Invoice = undefined;
	private _thisBillSpend: number = undefined;

	private _supportsMap: Map<string, SupportItem> = new Map<string, SupportItem>(); // Map of support items, keyed by supportItem.id

	private async loadSupports() {
		this._supportsMap.clear();

		// Find all of the supports used on this invoice
		const allSupportIds: string[] = Array.from(this._invoice.lineItems).map( lineItem => lineItem.supportItem );

		// Make a list of the unique support items (by converting to a set, and then back to an array)
		const uniqueSupportIds: string[] = Array.from( new Set<string>( allSupportIds ) );

		// Load the supports from indexedDB
		const promises: Promise<SupportItem>[] = uniqueSupportIds.map( this.supportsService.getSupport.bind(this.supportsService) );
		const supports = await Promise.all(promises);

		// Make an in-memory map for quick reference by ID
		supports.forEach( support => {
			this._supportsMap.set(support.id, support);
		});
	}

	public model = {
		"plan": undefined
	};

	constructor(private supportsService: SupportsService) {
		super();
		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Billing', 'Access billing');
	}

	@Input()
	set bill(value: Invoice) {
		if (value !== this._invoice) {
			this._invoice = value;
			this.loadSupports();
		}
	}

	@Input()
	set plans(value: Plan[]) {
		this._clientPlans = value;
		if (this._clientPlans.length > 0) {
			this.model.plan = this._clientPlans[0];

			const currentPlans = this._clientPlans.filter( plan => plan.status === PlanStatus.current );
			if (currentPlans.length === 1) {
				this.model.plan = currentPlans[0];
			}
		}
	}

	get supportCategories(): PlanSupportCategory[] {
		const values: PlanSupportCategory[] = this.model.plan.supportCategories;
		return values.sort( (a, b) => a.supportCategory.id - b.supportCategory.id );
	}

	getSupportItems(category: PlanSupportCategory): PlanSupportItem[] {
		const values: PlanSupportItem[] = category.supportItems;
		return values;
	}

	private budgetSpend(budget: PlanBudget): number {
		if (!budget) {
			return 0;
		}

		return (budget.paid || 0) + (budget.pending || 0) + (budget.unknown || 0);
	}

	private get thisBillSpend(): number {
		if (this._thisBillSpend !== undefined) {
			return this._thisBillSpend;
		}

		let result = 0;
		if (this._invoice) {
			result += Array.from(this._invoice.lineItems).reduce( (acc: number, cur: InvoiceLineItem) => {
				return acc + cur.total;
			}, 0);
		}

		this._thisBillSpend = result;
		return result;
	}

	get remainingFunds(): number {
		if (!this.model.plan) {
			return undefined;
		}
		return this.model.plan.budget.total - this.budgetSpend(this.model.plan.budget) - this.thisBillSpend;
	}

	get currentPlanSpend(): number {
		if (!this.model.plan) {
			return undefined;
		}

		return this.budgetSpend(this.model.plan.budget) + this.thisBillSpend;
	}

	get clientPlans(): Plan[] {
		return this._clientPlans;
	}

	public cancelDialog(): void {
		OverlayService.hide();
	}

	public getCategorySpendForThisBill(categoryId: number): number {
		const items = Array.from(this._invoice.lineItems).filter( (lineItem: InvoiceLineItem) => {

			const support = this._supportsMap.get(lineItem.supportItem);
			return support ? support.supportCategoryId === categoryId : false;

		});

		return items.reduce((acc: number, cur: InvoiceLineItem) => {
			return acc + cur.total;
		}, 0);
	}

	protected planLabel(plan: Plan): string {
		const formatString = "Do MMM YYYY";
		const startDate = moment(plan.startDate).startOf('day').format(formatString);
		const endDate = moment(plan.endDate).startOf('day').format(formatString);
		const status = PlanStatus.toString(plan.status);
		const region = RegionUtils.toString(plan.region).toUpperCase();
		const pace = plan.pace ? ' (PACE)' : '';
		return `${startDate} - ${endDate} (${status}) (Region: ${region})${pace}`;
	}
}
