import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class SyncErrorService {

	private errorMap = {
		"FIELD_CUSTOM_VALIDATION_EXCEPTION": "The following fields are invalid: "
	};

	private fieldMap = {
		"NDIS__c": "NDIS Number"
	};

	public format(error): string {
		if(!error)
			return error;

		let error_message: string = this.errorMap[error.name] || this.errorMap[error] || error.name || error;

		if (error.fields) {
			for (let index = 0; index < error.fields.length; index++) {
				error_message += this.fieldMap[error.fields[index]];
				if (index < error.fields.length - 1)
					error_message += ", ";
			}
		}

		return error_message;
	}

}
