import { Component, OnInit } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { UserType } from '@classes/user';
import { InvoiceStatus } from "@classes/invoices";
import { MenuBuilder } from "@services/navmenu.service";
import { ReportsService } from "@services/reports.service";
import { OverlayService } from "@services/overlay.service";
import { Table, TableColumnHeading, SortType, Alignment, StaticDataSource } from "@classes/tables";
import { saveAs } from 'file-saver';
import { DateUtils } from "@classes/utils";

interface InvoiceReportData {
	id: string;
	invoiceNumber: string;
	invoiceDate: Date;
	invoiceStatus: InvoiceStatus;
	clientId: string;
	client: string;
	providerId: string;
	provider: string;
	total: number;
	numPaid: number;
	amountPaid: number;
	numSubmitted: number;
	amountSubmitted: number;
	numRejected: number;
	amountRejected: number;
	numCancelled: number;
	amountCancelled: number;
	numExtracts: number;
}

@Component({
	"templateUrl": "./invoices.component.html",
	"styleUrls": ["./invoices.component.scss"]
})
export class MultipleSubmissionReportComponent extends PrivateComponent implements OnInit {

	private readonly _topHeadings: TableColumnHeading[] = [
		{"propName": undefined, "displayName": "", "sortType": SortType.none, "colspan": 4},
		{"propName": undefined, "displayName": "Submitted", "sortType": SortType.none, "colspan": 2, "alignment": Alignment.center},
		{"propName": undefined, "displayName": "Reconciled", "sortType": SortType.none, "colspan": 2, "alignment": Alignment.center},
		{"propName": undefined, "displayName": "Rejected", "sortType": SortType.none, "colspan": 2, "alignment": Alignment.center},
		{"propName": undefined, "displayName": "Cancelled", "sortType": SortType.none, "colspan": 2, "alignment": Alignment.center}
	];

	private readonly _tableHeadings: TableColumnHeading[] = [
		{"propName": "invoiceNumber", "displayName": "Bill #", "sortType": SortType.text },
		{"propName": "numExtracts", "displayName": "Extracts", "sortType": SortType.numeric, "alignment": Alignment.center},
		{"propName": "total", "displayName": "Bill Total", "sortType": SortType.numeric, "alignment": Alignment.center},
		{"propName": undefined, "displayName": "Unpaid", "sortType": SortType.none, "alignment": Alignment.center},
		{"propName": "numSubmitted", "displayName": "#", "sortType": SortType.numeric, "alignment": Alignment.center},
		{"propName": "amountSubmitted", "displayName": "$", "sortType": SortType.numeric, "alignment": Alignment.center},
		{"propName": "numPaid", "displayName": "#", "sortType": SortType.numeric, "alignment": Alignment.center},
		{"propName": "amountPaid", "displayName": "$", "sortType": SortType.numeric, "alignment": Alignment.center},
		{"propName": "numRejected", "displayName": "#", "sortType": SortType.numeric, "alignment": Alignment.center},
		{"propName": "amountRejected", "displayName": "$", "sortType": SortType.numeric, "alignment": Alignment.center},
		{"propName": "numCancelled", "displayName": "#", "sortType": SortType.numeric, "alignment": Alignment.center},
		{"propName": "amountCancelled", "displayName": "$", "sortType": SortType.numeric, "alignment": Alignment.center}
	];

	private _dataLoaded: boolean = false;
	private _sourceData: InvoiceReportData[] = undefined;
	private _table: Table<InvoiceReportData> = new Table<InvoiceReportData>(`submissionReport`, this._tableHeadings, this._topHeadings);
	private _summaryData: any = undefined;

	constructor(private reportsService: ReportsService) {
		super();

		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Reports', 'Multiple Submission Report');
	}

	ngOnInit() {
		super.ngOnInit();

		if (this.user) {
			this.buildMenu();
			this.loadData();
		}
	}

	public get billStatuses(): InvoiceStatus[] {
		if (!this._dataLoaded) {
			return [];
		}

		return Array.from(this._sourceData.keys());
	}

	public get table(): Table<InvoiceReportData> {
		return this._table;
	}

	public async loadData() {
		this._dataLoaded = false;
		try {
			OverlayService.show();
			this._summaryData = undefined;
			this._sourceData = await this.reportsService.multipleSubmissionReport();
			this._table.sourceData = StaticDataSource.from(this._sourceData);
			OverlayService.hide();
		}
		catch (e) {
			OverlayService.showError("Error", "Unable to display report");
			console.log(e);
		}
		finally {
			this._dataLoaded = true;
		}
	}

	protected get summary(): any {
		if (this._summaryData === undefined) {
			this.calcSummaryData();
		}

		return this._summaryData || {
			"total": undefined,
			"unpaid": undefined,
			"reconciled": undefined,
			"submitted": undefined,
			"rejected": undefined,
			"cancelled": undefined
		};
	}

	private calcSummaryData(): void {
		if (!this._dataLoaded || !this._sourceData || this._sourceData.length === 0) {
			return;
		}

		this._summaryData = {
			"total": this._sourceData.reduce( (acc, cur) => { acc += cur.total; return acc; }, 0),
			"unpaid": this._sourceData.reduce( (acc, cur) => { acc += cur.total - cur.amountPaid; return acc; }, 0),
			"reconciled": this._sourceData.reduce( (acc, cur) => { acc += cur.amountPaid; return acc; }, 0),
			"submitted": this._sourceData.reduce( (acc, cur) => { acc += cur.amountSubmitted; return acc; }, 0),
			"rejected": this._sourceData.reduce( (acc, cur) => { acc += cur.amountRejected; return acc; }, 0),
			"cancelled": this._sourceData.reduce( (acc, cur) => { acc += cur.amountCancelled; return acc; }, 0)
		};
	}

	get dataLoaded(): boolean {
		return this._dataLoaded;
	}

	public billStatus(status: InvoiceStatus): string {
		return InvoiceStatus.toString(status);
	}

	private buildMenu(): void {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.addHandler(
			'file-excel',
			'Download',
			() => { this.downloadReport(); },
			() => { return this.downloadDisabled(); }
		);
		menuBuilder.done();
	}

	private downloadDisabled(): boolean {
		return !this._dataLoaded || !this.table.hasData;
	}

	private downloadReport(): void {
		const csvReport = new ReportDownloader(this._sourceData);
		csvReport.downloadReport();
	}
}


class ReportDownloader {
	public constructor(private data: InvoiceReportData[]) {}

	private clientReportDataToString(item: InvoiceReportData): string {
		const fields = [
			"invoiceNumber",
			"invoiceDate",
			"client",
			"provider",
			"status",
			"numExtracts",
			"numLineItems",
			"total",
			"unpaid",
			"numPaid",
			"amountPaid",
			"numRejected",
			"amountRejected",
			"numCancelled",
			"amountCancelled",
			"numSubmitted",
			"amountSubmitted"
		];

		return fields.map( fieldName => {
			switch (fieldName) {
				case "unpaid": return item['total'] - item['amountPaid'];
				case "status": return InvoiceStatus.toString(item[fieldName]);
				case "invoiceDate": return DateUtils.toString(item[fieldName]);
				default: return `"${item[fieldName]}"`;
			}
		}).join(",");
	}

	private constructCSV(): string {
		let result = "Bill #,Date,Client,Provider,Status,# Extracts,# Line Items,Bill Total,Unpaid Amount,# Reconciled, $ Reconciled, # Rejected, $ Rejected, # Cancelled, $ Cancelled, # Submitted, $ Submitted\n";
		result += this.data.map( data => { return this.clientReportDataToString(data); }).join("\n");
		return result;
	}


	public downloadReport(): void {
		const csv = this.constructCSV();
		const file = new Blob([csv], { "type": "text/csv;charset=utf-8" });
		saveAs(file, "multi-submission-report.csv");
	}
}
