import { Injectable } from '@angular/core';
import { RestService, API } from './rest.service';
import { AttachedFile } from '@classes/files';
import { Cache } from "@classes/cache";
import { LineItemStatus, LineItemStatusUtils } from "@classes/invoices";
import { FileValidator } from "@classes/files";

abstract class ProdaFileValidator extends FileValidator {
	abstract get claimReferenceIdx(): number;
	abstract get fileType(): string;
}

export interface Admin {
	id: string;
	name: string;
}

export interface ProdaSubmission {
	id: string;
	submittedBy: string;
	submittedByName: string;
	submittedDate: Date;
	savedBy: string;
	savedByName: string;
	savedDate: Date;
	filename: string;
	responseFilename: string;
	count: number;
	reconciled: number;
	discrepancies: number;
	responseId: string;
	extract: string;
	prodaFileType: string;
	locked: boolean;
}

export interface Bank {
	bsb: string;
	accountNumber: string;
	accountName: string;
}

export interface ProdaSubmissionItem {
	id: string;
	invoiceNumber: string;
	claimReference: string;
	clientId: string;
	clientName: string;
	ndisNumber: string;
	amountClaimed: number;
	amountPaid: number;
	discrepancy: number;
	gstCode: string;
	responseId: string;
	responseItemId: string;
	successFileItemId: string;
	prodaFileType: string;
	//enriteAccountId: string;
	//enriteAccountName: string;
	providerId: string;
	providerName: string;
	resolved: boolean;
	bank: Bank;
	reimbursement: boolean;
	status: LineItemStatus;
	invoiceId: string;
	quantity: number;
	supportItem?: string;
	supportItemNumber: string;
	priceLimit: number;
	supportsDeliveredFrom: Date;
	paymentRequestStatus: string;
	errorMessage: string;
}

export interface ProdaSubmissionHistory extends Admin {
	// id: string;   // The id of the history item - inherited from Admin interface
	// name: string; // The name of the user that created the item - inherited from Admin interface
	user: string;    // The id of the user that created the item
	date: Date;      // The date the item was created
	comment: string; // The notes/comment attached to the item
}

@Injectable({ providedIn: 'root' })
export class ProdaService {

	private _submissions = new Cache<ProdaSubmission>();
	private _submissionItems = new Map<string, Cache<ProdaSubmissionItem>>();
	private _submissionHistory = new Map<string, Cache<ProdaSubmissionHistory>>();

	constructor(private restService: RestService) {}

	public clearCache(): void {
		this._submissions.invalidate();
		this._submissionItems.forEach( cache => {
			cache.invalidate();
		});
		this._submissionHistory.forEach( cache => {
			cache.invalidate();
		});
	}

	private getSubmissionItemCache(fileId): Cache<ProdaSubmissionItem> {
		if (!this._submissionItems.has(fileId)) {
			this._submissionItems.set(fileId, new Cache<ProdaSubmissionItem>());
		}

		return this._submissionItems.get(fileId);
	}

	private getSubmissionHistoryCache(submissionItemId): Cache<ProdaSubmissionHistory> {
		if (!this._submissionHistory.has(submissionItemId)) {
			this._submissionHistory.set(submissionItemId, new Cache<ProdaSubmissionHistory>());
		}

		return this._submissionHistory.get(submissionItemId);
	}

	public getAdmins(): Promise<Admin[]> {
		return this.restService.get(API.proda, "users/admins");
	}

	public loadFiles(offset: number = 0, forceReload: boolean = false): Promise<ProdaSubmission[]> {

		if (forceReload) {
			this._submissions.invalidate();
		}

		if (this._submissions.valid && offset === 0) {
			return Promise.resolve(this._submissions.items);
		}

		return this.restService.post(API.proda, "submissions", {"offset": offset}).then( result => {

			const currentSet = (this._submissions.valid) ? this._submissions.items : [];
			const newItems = result.map( item => {
				return {
					"id": item.id,
					"submittedBy": item.submittedBy,
					"submittedByName": item.submittedByName,
					"submittedDate": new Date(item.submissionDate),
					"savedBy": item.savedBy,
					"savedByName": item.savedByName,
					"savedDate": new Date(item.saveDate),
					"filename": item.filename,
					"count": item.count,
					"reconciled": item.reconciled,
					"discrepancies": item.discrepancies,
					"responseId": item.responseId,
					"extract": item.extract,
					"responseFilename": item.responseFilename,
					"prodaFileType": item.prodaFileType,
					"locked": item.locked
				};
			});

			this._submissions.items = currentSet.concat(newItems);

			return newItems;
		});
	}

	public async getSubmission(submissionId: string): Promise<ProdaSubmission> {
		if (this._submissions.valid) {
			const result = this._submissions.items.find( item => item.id === submissionId );
			if (result) {
				return Promise.resolve(result);
			}
		}

		const currentSet = (this._submissions.valid) ? this._submissions.items : [];
		const response = await this.restService.get(API.proda, `submissions/${submissionId}`);

		const newItems = response.map( item => {
			return {
				"id": item.id,
				"submittedBy": item.submittedBy,
				"submittedByName": item.submittedByName,
				"submittedDate": new Date(item.submissionDate),
				"savedBy": item.savedBy,
				"savedByName": item.savedByName,
				"savedDate": new Date(item.saveDate),
				"filename": item.filename,
				"count": item.count,
				"reconciled": item.reconciled,
				"discrepancies": item.discrepancies,
				"responseId": item.responseId,
				"extract": item.extract,
				"responseFilename": item.responseFilename,
				"prodaFileType": item.prodaFileType,
				"locked": item.locked
			};
		});

		return newItems.find( item => item.id === submissionId );
	}

	public getSubmissionItem(submissionId: string, submissionItemId: string): Promise<ProdaSubmissionItem> {
		return this.loadSubmissionFile(submissionId).then( items => {

			const result = items.find( item => item.id === submissionItemId );
			return result ? Promise.resolve(result) : Promise.reject(undefined);

		});
	}

	private calcDiscrepancy(amount: number): number {
		const oneCent = 0.01;

		if (amount === null) {
			return null;
		}

		if (Math.abs(amount) < oneCent) {
			return null;
		}

		return amount;
	}

	public loadSubmissionFile(submissionId: string, forceReload?: boolean): Promise<ProdaSubmissionItem[]> {
		const cache = this.getSubmissionItemCache(submissionId);

		if (forceReload) {
			cache.invalidate();
		}

		if (cache.valid) {
			return Promise.resolve(cache.items);
		}

		return this.restService.get(API.proda, `submission/${submissionId}`).then( data => {

			cache.items = data.map( item => {
				return {
					"id": item.id,
					"invoiceNumber": item.invoiceNumber,
					"claimReference": `${item.claimReference}`,
					"clientId": item.clientId,
					"clientName": item.clientName,
					"ndisNumber": item.ndisNumber,
					"amountClaimed": item.amountClaimed,
					"amountPaid": item.amountPaid,
					"discrepancy": this.calcDiscrepancy(item.discrepancy),
					"gstCode": item.gstCode,
					"responseId": item.responseId,
					"responseItemId": item.responseItemId,
					"successFileItemId": item.successFileItemId,
					"prodaFileType": item.prodaFileType,
					//"enriteAccountId": item.enriteAccountId,
					//"enriteAccountName": item.enriteAccountName,
					"providerId": item.providerId,
					"providerName": item.providerName,
					"resolved": item.finalised,
					"reimbursement": item.reimbursement,
					"bank": {
						"bsb": item.bankBSB,
						"accountNumber": item.bankAccountNumber,
						"accountName": item.bankAccountName
					},
					"status": LineItemStatusUtils.parse(item.status),
					"invoiceId": item.invoiceId,
					"quantity": item.quantity,
					"supportItem": item.supportItem,
					"supportItemNumber": item.supportItemNumber,
					"priceLimit": item.priceLimit,
					"supportsDeliveredFrom": item.supportsDeliveredFrom,
					"paymentRequestStatus": item.paymentRequestStatus,
					"errorMessage": item.errorMessage
				};
			});
			return Promise.resolve(cache.items);
		});
	}

	/**
	* Validates the provided bank data to ensure that BSB and account number appear valid:
	* BSB must be exactly 6 digits
	* Account Number must be at least 5 digits
	*
	* @param {Bank} bank The bank information to validate
	* @return {boolean} True if the information appears valid, false otherwise.
	*/
	public validateBankInfo(bank: Bank): boolean {
		bank = bank || {
			"bsb": undefined,
			"accountName": undefined,
			"accountNumber": undefined
		};

		const checks = [
			/^\d{6}$/.test(bank.bsb),             // True if BSB is exactly 6 digits
			/^\d{5,}$/.test(bank.accountNumber)   // True if accountNumber is 5 or more digits
		];

		return checks.reduce( (acc, cur) => { return acc && cur; }, true);
	}

	public saveProdaResponse(file: AttachedFile, submissionId: string, validator: ProdaFileValidator): Promise<string> {
		return file.textContent.then( content => {

			const postData = {
				"filename": file.name,
				"content": content,
				"submissionId": submissionId
			};

			let targetUrl = 'success';

			if (validator.fileType !== 'Success') {
				return Promise.reject("Can only process PRODA success files");
			}

			return this.restService.post(API.proda, targetUrl, postData).then( result => {
				this._submissions.invalidate();

				const cache = this.getSubmissionItemCache(submissionId);
				cache.invalidate();

				if (result.success && result.responseId) {
					return Promise.resolve(result.responseId);
				}
				else {
					return Promise.reject("Unknown response");
				}
			});
		});
	}

	public getSubmissionHistory(submissionItemId: string, forceReload?: boolean): Promise<ProdaSubmissionHistory[]> {
		const cache = this.getSubmissionHistoryCache(submissionItemId);

		if (forceReload) {
			cache.invalidate();
		}

		if (cache.valid) {
			return Promise.resolve(cache.items);
		}

		return this.restService.get(API.proda, `submission/history/${submissionItemId}`).then( data => {
			cache.items = data.map( item => {
				return {
					"id": item.id,
					"user": item.staff,
					"name": item.staffName,
					"date": new Date(item.date),
					"comment": item.comment
				};
			});
			return Promise.resolve(cache.items);
		});
	}

	public addHistory(submissionItemId: string, comment: string): Promise<any> {
		const postData = {
			"submissionItemId": submissionItemId,
			"comment": comment
		};

		return this.restService.post(API.proda, 'submission/history', postData).then( data => {

			const cache = this.getSubmissionHistoryCache(submissionItemId);
			cache.invalidate();

			return Promise.resolve();
		});
	}

	public finaliseResponseItem(submissionId, submissionItemId, responseItemId: string, value: boolean): Promise<any> {

		const data = {
			"responseItemId": responseItemId,
			"finalised": value
		};

		return this.restService.put(API.proda, "finalise", data).then( () => {

			const caches = [
				this._submissions,
				this.getSubmissionItemCache(submissionId),
				this.getSubmissionHistoryCache(submissionItemId)
			];

			caches.forEach( cache => {
				cache.invalidate();
			});

			return Promise.resolve();
		});
	}

	public generateABAFile(submissionId: string): Promise<string> {
		return this.restService.get(API.proda, `aba/${submissionId}`).then( result => {
			if (result.success && result.abaFile) {
				return Promise.resolve(result.abaFile);
			}

			return Promise.reject("An error occurred");
		});
	}

	public downloadABAFile(submissionId: string): Promise<string> {
		return this.restService.get(API.proda, `aba/download/${submissionId}`).then( result => {
			if (result.success && result.abaFile) {
				return Promise.resolve(result.abaFile);
			}

			return Promise.reject("An error occurred");
		});
	}

	public lockABAFile(submissionId: string): Promise<boolean|string> {
		return this.restService.get(API.proda, `aba/lock/${submissionId}`).then( result => {
			if (result.success !== undefined) {
				return Promise.resolve(result.success);
			}

			return Promise.reject("An error occurred");
		});
	}

	public getPRODASubmissionFile(submissionId: string): Promise<any> {
		return this.restService.get(API.proda, `submission/file/${submissionId}`).then( result => {
			if (result.success && result.document && result.filename) {
				return Promise.resolve({
					"document": result.document,
					"filename": result.filename
				});
			}

			return Promise.reject("An error occurred");
		});
	}

	private invalidateCaches() {
		this._submissions.invalidate();
		Array.from(this._submissionItems.values()).forEach( cache => cache.invalidate() );
		Array.from(this._submissionHistory.values()).forEach( cache => cache.invalidate() );
	}

	public createExtract(invoiceIds: string[]): Promise<any> {
		const postData = {
			"invoices": invoiceIds
		};

		return this.restService.post(API.proda, `extract`, postData).then( result => {
			if (!!result.success && !!result.extractId && !!result.submissionId) {
				this.invalidateCaches();
				return Promise.resolve(result);
			}

			return Promise.reject("An error occurred");
		});
	}

	public resetSubmission(submissionId: string): Promise<void> {
		return this.restService.delete(API.proda, `${submissionId}`).then( response => {
			if (response.success) {
				this.clearCache();
				return Promise.resolve();
			}

			return Promise.reject(response.message || "An error occurred");
		});
	}

	public reconcileItem(submissionItemId: string, comment?: string): Promise<void> {
		const postData = {
			"comment": comment
		};

		return this.restService.put(API.proda, `reconcile/${submissionItemId}`, postData).then( response => {
			if (response.success) {
				this.clearCache();
				return Promise.resolve();
			}

			return Promise.reject(response.message || "An error occurred");
		});
	}

	public rejectItem(submissionItemId: string, comment?: string, investigationType?: string): Promise<void> {
		const postData = {
			"comment": comment,
			"investigationType": investigationType
		};

		return this.restService.put(API.proda, `reject/${submissionItemId}`, postData).then( response => {
			if (response.success) {
				this.clearCache();
				return Promise.resolve();
			}

			return Promise.reject(response.message || "An error occurred");
		});
	}

	public resubmitItem(submissionItemId: string, quantity: number, rate: number, total: number, comment?: string): Promise<void> {
		const postData = {
			"rate": rate,
			"quantity": quantity,
			"total": total,
			"comment": comment
		};

		return this.restService.put(API.proda, `resubmit/${submissionItemId}`, postData).then( response => {
			if (response.success) {
				this.clearCache();
				return Promise.resolve();
			}

			return Promise.reject(response.message || "An error occurred");
		});
	}

	public resetInvoice(submissionItemId: string): Promise<string> {
		return this.restService.put(API.proda, `reset/${submissionItemId}`, {}).then( response => {
			if (response.success && response.invoiceId) {
				this.clearCache();
				return Promise.resolve(response.invoiceId);
			}

			return Promise.reject(response.message || "An error occurred");
		});
	}

	public resetLineitem(submissionItemId: string, investigationType?: string): Promise<string> {
		const postData = {
			"investigationType": investigationType
		};

		return this.restService.put(API.proda, `resetlineitem/${submissionItemId}`, postData).then( response => {
			if (response.success && response.invoiceId) {
				this.clearCache();
				return Promise.resolve(response.invoiceId);
			}

			return Promise.reject(response.message || "An error occurred");
		});
	}

	public deleteLineItem(submissionItemId: string): Promise<boolean> {
		return this.restService.delete(API.proda, `lineitem/${submissionItemId}`, {}).then( response => {
			if (response.success) {
				this.clearCache();
				return Promise.resolve(true);
			}

			return Promise.reject("Unable to delete line item");
		});
	}
}
