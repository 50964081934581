import { Component, OnInit } from '@angular/core';
import { RestService, API } from "@services/rest.service";
import { OverlayService } from "@services/overlay.service";
import { config } from "../../../config";
import { Settings } from "@classes/settings";

const semverCompare = require('semver/functions/compare')

@Component({
	"selector": "version",
	"styleUrls": ["./versionCheck.component.scss"],
	"templateUrl": "./versionCheck.component.html"
})
export class VersionCheckComponent implements OnInit {

	private static readonly retryIntervalDev = 30000; // Thirty seconds for local/dev
	// private static readonly retryIntervalDev = 10000; // Ten seconds for local/dev
	private static readonly retryIntervalProd = 300000; // Five minutes for prod

	private _table: any = undefined;
	private _newVersionAvailable: boolean = false;

	private get retryInterval(): number {
		return  ['local', 'dev'].includes(config.stage) ? VersionCheckComponent.retryIntervalDev : VersionCheckComponent.retryIntervalProd;
	}

	public get newVersionAvailable(): boolean {
		return this._newVersionAvailable;
	}

	private loadSettings() {
		this.restService.get(API.system, "settings").then( result => {

			const settings = Settings.instance;
			settings.setAll(result);
			this.scheduleLoadSettings();
			this.checkVersion();
		});
	}

	private scheduleLoadSettings() {
		setTimeout( () => { this.loadSettings(); }, this.retryInterval);
	}

	private checkVersion(): void {
		const versionKey: string = "version";
		const settings = Settings.instance;
		this._newVersionAvailable = false;

		if (settings.has(versionKey)) {
			const versionCheck = settings.get(versionKey);

			if (!!versionCheck.versionCheckEnabled && semverCompare(versionCheck.value, config.version) === 1) {
				console.log(`Latest version = ${versionCheck.value}, current version = ${config.version}`);
				this._newVersionAvailable = true;
			}
		}
	}

	constructor(private restService: RestService) {}

	ngOnInit() {
		this.scheduleLoadSettings();
	}

	public loadNewVersion() {
		this._newVersionAvailable = false;
		OverlayService.show();
		location.reload();
	}

	public hide() {
		this._newVersionAvailable = false;
	}
}
