import { Component, OnInit } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { UserType } from "@classes/user";
import { Table, TableColumnHeading, SortType, StaticDataSource } from "@classes/tables";
import { MenuBuilder } from "@services/navmenu.service";
import { ProdaService } from "@services/proda.service";
import { InvoiceService } from "@services/invoice2.service";
import { OverlayService } from "@services/overlay.service";
import { InvoiceHeader } from "@classes/invoices";
import { ErrorUtils } from "@classes/errors";
import { Settings } from '@classes/settings';

@Component({
	"styleUrls": ["./newextract.component.scss"],
	"templateUrl": "./newextract.component.html"
})
export class NewExtractComponent extends PrivateComponent implements OnInit {

	private static readonly defaultMaxLines = 600;

	private selectedInvoices: Map<string, boolean> = new Map<string, boolean>();

	private _dataLoaded: boolean = false;
	private _lockedInvoices: InvoiceHeader[] = [];
	private _working: boolean = false;

	get dataLoaded(): boolean { return this._dataLoaded; }

	get noneSelected(): boolean {
		return this.selectedInvoices.size === 0;
	}

	get busy(): boolean {
		return this._working;
	}

	public isNDSPInvoice(invoice: InvoiceHeader): boolean {
		return invoice.providerId === '99818d9d-faf4-4631-b31f-0e35e0f445ca';
	}

	invoiceIsSelected(invoiceId: string): boolean {
		return this.selectedInvoices.has(invoiceId);
	}

	toggleInvoiceSelection(invoiceId: string) {
		const isSelected = this.invoiceIsSelected(invoiceId);
		this.selectedInvoices.set(invoiceId, !isSelected);
	}

	private readonly tableHeadings: TableColumnHeading[] = [
		{"propName": undefined, "displayName": "", "sortType": SortType.none },
		{"propName": "date", "displayName": "Date", "sortType": SortType.date },
		{"propName": "invoiceNumber", "displayName": "Bill Number", "sortType": SortType.name },
		{"propName": "providerName", "displayName": "Provider", "sortType": SortType.text },
		{"propName": "clientName", "displayName": "Client", "sortType": SortType.name },
		{"propName": "numLines", "displayName": "# Lines", "sortType": SortType.numeric },
		{"propName": "total", "displayName": "Total", "sortType": SortType.numeric }
	];

	public table: Table<InvoiceHeader> = new Table<InvoiceHeader>('lockedinvoices', this.tableHeadings);

	constructor(private prodaService: ProdaService, private invoiceService: InvoiceService) {
		super();

		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Proda', 'PRODA Access');
	}

	private buildMenu(): void {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.done();
	}

	private async loadInvoices(): Promise<void> {
		this._lockedInvoices = await this.invoiceService.getLockedInvoices();
		this.selectMax();
		this.table.sourceData = StaticDataSource.from(this._lockedInvoices);
		return Promise.resolve();
	}

	protected selectAll(): void {
		this.selectedInvoices.clear();
		this._lockedInvoices.forEach( invoice => {
			if (!this.isNDSPInvoice(invoice)) {
				this.selectedInvoices.set(invoice.id, true);
			}
		});
		// this.selectedInvoices = new Map<string, boolean>(this.createSelectionInitData(true));
	}

	protected selectNone(): void {
		// this.selectedInvoices = new Map<string, boolean>(this.createSelectionInitData(false));
		this.selectedInvoices.clear();
	}

	protected selectMax(): void {
		this.selectedInvoices.clear();
		let total = 0;
		const max = this.maxLines;

		this._lockedInvoices.forEach( invoice => {
			if (!this.isNDSPInvoice(invoice) && ((total + invoice.numLines) <= max)) {
				total += invoice.numLines;
				this.selectedInvoices.set(invoice.id, true);
			}
		});
	}

	private loadingComplete() {
		this._dataLoaded = true;
		OverlayService.hide();
	}

	private loadData(): void {
		if (!this.user) {
			return;
		}

		OverlayService.show();
		let promises = [this.loadInvoices()];
		Promise.all(promises).then( () => { this.loadingComplete(); } );
	}

	ngOnInit() {
		super.ngOnInit()

		this.buildMenu();
		this.loadData();
	}

	private async createExtract() {
		// Prevent multiple extracts from being created, eg from an accidental double-click
		if (this._working) {
			return false;
		}

		OverlayService.show();
		this._working = true;
		const selectedInvoiceIds: string[] = [];

		this.selectedInvoices.forEach( (selected, invoiceId) => {
			if (selected) {
				selectedInvoiceIds.push(invoiceId);
			}
		});

		try {
			const result = await this.prodaService.createExtract(selectedInvoiceIds);
			OverlayService.hide();
			this.router.navigate([`/proda/submission/${result.submissionId}`]);
		}
		catch (e) {
			OverlayService.hide();
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "An unknown error occurred"));
		}
		finally {
			this._working = false;
		}
	}

	// private get maxSubmissionItems(): number {
	// 	const settings = Settings.instance;
	// 	if (settings.has('proda')) {
	// 		const value = settings.get('proda') || {};
	// 		return value.maxSubmissionItems || NewExtractComponent.defaultMaxLines;
	// 	}
	// 	return NewExtractComponent.defaultMaxLines;
	// }

	// private countSelectedItems() {

	// 	const maxItems = this.maxSubmissionItems;
	// 	if (this.selectedInvoices.size > maxItems) {

	// 		OverlayService.showDialog("Extract Limit Exceeded", `You have selected too many items for a single extract?<br />Use the first ${maxItems} instead?`, [{
	// 			"text": "No",
	// 			"handler": () => { OverlayService.hide(); }
	// 		}, {
	// 			"text": "Yes",
	// 			"handler": () => {
	// 				this.selectMaxItems(maxItems);
	// 				this.createExtract();
	// 			}
	// 		}]);

	// 	}
	// 	else {
	// 		this.createExtract();
	// 	}
	// }

	// private selectMaxItems(limit: number) {
	// 	let counter = 0;
	// 	this.selectedInvoices.forEach( (selected, invoiceId) => {
	// 		if (counter > limit) {
	// 			this.selectedInvoices.set(invoiceId, false);
	// 		}
	// 		else if (selected) {
	// 			counter++;
	// 		}
	// 	});
	// }

	confirmCreateExtract(): void {
		if (this.busy) {
			return;
		}

		OverlayService.showDialog("Confirm Extract", "Are you sure you want to generate this extract?", [{
			"text": "Cancel",
			"handler": () => { OverlayService.hide(); }
		}, {
			"text": "Create Extract",
			"handler": () => { this.createExtract(); }
		}]);
	}

	public get billCount(): number {
		return this.selectedInvoices.size;
	}

	public get lineCount(): number {
		return this._lockedInvoices.reduce( (acc, cur) => {
			if (this.selectedInvoices.has( cur.id )) {
				acc += cur.numLines;
			}
			return acc;
		}, 0);
	}

	public get maxLines(): number {
		const settings = Settings.instance;
		if (settings.has('proda')) {
			const value = settings.get('proda') || {};
			return value.maxSubmissionItems || NewExtractComponent.defaultMaxLines;
		}
		return NewExtractComponent.defaultMaxLines;
	}

	public get tooManyLines(): boolean {
		return this.lineCount > this.maxLines;
	}
}
