import { Component, OnInit, Input } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { User, UserType } from "@classes/user";
import { InvoiceHeader} from "@classes/invoices";
import { Alignment, Table, TableColumnHeading, SortType, StaticDataSource } from "@classes/tables";
import { InvoiceService } from "@services/invoice2.service";
import { isDevMode } from '@angular/core';
import moment from 'moment';

interface RecentInvoices {
	invoices: any[];
	totalInvoices: number;
	totalPaidInvoices: number;
}

@Component({
	"selector": "client-invoices",
	"styleUrls": ["./recentinvoices.component.scss"],
	"templateUrl": "./recentinvoices.component.html"
})
export class RecentInvoicesComponent extends PrivateComponent implements OnInit {

	private _isSelf: boolean = false;
	private _masqueradeUser: User = undefined;

	private _clientId: string = undefined;
	private _dataLoaded: boolean = false;
	private _totalInvoices: number = 0;

	readonly maxInvoicesToDisplay: number = 10;

	get isDebugging(): boolean {
		return isDevMode();
	}

	model: any = {
		"onlyShowPaidInvoices": false
	};

	@Input()
	set clientId(value: string) {
		this._clientId = value;
		this.loadInvoices();
	}

	get clientId(): string {
		return this._clientId;
	}

	get dataLoaded(): boolean {
		return this._dataLoaded;
	}

	get totalInvoices(): number {
		return this._totalInvoices;
	}

	private readonly tableHeadings: TableColumnHeading[] = [
		{"propName": "date", "displayName": "Bill Date", "sortType": SortType.date },
		{"propName": "paymentDate", "displayName": "Processed Date*", "sortType": SortType.date },
		{"propName": "invoiceNumber", "displayName": "Bill Number", "sortType": SortType.text },
		{"propName": "providerName", "displayName": "Account", "sortType": SortType.text },
		{"propName": "payee", "displayName": "Paid To", "sortType": SortType.text },
		{"propName": "total", "displayName": "Total", "sortType": SortType.numeric, "alignment": Alignment.right },
		{"propName": undefined, "displayName": "", "sortType": SortType.none }
	];

	table: Table<InvoiceHeader> = new Table('clientInvoices', this.tableHeadings);

	get masqueradeUser(): User {
		return this._masqueradeUser;
	}

	get masqueradeUserId(): string {
		return this._masqueradeUser ? this._masqueradeUser.id : "";
	}

	get isSelf(): boolean {
		return this._isSelf;
	}

	get isAdmin(): boolean {
		return this.user.accountType === UserType.Admin;
	}

	get masqueradeUserName(): string {
		return this._masqueradeUser ? `${this._masqueradeUser.firstName} ${this._masqueradeUser.lastName}` : '';
	}

	constructor(private invoiceService: InvoiceService) {
		super();
	}

	private loadInvoices() {
		if (!this.user) {
			return;
		}

		this.invoiceService.getRecentClientInvoices(this._clientId).then( (data: RecentInvoices) => {

			this._totalInvoices = this.isAdmin ? data.totalInvoices : data.totalPaidInvoices;


			// Get the first 10 transactions sorted by date descending
			this.table.sourceData = StaticDataSource.from( data.invoices.sort( (a: InvoiceHeader, b: InvoiceHeader) => {
				return moment(b.date).unix() - moment(a.date).unix();
			}).slice(0, this.maxInvoicesToDisplay) );

			this._dataLoaded = true;

			return Promise.resolve();
		}).catch( err => {

			this._dataLoaded = true;
			console.log(err);

		});
	}

	ngOnInit() {
		super.ngOnInit();
	}
}
