import moment from 'moment';
import { assert } from "@classes/errors";

export class Utils {
	public static trimString(value: string|null|undefined): string {
		return [null, undefined].includes(value) ? value : value.trim();
	}

	public static truncateString(text: string, maxLength: number = 100): string {
		const content = text || '';
		return content.length > maxLength ? content.slice(0, maxLength).split(' ').slice(0, -1).join(' ') + Utils.ellipsis : content;
	}

	public static readonly ellipsis = "…";

	public static nullOrUndefined(value: any): boolean {
		return [null, undefined].includes(value);
	}

	public static optionalString(value: string|undefined|null, defaultValue: string = ''): string {
		return [null, undefined].includes(value) ? defaultValue : value;
	}
}

export class FloatingPointUtils {

	private static validatePrecision(precision: number) {
		assert( Math.log10(precision) === Math.round(Math.log10(precision)),  "Precision must be a power of 10" );
	}

	public static readonly defaultPrecision = 0.01;

	public static equals(a: number, b: number, precision: number = FloatingPointUtils.defaultPrecision): boolean {
		FloatingPointUtils.validatePrecision(precision);
		return Math.round(a / precision) === Math.round(b / precision);
	}

	public static lessThan(a: number, b: number, precision: number = FloatingPointUtils.defaultPrecision): boolean {
		FloatingPointUtils.validatePrecision(precision);
		return Math.round(a / precision) < Math.round(b / precision);
	}

	public static lessThanOrEqualTo(a: number, b: number, precision: number = FloatingPointUtils.defaultPrecision): boolean {
		FloatingPointUtils.validatePrecision(precision);
		return Math.round(a / precision) <= Math.round(b / precision);
	}

	public static greaterThan(a: number, b: number, precision: number = FloatingPointUtils.defaultPrecision): boolean {
		return FloatingPointUtils.lessThan(b, a, precision);
	}

	public static greaterThanOrEqualTo(a: number, b: number, precision: number = FloatingPointUtils.defaultPrecision): boolean {
		return FloatingPointUtils.lessThanOrEqualTo(b, a, precision);
	}
}

export class DateUtils {
	public static readonly defaultFormat = 'YYYY-MM-DD';
	public static readonly ISO8601Format = 'YYYY-MM-DDTHH:mm:ssZ';
	public static readonly ISO8601msFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

	public static clone(src: Date): Date|undefined {
		if (!src) {
			return undefined;
		}

		return new Date(src.valueOf());
	}

	public static toString(value: Date, format: string = DateUtils.defaultFormat) {
		if (!value) {
			return undefined;
		}

		const m = moment(value);
		return m.isValid() ? m.format(format) : undefined;
	}

	public static parse(value: string, format: string = DateUtils.defaultFormat): Date {
		if (!value) {
			return null;
		}

		const m = !!format ? moment(value, format, true) : moment(value);
		return m.isValid() ? m.toDate() : undefined;
	}

	public static parseMoment(value: string, format: string = DateUtils.defaultFormat): moment.Moment {
		if (!value) {
			return null;
		}

		const m = !!format ? moment(value, format, true) : moment(value);
		return m.isValid() ? m : undefined;
	}
}
