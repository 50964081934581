import { Component, OnInit } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { UserType } from "@classes/user";
import { MenuBuilder } from "@services/navmenu.service";
import { PaginationProvider, PaginatedTable, TableColumnHeading, SortType, StaticDataSource } from "@classes/tables";
import { InvoiceHeader, InvoiceStatusUtils, InvoiceStatus, SupportDataType, SupportDataItem } from "@classes/invoices";
import { InvoiceService } from "@services/invoice2.service";
import { OverlayService } from "@services/overlay.service";
import { DataExchangeService } from "@services/dataexchange.service";
import { FreshdeskService } from "@services/freshdesk.service";
import { FilterSettings } from "../investigationFilter/filterSettings";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

class SimplePaginationProvider implements PaginationProvider {
	private _currentPage: number = 1;

	public readonly pageSubject = new Subject<number>();

	public resultsPerPage: number = 100;

	public get currentPage(): number {
		return this._currentPage;
	}

	public set currentPage(value: number) {
		this._currentPage = Math.max(1, value);
		this.pageSubject.next(this._currentPage);
	}

	public get numPages(): number {
		return Math.ceil(this.numResults / this.resultsPerPage);
	}

	public numResults: number;
}

@Component({
	// "selector": "investigations-list",
	"styleUrls": ["./investigations.component.scss"],
	"templateUrl": "./investigations.component.html"
})
export class BillsUnderInvestigationComponent extends PrivateComponent implements OnInit {

	private _dataLoaded: boolean = false;
	private _invoiceSupportData: SupportDataType[] = undefined;
	private _invoices: InvoiceHeader[] = [];

	private _currentPage: number = 1;
	private _resultsPerPage: number = 100;
	protected _pagination = new SimplePaginationProvider();

	private _filterSettings: FilterSettings = {
		"categories": new Set<string>(),
		"search": ""
	};

	private readonly tableHeadings: TableColumnHeading[] = [
		{"propName": "createdByName", "displayName": "Created By", "sortType": SortType.name },
		{"propName": "created", "displayName": "Created Date", "sortType": SortType.date },
		{"propName": "invoiceNumber", "displayName": "Bill Number", "sortType": SortType.name },
		{"propName": "clientName", "displayName": "Client", "sortType": SortType.name },
		{"propName": "providerName", "displayName": "Provider", "sortType": SortType.name },
		{"propName": "investigationTypeName", "displayName": "Category", "sortType": SortType.name },
		{"propName": "total", "displayName": "Total", "sortType": SortType.numeric },
		{"propName": "investigationDate", "displayName": "Investigation Date", "sortType": SortType.date },
		{"propName": "", "displayName": "", "sortType": SortType.none }
	];

	private buildMenu(): void {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.addRoute("plus", "New Bill", "/billing/new");
		menuBuilder.done();
	}

	private invoiceStatus: any = InvoiceStatusUtils.allValues().reduce( (acc, cur) => {
		acc[InvoiceStatusUtils.toPostgresEnum(cur)] = cur;
		return acc;
	}, {});

	public allInvestigationTypes: SupportDataItem[];
	public get filterSettings(): FilterSettings {
		return this._filterSettings;
	}

	private async loadData() {
		this._dataLoaded = false;
		OverlayService.show();
		try {
			await this.getInvoiceSupportData();

			const invTypeSupportDataTypeId: string = "3242c9f5-dcaa-3e85-bad5-77a60532ef04";
			const invType = this._invoiceSupportData.find(sdType => sdType.id === invTypeSupportDataTypeId);

			if (invType) {
				this.allInvestigationTypes = invType.supportData;
				invType.supportData.forEach(supportDataItem => {
					this._filterSettings.categories.add(supportDataItem.id);
				});
			}

			const investigationData = await this.loadInvoices();

		}
		catch (e) {
			console.log(e);
		}
		finally {
			this._dataLoaded = true;
			OverlayService.hide();
		}
	}

	public get currentPage(): number {
		return this._currentPage;
	}

	public set currentPage(value: number) {
		this._currentPage = Math.max(1, value);
		this.loadInvoices();
	}

	private async loadInvoices() {

		const filter = FilterSettings.clone(this._filterSettings);

		if (this.allInvestigationTypes.every( item => filter.categories.has(item.id) )) {
			filter.categories = null;
		}

		const searchText = !!filter ? filter.search : undefined;
		const categories = !!filter && filter.categories ? Array.from(filter.categories) : undefined;
		const pageNum = this._pagination.currentPage;

		const investigationData = await this.invoiceService.investigationBillsList(searchText, categories, this._pagination.currentPage, this._resultsPerPage);

		this._pagination.resultsPerPage = investigationData.resultsPerPage;
		this._pagination.numResults = investigationData.total;

		this.table.sourceData = StaticDataSource.from(investigationData.data);
	}

	readonly table: PaginatedTable<InvoiceHeader> = new PaginatedTable('investigationListTable', this.tableHeadings);

	get dataLoaded(): boolean {
		return this._dataLoaded;
	}

	constructor(private invoiceService: InvoiceService, private freshdeskService: FreshdeskService) {
		super();
		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Billing', 'Access billing');
		this.requirePermission('Billing', 'Read investigations list');
	}

	private invoiceStatusDescription(status: InvoiceStatus): string {
		return InvoiceStatusUtils.toString(status);
	}

	private async getInvoiceSupportData(): Promise<void> {
		this._invoiceSupportData = await this.invoiceService.getInvoiceSupportData();
	}

	ngOnInit() {
		super.ngOnInit();

		if (this.user) {
			this.buildMenu();
			this.loadData();
			this.table.resultsPerPage = this._pagination.resultsPerPage;

			this
				._pagination
				.pageSubject
				.pipe( takeUntil(this.unsubscribe) )
				.subscribe( this.loadInvoices.bind(this) );
		}
	}

	public filter: Set<string>;

	public filterInvestigations() {
		this._currentPage = 1;
		OverlayService.show();
		try {
			this.loadInvoices();
		}
		finally {
			OverlayService.hide();
		}
	}

	public openTicket(event: MouseEvent, invoice: InvoiceHeader) {
		event.preventDefault();
		event.cancelBubble = true;
		window.open(this.freshdeskService.getTicketUrl(invoice.ticketNumber), '_ticketWin');
	}

	public openBill(event: MouseEvent, invoice: InvoiceHeader) {
		const path = `billing/${invoice.id}`;
		DataExchangeService.set(true, "investigations");

		if (event.ctrlKey) {
			const url = this.router.serializeUrl(
				this.router.createUrlTree([path])
			);

			window.open(url, '_blank');
		}
		else {
			this.router.navigate([path]);
		}
	}

	get investigationTypes(): SupportDataItem[] {
		if (!!this._invoiceSupportData) {
			let tag = 'invType';
			let invType: SupportDataType[] = this._invoiceSupportData.filter( (supportDataTypeItem: SupportDataType) => supportDataTypeItem.tag === tag);
			return invType[0].supportData;
		}
	}

}
