import { OnDestroy, Injectable } from '@angular/core';
import { CacheSignalService } from "@services/cachesignal.service";
import { Subscription } from "rxjs";
@Injectable()
export abstract class SignalReceiver implements OnDestroy {
	protected _subscriptions: Subscription[] = [];

	constructor(protected signalService: CacheSignalService) {
		this.setupSubscriptions();
	}

	protected abstract setupSubscriptions();

	ngOnDestroy() {
		this._subscriptions.forEach( subs => {
			subs.unsubscribe();
		});
	}
}
