import { InvoiceLineItem } from "@classes/invoices";
import { Plan, PlanBudget, BudgetType } from "@classes/plans";
import { SupportItem } from "@classes/supports";
import { FloatingPointUtils as FPUtils } from "@classes/utils";
import moment from 'moment';

export interface ValidationResult {
	valid: boolean;
	// warningOnly: boolean;
	// errorMessage?: string;
	errors: LineItemBusinessRule[];
}

export interface BusinessRule {
	warningOnly: boolean;
	errorMessage: string;
}

export class LineItemValidator {

	private getRules(lineItem: InvoiceLineItem, providerId?: string): LineItemBusinessRule[] {

		let result: LineItemBusinessRule[] = [];
		result.push( new DateSet(lineItem) );
		result.push( new DateInThePast(lineItem) );
		result.push( new HasPlan(lineItem, this.plans.get(lineItem)) );

		const support = this.supports.get(lineItem);
		if (support !== undefined) {

			// Don't run the quantity check until a support has been selected
			result.push( new HasQuantity(lineItem) );
			result.push( new RateChecked(lineItem, support) );

			const plan = this.plans.get(lineItem);
			if (plan !== undefined) {

				// Category / Support Item budget checks
				result.push( new HasSupportCategory(lineItem, support, plan) );

				// Specified item check
				result.push( new SpecifiedItem(lineItem, support, plan) );

				// Budget checks
				result.push( new HasBudget(
					lineItem,
					Array.from(this.supports.keys()),
					this.supports,
					plan,
					this.samePlanBudget,
					providerId
				) );
			}
		}

		return result;
	}

	public validate(lineItem: InvoiceLineItem, providerId?: string): ValidationResult {

		const rules = this.getRules(lineItem, providerId);
		const validationResult = {
			"valid": true,
			"warningOnly": true,
			"errors": []
		};

		let result = rules.reduce( (result: ValidationResult, rule: LineItemBusinessRule) => {

			// If a previous rule has already failed, don't process any more
			if (!result.valid) {
				return result;
			}

			if (!rule.valid) {
				result.valid = result.valid && rule.warningOnly;
				result.errors.push(rule);
			}

			return result;
		}, validationResult);

		return result;
	}

	constructor(
		private lineItems: InvoiceLineItem[],
		private supports: Map<InvoiceLineItem, SupportItem>,
		private plans: Map<InvoiceLineItem, Plan>,
		private samePlanBudget: boolean) {
	}
}

abstract class LineItemBusinessRule implements BusinessRule {
	protected _errorMessage: string;
	protected _warningOnly: boolean = true;

	constructor(protected lineItem: InvoiceLineItem, errorMessage?: string) {
		this._errorMessage = errorMessage || "";
	}

	/**
	* Calculates the current spend on this budget
	*/
	protected currentSpend(budget: PlanBudget, allLineItems: InvoiceLineItem[] = [], samePlanBudget: boolean): number {
		// Calculate the current spend on this support item for other invoices
		let result = (budget.paid || 0) + (budget.unknown || 0) + (budget.pending || 0) + (budget.draft || 0);

		if (samePlanBudget) {
			
			const lineItem = allLineItems.find( (item, index, self) => {
				if(item.id === this.lineItem.id) {
					//Find the other line item
					let duplicateIndex = self.findIndex((otherItem, otherIndex) => (otherIndex !== index) && (otherItem.id == item.id));
					//If they have the same total, just return the current one
					if((duplicateIndex !== -1) && (item.total === self[duplicateIndex].total))
						return true;
					else //otherwise, return the item with a different total as this is the old total
						return (duplicateIndex === -1) || (item.total !== this.lineItem.total);
				} else {
					return false;
				}
			}, this);
			if (lineItem && this.lineItem.id !== undefined) {
				result -= (lineItem.total - Number(lineItem.discrepancy || 0));
			}
		}

		return result;
	}

	public abstract get valid(): boolean;

	public get errorMessage(): string {
		return this._errorMessage;
	}

	public get warningOnly(): boolean {
		return this._warningOnly;
	}
}

abstract class FatalLineItemBusinessRule extends LineItemBusinessRule {
	constructor(protected lineItem: InvoiceLineItem, errorMessage?: string) {
		super(lineItem, errorMessage);
		this._warningOnly = false;
	}
}

class DateSet extends FatalLineItemBusinessRule {
	get valid(): boolean {
		return this.lineItem.date !== undefined;
	}

	constructor(protected lineItem: InvoiceLineItem) {
		super(lineItem, "Please enter the service delivery date");
	}
}

class DateInThePast extends FatalLineItemBusinessRule {
	get valid(): boolean {
		return moment(this.lineItem.date).isBefore(moment());
	}

	constructor(protected lineItem: InvoiceLineItem) {
		super(lineItem, "Service delivery date cannot be in the future");
	}
}

class HasPlan extends FatalLineItemBusinessRule {
	get valid(): boolean {
		return this.plan !== undefined;
	}

	constructor(protected lineItem: InvoiceLineItem, protected plan: Plan) {
		super(lineItem, "There is no plan available for this date");
	}
}

class SupportSelected extends FatalLineItemBusinessRule {
	get valid(): boolean {
		return this.support !== undefined;
	}

	constructor(protected lineItem: InvoiceLineItem, protected support?: SupportItem) {
		super(lineItem, "Please select a support item.");
	}
}

/**
* Checks that the plan for the specified line item defines a budget for the line item's support category
*/
class HasSupportCategory extends LineItemBusinessRule {

	get valid(): boolean {
		return this.plan.supportCategories.some( category => category.supportCategory.id === this.support.supportCategoryId );
	}

	constructor(protected lineItem: InvoiceLineItem, private support: SupportItem, private plan: Plan) {
		super(lineItem, "The client's plan does not have a budget for this support category");

		// Uncomment the line below to prevent services in unfunded categories from being added to an invoice
		this._warningOnly = false;
	}
}

class RateChecked extends FatalLineItemBusinessRule {

	get valid(): boolean {
		if (!this.support || !this.support.priceLimit || !this.support.priceControl || !this.lineItem.rate) {
			return true
		}

		const rate = Number(this.lineItem.rate);
		if (isNaN(rate)) {
			return true;
		}

		return FPUtils.lessThanOrEqualTo(rate, this.support.priceLimit);
	}

	constructor(protected lineItem: InvoiceLineItem, private support: SupportItem) {
		super(lineItem);

		const maxRate = Number(support.priceLimit);
		this._errorMessage = `Unit price exceeds maximum allowed rate of $${maxRate.toFixed(2)}`;
		this._warningOnly = false;
	}
}

class SpecifiedItem extends LineItemBusinessRule {
	get valid(): boolean {

		// Find the PlanSupportItem for this support
		const category = this.plan.supportCategories.find( category => category.supportCategory.id === this.support.supportCategoryId );
		if (!category) {
			// Shouldn't get here - we should trigger an earlier warning about no budget for this item
			return true;
		}

		const planSupportItem = category.supportItems.find( item => item.supportItemNumber === this.support.supportItemNumber );
		if (!planSupportItem) {
			// Again, shouldn't to this point due to no budget for the item
			return true;
		}

		return !planSupportItem.specifiedItem;
	}

	constructor(protected lineItem: InvoiceLineItem, private support: SupportItem, private plan: Plan) {
		super(lineItem);

		const maxRate = Number(support.priceLimit);
		this._errorMessage = `Warning! This is a specified support item.`;
	}
}

class HasQuantity extends FatalLineItemBusinessRule {

	get valid(): boolean {
		const quantity = Number(this.lineItem.quantity);
		return !isNaN(quantity) && quantity > 0;
	}

	constructor(protected lineItem: InvoiceLineItem) {
		super(lineItem, `Please enter a quantity for this line item`);
	}
}

class HasBudget extends LineItemBusinessRule {

	protected _warningOnly: boolean = false;

	private setErrorMessage(budget: PlanBudget) {
		switch (budget.budgetType) {
			case BudgetType.supportItem:
				this._errorMessage = 'Insufficient funds in support item budget';
				break;

			case BudgetType.category:
				this._errorMessage = 'Insufficient funds in category budget';
				break;

			case BudgetType.serviceAgreementItem:
				this._errorMessage = 'Insufficient funds in service agreement item budget';
				break;

			case BudgetType.serviceAgreementCategory:
				this._errorMessage = 'Insufficient funds in service agreement category budget';
				break;

			default:
				this._errorMessage = 'Insufficient funds in plan budget';
		}
	}

	get valid(): boolean {

		if (!this.lineItem.total) {
			return true;
		}

		const budget = this.plan.findBudget(this.supports.get(this.lineItem), this.lineItem.date, this.providerId, true);
		this.setErrorMessage(budget);

		if (!budget) {
			return false;
		}
		const currentSpend = this.currentSpend(budget, this.allLineItems, this.samePlanBudget);
		const availableFunds = budget.total - (budget.quarantined || 0);

		// NB: Floating point errors here
		// return (currentSpend + this.lineItem.total - Number(this.lineItem.discrepancy || 0)) <= availableFunds;
		return FPUtils.lessThanOrEqualTo(currentSpend + this.lineItem.total - Number(this.lineItem.discrepancy || 0), availableFunds);
	}


	constructor(
		protected lineItem: InvoiceLineItem,                             // The line item that is being checked
		private allLineItems: InvoiceLineItem[],                         // All (active) line items on the bill
		private supports: Map<InvoiceLineItem, SupportItem>,             // Map of support items, keyed by the line they belong to
		private plan: Plan,
		private samePlanBudget: boolean,                                              // The plan
		private providerId: string) {
		super(lineItem, `Insufficient funds in budget`);
	}
}
