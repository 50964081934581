import { Component, Inject, OnInit, OnDestroy, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { UserService } from '@services/user.service';
import { DataExchangeService } from '@services/dataexchange.service';
import { MRUService } from "@services/mru.service";
import { User, UserType } from './user';
import { ServiceLocator } from '@services/locator.service';
import { Settings } from './settings';
import { Subject } from "rxjs";
import { Permissions, EPermissionsNotLoaded } from "@classes/permissions";
import { config } from "../../config";

/**
* Type alias that references a tuple. Used to identify a permission group and permission name.
*/
type PermissionRef = [string, string];

/**
* Utility class to perform authorisation checks for a given admin user and resource.
* User must possess all specified permissions in order to gain access. If no permissions are
* required, access is granted.
*/
class PermissionManager {

	/**
	* @constructor
	* @param {User} The user requesting authorisation.
	*/
	constructor(private user: User) {}

	/**
	* Performs the permission check.
	* @param {PermissionRef[]} List of permissions that the resource requires
	* @return {Promise<boolean>} A promise that resolves to true if the user is allowed to access the resource, and false otherwise
	*/
	public async hasPermission(permissionsRequired: PermissionRef[]): Promise<boolean> {
		if (permissionsRequired.length === 0) {
			// If no permissions are required for this component, return true
			return true;
		}

		// Ensure permissions list has been loaded from the server
		await Permissions.load();

		// Return true if all of the requested permissions have been assigned to the current user
		return permissionsRequired.every( item => this.user.permissions.includes( Permissions.getPermissionId(item[0], item[1]) ) );
	}
}
@Injectable()
export class PrivateComponent implements OnInit, OnDestroy {

	protected userService: UserService;
	protected router: Router;
	protected mruService: MRUService;
	protected location: Location;
	protected allowedUserTypes: UserType[] = [];

	protected permissionsRequired: PermissionRef[] = [];
	protected unsubscribe: Subject<void> = new Subject<void>();

	protected constructor() {
		this.userService = ServiceLocator.injector.get(UserService);
		this.router = ServiceLocator.injector.get(Router);
		this.location = ServiceLocator.injector.get(Location);
		this.mruService = ServiceLocator.injector.get(MRUService);
	}

	ngOnDestroy() {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}

	protected get user(): User {
		return this.userService.getUser();
	}

	/**
	* Checks that administrators have permissions to access this resource.
	* Redirects to the "Access Denied" page if the admin user does not have permissions.
	* Does not perform auth checks for other user / account types.
	* @return {Promise<void>}
	*/
	private async checkAdminPermissions(): Promise<void> {

		// Abort if there is no currently logged in user.
		if (!this.user) {
			return;
		}

		// Only to auth checks for administrators.
		if (this.user.accountType === UserType.Admin) {
			const permissionManager = new PermissionManager(this.user);
			if (! await permissionManager.hasPermission(this.permissionsRequired)) {

				// Redirect to the "Access Denied" page if not authorised to access the resource
				this.router.navigate(["/accessdenied"]);
			}
		}
	}

	protected requirePermission(groupName: string, permissionName: string) {
		this.permissionsRequired.push( [groupName, permissionName] );
	}

	protected checkAccess(): void {

		if (this.allowedUserTypes.length === 0) {

			// Some pages can be accessed by anyone. However, we may still need to
			// verify that administrators have access to this resource.
			this.checkAdminPermissions();
			return;
		}

		// Find the current user from the user service
		if (this.user === null) {

			// We should never reach this condition, as we will have exited beforehand as the user isn't logged in.
			// However, just for completeness...
			this.router.navigate(["/login"]);
			return;
		}

		// Check that the user is allowed to see the requested component
		if (!this.allowedUserTypes.includes(this.user.accountType)) {
			this.router.navigate(["/accessdenied"]);
		}

		this.checkAdminPermissions();
	}

	private checkMaintenanceMode(): void {
		const maintenanceModeSettingsKey: string = "maintenance";
		const settings = Settings.instance;

		if (settings.has(maintenanceModeSettingsKey)) {
			const maintenanceMode = settings.get(maintenanceModeSettingsKey);
			if (!!maintenanceMode.enabled) {
				this.router.navigate(["/maintenance"]);
			}
		}
	}

	ngOnInit() {
		if (!this.userService.isLoggedIn()) {
			this.router.navigate(["/login"]);
		}

		if (this.userService.passwordChangeRequired()) {
			this.router.navigate(["/password/change"]);
		}

		this.checkAccess();

		DataExchangeService.setPath(this.router.url);

		this.checkMaintenanceMode();
	}

	goBack() {
		this.location.back();
	}

	public hasPermission(groupName: string, permissionName: string): boolean {
		try {
			const permissionId = Permissions.getPermissionId(groupName, permissionName);
			return this.user && this.user.hasPermission(permissionId);
		}
		catch (e) {
			if (e instanceof EPermissionsNotLoaded) {

				// Don't log to the console
				return false;
			}
			else {
				console.log(e);
				return false;
			}
		}
	}
}
