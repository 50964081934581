import { Injectable } from '@angular/core';
import { RestService, API } from './rest.service';
import { Cache } from "@classes/cache";
import { UserService } from "@services/user.service";
import { UserAccountService } from "@services/accounts.service";
import { UserAccount, UserRelationship, UserRelationshipUtils } from "@classes/user";

@Injectable({ "providedIn": 'root' })
export class LinkedUserService {

	private _cache: Map<string, Cache<UserRelationship>> = new Map<string, Cache<UserRelationship>>();

	constructor(private restService: RestService, private userService: UserService, private accountsService: UserAccountService) {}

	private getCache(clientId: string): Cache<UserRelationship> {
		if (!this._cache.has(clientId)) {
			this._cache.set(clientId, new Cache<UserRelationship>());
		}

		return this._cache.get(clientId);
	}

	public getNominees(clientId: string): Promise<UserRelationship[]> {
		const cache = this.getCache(clientId);
		if (cache.valid) {
			return Promise.resolve(cache.items);
		}

		return this.restService.get(API.user, `nominees/${clientId}`).then( data => {
			if (data.success) {
				cache.items = data.nominees.map(UserRelationshipUtils.parse);
				console.log(cache.items);
				return Promise.resolve(cache.items);
			}

			return Promise.reject("Failed to load nominees");
		});
	}

	public async getLinkedClients(userId: string): Promise<UserRelationship[]> {
		const data = await this.restService.get(API.user, `linked-clients/${userId}`);
		return data.clients.map( UserRelationshipUtils.parse );
	}

	public save(relationship: UserRelationship): Promise<UserRelationship> {
		const postData = {
			"relationship": UserRelationshipUtils.toJSON(relationship)
		};

		return this.restService.post(API.user, `nominee`, postData).then( response => {

			const newRelationship = UserRelationshipUtils.parse(response);
			const cache = this.getCache(relationship.clientId);

			// Remove the old user relationship item in the cache, and replace with an up-to-date version
			if (cache && cache.valid) {
				let items = cache.items.filter( r => newRelationship.userId !== r.userId );
				items.push(newRelationship);
				cache.items = items;
			}

			// this.accountsService.replaceCachedNominee(newUser);

			return Promise.resolve(newRelationship);

		});
	}

	public authorise(relationship: UserRelationship, authorise: boolean): Promise<void> {
		const postData = {
			"userId": relationship.userId,
			"clientId": relationship.clientId,
			"authorise": authorise
		};

		return this.restService.post(API.user, `nominee/authorise`, postData).then( () => {
			const cache = this.getCache(relationship.clientId);
			cache.invalidate();
			return Promise.resolve();
		});
	}

	/*
	* Removes the link between a user and a client.
	* The linked user account is not modified / deleted.
	*
	* @param user {UserAccount} The user account that's linked to the client
	* @param relationship {UserRelationship} The relationship between user and client to remove
	* @return {Promise<void>}
	*/
	public unlink(relationship: UserRelationship): Promise<void> {
		const postData = {
			"clientId": relationship.clientId,
			"userId": relationship.userId
		};

		return this.restService.delete(API.user, `nominee/${relationship.clientId}/${relationship.userId}`, postData).then( result => {
			if (result.success) {
				const cache = this.getCache(relationship.clientId);
				cache.invalidate();
				return Promise.resolve();
			}

			return Promise.reject("Unable to unlink nominee");
		});
	}

	/*
	* Marks a linked user as inactive, preventing them from logging in (if they have valid credentials), and
	* removing their relationships to all clients.
	*/
	public delete(user: UserAccount): Promise<void> {
		return this.restService.delete(API.user, `linkeduser/${user.id}`).then( result => {
			if (result.success) {
				this._cache.clear();
				return Promise.resolve();
			}

			return Promise.reject("Unable to delete linked user");
		});
	}
}
