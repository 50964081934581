import { Injectable } from '@angular/core';
import { DBService } from "@services/db.service";
import { Entity } from "@classes/entity";
import { User } from "@classes/user";
import { ReplaySubject, Subscription, PartialObserver } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class MRUService {

	private static mruKey = "clientmru";
	private static maxListSize = 10;

	private _initialised: boolean = false;

	private readonly mru: ReplaySubject<Entity[]> = new ReplaySubject<Entity[]>(1);

	private async getMRU(): Promise<Entity[]> {
		const result = await DBService.nonVolatile.get(MRUService.mruKey);
		this._initialised = true;
		return result || [];
	}

	private async init() {
		const list = await this.getMRU();
		this.mru.next(list);
	}

	public subscribe(observer: PartialObserver<Entity[]>): Subscription {

		const result = this.mru.subscribe(observer);
		if (!this._initialised) {
			this.init();
		}
		return result;
	}

	public async clear() {
		await DBService.nonVolatile.remove(MRUService.mruKey);
		this.mru.next([]);
	}

	public async push(user: User) {
		let currentList: Entity[] = await this.getMRU();

		// If the item we're adding is already in the list, remove it so it can be
		// re-added at the beginning of the list
		const idx = currentList.findIndex( item => item.id === user.id );
		if (idx >= 0) {
			currentList.splice(idx, 1);
		}

		currentList.unshift( {"id": user.id, "name": `${user.lastName}, ${user.firstName}`} );
		currentList = currentList.slice(0, MRUService.maxListSize);

		await DBService.nonVolatile.set(MRUService.mruKey, currentList);
		this.mru.next(currentList);
	}

}
