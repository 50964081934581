import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OverlayService } from '@services/overlay.service';
import { PrivateComponent } from "@classes/private.component";
import { User, UserType } from "@classes/user";
import { ErrorUtils } from "@classes/errors";
import { MenuBuilder } from "@services/navmenu.service";
import { InvoiceService } from "@services/invoice2.service";
import { InvoiceHeader } from '@classes/invoices';
import { Alignment, PaginatedTable, TableColumnHeading, SortType, StaticDataSource } from "@classes/tables";
import moment from 'moment';

@Component({
	"styleUrls": ["./allinvoices.component.scss"],
	"templateUrl": "./allinvoices.component.html"
})
export class AllClientInvoicesComponent extends PrivateComponent implements OnInit {
	private _isSelf: boolean = false;
	private _masqueradeUser: User = undefined;
	private _clientId: string = undefined;
	private _dataLoaded: boolean = false;
	private _allInvoices: InvoiceHeader[] = [];

	public searchTerm: string = '';

	private readonly tableHeadings: TableColumnHeading[] = [
		{"propName": "date", "displayName": "Invoice Date", "sortType": SortType.date },
		{"propName": "paymentDate", "displayName": "Processed Date*", "sortType": SortType.date },
		{"propName": "invoiceNumber", "displayName": "Invoice Number", "sortType": SortType.text },
		{"propName": "account", "displayName": "Account", "sortType": SortType.text },
		{"propName": "payee", "displayName": "Paid To", "sortType": SortType.text },
		{"propName": "total", "displayName": "Total", "sortType": SortType.numeric, "alignment": Alignment.right },
		{"propName": undefined, "displayName": "", "sortType": SortType.none }
	];

	table: PaginatedTable<InvoiceHeader> = new PaginatedTable('clientInvoices', this.tableHeadings);

	get hasInvoices(): boolean {
		return this._allInvoices.length > 0;
	}

	get dataLoaded(): boolean {
		return this._dataLoaded;
	}

	get masqueradeUser(): User {
		return this._masqueradeUser;
	}

	get masqueradeUserId(): string {
		return this._masqueradeUser ? this._masqueradeUser.id : "";
	}

	get isSelf(): boolean {
		return this._isSelf;
	}

	get isParticipant(): boolean {
		return this._masqueradeUser ? this._masqueradeUser.accountType === UserType.Participant : false;
	}

	get isSupportCoordinator(): boolean {
		return this._masqueradeUser ? this._masqueradeUser.accountType === UserType.SupportCoordinator : false;
	}

	get isAdmin(): boolean {
		return this.user.accountType === UserType.Admin;
	}

	get masqueradeUserName(): string {
		return this._masqueradeUser ? `${this._masqueradeUser.firstName} ${this._masqueradeUser.lastName}` : '';
	}

	get ndisNumber(): string {
		return this._masqueradeUser ? this._masqueradeUser.ndisNumber : '';
	}

	get clientId(): string {
		return this._clientId;
	}

	constructor(private invoiceService: InvoiceService, private route: ActivatedRoute) {
		super();
	}

	ngOnInit() {
		super.ngOnInit();

		this.route.params.subscribe( params => {

			if (!this.user) {
				return;
			}

			// Make sure admin users have access to this page
			if (this.user.accountType === UserType.Admin) {
				if (!this.hasPermission('Account Details', 'View Other Account Details')) {
					this.router.navigate(["/accessdenied"]);
					return;
				}
			}

			const userId = params.clientId || this.user.id;
			this._isSelf = userId === this.user.id;

			this._clientId = userId;

			if (this.user) {
				this.userService.loadUser(params.clientId).then( user => {
					this._masqueradeUser = user;
					this.buildMenu();
					this.loadInvoices();
				});
			}
		});

		this.buildMenu();
	}

	private buildMenu(): void {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.done();
	}

	private loadInvoices(): void {
		OverlayService.show();
		this.invoiceService.clientInvoices(this._clientId).then( invoices => {

			this._allInvoices = invoices;
			OverlayService.hide();
			this.table.sourceData = StaticDataSource.from(invoices);
			this._dataLoaded = true;

		}).catch( e => {

			OverlayService.hide();
			this._dataLoaded = true;
			console.log(e);
			OverlayService.showError("Unable to load invoices", ErrorUtils.getErrorMessage(e, "An unknown error occurred") );

		});
	}

	public clearSearch() {
		this.searchTerm = '';
		this.table.sourceData = StaticDataSource.from(this._allInvoices);
	}

	public checkEnterKey($event: KeyboardEvent) {
		if ($event.key === 'Enter') {
			this.doSearch();
		}
	}

	public doSearch() {
		if (this.searchTerm != '') {
			const term = this.searchTerm.toLowerCase();

			const filtered = this._allInvoices.filter( header => {
				const searchItems = [
					header.providerName || '',
					header.invoiceNumber || '',
					header.invoiceNumber || '',
					header.comment || '',
					moment(header.date).format('DD/MM/YYYY')
				];
				return searchItems.some( item => item.toLowerCase().includes( term ) );
			});

			this.table.sourceData = StaticDataSource.from(filtered);
		}
		else {
			this.table.sourceData = StaticDataSource.from(this._allInvoices);
		}
	}
}
