import { BillingScheduleUtils, BillingSchedule, BillingItem, BillingScheduleItem } from "./billing";
import { Plan } from "./plans";
import { SupportItem } from "./supports";

import { PlanService }    from "@services/plan.service";
import { OverlayService } from "@services/overlay.service";
import { BillingService } from "@services/billing.service";
import { SupportsService } from "@services/supports.service";
import { ServiceLocator } from "@services/locator.service";
import { EInvalidArgument } from "@classes/errors";
import moment from 'moment';

export class BillingScheduleManager {

	private billingService: BillingService;
	private planService: PlanService;
	private supportsService: SupportsService;

	private _plan: Plan;
	private _schedule: BillingSchedule;
	private _supports: SupportItem[];
	private _numMonthsInPlan: number;
	private _dataLoaded: boolean = false;

	set plan(value: Plan) {
		this._plan = value;
		// this._supports = undefined;
		this.calcNumberOfMonthsInPlan();
		if (!this._plan.id) {
			this._dataLoaded = true;
		}
		else {
			this._dataLoaded = false;

			const promises = [
				this.loadBillingSchedule(),
				this.loadSupports()
			];

			Promise.all(promises).then( () => {
				this._dataLoaded = true;
			});
		}
	}

	get schedule(): BillingSchedule {
		return this._schedule;
	}

	get hasSchedule(): boolean {
		return this._schedule !== undefined;
	}

	get dataLoaded(): boolean {
		return this._dataLoaded;
	}

	constructor() {
		this.billingService = ServiceLocator.injector.get(BillingService);
		this.planService = ServiceLocator.injector.get(PlanService);
		this.supportsService = ServiceLocator.injector.get(SupportsService);
	}

	private calcNumberOfMonthsInPlan() {
		const planStart = moment(this._plan.startDate);
		const planEnd = moment(this._plan.endDate);
		this._numMonthsInPlan = Math.ceil(planEnd.diff(planStart, 'months', true));
	}

	private async loadSupports(): Promise<void> {
		if (!this._supports) {
			this._supports = await this.supportsService.getSupportsFor(this._plan.region, this._plan.startDate);
		}
	}

	private async loadBillingSchedule(): Promise<void> {
		if (!this._plan || !this._plan.id) {
			throw new Error("Missing plan, cannot load billing schedule");
		}

		try {
			this._schedule = await this.billingService.getBillingSchedule(this._plan);
		}
		catch (e) {
			this._schedule = undefined;
		}
	}

	public async createBillingSchedule(): Promise<void> {

		try {
			// await this.loadSupports();
			this._schedule = await this.billingService.newSchedule(this._plan);
		}
		catch (e) {
			if (e instanceof EInvalidArgument) {
				OverlayService.showError("Error", e.message);
			}
			else {
				console.log(e);
			}
		}
	}

	public removeBillingItem(idx: number): void {

		// Work on a clone of the items list, otherwise a strange error appears, caused by UI and ngModel
		let items = this._schedule.items.map( (x: any) => x.clone() );

		if ((idx > -1) && (idx < items.length)) {
			items.splice(idx, 1);
			this._schedule.items = items;
		}
	}

	public canAddSetupFee(): boolean {
		if (!this._schedule) {
			return false;
		}

		return true;
		// return this._schedule.items.every( item => !(item instanceof SetupFee) );
	}

	public canAddManagementFee(): boolean {
		if (!this._schedule || !this._plan) {
			return false;
		}

		return true;
	}

	public get canShuffleDays(): boolean {
		return !!this._schedule && !!this._plan;
	}

	public checkSupportItem(item: BillingItem) {

		if (!item.date) {
			return;
		}

		const support = this._supports.find( support => support.supportItemNumber === item.supportItemNumber );
		item.supportItemId = !!support ? support.id : undefined;
	}

	public addSetup() {
		if (!this.canAddSetupFee()) {
			return;
		}

		const setupItem = this._supports.find( support => support.supportItemNumber === BillingScheduleUtils.planManagementSupportItems.setup );

		// Work on a clone of the items list, otherwise a strange error appears, caused by UI and ngModel
		let items = this._schedule.items.map( (x: any) => x.clone() );

		items.push( BillingScheduleUtils.createSetupFee(this._plan, setupItem) );
		this.sortBillingItemsByDate(items);
		this._schedule.items = items;
	}

	private sortBillingItemsByDate(items: BillingItem[]): void {
		items.sort( (a, b) => {
			if (a.date && b.date) {
				return a.date.valueOf() - b.date.valueOf();
			}

			if (!a.date && !b.date) {
				return 0;
			}

			return 1;
		});
	}

	public addManagementFee() {
		if (!this.canAddManagementFee()) {
			return;
		}

		const monthlyFeeItem = this._supports.find( support => support.supportItemNumber === BillingScheduleUtils.planManagementSupportItems.monthlyFee );

		// Work on a clone of the items list, otherwise a strange error appears, caused by UI and ngModel
		let items = this._schedule.items.map( (x: any) => x.clone() );
		items.push( BillingScheduleUtils.createManagementFee(this._plan, undefined, monthlyFeeItem ) );
		this.sortBillingItemsByDate(items);
		this._schedule.items = items;
	}

	public itemInfo(item: BillingItem): string {
		if (this._supports) {

			const supportItem = this._supports.find( support => support.supportItemNumber === item.supportItemNumber );
			if (!supportItem || !item.amount || item.amount < 0) {
				return;
			}

			const itemAmount = Number(item.amount) || 0;
			if (supportItem.priceControl && supportItem.priceLimit && itemAmount < supportItem.priceLimit) {
				const max = supportItem.priceLimit.toFixed(2);
				return `Maximum price for this service item is $${max}`;
			}
		}
	}

	public itemError(item: BillingItem): string {
		if (!item.date) {
			return "Please enter a service date";
		}

		const d = moment(item.date);
		if (!d.isValid()) {
			return "Please enter a valid service date";
		}

		if (d.isBefore(moment(this._plan.startDate))) {
			return "Service date cannot be before plan start date";
		}

		if (d.isAfter(moment(this._plan.endDate))) {
			return "Service date cannot be after plan end date";
		}

		if (!item.supportItemId) {
			return "Unable to locate support item";
		}

		if (!item.amount || item.amount < 0) {
			return "Please enter the service fee";
		}

		if (this._supports) {
			const support = this._supports.find( supportItem => supportItem.supportItemNumber === item.supportItemNumber );
			if (!support) {
				return "Unable to locate support item";
			}

			const itemAmount = Number(item.amount) || 0;
			if (support.priceControl && support.priceLimit && itemAmount > support.priceLimit) {
				const max = support.priceLimit.toFixed(2);
				return `Amount exceeds maximum price of $${max} for this service item`;
			}
		}
	}

	public async saveBillingSchedule() {
		this._schedule = await this.billingService.save(this._schedule, this._supports);
	}

	public subtractDay() {
		if (!this.canShuffleDays) {
			return
		}

		this._schedule.items.forEach( (item: BillingScheduleItem) => item.subtractDay() );
	}

	public addDay() {
		if (!this.canShuffleDays) {
			return
		}

		this._schedule.items.forEach( (item: BillingScheduleItem) => item.addDay() );
	}
}
