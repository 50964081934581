import { Component, OnInit } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { UserType } from "@classes/user";
import { MenuBuilder } from "@services/navmenu.service";
import { Table, TableColumnHeading, SortType, StaticDataSource } from "@classes/tables";
import { InvoiceHeader, InvoiceStatusUtils, InvoiceStatus, SupportDataType, SupportDataItem } from "@classes/invoices";
import { InvoiceService } from "@services/invoice2.service";
import { OverlayService } from "@services/overlay.service";
import { DataExchangeService } from "@services/dataexchange.service";
import { FreshdeskService } from "@services/freshdesk.service";
import { ErrorUtils } from "@classes/errors";

@Component({
	// "selector": "importedbills-list",
	"styleUrls": ["./importedbills.component.scss"],
	"templateUrl": "./importedbills.component.html"
})
export class ImportedBillsComponent extends PrivateComponent implements OnInit {

	private _dataLoaded: boolean = false;
	private _ready: boolean = false;

	private lastSavedInvoice: string = undefined;
	private _invoiceSupportData: SupportDataType[] = undefined;
	private _invoices: InvoiceHeader[] = [];

	private readonly tableHeadings: TableColumnHeading[] = [
		{"propName": "created", "displayName": "Created Date", "sortType": SortType.date },
		{"propName": "invoiceNumber", "displayName": "Bill Number", "sortType": SortType.name },
		{"propName": "clientName", "displayName": "Client", "sortType": SortType.name },
		{"propName": "providerName", "displayName": "Provider", "sortType": SortType.text },
		{"propName": "total", "displayName": "Total", "sortType": SortType.numeric },
		{"propName": "ticketNumber", "displayName": "Ticket", "sortType": SortType.numeric },
		{"propName": "", "displayName": "", "sortType": SortType.none }
	];

	private buildMenu(): void {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.done();
	}

	private invoiceStatus: any = InvoiceStatusUtils.allValues().reduce( (acc, cur) => {
		acc[InvoiceStatusUtils.toPostgresEnum(cur)] = cur;
		return acc;
	}, {});

	private _searchActive: boolean = false;

	public allInvestigationTypes: SupportDataItem[];
	public _highlightLine: string = null;
	public searchText: string = '';

	public get searchActive(): boolean {
		return this._searchActive;
	}

	public get emptySearchPhrase(): boolean {
		return !this.searchText || !this.searchText.trim();
	}

	public get ready(): boolean {
		return this._ready;
	}


	private async loadData(): Promise<void> {
		this._dataLoaded = false;

		this._invoices = await this.invoiceService.importedBillsList(this.searchText);

		this.table.sourceData = StaticDataSource.from(this._invoices);
		this.lastSavedInvoice = DataExchangeService.get('lastSavedInvoice');
		this._dataLoaded = true;
		this._ready = true;
	}

	readonly table: Table<InvoiceHeader> = new Table('predraftInvoices', this.tableHeadings);

	get dataLoaded(): boolean {
		return this._dataLoaded;
	}

	constructor(private invoiceService: InvoiceService, private freshdeskService: FreshdeskService) {
		super();
		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Billing', 'Access billing');
		this.requirePermission('Billing', 'Read investigations list');
	}

	private invoiceStatusDescription(status: InvoiceStatus): string {
		return InvoiceStatusUtils.toString(status);
	}

	private async getInvoiceSupportData(): Promise<void> {
		this._invoiceSupportData = await this.invoiceService.getInvoiceSupportData();
	}

	ngOnInit() {
		super.ngOnInit();

		if (this.user) {
			this.buildMenu();
			this.loadData();
		}
	}

	public openTicket(event: MouseEvent, invoice: InvoiceHeader) {
		event.preventDefault();
		event.cancelBubble = true;
		window.open(this.freshdeskService.getTicketUrl(invoice.ticketNumber), '_ticketWin');
	}

	public openBill(event: MouseEvent, invoice: InvoiceHeader) {
		const path = `billing/${invoice.id}`;

		if (event.ctrlKey) {
			const url = this.router.serializeUrl(
				this.router.createUrlTree([path])
			);

			window.open(url, '_blank');
		}
		else {
			this.router.navigate([path]);
		}
	}

	protected async allocateInvoice(invoiceId: string) {
		this._highlightLine = invoiceId.substring(2, 6);
		OverlayService.show();
		try {
			await this.invoiceService.allocateInvoice(invoiceId);
			OverlayService.hide();
			this.router.navigate([`/billing/${invoiceId}`]);
		}
		catch (e) {
			console.log(e);
			OverlayService.hide();
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "Unable to allocate this to you - someone else probably took it."));
		}
	}

	public async doSearch() {
		this._searchActive = true;
		try {
			this.loadData();
			this._searchActive = false;
		}
		catch (e) {
			this._searchActive = false;
			console.log(e);
		}
	}

	public clearSearch() {
		this.searchText = '';
		this.doSearch();
	}
}
