export enum PlanStatus {
	current, expired, proposed, deceased, terminated
}

export namespace PlanStatus {
	const descriptions = new Map<PlanStatus, string>([
		[PlanStatus.current, "Current"],
		[PlanStatus.expired, "Expired"],
		[PlanStatus.proposed, "Draft"],
		[PlanStatus.deceased, "Deceased"],
		[PlanStatus.terminated, "Terminated"]
	]);

	const pgEnumValues = new Map<PlanStatus, string>([
		[PlanStatus.current, "current"],
		[PlanStatus.expired, "expired"],
		[PlanStatus.proposed, "proposed"],
		[PlanStatus.deceased, "deceased"],
		[PlanStatus.terminated, "terminated"]
	]);

	const values = new Map<string, PlanStatus>([
		["current", PlanStatus.current],
		["expired", PlanStatus.expired],
		["proposed", PlanStatus.proposed],
		["deceased", PlanStatus.deceased],
		["terminated", PlanStatus.terminated]
	]);

	export function toString(status: PlanStatus): string {
		return descriptions.get(status);
	}

	export function parse(value: string): PlanStatus {
		return values.get(value);
	}

	export function toPostgresEnum(value: PlanStatus): string {
		return pgEnumValues.get(value);
	}

	export function allValues(): PlanStatus[] {
		return Array.from(descriptions.keys());
	}
}
