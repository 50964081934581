import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@services/user.service';
import { Utils } from '@classes/utils';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';

@Component({
	"selector": "app-login",
	"templateUrl": "./login.component.html",
	"styleUrls": ["./login.component.scss"]
})

export class LoginComponent implements OnInit {

	faEyeSlash = faEyeSlash;
	faEye = faEye;

	private _hidePassword: boolean = true;

	get hidePassword(): boolean {
		return this._hidePassword;
	}

	model = {
		"username": "",
		"password": ""
	}

	constructor(private userService: UserService, private router: Router) {
	}

	ngOnInit() {
		if (this.userService.isLoggedIn()) {
			this.router.navigate(["/dashboard"])
		}
	}

	doLogin(): void {
		const username = this.model.username || "";
		this.userService.login(Utils.trimString(username.toLowerCase()), Utils.trimString(this.model.password));
	}

	toggleHidePassword(): void {
		this._hidePassword = !this._hidePassword;
	}
}
