import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrivateComponent } from "@classes/private.component";
import { MenuBuilder } from "@services/navmenu.service";
import { User, RelationshipType, UserRelationship, UserRelationshipUtils } from "@classes/user";
import { LinkedUserService } from "@services/linkedusers.service";
import { assert } from "@classes/errors";
import { RelationshipDescription } from "@classes/relationshipDescription";
import { Utils, DateUtils } from "@classes/utils";
import { OverlayService } from "@services/overlay.service";
import { ErrorUtils } from "@classes/errors";
import moment from 'moment';

@Component({
	"styleUrls": ["./nominee.component.scss"],
	"templateUrl": "./nominee.component.html"/*,
	"encapsulation": ViewEncapsulation.None*/ // Required to allow style change on the ngxTypeahead child component that's embedded in this one
})
export class NomineeComponent extends PrivateComponent implements OnInit {

	private static dateFormat = 'DDMMYYYY';
	private _dataLoaded: boolean = false;
	private _clientId: string;
	private _relationship: UserRelationship;
	private _isNew: boolean = false;
	private _client: User;

	get dataLoaded(): boolean {
		return this._dataLoaded;
	}

	constructor(private route: ActivatedRoute, private linkedUserService: LinkedUserService) {
		super();
	}

	ngOnInit() {
		super.ngOnInit();
		if (this.user) {
			this.buildMenu();
			this.route.params.subscribe( this.loadData.bind(this) );
		}
	}

	private async loadData(params) {
		this._dataLoaded = false;
		try {
			this._clientId = params.clientId;
			this._client = await this.userService.loadUser(this._clientId);

			this._isNew = params.userId === "new";

			if (this._isNew) {
				this._relationship = UserRelationshipUtils.create();
				this._relationship.clientId = this._clientId;
				this._relationship.type = RelationshipType.nominee;
			}
			else {
				const relationships = await this.linkedUserService.getNominees(this._clientId);
				this._relationship = relationships.find( item => item.userId === params.userId );
				assert(!!this._relationship);
				if (this._relationship.validFrom) {
					this.fromDate =  moment(this._relationship.validFrom).format(NomineeComponent.dateFormat);
				}
				if (this._relationship.validTo) {
					this.toDate =  moment(this._relationship.validTo).format(NomineeComponent.dateFormat);
				}
			}
		}
		catch (e) {
			console.log(e);
		}
		finally {
			this._dataLoaded = true;
		}
	}

	private buildMenu(): void {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.done();
	}

	public allDescriptions: () => RelationshipDescription[] = RelationshipDescription.allValues;
	public descriptionName: (description: RelationshipDescription) => string = RelationshipDescription.toString;

	public get isNew(): boolean {
		return this._isNew;
	}

	public get model(): UserRelationship {
		return this._relationship;
	}


	public bsb(): string {
		return this.model.bankInfo && this.model.bankInfo.bsb ? this.model.bankInfo.bsb : '';
	}

	public accountNumber(): string {
		return this.model.bankInfo && this.model.bankInfo.accountNumber ? this.model.bankInfo.accountNumber : '';
	}

	public get isAuthorised(): boolean {
		return this.model.type === RelationshipType.authorised_representative;
	}

	public get clientName(): string {
		if (!this._client) {
			return "";
		}

		return `${this._client.firstName} ${this._client.lastName}`;
	}

	public get ndisNumber(): string {
		if (!this._client) {
			return "";
		}

		return this._client.ndisNumber;
	}

	// public save() {
	// 	// Stub
	// }

	public setUser(user: User) {
		if (user) {
			this.model.userId = user.id;
			this.model.firstName = user.firstName;
			this.model.lastName = user.lastName;
			this.model.email = user.email;
			this.model.phone = user.phone;
		}
	}

	protected setDateFrom() {
		this.model.validFrom = DateUtils.parse(this.fromDate, 'DDMMYYYY');
	}

	protected setDateTo() {
		this.model.validTo = DateUtils.parse(this.toDate, 'DDMMYYYY');
	}

	public get canSave(): boolean {
		if (this.isNew) {
			// New users must have a first name, last name and description specified at a minimum
			return [this.model.firstName, this.model.lastName].map( Utils.trimString ).every( item => !!item ) && this.model.description !== undefined;
		}
		else {
			// Existing users just need to have the description of the relationship to the client
			return [this.model.userId].map( Utils.trimString ).every( item => !!item ) && this.model.description !== undefined;
		}
	}

	protected async saveNominee() {
		OverlayService.show();
		try {
			const savedRelationship = await this.linkedUserService.save(this.model);
			// if (this.isNew) {
			// 	this.router.navigate(['/relationship', this._clientId, savedRelationship.userId]);
			// 	OverlayService.hide();
			// }
			// else {
			// 	await this.loadData({"userId": savedRelationship.userId, "clientId": this._clientId});
			// 	OverlayService.hide();
			// }
			this.router.navigate(['/account', this._clientId]);
		}
		catch (e) {
			console.log(e);
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "An unknown error occurred"));
		}
	}

	public clearSelectedUser() {
		this.model.firstName = undefined;
		this.model.lastName = undefined;
		this.model.email = undefined;
		this.model.phone = undefined;
		this.model.bankInfo = undefined;
		this.model.userId = undefined;
	}

	public typeaheadSearchText: string = '';
	public fromDate: string = '';
	public toDate: string = '';
	public existingUser: boolean = true;
}
