import { Component, Input, Output, EventEmitter, OnInit, ElementRef } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { FileNoteStatus, FileNoteStatusUtils, UpdateNotesComponent } from "@classes/filenotes";
import { FileNotesService } from "@services/filenotes.service";
import { OverlayService } from "@services/overlay.service";
import { UserAccount } from '@classes/user';
import { UserAccountService } from "@services/accounts.service";
import moment from 'moment';

@Component({
	"selector": "update-notes-dialog",
	"styleUrls": ["./updatenotesdialog.component.scss"],
	"templateUrl": "./updatenotesdialog.component.html"
})
export class UpdateNotesDialogComponent extends PrivateComponent implements OnInit {

	private _model:UpdateNotesComponent = UpdateNotesComponent.empty();

	protected _adminUsers: UserAccount[] = [];
	
	protected reminderDateStr: string;

	protected allStatus: FileNoteStatus[] = FileNoteStatusUtils.allValues();
	protected statusDescription(status: FileNoteStatus): string {
		return FileNoteStatusUtils.toString(status);
	}

	get ownersList(): UserAccount[] {
		return this._adminUsers.filter( owner => !!owner.id );
	}

	get model(): UpdateNotesComponent {
		return this._model;
	}

	@Output()
	onUpdateNotes = new EventEmitter<UpdateNotesComponent>();

	@Input()
	set notes(value: Object[]) {
		if (!!value && value.length > 0) {
			this.model.notes = [...value];
		}
	}

	// Start initialisation section ---------------------------------------------
	constructor(private accountsService: UserAccountService, private fileNotesService: FileNotesService) {
		super();
	}

	ngOnInit() {
		super.ngOnInit()

		this.accountsService.getAdminAccounts(true).then( accounts => {
			this._adminUsers = accounts;
		});
	}

	ngAfterViewInit() {

	}
	// End initialisation section -----------------------------------------------

	public closeDialog(emitEvent: boolean = false) {
		if (emitEvent) {
			this.onUpdateNotes.emit(this.model);
		}
		OverlayService.hide();
	}

	public updateNotes() {
		this.closeDialog(true);
	}

	get canSave(): boolean {
		return this.model.assignedTo.id !== undefined || this.model.reminder !== undefined || this.model.status !== undefined;
	}

	private parseDateStr(value: string): Date {
		try {
			const m = moment(value, 'DDMMYYYY', true);
			return m.isValid() ? m.startOf('day').toDate() : undefined;
		}
		catch (e) {
			return undefined;
		}
	}

	protected setReminderDate() {
		this._model.reminder = this.parseDateStr(this.reminderDateStr);
	}

	protected assignedToChanged($event) {
		const admin = this._adminUsers.find( user => user.id === $event);
		if (admin) {
			this._model.assignedTo.name = admin.name;
		}
	}

	// private ownerChanged($event) {
	// 	const admin = this._adminUsers.find( user => user.id === $event);
	// 	if (admin) {
	// 		this._model.owner.name = admin.name;
	// 	}
	// }

	/**
	* Sets the focus to the specified UI form field. Cancels event propagation from a keyboard event if supplied.
	* Initially coded to handle keyboard shortcuts to allow quick focus of controls.
	*
	* @param {ElementRef|any} control The UI control to focus
	* @param {KeyboardEvent} event Optional keyboard event that will have propagation terminated
	*/
	private focusControl(control: ElementRef|any, event?: KeyboardEvent) {
		if (event) {
			this.cancelEvent(event);
		}

		if (control.nativeElement) {
			setTimeout(() => { control.nativeElement.focus(); }, 0);
		}
		else {
			setTimeout(() => { control.focus(); }, 0);
		}
	}

	/**
	* Stops propagation and default behaviour of a keyboard event
	* Used to intercept keyDown events on the host, and prevent the browser from applying default behaviour
	* (eg Ctrl+R should not reload the page)
	*
	* @param {KeyboardEvent} event
	*/
	private cancelEvent(event: KeyboardEvent): void {
		event.stopImmediatePropagation();
		event.stopPropagation();
		event.preventDefault();
	}

}
