import { Subject } from 'rxjs';
import { FileMetaData, FileType, AttachedFile, FileMimeTypes, FileDownloader } from "./files";
import { FileUploader } from 'ng2-file-upload';
import { OverlayService } from "@services/overlay.service";
import { ErrorUtils } from "./errors";
import { saveAs } from 'file-saver';
import { AttachmentType } from "@classes/attachmentType";
import { IconName } from '@fortawesome/free-solid-svg-icons';

export interface FileChanges {
	newFiles: AttachedFile[];
	changedTypes: AttachedFile[];
	deletedFiles: string[];
}

export class FileManager implements FileChanges {

	private _attachedFiles: AttachedFile[] = [];
	private _deletedFiles: Set<string> = new Set<string>(); // Set of attachment ids. Used to store a record of saved files that we want to remove from the server
	private mimeTypes: FileMimeTypes;
	private downloadService: FileDownloader;

	public dropZoneOver: boolean = false;

	private fileAdded = new Subject<AttachedFile>();
	private fileRemoved = new Subject<AttachedFile>();
	private _originalTypes: Map<string, AttachmentType> = new Map<string, AttachmentType>();

	readonly fileAdded$ = this.fileAdded.asObservable();
	readonly fileRemoved$ = this.fileRemoved.asObservable();

	public fileUploader: FileUploader = new FileUploader({url: undefined});

	constructor(fileList: AttachedFile[], downloadService: FileDownloader, ...allowedFormats: FileType[]) {
		this._attachedFiles = fileList || [];
		this._attachedFiles.forEach( file => {
			if (file.id) {
				this._originalTypes.set(file.id, file.attachmentType);
			}
		});

		this.downloadService = downloadService;
		this.mimeTypes = new FileMimeTypes(...allowedFormats);
	}

	set attachedFiles(value: AttachedFile[]) {
		this._attachedFiles = value;
	}

	get attachedFiles(): AttachedFile[] {
		return this._attachedFiles;
	}

	get count(): number {
		return this._attachedFiles.length;
	}

	get newFiles(): AttachedFile[] {
		return this._attachedFiles.filter( file => file.id === undefined ) as AttachedFile[];
	}

	get deletedFiles(): string[] {
		return Array.from(this._deletedFiles);
	}

	get changedTypes(): AttachedFile[] {
		return this._attachedFiles.filter( file => file.id !== undefined && file.attachmentType !== this._originalTypes.get(file.id) );
	}

	fileOverBase(event): void {
		this.dropZoneOver = event;
	}

	getIconFromMimeType(file: FileMetaData): IconName {
		return this.mimeTypes.getIcon(file.mimeType) as IconName;
	}

	async fileDropped(droppedFiles: FileList|File[]): Promise<void> {
		if (droppedFiles.length > 0) {
			const file = droppedFiles[0];

			if (this.mimeTypes.isAllowed(file.type)) {

				const newFile = await AttachedFile.fromFile(file);
				this._attachedFiles.push( newFile );
				this.fileAdded.next(newFile);

			}
			else {
				OverlayService.showError("Error", "Cannot upload this file type");
			}
		}
	}

	removeFile($event: MouseEvent, file: AttachedFile): void {
		if ($event) {
			$event.stopPropagation();
		}

		this.fileRemoved.next(file);

		if (file.id) {
			// If the file has an id (ie it's already been saved on the server), we need to make note of it here so that it can
			// be removed from the server when the user hits the "save" button
			this._deletedFiles.add(file.id);
			this._originalTypes.delete(file.id);
		}

		// Remove the file from the UI.
		const idx = this._attachedFiles.findIndex( item => item.md5 === file.md5 );
		if (idx >= 0) {
			const removed = this._attachedFiles.splice(idx, 1);
		}
	}

	private static dataURItoBlob(dataUrl: string): Promise<Blob> {
		return fetch(dataUrl).then(res => res.blob());
	}

	public downloadFile(file: AttachedFile, clientId?: string): void {
		FileManager.downloadFile(this.downloadService, file, clientId);
	}


	/**
	* OK - pay attention now, as this one is a little complicated...
	* We'd previously been uploading all files to S3 as Base64 encoded strings. However, with the transfer
	* of files from Salesforce, we now have a mixture of Base64 and unencoded files. The attachments lambda
	* service has been modified to return a buffer (instead of a Base64 encoded string), and we need to do some
	* conversion and testing at this end to figure out how to handle the file.
	*/
	public static downloadFile(downloadService: FileDownloader, file: AttachedFile, clientId?: string): void {
		if (!file || !file.id) {
			return;
		}

		OverlayService.show();
		downloadService.loadAttachment(file.id).then( bytes => {

			// Get the raw bytes for the file
			// const bytes = new Uint8Array(content, 0, content.data.length);

			// Make the assumption that the downloaded file is longer than 5 bytes... No doubt this will come back to bite me later...

			const dataPrefix = [100, 97, 116, 97, 58]; // ASCII codes for "data:" prefix for base64 encoded data
			const first5Bytes = new Uint8Array(bytes.slice(0, 5));
			const mightBeBase64 = dataPrefix.reduce( (acc, cur, idx) => {
				acc = acc && cur === first5Bytes[idx];
				return acc;
			}, true);

			// If the file doesn't start with "data:", then we've not code a URL encoded string... Just display the file
			if (!mightBeBase64) {
				return new Blob([bytes], {"type": file.mimeType});
			}

			// Convert to a string, so that we can verify it's a data-URL base64 encoded file
			// TextDecoder requires TypeScript >= 2.8
			const contentString = new TextDecoder("utf-8").decode(bytes);

			// 1) File should start with a URL encoded "data:[mimeType];base64," header. If this isn't present, it's an unencoded file
			const dataRe = /^data:.*;base64,/;
			if (!dataRe.test(contentString)) {

				// If this test fails, we're probably looking at an unencoded file
				return new Blob([bytes], {"type": file.mimeType});
			}

			// 2) Base64 data can only contain [a-z,0-9,+,/] and be padded with = at the end of the file
			// This test was removed because it caused a stack overflow on larger documents.
			// const base64Re = /^data:.*;base64,([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/;
			// if (!base64Re.test(contentString)) {

			// 	// If this test fails, we're probably looking at an unencoded file
			// 	return new Blob([bytes], {"type": file.mimeType});
			// }

			// We're probably looking at a Base64 encoded file
			return FileManager.dataURItoBlob(contentString);

		}).then( blob => {

			OverlayService.hide();

			// Offer to either save or open the file for images and PDFs
			if ([FileType.image, FileType.pdf].includes(file.type)) {

				OverlayService.showDialog("Download attachment", "Do you want to open this file in a new window, or save to your device?", [{
					"text": "Open in new window",
					"handler": () => {
						const blobUrl = URL.createObjectURL(blob);
						window.open(blobUrl, "_blank");
						OverlayService.hide();
					}
				}, {
					"text": "Save file",
					"handler": () => {
						OverlayService.hide();
						saveAs(blob, file.name);
					}
				}]);
			}
			else {
				OverlayService.hide();
				saveAs(blob, file.name);
			}

		}).catch( err => {

			console.log(err);
			OverlayService.hide();
			OverlayService.showError("Download Failed", ErrorUtils.getErrorMessage(err, "Unable to download attachment"));

		});
	}
}
