import { Component, OnInit } from '@angular/core';
import { AdminService } from '@services/admin.service';
import { OverlayService } from '@services/overlay.service';
import { E164Service } from '@services/E164.service';
import { PrivateComponent } from "@classes/private.component";
import { UserType, UserRole } from "@classes/user";
import { Utils } from "@classes/utils";
import { ErrorUtils } from "@classes/errors";
import { MenuBuilder } from "@services/navmenu.service";
import { Settings } from "@classes/settings";
import { Gender } from "@classes/gender";
import moment from 'moment';

interface NewUserModel {
	accountType: UserType;
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	generatePassword: boolean;
	password: string;
	ndisNumber?: string;
	dob?: string;
	// region?: Region;
	createLogin: boolean;
	noStatements: boolean;
	gender: Gender;
	roles: any;
}

@Component({
	"styleUrls": ["./createuser.component.scss"],
	"templateUrl": "./createuser.component.html"
})
export class CreateUserComponent extends PrivateComponent implements OnInit {

	private static CreatableAccountTypes: any = {};

	readonly accountType = {
		"client": UserType.Participant,
		"admin": UserType.Admin,
		// "supportCoordinator": UserType.SupportCoordinator,
		"user": UserType.User
	};

	public get accountTypes(): UserType[] {
		let result = [
			UserType.Participant,
			UserType.User
			// UserType.SupportCoordinator
		];

		if (this.hasPermission('Users', 'Create admin account')) {
			result.push(UserType.Admin);
		}

		return result;
	}

	protected accountTypeName(userType: UserType): string {
		return UserType.toString(userType);
	}

	/**
	* List of all roles defined in the system that can be applied to an admin account
	*/
	protected roles: UserRole[] = [];

	public model: NewUserModel = {} as NewUserModel;

	// get allRegions(): Region[] {
	// 	return RegionUtils.allValues();
	// }

	// public regionName(region: Region): string {
	// 	return RegionUtils.toString(region);
	// }

	private initUserTypes(): void {
		const creatableAccountTypes: any = {};

		// Administrators can create any type of account
		creatableAccountTypes[UserType.Admin] = [
			{"id": UserType.Admin, "name": "Administrator"},
			{"id": UserType.SupportCoordinator, "name": "Support Coordinator"},
			{"id": UserType.Participant, "name": "Client"},
			{"id": UserType.User, "name": "Authorised Representative / Nominee"}
		];
	}

	constructor(private adminService: AdminService) {
		super();

		this.allowedUserTypes = [UserType.Admin];
		this.permissionsRequired.push(['Users', 'Create Account'])
	}

	private async loadData() {
		if (this.user) {
			OverlayService.show();
			try {
				const customPermissionsRole = Settings.instance.get('customPermissionsRole').value;

				this.roles = await this.userService.getRoles();
				this.roles = this.roles.filter( role => role.id !== customPermissionsRole );

				this.model.roles = this.roles.reduce( (acc, role) => {
					acc[role.id] = false;
					return acc;
				}, {});
				OverlayService.hide()
			}
			catch (e) {
				OverlayService.hide();
				console.log(e);
				OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "An unknown error occurred"));
			}
		}
	}

	ngOnInit() {
		super.ngOnInit()
		this.initUserTypes();

		this.resetModel();
		this.loadData();

		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.done();
	}

	private resetModel() {
		this.model.accountType = undefined;
		this.model.firstName = "";
		this.model.lastName = "";
		this.model.email = "";
		this.model.phone = "";
		this.model.password = "";
		this.model.generatePassword = true;
		this.model.ndisNumber = undefined;
		this.model.noStatements = false;
		this.model.dob = undefined;
		// this.model.region = undefined;
		this.model.gender = undefined;
	}

	phoneValid(): boolean {
		const e164 = E164Service.toE164(this.model.phone);
		return e164 !== undefined;
	}

	private emailValid(): boolean {
		if (!this.model.email || this.model.email.trim() === "") {
			return false;
		}

		const re = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
		return re.test(this.model.email);
	}

	protected get ndisNumberValid(): boolean {
		if (this.model.accountType !== UserType.Participant) {
			return undefined;
		}

		// A valid NDIS Number is 9 digits long and starts with "43" or "5"
		return /^(43\d{7}|5\d{8})$/.test(this.model.ndisNumber);
	}

	formValid(): boolean {
		if (!this.model) {
			return false;
		}

		const usernameValid = this.emailValid();
		const passwordValid = this.model.generatePassword || this.model.password && this.model.password.trim().length > 6;

		const values = [
			this.model.accountType !== undefined,
			this.model.firstName && this.model.firstName.trim() !== "",
			this.model.lastName && this.model.lastName.trim() !== "",
			usernameValid,
			passwordValid,
			this.model.accountType === UserType.Participant ? this.ndisNumberValid : true,
			this.model.accountType === UserType.Participant ? this.dobValid && !this.dobTooOld && !this.dobInFuture: true,
			// this.model.accountType === UserType.Participant ? this.model.region !== undefined : true
		];

		return values.every( value => value === true );
	}

	private formatDateString(value: string): string {
		if (!value) {
			return null;
		}

		const m = moment(value, 'DD/MM/YYYY');
		return m.isValid() ? m.startOf('day').format("YYYY-MM-DD") : null;
	}

	private get selectedRoles(): string[] {
		return Object.keys(this.model.roles).filter( roleId => this.model.roles[roleId] === true );
	}

	createUser(form): void {
		OverlayService.show(`Creating account${Utils.ellipsis}`);

		const e164MobileNumber = E164Service.toE164(this.model.phone);

		const userData = {
			"firstName": this.model.firstName,
			"lastName": this.model.lastName,
			"email": this.model.email,
			"mobile": e164MobileNumber,
			"ndisNumber": this.model.accountType === UserType.Participant ? this.model.ndisNumber : undefined,
			"gender": this.model.accountType === UserType.Participant ? Gender.toJSON(this.model.gender) : undefined,
			"dob": this.model.accountType === UserType.Participant ? this.formatDateString(this.model.dob) : undefined,
			"createLogin": !!this.model.createLogin,
			"noStatements": !!this.model.noStatements,
			"password": this.model.generatePassword ? undefined : Utils.trimString(this.model.password),
			"username": Utils.trimString(this.model.email),
			"accountType": UserType.toPostgresEnum(this.model.accountType),
			// "region": RegionUtils.toPostgresEnum(this.model.region),
			"roles": this.model.accountType === UserType.Admin ? this.selectedRoles : undefined
		};

		this.adminService.createUser({"user": userData}).then( data => {

			OverlayService.hide();

			OverlayService.showDialog(
				"Success",
				`An account for ${this.model.firstName} ${this.model.lastName} was created successfully`,
				[{
					"text": "Close",
					"handler": () => {
						OverlayService.hide();
						this.resetModel();
						form.reset();
						this.router.navigate(['/account', data.id]);
					}
				}]);

		}).catch( err => {

			OverlayService.hide();

			err = err.response ? err.response.data : err;
			const errorMessage = err.errorMessage ? err.errorMessage : "Unable to create user at this time";

			OverlayService.showError("Error", errorMessage.replace(/^\[\d+\] /, ''));

		});
	}

	public get dobValid(): boolean {
		return moment(this.model.dob, 'DD/MM/YYYY', true).isValid();
	}

	public get dobInFuture(): boolean {
		const m = moment(this.model.dob, 'DD/MM/YYYY', true);
		const now = moment();
		return (m.isValid() && m.isAfter(now));
	}

	public get dobTooOld(): boolean {
		const m = moment(this.model.dob, 'DD/MM/YYYY', true);
		const oneHundredAndTwenty = moment().subtract(120, 'year');
		return (m.isValid() && m.isBefore(oneHundredAndTwenty));
	}

	public allGenders: () => Gender[] = Gender.allValues;
	public genderName(gender: Gender): string {
		return Gender.toString(gender);
	}

}
