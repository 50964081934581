import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrivateComponent } from "@classes/private.component";
import { MenuBuilder } from "@services/navmenu.service";
import { User, UserType } from "@classes/user";
import { Plan, PlanStatus } from "@classes/plans";
import { PlanService } from "@services/plan.service";
import { ProviderService } from "@services/provider.service";
import { Invoice, InvoiceService } from "@services/invoice.service";
import { Provider } from "@classes/provider";
import { InvoiceStatus } from "@classes/invoices";

@Component({
	"styleUrls": ["./providerInvoices.component.scss"],
	"templateUrl": "./providerInvoices.component.html"
})
export class ProviderInvoicesComponent extends PrivateComponent implements OnInit {

	private _dataLoaded: boolean = false;
	private _showReimbursements: boolean = true;
	private _client: User|undefined = undefined;
	private _plan: Plan|undefined = undefined;
	private _provider: Provider|undefined = undefined;
	private _invoices: Invoice[] = [];

	private async loadPlan(planId: string): Promise<void> {
		this._plan = await this.planService.load(planId);
	}

	private async loadClient(clientId: string) {
		this._client = await this.userService.loadUser(clientId);
	}

	private async loadProvider(providerId: string) {
		this._provider = await this.providerService.getProvider(providerId);
	}

	private async loadInvoices(providerId, planId: string, showReimbursements: boolean) {
		this._invoices = await this.invoiceService.loadProviderInvoices(providerId, planId, showReimbursements);
	}

	private async loadData(clientId: string, providerId: string, planId: string, showReimbursements: boolean) {
		this._dataLoaded = false;
		try {

			await Promise.all([
				this.loadPlan(planId),
				this.loadClient(clientId),
				this.loadProvider(providerId),
				this.loadInvoices(providerId, planId, showReimbursements)
			]);
		}
		finally {
			this._dataLoaded = true;
		}
	}

	constructor(
		private route: ActivatedRoute,
		private planService: PlanService,
		private providerService: ProviderService,
		private invoiceService: InvoiceService) {
		super();
	}

	private buildMenu() {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.done();
	}

	ngOnInit() {
		super.ngOnInit();

		this.route.params.subscribe( params => {

			if (this.user) {
				this.loadData(params.clientId, params.providerId, params.planId, this._showReimbursements);
			}
		});

		this.buildMenu();
	}

	public get plan(): Plan {
		return this._plan;
	}

	public readonly planStatus: (plan: PlanStatus) => string = PlanStatus.toString;
	public readonly invoiceStatus: (status: InvoiceStatus) => string = InvoiceStatus.toString;

	public get dataLoaded(): boolean {
		return this._dataLoaded;
	}

	public get clientName(): string {
		return this._client && `${this._client.firstName} ${this._client.lastName}` || "";
	}

	public get clientId(): string {
		return this._client.id || "";
	}

	public get ndisNumber(): string {
		return this._client && this._client.ndisNumber || "";
	}

	public get invoices(): Invoice[] {
		return this._invoices || [];
	}

	public get providerName(): string {
		return this._provider.name || "";
	}

	public get providerABN(): string {
		return this._provider.abn || "";
	}

	public get providerId(): string {
		return this._provider.id || "";
	}

	public get showReimbursements(): boolean {
		return this._showReimbursements;
	}

	public onShowReimbursementsChange(){
		this._showReimbursements = !this._showReimbursements;
		this.loadInvoices(this._provider.id, this._plan.id, this._showReimbursements);
	}

	public get isAdmin(): boolean {
		return this.user.accountType === UserType.Admin;
	}
}
