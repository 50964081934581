import { Component, OnInit } from '@angular/core';
import { DataExchangeService } from '@services/dataexchange.service';
import { PrivateComponent } from "@classes/private.component"
import { UserAccount, UserType } from "@classes/user"
import { PaginatedTable, TableColumnHeading, SortType, StaticDataSource } from "@classes/tables";
import { UserAccountService } from "@services/accounts.service";
import { MenuBuilder } from "@services/navmenu.service";
import { PhoneNumberUtil, PhoneNumberType, PhoneNumberFormat } from 'google-libphonenumber';

@Component({
	"styleUrls": ["./listaccounts.component.scss"],
	"templateUrl": "./listaccounts.component.html"
})
export class ListAccountsComponent extends PrivateComponent implements OnInit {

	private _dataLoaded: boolean = false;
	private canDisplay: boolean = false;

	readonly tableHeadings: TableColumnHeading[] = [
		{"propName": "name", "displayName": "Name", "sortType": SortType.name },
		{"propName": "accountType", "displayName": "Account Type", "sortType": SortType.text },
		{"propName": "email", "displayName": "Email", "sortType": SortType.text },
		{"propName": "phone", "displayName": "Mobile", "sortType": SortType.text },
		{"propName": "createdBy", "displayName": "Created By", "sortType": SortType.name }
		// {"propName": "createdAt", "displayName": "Creation Date", "sortType": SortType.date }
	];

	protected readonly administratorAccount = UserType.Admin;

	protected readonly accountTypes: UserType[] = UserType.allValues();

	protected accountTypeName(userType: UserType): string {
		return UserType.toString(userType);
	}

	table: PaginatedTable<UserAccount> = new PaginatedTable('list.accounts', this.tableHeadings);

	model: any = {
		"search": undefined,
		"accountType": DataExchangeService.get('accountList.accountTypeFilter', false)
	};

	get dataLoaded(): boolean {
		return this._dataLoaded;
	}


	constructor(private userAccountService: UserAccountService) {
		super();

		this.allowedUserTypes = [UserType.Admin, UserType.SupportCoordinator];
		this.requirePermission('Users', 'List Accounts');
	}

	ngOnInit() {
		super.ngOnInit();
		this.canDisplay = this.user && [UserType.Admin, UserType.SupportCoordinator].includes(this.user.accountType);

		// const filterId: number = DataExchangeService.get('accountList.filter', false) || 1;

		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.done();

		this.model.accountType = DataExchangeService.get('accountList.accountTypeFilter', false);

		this.loadAccounts(true);
	}

	private async loadAccounts(forceReload: boolean = false) {
		this._dataLoaded = false;

		if (this.canDisplay) {
			const searchPhrase = this.model.search ? this.model.search.toLowerCase() : undefined;
			try {
				const allAccounts = await this.userAccountService.getAccounts(forceReload, searchPhrase);

				if (this.model.accountType === undefined) {
					this.table.sourceData = StaticDataSource.from(allAccounts);
				}
				else {
					this.table.sourceData = StaticDataSource.from( allAccounts.filter( record => record.accountType === this.model.accountType ) );
				}

				this._dataLoaded = true;

			}
			catch (err) {

				console.log(err);
				this.table.sourceData = undefined;
				this._dataLoaded = true;

			};
		}
	}

	forceReload(): void {
		this.loadAccounts(true);
	}

	private updateDisplayData(): void {

		// let unsortedData = this.filterModel ? this._data.filter( this.filterModel.filterFunc ) : this._data;
		// this._displayDataSource.next( unsortedData.sort( this.getSortFunc() ) );

	}

	getUserTypeDisplayName(type: UserType): string {
		return UserType.toString(type);
	}

	search(): void {
		DataExchangeService.set(this.model.accountType, 'accountList.accountTypeFilter');
		this.loadAccounts();
	}

	formatPhoneNumber(number: string): string {
		if (!number) {
			return "";
		}

		try {

			const phoneUtil = PhoneNumberUtil.getInstance();
			const phoneNumber = phoneUtil.parseAndKeepRawInput(number, "AU");

			if ( phoneUtil.isValidNumber(phoneNumber) && phoneUtil.getNumberType(phoneNumber) === PhoneNumberType.MOBILE) {
				return phoneUtil.format(phoneNumber, PhoneNumberFormat.NATIONAL);
			}
		}
		catch (e) {
			console.log(e);
			if (e.message !== "The string supplied did not seem to be a phone number") {
				console.log(e);
			}
		}

		return "";

	}
}

