import { Component, OnInit } from '@angular/core';

import { DialogMode, OverlayService, ErrorMessage, Template } from '@services/overlay.service';

@Component({
	"selector": "modal-overlay",
	"templateUrl": "./overlay.component.html",
	"styleUrls": ["./overlay.component.scss"]
})
export class OverlayComponent implements OnInit {

	private timeout: any = undefined;
	private timeoutCounter: number = 0;

	private clearTimeout() {
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
	}

	private setTimeout() {
		this.clearTimeout();
		this.timeout = setTimeout(() => { this.autohide() }, 1000);
	}

	private autohide() {
		this.clearTimeout();
		this.timeoutCounter--;
		if (this.timeoutCounter === 0) {
			this.mode = DialogMode.none;
		}
		else {
			this.setTimeout();
		}
	}

	dialogMode = {
		"none": DialogMode.none,
		"wait": DialogMode.wait,
		"error": DialogMode.error,
		"success": DialogMode.success,
		"dialog": DialogMode.dialog,
		"template": DialogMode.template
	};

	mode: DialogMode = DialogMode.none;

	loadingMessage: string;

	errorMessage: ErrorMessage = {
		"title": "",
		"message": "",
		"buttonText": "Close",
		"autoclose": 0
	};

	dialog = {
		"title": "",
		"content": "",
		"buttons": []
	};

	template: Template = {
		"template": undefined,
		"context": undefined
	}

	get countdown(): number {
		return this.timeoutCounter;
	}

	get countdownSecondsPlural(): string {
		return this.timeoutCounter === 1 ? '' : 's';
	}

	get isAutoclose(): boolean {
		return this.timeoutCounter > 0;
	}

	constructor() {

		OverlayService.loadingText$.subscribe( loadingText => {
			this.loadingMessage = loadingText;
		});

		OverlayService.errorMessageS.subscribe( errorMessage => {
			this.errorMessage = errorMessage;
		});

		OverlayService.dialogMode$.subscribe( mode => {
			this.mode = mode;
			this.timeoutCounter = 0;
			this.clearTimeout();
			if (this.errorMessage.autoclose) {
				this.timeoutCounter = this.errorMessage.autoclose;
				this.setTimeout();
			}
		});

		OverlayService.dialog$.subscribe( dialog => {
			this.dialog = dialog;
		});

		OverlayService.templateMode$.subscribe( template => {
			this.template = template;
		});
	}

	isActive(): boolean {
		return this.mode !== DialogMode.none;
	}

	ngOnInit() {
	}

	closeDialog(): void {
		this.timeoutCounter = 0;
		OverlayService.hide();
	}

}
