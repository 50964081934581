import { Component, OnInit } from '@angular/core';
import { config } from "../../../config";

@Component({
	"selector": "chatbot",
	"templateUrl": "./chatbot.component.html"
})
export class ChatbotComponent implements OnInit {

	constructor() {}

	get isProduction(): boolean {
		return (config.stage === "prod");
	}

	ngOnInit() {
		if (this.isProduction) {

			console.log("Creating chatbot");
			const chatbot = document.createElement("script");
			chatbot.type = "text/javascript";
			chatbot.async = true;
			chatbot.src = "//code.tidio.co/clx599yukor9eyhu5pz2se3ovgapc5dr.js";
			document.getElementsByTagName('body')[0].appendChild(chatbot);
		}
	}
}
