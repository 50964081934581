import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { UserType, UserAccount } from "@classes/user";
import { Table, PaginatedTable, TableColumnHeading, SortType, StaticDataSource } from "@classes/tables";
import { MenuBuilder } from "@services/navmenu.service";
import { InvoiceHeader, InvoiceStatusUtils, InvoiceStatus } from "@classes/invoices";
import { InvoiceService, DeletedInvoiceSearch, ReimbursementsSearch } from "@services/invoice2.service";
import { OverlayService } from "@services/overlay.service";
import { DataExchangeService } from "@services/dataexchange.service";
import { FreshdeskService } from "@services/freshdesk.service";
import { StorageService } from "@services/storage.service";
import { UserAccountService } from "@services/accounts.service";
import moment from 'moment';

@Component({
	"styleUrls": ["./findinvoice.component.scss"],
	"templateUrl": "./findinvoice.component.html"
})
export class FindInvoiceComponent extends PrivateComponent implements OnInit {
	@ViewChild('filterTemplate') private filterTemplateRef: TemplateRef<any>;

	private static readonly userFilterStorageKey = 'FindInvoiceComponent.userFilter';

	private _recentInvoicesLoaded: boolean = false;
	private _searchResultsLoaded: boolean = false;
	protected lastSavedInvoice: string = undefined;
	private _admins: UserAccount[] = [];
	private _recentInvoices: InvoiceHeader[] = [];
	private searchData: InvoiceHeader[];

	private readonly tableHeadings: TableColumnHeading[] = [
		{"propName": "createdByName", "displayName": "Created By", "sortType": SortType.name },
		{"propName": "created", "displayName": "Created Date", "sortType": SortType.date },
		{"propName": "status", "displayName": "Status", "sortType": SortType.numeric },
		{"propName": "invoiceNumber", "displayName": "Bill Number", "sortType": SortType.name },
		{"propName": "clientName", "displayName": "Client", "sortType": SortType.name },
		{"propName": "providerName", "displayName": "Provider", "sortType": SortType.text },
		{"propName": "total", "displayName": "Total", "sortType": SortType.numeric },
		{"propName": "", "displayName": "", "sortType": SortType.none }
	];

	protected invoiceStatus: any = InvoiceStatusUtils.allValues().reduce( (acc, cur) => {
		acc[InvoiceStatusUtils.toPostgresEnum(cur)] = cur;
		return acc;
	}, {});

	private buildMenu(): void {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.addRoute("plus", "New Bill", "/billing/new");
		if (this.hasPermission('Data Import', 'Data Import Access')) {
			menuBuilder.addRoute('cloud-upload-alt', "Data Import", "/dataimport/new");
		}
		menuBuilder.done();
	}

	private async loadAdminAccounts(): Promise<UserAccount[]> {
		return this.userAccountService.getAdminsWithDraftInvoices();
	}

	private async loadInvoices() {
		return this.invoiceService.getRecentInvoices();
	}

	private async loadData() {
		this._recentInvoicesLoaded = false;
		try {
			this.initaliseUserFilter();
			const [invoices, admins] = await Promise.all([this.loadInvoices(), this.loadAdminAccounts()]);

			this._recentInvoices = invoices;
			this._admins = admins.sort( (a, b) => a.name.localeCompare(b.name) );

			this.recentInvoicesTable.sourceData = StaticDataSource.from( this.filterInvoices() );
			this.lastSavedInvoice = DataExchangeService.get('lastSavedInvoice');
		}
		catch (e) {
			console.log(e);
		}
		finally {
			this._recentInvoicesLoaded = true;
		}
	}

	readonly searchResultsTable: PaginatedTable<InvoiceHeader> = new PaginatedTable('invoiceSearchResults', this.tableHeadings);
	readonly recentInvoicesTable: Table<InvoiceHeader> = new Table('recentInvoices', this.tableHeadings);

	get recentInvoicesLoaded(): boolean {
		return this._recentInvoicesLoaded;
	}

	get searchResultsLoaded(): boolean {
		return this._searchResultsLoaded;
	}

	get foundMatchingInvoices(): boolean {
		return this.searchResultsTable.hasData;
	}

	constructor(private invoiceService: InvoiceService, private freshdeskService: FreshdeskService, private userAccountService: UserAccountService) {
		super();
		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Billing', 'Access billing');
	}

	protected invoiceStatusDescription(status: InvoiceStatus): string {
		return InvoiceStatusUtils.toString(status);
	}

	ngOnInit() {
		super.ngOnInit();

		if (this.user) {
			this.buildMenu();
			this.loadData();
		}
		this.invoiceStatus.all = null;

		this.filtersCopy = Object.assign({}, this.filters);
	}

	public showFilterDialog(): void {
		OverlayService.showTemplate(this.filterTemplateRef, {});
	}

	protected saveFilters(): void {
		OverlayService.hide();

		this.invoiceSearch(this.filters.searchTerm);
	}

	protected clearFilters(): void {
		this.filters = Object.assign({}, this.defaultFilters);
		this.filters.searchTerm = this.filtersCopy.searchTerm;
	}

	protected closeFilterDialog() {
		OverlayService.hide();

		this.filters = Object.assign({}, this.filtersCopy);
	}

	protected async doSearch(searchText: string): Promise<any> {
		this._searchResultsLoaded = false;

		OverlayService.show();
		try {

			let deletedInvoices: DeletedInvoiceSearch = DeletedInvoiceSearch.exclude;
			if (this.filters.searchDeletedInvoices) {
				deletedInvoices = DeletedInvoiceSearch.include;

				if (this.filters.onlyDeletedInvoices) {
					deletedInvoices = DeletedInvoiceSearch.only;
				}
			}

			let searchResults;
			if(this.needQuery) {
				searchResults = await this.invoiceService.search(searchText, deletedInvoices, this.filters.reimbursementSearchType, this.filters.searchType, true);
				this.searchData = searchResults;
			} else {
				searchResults = this.searchData;
			}

			searchResults = !searchResults ? [] : searchResults.filter(invoice => {

				if((invoice.status != this.filterStatus) && (this.filterStatus !== null)) {
					return false;
				} else if(this.filters.fromDate && (invoice.date < this.filters.fromDate)) {
					return false;
				} else if(this.filters.toDate && (invoice.date > this.filters.toDate)) {
					return false
				}
		
				return true;
			}, this);

			this.searchResultsTable.sourceData = StaticDataSource.from(searchResults);

		} catch (e) {
			console.log(e);
		}
		this.filtersCopy = Object.assign({}, this.filters);
		OverlayService.hide();
		this._searchResultsLoaded = true;
	}

	invoiceSearch(searchText: string): void {
		this.doSearch(searchText);
	}

	clearSearch(): void {
		this.filters.searchTerm = '';
		this.filtersCopy.searchTerm = '';
	}

	searchOnReturn(event: KeyboardEvent, searchText: string): void {
		if (event.key === 'Enter') {
			this.invoiceSearch(searchText);
		}
	}

	public openTicket(event: MouseEvent, invoice: InvoiceHeader) {
		event.preventDefault();
		event.cancelBubble = true;
		window.open(this.freshdeskService.getTicketUrl(invoice.ticketNumber), '_ticketWin');
	}

	public openBill(event: MouseEvent, invoice: InvoiceHeader) {
		const path = `billing/${invoice.id}`;

		if (event.ctrlKey) {
			const url = this.router.serializeUrl(
				this.router.createUrlTree([path])
			);

			window.open(url, '_blank');
		}
		else {
			this.router.navigate([path]);
		}
	}

	public filters = {
		searchTerm: "",
		searchDeletedInvoices: false,
		onlyDeletedInvoices: false,
		reimbursementSearchType: ReimbursementsSearch.all,
		filterStatus: null,
		fromDateString: "",
		toDateString: "",
		fromDate: undefined,
		toDate: undefined,
		searchType: "all"
	};
	private defaultFilters = {
		searchTerm: "",
		searchDeletedInvoices: false,
		onlyDeletedInvoices: false,
		reimbursementSearchType: ReimbursementsSearch.all,
		filterStatus: null,
		fromDateString: "",
		toDateString: "",
		fromDate: undefined,
		toDate: undefined,
		searchType: "all"
	};
	private filtersCopy;

	public get numFilters(): string {
		let activeFilters: number = 0;
		for (let [filter, value] of Object.entries(this.filters)) {
			if((this.defaultFilters[filter] != value) && (filter != "searchTerm") && (filter != "fromDateString") && (filter != "toDateString")) {
				activeFilters++;
			}
		}

		return activeFilters > 0 ? " ("+activeFilters.toString()+")" : "";
	}

	public get needQuery(): boolean {
		return (this.filters.searchTerm != this.filtersCopy.searchTerm) ||
			   (this.filters.searchDeletedInvoices != this.filtersCopy.searchDeletedInvoices) ||
			   (this.filters.onlyDeletedInvoices != this.filtersCopy.onlyDeletedInvoices) ||
			   (this.filters.searchType != this.filtersCopy.searchType) ||
			   (this.filters.reimbursementSearchType != this.filtersCopy.reimbursementSearchType);
	}

	public get validDateFilters(): boolean {
		return (!!this.filters.fromDateString != (this.filters.fromDate == undefined)) && (!!this.filters.toDateString != (this.filters.toDate == undefined));
	}

	public set filterStatus(invoiceStatus: any) {
		if (invoiceStatus === 'null') {
			this.filters.filterStatus = null;
		} else {
			this.filters.filterStatus = invoiceStatus;
		}
	}

	public get filterStatus(): any {
		return this.filters.filterStatus;
	}

	public setFromDate(date: string) {
		const m = moment(date, 'DDMMYYYY', true);
		if(m.isValid()) {
			this.filters.fromDate = m.startOf('day').toDate();
		} else {
			this.filters.fromDate = undefined;
		}
	}

	public setToDate(date: string) {
		const m = moment(date, 'DDMMYYYY', true);
		if(m.isValid()) {
			this.filters.toDate = m.startOf('day').toDate();
		} else {
			this.filters.toDate = undefined;
		}
	}

	public get admins(): UserAccount[] {
		return this._admins;
	}

	private _userFilter: string|null = null;

	public get userFilter(): string|null {
		return this._userFilter;
	}

	public set userFilter(value: string|null) {
		this._userFilter = value;
		StorageService.set(FindInvoiceComponent.userFilterStorageKey, value);
		this.recentInvoicesTable.sourceData = StaticDataSource.from( this.filterInvoices() );
	}

	private filterInvoices(): InvoiceHeader[] {
		const src = this._recentInvoices.filter( invoice => {
			if ([this.filterAnyone, null].includes(this._userFilter)) {
				return true;
			}
			return invoice.createdBy === this._userFilter;
		});

		return src;
	}

	private initaliseUserFilter() {
		const storedValue = StorageService.get(FindInvoiceComponent.userFilterStorageKey);
		this._userFilter = storedValue === null ? this.user.id : storedValue;
	}

	public readonly filterAnyone: string = "-";
}
