import { Component } from '@angular/core';
import { IconName } from '@fortawesome/free-solid-svg-icons';

import { NavMenuService, MenuItem } from '@services/navmenu.service';

@Component({
	"selector": "navigation-buttons",
	"styleUrls": ["./menu.component.scss"],
	"templateUrl": "./menu.component.html"
})
export class MenuComponent {

	private _menuItems: MenuItem[] = [];

	get menuItems(): MenuItem[] {
		return this._menuItems;
	}

	protected toIcon(icon: string): IconName {
		return icon as IconName;
	}

	constructor() {

		NavMenuService.menuItems$.subscribe( menuItems => {
			this._menuItems = menuItems;
		});
	}

}
