import { registerLocaleData }                      from '@angular/common';
import localeAu                                    from '@angular/common/locales/en-AU';
import localeAuExtra                               from '@angular/common/locales/extra/en-AU';

import { NgModule, Injector, LOCALE_ID }           from '@angular/core';
import { BrowserModule }                           from '@angular/platform-browser';
import { FormsModule }                             from '@angular/forms';
import { HttpClientModule }                        from '@angular/common/http';
import { FileUploadModule }                        from 'ng2-file-upload';

//import { AmplifyAngularModule, AmplifyService }    from 'aws-amplify-angular';
//import { AmplifyAuthenticatorModule }              from '@aws-amplify/ui-angular';

import { DataExchangeService }                     from './services/dataexchange.service';
import { StorageService }                          from './services/storage.service';
import { ServiceLocator }                          from './services/locator.service';
import { AppRoutingModule }                        from './app-routing.module';

import { AppComponent }                            from './components/app/app.component';
import { ChatbotComponent }                        from './components/chatbot/chatbot.component';
import { MaintenanceComponent }                    from './components/maintenance/maintenance.component';
import { VersionCheckComponent }                   from './components/versionCheck/versionCheck.component';
import { HeaderComponent }                         from './components/header/header.component';
import { GlobalSearchComponent }                   from './components/header/search/search.component';
import { LoginComponent }                          from './components/login/login.component';
import { AttachmentDialogComponent }               from './components/attachmentDialog/attachmentDialog.component';
import { AccountDetailsComponent }                 from './components/accountdetails/accountdetails.component';
import { LinkedProvidersComponent }                from './components/accountdetails/linkedProviders/linkedproviders.component';
import { NomineesComponent }                       from './components/accountdetails/nominees/nominees.component';
import { LoginHistoryComponent }                   from './components/accountdetails/loginHistory/loginHistory.component';
import { AccountCredentialsComponent }             from './components/accountdetails/accountCredentials/accountcredentials.component';
import { QuarantinedFundsComponent }               from './components/accountdetails/quarantinedFunds/quarantinedfunds.component';
import { AccountPermissionsComponent }             from './components/accountdetails/permissions/permissions.component';
import { AccountRolesComponent }                   from './components/accountdetails/roles/roles.component';
import { FileNotesComponent }                      from './components/notes/notes.component';
import { DocumentFilterComponent }                 from './components/accountdetails/documentFilter/documentFilter.component';
import { InvoiceClientSearchComponent }            from './components/administrator/v2invoicing/edit/clientSearch/clientSearch.component';
import { InvoiceProviderSearchComponent }          from './components/administrator/v2invoicing/edit/providerSearch/providerSearch.component';

import { TasksComponent }                          from './components/administrator/tasks/tasks.component';
import { TaskSummaryComponent }                    from './components/administrator/tasks/summary/summary.component';
import { TaskListComponent }                       from './components/administrator/tasks/list/list.component';
import { UpdateNotesDialogComponent }              from './components/administrator/tasks/updateNotesDialog/updatenotesdialog.component';

import { DashboardComponent }                      from './components/dashboard/dashboard.component';
import { AdminDashboardComponent }                 from './components/administrator/dashboard/admindashboard.component';
import { FileNotesWidgetComponent }                from './components/administrator/dashboard/filenotes/filenotes.component';
import { InvoiceWidgetComponent }                  from './components/administrator/dashboard/invoiceWidget/invoiceWidget.component';

import { ClientsWithTrafficLightsComponent }       from './components/administrator/dashboard/clientsWithTrafficLights/clientswithtrafficlights.component';
import { ClientsWithoutPlansComponent }            from './components/administrator/dashboard/clientsWithoutPlans/clientswithoutplans.component';
import { PlansExpiringSoonComponent }              from './components/administrator/dashboard/plansExipiringSoon/plansexpiringsoon.component';
import { NewClientsComponent }                     from './components/administrator/dashboard/newClients/newclients.component';
import { ClientSearchComponent }                   from './components/administrator/dashboard/search/clientsearch.component';

import { ParticipantDashboardComponent }           from './components/participant/dashboard/participantdashboard.component';
import { SupportCoordinatorDashboardComponent }    from './components/supportcoordinator/dashboard/dashboard.component';
import { SupportCoordinatorTransactionsComponent } from './components/supportcoordinator/transactions/transactions.component';
import { SupportCoordinatorClientsComponent }      from './components/supportcoordinator/clients/supportcoordinatorclients.component';
import { SupportCoordinatorInvoicesComponent }     from './components/supportcoordinator/invoices/invoices.component';
import { PlanCategoryAllocationChartComponent }    from './components/participant/allocationchart/allocationchart.component';
import { ChangePwdComponent }                      from './components/password/change/changepassword.component';
import { OverlayComponent }                        from './components/overlay/overlay.component';
import { MenuComponent }                           from './components/menu/menu.component';
import { ForgotPasswordComponent }                 from './components/password/forgot/forgotpassword.component';
import { ResetPasswordComponent }                  from './components/password/reset/resetpassword.component';
import { CreateUserComponent }                     from './components/createuser/createuser.component';
import { AccessDeniedComponent }                   from './components/accessdenied/accessdenied.component';
import { ClientCheckComponent }                    from './components/clientcheck/clientcheck.component';
import { LinkedSupportCoordinatorsComponent }      from './components/administrator/linkedaccounts/linkedaccounts.component';
// import { TransactionSummaryComponent }             from './components/administrator/transactions/summary/transactionsummary.component';
import { CategoryTransactionsComponent }           from './components/participant/categorytransactions/categorytransactions.component';
import { RecentInvoicesComponent }                 from './components/participant/invoices/recent/recentinvoices.component';
import { AllClientInvoicesComponent }              from './components/participant/invoices/all/allinvoices.component';
import { ClientInvoiceComponent }                  from './components/participant/invoices/show/invoice.component';
// import { TransactionComponent }                    from './components/administrator/transactions/show/transaction.component';
import { ProvidersComponent }                      from './components/administrator/providers/list/providers.component';
import { ProviderComponent }                       from './components/administrator/providers/edit/provider.component';
import { ProviderBillsComponent }                  from './components/administrator/providers/edit/bills/providerbills.component';
import { ProviderNotesComponent }                  from './components/administrator/providers/notes/notes.component';
import { ListAccountsComponent }                   from './components/listaccounts/listaccounts.component';
import { PaginationComponent }                     from './components/pagination/pagination.component';

import { NewExtractComponent }                     from './components/administrator/proda/newextract/newextract.component';

// import { GovernmentPaymentFileComponent }          from './components/administrator/proda/addFile/addFile.component';
import { ProdaFileListComponent }                  from './components/administrator/proda/list/listFiles.component';
import { ProdaSubmissionComponent }                from './components/administrator/proda/submission/submission.component';
import { ProdaResponseComponent }                  from './components/administrator/proda/addResponse/addresponse.component';
import { ProdaReconcilliationComponent }           from './components/administrator/proda/reconcilliation/reconcilliation.component';
import { XeroComponent }                           from './components/administrator/proda/xero/xero.component';
import { FindInvoiceComponent }                    from './components/administrator/v2invoicing/find/findinvoice.component';
import { EditInvoiceComponent }                    from './components/administrator/v2invoicing/edit/editinvoice.component';
import { InvoiceLineItemComponent }                from './components/administrator/v2invoicing/edit/lineitem/invoicelineitem.component';
import { InvoiceAttachmentComponent }              from './components/administrator/v2invoicing/edit/attachments/invoiceattachments.component';
import { PlansDialogComponent }                    from './components/administrator/v2invoicing/edit/plansDialog/plansdialog.component';
import { BillSaveDialogComponent }                 from './components/administrator/v2invoicing/edit/saveDialog/savedialog.component';
import { BillReopenDialogComponent }               from './components/administrator/v2invoicing/edit/reopenDialog/reopendialog.component';
import { TableHeaderComponent }                    from './components/table/tableheader.component';
import { TableHeader2Component }                   from './components/table/tableheader2.component';
import { provideNgxMask, NgxMaskDirective, NgxMaskPipe }  from 'ngx-mask';
//import { NgxTypeaheadModule }                      from 'ngx-typeahead';
import { TypeaheadModule }						   from 'ngx-bootstrap/typeahead';
import { EditPlanComponent }                       from './components/administrator/plans/edit/editplan.component';
// import { ProvidersDialogComponent }               from './components/administrator/plans/edit/providersDialog/providers.dialog';
//import { PlanSupportCategoryComponent }            from './components/administrator/plans/edit/supportCategory/supportCategory.component';
import { PlanSupportItemComponent }                from './components/administrator/plans/edit/supportItem/planSupportItem.component';
// import { NDISPriceListComponent }                 from './components/administrator/prices/list/ndisprices.component';
// import { NDISPriceEditComponent }                 from './components/administrator/prices/edit/editprice.component';
import { AuMobileValidatorDirective }              from './classes/validators/auphone.directive';
import { AuPhoneValidatorDirective }               from './classes/validators/auphone.directive';
import { FileSizePipe }                            from './classes/filesize.pipe';
import { NoteDialogComponent }                     from './components/accountdetails/noteDialog/notedialog.component';

import { BillsUnderInvestigationComponent }        from './components/administrator/v2invoicing/investigations/investigations.component';
import { InvestigationFilterComponent }            from './components/administrator/v2invoicing/investigationFilter/investigationFilter.component';

import { ReportsComponent }                        from './components/reports/reports.component';
import { DataEntryReportComponent }                from './components/reports/dataEntry/dataentry.component';
import { BillingReportComponent }                  from './components/reports/billing/billingreport.component';
import { ClientReportComponent }                   from './components/reports/client/clientreport.component';
import { ReconciliationReportComponent }           from './components/reports/reconciliation/reconciliationReport.component';
import { MultipleSubmissionReportComponent }       from './components/reports/invoices/invoices.component';

import { DataImportComponent }                     from './components/administrator/dataimport/edit/dataimport.component';
import { DataImportListComponent }                 from './components/administrator/dataimport/list/dataimports.component';
import { ImportedBillsComponent }                  from './components/administrator/v2invoicing/importedBills/importedbills.component';

import { NomineeComponent }                        from "./components/accountdetails/nominee/nominee.component";
import { UserSearchComponent }                     from "./components/administrator/userSearch/userSearch.component";
import { LinkedClientsComponent }                  from "./components/accountdetails/linkedClients/linkedClients.component";

import { RoleEditorComponent }                     from './components/su/roleeditor/roleeditor.component';

import { DashboardMessageComponent }               from "./components/messages/dashboardmessage/dashboardmessage.component";

import { FontAwesomeModule, FaIconLibrary }        from '@fortawesome/angular-fontawesome';
import { fas }									   from '@fortawesome/free-solid-svg-icons';
//import { initIconLibrary }                       from './icons';

import { ServiceAgreementComponent }               from './components/administrator/plans/edit/serviceAgreement/render/serviceAgreement.component';
import { ProviderSpendComponent }                  from "./components/providerSpend/providerSpend.component";
import { PlanUtilisationComponent }                from "./components/planUtilisation/planUtilisation.component";
import { ProviderInvoicesComponent }               from "./components/providerInvoices/providerInvoices.component";

//export const options: Partial<IConfig> | (() => Partial<IConfig>) = undefined;

registerLocaleData(localeAu, 'en-AU', localeAuExtra);

@NgModule({
	"declarations": [
		AppComponent,
		ChatbotComponent,
		VersionCheckComponent,
		HeaderComponent,
		GlobalSearchComponent,
		LoginComponent,
		DashboardComponent,
		AdminDashboardComponent,
		AttachmentDialogComponent,
		SupportCoordinatorDashboardComponent,
		SupportCoordinatorTransactionsComponent,
		SupportCoordinatorClientsComponent,
		SupportCoordinatorInvoicesComponent,
		AccountPermissionsComponent,
		AccountRolesComponent,
		ParticipantDashboardComponent,
		ChangePwdComponent,
		OverlayComponent,
		MenuComponent,
		ForgotPasswordComponent,
		ResetPasswordComponent,
		CreateUserComponent,
		PlanCategoryAllocationChartComponent,
		AccessDeniedComponent,
		ClientCheckComponent,
		NomineesComponent,
		TasksComponent,
		TaskSummaryComponent,
		TaskListComponent,
		UpdateNotesDialogComponent,
		FileNotesComponent,
		InvoiceClientSearchComponent,
		InvoiceProviderSearchComponent,
		LinkedClientsComponent,
		NomineeComponent,
		// TransactionSummaryComponent,
		// TransactionComponent,
		CategoryTransactionsComponent,
		// ProvidersDialogComponent,
		LinkedSupportCoordinatorsComponent,
		ListAccountsComponent,
		XeroComponent,
		PaginationComponent,
		InvoiceWidgetComponent,
		RecentInvoicesComponent,
		ClientInvoiceComponent,
		ProvidersComponent,
		ProviderComponent,
		ProviderBillsComponent,
		ProviderNotesComponent,
		AllClientInvoicesComponent,
		AuMobileValidatorDirective,
		AuPhoneValidatorDirective,
		AccountDetailsComponent,
		AccountCredentialsComponent,
		LoginHistoryComponent,
		FileSizePipe,
		DocumentFilterComponent,
		NewExtractComponent,
		UserSearchComponent,
		// GovernmentPaymentFileComponent,
		ProdaFileListComponent,
		ProdaSubmissionComponent,
		ProdaResponseComponent,
		ProdaReconcilliationComponent,
		MaintenanceComponent,
		FindInvoiceComponent, // New invoicing
		EditInvoiceComponent, // New invoicing
		InvoiceLineItemComponent, // New invoicing
		InvoiceAttachmentComponent, // New invoicing
		PlansDialogComponent, // New invoicing
		BillSaveDialogComponent, // New invoicing
		BillReopenDialogComponent, // New invoicing
		TableHeaderComponent,
		TableHeader2Component,
		EditPlanComponent,
//		PlanSupportCategoryComponent,
		PlanSupportItemComponent,
		// NDISPriceListComponent,
		// NDISPriceEditComponent,
		LinkedProvidersComponent,
		ClientsWithoutPlansComponent,
		ClientsWithTrafficLightsComponent,
		PlansExpiringSoonComponent,
		NewClientsComponent,
		ClientSearchComponent,
		FileNotesWidgetComponent,
		NoteDialogComponent,
		BillsUnderInvestigationComponent,
		InvestigationFilterComponent,
		DataImportComponent,
		DataImportListComponent,
		ImportedBillsComponent,
		QuarantinedFundsComponent, // <-- ToDo: Remove this
		ServiceAgreementComponent,
		ProviderSpendComponent,
		PlanUtilisationComponent,
		ProviderInvoicesComponent,
		DashboardMessageComponent,

		// Reporting
		ReportsComponent,
		BillingReportComponent,
		ClientReportComponent,
		RoleEditorComponent,
		MultipleSubmissionReportComponent,
		ReconciliationReportComponent,
		DataEntryReportComponent
	],
	"imports": [
		BrowserModule,
		FormsModule,
		AppRoutingModule,
		HttpClientModule,
		//AmplifyAuthenticatorModule,
		FontAwesomeModule,
		FileUploadModule,
		NgxMaskDirective,
		NgxMaskPipe,
		TypeaheadModule,
	],
	"providers": [
		{ "provide": LOCALE_ID, "useValue": "en-AU"},
		provideNgxMask(),
		DataExchangeService
	],
	"bootstrap": [AppComponent]
})


export class AppModule {

	constructor(private injector: Injector, library: FaIconLibrary) {

		ServiceLocator.injector = this.injector;
		library.addIconPacks(fas);
	}
}
