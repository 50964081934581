import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrivateComponent } from "@classes/private.component";
import { User, UserType } from "@classes/user";
import { ClientStatus, AccountStatus, ExitReason } from "@classes/clientStatus";
import { MenuBuilder } from "@services/navmenu.service";
import { OverlayService } from "@services/overlay.service";
import { BankInformation, BankInformationUtils } from "@classes/bankinfo";
import { Region, RegionUtils } from "@classes/regions";
import { Plan, PlanStatus } from "@classes/plans";
import { AttachedFile } from "@classes/files";
import { AttachmentTarget, AttachmentTargetType, AttachmentTargetUtils  } from '@classes/attachments';
import { PlanService } from "@services/plan.service";
import { AttachmentsService } from "@services/attachments.service";
import { LinkedProvider, ProviderService } from "@services/provider.service";
import { FileManager } from "@classes/filemanager";
import { FileMimeTypes } from "@classes/files";
import { FileNote, FileNoteStatus } from "@classes/filenotes";
import { FileNotesComponent } from '@components/notes/notes.component';
import { FileNotesService } from "@services/filenotes.service";
import { DataExchangeService } from "@services/dataexchange.service";
import { Table, TableColumnHeading, SortType, StaticDataSource } from "@classes/tables";
import { ErrorUtils } from "@classes/errors";
import { Utils } from "@classes/utils";
import { Address, AddressClass } from "@classes/address";
import { saveAs } from 'file-saver';
import { Gender } from "@classes/gender";
import { ContactPreference } from "@classes/contactPreference";
import { AttachmentType } from "@classes/attachmentType";
import { UserAccountService } from "@services/accounts.service";
import { LinkedUserService } from "@services/linkedusers.service";
import { SyncErrorService } from '@services/syncerror.service';
import moment from 'moment';
import { IconName } from '@fortawesome/free-solid-svg-icons';
import { DefaultValueAccessor } from '@angular/forms';
import { isString } from 'lodash';

class DateProxy {

	public static readonly dateFormat: string = 'DD/MM/YYYY';

	private _strValue: string = '';
	private _parsedDate: moment.Moment;

	constructor(private srcObject?: object, private fieldName?: string) {
		if (!!this.srcObject && this.srcObject[this.fieldName]) {
			this._parsedDate = moment(this.srcObject[this.fieldName]);
			this._strValue = this._parsedDate.format(DateProxy.dateFormat);
		}
	}

	public get value(): string {
		return this._strValue;
	}

	public get isValid(): boolean {
		return this._parsedDate === undefined ? false : this._parsedDate.isValid();
	}

	public set value(_value: string) {
		this._strValue = _value;
		try {
			this._parsedDate = moment(this._strValue, DateProxy.dateFormat, true);
		}
		catch (e) {
			this._parsedDate = undefined;
		}

		if (this.isValid && this.srcObject) {
			this.srcObject[this.fieldName] = this._parsedDate.toDate();
		}
	}
}

enum Tabs {
	account, address, plans, banking, credentials, access, documents, notes, providers, nominees, roles, permissions, clients
}

@Component({
	"styleUrls": ["./accountdetails.component.scss"],
	"templateUrl": "./accountdetails.component.html"
})
export class AccountDetailsComponent extends PrivateComponent implements OnInit {

	@ViewChild('attachmentDialog') private attachmentDialog: TemplateRef<any>;
	@ViewChild('statementDialog') private statementDialog: TemplateRef<any>;
	@ViewChild('transactionReportTemplate') private transactionReportTemplate: TemplateRef<any>;

	@ViewChild('emptyTarget') private emptyTarget: TemplateRef<any>;
	@ViewChild('invoiceTarget') private invoiceTarget: TemplateRef<any>;
	@ViewChild('planTarget') private planTarget: TemplateRef<any>;
	@ViewChild('providerTarget') private providerTarget: TemplateRef<any>;
	@ViewChild('filenoteTarget') private filenoteTarget: TemplateRef<any>;
	@ViewChild(FileNotesComponent) private notesComponent: FileNotesComponent;
	@ViewChild('confirmDeleteAdminDialog') private confirmDeleteAdminDialog: TemplateRef<any>;

	private readonly documentTemplates: Map<AttachmentTargetType, TemplateRef<any>> = new Map<AttachmentTargetType, TemplateRef<any>>();

	private readonly dobFormat = "DD/MM/YYYY";
	readonly states: string[] = ['', 'ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];
	readonly statuses: AccountStatus[] = AccountStatus.allValues;
	readonly exitReasons: ExitReason[] = ExitReason.allValues;

	protected now: string = moment().format();

	private _isSelf: boolean = false;
	private _masqueradeUser: User = undefined;
	private _bank: BankInformation = BankInformationUtils.newInfo();
	private _plans: Plan[] = [];
	private _linkedProviders: LinkedProvider[] = [];
	// private _allProviders: Provider[] = [];
	private _documents: AttachedFile[] = [];
	private _notes: FileNote[] = [];
	private _fileMimeTypes: FileMimeTypes = new FileMimeTypes();

	public statusDateProxy: DateProxy = new DateProxy();
	public dobDateProxy: DateProxy = new DateProxy();
	public readonly maxNoteContentChars: number = 100;

	public statusString(status: AccountStatus): string {
		return AccountStatus.toString(status);
	}

	public exitReasonString(reason: ExitReason): string {
		return ExitReason.toString(reason);
	}

	public readonly accountStatus = {
		"active": AccountStatus.active,
		"inactive": AccountStatus.inactive,
		"deceased": AccountStatus.deceased,
		"prospective": AccountStatus.prospective
	};

	private attachmentTargetType: any = AttachmentTargetUtils.targetTypes();

	get fileNotes(): FileNote[] { return this._notes; }
	private _noteBeingEdited: FileNote = undefined;

	public get stickyNotes(): FileNote[] {
		return this._notes.filter( note => note.sticky );
	}

	public get importantNotes(): FileNote[] {
		return this._notes.filter( note => {
			if (note.sticky || note.status === FileNoteStatus.completed) {
				return false;
			}

			if (!!note.reminder) {
				return this.dateBetweenDays(note.reminder, 0, 7) || this.isPastDate(note.reminder);
			}
			else if (!!note.eventDate) {
				return this.dateBetweenDays(note.eventDate, -7, 7);
			}

			return false;
		});
	}

	private _dataLoaded: boolean = false;

	private _currentTab: Tabs = Tabs.account;
	public get currentTab(): Tabs { return this._currentTab; }

	public authCode: string = '';

	public readonly tab = {
		"account": Tabs.account,
		"address": Tabs.address,
		"plans": Tabs.plans,
		"bank": Tabs.banking,
		"credentials": Tabs.credentials,
		"access": Tabs.access,
		"documents": Tabs.documents,
		"notes": Tabs.notes,
		"nominees": Tabs.nominees,
		"providers": Tabs.providers,
		"permissions": Tabs.permissions,
		"roles": Tabs.roles,
		"clients": Tabs.clients
	};

	model: any = {
		"firstName": undefined,
		"lastName": undefined,
		"givenName": undefined,
		"dob": undefined,
		"region": undefined,
		"ndisNumber": undefined,
		"active": true,
		"address": {},
		"postalAddress": {},
		"markPlanAsDeceased": false,
		"markPlanAsTerminated": false,
		"receiveStatements": false,
		"last_sync_at": null,
		"sync_errors": null
	};

	// Table headings for list of plans
	private readonly tableHeadings: TableColumnHeading[] = [
		{"propName": undefined, "displayName": "", "sortType": SortType.none },
		{"propName": "status", "displayName": "Status", "sortType": SortType.text },
		{"propName": "startDate", "displayName": "Start Date", "sortType": SortType.date },
		{"propName": "endDate", "displayName": "End Date", "sortType": SortType.date },
		{"propName": "total", "displayName": "Budget", "sortType": SortType.numeric },
		{"propName": "region", "displayName": "Region", "sortType": SortType.text },
		{"propName": "pace", "displayName": "PACE", "sortType": SortType.text },
		{"propName": undefined, "displayName": "", "sortType": SortType.none }
	];

	table: Table<Plan> = new Table('userplans', this.tableHeadings);

	private readonly documentTableHeadings: TableColumnHeading[] = [
		{"propName": "name", "displayName": "Name", "sortType": SortType.text },
		{"propName": "attachmentType", "displayName": "Type", "sortType": SortType.text },
		{"propName": "size", "displayName": "Size", "sortType": SortType.numeric },
		{"propName": null, "displayName": "Linked To", "sortType": SortType.none },
		{"propName": "dateAdded", "displayName": "Date Added", "sortType": SortType.date }
	];

	documentsTable: Table<AttachedFile> = new Table('documentstable', this.documentTableHeadings);

	get bank(): BankInformation { return this._bank; }
	get masqueradeUser(): User { return this._masqueradeUser; }
	get isSelf(): boolean { return this._isSelf; }
	get masqueradeUserName(): string { return this._masqueradeUser ? `${this._masqueradeUser.firstName} ${this._masqueradeUser.lastName}` : ''; }
	get masqueradeUserId(): string { return this._masqueradeUser ? this._masqueradeUser.id : ''; }
	get hasCognitoAccount(): boolean { return this.currentUser ? !!this.currentUser.id : false; }
	get clientId(): string { return this.currentUser ? this.currentUser.id : undefined; }
	get currentUser(): User {
		return this._isSelf ? this.user : this._masqueradeUser;
	}

	get canSave(): boolean {
		return [
			this.emailValid,
			this.postcodeValid,
			this.postalPostcodeValid,
			this.phoneValid
		].every(Boolean);
	}

	get emailValid(): boolean {
		if(!this.email) return true;
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return regex.test(this.model.email.trim());
	}

	get postcodeValid(): boolean {
		if(!this.model.address || !this.model.address.postcode) return true;
		const regex = /^\d{4}$/;
		return regex.test(this.model.address.postcode.trim());
	}

	get postalPostcodeValid(): boolean {
		if(!this.model.postalAddress || !this.model.postalAddress.postcode) return true;
		const regex = /^\d{4}$/;
		return regex.test(this.model.postalAddress.postcode.trim());
	}

	get phoneValid(): boolean {
		if(!this.phone) return true;
		const regex = /^[0-9 ()+]*$/;
		return regex.test(this.phone.trim());
	}

	get canEdit(): boolean {
		return 	(this.hasPermission('Account Details', 'Edit Other Account Details') && 
				[this.userType.participant, this.userType.user].includes(this.accountType)) ||
				(this.hasPermission('Account Details', 'Edit Admin Account Details') && 
				[this.userType.admin].includes(this.accountType));
	}

	get isAdmin(): boolean {
		return this.user ? this.user.accountType === this.userType.admin : false;
	}

	get viewingAdmin(): boolean {
		if(!this._masqueradeUser) return true;
		return this._masqueradeUser.accountType ? this._masqueradeUser.accountType === this.userType.admin : true;
	}

	get syncSuccess(): boolean {
		if(this.model.sync_errors.success !== undefined) {
			return this.model.sync_errors.success;
		} else if (this.model.sync_errors) {
			return this.model.sync_errors == "Sync Success";
		} else {
			return false;
		}
	}

	get firstName(): string {
		return this.currentUser && this.currentUser.firstName || "";
	}

	get lastName(): string {
		return this.currentUser && this.currentUser.lastName || "";
	}

	get givenName(): string {
		return this.currentUser && this.currentUser.givenName || "";
	}

	get accountType(): UserType {
		// Trap for young players here... UserType.Admin === 0,
		// therefore this.currentUser.accountType || undefined will return undefined for administrators
		return this.currentUser ? this.currentUser.accountType : undefined;
	}

	get ndisNumber(): string {
		return this.currentUser && this.currentUser.ndisNumber || "";
	}

	get status(): ClientStatus {
		return this.currentUser && this.currentUser.status || ClientStatus.empty();
	}

	get region(): Region {
		return this.currentUser && this.currentUser.region || undefined;
	}

	get email(): string {
		return this.model.email || "";
	}

	get accountHasLogin(): boolean {
		return this.currentUser && this.currentUser.hasLogin;
	}

	get phone(): string {
		return this.model.phone || "";
	}

	get userTypeDisplay(): string {
		return this.currentUser && UserType.toString(this.currentUser.accountType) || "";
	}

	get isParticipant(): boolean {
		return this.currentUser && this.currentUser.accountType === UserType.Participant;
	}

	get isNominee(): boolean {
		return this.currentUser && [UserType.User, UserType.SupportCoordinator].includes(this.currentUser.accountType);
	}
	
	get isPace(): boolean {
		if(this._plans && this._plans.length){
			return this._plans.filter( plan => plan.pace ).length > 0;
		}
		return false;
	}

	get dataLoaded(): boolean {
		return this._dataLoaded;
	}

	get clientName(): string {
		return this.currentUser && `${this.currentUser.firstName} ${this.currentUser.lastName}` || "";
	}

	get linkedProviders(): LinkedProvider[] {
		return this._linkedProviders;
	}

	set linkedProviders(value: LinkedProvider[]) {
		this._linkedProviders = value;
	}

	// get allProviders(): Provider[] {
	// 	return this._allProviders;
	// }

	get hasDocuments(): boolean {
		return this._documents && this._documents.length > 0;
	}

	set ndisNumber(value: string) {
		if (this._masqueradeUser) {
			this._masqueradeUser.ndisNumber = value;
		}
	}

	set region(value: Region) {
		if (this._masqueradeUser) {
			this._masqueradeUser.region = value;
		}
	}

	get allRegions(): Region[] {
		return RegionUtils.allValues();
	}

	get plans(): Plan[] {
		return this._plans;
	}

	public get currentPlan():Plan {
		return this._plans.find( plan => plan.status === PlanStatus.current ) || undefined;
	}
	public get currentPlanRegionName():string {
		return this.currentPlan ? this.regionName(this.currentPlan.region) : '';
	}
	public get currentPlanRegion():number {
		return this.currentPlan ? this.currentPlan.region : undefined;
	}

	public allGenders: () => Gender[] = Gender.allValues;
	public genderName: (gender: Gender) => string = Gender.toString;

	public allContactMethods: () => ContactPreference[] = ContactPreference.allValues;
	public contactMethodName: (method: ContactPreference) => string = ContactPreference.toString;

	public get clientAge(): string {
		if (!this.currentUser || this.currentUser.accountType !== UserType.Participant || !this.currentUser.dob) {
			return "";
		}

		const dob = moment(this.currentUser.dob);
		const now = moment();
		const years = now.diff(dob, 'years');
		const months = now.diff(dob.add(years, 'years'), 'months');
		if (months === 0) {
			return `${years} year${years === 1 ? '' : 's'}`
		}
		else {
			return `${years} year${years === 1 ? '' : 's'}, ${months} month${months === 1 ? '' : 's'}`;
		}
	}

	public get timeWithNdsp(): string {
		let currentTime = new Date();

		let totalMonths: number = 	currentTime.getMonth() - 
									this.currentUser.createdAt.getMonth() +
									12 * (currentTime.getFullYear() - this.currentUser.createdAt.getFullYear());

		let months: number = totalMonths % 12;

		let years: number = (totalMonths - months) / 12;

		if((months <= 0) && (years <= 0)) {
			let days: number = currentTime.getDate() - this.currentUser.createdAt.getDate();
			return `${days} day${days === 1 ? '' : 's'}`;
		} else if (months <= 0) {
			return `${years} year${years === 1 ? '' : 's'}`;
		} else if (years <= 0) {
			return `${months} month${months === 1 ? '' : 's'}`;
		} else {
			return `${years} year${years === 1 ? '' : 's'}, ${months} month${months === 1 ? '' : 's'}`;
		}
	}

	protected toIcon(icon: string): IconName {
		return icon as IconName;
	}

	public isCurrentPlan(plan: Plan): boolean {
		return plan.status === PlanStatus.current;
	}

	public regionName(region: Region): string {
		return RegionUtils.toString(region);
	}

	public changeTab(tab: Tabs): boolean {
		this._currentTab = tab;
		if (this._masqueradeUser) {
			DataExchangeService.set(tab, `accountDetails.tab.${this._masqueradeUser.id}`);
			if (tab === Tabs.notes) {
				this.loadFileNotes(this.isSelf ? this.user.id : this._masqueradeUser.id);
			}
		}
		return false;
	}

	private readonly accountTypes = new Map<UserType, String>([
		[UserType.Admin, UserType.toString(UserType.Admin)],
		[UserType.SupportCoordinator, UserType.toString(UserType.SupportCoordinator)],
		[UserType.User, UserType.toString(UserType.User)],
		[UserType.Participant, UserType.toString(UserType.Participant)]
	]);

	readonly accountTypeEntries = Array.from(this.accountTypes.entries());
	readonly userTypeParticipant = UserType.Participant;
	readonly userTypeUser = UserType.User;
	readonly userTypeAdmin = UserType.Admin;

	readonly userType = {
		"admin": UserType.Admin,
		"supportCoordinator": UserType.SupportCoordinator,
		"participant": UserType.Participant,
		"user": UserType.User
	};

	constructor(
		private route: ActivatedRoute,
		private planService: PlanService,
		private providerService: ProviderService,
		private fileNotesService: FileNotesService,
		private accountsService: UserAccountService,
		private linkedUserService: LinkedUserService,
		private syncErrorService: SyncErrorService,
		private attachmentsService: AttachmentsService) {
		super();

		const original = DefaultValueAccessor.prototype.registerOnChange;

		DefaultValueAccessor.prototype.registerOnChange = function (fn) {
			return original.call(this, value => {
				const trimmed = isString(value) ? value.trim() : value;
				return fn(trimmed);
			});
		};
	}

	public async saveUser(): Promise<void> {
		OverlayService.show(`Saving${Utils.ellipsis}`);

		// let user = this.isSelf ? this.user : this._masqueradeUser;
		let clone = this.isSelf ? new User(this.user) : new User(this._masqueradeUser);
		let updatePlans = false;

		clone.firstName = this.model.firstName;
		clone.lastName = this.model.lastName;
		clone.givenName = this.model.givenName;
		clone.inactive = !this.model.active;
		clone.gender = this.model.gender;
		clone.contactMethod = this.model.contactMethod;
		clone.disability = this.model.disability;
		clone.phone = this.model.phone;
		clone.email = this.model.email;
		clone.address = {...this.model.address};
		clone.postalAddress = {...this.model.postalAddress};
		if (this.model.status) {
			clone.status = ClientStatus.clone(this.model.status);

			switch (this.model.status.accountStatus) {
				case AccountStatus.deceased:
					updatePlans = this.model.markPlanAsDeceased;
					break;
				case AccountStatus.inactive:
					updatePlans = this.model.markPlanAsTerminated;
					break;
				default:
					updatePlans = false;
					break;
			}
		}

		clone.ndisNumber = clone.accountType === UserType.Participant ? this.model.ndisNumber : null;
		clone.region = clone.accountType === UserType.Participant ? this.model.region : null;
		clone.dob = clone.accountType === UserType.Participant ? this.stringToDate(this.model.dob) : null;
		clone.receiveStatements = clone.accountType === UserType.Participant ? this.model.receiveStatements : undefined;

		try {
			await this.userService.updateUser(clone, updatePlans);
			let promises = [ this.loadUser(clone.id) ];
			if (updatePlans) {
				promises.push(this.loadPlans(clone.id, true));
			}
			await Promise.all(promises);
			this.reloadUser();
			OverlayService.hide();
		}
		catch (e) {
			console.log(e);
			OverlayService.hide();
			OverlayService.showError("Error", "Unable to update user");
		}

	}

	private sameAddresses(a: Address, b: Address): boolean {

		return AddressClass.addressFields.every( fieldName => {

			// Compare 'falsey' values first
			if (!a[fieldName] && !b[fieldName]) {
				return true;
			}
			return a[fieldName] === b[fieldName];
		});
	}

	public userUnchanged(): boolean {
		if (!this._dataLoaded) {
			return true;
		}

		const user = this.isSelf ? this.user : this._masqueradeUser;
		let tests = [
			user.firstName === this.model.firstName,
			user.lastName === this.model.lastName,
			user.email === this.model.email,
			user.phone === this.model.phone,
			user.givenName === this.model.givenName,
			user.receiveStatements === this.model.receiveStatements
		];

		if (user.accountType === UserType.Participant) {
			tests.push( user.region === this.model.region );
			tests.push( user.ndisNumber === this.model.ndisNumber );
			tests.push( this.sameAddresses(user.address || <Address>{}, this.model.address || <Address>{}) );
			tests.push( this.sameAddresses(user.postalAddress || <Address>{}, this.model.postalAddress || <Address>{}) );
			// tests.push( user.inactive !== this.model.active );
			tests.push( this.dateToString(user.dob) === this.dateToString(this.model.dob) );
			tests.push( this.status.accountStatus === this.model.status.accountStatus );
			tests.push( this.status.exitReason === this.model.status.exitReason );
			tests.push( this.status.notes === this.model.status.notes );
			tests.push( this.dateToString(this.status.statusDate) === this.dateToString(this.model.status.statusDate) );
			tests.push( user.gender === this.model.gender );
			tests.push( user.contactMethod === this.model.contactMethod );
			tests.push( user.disability === this.model.disability );
		}

		return tests.every( test => test === true );
	}

	public attachmentTypeName: (AttachmentType) => string = AttachmentType.toString;
	public filter: Set<AttachmentType> = new Set<AttachmentType>([
		AttachmentType.statement,
		AttachmentType.quote,
		AttachmentType.ndsp_doc,
		AttachmentType.rAndN,
		AttachmentType.support_letter,
		AttachmentType.ndis_plan,
		AttachmentType.sa,
		AttachmentType.other
	]);

	protected bsbPaste(event: ClipboardEvent) {
		let stripped = event.clipboardData.getData('text');
		stripped = stripped.replace(/\D/g,'').substring(0, 6);
		event.preventDefault()
		this.bank.bankAccountBSB = stripped;
	}

	protected accPaste(event: ClipboardEvent) {
		let stripped = event.clipboardData.getData('text');
		stripped = stripped.replace(/\D/g,'').substring(0, 9);
		event.preventDefault()
		this.bank.bankAccountNumber = stripped;
	}

	private stringToDate(value: string): Date {
		if (!value) {
			return undefined;
		}

		const m = moment(value, this.dobFormat, true);
		if (m.isValid()) {
			return m.startOf('day').toDate();
		}

		return undefined;
	}

	private dateToString(date: Date): string {
		if (date === null || date === undefined) {
			return "";
		}
		const m = moment(date);
		return m.isValid() ? m.format(this.dobFormat) : "";
	}

	private loadUser(userId: string, forceRefresh: boolean = false): Promise<void> {
		if (this.isSelf) {
			this.model.firstName = this.user.firstName;
			this.model.lastName = this.user.lastName;
			this.model.givenName = this.user.givenName;
			this.model.email = this.user.email;
			this.model.phone = this.user.phone;
			this.model.dob = this.dateToString(this.user.dob);
			this.model.ndisNumber = this.user.accountType === UserType.Participant ? this.user.ndisNumber : undefined;
			this.model.region = undefined; // this.user.accountType === UserType.Participant ? this.user.region : undefined;
			this.model.active = !this.user.inactive;
			this.model.address = this.user.address ? {...this.user.address} : <Address>{};
			this.model.postalAddress = this.user.postalAddress ? {...this.user.postalAddress} : <Address>{};
			this.model.deleted = this.user.deleted;
			this.dobDateProxy = new DateProxy(this.model, 'dob');
			return Promise.resolve();
		}

		return this.userService.loadUser(userId, forceRefresh).then( user => {

			this.mruService.push(user);
			// console.log(user);
			this._masqueradeUser = user;
			this.model.firstName = this._masqueradeUser.firstName;
			this.model.lastName = this._masqueradeUser.lastName;
			this.model.givenName = this._masqueradeUser.givenName;
			this.model.email = this._masqueradeUser.email;
			this.model.phone = this._masqueradeUser.phone;
			this.model.dob = this._masqueradeUser.dob ? new Date(this._masqueradeUser.dob.valueOf()) : undefined;
			this.model.ndisNumber = this._masqueradeUser.accountType === UserType.Participant ? this._masqueradeUser.ndisNumber : undefined;
			this.model.region = this._masqueradeUser.accountType === UserType.Participant ? this._masqueradeUser.region : undefined;
			this.model.active = !this._masqueradeUser.inactive;
			this.model.status = ClientStatus.clone(this._masqueradeUser.status);
			this.model.address = this._masqueradeUser.address ? {...this._masqueradeUser.address} : <Address>{};
			this.model.postalAddress = this._masqueradeUser.postalAddress ? {...this._masqueradeUser.postalAddress} : <Address>{};
			this.model.receiveStatements =this._masqueradeUser.accountType === UserType.Participant ? this._masqueradeUser.receiveStatements : undefined;
			this.model.gender = this._masqueradeUser.gender;
			this.model.contactMethod = this._masqueradeUser.contactMethod;
			this.model.disability = this._masqueradeUser.disability;
			this.model.deleted = this._masqueradeUser.deleted;
			this.model.last_sync_at = this._masqueradeUser.last_sync_at;
			this.model.sync_errors = this.syncErrorService.format(this.masqueradeUser.sync_errors);

			this.statusDateProxy = new DateProxy(this.model.status, 'statusDate');
			this.dobDateProxy = new DateProxy(this.model, 'dob');
			return Promise.resolve();
		});
	}

	public reloadUser() {
		this.loadUser(this.masqueradeUser.id, true);
	}

	private async loadLinkedProviders(userId: string): Promise<any> {
		if (!this.isSelf) {
			try {
				this._linkedProviders = await this.providerService.loadLinkedProviders(userId);
			}
			catch (e) {
				this._linkedProviders = [];
			}
		}
	}

	// private async loadAllProviders(): Promise<any> {
	// 	if (!this.isSelf) {
	// 		try {
	// 			this._allProviders = await this.providerService.getProviders();
	// 		}
	// 		catch (e) {
	// 			this._allProviders = [];
	// 		}
	// 	}
	// }

	private loadBankInfo(userId: string): Promise<any> {
		return this.userService.loadBankInfo(userId).then( bank => {
			this._bank = bank;
			return Promise.resolve();
		});
	}

	private loadPlans(userId: string, forceReload: boolean = false): Promise<any> {
		return this.planService.listPlans(userId, forceReload).then( plans => {
			this._plans = plans;
			this.table.sourceData = StaticDataSource.from(this._plans);
			this.model.region = this.currentPlanRegion;
			return Promise.resolve();
		});
	}

	public filterDocuments() {
		this.documentsTable.sourceData = StaticDataSource.from(this._documents.filter( document => this.filter.has(document.attachmentType !== undefined ? document.attachmentType : AttachmentType.other) ));
	}

	protected loadDocuments(userId: string): Promise<any> {
		if (!this.isSelf) {
			return this.userService.loadUserDocuments(userId).then( data => {
				this._documents = data;
				this.filterDocuments();
				return Promise.resolve();
			});
		}
		else {
			return Promise.resolve();
		}
	}

	protected getPlanStatusDescription: (status: PlanStatus) => string = PlanStatus.toString;

	protected getDocumentTemplateName(target: AttachmentTarget): TemplateRef<any> {
		return this.documentTemplates.has(target.type) ? this.documentTemplates.get(target.type) : this.emptyTarget;
	}

	private loadFileNotes(userId: string): Promise<any> {
		return this.fileNotesService.listNotes(userId).then( notes => {
			this._notes = notes.sort( (a, b) => {
				return b.createdDate.valueOf() - a.createdDate.valueOf();
			});
			return Promise.resolve();
		});
	}

	private loadData(userId): Promise<void> {

		this._dataLoaded = false;

		OverlayService.show();

		return this.loadUser(userId).then( () => {

			if ([UserType.User, UserType.SupportCoordinator].includes(this.accountType)) {

				let promises = [
					this.loadBankInfo(userId)
				];

				this._plans = [];

				// Administrators get to see file notes as well
				if (this.isAdmin) {
					promises.push( this.loadFileNotes(userId) );
				}

				return Promise.all(promises).then( () => {
					return Promise.resolve();
				});
			}

			if (this.accountType === UserType.Participant) {

				// List of items that support coordinators and administrators can see
				let promises = [
					this.loadBankInfo(userId),
					this.loadPlans(userId),
					this.loadLinkedProviders(userId),
					// this.loadAllProviders(),
					this.loadDocuments(userId)
				];

				// Administrators get to see file notes as well
				if (this.isAdmin) {
					promises.push( this.loadFileNotes(userId) );
				}

				return Promise.all(promises).then( () => {
					return Promise.resolve();
				});
			}
			else {
				return Promise.resolve();
			}

		}).then( () => {

			this._dataLoaded = true;
			OverlayService.hide();
			return Promise.resolve();

		}).catch( err => {

			console.log(err);
			OverlayService.hide();

		});
	}

	ngAfterViewInit() {
		this.documentTemplates.set(AttachmentTargetType.invoice, this.invoiceTarget);
		this.documentTemplates.set(AttachmentTargetType.plan, this.planTarget);
		this.documentTemplates.set(AttachmentTargetType.provider, this.providerTarget);
		this.documentTemplates.set(AttachmentTargetType.note, this.filenoteTarget);

		if (!this._masqueradeUser || !this._masqueradeUser.id) {
			this._currentTab = Tabs.account;
		}
		else {
			this._currentTab = DataExchangeService.get(`accountDetails.tab.${this._masqueradeUser.id}`, false) || Tabs.account;
		}
	}

	ngOnInit() {
		super.ngOnInit();

		this.route.params.subscribe( params => {

			if (this.user) {
				const userId = params.userId || this.user.id;
				this._isSelf = userId === this.user.id;

				this.loadData(userId).then( () => {
					this.buildMenu();
					if (params.noteId) {
						const note = this._notes.find( note => note.id === params.noteId);
						if (note) {
							this.showNote(note);
						}
					}
				});
			}
		});

		this.buildMenu();
	}

	private buildMenu(): void {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		if (!this._isSelf) {
			if (this._masqueradeUser) {
				const label = this.isParticipant ? "Client Dashboard" : "User Dashboard";
				menuBuilder.addRoute("chart-pie", label, `/dashboard/${this._masqueradeUser.id}`);

				menuBuilder.addHandler("file-invoice-dollar", "Statements", () => { this.showStatementsDialog(); }, undefined, () => {
					return this.isParticipant && this.hasPermission('Users', 'Request Statement');
				});

				menuBuilder.addRoute("coins", "Provider Spend", `/${this._masqueradeUser.id}/providerspend`);

				if (this.isAdmin) { menuBuilder.addRoute("chart-bar", "Plan Utilisation", `/${this._masqueradeUser.id}/planutilisation`); }
			}
		}
		else {
			menuBuilder.addRoute("lock", "Change Password", "/password/change");
		}
		menuBuilder.done();
	}

	public requestAuthCode() {

		OverlayService.show();
		this.userService.requestAuthCode(this.clientId).then( result => {

			this._bank.authcodeRequested = result;
			OverlayService.hide();

			if (!result) {
				OverlayService.showError("Error", "Unable to change bank details for this client");
			}
			else {
				OverlayService.showSuccess("Unlock code generated", "Please see your authorised staff member to obtain the unlock code");
			}
		}).catch( err => {

			OverlayService.hide();
			const errorMessage = err.errorMessage || "Unable to change bank details for this client";
			OverlayService.showError("Error", errorMessage.replace(/^\[\d+\]\s/, ''));

		});
	}

	public unlockBankSection() {
		OverlayService.show();
		this.userService.unlock(this.clientId, this._bank.authcode).then( result => {
			OverlayService.hide();
			this._bank.isUnlocked = result;

			if (!result) {
				OverlayService.showError("Error", "Invalid unlock code provided");
			}
			else {
				OverlayService.showSuccess("Success", "Banking details unlocked");
			}
		}).catch( err => {
			OverlayService.hide();
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(err) || "Unable to unlock banking details for this client");
		});
	}

	public saveBankDetails(): void {
		const tests = [
			BankInformationUtils.validateBsb(this.bank.bankAccountBSB),
			BankInformationUtils.validateAccountNumber(this.bank.bankAccountNumber)
		];

		if (tests.every( result => result === true )) {
			OverlayService.show("Saving");
			this.userService.saveBankInfo(this.clientId, this.bank).then( () => {
				this.reloadUser();
				OverlayService.hide();
				this.bank.authcode = null;
				this.bank.isUnlocked = false;
				this.bank.authcodeRequested = false;

			}).catch( err => {

				console.log(err);
				OverlayService.hide();
				OverlayService.showError("Error", ErrorUtils.getErrorMessage(err, "Unable to save bank details"));

			});
		}
		else {
			OverlayService.showError("Error", "Invalid BSB or account number");
		}
	}

	public deleteBankDetails(): void {
		OverlayService.show("Deleting");

		this.userService.deleteBankInfo(this.clientId, this.bank).then( () => {
			this.loadBankInfo(this.clientId).then(() => {
				OverlayService.hide();
				this.bank.authcode = null;
				this.bank.isUnlocked = false;
				this.bank.authcodeRequested = false;
			});
		}).catch( err => {
			console.log(err);
			OverlayService.hide();
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(err, "Unable to delete bank details"));
		});

	}

	planStatusDescription: (planStatus: PlanStatus) => string = PlanStatus.toString;

	newPlan(): void{
		DataExchangeService.set(this._masqueradeUser.id, "planUser");
		this.router.navigate(["plan/new"]);
	}

	canDeletePlan(plan: Plan): boolean {
		return [PlanStatus.proposed].includes(plan.status);
		// return [PlanStatus.scheduled, PlanStatus.proposed].includes(plan.status);
	}

	async deletePlan($event: MouseEvent, plan: Plan): Promise<void> {
		$event.stopPropagation();

		// if (![PlanStatus.scheduled, PlanStatus.proposed].includes(plan.status)) {
		if (![PlanStatus.proposed].includes(plan.status)) {
			OverlayService.showError("Error", "Cannot delete this plan");
			return;
		}

		OverlayService.show(`Deleting plan${Utils.ellipsis}`);
		try {
			await this.planService.deletePlan(plan);
			this._plans = this._plans.filter( item => item.id !== plan.id );
			this.table.sourceData = StaticDataSource.from(this._plans);
			OverlayService.hide();
		}
		catch (e) {
			console.log(e);
			OverlayService.showError("Error", e.message);
		}
	}


	private dataURItoBlob(dataUrl: string): Promise<Blob> {
		return fetch(dataUrl).then(res => res.blob());
	}

	downloadDocument(document: AttachedFile) {
		FileManager.downloadFile(this.attachmentsService, document);
	}


	private isFutureDate(date: Date): boolean {
		return moment(date).startOf('day').isSameOrAfter( moment().startOf('day') );
	}

	private isPastDate(date: Date): boolean {
		return moment(date).startOf('day').isSameOrBefore( moment().startOf('day') );
	}

	private dateBetweenDays(date: Date, numDaysFrom: number, numDaysTo: number): boolean {
		const cutoffStart = moment().add(numDaysFrom, 'days').startOf('day');
		const cutoffEnd = moment().add(numDaysTo, 'days').startOf('day');
		return moment(date).isBetween(cutoffStart, cutoffEnd, null, '[]');
	}

	private saveNote(note: FileNote): void {
		OverlayService.show();
		this.fileNotesService.saveNote( note ).then( _ => {

			// Will be quick if the cache is still valid, otherwise a round-trip to the server is required
			return this.loadFileNotes(this.clientId);

		}).then( () => {

			OverlayService.hide();

		}).catch( e => {

			OverlayService.hide();
			console.log(e);
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "An unknown error occurred"));

		});
	}

	protected setNoteStatus(note: FileNote, status: FileNoteStatus): void {
		note.status = status;
		this.saveNote(note);
	}

	protected showAttachmentDialog(note: FileNote): void {
		const targets = [
			AttachmentTargetUtils.target(this.currentUser.id, AttachmentTargetType.client),
			AttachmentTargetUtils.target(note.id, AttachmentTargetType.note)
		];

		OverlayService.showTemplate(this.attachmentDialog, {"targets": targets, "files": note.attachments});
	}

	protected async saveAttachments($event: any) {
		if ($event.fileManager && $event.targets && $event.targets.length > 0) {
			OverlayService.show(`Saving${Utils.ellipsis}`);
			try {
				const result = await this.attachmentsService.saveAttachments($event.targets, $event.fileManager);

				result.savedFiles.forEach(file => {
					this._documents.push(file);
				});

				result.deletedFiles.forEach(fileId => {
					const idx = this._documents.findIndex( item => item.id === fileId );
					if (idx >= 0) {
						this._documents.splice(idx, 1);
					}
				});

				this.documentsTable.sourceData = StaticDataSource.from(this._documents);
				OverlayService.hide();
			}
			catch (e) {
				console.log(e);
				OverlayService.hide();
				OverlayService.showError("Error", "Unable to save attachment");
			}
		}
	}

	private getFileIcon(file: AttachedFile): string {
		return this._fileMimeTypes.getIcon(file.mimeType);
	}

	protected userUpdated($event: User) {
		this.loadUser($event.id);
	}

	private showStatementsDialog() {
		const now = moment();
		const startYear = 2020;
		const endYear = now.year();

		const context = {
			"month": now.month(),
			"year": now.year(),
			"email": false,
			"months": Array.from(Array(12).keys()).map( idx => {

				const d = new Date(now.year(), idx, 1);
				return {
					"value": idx,
					"name": d.toLocaleString('default', {"month": "long"})
				};
			}),
			"years": Array.from(Array(1 + endYear - startYear).keys()).map( idx => idx + startYear )
		};

		OverlayService.showTemplate(this.statementDialog, context);
	}

	public canCreateStatement(month: number, year: number): boolean {
		const selected = moment( [year, month, 1] ).startOf('month');
		const now = moment().startOf('month');
		return selected.isSameOrBefore(now);
	}

	public closeDialog(): void {
		OverlayService.hide();
	}

	public generateStatement(context: any): void {
		OverlayService.hide();
		OverlayService.show(`Requesting statement${Utils.ellipsis}`);
		this.userService.requestStatement(this._masqueradeUser.id, context.year, context.month + 1, context.email).then( result => {

			OverlayService.hide();

			if (result.statements && result.statements.length) {
				const plural = `${result.statements.length === 1 ? "" : "s"}`;
				this.fileNotesService.invalidateCacheForUser(this._masqueradeUser.id);
				if (this._currentTab === Tabs.notes) {

					// Reload the filenotes after a short pause, giving the λ functions time to generate the statement
					this.reloadNotes(3000).then( () => {
						OverlayService.showSuccess(`Statement${plural} generated`, `If requested, the statement${plural} will be emailed within the next 2 hours`);
					});
				}
				else {
					OverlayService.showSuccess(`Statement${plural} generated`, `If requested, the statement${plural} will be emailed within the next 2 hours`);
				}
			}
			else if (result.description) {
				OverlayService.showError("Unable to generate statement", result.description);
			}

		}).catch( err => {
			console.log(err);
			OverlayService.hide();
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(err) || "Unable to generate statement");
		});
	}

	public reloadNotes(delayMilliseconds: number = 0): Promise<void> {
		OverlayService.show();
		return new Promise( (resolve, reject) => {

			this.fileNotesService.invalidateCacheForUser(this._masqueradeUser.id);
			setTimeout(() => {
				this.loadFileNotes(this._masqueradeUser.id).then( () => {
					OverlayService.hide();
					resolve();
				});
			}, delayMilliseconds);
		});
	}

	public transactionReportDialog($event: MouseEvent, plan: Plan) {
		const uiDateFormat = 'DDMMYYYY';
		$event.stopPropagation();
		const reportSettings = {
			"plan": plan,
			"ui": {
				"entirePlanDuration": true,
				"startDate": moment(plan.startDate).format(uiDateFormat),
				"endDate": moment(plan.endDate).format(uiDateFormat),
				get startDateValid() {
					return moment(this.startDate, uiDateFormat, true).isValid();
				},
				get endDateValid() {
					return moment(this.endDate, uiDateFormat, true).isValid();
				},
				get badDates() {
					if (this.entirePlanDuration) {
						return false;
					}
					const startDate = moment(this.startDate, uiDateFormat, true);
					const endDate = moment(this.endDate, uiDateFormat, true);
					if (!startDate.isValid() || startDate.isBefore(moment(plan.startDate)) || startDate.isAfter(moment(plan.endDate))) {
						return true;
					}
					if (!endDate.isValid() || endDate.isAfter(moment(plan.endDate)) || endDate.isBefore(moment(plan.startDate))) {
						return true;
					}

					return false;
				}
			},
			"startDate": plan.startDate,
			"endDate": plan.endDate
		};

		//console.log(reportSettings);
		OverlayService.showTemplate(this.transactionReportTemplate, reportSettings);
	}

	public async generateReport(reportSettings: any) {
		//console.log(reportSettings);
		if (reportSettings.entirePlanDuration) {
			reportSettings.startDate = reportSettings.plan.startDate;
			reportSettings.endDate = reportSettings.plan.endDate;
		}
		else {
			reportSettings.startDate = moment(reportSettings.ui.startDate, 'DD-MM-YYYY');
			reportSettings.endDate = moment(reportSettings.ui.endDate, 'DD-MM-YYYY');
		}

		OverlayService.show();
		try {
			const content = await this.planService.transactionReport(reportSettings.plan, reportSettings.startDate, reportSettings.endDate);
			const blob = await this.dataURItoBlob(content);

			OverlayService.showDialog("Download attachment", "Do you want to open this file in a new window, or save to your device?", [{
				"text": "Open in new window",
				"handler": () => {
					const blobUrl = URL.createObjectURL(blob);
					window.open(blobUrl, "_blank");
					OverlayService.hide();
				}
			}, {
				"text": "Save file",
				"handler": () => {
					OverlayService.hide();
					saveAs(blob, 'planReport.pdf');
				}
			}]);

			// OverlayService.hide();
		}
		catch (e) {
			console.log(e);
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "An unknown error occurred"));
		}

	}

	public parseDate(value: string): Date {
		return moment(value, 'YYYY-MM-DD').toDate();
	}

	public get showTerminationTransitionControls(): boolean {
		const tests = [
			this._masqueradeUser.status.accountStatus !== AccountStatus.inactive,
			this.model.status.accountStatus === AccountStatus.inactive,
			this.plans.some( plan => plan.status === PlanStatus.current )
		];

		return tests.every( item => item === true );
	}

	public get inactiveClient(): boolean {
		return [AccountStatus.inactive, AccountStatus.deceased].includes(this.model.status.accountStatus);
	}

	public get showDeceasedTransitionControls(): boolean {
		const tests = [
			this._masqueradeUser.status.accountStatus !== AccountStatus.deceased,
			this.model.status.accountStatus === AccountStatus.deceased,
			this.plans.some( plan => plan.status === PlanStatus.current )
		];

		return tests.every( item => item === true );
	}

	public checkStatusTransition(): void {
		if (this._masqueradeUser.status.accountStatus === AccountStatus.active) {
			switch (this.model.status.accountStatus) {
				case AccountStatus.inactive:
					this.statusDateProxy.value = moment().format(DateProxy.dateFormat);
					break;
				case AccountStatus.deceased:
					this.statusDateProxy.value = '';
					break;
				default:
					this.statusDateProxy.value = moment(this._masqueradeUser.status.statusDate).format(DateProxy.dateFormat);
					break;
			}
		}
	}

	public showNote(note: FileNote) {
		this.changeTab(Tabs.notes);
		setTimeout( () => { this.notesComponent.currentNote = note; }, 0);
	}

	public async confirmDeleteUser() {
		OverlayService.show();
		try {
			const linkedClients = await this.linkedUserService.getLinkedClients(this.masqueradeUser.id);
			const messageComponents = ["Are you sure you want to delete this account?"];
			if (linkedClients.length > 0) {
				messageComponents.push(`There ${linkedClients.length === 1 ? 'is' : 'are'} ${linkedClients.length} client${linkedClients.length === 1 ? '' : 's'} linked to this user`);
			}

			OverlayService.showDialog("Confirm", messageComponents.join("<br />"), [{
				"text": "Don't Delete",
				"handler": OverlayService.hide
			}, {
				"text": "Delete",
				"handler": this.deleteUser.bind(this)
			}]);


		}
		catch (e) {
			OverlayService.hide();
		}
	}

	public async confirmDeleteAdmin() {
		OverlayService.show();
		try {
			const [
				numAssignedTasks,
				allAdmins
			] = await Promise.all([
				this.fileNotesService.taskMetadata(this.masqueradeUser.id, undefined, undefined, {type: "all", from: undefined, to: undefined}),
				this.accountsService.getAdminAccounts(true)
			]);

			//console.log(allAdmins);

			if (numAssignedTasks === 0) {
				this.confirmDeleteParticipant();
			}
			else {
				OverlayService.showTemplate(this.confirmDeleteAdminDialog, {"numTasks": numAssignedTasks, "admins": allAdmins, "heir": undefined});
			}
		}
		catch (e) {
			OverlayService.hide();
			console.log(e);
		}
	}

	public confirmDeleteParticipant() {
		OverlayService.showDialog("Confirm", "Are you sure you want to delete this user account?", [{
			"text": "Don't Delete",
			"handler": OverlayService.hide
		}, {
			"text": "Delete",
			"handler": this.deleteUser.bind(this)
		}]);
	}

	public confirmDeleteBankDetails() {
		OverlayService.showDialog("Confirm", "<p>Are you sure you want to delete these bank details?</p><p><strong>Note:</strong> If the Bank Details you are deleting are also in Xero, <br/>please remove them from Xero manually, as they will not <br/>automatically be removed when the next sync occurs.</p>", [{
			"text": "Don't Delete",
			"handler": OverlayService.hide
		}, {
			"text": "Delete",
			"handler": this.deleteBankDetails.bind(this)
		}]);
	}

	public async deleteAdmin(assignTasksTo: string) {
		const defaultErrorMessage = "Unable to delete user account";
		OverlayService.show(`Deleting user${Utils.ellipsis}`);
		try {
			const success = await this.accountsService.deleteUser(this._masqueradeUser.id, assignTasksTo);
			if (!success) {
				throw new Error(defaultErrorMessage);
			}
			this.accountsService.removeUserFromCache(this._masqueradeUser.id);
			await this.reloadUser();
			OverlayService.hide();
			OverlayService.showSuccess("Success", "User account deleted");
		}
		catch (e) {
			OverlayService.hide();
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, defaultErrorMessage));
		}
	}

	private async deleteUser() {
		const defaultErrorMessage = "Unable to delete user account";
		OverlayService.show(`Deleting user${Utils.ellipsis}`);
		try {
			const success = await this.accountsService.deleteUser(this._masqueradeUser.id);
			if (!success) {
				throw new Error(defaultErrorMessage);
			}

			this.accountsService.removeUserFromCache(this._masqueradeUser.id);

			await this.reloadUser();
			OverlayService.hide();
			OverlayService.showSuccess("Success", "User account deleted");
		}
		catch (e) {
			OverlayService.hide();
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, defaultErrorMessage));
		}
	}
}
