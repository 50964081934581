import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { OverlayService } from '@services/overlay.service';
import { PrivateComponent } from "@classes/private.component";
import { UserType } from "@classes/user";
import { MenuBuilder } from "@services/navmenu.service";
import { FileNoteStatus } from "@classes/filenotes";
import moment from 'moment';

interface TaskModel {
	status: FileNoteStatus;
	overdue: boolean;
	reminder: {
		type: string,
		from: Date,
		to: Date
	}
}

export interface SelectedUser {
	"id": string;
	"name": string;
}

@Component({
	"styleUrls": ["./tasks.component.scss"],
	"templateUrl": "./tasks.component.html"
})
export class TasksComponent extends PrivateComponent implements OnInit {

	@ViewChild('taskSummaryTemplate') private taskSummaryTemplateRef: TemplateRef<any>;
	@ViewChild('filterTemplate') private filterTemplateRef: TemplateRef<any>;
	@ViewChild('reminderTemplate') private reminderTemplateRef: TemplateRef<any>;

	private _selectedUser: SelectedUser = undefined;
	private _reminderType: string = "all";
	protected _reminderFrom: string = "";
	protected _reminderTo: string = "";

	private _model: TaskModel = {
		status: undefined,
		overdue: false,
		reminder: {
			type: "all",
			from: undefined,
			to: undefined
		}
	};

	public get model(): TaskModel {
		return this._model;
	}

	public readonly statuses: FileNoteStatus[] = [
		// FileNoteStatus.not_started, 
		FileNoteStatus.open
	];
	public readonly filenoteStatus: any = {
		// "not_started": FileNoteStatus.not_started,
		"open": FileNoteStatus.open
	};

	constructor() {
		super();
		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Admin Dashboard', 'All Alerts');
	}

	get selectedUser(): any {
		return this._selectedUser;
	}

	public get reminderType(): string {
		return this._reminderType;
	}

	public get reminderFrom(): string {
		return this._model.reminder.from ? moment(this._model.reminder.from).format('DDMMYYYY') : '';
	}

	public get reminderTo(): string {
		return this._model.reminder.to ? moment(this._model.reminder.to).format('DDMMYYYY') : '';
	}

	public set reminderType(value: string){
		this._reminderType = value;
	}

	public set reminderFrom(value: string){
		this._model.reminder.from = moment(value, 'DDMMYYYY').toDate();
		console.log(value);
		console.log(this._model.reminder.from);
	}

	public set reminderTo(value: string){
		this._model.reminder.to = moment(value, 'DDMMYYYY').toDate();
		console.log(value);
		console.log(this._model.reminder.to);
	}

	public userSelected(value: SelectedUser) {
		this._selectedUser = value;
	}

	private buildMenu(): void {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.addHandler("user", "User Filter", () => {
			this.showUserDialog();
		});
		// menuBuilder.addHandler("filter", "Status Filter", () => {
		// 	this.showFilterDialog();
		// });
		menuBuilder.addHandler("bell", "Reminder Filter", () => {
			this.showReminderDialog();
		});
		menuBuilder.done();
	}

	private showUserDialog(): void {
		OverlayService.showTemplate(this.taskSummaryTemplateRef, {});
	}

	private showFilterDialog(): void {
		OverlayService.showTemplate(this.filterTemplateRef, {});
	}

	private showReminderDialog(): void {
		OverlayService.showTemplate(this.reminderTemplateRef, {});
	}

	private parseDateStr(value: string): Date {
		try {
			const m = moment(value, 'DDMMYYYY', true);
			return m.isValid() ? m.startOf('day').toDate() : undefined;
		}
		catch (e) {
			return undefined;
		}
	}

	// check if date is empty or valid
	protected dateCheck(value) {
		return !!this.parseDateStr(value) || value=='';
	}

	// compare if date from is before date to
	protected dateCompare(fromDate, toDate) {
		let from = this.parseDateStr(fromDate);
		let to = this.parseDateStr(toDate);
		return from <= to || (!fromDate || !toDate);
	}

	ngOnInit() {
		super.ngOnInit();
		this.buildMenu();
	}

	public getStatusDescription(value: FileNoteStatus): string {
		return FileNoteStatus.toString(value);
	}

	public closeDialog(): void {
		OverlayService.hide();
	}

	public closeReminderDialog(): void {
		if(this.reminderType == 'none') {
			this.reminderFrom = '';
			this.reminderTo = '';
		}
		this.reminderFrom = !!this.parseDateStr(this._reminderFrom) ? this._reminderFrom : '';
		this.reminderTo = !!this.parseDateStr(this._reminderTo) ? this._reminderTo : '';
		this._model.reminder.type = this.reminderType;
		this._model.reminder.from = this.parseDateStr(this._reminderFrom);
		this._model.reminder.to = this.parseDateStr(this._reminderTo);
		OverlayService.hide();
	}
}
