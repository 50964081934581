import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@services/user.service';
import { User, UserType } from '@classes/user';
import { Settings } from '@classes/settings';
import { Permissions } from "@classes/permissions";
import { config } from "../../../config";
import { MRUService } from "@services/mru.service";
import { Subscription } from "rxjs";
import { Entity } from "@classes/entity";

@Component({
	"selector": "app-header",
	"templateUrl": "./header.component.html",
	"styleUrls": ["./header.component.scss"]
})
export class HeaderComponent implements OnInit {

	private _subs: Subscription;
	private _mru: Entity[] = [];

	constructor(public userService: UserService, private router: Router, private mruService: MRUService) {
	}

	private setMRU(value: Entity[]) {
		this._mru = value;
	}

	ngOnInit() {
		this._subs = this.mruService.subscribe( this.setMRU.bind(this) );
		// this._subs = this.mruService.mru.subscribe( this.setMRU.bind(this) );
	}

	readonly admin: UserType = UserType.Admin;
	readonly supportCoordinator: UserType = UserType.SupportCoordinator;
	readonly participant: UserType = UserType.Participant;

	private user: User;

	title = 'National Disability Support Partners';

	public hasPermission(groupName: string, permissionName: string): boolean {
		try {
			const permissionId = Permissions.getPermissionId(groupName, permissionName);
			return this.user.hasPermission(permissionId);
		}
		catch (e) {
			return false;
		}
	}

	get stageName(): string {
		return config.stage;
	}

	get prodEnv(): boolean {
		return ['prod', 'nappa2'].includes(config.stage);
	}

	get firstName(): string {
		this.user = this.userService.getUser()
		return this.user && this.user.firstName || ""
	}

	get lastName(): string {
		this.user = this.userService.getUser()
		return this.user && this.user.lastName || ""
	}

	get ndisNumber(): string {
		this.user = this.userService.getUser();
		return this.user && this.userIs(UserType.Participant) ? this.user.ndisNumber : '';
	}

	userIs(type: UserType, ...otherTypes: UserType[]): boolean {
		this.user = this.userService.getUser();
		if (!this.user) {
			return false;
		}

		const allTypes = otherTypes.concat(type);
		return allTypes.includes(this.user.accountType);
	}

	get prodaEnabled(): boolean {
		const settings = Settings.instance;
		if (settings.has('proda')) {
			const value = settings.get('proda') || {};
			return !!value.enabled;
		}

		return false;
	}

	get xeroEnabled(): boolean {
		const settings = Settings.instance;
		if (settings.has('proda')) {
			const value = settings.get('proda') || {};
			return !!value.xeroEnabled;
		}

		return false;
	}

	get recentClients(): Entity[] {
		return this._mru;
	}

	public clearMRU() {
		this.mruService.clear();
	}

}
