export enum BudgetType {
	plan, category, supportItem, serviceAgreementCategory, serviceAgreementItem
}

export interface PlanBudget {
	total: number;                     // Total amount in the plan / category / support item
	quarantined?: number;              // Total amount reserved for nominated providers. Must be <= total
	paid: number;                      // Amount paid
	pending: number;                   // Amount awaiting PRODA submission or response
	draft: number;                     // Amount on draft invoices.
	unknown: number;                   // Usually old invoices from Enrite fall into this bucket. Assumed to be paid.
	remaining: number;                 // Remaining funds displayed to admins
	clientRemaining: number;           // Remaining funds displayed to client (excludes pending amounts)
	quarantineSpend?: number;
	exQuarantineSpend?: number;
	allocated?: number;
	variance?: number;
	varianceLevel?: number;
	budgetType?: BudgetType;
}

export namespace PlanBudget {

	export function blank(type?: BudgetType): PlanBudget {
		return {
			"total": undefined,
			"quarantined": undefined,
			"paid": undefined,
			"pending": undefined,
			"draft": undefined,
			"unknown": undefined,
			"remaining": undefined,
			"clientRemaining": undefined,
			"budgetType": type
		};
	}

	/**
	* Attempts to parse a number. Returns a default value if the source does not represent a number.
	*/
	function n(value: any, defaultResult?: number): number {
		const result = Number(value);
		return Number.isNaN(result) ? defaultResult === undefined ? 0 : defaultResult : result;
	}

	export function parse(src: any, type?: BudgetType): PlanBudget {
		if (!src) {
			return PlanBudget.blank(type);
		}

		return {
			"total": n(src.total),
			"quarantined": n(src.quarantined),
			"paid": n(src.paid),
			"pending": n(src.pending),
			"draft": n(src.draft),
			"unknown": n(src.unknown),
			"remaining": n(src.total) - n(src.paid) - n(src.pending) - n(src.unknown) - n(src.quarantined) - n(src.draft),
			"clientRemaining": n(src.total) - n(src.paid) - n(src.unknown),
			"allocated": n(src.allocated),
			"budgetType": type
		};
	}

	export function toJSON(src: PlanBudget): any {
		if (!src) {
			return PlanBudget.blank();
		}

		return {
			"total": n(src.total, null),
			"quarantined": n(src.quarantined, null),
			"paid": n(src.paid, null),
			"pending": n(src.pending, null),
			"draft": n(src.draft, null),
			"unknown": n(src.unknown, null),
			"allocated": src.allocated,
			"variance": src.variance,
			"varianceLevel": src.varianceLevel
		};
	}

	/**
	* Forces all properties on a budget to be numbers.
	* Eliminates any undefined/NaN/null values and converts to zero.
	*/
	export function numberify(src: PlanBudget): PlanBudget {
		const result = PlanBudget.clone(src);

		result.total = n(result.total);
		result.quarantined = n(result.quarantined);
		result.paid = n(result.paid);
		result.pending = n(result.pending);
		result.draft = n(result.draft);
		result.unknown = n(result.unknown);
		result.remaining = n(result.remaining);
		result.clientRemaining = n(result.clientRemaining);
		result.allocated = n(result.allocated);
		result.budgetType = n(result.budgetType);

		return result;
	}

	export function clone(src: PlanBudget, type?: BudgetType): PlanBudget {
		if (!src) {
			return PlanBudget.blank(type);
		}

		return {
			"total": src.total,
			"quarantined": src.quarantined,
			"paid": src.paid,
			"pending": src.pending,
			"draft": src.draft,
			"unknown": src.unknown,
			"remaining": src.total - src.quarantined - src.draft - src.paid - src.pending - src.unknown,
			"clientRemaining": src.total - src.paid - src.unknown,
			"allocated": src.allocated,
			"budgetType": src.budgetType
		};
	}
}
