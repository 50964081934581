import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { User } from "@classes/user";
import { Provider } from '@services/provider.service';
import { FileNote, FileNoteStatus, FileNoteStatusUtils, FileNoteUtils, FileNoteCategory } from "@classes/filenotes";
import { FileNotesService } from "@services/filenotes.service";
import { OverlayService } from "@services/overlay.service";
import { UserAccount } from '@classes/user';
import { UserAccountService } from "@services/accounts.service";
import { FileManager } from "@classes/filemanager";
import { FreshdeskService } from "@services/freshdesk.service";
import moment from 'moment';

@Component({
	"selector": "file-note-dialog",
	"styleUrls": ["./notedialog.component.scss"],
	"templateUrl": "./notedialog.component.html"
})
export class NoteDialogComponent extends PrivateComponent implements OnInit {

	@ViewChild('title')
	private titleElement: ElementRef<HTMLInputElement>;

	@ViewChild('content')
	private contentElement: ElementRef<HTMLTextAreaElement>;

	private _target: User | Provider;
	private _model: FileNote = FileNoteUtils.newNote();
	private _categoryId: string = undefined;

	private isNew: boolean = true;
	private _filemanager: FileManager;
	protected _adminUsers: UserAccount[] = [];
	private _filenoteCategories: FileNoteCategory[] = [];
	protected communicationMethods: FileNoteCategory[] = [{"id": undefined, "name": "Not set", "grouping": 1, "sortOrder": -1}];
	protected contacts: FileNoteCategory[] = [{"id": undefined, "name": "Not set", "grouping": 2, "sortOrder": -1}];
	protected reasons: FileNoteCategory[] = [{"id": undefined, "name": "Not set", "grouping": 3, "sortOrder": -1}];

	// Little bit precarious, since we're hard-coding three array elements
	protected _category: string[] = [undefined, undefined, undefined];

	protected eventDateStr: string;
	protected reminderDateStr: string;

	protected allStatus: FileNoteStatus[] = FileNoteStatusUtils.allValues();
	protected statusDescriptions: string[] = this.allStatus.map(status => FileNoteStatusUtils.toString(status));

	get model(): FileNote {
		return this._model;
	}

	get ownersList(): UserAccount[] {
		return this._adminUsers.filter( owner => !!owner.id );
	}

	get referenceCode(): string {
		return this._model.referenceCode;
	}

	public get ticketUrl(): string {
		if(this._model.referenceCode.length > 7 && this._model.referenceCode.startsWith('0'))
			return ""; //'https://salesforceredirector.mpm.com.au/' + this._model.referenceCode;
		else
		return this.freshdeskService.getTicketUrl(this._model.referenceCode) || "";
	}
	public get isValidTicketNumber(): boolean {
		const SF = /^0\d{7}$/;
		const FD = /^\d{7}$/;
		return FD.test(this._model.referenceCode) || SF.test(this._model.referenceCode);
	}

	public get isOpen() {
		return this.statusDescriptions[this.model.status] == 'Open';
	}

	@Output()
	onSaveNote = new EventEmitter<FileNote>();

	@Input()
	set target(value: User|Provider) {
		this._target = value;
	}

	@Input()
	set note(value: FileNote) {
		if (!!value) {
			this._model = FileNoteUtils.clone(value);

			// Make sure we're including 3 items in the list of possible categories
			while (this._model.category.length < 3) {
				this._model.category.push(undefined);
			}

			this.reminderDateStr = this._model.reminder ? moment(this._model.reminder).format('DDMMYYYY') : undefined;
			this.eventDateStr = this._model.eventDate ? moment(this._model.eventDate).format('DDMMYYYY') : undefined;
			this.initNoteCategories();
		}
	}

	get fileManager(): FileManager {
		return this._filemanager;
	}

	// Start initialisation section ---------------------------------------------
	constructor(private accountsService: UserAccountService,
				private fileNotesService: FileNotesService,
				private freshdeskService: FreshdeskService) {
		super();
		this.model.owner.id = this.user.id;
		this.model.owner.name = `${this.user.firstName} ${this.user.lastName}`;
	}

	ngOnInit() {
		super.ngOnInit()
		this.eventDateStr = this._model.eventDate ? moment(this._model.eventDate).format('DDMMYYYY') : undefined;

		this.accountsService.getAdminAccounts(true, this._model.id).then( accounts => {
			this._adminUsers = accounts;
		});

		this.fileNotesService.fileNoteCategories().then( data => {
			this._filenoteCategories = data;
			this.initNoteCategories();
		});
	}

	private initNoteCategories() {
		if (this._model !== undefined && this._filenoteCategories.length > 0) {
			this._model.category.forEach( categoryId => {
				const category = this._filenoteCategories.find( item => item.id === categoryId );
				if (category !== undefined) {
					this._category[category.grouping - 1] = category.id;
				}
			});

			this._filenoteCategories.forEach(cat => {
				if (cat.grouping == 1)
					this.communicationMethods.push(cat);
				else if(cat.grouping == 2)
					this.contacts.push(cat);
				else if(cat.grouping == 3)
					this.reasons.push(cat);
			});
		}
	}

	ngAfterViewInit() {
		if (!this._model.parent) {
			this.focusControl(this.titleElement);
		}
		else {
			this.focusControl(this.contentElement);
		}
	}
	// End initialisation section -----------------------------------------------

	private isClient(target: User|Provider): target is User {
		if (!target) {
			return false;
		}

		return (target as User).accountType !== undefined;
	}

	public confirmCloseDialog() {
		if(confirm("Are you sure you want to close this note? Your changes will not be saved.")) {
			this.closeDialog();
		}
	}

	public closeDialog(emitEvent: boolean = false) {
		if (emitEvent) {

			if (this.isClient(this._target)) {
				this.model.clientId = this._model.clientId || this._target.id;
			}
			else {
				this.model.providerId = this._model.providerId || this._target.id;
			}

			// this._model.clientId = this._model.clientId || this._client.id;
			this._model.category = this._category;
			this.onSaveNote.emit(this.model);
		}
		OverlayService.hide();
	}

	public saveNote() {
		this.closeDialog(true);
	}

	get canSave(): boolean {
		if (!!this.model.parent) {
			const content = this.model.content ? this.model.content.trim() : "";
			return !!content;
		} else {
			if(this.isOpen && (!this.model.reminder || !this.model.assignedTo.id)) {
				return false;
			}
			const title = this.model.title ? this.model.title.trim() : "";
			return !!title && (this.model.status != undefined) && (this.isValidTicketNumber || !this._model.referenceCode);
		}
	}

	private parseDateStr(value: string): Date {
		try {
			const m = moment(value, 'DDMMYYYY', true);
			return m.isValid() ? m.startOf('day').toDate() : undefined;
		}
		catch (e) {
			return undefined;
		}
	}

	protected setReminderDate() {
		this._model.reminder = this.parseDateStr(this.reminderDateStr);
	}

	protected setEventDate() {
		this._model.eventDate = this.parseDateStr(this.eventDateStr);
	}

	protected assignedToChanged($event) {
		const admin = this._adminUsers.find( user => user.id === $event);
		if (admin) {
			this._model.assignedTo.name = admin.name;
		}
	}

	protected ownerChanged($event) {
		const admin = this._adminUsers.find( user => user.id === $event);
		if (admin) {
			this._model.owner.name = admin.name;
		}
	}

	/**
	* Sets the focus to the specified UI form field. Cancels event propagation from a keyboard event if supplied.
	* Initially coded to handle keyboard shortcuts to allow quick focus of controls.
	*
	* @param {ElementRef|any} control The UI control to focus
	* @param {KeyboardEvent} event Optional keyboard event that will have propagation terminated
	*/
	private focusControl(control: ElementRef|any, event?: KeyboardEvent) {
		if (event) {
			this.cancelEvent(event);
		}

		if (control.nativeElement) {
			setTimeout(() => { control.nativeElement.focus(); }, 0);
		}
		else {
			setTimeout(() => { control.focus(); }, 0);
		}
	}

	/**
	* Stops propagation and default behaviour of a keyboard event
	* Used to intercept keyDown events on the host, and prevent the browser from applying default behaviour
	* (eg Ctrl+R should not reload the page)
	*
	* @param {KeyboardEvent} event
	*/
	private cancelEvent(event: KeyboardEvent): void {
		event.stopImmediatePropagation();
		event.stopPropagation();
		event.preventDefault();
	}

	
}
