import { Entity } from "@classes/entity";

export interface Provider extends Entity {
	abn?: string;
	deleted: boolean;
}

export namespace Provider {
	export function parse(src: any) {
		const a = Entity.parse(src);
		const b = {
			"abn": src.abn,
			"deleted": src.deleted
		};

		return {...a, ...b};
	}

	export function toJSON(src: Provider): any {
		const a = Entity.toJSON(src);
		const b = {
			"abn": src.abn,
			"deleted": src.deleted
		};

		return {...a, ...b};
	}

	export function blank(): Provider {

		const a = Entity.blank();
		const b = {
			"abn": undefined,
			"deleted": undefined
		};

		return {...a, ...b};
	}

	export function clone(src: Provider): Provider {
		const a = Entity.clone(src);
		const b = {
			"abn": src.abn,
			"deleted": src.deleted
		};

		return {...a, ...b};
	}
}
