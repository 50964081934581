import { Injectable } from '@angular/core';

@Injectable({ "providedIn": "root" })
export class FreshdeskService {
	private static ticketUrlTemplate = `https://ndsp-planmanagers.freshdesk.com/a/tickets/`;

	public getTicketUrl(ticketNumber: string): string {
		return `${FreshdeskService.ticketUrlTemplate}${ticketNumber}`;
	}
}
