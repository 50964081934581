import { Component, OnInit } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";

@Component({
	"templateUrl": "./accessdenied.component.html"
})
export class AccessDeniedComponent extends PrivateComponent implements OnInit {

	constructor() {
		super()
	}

	ngOnInit() {
		super.ngOnInit()
	}

}
