import { Component, OnInit } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { UserType } from '@classes/user';
import { Address, AddressClass } from "@classes/address";
import { AccountStatus, ExitReason } from "@classes/clientStatus";
import { MenuBuilder } from "@services/navmenu.service";
import { ClientReportService, ClientReportData, ClientReportModel } from "@services/reports/clientReport.service";
import { OverlayService } from "@services/overlay.service";
import { Table, TableColumnHeading, SortType, StaticDataSource } from "@classes/tables";
import { saveAs } from 'file-saver';

@Component({
	"templateUrl": "./clientreport.component.html",
	"styleUrls": ["./clientreport.component.scss"]
})
export class ClientReportComponent extends PrivateComponent implements OnInit {

	private readonly _tableHeadings: TableColumnHeading[] = [
		{"propName": "clientName", "displayName": "Name", "sortType": SortType.none },
		{"propName": "ndisNumber", "displayName": "NDIS #", "sortType": SortType.none },
		{"propName": "email", "displayName": "Email", "sortType": SortType.none },
		{"propName": "phone", "displayName": "Phone", "sortType": SortType.none },
		{"propName": "address", "displayName": "Address", "sortType": SortType.none },
		{"propName": "state", "displayName": "State", "sortType": SortType.none },
		{"propName": "status", "displayName": "Status", "sortType": SortType.none },
		{"propName": "statusDate", "displayName": "Status Date", "sortType": SortType.none },
		{"propName": "exitReason", "displayName": "Exit Reason", "sortType": SortType.none }
	];

	private _dataLoaded: boolean = true;
	private _sourceData: ClientReportData[] = [];
	protected table: Table<ClientReportData> = new Table('clientReport', this._tableHeadings);

	readonly states: string[] = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];
	readonly statuses: AccountStatus[] = AccountStatus.allValues;

	public statusString: (AccountStatus) => string = AccountStatus.toString;
	public formatAddress: (Address, string?) => string = AddressClass.toString;

	public model: ClientReportModel = ClientReportModel.blank();

	constructor(private reportsService: ClientReportService) {
		super();

		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Reports', 'Client Report');
	}

	ngOnInit() {
		super.ngOnInit();

		if (this.user) {
			this.buildMenu();
		}
	}

	public async loadData() {
		this._dataLoaded = false;
		try {
			OverlayService.show();
			this._sourceData = await this.reportsService.clientReport(this.model);
			this.table.sourceData = StaticDataSource.from(this._sourceData);
			OverlayService.hide();
		}
		catch (e) {
			OverlayService.showError("Error", "Unable to display report");
			console.log(e);
		}
		finally {
			this._dataLoaded = true;
		}
	}


	get dataLoaded(): boolean {
		return this._dataLoaded;
	}

	private buildMenu(): void {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.addHandler(
			'file-excel',
			'Download',
			() => { this.downloadReport(); },
			() => { return this.downloadDisabled(); }
		);
		menuBuilder.done();
	}

	private downloadDisabled(): boolean {
		return !this._dataLoaded || !this.table.hasData;
	}

	private downloadReport(): void {
		const csvReport = new ReportDownloader(this._sourceData);
		csvReport.downloadReport();
	}

	public status(status: AccountStatus): string {
		return AccountStatus.toString(status);
	}

	// public region(region: Region): string {
	// 	return Region.toString(region);
	// }

	public exitReason(reason: ExitReason): string {
		return ExitReason.toString(reason);
	}
}


class ReportDownloader {
	public constructor(private data: ClientReportData[]) {}

	private clientReportDataToString(item: ClientReportData): string {
		const fields = [
			"clientName",
			"ndisNumber",
			"email",
			"phone",
			"address",
			// "region",
			"state",
			"status",
			"statusDate",
			"exitReason"
		];

		return fields.map( fieldName => {
			switch (fieldName) {
				case "address": return AddressClass.toString(item[fieldName], ",\n");
				// case "region": return Region.toString(item[fieldName]);
				case "status": return AccountStatus.toString(item[fieldName]);
				default: return item[fieldName];
			}
		} ).map( item => [null, undefined].includes(item) ? "" : `"${item}"` ).join(",");
	}

	private constructCSV(): string {
		let result = "Name,NDIS #,Email,Phone,Address,State,Status,Status Date,Exit Reason\n";
		result += this.data.map( data => { return this.clientReportDataToString(data); }).join("\n");
		return result;
	}


	public downloadReport(): void {
		const csv = this.constructCSV();
		const file = new Blob([csv], { "type": "text/csv;charset=utf-8" });
		saveAs(file, "client-report.csv");
	}
}
