import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, TemplateRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Provider, ProviderService } from "@services/provider.service";
import { Utils } from "@classes/utils";
import { Key } from "@classes/keys";
import { Subject } from 'rxjs';
import { concatWith, distinctUntilChanged, filter, map } from 'rxjs/operators';

@Component({
	"selector": "provider-search",
	"templateUrl": "./providerSearch.component.html",
	"styleUrls": ["./providerSearch.component.scss"]
})
export class InvoiceProviderSearchComponent {

	private static instanceCount: number = 0;

	private readonly NONE_SELECTED = -1;
	private _provider: Provider;

	@ViewChild('searchResultsDialog')
	private searchResultsDialog: TemplateRef<any>;

	@ViewChild('providerField')
	private providerInput: ElementRef<HTMLInputElement>;

	public readonly uniqueId: number = ++InvoiceProviderSearchComponent.instanceCount;

	@HostListener('keyup', ['$event'])
	onkeyup(event: KeyboardEvent) {

		switch (event.keyCode) {
			case Key.Escape:
				this.handleEscapeKey();
				event.preventDefault();
				break;

			case Key.Enter:
				event.preventDefault();
				event.stopPropagation();
				event.stopImmediatePropagation();
				this.handleEnterKey(event);
				break;

			default:
				this.keyup$.next(event);
		}
	}

	constructor(private providerService: ProviderService, private router: Router) {}

	ngOnInit() {
		this.keyup$.pipe(

			filter( e => this.filterArrowKeys(e) ),
			map( (e: KeyboardEvent) => e.keyCode)

		).subscribe( (keyCode: number) => {

			this.updateSelectedIndex(keyCode);

		});

		this.keyup$.pipe(

			filter( e => !this.filterArrowKeys(e) ),
			concatWith(),
			distinctUntilChanged()

		).subscribe( e => {

			this.showResults = false;

		});
	}

	ngOnDestroy() {
		this.keyup$.complete();
	}

	public whatTheUserTyped: string;
	public showResults: boolean = false;
	public searchActive: boolean = false;
	private keyup$ = new Subject<KeyboardEvent>();
	protected currentActiveItem: number = this.NONE_SELECTED;
	public searchResults: Provider[] = [];

	@Input()
	public set provider(value: Provider) {
		if (value !== this._provider) {
			if (value) {
				this.whatTheUserTyped = value.name;
			}
			this._provider = value;
		}
	}

	@Input()
	public disabled: boolean = false;

	@Input()
	public unrestricted: boolean = false;

	@Input()
	public nolabel: boolean = false;

	@Input()
	public overlay: boolean = false;

	@Output()
	public providerChanged: EventEmitter<Provider> = new EventEmitter<Provider>();

	private currentSearch = {
		"timeoutHandle": undefined,
		"text": undefined
	};

	private textChanged(value: string) {
		this.whatTheUserTyped = value;
	}

	private handleEscapeKey() {
		if (this.showResults && this.searchResults.length > 0) {
			this.showResults = false;
		}
	}

	private filterArrowKeys(e: KeyboardEvent) {
		return [Key.ArrowDown, Key.ArrowUp].includes(e.keyCode);
	}

	private updateSelectedIndex(keyCode: number) {
		if (this.searchResults.length === 0) {
			this.currentActiveItem = this.NONE_SELECTED;
			return;
		}

		const increment = keyCode === Key.ArrowUp ? -1 : 1;
		let idx = this.currentActiveItem += increment;
		if (idx >= this.searchResults.length) {
			idx = 0;
		}
		else if (idx < 0) {
			idx = this.searchResults.length - 1;
		}

		this.currentActiveItem = idx;
		this.scrollSelectedItemIntoView();
	}

	private scrollSelectedItemIntoView() {
		window.setTimeout(() => {
			const activeButton = document.querySelector("button.active");
			if (activeButton) {
				activeButton.scrollIntoView({"behavior": "auto", "block": "nearest"});
			}
		}, 0);
	}

	private handleEnterKey($event: KeyboardEvent) {
		if (this.currentActiveItem > this.NONE_SELECTED && this.showResults) {
			this.selectResult( $event, this.searchResults[this.currentActiveItem], this.currentActiveItem );
		}
		else {
			this.doSearch();
		}
	}

	protected selectResult($event: KeyboardEvent|MouseEvent, item: Provider, idx: number) {

		this.currentActiveItem = idx;
		this.whatTheUserTyped = '';
		this.currentSearch.text = '';

		this._provider = item;
		this.providerChanged.next(item);
		this.whatTheUserTyped = item.name;

		this.showResults = false;
	}

	public doSearch() {
		const searchTerm = Utils.trimString(this.whatTheUserTyped);

		if (!!searchTerm) {

			if (searchTerm !== this.currentSearch.text) {

				this.currentSearch.text = searchTerm;
				this.searchActive = true;

				this.providerService.invoiceProviderSearch(this.currentSearch.text, this.unrestricted).then( results => {
					this.currentActiveItem = this.NONE_SELECTED;
					this.searchResults = results;
					this.showResults = true;
					this.searchActive = false;
				});
			}
			else if (this.searchResults.length) {
				this.showResults = true;
			}
		}
	}

	private get isABNSearch(): boolean {
		return /^\d{11}$/.test(this.whatTheUserTyped)
	}

	private get isNameSearch(): boolean {
		const searchString = this.whatTheUserTyped || '';
		return searchString.length > 2 && !/\d/.test(searchString);
	}

	public get canSearch(): boolean {
		return this.isABNSearch || this.isNameSearch;
	}

	protected get multipleAbnMessage(): string {
		let message = "";
		if (this._provider && this._provider.abn && this.searchResults.length) {
			let abnCount = this.searchResults.filter(p => p.abn === this._provider.abn).length;
			if (abnCount > 1) {
				message += `(${abnCount} providers using this ABN)`;
			}
		}
		else if (this._provider && this._provider.abnList) {
			let abnCount = this._provider.abnList.length;
			if (abnCount > 1) {
				message += `(${abnCount} providers using this ABN)`;
			}
		}
		return message;
	}

	public modelChanged() {
		if (this._provider && this.whatTheUserTyped !== this._provider.name) {
			this._provider = undefined;
			this.providerChanged.next(undefined);
		}
	}

	public get abnText() {
		if (!this._provider) {
			return '';
		}

		return this._provider.abn ? `ABN: ${this._provider.abn}` : 'No ABN';
	}

	public get bankAccountBSB() {
		if (!this._provider) {
			return '';
		}
		return this._provider.bankAccountBSB ? `${this._provider.bankAccountBSB}` : 'No BSB';
	}

	public get bankAccountNumber(){
		if (!this._provider) {
			return '';
		}
		return this._provider.bankAccountNumber ? `${this._provider.bankAccountNumber}` : 'No Account';
	}

	public get provider(): Provider {
		return this._provider;
	}

	/**
	* Checks to see if what's been pasted into the provider's box looks like an ABN. Removes spaces
	* and tests for 11 digits.
	*/
	public abnPaste(event: ClipboardEvent) {
		const text = event.clipboardData.getData('text');
		const tidied = text.replace(/\s/g, '');
		if (/^\d{11}$/.test(tidied)) {
			event.preventDefault();
			this.whatTheUserTyped = tidied;
			this.modelChanged();
		}
	}

	public get inputElement(): ElementRef {
		return this.providerInput;
	}
}
