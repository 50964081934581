import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, TemplateRef, HostListener } from '@angular/core';
import { User } from "@classes/user";
import { SearchService } from "@services/search.service";
import { Utils } from "@classes/utils";
import { Key } from "@classes/keys";
import { Subject } from 'rxjs';
import { concatWith, distinctUntilChanged, filter, map } from 'rxjs/operators';

@Component({
	"selector": "invoice-client-search",
	"templateUrl": "./clientSearch.component.html",
	"styleUrls": ["./clientSearch.component.scss"]
})
export class InvoiceClientSearchComponent {

	private readonly NONE_SELECTED = -1;
	private _client: User;

	@ViewChild('searchResultsDialog')
	private searchResultsDialog: TemplateRef<any>;

	@ViewChild('clientField')
	private clientInput: ElementRef<HTMLInputElement>;

	@HostListener('keyup', ['$event'])
	onkeyup(event: KeyboardEvent) {

		switch (event.keyCode) {
			case Key.Escape:
				this.handleEscapeKey();
				event.preventDefault();
				break;

			case Key.Enter:
				this.handleEnterKey(event);
				event.preventDefault();
				break;

			default:
				this.keyup$.next(event);
		}
	}

	constructor(private searchService: SearchService) {}

	ngOnInit() {
		this.keyup$.pipe(

			filter( e => this.filterArrowKeys(e) ),
			map( (e: KeyboardEvent) => e.keyCode)

		).subscribe( (keyCode: number) => {

			this.updateSelectedIndex(keyCode);

		});

		this.keyup$.pipe(

			filter( e => !this.filterArrowKeys(e) ),
			concatWith(),
			distinctUntilChanged()

		).subscribe( e => {

			this.showResults = false;

		});
	}

	ngOnDestroy() {
		this.keyup$.complete();
	}

	public whatTheUserTyped: string;
	public showResults: boolean = false;
	public searchActive: boolean = false;
	private keyup$ = new Subject<KeyboardEvent>();
	protected currentActiveItem: number = this.NONE_SELECTED;
	public searchResults: User[] = [];

	@Input()
	public set client(value: User) {
		if (value !== this._client) {
			if (value) {
				this.whatTheUserTyped = value.name;
			}
			this._client = value;
		}
	}

	@Input()
	public disabled: boolean = false;

	@Output()
	public clientChanged: EventEmitter<User> = new EventEmitter<User>();

	private currentSearch = {
		"timeoutHandle": undefined,
		"text": undefined
	};

	private textChanged(value: string) {
		this.whatTheUserTyped = value;
	}

	private handleEscapeKey() {
		if (this.showResults && this.searchResults.length > 0) {
			this.showResults = false;
		}
	}

	private filterArrowKeys(e: KeyboardEvent) {
		return [Key.ArrowDown, Key.ArrowUp].includes(e.keyCode);
	}

	private updateSelectedIndex(keyCode: number) {
		if (this.searchResults.length === 0) {
			this.currentActiveItem = this.NONE_SELECTED;
			return;
		}

		const increment = keyCode === Key.ArrowUp ? -1 : 1;
		let idx = this.currentActiveItem += increment;
		if (idx >= this.searchResults.length) {
			idx = 0;
		}
		else if (idx < 0) {
			idx = this.searchResults.length - 1;
		}

		this.currentActiveItem = idx;
		this.scrollSelectedItemIntoView();
	}

	private scrollSelectedItemIntoView() {
		window.setTimeout(() => {
			const activeButton = document.querySelector("button.active");
			if (activeButton) {
				activeButton.scrollIntoView({"behavior": "auto", "block": "nearest"});
			}
		}, 0);
	}

	private handleEnterKey($event: KeyboardEvent) {
		if (this.currentActiveItem > this.NONE_SELECTED && this.showResults) {
			this.selectResult( $event, this.searchResults[this.currentActiveItem], this.currentActiveItem );
		}
		else {
			this.doSearch();
		}
	}

	protected selectResult($event: KeyboardEvent|MouseEvent, item: User, idx: number) {

		this.currentActiveItem = idx;
		this.whatTheUserTyped = '';
		this.currentSearch.text = '';

		this._client = item;
		this.clientChanged.next(item);
		this.whatTheUserTyped = item.name;

		this.showResults = false;
	}

	public async doSearch() {
		const searchTerm = Utils.trimString(this.whatTheUserTyped);

		if (!!searchTerm) {

			if (searchTerm !== this.currentSearch.text) {

				this.currentSearch.text = searchTerm;
				this.searchActive = true;

				try {
					const results = await this.searchService.clientSearch(this.currentSearch.text);
					this.currentActiveItem = this.NONE_SELECTED;
					this.searchResults = results;
					this.showResults = true;
				}
				catch (e) {
					this.searchResults = [];
					this.showResults = false;
				}
				finally {
					this.searchActive = false;
				}

			}
			else if (this.searchResults.length) {
				this.showResults = true;
			}
		}
	}

	public get canSearch(): boolean {
		const searchString = this.whatTheUserTyped || '';
		return searchString.length > 2;
	}

	public modelChanged() {
		if (this._client && this.whatTheUserTyped !== this._client.name) {
			this._client = undefined;
			this.clientChanged.next(undefined);
		}
	}

	public get ndisNumber() {
		if (!this._client) {
			return '';
		}

		return this.client.ndisNumber ? `NDIS #: ${this._client.ndisNumber}` : '';
	}

	public get client(): User {
		return this._client;
	}

	public get inputElement(): ElementRef {
		return this.clientInput;
	}
}
