import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit, ViewChild, ViewChildren, HostListener, ElementRef, QueryList } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { InvoiceLineItem, LineItemStatus, LineItemStatusUtils, InvoiceUtils, GSTCode, GSTCodeUtils } from "@classes/invoices";
import { Plan, PlanBudget, BudgetType, PlanStatus, PlanSupportCategory, PlanSupportItem } from "@classes/plans";
import { PlanService } from "@services/plan.service";
import { SupportItem, UnitOfMeasure } from "@classes/supports";
import { OverlayService } from "@services/overlay.service";
import { SupportsService } from "@services/supports.service";
import { LineItemValidator, BusinessRule } from "./invoicelineitem.businessrules";
import { Settings } from "@classes/settings";
import { UserType } from "@classes/user";
import { DateUtils } from "@classes/utils";
import { RegionUtils } from "@classes/regions";
import moment from 'moment';
import { config } from "../../../../../../config";
import { assert, EAssertionFailed } from "@classes/errors";
import { Observable, of, Subscriber, mergeMap } from 'rxjs';

type DateIncrement = -1 | 1;
type DateIncrementUnit = 'days' | 'months';

enum QuantityType {
	decimal, hoursAndMins
}

@Component({
	"selector": "line-item-dialog",
	"styleUrls": ["invoicelineitem.component.scss"],
	"templateUrl": "./invoicelineitem.component.html"
})
export class InvoiceLineItemComponent extends PrivateComponent implements OnInit, AfterViewInit {

	supportItemSource: Observable<SupportItem[]>

	constructor(private planService: PlanService, private supportsService: SupportsService) {
		super();
		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Billing', 'Access billing');
		this.supportItemSource = new Observable((observer: Subscriber<string>) => {
			// Runs on every search
			observer.next(this.model.serviceName);
		  })
			.pipe(
			  mergeMap((token: string) => this.getSupportItemsAsObservable(token))
			);
	}

	public readonly budgetType = {
		"plan": BudgetType.plan,
		"category": BudgetType.category,
		"item": BudgetType.supportItem,
		"serviceAgreement": BudgetType.serviceAgreementCategory,
		"serviceAgreementItem": BudgetType.serviceAgreementItem
	};

	@ViewChild('form')
	private form: ElementRef<any>;

	@ViewChild('lineItemDateControl')
	private lineItemDateControl: ElementRef<any>;

	@ViewChild('quantity')
	private quantityControl: ElementRef<any>;

	@ViewChildren('suggestion')
	private suggestions: QueryList<any>;

	private _providerId: string = undefined;                   	// The provider id for this invoice
	private _dob: string = undefined;                  		   	// Client DOB string DD/MM/YYYY (NN Years old)
	private _dateFocused: boolean = false;                     	// Flag to indicate if keyboard focus is in the date control
	private _srcLineItem: InvoiceLineItem = undefined;         	// A copy of the original line item passed in from the parent
	private _clientPlans: Plan[] = [];                         	// List of all plans for this client
	private _supportItems: SupportItem[] = [];          		// Support items for the plan region and service delivered date
	private _supportItemSuggestionElements: ElementRef[] = []; 	// List of support item suggestions in the ngx-typeahead component
	private _support: SupportItem = undefined;                 	// The support item selected from the ngxTypeahead component
	private _invoiceTotal: number = 0;
	private _pace: boolean = false;

	private _budget: PlanBudget = undefined;

	private _lineItems: InvoiceLineItem[] = [];                	// All of the line items for this invoice, excluding the one we're editing

	private _timeEntry: boolean = false;                       	// Default to numeric quantity, rather than hours/mins for hourly rate services
	private _initialPlan: Plan;
	private _initialBudget: PlanBudget;       
	private _currentSpend: number;                         	// Plan that the line item is initially assigned to. Helps with budget calculations when line item date is changed

	private _initialised: boolean = false;

	private planMap: Map<InvoiceLineItem, Plan> = new Map<InvoiceLineItem, Plan>();
	private supportMap: Map<InvoiceLineItem, SupportItem> = new Map<InvoiceLineItem, SupportItem>();
	private budgetMap: Map<InvoiceLineItem, PlanBudget> = new Map<InvoiceLineItem, PlanBudget>();

	private validator: LineItemValidator = new LineItemValidator(this._lineItems, this.supportMap, this.planMap, false);

	private _providerBudget = {
		"category": undefined,
		"item": undefined
	};

	private _masterMode: boolean;

	public reconciledStatus: LineItemStatus = LineItemStatus.reconciled;
	public allLineItemStatuses: LineItemStatus[] = LineItemStatusUtils.allValues();
	public allLineItemStatusDescriptions: string[] = this.allLineItemStatuses.map(status => LineItemStatusUtils.toString(status));

	public unitPrice: Number;
	public errors: BusinessRule[] = [];
	private validationPassed: boolean = true;

	public model: any = {
		"id": undefined,
		"date": "",
		"dateDay": "",
		"serviceName": undefined,
		"lineItem": undefined,
		"quantity": {
			"hours": undefined,
			"mins": undefined,
			"type": QuantityType.decimal
		}
	}

	public getSupportItemsAsObservable(token:string): Observable<SupportItem[]> {
		return of(
		  this.supportItems.filter((support:SupportItem) => {
			let name = support.name, supportNumber = support.supportItemNumber;
			if(name === null)
				name = "";
			if(supportNumber === null)
				supportNumber = "";
			return name.toLowerCase().includes(token.toLowerCase()) || supportNumber.toLowerCase().includes(token.toLowerCase());
		  })
		);
	  }

	public allGSTCodes: GSTCode[] = GSTCodeUtils.allValues();
	public quantityTypes: any = {
		"decimal": QuantityType.decimal,
		"hoursAndMins": QuantityType.hoursAndMins
	};

	@Output('saveLineItem')
	lineItemOutput = new EventEmitter<InvoiceLineItem>();

	@Output()
	dialogClosed = new EventEmitter<boolean>();

	// @Input()
	// set invoiceTotal(value: number) {
	// 	this._invoiceTotal = value;
	// }

	@Input()
	set lineItems(value: InvoiceLineItem[]) {
		if (this._lineItems !== value) {
			this._lineItems = Array.from(value);
		}
	}

	@Input()
	set src(value: InvoiceLineItem) {

		if (value !== this.model.lineItem) {

			this._srcLineItem = InvoiceUtils.cloneLineItem(value);

			this.model.lineItem = value;
			this.model.date = this.model.lineItem.date !== undefined ? moment(this.model.lineItem.date).format("DDMMYYYY") : "";
			this.model.dateDay = this.model.lineItem.date !== undefined ? moment(this.model.lineItem.date).format('dddd') : "Day";
			this.model.serviceName = this.model.lineItem.serviceName;
		}
	}

	@Input()
	set masterMode(value: boolean) {
		this._masterMode = value;
	}

	@Input()
	set clientDOB(value: string) {
		this._dob = value;
	}

	@Input()
	set providerId(value: string) {
		this._providerId = value;
	}

	@Input()
	set plans(value: Plan[]) {
		if (value.length > 0) {
			this._clientPlans = value;
		}
	}

	get masterMode(): boolean {
		return this._masterMode;
	}

	public get dob():string {
		return this._dob;
	}

	private async init() {
		if (this.model.lineItem) {

			this._initialPlan = this.findPlanForLineItem(this.model.lineItem);

			this._lineItems.push(this._srcLineItem);

			this.createPlanMap();
			await this.createSupportMap();
			this.createBudgetMap();

			await this.loadSupportItems();

			if (this.model.lineItem.id) {
				// this._support = await this.supportsService.getSupport(this.model.lineItem.supportId);
				//this._support = await this.supportsService.getSupport(this.model.lineItem.supportItem);
				await this.loadSelectedSupportItem();
				this.findBudget();

				if (!!this._initialPlan && !!this._support && this.model.lineItem && this.model.lineItem.date && this._providerId) {
					this._initialBudget = this._initialPlan.findBudget(this._support, this.model.lineItem.date, this._providerId, true);
				}
				else {
					this._initialBudget = undefined;
				}
			}

			this.validator = new LineItemValidator(this._lineItems, this.supportMap, this.planMap, this.stillOnSamePlanBudget);
			this.calcUnitPrice();
			this._initialised = true;
		}
	}

	/**
	* Builds a map to connect each line item to a plan
	*/
	private createPlanMap() {
		this.planMap.clear();

		const allLineItems = Array.from(this._lineItems).concat(this.model.lineItem);

		allLineItems.forEach( lineItem => {
			const plan = this.findPlanForLineItem(lineItem);
			if (plan) {
				this.planMap.set(lineItem, plan);
			}
		});
	}

	private createBudgetMap() {
		this.budgetMap.clear();

		const allLineItems = Array.from(this._lineItems).concat(this.model.lineItem);
		allLineItems.forEach( lineItem => {
			const budget = this.findBudgetForLineItem(lineItem);
			if (budget) {
				this.budgetMap.set(lineItem, budget);
			}
		});
	}

	private findBudgetForLineItem(lineItem: InvoiceLineItem): PlanBudget {
		try {
			const plan = this.findPlanForLineItem(lineItem);
			assert(!!plan);

			const support = this.supportMap.get(lineItem);
			assert(!!support);

			return plan.findBudget(support, lineItem.date, this._providerId);
		}
		catch (e) {
			if (e instanceof EAssertionFailed) {
				return undefined;
			}

			throw e;
		}
	}

	private findBudget(): void {
		const plan = this.plan;

		// Determine the budget to use when checking available funds for the selected support.
		// Requires a plan, support item, provider and service date
		if (!!plan && !!this._support && this.model.lineItem && this.model.lineItem.date && this._providerId) {
			this._budget = plan.findBudget(this._support, this.model.lineItem.date, this._providerId, true);
		}
		else {
			this._budget = undefined;
		}
	}

	public get budget(): PlanBudget {
		return this._budget;
	}

	/**
	* Builds a map of support items, keyed by line item
	*/
	private async createSupportMap() {
		this.supportMap.clear();

		const allLineItems = Array.from(this._lineItems).concat(this.model.lineItem);
		const promises = allLineItems.map( async lineItem => {

			if (!this.plan) {
				return Promise.resolve(undefined);
			}

			const supports = await this.supportsService.getSupportsFor(this.plan.region, lineItem.date, lineItem.supportItemNumber, undefined, this.plan.pace);
			if (supports.length === 1) {
				this.supportMap.set(lineItem, supports[0]);
			}
		});

		await Promise.all(promises);
	}

	private findPlanForLineItem(lineItem: InvoiceLineItem): Plan {
		if (!lineItem || !lineItem.date) {
			return undefined;
		}

		const lineItemDate = moment(lineItem.date);

		const allowedPlanStatuses = this._masterMode
		                          ? [PlanStatus.current, PlanStatus.expired, PlanStatus.terminated, PlanStatus.deceased]
		                          : [PlanStatus.current, PlanStatus.expired, PlanStatus.deceased];

		const matchingPlans = this._clientPlans.filter(plan => {
			const startDate = moment(plan.startDate).startOf('day');
			const endDate = moment(plan.endDate).endOf('day');

			return lineItemDate.isBetween(startDate, endDate, null, '[]') && allowedPlanStatuses.includes(plan.status);
		});


		return matchingPlans.length === 1 ? matchingPlans.pop() : undefined;
	}


	/**
	* Stores the focus state of the "Invoice Date" UI form field. Used so that we can intercept
	* keyboard events when this field is focused, and change date based on up and down arrow keys.
	* Triggered by the "focus" and "blur" events on the control.
	*
	* @param {boolean} value The focus state for the control.
	*/
	public set dateFocused(value: boolean) {
		this._dateFocused = value;
	}

	/**
	* Handle up/down arrow keys to enable scrolling for suggestion items in ngx-typeahead component
	* @param {KeyboardEvent} event
	*/
	@HostListener('window:keydown', ['$event'])
	private interceptBrowserShortcuts(event: KeyboardEvent) {
		// Check for the down and up arrow keys. Will use these to try and determine the selected suggestion from the
		// ngx-typeahead component for support item suggestions, and scroll into view any that appear below the "fold"
		if (["ArrowDown", "ArrowUp"].includes(event.key)) {
			this.scrollToSupportItemSuggestion();
		}


		// Make sure the keyboard focus can't leave the dialog box
		if (event.key === 'Tab') {

			const activeElement = document.activeElement;
			const els = Array.from(this.form.nativeElement.querySelectorAll('button:not([disabled]), input'));
			const first = els[0];
			const last = els[els.length - 1];

			if (!event.shiftKey && activeElement === last) {
				this.focusControl(first, event);
			}
			else if (event.shiftKey && activeElement === first) {
				this.focusControl(last, event);
			}

		}
	}

	protected gstCodeDescription(gstCode: GSTCode): string {
		return GSTCodeUtils.toString(gstCode);
	}

	/**
	* Handles keyUp events to implement keyboard shortcuts on page
	* @param {KeyboardEvent} event
	*/
	@HostListener('window:keyup', ['$event'])
	private keyboardShortcut(event: KeyboardEvent) {

		// Close the dialog with the escape key
		if (event.key === 'Escape') {
			this.closeDialog();
			return;
		}

		// Date controls can use up/down arrows as a shortcut to increment and decrement their value
		if (this._dateFocused) {
			switch (event.key) {
				case "ArrowUp": {
					this.changeDate(+1, event.ctrlKey ? 'months' : 'days');
					break;
				}
				case "ArrowDown": {
					this.changeDate(-1, event.ctrlKey ? 'months' : 'days');
					break;
				}
			}
		}
	}

	/**
	* Increments or decrements a date value in the model by either a day or a month.
	* Values changed are either the invoice date, or the date of the current invoice line item.
	*
	* @param {DateIncrement} direction The direction to change the date (either +1 or -1)
	* @param {DateIncrementUnit} unit The date unit (either day or month) to modify
	*/
	private changeDate(direction: DateIncrement, unit: DateIncrementUnit): void {
		const dateStr = this.model.date;
		const now = moment().startOf('day');

		let newDateValue = now;
		if (dateStr !== undefined) {

			newDateValue = moment(this.model.lineItem.date).add(direction, unit);
			if (newDateValue.isAfter(now)) {
				newDateValue = now;
			}
		}

		this.model.lineItem.date = newDateValue.toDate();
		this.model.date = newDateValue.format('DDMMYYYY');
		this.model.dateDay = moment(this.model.lineItem.date).format('dddd') || "Day";
		this.planMap.set( this.model.lineItem, this.findPlanForLineItem(this.model.lineItem) );

		this.loadSupportItems();
		this.loadSelectedSupportItem();
		this.validator = new LineItemValidator(this._lineItems, this.supportMap, this.planMap, this.stillOnSamePlanBudget);
		this.validate();
	}

	/**
	* Returns the support item that matches the plan's region, service date and support item number
	*/
	private async getSupportBySupportItemNumber(): Promise<SupportItem> {
		const plan = this.planMap.get(this.model.lineItem);
		if (!plan || !this.model.lineItem.supportItemNumber) {
			return undefined;
		}

		const foundSupports = await this.supportsService.getSupportsFor(plan.region, this.model.lineItem.date, this.model.lineItem.supportItemNumber, undefined, plan.pace);
		if (Array.isArray(foundSupports) && foundSupports.length === 1) {
			return foundSupports[0];
		}
	}

	public supportItemNotAvailable: boolean = false;

	private async loadSelectedSupportItem() {

		const supportItem = await this.getSupportBySupportItemNumber();
		if (supportItem) {
			this._support = supportItem;
			this.model.lineItem.supportItem = this._support.id;
			this.model.lineItem.supportItemNumber = this._support.supportItemNumber;
			this.model.lineItem.serviceName = this._support.name;
			this.model.serviceName = this._support.name;
			this.supportItemNotAvailable = false;
			this.supportMap.set(this.model.lineItem, supportItem);
		}
		else {
			this._support = undefined;
			this.model.lineItem.supportItem = undefined;
			this.supportItemNotAvailable = !!this.model.lineItem.supportItemNumber;
		}

		this.findBudget();

		// if (!this._support && this.model.lineItem.supportItem) {
		// 	const support = await this.supportsService.getSupport(this.model.lineItem.supportItem);
		// 	this._support = support;
		// 	this.findBudget();
		// }
		// else if (this.model.lineItem.supportItem) {

		// 	const plan = this.planMap.get(this.model.lineItem);
		// 	if (!plan) {
		// 		this._support = undefined;
		// 		return;
		// 	}

		// 	// Check that we're using the correct support item
		// 	const foundSupports = await this.supportsService.getSupportsFor(plan.region, this.model.lineItem.date, this.model.lineItem.supportItemNumber);
		// 	if (!foundSupports || foundSupports.length !== 1) {
		// 		this._support = undefined;
		// 		return;
		// 	}
		// 	else {
		// 		this.model.lineItem.supportItem = foundSupports[0].id;
		// 		this._support = foundSupports[0];
		// 	}
		// }
	}

	/**
	* Sets the date on the model line item following a change on the text control.
	* Parses the string value (since it's a known format) and converts to a date value.
	* Triggers the selection of the plan that this line item pertains to.
	*/
	public setDate() {
		const m = moment(this.model.date, 'DDMMYYYY', true);
		if (m.isValid()) {

			this.model.lineItem.date = m.startOf('day').toDate();
			this.model.dateDay = moment(m).format('dddd') || "Day";
			this.planMap.set( this.model.lineItem, this.findPlanForLineItem(this.model.lineItem) );
			this.loadSupportItems();
			this.loadSelectedSupportItem();
			this.validator = new LineItemValidator(this._lineItems, this.supportMap, this.planMap, this.stillOnSamePlanBudget);
			this.validate();
		}
		else {
			this.model.lineItem.date = undefined;
			this.model.dateDay = "Day";
			this._supportItems = [];
		}
	}

	private get plan(): Plan {
		return this.planMap.get(this.model.lineItem);
	}

	/**
	* Change of date in the dialog should trigger a check to see if we've moved to a different price guide, and the currently
	* selected support item is still available.
	* Queries indexedDB for the region, date and support item number and updates the model and supports cache accordingly.
	*/
	private async updateSupportItem(date: Date) {

		assert(false, "Not used: updateSupportItem");

		if (!this.model.lineItem || !this.plan) {
			return;
		}

		const supports = await this.supportsService.getSupportsFor(this.plan.region, date, this.model.lineItem.supportItemNumber);
		if (supports.length === 1) {
			this._support = supports[0];
			this.model.lineItem.supportItem = this._support.id;
			this.model.lineItem.supportItemNumber = this._support.supportItemNumber;
			this.model.lineItem.serviceName = this._support.name;
			this.model.serviceName = this._support.name;
			this.supportItemNotAvailable = false;

			// this.supportItemCache.set(supports[0].id, supports[0]);
		}
		else {
			this._support = undefined;
			this.model.lineItem.supportItem = undefined;
			this.model.lineItem.supportItemNumber = undefined;
			this.model.lineItem.serviceName = undefined;
		}
	}

	public get supportItems(): SupportItem[] {
		return this._supportItems;
	}

	private async loadSupportItems(): Promise<void> {
		const plan = this.plan;
		if (!!plan && !!this.model.lineItem.date) {
			this._supportItems = await this.planService.getSupportItemsForRegion(plan.region, this.model.lineItem.date, plan.pace);
		}
		
		this._supportItems = this._providerId === config.ndsp ? this._supportItems.filter( item => item.supportCategoryId === 14 ) : this._supportItems;
	}

	protected calcQuantity() {
		const hours = Number(this.model.quantity.hours) || 0;
		const mins = Number(this.model.quantity.mins) || 0;
		this.model.lineItem.quantity = Number( ( hours + (mins / 60) ).toFixed(2) ); // force 2 decimals as that is all that PRODA will accept.
		this.calcUnitPrice();
	}

	protected syncQuantity() {
		if (this.model.quantity.type === QuantityType.decimal) {
			this.calcQuantity();
		}
		else if (this.model.lineItem.quantity) {
			this.model.quantity.hours = Math.floor(this.model.lineItem.quantity);
			this.model.quantity.mins = Math.round((this.model.lineItem.quantity - this.model.quantity.hours) * 60);
			this.validate();
		}
	}

	public closeDialog(addAnother: boolean = false): void {
		OverlayService.hide();
		this.dialogClosed.emit(addAnother);
	}

	ngOnInit() {
		super.ngOnInit();

		try {
			this._timeEntry = !!Settings.instance.get("defaultQuantityType").timeEntry;
		}
		catch (e) {
			this._timeEntry = false;
		}

		this.init();
	}

	ngAfterViewInit() {

		this.focusControl(this.lineItemDateControl);

		// Follow changes in the list of support item suggestions in the ngx-typeahead component
		this.suggestions.changes.subscribe(list => {
			this._supportItemSuggestionElements = list;
		});

	}

	/**
	* Sets the focus to the specified UI form field. Cancels event propagation from a keyboard event if supplied.
	* Initially coded to handle keyboard shortcuts to allow quick focus of controls.
	*
	* @param {ElementRef|any} control The UI control to focus
	* @param {KeyboardEvent} event Optional keyboard event that will have propagation terminated
	*/
	private focusControl(control: ElementRef|any, event?: KeyboardEvent) {
		if (event) {
			this.cancelEvent(event);
		}
		if (control.nativeElement) {
			setTimeout(() => { control.nativeElement.focus(); }, 0);
		}
		else {
			setTimeout(() => { control.focus(); }, 0);
		}
	}

	/**
	* Stops propagation and default behaviour of a keyboard event
	* Used to intercept keyDown events on the host, and prevent the browser from applying default behaviour
	* (eg Ctrl+R should not reload the page)
	*
	* @param {KeyboardEvent} event
	*/
	private cancelEvent(event: KeyboardEvent): void {
		event.stopImmediatePropagation();
		event.stopPropagation();
		event.preventDefault();
	}

	/**
	* Locates an active (selected) item in the list of suggested support items (in the ngx-typeahead component in the lineItem dialog).
	* If an active element is found, ensures it is scrolled into view.
	* A bit of a hack to ensure that the keyboard can't be used to select an item that isn't visible on-screen, since the ngx-typeahead
	* component doesn't handle scrolling automatically.
	*/
	private scrollToSupportItemSuggestion(): void {
		if (this._supportItemSuggestionElements.length) {

			const activeButtons = this._supportItemSuggestionElements.map( (item: ElementRef) => item.nativeElement ).filter( element => {
				const className = element.parentNode.className || "";
				return className.includes('active');
			});

			if (activeButtons.length) {
				const button = activeButtons.pop();
				button.parentNode.scrollIntoView(false);
			}

		}
	}

	get hasMaxRate(): boolean {
		return this._support && this._support.priceControl && (this._support.priceLimit > 0);
	}

	get maxRate(): number {
		if (this.hasMaxRate) {
			return this._support.priceLimit;
		}

		return undefined;
	}

	get isFixedRateSupport(): boolean {
		return this._support && !!this._support.fixedRate && this._support.fixedRate > 0;
	}

	public serviceDeliveredKeyUp(): void {
		if (this.model.serviceName !== this.model.lineItem.serviceName) {
			this._support = undefined;
			this.model.lineItem.supportItem = undefined;
			this.model.lineItem.supportItemNumber = undefined;
		}
	}

	public serviceDeliveredSelected(item: SupportItem): void {
		this._support = item;

		this.model.lineItem.supportItem = item.id;
		this.model.lineItem.supportItemNumber = item.supportItemNumber;
		this.model.lineItem.serviceName = item.name;
		this.model.serviceName = item.name;
		this.supportItemNotAvailable = false;

		this.findBudget();

		this.supportMap.set(this.model.lineItem, item);

		if (this.isHourlyRate) {

			if (this._timeEntry) {
				this.model.quantity.type = QuantityType.hoursAndMins;
			}

			if (this.model.lineItem.quantity) {
				this.model.quantity.hours = Math.floor(this.model.lineItem.quantity);
				this.model.quantity.mins = Math.round((this.model.lineItem.quantity - this.model.quantity.hours) * 60);
			}
		}
		else {
			this.model.quantity.type = QuantityType.decimal;
		}

		if (this.model.lineItem.total) {
			this.setTotal();
		}
		this.validate();
		this.focusControl(this.quantityControl);
	}

	/**
	* Returns a textual representation of the unit of measure.
	*
	* @return {string|undefined}
	*/
	public get unitOfMeasure(): string {
		if (!this._support) {
			return undefined;
		}

		return UnitOfMeasure.toString(this._support.unitOfMeasure);
	}

	protected decimalToHoursAndMinutes(): string {
		if (!this.model.lineItem.quantity) {
			return "";
		}

		const hours = Math.floor(this.model.lineItem.quantity);
		const mins = Math.round((this.model.lineItem.quantity - hours) * 60);

		return `${hours}hr ${mins}min`;
	}

	public calcUnitPrice() {
		if (!this._support || !this.model.lineItem.total || !this.model.lineItem.quantity) {
			this.unitPrice = undefined;
		} else {
			const myUnitPrice: number = Number( (this.model.lineItem.total / this.model.lineItem.quantity ).toFixed(2) );
			this.model.lineItem.rate = myUnitPrice;
			this.unitPrice = myUnitPrice;
		}

		this.validate();
	}

	public get isHourlyRate(): boolean {
		if (!this._support) {
			return false;
		}

		return this._support.unitOfMeasure === UnitOfMeasure.hour;
	}

	/**
	* Determines if the line item contains valid data, and whether the save button can be clicked.
	*
	* @return {boolean}
	*/
	public get canSave(): boolean {
		if(!this._initialised)
			return false;
		
		const tests = [
			this._support !== undefined,
			this.model.lineItem !== undefined,
			this.model.lineItem.date !== undefined,
			this.model.lineItem.total > 0
		];

		return tests.every(item => item === true) && this.validationPassed;
	}

	/**
	* Populates the line item name input's placeholder text with a suitable message.
	*
	* @return {string}
	*/
	public get lineItemPlaceHolder(): string {
		if (this.model.lineItem.date === undefined) {
			return "Select line item date to proceed";
		}

		if (!this.hasPlan) {
			return "No plan found for this support date";
		}

		if (this.supportItems.length === 0) {
			return "Loading...";
		}

		return "Select service delivered";
	}

	/**
	* Triggered when the user hits the "save" button. Adds the finalised line item to the event emitter so that the
	* parent controller can process it, and closes the dialog.
	*/
	public saveLineItem(addAnother: boolean = false): void {
		this.lineItemOutput.emit( this.model.lineItem );
		this.closeDialog(addAnother);
	}

	public validate() {
		const validation = this.validator.validate(this.model.lineItem, this._providerId);
		this.errors = validation.errors;
		this.validationPassed = validation.valid;
	}

	public get hasPlan(): boolean {
		return this.plan !== undefined;
	}

	private get hasCategoryBudget(): boolean {
		const plan = this.plan;
		return plan !== undefined && this._support !== undefined && plan.supportCategories.some( category => category.supportCategory.id === this._support.supportCategoryId );
	}

	public get hasBudget(): boolean {
		return !!this._budget;
	}

	public get budgetTypeTitle(): string {
		if (!this._budget) {
			return "";
		}

		switch (this.budget.budgetType) {
			case BudgetType.category: return "Category Budget";
			case BudgetType.supportItem: return "Support Item Budget";
			case BudgetType.serviceAgreementCategory: return "Service Agreement Budget";
			case BudgetType.serviceAgreementItem: return "Service Agreement Budget";
			case BudgetType.plan: return "Plan Budget";
		}
	}

	/**
	* Returns the PlanSupportItems from the current plan for the support item currently selected
	* In most cases, this will be zero or one item, however, it's possible that multiple items have been
	* defined in the plan (eg some portion of an item's budget is assigned to a particular provider)
	*/
	private get planSupportItems(): PlanSupportItem[] {

		assert(false, "Not used: planSupportItems");

		if (this.plan !== undefined && this._support !== undefined) {

			if ( typeof(this.planSupportCategory) === 'undefined' ) {
				return undefined;
			}

			return Array.from( this.planSupportCategory.supportItems.values() ).filter( (item: PlanSupportItem) => {
				return item.supportItem.supportItemNumber === this._support.supportItemNumber;
			});

		}

		return [];
	}

	/**
	* Determines if there is a budget for the selected support item
	*/
	private get hasItemBudget(): boolean {
		return this._budget && this._budget.budgetType === BudgetType.supportItem && this._budget.total > 0;
	}

	/**
	* Returns the PlanSupportCategory for the category of the selected support item on the current plan
	*/
	private get planSupportCategory(): PlanSupportCategory {
		if ( typeof(this._support) === 'undefined' ) {
			return undefined;
		}

		return this.plan.supportCategories.find( category => category.supportCategory.id === this._support.supportCategoryId );
	}

	/**
	* Returns the total budget in the plan for the current support category
	*/
	public get budgetTotal(): number {
		try {
			assert(!!this._budget);
			return this._budget.total;
		}
		catch (e) {
			if (e instanceof EAssertionFailed) {
				return undefined;
			}

			throw e;
		}
	}

	/**
	* Returns the total amount paid against the budget
	*/
	public get budgetAmountPaid(): number {
		try {
			assert(!!this._budget);
			return this._budget.paid || 0;
			// return this.budgetSpend(this._budget);
		}
		catch (e) {
			if (e instanceof EAssertionFailed) {
				return undefined;
			}

			throw e;
		}
	}

	public budgetsEqual(): boolean {
		if(this._initialBudget && this._budget) {
			for (let key in this._budget) {
				if(this._initialBudget[key] !== this._budget[key])
					return false;
			}
			return true;
		} else {
			return false;
		}
	}

	public get stillOnSamePlanBudget(): boolean {
		try {
			if(this._initialPlan === undefined)
				return false;
			const planForLineItem = this.findPlanForLineItem(this.model.lineItem);
			assert(!!planForLineItem);

			return this._initialPlan.id === planForLineItem.id && this.budgetsEqual();
		} catch (e) {
			if (e instanceof EAssertionFailed) {
				return undefined;
			}

			throw e;
		}
	}

	/**
	* Returns the total amount on unpaid invoices against the current budget. These could be on other draft invoice,
	* or other lines on this invoice. It excludes the current line item, however.
	*/
	public get budgetAmountPending(): number {
		try {
			assert(!!this._budget);
			assert(!!this._srcLineItem);
			
			return Math.max(((this._budget.pending || 0) + (this._budget.draft || 0) - (this.stillOnSamePlanBudget && (this._budget.pending >= this._srcLineItem.total) ? this._srcLineItem.total : 0)), 0);
		}
		catch (e) {
			if (e instanceof EAssertionFailed) {
				return undefined;
			}

			throw e;
		}
	}

	public get notAServiceAgreement(): boolean {
		try {
			assert(!!this._budget);
			return [BudgetType.plan, BudgetType.supportItem, BudgetType.category].includes(this._budget.budgetType);
		}
		catch (e) {
			if (e instanceof EAssertionFailed) {
				return undefined;
			}

			throw e;
		}
	}

	public get notAServiceAgreementItem(): boolean {
		try {
			assert(!!this._budget);
			return this._budget.budgetType !== BudgetType.serviceAgreementItem;
		}
		catch (e) {
			if (e instanceof EAssertionFailed) {
				return undefined;
			}

			throw e;
		}
	}

	/**
	* Returns the total amount of quarantined funds in a category
	*/
	public get quarantinedAmount(): number {
		try {
			assert(!!this._budget);
			return this._budget.quarantined;
		}
		catch (e) {
			if (e instanceof EAssertionFailed) {
				return undefined;
			}

			throw e;
		}
	}

	public get budgetRemaining(): number {
		try {
			assert(!!this._budget);
			assert(!!this._srcLineItem);

			let currentSpend = Math.max(((this._budget.paid || 0) + (this._budget.unknown || 0) + (this._budget.pending || 0) + (this._budget.draft || 0) - (this.stillOnSamePlanBudget && (this._budget.pending >= this._srcLineItem.total) ? this._srcLineItem.total : 0)), 0);

			return ((this._budget.total || 0) - (this._budget.quarantined || 0) - currentSpend);
		}
		catch (e) {
			if (e instanceof EAssertionFailed) {
				return undefined;
			}

			throw e;
		}
	}

	private get planTotal(): number {
		return this.plan.total || 0;
	}

	public max: (a: number, b: number) => number = Math.max;

	public setTotal(): void {
		try {
			this.model.lineItem.total = Number(this.model.lineItem.total);
			if (this.isFixedRateSupport) {
				this.model.lineItem.quantity = this.model.lineItem.total / this._support.fixedRate;
				this.model.quantity.hours = this.model.lineItem.quantity;
				this.model.quantity.minutes = 0;
			}
			this.calcUnitPrice();
		}
		catch (e) {
			this.model.lineItem.total = undefined;
		}
	}

	public setDiscrepancy(): void {
		try {
			this.model.lineItem.discrepancy = Number(this.model.lineItem.discrepancy);
			this.validate();
		}
		catch (e) {
			this.model.lineItem.discrepancy = undefined;
		}
	}

	public planInfo(): string {
		if (!this.plan) {
			return '';
		}

		const dateFormat = 'DD/MM/YYYY';
		const startDate = DateUtils.toString(this.plan.startDate, dateFormat);
		const endDate = DateUtils.toString(this.plan.endDate, dateFormat);
		const status = PlanStatus.toString(this.plan.status);
		const region = RegionUtils.toString(this.plan.region).toUpperCase();
		const pace = this.plan.pace ? ' (PACE)' : '';
		return `${startDate} - ${endDate} (${status}) (Region: ${region})${pace}`;
	}

}
