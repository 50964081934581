// AWS Amplify polyfill for Angular 6
(window as any).global = window;

import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

import { Amplify } from "aws-amplify";
import amplifySettings from "./aws-settings";

Amplify.configure(amplifySettings);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
