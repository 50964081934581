import { Component, OnInit, Input } from '@angular/core';
import { DataExchangeService } from '@services/dataexchange.service';
import { ActivatedRoute } from '@angular/router';
import { PrivateComponent } from "@classes/private.component";
import { SupportCoordinatorTransactionService, Transaction } from '@services/transaction.service';
import * as tables from "@classes/tables";

@Component({
	"selector": "support-coordinator-transactions",
	"styleUrls": ["./transactions.component.scss"],
	"templateUrl": "./transactions.component.html"
})
export class SupportCoordinatorTransactionsComponent extends PrivateComponent implements OnInit {

	private readonly tableHeadings: tables.TableColumnHeading[] = [
		{"propName": "ndisNumber", "displayName": "NDIS #", "sortType": tables.SortType.text },
		{"propName": "clientName", "displayName": "Client Name", "sortType": tables.SortType.name },
		{"propName": "serviceDate", "displayName": "Service Date", "sortType": tables.SortType.date },
		{"propName": "total", "displayName": "Amount", "sortType": tables.SortType.numeric },
		{"propName": "account", "displayName": "Provider", "sortType": tables.SortType.text },
		{"propName": "supportCategory", "displayName": "Category", "sortType": tables.SortType.text }
	];

	private _supportCoordinatorId: string = undefined;
	private _dataLoaded: boolean = false;

	@Input()
	set supportCoordinatorId(value: string) {
		this._supportCoordinatorId = value;
		this.loadTransactions();
	}

	get supportCoordinatorId(): string {
		return this._supportCoordinatorId;
	}

	table: tables.Table<Transaction> = new tables.Table('supportCoordinatorTransactions', this.tableHeadings);

	get dataLoaded(): boolean {
		return this._dataLoaded;
	}

	readonly ascending: tables.SortDirection = tables.SortDirection.ascending;
	readonly descending: tables.SortDirection = tables.SortDirection.descending;

	constructor(private route: ActivatedRoute, private transactionService: SupportCoordinatorTransactionService) {
		super();
	}

	ngOnInit() {
		super.ngOnInit();

		const filterId: number = DataExchangeService.get('supportCoordinatorTransactions.filter', false) || 1;
		// this.filterModel = this.dateFilters.find( filter => filter.id === filterId );

	}

	private loadTransactions(forceReload: boolean = false) {
		this._dataLoaded = false;

		if (!this.supportCoordinatorId) {
			return;
		}

		this.transactionService.getData(this.supportCoordinatorId, forceReload).then( result => {

			this._dataLoaded = true;
			this.table.sourceData = tables.StaticDataSource.from(result);

		}).catch( err => {

			console.log(err);
			this._dataLoaded = true;
			this.table.sourceData = tables.StaticDataSource.from([]);

		});
	}

	forceReload(): void {
		this.loadTransactions(true);
	}
}
