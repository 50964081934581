import { Injectable, OnDestroy } from '@angular/core';
import { RestService, API } from './rest.service';
import { UserAccount, UserAccountUtils } from '@classes/user';
import { FilterFunc } from '@classes/datafilter';
import { Cache } from "@classes/cache";
import { CacheSignalService } from "@services/cachesignal.service";
import { SignalReceiver } from "@classes/signalreceiver.class";

@Injectable({ providedIn: 'root' })
export class UserAccountService extends SignalReceiver implements OnDestroy {

	private _cache: Cache<UserAccount> = new Cache<UserAccount>();
	private _admins: Cache<UserAccount> = new Cache<UserAccount>();
	private _accountsThanCanBeNominees: Cache<UserAccount> = new Cache<UserAccount>();

	constructor(private restService: RestService, signalService: CacheSignalService) {
		super(signalService);
	}

	protected setupSubscriptions() {

		// Delete all plans for a client when an invoice is saved. This will force fresh data to be loaded
		// allowing totals to be reflected correctly.
		this._subscriptions.push( this.signalService.observable('User Created').subscribe( () => {
			this._cache.invalidate();
			this._admins.invalidate();
			this._accountsThanCanBeNominees.invalidate();
		}) );
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	private cachedData(filter?: FilterFunc): Promise<UserAccount[]> {
		return Promise.resolve( !!filter ? this._cache.items.filter(filter) : this._cache.items );
	}

	private requestData(filter?: FilterFunc): Promise<UserAccount[]> {
		return this.restService.get(API.admin, "accounts/summary").then( result => {

			this._cache.items = result.map( UserAccountUtils.parse );

		}).then( () => {

			return this.cachedData(filter);

		});
	}

	public getAccounts(forceReload: boolean = false, searchText?: string): Promise<UserAccount[]> {
		if (forceReload) {
			this._cache.invalidate();
		}

		const filterFunc: FilterFunc = searchText ? item => item.name.toLowerCase().includes(searchText.toLowerCase()) : undefined;

		return this._cache.valid ? this.cachedData(filterFunc) : this.requestData(filterFunc);
	}

	public getAdminsWithDraftInvoices(): Promise<UserAccount[]> {
		return this.restService.get(API.dashboards, "admin/staffWithDraftInvoices").then( result => {
			return Promise.resolve( result.map( UserAccountUtils.parse ) );
		});
	}

	public getAdminAccounts(forceReload: boolean = false, fileNoteId?: string): Promise<UserAccount[]> {
		if (forceReload) {
			this._admins.invalidate();
		}

		if (this._admins.valid) {
			return Promise.resolve(this._admins.items);
		}

		const pathParts = ["accounts", "admin"];
		if (!!fileNoteId) {
			pathParts.push(fileNoteId);
		}

		return this.restService.get(API.admin, pathParts.join("/")).then( result => {
			this._admins.items = result.map( UserAccountUtils.parse );
			return Promise.resolve(this._admins.items);
		});
	}

	public getAccountsThatCanBeNominees(): Promise<UserAccount[]> {
		if (this._accountsThanCanBeNominees.valid) {
			return Promise.resolve(this._accountsThanCanBeNominees.items);
		}

		return this.restService.get(API.user, "nominees/all").then( result => {
			this._accountsThanCanBeNominees.items = result.nominees.map( UserAccountUtils.parse );
			return Promise.resolve(this._accountsThanCanBeNominees.items);
		});
	}

	public async deleteUser(userId: string, reassignTasksTo?: string): Promise<boolean> {
		const data = {"reassignTasksTo": reassignTasksTo};
		if (!reassignTasksTo) {
			delete data.reassignTasksTo;
		}
		console.log(data);
		const result = await this.restService.delete(API.system, `user/${userId}`, data);
		return result.success;
	}

	public removeUserFromCache(userId: string):void {
		[this._admins, this._cache].forEach( cache => {

			if (cache.valid) {

				const items = cache.items;
				const idx = items.findIndex( item => item.id === userId );
				if (idx >= 0) {
					items.splice(idx, 1);
					cache.items = items;
				}
			}
		});
	}

	// public replaceCachedNominee(replaceAccount: UserAccount): void {
	// 	if (this._accountsThanCanBeNominees.valid) {
	// 		this._accountsThanCanBeNominees.items = this._accountsThanCanBeNominees.items.filter( cachedAccount => cachedAccount.id !== replaceAccount.id );
	// 		this._accountsThanCanBeNominees.items.push(replaceAccount);
	// 	}
	// }
}
