import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { OverlayService } from '@services/overlay.service';
import { FileNotesService } from '@services/filenotes.service';
import { PrivateComponent } from "@classes/private.component";
import { FileNote, UpdateNotesComponent } from "@classes/filenotes";
import { UserType } from "@classes/user";
import { PaginatedTable, TableColumnHeading, SortType, StaticDataSource } from "@classes/tables";
import { Utils } from "@classes/utils";
import { SimplePaginator, PaginationProvider } from '@classes/tables';
import { Subscription } from 'rxjs';
import { Settings } from '@classes/settings';
import { ErrorUtils } from "@classes/errors";
import { FileNoteStatus } from "@classes/filenotes";
import { SelectedUser } from "../tasks.component";
import moment from 'moment';

enum Tabs {
	taskTable, taskCards
}
interface ReminderModel {
	type: string,
	from: Date,
	to: Date
}
// interface DataParams {
// 	selectedUserId: SelectedUser, 
// 	filterStatus: FileNoteStatus, 
// 	filterOverdue: boolean, 
// 	filterReminder: ReminderModel
// }
@Component({
	"selector": "task-list",
	"styleUrls": ["list.component.scss"],
	"templateUrl": "./list.component.html"
})

export class TaskListComponent extends PrivateComponent implements OnInit, OnDestroy {

	private static readonly defaultResultsPerPage: number = 100;
	private numFilenotes: number;

	private readonly tableHeadings: TableColumnHeading[] = [
		{ "propName": "selected", "displayName": "", "sortType": SortType.none },
		{ "propName": "title", "displayName": "Title", "sortType": SortType.text },
		{ "propName": "content", "displayName": "Content", "sortType": SortType.text },
		{ "propName": "clientName", "displayName": "Client", "sortType": SortType.name },
		{ "propName": "createdDate", "displayName": "Created", "sortType": SortType.date },
		{ "propName": "createdBy.name", "displayName": "By", "sortType": SortType.none },
		{ "propName": "assignedTo.name", "displayName": "Assigned", "sortType": SortType.none },
		{ "propName": "reminder", "displayName": "Reminder", "sortType": SortType.date },
		{ "propName": "", "displayName": "", "sortType": SortType.none }
	];

	@ViewChild('editNoteDialog') private noteDialog: TemplateRef<any>;
	@ViewChild('updateNotesDialog') private updateNotesDialog: TemplateRef<any>;

	private _dataLoaded: boolean = false;
	private _metadataLoaded: boolean = false;
	private _src: FileNote[] = [];
	private _selectedNumber = 0;
	private _selected: {} = {};
	private _paginator: SimplePaginator = new SimplePaginator();
	private _pageChangeSubscription: Subscription;
	private _selectedUser: SelectedUser = undefined;
	private _filterStatus: FileNoteStatus = undefined;
	private _filterOverdue: boolean = false;
	private _filterReminderType: string = "all";
	private _filterReminderFrom: Date = undefined;
	private _filterReminderTo: Date = undefined;
	// private _loadTasksParams: DataParams;
	// private _taskMetaParams: DataParams;

	private _currentTab: Tabs = Tabs.taskCards;
	public get currentTab(): Tabs { return this._currentTab; }

	public readonly tab = {
		"taskTable": Tabs.taskTable,
		"taskCards": Tabs.taskCards,
	}

	public changeTab(tab: Tabs): boolean {
		this._currentTab = tab;
		return false;
	}

	private get selectedUserId(): string {
		return this._selectedUser && this._selectedUser.id;
	}

	protected get getTasksSelected(): Object[] {
		return Object.keys(this._selected).filter(t => {return this._selected[t]});
	}
	protected get getNotesSelected(): Object[] {
		return Object.keys(this._selected).filter(t => {return this._selected[t]});
	}
	protected get selectedNumber(): number {
		return this._selectedNumber;
	}

	readonly tasksTable: PaginatedTable<FileNote> = new PaginatedTable('invoiceSearchResults', this.tableHeadings);

	get statusDescription(): string {
		let from = moment(this._filterReminderFrom).format('DD/MM/YY');
		let to = moment(this._filterReminderTo).format('DD/MM/YY');
		let result = 'Showing';

		if (this._filterStatus === undefined) {
			result += ' all';

			if (this.selectedUserId === null) {
				result += ' unassigned';
				result += this._filterOverdue ? ' and overdue items' : ' items';
			}
			else {
				result += this._filterOverdue ? ' overdue items' : ' items';
			}

			if (this.selectedUserId) {
				result += ' assigned to ' + this._selectedUser.name;
			}
		}
		else {
			result += ' ' + FileNoteStatus.toString(this._filterStatus).toLowerCase();
			result += this._filterOverdue ? ' and overdue' : '';

			result += this.selectedUserId === null ? ' unassigned items' : ' items';
			if (this.selectedUserId) {
				result += ' assigned to ' + this._selectedUser.name;
			}
		}
		
		if (this._filterReminderType == 'none') {
			result += ' with no reminders';
		} else if(this._filterReminderType == 'reminder') {
			result += ' with reminders';
		}
		if(!!this._filterReminderFrom && !!this._filterReminderTo){
			result += ` between ${from} and ${to}`;
		} else if (!!this._filterReminderFrom) {
			result += ` after ${from}`;
		} else if (!!this._filterReminderTo) {
			result += ` before ${to}`;
		}

		if (this._src.length) {
			let notesFrom = (this._paginator.currentPage - 1) * 100 + 1;
			let notesTo = this._src.length >= 100 ? (this._paginator.currentPage) * 100 : (this._paginator.currentPage-1) * 100 + this._src.length;
			result += ` (${notesFrom}-${notesTo} of ${this.numFilenotes})`;
		}

		return result;
	}

	get dataLoaded(): boolean {
		return this._dataLoaded;
	}

	get metadataLoaded(): boolean {
		return this._metadataLoaded;
	}

	get notes(): FileNote[] {
		return this._src;
	}

	get paginator(): PaginationProvider {
		return this._paginator;
	}

	@Input()
	set filterUser(value: SelectedUser) {
		if(value != this._selectedUser) {
			this._selectedUser = value;
			this.loadData();
		}
	}

	@Input()
	set filterStatus(value: FileNoteStatus) {
		if(value != this._filterStatus) {
			this._filterStatus = value;
			this.loadData();
		}
	}

	@Input()
	set filterOverdue(value: boolean) {
		if(value != this._filterOverdue) {
			this._filterOverdue = value;
			this.loadData();
		}
	}
	
	@Input()
	set filterReminderType(value: string) {
		if(value != this._filterReminderType) {
			this._filterReminderType = value;
			this.loadData();
		}
	}
	
	@Input()
	set filterReminderFrom(value: string) {
		let from = this.parseDateStr(value)
		if(from != this._filterReminderFrom) {
			this._filterReminderFrom = from;
			this.loadData();
		}
	}
	
	@Input()
	set filterReminderTo(value: string) {
		let to = this.parseDateStr(value)
		if(to != this._filterReminderTo) {
			this._filterReminderTo = to;
			this.loadData();
		}
	}

	@Output()
	onNoteSaved = new EventEmitter<void>();

	@Output()
	onUpdateNotes = new EventEmitter<void>();

	constructor(private filenotesService: FileNotesService) {
		super();
		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Admin Dashboard', 'All Alerts');
	}

	private get resultsPerPage(): number {
		const settings = Settings.instance;
		if (settings.has('tasks')) {
			const value = settings.get('tasks') || {};
			return value.itemsPerPage || TaskListComponent.defaultResultsPerPage;
		}
		return TaskListComponent.defaultResultsPerPage;
	}
	private get filterReminder():ReminderModel {
		return {
			type: this._filterReminderType, 
			from: this._filterReminderFrom, 
			to: this._filterReminderTo
		};
	}
	
	private parseDateStr(value: string): Date {
		try {
			const m = moment(value, 'DDMMYYYY', true);
			return m.isValid() ? m.startOf('day').toDate() : undefined;
		}
		catch (e) {
			return undefined;
		}
	}

	ngOnInit() {
		super.ngOnInit();
		this._pageChangeSubscription = this._paginator.pageChangeObservable.subscribe(pageNumber => {
			this.loadTasks(pageNumber);
		});
		this.loadData();
	}

	ngOnDestroy() {
		this._pageChangeSubscription.unsubscribe();
	}

	private isFutureDate(date: Date): boolean {
		return moment(date).startOf('day').isSameOrAfter(moment().startOf('day'));
	}
	// private getDataParams(selectedUserId, filterStatus, filterOverdue, filterReminder):DataParams {
	// 	let params:DataParams = {
	// 		selectedUserId,
	// 		filterStatus,
	// 		filterOverdue,
	// 		filterReminder
	// 	}
	// 	return params
	// }
	// private compareObjects(value1, value2){
	// 	return JSON.stringify(value1) == JSON.stringify(value2);
	// }

	private async loadTasks(page: number = 1) {
		// don't load if no params has changed
		// let newParams = this.getDataParams(this.selectedUserId, this._filterStatus, this._filterOverdue, this.filterReminder);
		// if (this.compareObjects(this._loadTasksParams, newParams)) return;
		// this._loadTasksParams = newParams;
		try {
			this._dataLoaded = false;
			await this.filenotesService.loadTasks(page, this.selectedUserId, this._filterStatus, this._filterOverdue, this.filterReminder).then(result => {
				this._src = result;
				this._selected = result.reduce((ac, a) => ({ ...ac, [a.id]: false }), {});
				this._selectedNumber = result.length;
				this.tasksTable.sourceData = StaticDataSource.from(this._src);
			});
			
			this._dataLoaded = true;
		}
		catch (e) {
			console.log(e);
		}
	}

	private async loadData(pageNumber: number = 1) {
		// don't load if no params has changed
		// let newParams = this.getDataParams(this.selectedUserId, this._filterStatus, this._filterOverdue, this.filterReminder);
		// if (this.compareObjects(this._taskMetaParams, newParams)) return;
		// this._taskMetaParams = newParams;
		this._dataLoaded = false;
		this._metadataLoaded = false;
		if (this.user) {
			try {
				this.numFilenotes = await this.filenotesService.taskMetadata(this.selectedUserId, this._filterStatus, this._filterOverdue, this.filterReminder);
				this._paginator.init(this.numFilenotes, this.resultsPerPage, pageNumber);
				await this.loadTasks(this._paginator.currentPage);
				this._metadataLoaded = true;
			} catch (e) {
				console.log(e);
			}
		}
	}

	protected isTaskSelected(noteId:string):boolean{
		return this._selected[noteId];
	}

	protected selectTask(noteId: string):void {
		this._selected[noteId] = !this._selected[noteId];
	}

	protected selectTasks(): void {
		Object.keys(this._selected).forEach(t => this._selected[t] = false);
		if(this.currentTab == Tabs.taskTable){
			let tasks = this.tasksTable.displayData;
			let n = this._selectedNumber < tasks.length ? this._selectedNumber : tasks.length;
			for(var i=0; i<n; i++){
				this._selected[tasks[i].id] = true;
			}
		} else {
			let tasks = Object.keys(this._selected);
			let n = this._selectedNumber < tasks.length ? this._selectedNumber : tasks.length;
			for(var i=0; i<n; i++){
				this._selected[tasks[i]] = true;
			}
		}
	}

	protected updateTasksSelected() {
		if(this.getTasksSelected.length > 0) {
			OverlayService.showTemplate(this.updateNotesDialog, this.getTasksSelected);
		}
	}

	protected updateNotes(notes: UpdateNotesComponent): void {
		OverlayService.show();
		this.filenotesService.updateNotes(notes).then(() => {
			this.onUpdateNotes.next();
			return this.loadData(this._paginator.currentPage);
		}).then( () => {
			OverlayService.hide();
		}).catch( e => {
			OverlayService.hide();
			console.log(e);
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "An unknown error occurred"));

		});
	}

	protected selectTasksNone(): void {
		Object.keys(this._selected).forEach(t => this._selected[t] = false);
	}

	public get model(): any {
		return { selected: this._selected, selectedNumber: this._selectedNumber };
	}

	protected setSelectedNumber(n) {
		let max = Object.keys(this._selected).length;
		if(n > max) n = max;
		this._selectedNumber = n;
	}

	protected truncateContent(content: string): string {
		return Utils.truncateString(content);
	}

	public gotoClient(clientId: string): void {
		this.router.navigate([`/account/${clientId}`]);
	}

	public showNote(noteId: string): void {
		OverlayService.show();
		this.filenotesService.loadNote(noteId).then( note => {
			OverlayService.showTemplate(this.noteDialog, note);
		});
	}

	public saveNote(note: FileNote): void {
		OverlayService.show();
		this.filenotesService.saveNote( note ).then( () => {
			this.onNoteSaved.next();
			return this.loadData(this._paginator.currentPage);
		}).then( () => {
			OverlayService.hide();
		}).catch( e => {
			OverlayService.hide();
			console.log(e);
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "An unknown error occurred"));
		});
	}
}
