import { Component, OnInit, Input } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { InvoiceService, Invoice } from "@services/invoice.service";
import { Alignment, Table, TableColumnHeading, SortType, StaticDataSource } from "@classes/tables";

@Component({
	"selector": "support-coordinator-invoices",
	"styleUrls": [],
	"templateUrl": "./invoices.component.html"
})
export class SupportCoordinatorInvoicesComponent extends PrivateComponent implements OnInit {

	private readonly tableHeadings: TableColumnHeading[] = [
		{"propName": "clientName", "displayName": "Client", "sortType": SortType.name },
		{"propName": "date", "displayName": "Invoice Date", "sortType": SortType.date },
		{"propName": "account", "displayName": "Account", "sortType": SortType.text },
		{"propName": "total", "displayName": "Total", "sortType": SortType.numeric, "alignment": Alignment.right },
		{"propName": undefined, "displayName": "", "sortType": SortType.none }
	];

	private _supportCoordinatorId: string = undefined;
	private _dataLoaded: boolean = false;

	private get isSelf(): boolean {
		return this.user ? this.user.id === this.supportCoordinatorId : false;
	}

	@Input()
	set supportCoordinatorId(value: string) {
		if (value) {
			this._supportCoordinatorId = value;
			this.loadData();
		}
	}

	get supportCoordinatorId(): string {
		return this._supportCoordinatorId;
	}

	table: Table<Invoice> = new Table('supportCoordinatorClients', this.tableHeadings);

	get dataLoaded(): boolean {
		return this._dataLoaded;
	}

	constructor(private invoiceService: InvoiceService) {
		super();
	}

	ngOnInit() {
		super.ngOnInit();
	}

	private loadData(forceReload: boolean = false) {
		this._dataLoaded = false;

		if (!this.supportCoordinatorId) {
			return;
		}

		this.invoiceService.getSupportCoordinatorInvoices(this.supportCoordinatorId).then( result => {

			this._dataLoaded = true;
			this.table.sourceData = StaticDataSource.from(result);

		}).catch( err => {

			console.log(err);
			this._dataLoaded = true;
			this.table.sourceData = StaticDataSource.from([]);

		});
	}

	forceReload(): void {
		this.loadData(true);
	}
}
