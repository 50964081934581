import { Injectable } from "@angular/core";
import { RestService, API } from "./rest.service";
import { AttachedFile, FileDownloader } from "@classes/files";
import { AttachmentTarget, AttachmentTargetUtils } from "@classes/attachments";
import { AttachmentType } from "@classes/attachmentType";
import { FileChanges } from "@classes/filemanager";

@Injectable({ providedIn: "root" })
export class AttachmentsService implements FileDownloader {

	constructor(private restService: RestService) {}

	public async loadAttachment(attachmentId: string): Promise<any> {
		const downloadRequest = await this.restService.get(API.attachments, `${attachmentId}`);

		const download = await fetch(downloadRequest.url, {
			"method": "GET",
			"mode": "cors",
			"cache": "no-cache",
			"headers": {}
		});

		return await download.arrayBuffer();
	}

	private removeFiles(deletedFiles: string[]): Promise<any> {
		if (!deletedFiles.length) {
			return Promise.resolve();
		}

		return Promise.all( deletedFiles.map( fileId => this.restService.delete(API.attachments, `${fileId}`) ) );
	}

	private async uploadFile(file: AttachedFile, targets: AttachmentTarget[]): Promise<AttachedFile> {

		const postData = {
			"file": {
				"name": file.name,
				"mimeType": file.mimeType,
				"size": file.size,
				"md5": file.md5
			},
			"metadata": {
				"description": file.description || null
			}
		};

		// Request a pre-signed URL from S3
		// const uploadRequest = await this.restService.post(API.attachments, "upload", postData);
		const uploadRequest = await this.restService.put(API.attachments, "", postData);

		// Use Fetch API, because the Angular HttpClient adds a bunch of headers that stops the upload working correctly.
		const response = await fetch(uploadRequest.url, {
			"method": "PUT",
			"mode": "cors",
			"cache": "no-cache",
			"headers": {
				"Content-Type": file.mimeType
			},
			"body": file.file
		});

		// Confirm to the server that the file was uploaded successfully, and link it to the specified attachment targets
		const confirmData = {
			"targets": targets.map( AttachmentTargetUtils.toJson )
		};
		await this.restService.post(API.attachments, `confirm/${uploadRequest.fileId}`, confirmData);

		file.id = uploadRequest.fileId;
		return file;
	}

	private saveFiles(targets: AttachmentTarget[], files: AttachedFile[]): Promise<any> {

		const promises = files.map( file => {

			return this.uploadFile(file, targets);

			// return file.content.then( content => {
			// 	return {
			// 		"file": {
			// 			"name": file.name,
			// 			"mimeType": file.mimeType,
			// 			"content": content.replace(/^.*,/, ''), // strip out the "data:[<mediatype>][;base64]," header info
			// 			"size": file.size,
			// 			"md5": file.md5
			// 		},
			// 		"metadata": {
			// 			"description": file.description || null,
			// 			"targets": targets.map( AttachmentTargetUtils.toJson )
			// 		}
			// 	};
			// }).then( postData => {

			// 	return this.restService.put(API.attachments, "", postData);

			// }).then( response => {

			// 	if (response.success) {
			// 		console.log("File PUT successful");
			// 		file.id = response.fileId;
			// 		return Promise.resolve(file);
			// 	}

			// 	return Promise.reject("File PUT unsuccessful");
			// });
		});

		return Promise.all(promises);
	}

	private async updateAttachmentTypes(files: AttachedFile[]) {
		if (!files.length) {
			return;
		}

		const promises = files.map( file => {
			const postData = {
				"type": AttachmentType.toPostgresEnum(file.attachmentType)
			};

			return this.restService.patch(API.attachments, `type/${file.id}`, postData);
		});

		await Promise.all(promises);
	}

	// public saveAttachments(target: AttachmentTarget[], newFiles: AttachedFile[], deletedFiles: string[], changedTypes?: AttachedFile[]): Promise<any> {
	public saveAttachments(target: AttachmentTarget[], changes: FileChanges): Promise<any> {
		let savedFiles;

		return this.saveFiles(target, changes.newFiles).then( result => {

			savedFiles = result;
			return this.removeFiles(changes.deletedFiles);

		}).then( () => {

			return this.updateAttachmentTypes(changes.changedTypes);

		}).then( _ => {

			return Promise.resolve({
				"savedFiles": savedFiles,
				"deletedFiles": changes.deletedFiles
			});

		});
	};
}
