import { Component, OnInit, Input } from '@angular/core';

@Component({
	"selector": "[autotableheader]",
	"styleUrls": ["./tableheader.component.scss"],
	"templateUrl": "./tableheader.component.html"
})
export class TableHeaderComponent implements OnInit {

	private _table: any = undefined;

	@Input()
	set table(value: any) {
		this._table = value;
	}

	get table(): any {
		return this._table;
	}

	ngOnInit() {}

}
