import { Component, OnInit, Input } from '@angular/core';
import { OverlayService } from '@services/overlay.service';
import { PrivateComponent } from "@classes/private.component";
import { UserType, UserAccount } from "@classes/user";
import { Provider, ProviderService } from '@services/provider.service';
import { Utils, DateUtils } from "@classes/utils";
import { PaginatedTable, TableColumnHeading, SortType, Alignment, StaticDataSource } from "@classes/tables";
import { InvoiceHeader } from '@classes/invoices';
import { ReimbursementsSearch } from "@services/invoice2.service";
import moment from 'moment';

interface InvoiceFilter {
	type: string;
	reimbursements: number;
	from?: string;
	to?: string;
}

@Component({
	"selector": "provider-bills",
	"styleUrls": ["./providerbills.component.scss"],
	"templateUrl": "./providerbills.component.html"
})
export class ProviderBillsComponent extends PrivateComponent implements OnInit {

	private _provider: Provider = undefined;

	private _billFilter: string = 'recent';
	private _fromDate: Date = undefined;
	private _toDate: Date = undefined;
	private _linkedClients: UserAccount[] = [];

	protected context = {
		"fromDate": this._fromDate ? moment(this._fromDate, "DDMMYYYY").format('YYYY-MM-DD') : undefined,
		"toDate": this._toDate ? moment(this._toDate, "DDMMYYYY").format('YYYY-MM-DD') : undefined
	};

	public clientId: string = undefined;
	public reimbursementSearchType: ReimbursementsSearch = ReimbursementsSearch.exclude;

	protected get isAdmin(): boolean {
		return this.user ? this.user.accountType === UserType.Admin : false;
	}

	public get billFilter(): string {
		return this._billFilter;
	}

	public set billFilter(_value: string) {
		this._billFilter = _value;
	}

	protected setDateFrom(value: string) {
		// value = value.replace(/^(\d{1,2})\/(\d)\//, '$1/0$2/');
		// this.context.fromDate = value.replace(/^(\d)\//, '0$1/');
		this._fromDate = DateUtils.parse(this.context.fromDate, 'DDMMYYYY');
	}

	protected setDateTo(value: string) {
		this._toDate = DateUtils.parse(value, 'DDMMYYYY');
	}

	private _filteredBills: InvoiceHeader[] = [];
	public get filteredBills(): InvoiceHeader[] {
		return this._filteredBills;
	}

	// Table headings for list of Bills
	private readonly tableHeadingsBills: TableColumnHeading[] = [
		{"propName": "dateEntered", "displayName": "Date Entered", "sortType": SortType.date },
		{"propName": "invoiceDate", "displayName": "Bill Date", "sortType": SortType.date },
		{"propName": "invoiceNumber", "displayName": "Bill Number", "sortType": SortType.text },
		{"propName": "clientName", "displayName": "Client", "sortType": SortType.text },
		//{"propName": "ndisNumber", "displayName": "NDIS No.", "sortType": SortType.text },
		{"propName": "total", "displayName": "Total", "sortType": SortType.numeric, "alignment": Alignment.right },
		{"propName": "status", "displayName": "Status", "sortType": SortType.text },
		{"propName": "paymentDate", "displayName": "Processed Date", "sortType": SortType.date },
		{"propName": "totalPaid", "displayName": "Total Paid", "sortType": SortType.numeric, "alignment": Alignment.right }//,
		//{"propName": undefined, "displayName": "", "sortType": SortType.none }
	];

	private async loadFilteredBills(print: boolean = false): Promise<void> {
		try {

			const filter: InvoiceFilter = {
				"type" : this._billFilter,
				"reimbursements": this.reimbursementSearchType
			};

			let fromDate = this._fromDate ? moment(this._fromDate, "DDMMYYYY").format('YYYY-MM-DD') : undefined;
			let toDate = this._toDate ? moment(this._toDate, "DDMMYYYY").format('YYYY-MM-DD') : undefined;
			if ( this._billFilter == 'custom' && (!fromDate || !toDate) ) {
				return Promise.reject("Both from and to dates must be entered");
			} else {
				// We have valid filters and can trigger call
				if (this._billFilter == 'custom') {
					filter.from = fromDate;
					filter.to = toDate;
				}

				const response = await this.providerService.loadFilteredBills(this._provider.id, this.clientId, filter, print);
				if (print) {
					this.printReport(response.content);
					OverlayService.hide();
				}
				else {
					this._filteredBills = response.invoices;
					this.filteredBillsTable.sourceData = StaticDataSource.from(this._filteredBills);
				}
			}
		}
		catch (e) {
			console.log(e);
			return Promise.resolve();
		}
	}

	private async printReport(content: string) {
		const blob = await this.dataURItoBlob(content);
		const blobUrl = URL.createObjectURL(blob);
		window.open(blobUrl, "_blank");
	}

	private async dataURItoBlob(dataUrl: string): Promise<Blob> {
		const result = await fetch(dataUrl);
		return result.blob();
	}

	public filteredBillsTable: PaginatedTable<InvoiceHeader> = new PaginatedTable('bills', this.tableHeadingsBills);

	constructor(private providerService: ProviderService) {
		super();
	}

	public get showDates(): boolean {
		return this._billFilter === 'custom';
	}

	public async fetchInvoices(print: boolean = false): Promise<void> {
		OverlayService.show(`Getting Invoices${Utils.ellipsis}`);

		try {
			await this.loadFilteredBills(print);
			OverlayService.hide();
		}
		catch (e) {
			console.log(e);
			OverlayService.hide();
			OverlayService.showError("Error", e);
		}
	}

	@Input()
	public set provider(value: Provider) {
		if (this._provider !== value) {
			this._provider = value;
			this._filteredBills = [];
			this.filteredBillsTable.sourceData = StaticDataSource.from(this._filteredBills);
			this.loadFilteredBills(false);
		}
	}

	public clientName(client: UserAccount): string {
		return `${client.lastName}, ${client.firstName}`;
	}

	@Input()
	public set clients(value: UserAccount[]) {
		this._linkedClients = value.sort( (a, b) => {
			return this.clientName(a).localeCompare(this.clientName(b));
		});
	}

	public get clients(): UserAccount[] {
		return this._linkedClients;
	}

	public openBill(event: MouseEvent, invoiceId: string) {
		const path = `/client/invoice/${invoiceId}`;

		if (event.ctrlKey) {
			const url = this.router.serializeUrl(
				this.router.createUrlTree([path])
			);

			window.open(url, '_blank');
		}
		else {
			this.router.navigate([path]);
		}
	}
}
