import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AttachmentType } from "@classes/attachmentType";

@Component({
	"selector": "document-filter",
	"styleUrls": ["./documentFilter.component.scss"],
	"templateUrl": "./documentFilter.component.html"
})
export class DocumentFilterComponent {

	@Input()
	public filter: Set<AttachmentType>;

	@Output()
	public changed: EventEmitter<void> = new EventEmitter<void>();

	public allAttachmentTypes: AttachmentType[] = AttachmentType.allValues;
	public attachmentTypeName: (AttachmentType) => string = AttachmentType.toString;

	public isSelected(value: AttachmentType) {
		return this.filter ? this.filter.has(value) : false;
	}

	public toggleAll() {
		this.allAttachmentTypes.forEach( attachmentType => {

			const method = this.filter.has(attachmentType) ? this.filter.delete : this.filter.add;
			method.call(this.filter, attachmentType);

		});

		this.changed.next();
	}

	public toggleFilter(value: AttachmentType) {
		if (!this.filter) {
			return;
		}

		const method = this.filter.has(value) ? this.filter.delete : this.filter.add;
		method.call(this.filter, value);
		this.changed.next();
	}
}
