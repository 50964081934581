export enum ContactPreference {
	email, phone, sms, post
}

export namespace ContactPreference {

	export function allValues(): ContactPreference[] {
		return [ContactPreference.email, ContactPreference.phone, ContactPreference.sms, ContactPreference.post];
	}

	export function parse(src: string): ContactPreference {
		switch (src) {
			case 'email': return ContactPreference.email;
			case 'phone': return ContactPreference.phone;
			case 'sms': return ContactPreference.sms;
			case 'post': return ContactPreference.post;
			default: return undefined;
		}
	}

	export function toString(src: ContactPreference): string {
		switch (src) {
			case ContactPreference.email: return 'Email';
			case ContactPreference.phone: return 'Phone';
			case ContactPreference.sms: return 'SMS';
			case ContactPreference.post: return 'Post';
			default: return undefined;
		}
	}

	export function toJSON(src: ContactPreference): string {
		switch (src) {
			case ContactPreference.email: return 'email';
			case ContactPreference.phone: return 'phone';
			case ContactPreference.sms: return 'sms';
			case ContactPreference.post: return 'post';
			default: return undefined;
		}
	}
}
