import { Injectable } from '@angular/core';
import { RestService, API } from './rest.service';
import { DateUtils } from '@classes/utils';
import { User } from '@classes/user';
import { Address, AddressClass } from '@classes/address'

export enum SearchResultType {
	client, support_coordinator, user, provider, bill, note, admin
}

export class SearchResultUtils {
	private static names: Map<SearchResultType, string> = new Map<SearchResultType, string>([
		[SearchResultType.client, "Client"],
		[SearchResultType.user, "Nominee / Authorised Representative"],
		[SearchResultType.support_coordinator, "Support Coordinator"],
		[SearchResultType.provider, "Provider"],
		[SearchResultType.bill, "Bill"],
		[SearchResultType.note, "File Note"],
		[SearchResultType.admin, "Admin"]
	]);

	private static values: Map<number, SearchResultType> = new Map<number, SearchResultType>([
		[0, SearchResultType.client],
		[1, SearchResultType.support_coordinator],
		[2, SearchResultType.user],
		[3, SearchResultType.provider],
		[4, SearchResultType.bill],
		[5, SearchResultType.note],
		[6, SearchResultType.admin]
	]);

	public static toString(value: SearchResultType): string {
		return SearchResultUtils.names.get(value);
	}

	public static parse(src: any): SearchResult {
		let result: SearchResult = {
			"id": src.id,
			"name": src.name,
			"type": SearchResultUtils.values.get(src.type)
		};

		if(src.address !== null) {
			result.address = src.address; //pull out the state from the address object and set it as state
		} else {
			result.address = AddressClass.parse(null,null,null,null,null);
		}

		switch (result.type) {
			case SearchResultType.client:
				result.email = src.email;
				result.phone = src.phone;
				result.ndisNumber = src.ndisNumber;
				break;

			case SearchResultType.support_coordinator:
			case SearchResultType.admin:
				result.email = src.email;
				result.phone = src.phone;
				break;

			case SearchResultType.user:
				result.email = src.email;
				result.phone = src.phone;
				break;

			case SearchResultType.provider:
				result.abn = src.abn;
				result.email = src.email;
				result.phone = src.phone;
				break;

			case SearchResultType.bill:
				result.clientName = src.clientName;
				result.providerName = src.providerName;
				result.date = DateUtils.parse(src.date, null);
				result.total = src.total;
				break;

			default:
				break;
		}

		return result;
	}
}

export interface SearchResult {
	id: string;
	name: string;
	type: SearchResultType;
	email?: string;
	phone?: string;
	address?: Address;
	ndisNumber?: string;
	abn?: string;
	clientName?: string;
	providerName?: string;
	date?: Date;
	total?: number;
}

@Injectable({ "providedIn": "root" })
export class SearchService {

	constructor(private restService: RestService) {}

	public search(searchPhrase: string): Promise<SearchResult[]> {
		const postData = {
			"text": searchPhrase
		};

		return this.restService.post(API.admin, "search", postData).then( response => {
			return Promise.resolve(response.searchResults.map(SearchResultUtils.parse));
		});
	}

	public clientSearch(searchPhrase: string): Promise<User[]> {
		return this.restService.get(API.admin, `search/client?q=${encodeURIComponent(searchPhrase)}`).then( response => {
			return Promise.resolve(response.map(User.parse));
		});
	}

	public userSearch(searchPhrase: string): Promise<User[]> {
		return this.restService.get(API.admin, `search/user?q=${encodeURIComponent(searchPhrase)}`).then( response => {
			return Promise.resolve(response.map(User.parse));
		});
	}

}
