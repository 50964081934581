import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@services/user.service';
import { Auth } from 'aws-amplify';
import { DataExchangeService } from '@services/dataexchange.service';
import { OverlayService } from '@services/overlay.service';

enum SignupType {
	Email,
	Mobile
}

interface ForgotPasswordModel {
	email: string
}

@Component({
	"templateUrl": "./forgotpassword.component.html",
	"styleUrls": ["./forgotpassword.component.scss"]
})
export class ForgotPasswordComponent implements OnInit {

	constructor(private userService: UserService, private router: Router) {}

	model: ForgotPasswordModel = {
		"email": undefined
	}

	ngOnInit() {
		if (this.userService.isLoggedIn()) {
			this.router.navigate(["/dashboard"]);
		}
	}

	private resetPasswordCodeSent(data: any): Promise<any> {
		const username: string = this.model.email;
		DataExchangeService.set({
			"username": username,
			"method": data.DeliveryMedium
		}, "password.reset");

		this.router.navigate(["/password/reset"]);
		OverlayService.hide();
		return Promise.resolve();
	}

	doReset() {
		OverlayService.show();
		const username: string = this.model.email;
		Auth.forgotPassword(username).then( data => {

			return this.resetPasswordCodeSent(data);

		}).catch( err => {

			OverlayService.hide();
			if (err.code === 'UserNotFoundException') {
				OverlayService.showError("Error", `No matching account found for email ${this.model.email}`);
			}
			else {
				OverlayService.showError("Error", "Unable to reset password");
				console.log(err);
			}

		});
	}
}
