import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrivateComponent } from "@classes/private.component";
import { MenuBuilder } from "@services/navmenu.service";
import { User, UserType } from "@classes/user";
import { SupportCategory } from "@classes/supports";
import { Plan, PlanStatus, PlanSupportCategory } from "@classes/plans";
import { PlanService } from "@services/plan.service";
import { ProviderSpend } from "@classes/providerSpend";
import { PlanBudget } from "@classes/budget";
import { SupportsService } from "@services/supports.service";


@Component({
	"styleUrls": ["./providerSpend.component.scss"],
	"templateUrl": "./providerSpend.component.html"
})
export class ProviderSpendComponent extends PrivateComponent implements OnInit {

	private _dataLoaded: boolean = false;
	private _showReimbursements: boolean = true;
	private _clientId: string|undefined = undefined;
	private _client: User = undefined;
	private _supportCategories: SupportCategory[] = [];
	private _plans: Plan[] = [];
	private _providerSpend: ProviderSpend[] = [];
	private _spendByProvider: any;

	get isAdmin(): boolean {
		return this.user.accountType === UserType.Admin;
	}

	private async loadSupportCategories(): Promise<void> {
		this._supportCategories = await this.supportsService.getSupportCategories();
	}

	private async loadPlans(userId: string): Promise<void> {
		this._plans = await this.planService.listPlans(userId);
	}

	private async loadProviderSpend() {
		if (this.plan === undefined) {
			return;
		}

		try {
			this._providerSpend = await this.planService.getProviderSpend(this.plan.id, this._showReimbursements);
			let data = this._providerSpend;

			const res = data.reduce((acc, curr) => {
				if ( !acc[curr.providerId] ) {
					acc[curr.providerId] = { 
						spends: [],
						providerId: curr.providerId,
						providerName: curr.providerName,
						providerABN: curr.providerABN,
						categories: [],
						totalSpend: 0,
						numInvoices: 0,
					};
				}
				acc[curr.providerId].spends.push(curr);
				acc[curr.providerId].categories.push(curr.supportCategoryNumber);
				acc[curr.providerId].totalSpend += curr.totalSpend;
				acc[curr.providerId].numInvoices += curr.numInvoices;
				return acc;
			},{});
			this._spendByProvider = Object.values(res);
		}
		catch (e) {
			console.log(e);
		}
	}

	private async loadClient(clientId: string) {
		this._client = await this.userService.loadUser(clientId);
	}

	private async loadData() {
		this._dataLoaded = false;
		try {
			await Promise.all([
				this.loadPlans(this._clientId),
				this.loadClient(this._clientId)
			]);

			if(this.isAdmin){
				await this.loadSupportCategories();
			}

			this.plan = this.currentPlan || this.plans.length ? this.plans[this.plans.length - 1] : undefined;
			if (this.plan !== undefined) {
				await this.loadProviderSpend();
			}
		}
		finally {
			this._dataLoaded = true;
		}
	}

	constructor(private route: ActivatedRoute, private planService: PlanService, private supportsService: SupportsService) {
		super();
	}

	private buildMenu() {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.done();
	}

	ngOnInit() {
		super.ngOnInit();

		this.route.params.subscribe( params => {

			if (this.user) {
				this._clientId = params.clientId;
				this.loadData();
			}
		});

		this.buildMenu();
	}

	public get plans(): Plan[] {
		return this._plans.filter( plan => plan.status !== PlanStatus.proposed );
	}

	public get spendByProvider(): any {
		return this._spendByProvider;
	}

	public planSelected() {
		this.loadProviderSpend();
	}

	private get currentPlan(): Plan {
		return this._plans.find( plan => plan.status === PlanStatus.current );
	}

	public readonly planStatus: (plan: PlanStatus) => string = PlanStatus.toString;
	public plan: Plan|undefined = undefined;

	public get dataLoaded(): boolean {
		return this._dataLoaded;
	}

	public get supportCategories(): PlanSupportCategory[] {
		return this.plan.supportCategories;
	}

	public supportCategory(categoryId: number): any {
		if(this.isAdmin) {
			return this._supportCategories.find(cat => cat.id === categoryId);
		} 
		return this.plan.supportCategories.find( item => item.supportCategory.id === categoryId );
	}

	public spendCategory(categoryId: number): ProviderSpend[] {
		return this._providerSpend.filter( item => item.supportCategoryNumber === categoryId );
	}

	public paidAmount(budget: PlanBudget): number {
		return budget.paid + budget.unknown;
	}

	public remaining(budget: PlanBudget): number {
		return budget.total - (budget.paid + budget.unknown);
	}

	public get clientName(): string {
		return this._client && `${this._client.firstName} ${this._client.lastName}` || "";
	}

	public get clientId(): string {
		return this._clientId || "";
	}

	public get currentPlanId(): string {
		return this.plan.id || "";
	}

	public get ndisNumber(): string {
		return this._client && this._client.ndisNumber || "";
	}

	public get showReimbursements(): boolean {
		return this._showReimbursements;
	}

	public onShowReimbursementsChange(){
		this._showReimbursements = !this._showReimbursements;
		this.loadProviderSpend();
	}

	public showProviderInvoices(event: MouseEvent, providerId: string) {
		event.cancelBubble = true;
		this.router.navigate([`/invoices/${this._client.id}/${providerId}/${this.currentPlan.id}`]);
	}
}
