interface PromiseResult {
	"resolve": (any) => any;
	"reject": () => any;
}

interface StorageAPI {
	set: (values: any | any[]) => Promise<any>;
	get: (key: string) => Promise<any>;
	remove: (keyOrRange: string|IDBKeyRange) => Promise<void>;
	clear: () => Promise<void>;
	query: (indexName: string, keyRange: IDBKeyRange) => Promise<any[]>;
}

interface KeyStorageAPI {
	set: (keys: string | string[], values: any | any[]) => Promise<any>;
	get: (key: string) => Promise<any>;
	remove: (keyOrRange: string|IDBKeyRange) => Promise<void>;
	clear: () => Promise<void>;
}

/**
* IndexedDB Storage wrapper
*/
export class DBService {
	private static db: IDBDatabase;
	private static connectionRequest: IDBOpenDBRequest;

	private static readonly dbName = 'ndsp';
	private static readonly dbVersion = 3;

	private static connectQueue: PromiseResult[] = [];

	private static init(): Promise<IDBDatabase> {

		// Do not proceed if indexedDB not supported
		if (!('indexedDB' in window)) {
			return Promise.reject("Your browser doesn't support indexedDB");
		}

		if (DBService.db) {
			return Promise.resolve(DBService.db);
		}

		return new Promise<IDBDatabase>((resolve, reject) => {

			DBService.connectQueue.push({"resolve": resolve, "reject": reject});
			if (DBService.connectQueue.length > 1) {
				return;
			}

			DBService.connectionRequest = indexedDB.open(DBService.dbName, DBService.dbVersion);

			DBService.connectionRequest.onupgradeneeded = (event: any) => {
				const db = event.target.result;

				["nonvolatile", "supports"].forEach( objectStoreName => {
					try {
						db.deleteObjectStore(objectStoreName);
					}
					catch (e) {
						// Ignore - probably didn't exist in the first place
					}
				});

				const supports = db.createObjectStore("supports", {"keyPath": "id"});
				supports.createIndex("id", "id", { "unique": true });
				supports.createIndex("support", ["region", "dateFrom", "supportItemNumber", "supportCategoryId"], { "unique": true });
				supports.createIndex("supportNonUnique", ["region", "dateFrom", "supportItemNumber"]);
				supports.createIndex("plan", ["region", "dateFrom"], { "unique": false });

				db.createObjectStore("nonvolatile");
			};

			DBService.connectionRequest.onsuccess = () => {
				DBService.db = DBService.connectionRequest.result;
				DBService.connectionRequest = undefined;
				while (DBService.connectQueue.length) {
					const promise = DBService.connectQueue.shift();
					promise.resolve(DBService.db);
				}
			}
		});
	}

	private static clear(objectStore: string): Promise<void> {
		return new Promise((resolve, reject) => {
			DBService
				.init()
				.then( db => {
					const transaction = db.transaction(objectStore, "readwrite");
					const entitiesStore = transaction.objectStore(objectStore);
					transaction.oncomplete = () => {
						resolve();
					};

					entitiesStore.clear();

				}, (e) => {
					console.log(e);
					resolve();
				});
		});
	}

	private static keyset(objectStore: string, keys: string | string[], values: any | any[]): Promise<any> {

		const _keys = Array.isArray(keys) ? [...keys] : [keys];
		const _values = Array.isArray(keys) ? [...values] : [values];
		const singleItem = _keys.length === 1;

		if (_keys.length !== _values.length) {
			return Promise.reject("Number of keys does not match number of values");
		}

		return new Promise((resolve, reject) => {
			const completed = () => { resolve(values); };

			DBService
				.init()
				.then( db => {
					const transaction = db.transaction(objectStore, "readwrite");
					const store = transaction.objectStore(objectStore);

					if (singleItem) {
						store.put( _values.pop(), _keys.pop()).onsuccess = completed;
					}
					else {
						transaction.oncomplete = completed
						while (_values.length > 0) {
							store.put( _values.pop(), _keys.pop());
						}
					}

				}, (e) => { console.log(e); resolve(values); } );
		});
	}

	private static set(objectStore: string, values: any | any[]): Promise<any> {

		const _values = Array.isArray(values) ? [...values] : [values];
		const numItems = _values.length;
		const singleItem = numItems === 1;

		return new Promise((resolve, reject) => {
			const completed = () => { resolve(values); };

			DBService
				.init()
				.then( db => {
					const transaction = db.transaction(objectStore, "readwrite");
					const store = transaction.objectStore(objectStore);

					if (singleItem) {
						store.put( _values.pop() ).onsuccess = completed;
					}
					else {
						transaction.oncomplete = completed;

						while (_values.length > 0) {
							store.put( _values.pop() );
						}
					}

				}, (e) => { console.log(e); resolve(values); } );
		});
	}

	private static query(objectStore: string, indexName: string, keyRange: IDBKeyRange): Promise<any[]> {

		return new Promise((resolve, reject) => {

			let result: any[] = [];

			DBService
				.init()
				.then( db => {
					const transaction = db.transaction(objectStore, "readonly");
					const store = transaction.objectStore(objectStore);

					const request = store.index(indexName).getAll(keyRange);
					request.onsuccess = () => {
						resolve(request.result);
					};

				}, (e) => { console.log(e); resolve(undefined); } );
		});
	}

	private static remove(objectStore: string, keyOrRange: string|IDBKeyRange): Promise<void> {
		return new Promise((resolve, reject) => {
			DBService
				.init()
				.then( db => {
					const transaction = db.transaction(objectStore, "readwrite");
					const entitiesStore = transaction.objectStore(objectStore);
					const request = entitiesStore.delete(keyOrRange);
					request.onsuccess = () => {
						resolve();
					}
				}, () => resolve() );
		});
	}

	private static get(objectStore: string, key: string): Promise<any> {
		return new Promise((resolve, reject) => {
			DBService
				.init()
				.then( db => {
					const transaction = db.transaction(objectStore, "readonly");
					const entitiesStore = transaction.objectStore(objectStore);
					const request = entitiesStore.get(key);
					request.onsuccess = () => {
						resolve(request.result);
					}
				}, () => resolve(undefined) );
		});
	}

	public static get nonVolatile(): KeyStorageAPI {
		const objectStoreName = 'nonvolatile';
		return {
			"get": DBService.get.bind(undefined, objectStoreName),
			"set": DBService.keyset.bind(undefined, objectStoreName),
			"remove": DBService.remove.bind(undefined, objectStoreName),
			"clear": DBService.clear.bind(undefined, objectStoreName)
		};
	}

	public static get supports(): StorageAPI {
		const objectStoreName = 'supports';
		return {
			"get": DBService.get.bind(undefined, objectStoreName),
			"set": DBService.set.bind(undefined, objectStoreName),
			"remove": DBService.remove.bind(undefined, objectStoreName),
			"clear": DBService.clear.bind(undefined, objectStoreName),
			"query": DBService.query.bind(undefined, objectStoreName)
		};
	}
}
