import { Injectable } from "@angular/core";
import { RestService, API } from "./rest.service";

@Injectable({ "providedIn": "root" })
export class SuperUserService {

	constructor(private restService: RestService) {}

	public loadPermissions(roleId: string): Promise<string[]> {
		return this.restService.get(API.su, `role/${roleId}/permissions`).then( data => {
			return data.reduce( (acc, cur) => {
				acc.push(cur.permissions);
				return acc;
			}, []);
		});
	}

	public savePermissions(roleId, permissions): Promise<void> {
		const postData = {
			"permissions": permissions
		};

		return this.restService.put(API.su, `role/${roleId}/permissions`, postData).then( data => {
			if (data.success) {
				return Promise.resolve();
			}

			return Promise.reject("Failed to save permissions");
		});
	}
}
