import { Injectable } from '@angular/core';
import { RestService, API } from './rest.service';
import { FileDownloader } from '@classes/files';
import { TrafficLightData, TrafficLightUtils } from '@classes/trafficlights';
import { Cache } from "@classes/cache";
import { DateUtils } from "@classes/utils";
import { CacheSignalService } from "@services/cachesignal.service";
import { User } from "@classes/user";

interface ClientRef {
	id: string;
	clientName: string;
}

export interface ExpiringPlan extends ClientRef {
	endDate: Date;
}

export interface ClientWithoutPlan extends ClientRef {
	createdAt: Date;
	planEnded: Date;
}

export interface NewClient extends ClientRef {
	createdBy: string;
	createdAt: Date;
	startDate: Date;
}

@Injectable({ providedIn: 'root' })
export class AdminService implements FileDownloader {

	private static trafficeLightCache: Cache<TrafficLightData> = new Cache<TrafficLightData>();
	private static plansExipiringSoonCache: Cache<ExpiringPlan> = new Cache<ExpiringPlan>();
	private static clientsWithoutPlansCache: Cache<ClientWithoutPlan> = new Cache<ClientWithoutPlan>();
	private static newClientCache: Cache<NewClient> = new Cache<NewClient>();

	constructor(private restService: RestService, private signalService: CacheSignalService) {}

	createUser(userData: any): Promise<User> {
		return this.restService.post(API.admin, "user", userData).then( result => {
			this.signalService.signal("User Created");
			return new User(result);
		});
	}

	clientsWithoutPlans(): Promise<ClientWithoutPlan[]> {
		if (AdminService.clientsWithoutPlansCache.valid) {
			return Promise.resolve(AdminService.clientsWithoutPlansCache.items);
		}

		return this.restService.get(API.dashboards, "admin/clientswithoutplans").then( data => {
			AdminService.clientsWithoutPlansCache.items = data.map( item => {
				return {
					"id": item.id,
					"clientName": item.clientName,
					"createdAt": DateUtils.parse(item.createdAt, DateUtils.ISO8601msFormat),
					"planEnded": DateUtils.parse(item.planEnded, DateUtils.ISO8601msFormat)
				};
			});

			return Promise.resolve(AdminService.clientsWithoutPlansCache.items);
		});
	}

	plansExipiringSoon(): Promise<ExpiringPlan[]> {
		if (AdminService.plansExipiringSoonCache.valid) {
			return Promise.resolve(AdminService.plansExipiringSoonCache.items);
		}

		return this.restService.get(API.dashboards, "admin/expiringsoon").then( data => {
			AdminService.plansExipiringSoonCache.items = data.map( item => {
				return {
					"id": item.id,
					"clientName": item.clientName,
					"endDate": DateUtils.parse(item.endDate, DateUtils.ISO8601msFormat)
				};
			});

			return Promise.resolve(AdminService.plansExipiringSoonCache.items);
		});
	}

	newClients(): Promise<NewClient[]> {
		if (AdminService.newClientCache.valid) {
			return Promise.resolve(AdminService.newClientCache.items);
		}

		return this.restService.get(API.dashboards, "admin/newclients").then( data => {
			AdminService.newClientCache.items = data.clients.map( item => {
				return {
					"id": item.id,
					"clientName": item.clientName,
					"createdAt": DateUtils.parse(item.createdAt, DateUtils.ISO8601msFormat),
					"createdBy": item.createdBy
				};
			});

			return Promise.resolve(AdminService.newClientCache.items);
		});
	}

	clientsWithTrafficLights(): Promise<TrafficLightData[]> {
		if (AdminService.trafficeLightCache.valid) {
			return Promise.resolve( AdminService.trafficeLightCache.items );
		}

		return this.restService.get(API.dashboards, "admin/clientswithtrafficlights").then( response => {
			AdminService.trafficeLightCache.items = response.map( TrafficLightUtils.parse);
			return Promise.resolve( AdminService.trafficeLightCache.items );
		});
	}

	public loadAttachment(attachmentId: string): Promise<any> {
		return this.restService.get(API.attachments, `${attachmentId}`).then( response => {
			return response.content;
		});
	}

	public deleteCredentials(userId: string): Promise<boolean> {
		return this.restService.delete(API.admin, `login/${userId}`).then( response => {
			return Promise.resolve( response.success === true );
		});
	}
}
