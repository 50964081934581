import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrivateComponent } from "@classes/private.component";
import { UserAccount, User, UserType } from "@classes/user";
import { DialogButton, OverlayService } from '@services/overlay.service';
import { Table, TableColumnHeading, SortType, StaticDataSource } from "@classes/tables";
import { MenuBuilder } from "@services/navmenu.service";
import { FileNotesService } from "@services/filenotes.service";

interface CheckedUser extends UserAccount {
	checked: boolean;
}

@Component({
	"styleUrls": ["./linkedaccounts.component.scss"],
	"templateUrl": "./linkedaccounts.component.html"
})
export class LinkedSupportCoordinatorsComponent extends PrivateComponent implements OnInit {

	private _clientId: string = undefined;
	private _client: User = undefined;
	private _supportCoordinators: CheckedUser[] = [];

	private _dataLoaded: boolean = false;

	public showSupportCoordinatorDialog: boolean = false;

	public newSupportCoordinators: UserAccount[] = [];

	private readonly tableHeadings: TableColumnHeading[] = [
		{"propName": "name", "displayName": "Support Coordinator", "sortType": SortType.name },
		{"propName": undefined, "displayName": "", "sortType": SortType.none}
	];

	table: Table<UserAccount> = new Table('linkedSupportCoordinators', this.tableHeadings);

	get unlinkedSupportCoordinators(): CheckedUser[] {
		if (!this._dataLoaded || this._supportCoordinators.length < 1) {
			return [];
		}

		const linkedIds = this.table.displayData.map( item => item.id );
		return this._supportCoordinators.filter( item => !linkedIds.includes(item.id) );
	}

	get dataLoaded(): boolean {
		return this._dataLoaded;
	}

	get client(): User {
		return this._client;
	}

	get clientId(): string {
		return this._clientId;
	}

	get clientName(): string {
		return this._client ? `${this._client.firstName} ${this._client.lastName}` : '';
	}

	get supportCoordinators(): UserAccount[] {
		return this._supportCoordinators;
	}

	constructor(private route: ActivatedRoute, private fileNotesService: FileNotesService) {
		super();
		this.allowedUserTypes = [UserType.Admin];
	}

	private buildMenu() {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.addHandler("plus", "Add New", () => { this.addNew(); });
		menuBuilder.done();
	}

	ngOnInit() {
		super.ngOnInit();

		OverlayService.show();

		this.route.params.subscribe( params => {

			this._clientId = params.clientId;

			let promises = [];

			promises.push( this.userService.loadUser(params.clientId).then( user => {
				this._client = user;
			}) );

			promises.push( this.loadLinkedSupportCoordinators() );
			promises.push( this.loadAllSupportCoordinators() );

			Promise.all(promises).then( () => {
				OverlayService.hide();
			});

		});

		this.buildMenu();
	}

	private loadLinkedSupportCoordinators(): Promise<any> {
		return this.userService.getLinkedSupportCoordinators(this._clientId).then( supportCoordinators => {
			this.table.sourceData = StaticDataSource.from(supportCoordinators);
			this._dataLoaded = true;
		});
	}

	private loadAllSupportCoordinators(): Promise<any> {
		return this.userService.getAllSupportCoordinators().then( supportCoordinators => {
			this._supportCoordinators = supportCoordinators.map( item => {
				return item['checked'] = false, <CheckedUser>item;
			});
		});
	}

	private closeDialog(): void {
		OverlayService.hide();
	}

	private unlinkSupportCoordinator(supportCoordinatorIdentityId: string): void {

		// Hide the dialog
		OverlayService.hide();

		// Show a "loading..." overlay
		OverlayService.show();

		this.userService.unlinkSupportCoordinator(supportCoordinatorIdentityId, this._clientId).then( () => {

			this.fileNotesService.invalidateCacheForUser(this._clientId);
			return this.loadLinkedSupportCoordinators();

		}).then( () => {

			OverlayService.hide();

		}).catch( e => {

			OverlayService.hide();
			console.log(e);

		});
	}

	removeSupportCoordinator(identityId) {
		const dialogButtons: DialogButton[] = [{
			"text": "Cancel",
			"handler": this.closeDialog
		}, {
			"text": "Remove",
			"handler": () => {
				this.unlinkSupportCoordinator(identityId);
			}
		}];

		OverlayService.showDialog("Confirm", "Are you sure you want to remove this support coordinator?", dialogButtons);
	}

	addNew(): void {
		this._supportCoordinators = this._supportCoordinators.map( item => {
			return item.checked = false, item;
		});
		this.showSupportCoordinatorDialog = true;
	}

	closeSupportCoordinatorDialog(): void {
		this.showSupportCoordinatorDialog = false;
	}

	addSelectedSupportCoordinators(): void {

		// Hide the dialog box
		this.showSupportCoordinatorDialog = false;


		// Find the selected support coordinators
		const selectedSupportCoordinators = this._supportCoordinators.filter( item => item.checked ).map( item => item.id );
		if (selectedSupportCoordinators.length) {

			// Show the "Loading..." overlay modal
			OverlayService.show();

			// Send the request
			this.userService.linkSupportCoordinators(this._clientId, selectedSupportCoordinators).then( () => {

				this._dataLoaded = false;
				this.fileNotesService.invalidateCacheForUser(this._clientId);
				return this.loadLinkedSupportCoordinators();

			}).then( () => {

				OverlayService.hide();

			}).catch( e => {

				OverlayService.hide();

			});
		}

	}


}
