import { Injectable } from '@angular/core';
import { RestService, API } from './rest.service';
import { Plan } from "@classes/plans";
import { Invoice, InvoiceHeader, InvoiceStatusUtils, InvoiceUtils, SupportDataType } from '@classes/invoices';
import { FileDownloader } from '@classes/files';
import { DateUtils } from '@classes/utils';
import { CacheSignalService } from "@services/cachesignal.service";
import { FileManager } from "@classes/filemanager";
import { AttachmentTargetType, AttachmentTargetUtils  } from '@classes/attachments';
import { PaginatedData } from "@classes/paginatedData";
import { AttachmentsService } from "@services/attachments.service";
import { ReimbursementRecipient } from "@classes/reimbursementRecipient";

export enum DeletedInvoiceSearch {
	exclude = 0, include = 1, only = 2
}

export enum ReimbursementsSearch {
	all = 0, exclude = 1, only = 2
}

@Injectable({ providedIn: 'root' })
export class InvoiceService implements FileDownloader {
	private static readonly unknownProviders: string[] = ['ca90a10e-b1e1-4019-89a8-1c2a02b6d3b5', 'f338e5ca-409c-4cc3-a1a1-ef5812e6e85d'];

	constructor(private restService: RestService, private signalService: CacheSignalService, private attachmentsService: AttachmentsService) {}

	private invoiceHeaderMapper(invoiceData: any): InvoiceHeader {
		return {
			"clientId": invoiceData.clientId,
			"adjustment": invoiceData.adjustment,
			"clientName": invoiceData.clientName,
			"comment": invoiceData.comment,
			"gst": invoiceData.gst,
			"id": invoiceData.id,
			"date": DateUtils.parse(invoiceData.invoiceDate, null),
			"paymentDate": DateUtils.parse(invoiceData.paymentDate, null),
			"invoiceNumber": invoiceData.invoiceNumber,
			"providerId": invoiceData.providerId,
			"providerName": InvoiceService.unknownProviders.includes(invoiceData.providerId) ? '' : invoiceData.providerName,
			"status": InvoiceStatusUtils.parse(invoiceData.status),
			"total": invoiceData.total,
			"reimbursement": invoiceData.reimbursement,
			"reimbursementRecipient": invoiceData.reimbursementRecipient,
			"payee": invoiceData.payee,
			"createdBy": invoiceData.createdBy,
			"createdByName": invoiceData.createdByName,
			"numLines": invoiceData.numLines,
			"created": DateUtils.parse(invoiceData.createdDate, null),
			"ticketNumber": invoiceData.ticketNumber,
			"deleted": !!invoiceData.deleted,
			"investigationTypeId": invoiceData.investigationTypeId,
			"investigationTypeName": invoiceData.investigationTypeName,
			"investigationDate": DateUtils.parse(invoiceData.investigationDate, null),
			"investigationUserId" : invoiceData.investigationUserId
		};
	}

	//getInvoiceSupportData(): Promise<SupportDataTypeArray> {
	//	// Returns data specific to Invoices - eg: investigationType DDL
	//	return this.restService.get(API.invoices, 'data').then( data => {
	//		return Promise.resolve(data.supportData);
	//	});
	//}

	getInvoiceSupportData(callType: string = undefined): Promise<SupportDataType[]> {

		/*
			"p" - honour permissions? true or false
			"l" - limit to sortOrder <= this value
		*/
		let postData = {
			"p": true,
			"l": 99999
		};
		if (!!callType) {
			if (callType == 'edit') {
				postData = {
					"p": false,
					"l": 999
				}
			}
		}

		return this.restService.post(API.invoices, "data", postData).then( result => {
			return Promise.resolve( result.supportData );
		});
	}

	getRecentInvoices(): Promise<InvoiceHeader[]> {
		return this.restService.get(API.invoices, 'recent').then( data => {
			return Promise.resolve(data.map( this.invoiceHeaderMapper ));
		});
	}

	getLockedInvoices(): Promise<InvoiceHeader[]> {
		return this.restService.get(API.invoices, 'locked').then( data => {
			return Promise.resolve(data.map( this.invoiceHeaderMapper ));
		});
	}

	getRecentClientInvoices(clientId: string): Promise<any> {
		return this.restService.get(API.invoices, `recent/${clientId}`).then( data => {

			const result = {
				"invoices": data.invoices.map( this.invoiceHeaderMapper ),
				"totalInvoices": data.totalInvoices,
				"totalPaidInvoices": data.totalPaidInvoices
			};

			return Promise.resolve(result);
		});
	}

	search(searchText: string, deletedInvoices: DeletedInvoiceSearch, reimbursements: ReimbursementsSearch, type: string = 'all', isAdmin: boolean = true): Promise<InvoiceHeader[]> {
		const pathPrefix = isAdmin ? 'admin/' : '';
		const deletedParam = `&d=${deletedInvoices}`;
		const reimbursementParam = `&r=${reimbursements}`;
		const typeParam = `&t=${type}`;
		return this.restService.get(API.invoices, `${pathPrefix}search?q=${searchText}${deletedParam}${reimbursementParam}${typeParam}`).then( data => {
			return Promise.resolve(data.map( this.invoiceHeaderMapper ));
		});
	}

	loadInvoice(invoiceId: string): Promise<Invoice> {
		return this.restService.get(API.invoices, `invoice/${invoiceId}`).then( result => {
			return InvoiceUtils.jsonToInvoice(result);
		});
	}

	deleteInvoice(invoice: Invoice): Promise<void> {
		if (!invoice.id) {
			return Promise.resolve();
		}

		return this.restService.delete(API.invoices, `${invoice.id}`).then( whatever => {
			this.signalService.signal("Invoice Deleted", invoice);
			return Promise.resolve();
		});
	}

	patchInvoice(invoiceId: string, clientId: string, providerId: string, invoiceData: any, fileManager: FileManager): Promise<any> {

		const patchData: any = {
			"invoiceData": Invoice.patchToJSON(invoiceData),
			"providerId": providerId,
			"clientId": clientId
		};

		let returnedInvoice;

		return this.restService.patch(API.invoices, `invoice/${invoiceId}`, patchData).then( response => {

			returnedInvoice = InvoiceUtils.jsonToInvoice(response.invoice);
			this.signalService.signal("Invoice Saved", returnedInvoice);
			return this.saveFiles(returnedInvoice, fileManager);

		}).then( () => {

			return Promise.resolve( returnedInvoice );

		});
	}

	allocateInvoice(invoiceId: string): Promise<void> {
		return this.restService.get(API.invoices, `allocate/${invoiceId}`).then( response => {
			//if (!!response.errorMessage) {
			//	return Promise.reject();
			//}
			return Promise.resolve();
		});
	}

	saveInvoice(invoice: Invoice, fileManager?: FileManager, startTime?: Date): Promise<any> {
		const postData: any = {
			"invoice": InvoiceUtils.invoiceToJson(invoice),
			"startTime": !!startTime ? DateUtils.toString(startTime, DateUtils.ISO8601Format) : undefined
		};

		let result = {
			"invoice": undefined,
			"plans": undefined
		};

		return this.restService.post(API.invoices, "invoice", postData).then( response => {

			result.invoice = InvoiceUtils.jsonToInvoice(response.invoice);
			result.plans = (response.plans || []).map( Plan.parse );

			this.signalService.signal("Invoice Saved", result.invoice);
			return this.saveFiles(result.invoice, fileManager);

		}).then( () => {

			return Promise.resolve( result );

		});
	}

	private removeFiles(deletedFiles: string[]): Promise<any> {
		if (!deletedFiles.length) {
			return Promise.resolve();
		}

		return Promise.all( deletedFiles.map( fileId => this.restService.delete(API.attachments, `${fileId}`) ) );
	}

	private saveFiles(invoice: Invoice, fileManager?: FileManager): Promise<any> {

		if (!fileManager) {
			return Promise.resolve();
		}

		const targets = [
			AttachmentTargetUtils.target(invoice.clientId, AttachmentTargetType.client),
			AttachmentTargetUtils.target(invoice.providerId, AttachmentTargetType.provider),
			AttachmentTargetUtils.target(invoice.id, AttachmentTargetType.invoice)
		];

		return this.attachmentsService.saveAttachments(targets, fileManager).then( () => {
			return Promise.resolve();
		})
/*
		const promises = files.map( file => {

			return file.content.then( content => {
				return {
					"file": {
						"name": file.name,
						"mimeType": file.mimeType,
						"content": content.replace(/^.*,/, ''), // strip out the "data:[<mediatype>][;base64]," header info
						"size": file.size,
						"md5": file.md5,
						"attachmentType": AttachmentType.toPostgresEnum(file.attachmentType)
					},
					"metadata": {
						"description": null,
						"targets": [{
							"id": invoice.id,
							"type": "invoice",
						}, {
							"id": invoice.clientId,
							"type": "client",
						}, {
							"id": invoice.providerId,
							"type": "provider",
						}]
					}
				};
			}).then( postData => {

				return this.restService.put(API.attachments, "", postData);

			}).then( () => {

				return Promise.resolve();
			});
		});

		return Promise.all(promises);
*/
	}

	public clientInvoices(clientId: string): Promise<InvoiceHeader[]> {
		return this.restService.get(API.invoices, `client/${clientId}`).then( result => {
			return result.map( this.invoiceHeaderMapper );
		});
	}

	public investigationBillsList(searchText?: string, categories?: string[], pageNum: number = 1, resultsPerPage: number = 25): Promise<PaginatedData<InvoiceHeader>> {
		const postData = {
			"q": searchText,
			"categories": categories,
			"pageNum": pageNum,
			"resultsPerPage": resultsPerPage
		};

		return this.restService.post(API.invoices, 'investigations', postData).then( result => {
			return {
				"page": result.page,
				"total": result.count,
				"resultsPerPage": result.resultsPerPage,
				"data": result.invoices.map( this.invoiceHeaderMapper )
			};
		});
	}

	public importedBillsList(searchText?: string): Promise<InvoiceHeader[]> {
		let path = 'imported';
		if (!!searchText && !!searchText.trim()) {
			path += `?q=${searchText.trim()}`;
		}
		return this.restService.get(API.invoices, path).then( result => {
			return result.map( this.invoiceHeaderMapper );
		});
	}

	public loadAttachment(attachmentId: string, clientId?: string): Promise<any> {
		const pathSeparator = "/";
		const path = Array.from(arguments).filter( item => !!item ).join(pathSeparator);
		return this.restService.get(API.attachments, path);
	}

	public billingAlertsData(): Promise<InvoiceHeader[]> {
		return this.restService.get(API.dashboards, 'alerts').then( result => {
			return result.map( this.invoiceHeaderMapper );
		});
	}

	public checkForDuplicates(invoice: InvoiceHeader, fileManager?: FileManager): Promise<any[]> {
		const checksums = fileManager && fileManager.attachedFiles.length > 0 ? fileManager.attachedFiles.map( file => file.md5 ) : [];
		const postData = {
			"invoiceId": invoice.id,
			"invoiceNumber": invoice.invoiceNumber,
			"providerId": invoice.providerId,
			"clientId": invoice.clientId,
			"total": invoice.total,
			"date": DateUtils.toString(invoice.date),
			"checksums": checksums
		};

		return this.restService.post(API.invoices, "duplicates", postData).then( result => {
			return Promise.resolve( result.isDuplicate );
		});
	}

	/**
	* Get a list of all linked users (and their bank accounts) so that a reimbursement recipient
	* can be selected in the invoice entry screen
	*/
	public async getReimbursementOptions(clientId: string): Promise<ReimbursementRecipient[]> {
		const response = await this.restService.get(API.invoices, `reimbursement/${clientId}`);
		if (Array.isArray(response)) {
			return response.map( ReimbursementRecipient.parse );
		}
		return undefined;
	}
}
