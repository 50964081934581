import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrivateComponent } from "@classes/private.component"
import { User, UserType } from "@classes/user"

@Component({
	"templateUrl": "./dashboard.component.html"
})
export class DashboardComponent extends PrivateComponent implements OnInit {

	private masqueradeAs: User = undefined;

	constructor(private route: ActivatedRoute) {
		super();
	}

	private get dashboardUser(): User {
		return this.masqueradeAs || this.user;
	}

	ngOnInit() {
		super.ngOnInit();

		this.route.params.subscribe( params => {
			const userId = params.userId;

			// If we're provided with a userId to masquerade as, load the user here
			if (!!userId && this.user) {

				this.userService.loadUser(userId).then( user => {
					this.masqueradeAs = user;
				});

			}

		});
	}

	userInfo(): string {
		return this.dashboardUser ? this.dashboardUser.firstName || "" : "";
	}

	public get isAdmin(): boolean {
		return this.dashboardUser ? this.dashboardUser.accountType === UserType.Admin : false;
	}

	public get isSupportCoordinator(): boolean {
		return this.dashboardUser ? this.dashboardUser.accountType === UserType.SupportCoordinator : false;
	}

	public get isAuthorisedRep(): boolean {
		return this.dashboardUser ? this.dashboardUser.accountType === UserType.User : false;
	}

	public get isParticipant(): boolean {
		return this.dashboardUser ? this.dashboardUser.accountType === UserType.Participant : false;
	}
}
