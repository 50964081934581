export class CacheRegistry {
	private static caches: Set<Cache<any>> = new Set<Cache<any>>();

	public static add(cache: Cache<any>): void {
		CacheRegistry.caches.add(cache);
	}

	public static remove(cache: Cache<any>): void {
		if (CacheRegistry.caches.has(cache)) {
			CacheRegistry.caches.delete(cache);
		}
	}

	public static clear(): void {
		CacheRegistry.caches.forEach( value => {
			value.invalidate();
		});
	}
}

/**
* Implements a cache of typed values (stored as an array), using generics.
* Manages cache aging.
*/
export class Cache<T> {

	/**
	* The array if items to cache
	* @private
	* @type {T[]}
	*/
	private _items: T[] = undefined;

	private timeoutHandle: any;

	/**
	* The Unix timestamp of the last time the items were stored
	* @private
	* @type {number}
	*/
	private _cacheTime: number = undefined;

	/**
	* The maximum age (in seconds) for the cached data to be considered valid
	* @private
	* @type {number}
	*/
	private readonly maxCacheAgeSeconds: number;

	/**
	* @param {maxAge} Max lifetime of cache before data is considered stale. Pass null to make cache live forever.
	* @constructor
	*/
	constructor(maxAge?: number) {
		const defaultMaxCacheAge = 300;
		this.maxCacheAgeSeconds = maxAge === null ? null : maxAge || defaultMaxCacheAge;
	}

	/**
	* Getter for private property that indicates whether the age of the cached data
	* has exceeded the specified maximum age.
	* @private
	* @type {boolean}
	*/
	private get cacheHasExpired(): boolean {
		if (this.maxCacheAgeSeconds === null) {
			return false;
		}

		const currentTime = Math.floor(new Date().valueOf() / 1000);
		return currentTime - this._cacheTime > this.maxCacheAgeSeconds;
	}

	/**
	* Getter for private property that indicates whether the cache has been populated with items.
	* @private
	* @type {boolean}
	*/
	private get hasCachedData(): boolean {
		return this._items !== undefined;
	}

	/**
	* Getter that indicates whether the cache should be (re)populated with data
	* @public
	* @type {boolean}
	*/
	get needsRefresh(): boolean {
		return !this.hasCachedData || this.cacheHasExpired;
	}

	/**
	* Opposite of "needsRefresh". Getter that indicates if we have good, fresh data.
	* @public
	* @type {boolean}
	*/
	get valid(): boolean {
		return this.hasCachedData && !this.cacheHasExpired;
	}

	/**
	* Getter that returns the cached items
	* @public
	* @type {T[]}
	*/
	get items(): T[] {

		// Don't return anything if we don't have data, or the cache has expired
		if (this.needsRefresh) {
			return undefined;
		}

		return this._items;
	}

	private clearTimeout(): void {
		if (this.timeoutHandle) {
			clearTimeout(this.timeoutHandle);
			this.timeoutHandle = undefined;
		}
	}

	/**
	* Setter for caching an array of values.
	* Sets the cacheTime when called to ensure that the cached data is invalidated appropriately.
	* @public
	* @param {T[]} value The array of items to cache
	*/
	set items(value: T[]) {
		this._cacheTime = Math.floor(new Date().valueOf() / 1000);
		this._items = value;

		if (this.maxCacheAgeSeconds !== null) {
			// After the max cache time has elapsed, delete the data.
			this.clearTimeout();
			this.timeoutHandle = setTimeout(() => { this.invalidate(); }, this.maxCacheAgeSeconds * 1000);
		}
		CacheRegistry.add(this);
	}

	/**
	* Removes the cached values, and unsets the cache's timestamp to ensure that the object no longer stores data.
	* @public
	*/
	public invalidate(): void {
		console.log("Invalidating cached data");
		this._items = undefined;
		this._cacheTime = undefined;
		this.clearTimeout();
		CacheRegistry.remove(this);
	}

}
