import { Component, OnInit } from '@angular/core';
import { PrivateComponent } from "@classes/private.component"
import { MessageType, AsyncResult } from "@classes/asyncresult"
import { MenuBuilder } from "@services/navmenu.service";

@Component({
	"templateUrl": "./changepassword.component.html"
})
export class ChangePwdComponent extends PrivateComponent implements OnInit {

	private _hidePassword: boolean = true;
	get hidePassword(): boolean {
		return this._hidePassword;
	}

	toggleHidePassword(): void {
		this._hidePassword = !this._hidePassword;
	}

	model = {
		"oldpwd": "",
		"password": "",
		"confirmpwd": ""
	}

	private resetModel(): void {
		this.model.oldpwd = "";
		this.model.password = "";
		this.model.confirmpwd = "";
	}

	changeResult: AsyncResult = null

	constructor() {
		super();
	}

	ngOnInit() {
		super.ngOnInit()

		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.done();
	}

	changePwd(form): void {
		if (this.formValid()) {
			this.userService.changePassword(this.model.oldpwd, this.model.password).then( (result: AsyncResult) => {

				this.changeResult = result;
				if (result.success) {
					this.resetModel()
					form.reset();
				}

			}).catch( err => {

				this.changeResult = new AsyncResult(MessageType.error, false, err);

			});
		}
	}

	formValid(): boolean {

		let oldPwd = this.model.oldpwd || "";
		let newPwd = this.model.password || "";
		let result = oldPwd.trim().length > 0;

		result = result && newPwd.trim().length >= 8;
		result = result && newPwd === this.model.confirmpwd;
		return result;
	}
}
