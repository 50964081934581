import { Subject } from 'rxjs';
import { TemplateRef } from '@angular/core';
import { Utils } from "@classes/utils";

export enum DialogMode {
	none, wait, error, success, dialog, template
}

export interface ErrorMessage {
	title: string,
	message: string,
	buttonText: string
	autoclose?: number;
}

export interface DialogButton {
	text: string;
	handler: () => void;
}

interface Dialog {
	title: string;
	content: string;
	buttons: DialogButton[];
}

export interface Template {
	template: TemplateRef<any>;
	context?: any;
}

export class OverlayService {

	private static defaultLoadingMessage: string = `Loading${Utils.ellipsis}`;
	private static defaultButtonText: string = "Close"
	private static _isVisble: boolean = false;

	private static loadingTextSource = new Subject<string>();
	private static errorMessageSource = new Subject<ErrorMessage>();
	private static dialogModeSource = new Subject<DialogMode>();
	private static templateModeSource = new Subject<Template>();
	private static dialogSource = new Subject<Dialog>();

	static loadingText$ = OverlayService.loadingTextSource.asObservable();
	static errorMessageS = OverlayService.errorMessageSource.asObservable();
	static dialogMode$ = OverlayService.dialogModeSource.asObservable();
	static templateMode$ = OverlayService.templateModeSource.asObservable();
	static dialog$ = OverlayService.dialogSource.asObservable();

	static show(msg?: string): void {
		OverlayService.loadingTextSource.next(msg || OverlayService.defaultLoadingMessage );
		OverlayService.dialogModeSource.next(DialogMode.wait);
		OverlayService._isVisble = true;
	}

	static hide(): void {
		OverlayService._isVisble = false;
		OverlayService.dialogModeSource.next(DialogMode.none);
	}

	static showError(title: string, message: string, buttonText: string = OverlayService.defaultButtonText, autocloseInterval?: number): void {
		OverlayService.errorMessageSource.next({
			"title": title,
			"message": message,
			"buttonText": buttonText,
			"autoclose": autocloseInterval || 0
		});
		OverlayService.dialogModeSource.next(DialogMode.error);
		OverlayService._isVisble = true;
	}

	static showSuccess(title: string, message: string, buttonText: string = OverlayService.defaultButtonText, autocloseInterval?: number): void {
		OverlayService.errorMessageSource.next({
			"title": title,
			"message": message,
			"buttonText": buttonText,
			"autoclose": autocloseInterval || 0
		});
		OverlayService.dialogModeSource.next(DialogMode.success);
		OverlayService._isVisble = true;
	}

	static showDialog(title: string, content: string, buttons: DialogButton[]): void {
		OverlayService.dialogSource.next({
			"title": title,
			"content": content,
			"buttons": buttons
		});
		OverlayService.dialogModeSource.next(DialogMode.dialog);
		OverlayService._isVisble = true;
	}

	static showTemplate(template: TemplateRef<any>, context?: any): void {
		OverlayService.templateModeSource.next({
			"template": template,
			"context": context
		});
		OverlayService.dialogModeSource.next(DialogMode.template);
		OverlayService._isVisble = true;
	}

	static get visible(): boolean {
		return OverlayService._isVisble;
	}
}
