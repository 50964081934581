import { Component, OnInit } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { UserAccount, UserType } from "@classes/user";
import { UserAccountService } from "@services/accounts.service";


@Component({
	"selector": "clientsearch",
	"styleUrls": ["./clientsearch.component.scss"],
	"templateUrl": "./clientsearch.component.html"
})
export class ClientSearchComponent extends PrivateComponent implements OnInit {

	private _dataLoaded: boolean = false;
	get dataLoaded(): boolean { return this._dataLoaded; }

	private _clients: UserAccount[] = [];
	get clients(): UserAccount[] {
		return this._clients;
	}

	model: any = {
		"clientName": ""
	};

	constructor(private userAccountService: UserAccountService) {
		super()
	}

	ngOnInit() {
		super.ngOnInit()
		this.loadData();
	}

	private async loadData() {
		this._dataLoaded = false;

		const data = await this.userAccountService.getAccounts();
		this._clients = data.filter( user => user.accountType === UserType.Participant);

		// this._clients = data.filter( user => user.accountType === UserType.Participant).map( user => {
		// 	return {
		// 		"id": user.id,
		// 		"name": `${user.firstName} ${user.lastName}`,
		// 		"ndisNumber": user.ndisNumber,
		// 		"email": user.email
		// 	};
		// });

		this._dataLoaded = true;
	}

	public clientSelected(client: any): void {
		if (client && client.id) {
			this.router.navigate([`/dashboard/${client.id}`]);
		}
	}

	public clearSearch(): void {
		this.model.clientName = "";
	}

	/**
	* Formatting function for NDIS number in the ngxTypeahead dropdown list.
	* Returns the ndisNumber as string if defined, or "Unknown" otherwise.
	*
	* @param {string} value The value of the ndisNumber field on the client record
	* @return {string}
	*/
	protected getNDISNumber(value: string): string {
		return value || "Unknown";
	}
}
