import { Injectable } from '@angular/core';
import { RestService, API } from './rest.service';
import moment from 'moment';

export interface Transaction {
	id: string;
	currentPlan: boolean;
	supportCategoryId: string;
	supportCategory: string;
	clientId: string;
	clientName: string;
	ndisNumber: string;
	serviceDate: Date;
	comments: string;
	account: string;
	total: number;
	created: Date;
	createdBy: string;
	serviceName: string;
	document?: any;
	extractId: string;
	claimReferenceNumber: string;
	invoiceId?: string;
	invoiceNumber?: string;
}

export interface ServiceDelivered {
	id: string;
	clientId: string;
	date: Date;
	invoiceNumber?: string;
	supportItemNumber: string;
	claimReference?: string;
	supportId: string;
	supportItemName: string;
	providerId: string;
	providerName: string;
	total: number;
	rate: number;
	quantity: number;
	paid: boolean;
}

class ServiceDeliveredUtils {
	public static parse(data: any): ServiceDelivered {
		return {
			"id": data.id,
			"clientId": data.clientId,
			"date": moment(data.date).toDate(),
			"supportItemNumber": data.supportItemNumber,
			"supportId": data.supportId,
			"supportItemName": data.supportItemName,
			"providerId": data.providerId,
			"providerName": data.providerName,
			"total": data.total,
			"rate": data.rate,
			"quantity": data.quantity,
			"paid": data.paid,
			"claimReference": data.claimReference,
			"invoiceNumber": data.invoiceNumber
		};
	}
}

@Injectable({ "providedIn": 'root' })
export class TransactionService {
	constructor(protected restService: RestService) {}

	private mapperFunc(item: any): ServiceDelivered {
		return ServiceDeliveredUtils.parse(item);
	}

	public getServicesDelivered(clientId: string, planId: string, supportCategory: number): Promise<ServiceDelivered[]> {
		return this.restService.get(API.plans, `servicesdelivered/${clientId}/${planId}/${supportCategory}`).then( result => {
			return Promise.resolve( result.map( this.mapperFunc ) );
		});
	}
}

abstract class BaseTransactionService {

	protected static _transactions = new Map<string, Transaction>();
	protected static maxDataAge: number = 5 * 60;

	constructor(protected restService: RestService) {}

	mapResponse(items): Transaction[] {
		return items.map( item => {
			return {
				"id": item.id,
				"currentPlan": item.serviceAgreementStatus === "Current",
				"supportCategoryId": item.supportCategoryId,
				"supportCategory": item.supportCategory,
				"clientId": item.clientId,
				"clientName": item.clientName,
				"ndisNumber": item.ndisNumber,
				"serviceDate": item.serviceDate,
				"comments": item.comments,
				"account": item.account,
				"total": item.total,
				"created": item.created,
				"createdBy": item.createdBy,
				"serviceName": item.serviceName,
				"document": item.document,
				"extractId": item.extractId,
				"claimReferenceNumber": item.claimReferenceNumber,
				"invoiceId": item.invoiceId,
				"invoiceNumber": item.invoiceNumber
			};
		});
	}

	getTransaction(transactionId: string): Promise<Transaction> {

		if (BaseTransactionService._transactions.has(transactionId)) {
			return Promise.resolve(BaseTransactionService._transactions.get(transactionId));
		}

		return Promise.reject("Not Found");
	}
}

abstract class MultiUserTransactionService extends BaseTransactionService {
	protected _data: Transaction[] = [];

	protected lastFetched: Date  = undefined;

	protected abstract getApiUrl(): string;
	protected abstract getServiceName(): string;
	protected abstract getService(): API;

	constructor(protected restService: RestService) {
		super(restService);
	}

	getData(forceReload: boolean = false): Promise<Transaction[]> {

		if (forceReload) {
			this.lastFetched = undefined;
		}

		const lastFetched = moment(this.lastFetched).unix();
		const now = moment().unix();

		if (this.lastFetched === undefined || now - lastFetched > BaseTransactionService.maxDataAge) {

			return this.restService.get(this.getService(), this.getApiUrl()).then( result => {

				result.forEach( transaction => {
					BaseTransactionService._transactions.set(transaction.id, transaction);
				});

				this._data = this.mapResponse(result);
				this.lastFetched = new Date();
				return Promise.resolve(this._data);
			});
		}
		else {
			return Promise.resolve(this._data);
		}
	}
}

@Injectable({ "providedIn": 'root' })
export class AdminTransactionService extends MultiUserTransactionService {

	constructor(protected restService: RestService) {
		super(restService);
	}

	protected _data: Transaction[] = [];
	protected lastFetched: Date  = undefined;

	protected getApiUrl(): string {
		return "transactions/summary";
	}

	protected getServiceName(): string {
		return "admin";
	}

	protected getService(): API {
		return API.admin;
	}
}

@Injectable({ "providedIn": 'root' })
export class SupportCoordinatorTransactionService extends BaseTransactionService {

	private _supportCoordinatorTransactions = new Map<string, Transaction[]>();
	private lastFetched = new Map<string, number>();

	constructor(protected restService: RestService) {
		super(restService);
	}

	getData(supportCoordinatorId: string, forceReload: boolean = false): Promise<Transaction[]> {

		if (forceReload) {
			this.lastFetched.delete(supportCoordinatorId);
		}

		const lastFetched = this.lastFetched.has(supportCoordinatorId) ? this.lastFetched.get(supportCoordinatorId) : undefined;
		const now = moment().unix();

		if (lastFetched === undefined || now - lastFetched > BaseTransactionService.maxDataAge) {

			return this.restService.get(API.supportcoordinator, `transactions/${supportCoordinatorId}`).then( result => {

				this._supportCoordinatorTransactions.set(supportCoordinatorId, this.mapResponse(result));
				result.forEach(transaction => {
					BaseTransactionService._transactions.set(transaction.id, transaction);
				});

				this.lastFetched.set(supportCoordinatorId, moment().unix());
				return Promise.resolve(this._supportCoordinatorTransactions.get(supportCoordinatorId));
			});
		}
		else {
			return Promise.resolve(this._supportCoordinatorTransactions.get(supportCoordinatorId));
		}
	}
}

@Injectable({ "providedIn": 'root' })
export class UserTransactionService extends BaseTransactionService {

	private _userTransactions = new Map<string, Transaction[]>();
	private lastFetched = new Map<string, number>();

	constructor(protected restService: RestService) {
		super(restService);
	}

	getData(userId: string, forceReload: boolean = false): Promise<Transaction[]> {

		if (forceReload) {
			this.lastFetched.delete(userId);
		}

		const lastFetched = this.lastFetched.has(userId) ? this.lastFetched.get(userId) : undefined;
		const now = moment().unix();

		if (lastFetched === undefined || now - lastFetched > BaseTransactionService.maxDataAge) {

			return this.restService.get(API.user, `transactions/${userId}`).then( result => {

				this._userTransactions.set(userId, this.mapResponse(result));
				result.forEach(transaction => {
					console.log("Caching transaction " + transaction.id);
					BaseTransactionService._transactions.set(transaction.id, transaction);
				});

				this.lastFetched.set(userId, moment().unix());
				return Promise.resolve(this._userTransactions.get(userId));
			});
		}
		else {
			return Promise.resolve(this._userTransactions.get(userId));
		}
	}

	getCategoryTransactions(clientId: string, categoryId: string, forceReload: boolean = false): Promise<Transaction[]> {
		return this.getData(clientId, forceReload).then( transactions => {

			return Promise.resolve( transactions.filter( transaction => transaction.supportCategoryId === categoryId) );

		});
	}
}
