import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { UserType } from "@classes/user";
import { Utils } from "@classes/utils";
import { Table, TableColumnHeading, SortType, Alignment, StaticDataSource } from "@classes/tables";
import { MenuBuilder } from "@services/navmenu.service";
import { ProdaService, ProdaSubmission } from "@services/proda.service";
import { OverlayService } from "@services/overlay.service";
import { NDSPService } from "@services/ndsp.service";
import { ErrorUtils } from "@classes/errors";

@Component({
	"styleUrls": ["./listFiles.component.scss"],
	"templateUrl": "./listFiles.component.html"
})
export class ProdaFileListComponent extends PrivateComponent implements OnInit {

	@ViewChild('ndspBillsDialog') private billsDialog: TemplateRef<any>;

	private _dataLoaded: boolean = false;
	private _files: ProdaSubmission[] = [];
	private ndspBillCount: number = 0;

	private _isLoading: boolean = false;

	private readonly tableHeadings: TableColumnHeading[] = [
		{"propName": "submittedDate", "displayName": "Date", "sortType": SortType.date },
		{"propName": "extract", "displayName": "Extract", "sortType": SortType.numeric },
		{"propName": "filename", "displayName": "Filename", "sortType": SortType.text },
		{"propName": "submittedByName", "displayName": "Submitted By", "sortType": SortType.name },
		{"propName": "count", "displayName": "Num Items", "sortType": SortType.numeric, "alignment": Alignment.center },
		{"propName": "reconciled", "displayName": "Reconciled", "sortType": SortType.numeric, "alignment": Alignment.center },
		{"propName": "discrepancies", "displayName": "Discrepancies", "sortType": SortType.numeric, "alignment": Alignment.center }
		// {"propName": "locked", "displayName": "Locked", "sortType": SortType.numeric, "alignment": Alignment.center }
	];

	table: Table<ProdaSubmission> = new Table('proddasubmissions', this.tableHeadings);

	get dataLoaded(): boolean {
		return this._dataLoaded;
	}

	constructor(private prodaService: ProdaService, private ndspService: NDSPService) {
		super();

		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Proda', 'PRODA Access');
	}

	private buildMenu(): void {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.addRoute('cloud-upload-alt', "PRODA Submission", "/proda/submission/new");
		menuBuilder.addRoute('file-csv', "Xero Export", "/proda/xero");
		if (this.hasPermission('SU', 'Mark NDSP bills as paid')) {
			menuBuilder.addHandler('check', "NDSP Bills", () => { this.ndspBillsDialog(); }, undefined, undefined, this.ndspBillCount);
		}
		menuBuilder.done();
	}

	private loadFiles(): void {
		if (!this.user) {
			return;
		}

		this._dataLoaded = false;

		OverlayService.show();
		this.prodaService.loadFiles().then( result => {
			this._files = result;
			this.table.sourceData = StaticDataSource.from(this._files);
			this._dataLoaded = true;
			this.filter('');
			OverlayService.hide();
		}).catch( err => {
			OverlayService.hide();
			console.log(err);
		});

	}

	ngOnInit() {
		super.ngOnInit()

		this.buildMenu();
		this.loadFiles();
		if (this.hasPermission('SU', 'Mark NDSP bills as paid')) {
			this.ndspService.loadInvoices().then( data => {
				this.ndspBillCount = data.length;
				this.buildMenu();
			});
		}
	}

	IsUnlocked(item: any): boolean {

		if (item) {
			return !item.locked;
		}
		return false;
	}

	public filter(value: string): void {
		const searchValue = (value || "").toLowerCase();

		const filteredFiles = this._files.filter( file => {
			// if (this.onlyUnlocked && this.IsUnlocked(file)) {
			// 	console.log('unlocked');
			// 	console.log(file);
			// }
			// return this.onlyUnlocked ? this.IsUnlocked(file) : true;
			return true;

		}).filter( file => {

			const searchableFields = [
				file.submittedByName || "",
				file.savedByName || "",
				file.filename || "",
				file.extract || ""
			];

			return searchableFields.map( x => `${x}`.toLowerCase() ).some( prop => prop.includes(searchValue) );

		});

		this.table.sourceData = StaticDataSource.from(filteredFiles);
	}

	public clearFilter(filterBox: any): void {
		filterBox.value = "";
		this.loadFiles();
	}

	private ndspBillsDialog() {
		OverlayService.show();
		this.ndspService.loadInvoices().then( data => {
			OverlayService.hide();

			if (data.length === 0) {
				OverlayService.showDialog("NDSP Bills", "You're all up to date!<br />There are no NDSP bills that can be marked as paid at this time.", [{
					"text": "Close",
					"handler": OverlayService.hide
				}]);
			}
			else {
				this.showBillsDialog(data);
			}
		}).catch( err => {
			OverlayService.hide();
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(err, "An unknown error occurred"));
		});
	}

	private showBillsDialog(bills) {
		const context = {
			"bills": bills,
			"selectedBills": bills.reduce( (acc, cur) => {
				acc[cur.id] = true;
				return acc;
			}, {})
		};

		OverlayService.showTemplate(this.billsDialog, context)
	}

	public closeDialogWindow() {
		OverlayService.hide();
	}

	public markBillsAsPaid(selectedBills) {
		const billsToMark = Object.keys(selectedBills).filter( id => selectedBills[id] );
		OverlayService.hide();

		OverlayService.show(`Saving${Utils.ellipsis}`);
		this.ndspService.markAsPaid(billsToMark).then( result => {

			if (!result) {
				return Promise.resolve(undefined);
			}

			return this.ndspService.loadInvoices();

		}).then( numInvoices => {

			if (numInvoices !== undefined) {
				this.ndspBillCount = numInvoices.length;
				this.buildMenu();
			}

			OverlayService.hide();

		}).catch( err => {

			OverlayService.hide();
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(err, "An unknown error occurred"));

		});

	}

	public get isLoading(): boolean {
		return this._isLoading;
	}

	public async loadMore() {
		this._isLoading = true;
		try {
			const moreFiles = await this.prodaService.loadFiles(this._files.length);
			this._files = this._files.concat(moreFiles);
			this.table.sourceData = StaticDataSource.from(this._files);
			this.filter('');
		}
		catch (e) {
			console.log(e);
		}
		finally {
			this._isLoading = false;
		}
	}
}
