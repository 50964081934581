import { Injectable } from "@angular/core";
import { DBService } from "@services/db.service";
import { SearchResultType } from '@services/search.service';

@Injectable({ providedIn: "root" })
export class SearchPreferenceService {

    private static prefKey = "searchpref";

    public async get(): Promise<SearchResultType> {
        const result = await DBService.nonVolatile.get(SearchPreferenceService.prefKey);
        return result || null;
    }

    public async set(preference: SearchResultType) {
        await DBService.nonVolatile.set(SearchPreferenceService.prefKey, preference);
    }
}
