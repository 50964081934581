import { AttachedFile } from '@classes/files';
import moment from "moment";

interface AdminUser {
	id: string;
	name: string;
}

export interface FileNoteCategory {
	id: string;
	name: string;
	sortOrder: number;
	grouping: number;
}

export enum FileNoteStatus {
	open,
	completed,
	// not_started
}

export namespace FileNoteStatus {
	const descriptions = new Map<FileNoteStatus, string>([
		[FileNoteStatus.open, "Open"],
		[FileNoteStatus.completed, "Completed"],
		// [FileNoteStatus.not_started, "Not Started"]
	]);

	const pgEnumValues = new Map<FileNoteStatus, string>([
		[FileNoteStatus.open, "open"],
		[FileNoteStatus.completed, "completed"],
		// [FileNoteStatus.not_started, "not_started"]
	]);

	const values = new Map<string, FileNoteStatus>([
		["open", FileNoteStatus.open],
		["completed", FileNoteStatus.completed],
		// ["not_started", FileNoteStatus.not_started]
	]);

	export function toString(status: FileNoteStatus): string {
		return descriptions.get(status);
	}

	export function toPostgresEnum(status: FileNoteStatus): string {
		return pgEnumValues.get(status);
	}

	export function allValues(): FileNoteStatus[] {
		return Array.from(descriptions.keys());
	}

	export function parse(src: string): FileNoteStatus {
		return values.get(src);
	}
}

export class FileNoteStatusUtils {
	private constructor() {}

	static toString(status: FileNoteStatus): string {
		return FileNoteStatus.toString(status);
	}

	static toPostgresEnum(status: FileNoteStatus): string {
		return FileNoteStatus.toPostgresEnum(status);
	}

	static parse(value: string): FileNoteStatus {
		return FileNoteStatus.parse(value);
	}

	static allValues(): FileNoteStatus[] {
		return FileNoteStatus.allValues();
	}
}

export interface FileNote {
	id: string;
	clientId: string;
	providerId: string;
	planId: string;
	parent: string;
	providerName: string;
	clientName: string;
	title?: string;
	content?: string;
	owner: AdminUser;
	createdBy: AdminUser;
	createdDate: Date;
	lastModified?: Date;
	lastModifiedBy?: AdminUser;
	assignedTo?: AdminUser;
	sticky: boolean;
	billingNote: boolean;
	reminder?: Date;
	eventDate?: Date;
	status: FileNoteStatus;
	attachments: AttachedFile[];
	icon: string;
	numComments: number;
	category: string[];
	totalMinutes: number;
	referenceCode: string;
	contentVisible?: boolean; // Used for collapsing content when displaying note list. Not stored in DB.
	replies?: FileNote[]
}

export class FileNoteUtils {

	private static reminderDateFormat = 'YYYY-MM-DD';

	private static trimString(value: string): string|undefined {
		value = (value || "").trim();
		return !!value ? value : undefined;
	}

	static noteToJson(note: FileNote): any {
		let result = {
			"id": note.id ? note.id : undefined,
			"clientId": note.clientId,
			"providerId": note.providerId,
			"planId": note.planId,
			"parent": note.parent,
			"title": FileNoteUtils.trimString(note.title),
			"content": FileNoteUtils.trimString(note.content),
			"owner": FileNoteUtils.trimString(note.owner.id),
			"assignedTo": FileNoteUtils.trimString(note.assignedTo.id),
			"sticky": note.sticky,
			"billingNote": note.billingNote,
			"reminder": note.reminder ? moment(note.reminder).format(FileNoteUtils.reminderDateFormat) : undefined,
			"eventDate": note.eventDate ? moment(note.eventDate).format(FileNoteUtils.reminderDateFormat) : undefined,
			"icon": FileNoteUtils.trimString(note.icon),
			"status": FileNoteStatusUtils.toPostgresEnum(note.status),
			"category": note.category && note.category.filter( item => !!item ) || undefined,
			"totalMinutes": note.totalMinutes,
			"referenceCode": FileNoteUtils.trimString(note.referenceCode)
		};

		return result;
	}

	// assignedTo: undefined for no changes in patch, null to set it to null for all tasks, id to change all tasks
	static updateNotesToJson(src: UpdateNotesComponent): any {
		let result = {
			//"owner": src.owner.id ? FileNoteUtils.trimString(src.owner.id) : src.owner.id === null ? null : undefined,
			"assignedTo": src.assignedTo.id ? FileNoteUtils.trimString(src.assignedTo.id) : src.assignedTo.id === null ? null : undefined,
			"status": src.status || src.status >= 0 ? FileNoteStatusUtils.toPostgresEnum(src.status) : undefined,
			"reminder": src.reminder ? moment(src.reminder).format(FileNoteUtils.reminderDateFormat) : undefined,
			"notes": [...src.notes]
		};

		return result;
	}

	static parse(data: any): FileNote {
		return {
			"id": data.id,
			"clientId": data.clientId,
			"providerId": data.providerId,
			"planId": data.planId,
			"parent": data.parent,
			"clientName": data.clientName,
			"providerName": data.providerName,
			"title": data.title,
			"content": data.content,
			"numComments": data.numComments,
			"assignedTo": {
				"id": data.assignedTo,
				"name": data.assignedToUser
			},
			"owner": {
				"id": data.owner,
				"name": data.ownerUser
			},
			"sticky": !!data.sticky,
			"billingNote": !!data.billingNote,
			"reminder": data.reminder ? moment(data.reminder).toDate() : undefined,
			"eventDate": data.eventDate ? moment(data.eventDate).toDate() : undefined,
			"createdDate": moment(data.createdDate).toDate(),
			"createdBy": {
				"id": data.createdBy,
				"name": data.createdByUser
			},
			"lastModified": data.lastModified ? moment(data.lastModified).toDate() : undefined,
			"lastModifiedBy":{
				"id": data.modifiedBy,
				"name": data.modifiedByUser
			},
			"status": FileNoteStatusUtils.parse(data.status),
			"icon": data.icon,
			"category": data.category && data.category || [],
			"totalMinutes": data.totalMinutes,
			"referenceCode": data.referenceCode,
			"attachments": (data.attachments || []).map( AttachedFile.parse )
		};
	}

	static clone(src: FileNote): FileNote {
		const result = {
			"id": src.id,
			"clientId": src.clientId,
			"providerId": src.providerId,
			"planId": src.planId,
			"parent": src.parent,
			"clientName": src.clientName,
			"providerName": src.providerName,
			"title": src.title,
			"content": src.content,
			"numComments": src.numComments,
			"createdBy": {...src.createdBy},
			"owner": {...src.owner},
			"createdDate": src.createdDate,
			"lastModified": src.lastModified,
			"lastModifiedBy": {...src.lastModifiedBy},
			"assignedTo": {...src.assignedTo},
			"sticky": src.sticky,
			"billingNote": src.billingNote,
			"reminder": src.reminder,
			"eventDate": src.eventDate,
			"icon": src.icon,
			"status": src.status,
			"category": src.category && src.category || [],
			"totalMinutes": src.totalMinutes,
			"referenceCode": src.referenceCode,
			"attachments": [...src.attachments]
		};

		return result;
	}

	static newNote(): FileNote {
		return {
			"id": undefined,
			"clientId": undefined,
			"providerId": undefined,
			"planId": undefined,
			"parent": undefined,
			"clientName": undefined,
			"providerName": undefined,
			"title": undefined,
			"content": undefined,
			"numComments": 0,
			"owner": {
				"id": undefined,
				"name": undefined
			},
			"createdBy": {
				"id": undefined,
				"name": undefined
			},
			"createdDate": undefined,
			"lastModified": undefined,
			"lastModifiedBy": {
				"id": undefined,
				"name": undefined
			},
			"assignedTo": {
				"id": undefined,
				"name": undefined
			},
			"sticky": false,
			"billingNote": false,
			"reminder": undefined,
			"eventDate": moment().startOf('day').toDate(),
			"icon": undefined,
			"status": undefined,
			"category": [],
			"totalMinutes": undefined,
			"referenceCode": undefined,
			"attachments": []
		};
	}
}

export interface TaskSummaryItem {
	userId: string;
	name: string;
	notStarted: number;
	open: number;
	overdue: number;
	total: number;
}

export namespace TaskSummaryItem {
	export function parse(src: any): TaskSummaryItem {
		return {
			"userId": src.assignedTo,
			"name": src.assignedToName,
			"notStarted": Number(src.notStarted),
			"open": Number(src.open),
			"overdue": Number(src.overdue),
			"total": Number(src.total)
		};
	}
}

export interface UpdateNotesComponent {
		//owner: { id, name },
		assignedTo?: {
			id,
			name
		},
		reminder?: Date;
		status?,
		notes
}

export namespace UpdateNotesComponent {
	export function clone(src: UpdateNotesComponent): UpdateNotesComponent {
		return !src ? undefined : {...src};
	}

	export function empty(): UpdateNotesComponent {
		return {
			//owner: { id: undefined, name: undefined },
			assignedTo: {
				id: undefined,
				name: undefined
			},
			reminder: undefined,
			status: undefined,
			notes: []
		};
	}
}