import { MD5 } from 'crypto-js';
import { AttachmentTarget } from './attachments';
import { AttachmentType } from "./attachmentType";
import moment from "moment";


/**
* Simple interface defining file metadata
*/
export interface FileMetaData {
	id?: string;
	name: string;
	mimeType: string;
	size: number;
	md5: string;
	dateAdded?: Date;
	description?: string;
	attachmentType?: AttachmentType;
}

export interface FileDownloader {
	loadAttachment: (attachmentId: string, clientId?: string) => Promise<any>;
}

export enum FileType {
	csv, image, pdf, document, spreadsheet, svg
}

const fileMimeTypeMatchers = new Map<FileType, RegExp[]>([
	[FileType.csv, [/^text\/csv$/, /^application\/csv$/, /^application\/vnd\.ms-excel$/]],
	[FileType.pdf, [/^application\/pdf$/]],
	[FileType.svg, [/^application\/svg\+xml$/]],
	[FileType.image, [/^image\/.*$/]],
	[FileType.spreadsheet, [/^application\/vnd\.ms-excel$/, /^application\/vnd\.openxmlformats-officedocument\.spreadsheetml\.sheet$/]],
	[FileType.document, [/^application\/msword$/, /^application\/vnd\.openxmlformats-officedocument\.wordprocessingml\.document/]]
]);

/**
* Utility class to calculate the size of a file from it's base64-encoded data URL
*/
class Base64Utils {
	private readonly base64Data: string;

	private get paddingBytes() {
		const re = /={1,2}$/;
		const r = this.base64Data.match(re);
		return r === null ? 0 : r[0].length;
	}

	constructor(src: string) {
		this.base64Data = src.replace(/^.*,/, '');
	}

	get filesize(): number {
		return (this.base64Data.length * 0.75) - this.paddingBytes;
	}
}

class ImageResizer {
	public static maxImageFileSize: number = 1024 * 1024; // 1MB file size limit for images

	private originalFileSize: number;

	private readImage(content: string): Promise<HTMLImageElement> {
		return new Promise( resolve => {

			const img = document.createElement("img");
			img.crossOrigin = "Anonymous";

			const imageLoaded = (img) => {
				return resolve(img);
			}

			img.onload = imageLoaded.bind(null, img);
			img.src = content;
		});
	}

	/**
	* Determines approximate image dimensions for new image based on the desired file size. Calculation
	* is really a back-of-the-envelope rough guess, actual file size will vary dramatically based on JPEG
	* compression level applied to output image.
	*
	* Uses:
	* filesize = c * imageWidth * imageHeight, where c is a constant that approximates bit depth and compression level.
	*/
	private scaleImage(img: HTMLImageElement) {
		const srcAspectRatio = 1.0 * img.naturalWidth / img.naturalHeight;
		const c = this.originalFileSize / (img.naturalWidth * img.naturalHeight);
		const newX = Math.floor( Math.sqrt( AttachedFile.maxImageFileSize * srcAspectRatio / c ) );
		const newY = Math.floor( newX / srcAspectRatio );

		const canvas = document.createElement("canvas");
		canvas.width = newX;
		canvas.height = newY;
		let context = canvas.getContext("2d");

		if (srcAspectRatio >= 1.0) {
			// Image is landscape or square
			let scaleFactor = 1.0 * img.naturalHeight / newY;
			let scaledWindowWidth = newX * scaleFactor;
			let offsetX = (img.naturalWidth - scaledWindowWidth) / 2;
			context.drawImage(img, offsetX, 0, scaledWindowWidth, img.naturalHeight, 0, 0, newX, newY);
		}
		else {
			// Image is portrait
			let scaleFactor = 1.0 * img.naturalWidth / newX;
			let scaledWindowHeight = newY * scaleFactor;
			let offsetY = (img.naturalHeight - scaledWindowHeight) / 2;
			context.drawImage(img, 0, offsetY, img.naturalWidth, scaledWindowHeight, 0, 0, newX, newY);
		}

		const result = canvas.toDataURL("image/jpeg", 0.8);
		const base64 = new Base64Utils(result);
		console.log(`Base64 length = ${result.length}`);
		console.log(`Filesize = ${base64.filesize}`);
		return Promise.resolve( result );
	}

	public scale(content: string): Promise<string> {
		const base64 = new Base64Utils(content);
		this.originalFileSize = base64.filesize;

		return this.readImage(content).then( img => {
			return this.scaleImage(img);
		});
	}
}

/**
* Implementation of the FileMetaData interface, with static factory methods.
* Provides a static method for calculating the MD5 checksum for the specified file.
*/
export class AttachedFile implements FileMetaData {

	public static maxImageFileSize: number = 1024 * 1024; // 1MB file size limit for images

	private _md5: string;
	private _textContent: string;
	private _content: string;
	private _size: number;

	readonly file?: File;
	readonly name: string;
	readonly mimeType: string;
	readonly dateAdded?: Date;
	readonly description?: string;
	public id?: string;
	public attachmentType?: AttachmentType;

	readonly targets?: AttachmentTarget[];

	get md5(): string {
		return this._md5;
	}

	get size(): number {
		return this._size;
	}

	static clone(src: AttachedFile): AttachedFile {
		return new AttachedFile(undefined, src);
	}

	private get isImage(): boolean {
		return this.type === FileType.image;
	}

	private get imageTooLarge(): boolean {
		return this.size > ImageResizer.maxImageFileSize;
	}

	private readFileAsBase64(): Promise<string> {

		return new Promise( resolve => {

			const fileLoaded = (event) => {
				return resolve(event.target.result);
			};

			const reader = new FileReader();
			reader.onloadend = fileLoaded;
			reader.readAsDataURL(this.file);
		});
	}

	private resizeImage(): Promise<string> {

		return this.readFileAsBase64().then( content => {

			const resizer = new ImageResizer();
			return resizer.scale(content);

		}).then( result => {

			const base64 = new Base64Utils(result);
			this._size = base64.filesize;
			this._md5 = MD5(result).toString();
			return Promise.resolve(result);

		});
	}

	get content(): Promise<string> {

		// Return cached value if available
		if (this._content) {
			return Promise.resolve(this._content);
		}

		return new Promise( (resolve, reject) => {

			if (!this.file) {
				return reject("Local file not available");
			}

			if (this.isImage && this.imageTooLarge) {
				console.log("Resizing image...");
				this.resizeImage().then( result => {
					this._content = result;
					resolve(this._content);
				})
			}
			else {
				this.readFileAsBase64().then( result => {
					this._content = result;
					resolve(this._content);
				});
			}
		});
	}

	get textContent(): Promise<string> {
		// Return cached value if available
		if (this._textContent) {
			return Promise.resolve(this._textContent);
		}

		return new Promise( (resolve, reject) => {

			if (!this.file) {
				return reject("Local file not available");
			}

			const reader = new FileReader();
			reader.onloadend = (event) => {
				this._textContent = reader.result as string;
				resolve( this._textContent );
			};

			reader.readAsText(this.file);
		});
	}

	get type(): FileType|undefined {

		return Array.from(fileMimeTypeMatchers.keys()).reduce( (result, key) => {

			if (result !== undefined) {
				return result;
			}

			const match = fileMimeTypeMatchers.get(key).some( expr => expr.test(this.mimeType) );
			return match ? key : undefined;

		}, undefined);
	}

	static calcMD5(file: File): Promise<string> {

		return new Promise( (resolve, reject) => {

			const fileLoaded = (event) => {
				const binary = event.target.result;
				const md5 = MD5(binary).toString();
				resolve( md5 );
			};

			const reader = new FileReader();
			reader.onloadend = fileLoaded;
			reader.readAsBinaryString(file);
		});
	}

	protected constructor(file?: File, props?: FileMetaData, targets?: AttachmentTarget[]) {
		if (file) {
			this.file = file;
			this.name = file.name;
			this.mimeType = file.type;
			this._size = file.size;
			AttachedFile.calcMD5(file).then( md5 => {
				this._md5 = md5;
			});
		}
		else if (props) {
			this.id = props.id;
			this.name = props.name;
			this._size = props.size;
			this.mimeType = props.mimeType;
			this._md5 = props.md5;
			this.dateAdded = props.dateAdded;
			this.description = props.description;
			this.attachmentType = props.attachmentType;
		}

		this.targets = targets;
	}

	static async fromFile(file: File): Promise<AttachedFile> {
		const result = new AttachedFile(file);
		result._md5 = await AttachedFile.calcMD5(file);
		return result;
	}

	static fromMetaData(props: FileMetaData, targets?: AttachmentTarget[]): AttachedFile {
		return new AttachedFile(undefined, props, targets);
	}

	static parse(src: any): AttachedFile {
		return AttachedFile.fromMetaData({
			"id": src.id,
			"name": src.name,
			"mimeType": src.mimeType,
			"size": Number(src.size),
			"md5": src.md5,
			"dateAdded": moment(src.dateAdded).toDate(),
			"attachmentType": AttachmentType.parse(src.attachmentType)
		});
	}
}

/**
* Helper class defining allowed mime types for files that can be attached to an invoice.
* Provides a method for identifying a suitable icon (from the FontAwesome collection) for
* each allowed file type.
*/
export class FileMimeTypes {
	private mimeTypes = new Map<RegExp, string>();

	constructor(...allowedFormats: FileType[]) {

		const knownTypes = new Map<FileType, any>([
			[FileType.csv, { "file-excel": fileMimeTypeMatchers.get(FileType.csv) }],
			[FileType.pdf, { "file-pdf": fileMimeTypeMatchers.get(FileType.pdf) }],
			[FileType.svg, { "file-alt": fileMimeTypeMatchers.get(FileType.svg) }],
			[FileType.image, { "file-image": fileMimeTypeMatchers.get(FileType.image) }],
			[FileType.spreadsheet, { "file-excel": fileMimeTypeMatchers.get(FileType.spreadsheet) }],
			[FileType.document, { "file-word": fileMimeTypeMatchers.get(FileType.document) }]
		]);

		// Defaults to PDF, image and MS Word/Open Office documents
		if (allowedFormats && allowedFormats.length === 0) {
			allowedFormats = [FileType.pdf, FileType.image, FileType.document];
		}

		allowedFormats.forEach( format => {

			const mimeTypesForFormat = knownTypes.get(format);
			Object.keys( mimeTypesForFormat ).forEach( icon => {
				mimeTypesForFormat[icon].forEach( mimeType => {
					this.mimeTypes.set(mimeType, icon);
				});
			});
		});
	}

	isAllowed(mimeType: string): boolean {
		return Array.from( this.mimeTypes.keys() ).reduce( (acc, cur) => acc || cur.test(mimeType), false );
	}

	getIcon(mimeType: string): string {
		let result = "file";

		Array.from(this.mimeTypes.keys()).forEach( re => {
			if (re.test(mimeType)) {
				result = this.mimeTypes.get(re);
			}
		});

		return result;
	}
}

export abstract class FileValidator {
	private file: AttachedFile;

	constructor(src: AttachedFile) {
		this.file = src;
	}

	abstract get fileSignature(): string;

	private checkSignature(fileContent) {

		// Convert the line of the file to an array of strings. Split on both Windows and Unix line-endings (CRLF or LF)
		const lines: string[] = fileContent.split(/\r?\n/);

		// If there's less that two lines in the file, then there's going to be no useful data in it, so return false
		if (lines.length < 2) {
			return Promise.resolve(false);
		}

		// Find the header line (the first line)
		let firstLine: string = lines[0];
		let header: string = firstLine.split(',').map(element => {
			return element.trim();
		}).join(',');

		// Compare it to the known file signature
		return Promise.resolve(this.fileSignature.split('|').indexOf(header) >= 0);
	}

	public isValid(): Promise<boolean> {

		return this.file.textContent.then( fileContent => {
			return this.checkSignature(fileContent);
		});
	}
}
