import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrivateComponent } from "@classes/private.component";
import { Plan, PlanStatus } from "@classes/plans";
import { MenuBuilder } from "@services/navmenu.service";
import { PlanService } from "@services/plan.service";
import { QuarantinedFundsData } from "./quarantinedFundsData";
import { OverlayService } from "@services/overlay.service";
import { ErrorUtils } from "@classes/errors";
import { UserType } from "@classes/user";
import { ServiceAgreement } from "@classes/serviceAgreement";

@Component({
	"styleUrls": ["./quarantinedfunds.component.scss"],
	"templateUrl": "./quarantinedfunds.component.html"
})
export class QuarantinedFundsComponent extends PrivateComponent implements OnInit {

	/**
	* Helper class for retrieving and manipulation plan data
	*/
	protected data: QuarantinedFundsData = undefined;

	/**
	* The plan selected in the drop-down box that we're editing
	*/
	public plan: Plan = undefined;

	public get serviceAgreements(): ServiceAgreement[] {
		return this.data ? this.data.serviceAgreements : [];
	}

	constructor(
		private route: ActivatedRoute,
		private planService: PlanService) {
		super();

		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Plans', 'Edit Quarantined Funds');
	}

	/**
	* Defines the buttons that will be displayed at the top of the page
	*/
	private buildMenu(): void {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.done();
	}

	/**
	* Calculated property which indicates when all of the data required for this component
	* has been loaded.
	* Used to display a "loading..." message (and disable the rest of the UI) whilst we're
	* waiting for data to be retrieved.
	*/
	public get dataLoaded(): boolean {
		return this.data !== undefined && this.data.loaded;
	}

	/**
	* Returns a string representing the status of a plan.
	* Delegates implementation to the exported method of the PlanStatus namespace.
	*/
	public readonly planStatus: (plan: PlanStatus) => string = PlanStatus.toString;

	public async planSelected() {
		OverlayService.show();
		try {
			this.data.loadServiceAgreements(this.plan);
			OverlayService.hide();
		}
		catch (e) {
			console.log(e);
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "Unable to load service agreements"));
		}
	}

	/**
	* Initiates loading of data required for this component when the clientId and providerId
	* path params have been received.
	*/
	ngOnInit() {
		super.ngOnInit()

		this.route.params.subscribe( params => {

			if (this.user) {
				const userId = params.userId;

				this.data = new QuarantinedFundsData(userId);
				this.data.load().then( () => {
					this.mruService.push(this.data.client);
					this.plan = this.data.currentPlan || this.data.plans.length ? this.data.plans[this.data.plans.length - 1] : undefined;
					this.data.loadServiceAgreements(this.plan);
					this.buildMenu();
				});
			}
		});

		this.buildMenu();
	}

	public addServiceAgreement() {
		const newServiceAgreement = ServiceAgreement.blank();

		newServiceAgreement.client.id = this.data.client.id;
		newServiceAgreement.client.name = `${this.data.client.firstName} ${this.data.client.lastName}`;
		newServiceAgreement.planId = this.plan.id;
		newServiceAgreement.dateFrom = new Date(this.plan.startDate);
		newServiceAgreement.dateTo = new Date(this.plan.endDate);

		this.data.serviceAgreements.unshift( newServiceAgreement );
	}

	/**
	* Removes a service agreement from the list.
	*/
	public async deleteServiceAgreement(item: ServiceAgreement) {
		const idx = this.data.serviceAgreements.indexOf(item);
		if (idx >= 0) {

			OverlayService.show();
			try {
				if (!!item.id) {
					await this.planService.deleteServiceAgreement(item);
				}

				this.data.serviceAgreements.splice(idx, 1);
				OverlayService.hide();
			}
			catch (e) {
				console.log(e);
				OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "Unable to delete service agreement") );
			}

		}
	}
}
