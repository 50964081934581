import { Cache } from 'aws-amplify';

export class DataExchangeService {

	private static data: any = {};
	private static pathCache: any;
	private static pathKey = 'path';

	static set(value: any, key?: string): void {
		key = key || "anonymous";
		DataExchangeService.data[key] = value;
	}

	private static initCache(): void {
		if (!DataExchangeService.pathCache) {
			const config = {
				"keyPrefix": "dx",
				"storage": window.sessionStorage
			};
			DataExchangeService.pathCache = Cache.createInstance(config);
		}
	}

	static setPath(value: string): void {
		DataExchangeService.initCache();
		DataExchangeService.pathCache.setItem(DataExchangeService.pathKey, value);
	}

	static getPath(): string {
		DataExchangeService.initCache();
		const result = DataExchangeService.pathCache.getItem(DataExchangeService.pathKey);
		DataExchangeService.pathCache.removeItem(DataExchangeService.pathKey);
		return result;
	}

	static get(key: string, clear?: boolean): any;
	static get(clear?: boolean): any;
	static get(x: any, clear?: boolean): any {
		const key = (typeof x === "string") ? x : "anonymous";
		const result = DataExchangeService.data[key];
		if (!!clear) {
			delete DataExchangeService.data[key];
		}
		return result;
	}

	static clear() {
		DataExchangeService.pathCache.removeItem(DataExchangeService.pathKey);
		DataExchangeService.data = {};
	}
}

