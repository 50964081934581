import { Component, OnInit } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { UserType } from '@classes/user';
import { MenuBuilder } from "@services/navmenu.service";
import { ReportsService } from "@services/reports.service";
import { OverlayService } from "@services/overlay.service";
import { DataExchangeService } from "@services/dataexchange.service";
import { Table, TableColumnHeading, SortType, StaticDataSource } from "@classes/tables";
import { saveAs } from 'file-saver';
import { DateUtils } from "@classes/utils";
import moment from 'moment';

interface BillingReportData {
	userId: string;
	userName: string;
	numBills: number;
	averageEntryTimeSeconds: number;
	minEntryTimeSeconds: number;
	maxEntryTimeSeconds: number;
	entryTimeStdDevSeconds: number;
	totalLineItems: number;
	averageLineItemsPerBill: number;
	averageSecondsPerLineItem: number;
}

@Component({
	"templateUrl": "./billingreport.component.html",
	"styleUrls": ["./billingreport.component.scss"]
})
export class BillingReportComponent extends PrivateComponent implements OnInit {

	private static readonly dateFormat = 'DDMMYYYY';

	private _dataLoaded: boolean = false;

	private readonly tableHeadings: TableColumnHeading[] = [
		{"propName": "userName", "displayName": "Operator", "sortType": SortType.name },
		{"propName": "numBills", "displayName": "Bills Entered", "sortType": SortType.numeric},
		{"propName": "totalLineItems", "displayName": "Line Items", "sortType": SortType.numeric},
		{"propName": "averageLineItemsPerBill", "displayName": "Avg. Line Items Per Bill", "sortType": SortType.numeric},
		{"propName": "averageEntryTimeSeconds", "displayName": "Avg. Bill Entry Time", "sortType": SortType.numeric},
		{"propName": "averageSecondsPerLineItem", "displayName": "Avg. Time Per Line Item", "sortType": SortType.numeric},
		{"propName": "minEntryTimeSeconds", "displayName": "Min. Bill Entry Time", "sortType": SortType.numeric},
		{"propName": "maxEntryTimeSeconds", "displayName": "Max. Bill Entry Time", "sortType": SortType.numeric},
		{"propName": "entryTimeStdDevSeconds", "displayName": "Bill Entry σ", "sortType": SortType.numeric}
	];

	private sourceData: BillingReportData[] = [];
	protected table: Table<BillingReportData> = new Table('billingReport', this.tableHeadings);

	public model = {
		"reportPeriod": undefined,
		"startDate": undefined,
		"endDate": undefined,
		"events": {
			"reportPeriodChange": v => {
				DataExchangeService.set(v, "billingReport.reportPeriod");
			},
			"dateChange": (v, item) => {
				const date = DateUtils.parse(v, BillingReportComponent.dateFormat);
				if (date !== undefined) {
					DataExchangeService.set(v, `billingReport.${item}`);
				}
			}
		}
	};

	public reportPeriods = [
		{"name": "Last week", "value": 0},
		{"name": "Last month", "value": 1},
		{"name": "Choose dates", "value": 2}
	];

	constructor(private reportsService: ReportsService) {
		super();

		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Reports', 'Billing Entry Report');
	}

	ngOnInit() {
		super.ngOnInit();

		if (this.user) {
			this.buildMenu();

			this.model.reportPeriod = DataExchangeService.get("billingReport.reportPeriod") || 0;
			this.model.startDate = DataExchangeService.get("billingReport.startDate");
			this.model.endDate = DataExchangeService.get("billingReport.endDate");

			if (!this.reportSettingsInvalid) {
				this.loadData();
			}
			else {
				this._dataLoaded = true;
			}
		}
	}

	get dataLoaded(): boolean {
		return this._dataLoaded;
	}

	private buildMenu(): void {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.addHandler(
			'file-excel',
			'Download',
			() => { this.downloadReport(); },
			() => { return this.downloadDisabled(); }
		);
		menuBuilder.done();
	}

	private downloadDisabled(): boolean {
		return !this._dataLoaded || !this.table.hasData;
	}

	public async loadData() {
		this._dataLoaded = false;
		try {
			OverlayService.show();
			this.sourceData = await this.reportsService.billingReport(this.model.reportPeriod, DateUtils.parse(this.model.startDate, BillingReportComponent.dateFormat), DateUtils.parse(this.model.endDate, BillingReportComponent.dateFormat));
			this.table.sourceData = StaticDataSource.from(this.sourceData);
			OverlayService.hide();
		}
		catch (e) {
			OverlayService.hide();
			OverlayService.showError("Error", "Unable to display report");
			console.log(e);
		}
		finally {
			this._dataLoaded = true;
		}
	}

	public get reportSettingsInvalid(): boolean {
		if ([0, 1].includes(this.model.reportPeriod)) {
			return false;
		}

		const startDate = DateUtils.parseMoment(this.model.startDate, BillingReportComponent.dateFormat);
		const endDate = DateUtils.parseMoment(this.model.endDate, BillingReportComponent.dateFormat);
		const now = moment();

		if ([null, undefined].includes(startDate) || [null, undefined].includes(endDate)) {
			return true;
		}

		if (startDate.isAfter(endDate)) {
			return true;
		}

		if (startDate.isAfter(now) || endDate.isAfter(now)) {
			return true;
		}

		return false;
	}

	private downloadReport(): void {
		const csvReport = new ReportDownloader(this.sourceData);
		csvReport.downloadReport();
	}

}


class ReportDownloader {
	public constructor(private data: BillingReportData[]) {}

	private billingReportDataToString(item: BillingReportData): string {
		const fields = [
			"userName",
			"numBills",
			"totalLineItems",
			"averageLineItemsPerBill",
			"averageEntryTimeSeconds",
			"averageSecondsPerLineItem",
			"minEntryTimeSeconds",
			"maxEntryTimeSeconds",
			"entryTimeStdDevSeconds"
		];

		return fields.map( fieldName => item[fieldName] ).join(",");
	}

	private constructCSV(): string {
		let result = "Operator,Bills Entered,Total Line Items,Avg. Line Items Per Bill,Avg. Bill Entry Time (s),Avg. Time Per Line Item (s),Min. Bill Entry Time (s),Max. Bill Entry Time (s),Bill Entry σ(s)\n";
		result += this.data.map( data => { return this.billingReportDataToString(data); }).join("\n");
		return result;
	}

	public downloadReport(): void {
		const csv = this.constructCSV();
		const file = new Blob([csv], { "type": "text/csv;charset=utf-8" });
		saveAs(file, "billing-entry-report.csv");
	}
}
