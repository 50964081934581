import { ServiceAgreement } from "@classes/serviceAgreement";
import { assert, EAssertionFailed } from "@classes/errors";
import moment from 'moment';

	/**
	* Determines if a service agreement overlaps any other service agreement.
	* An overlap occurs if all of the following conditions are met:
	* - Two service agreements are for the same provider
	* - The intersection of the date ranges for the service agreements is non-empty
	* - The service agreements contain budgets for the same support category
	*/
export class OverlapTest {

	private startDate: moment.Moment;
	private endDate: moment.Moment;

	constructor(private agreement: ServiceAgreement, private otherAgreements: ServiceAgreement[]) {

		this.startDate = !!this.agreement.dateFrom ? moment(this.agreement.dateFrom) : undefined;
		this.endDate = !!this.agreement.dateTo ? moment(this.agreement.dateTo) : undefined;
	}

	private filterByProvider(src: ServiceAgreement[]): ServiceAgreement[] {

		return src.filter( agreement => {
			if (!agreement.provider || !agreement.provider.id) {
				return false;
			}

			return agreement.provider.id === this.agreement.provider.id;
		});
	}

	private overlappingDates(src: ServiceAgreement[]): ServiceAgreement[] {
		return src.filter( agreement => {
			try {
				if (!agreement.dateFrom || !agreement.dateTo) {
					return false;
				}

				const dateFrom = moment(agreement.dateFrom);
				const dateTo = moment(agreement.dateTo);

				return this.startDate.isBetween(dateFrom, dateTo, undefined, '[]') ||
				       this.endDate.isBetween(dateFrom, dateTo, undefined, '[]') ||
				       dateFrom.isBetween(this.startDate, this.endDate) ||
				       dateTo.isBetween(this.startDate, this.endDate);
			}
			catch (e) {
				return false;
			}
		});
	}

	private sameCategory(src: ServiceAgreement[], categoryNumber: number): ServiceAgreement[] {
		return src.filter( agreement => {
			const agreementCategories = agreement.categories.map( category => category.categoryNumber );
			return agreementCategories.includes(categoryNumber);
		});
	}

	public hasOverlap(categoryNumber: number) {

		try {

			// Preliminary tests... If any of these fail we can't check for overlaps. This does not
			// indicate that there is an overlap for this category.
			assert(this.otherAgreements.length > 0); // If no other agreements, can't be an overlap
			assert(this.startDate !== undefined); // Must have a start date
			assert(this.endDate !== undefined); // Must have an end date
			assert(!!this.agreement.provider && !!this.agreement.provider.id); // Must have a provider
		}
		catch (e) {
			if (e instanceof EAssertionFailed) {
				return;
			}

			throw e;
		}


		const sameProviders = this.filterByProvider(this.otherAgreements);
		if (sameProviders.length === 0) {
			return;
		}

		const overlaps = this.overlappingDates(sameProviders);
		if (overlaps.length === 0) {
			return;
		}

		const overlap = this.sameCategory(overlaps, categoryNumber).length > 0;
		assert(!overlap, "Overlaps with the same category and provider on another service agreement");
	}
}
