import { Component, OnInit } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { UserType } from '@classes/user';
import { InvoiceStatus } from "@classes/invoices";
import { MenuBuilder } from "@services/navmenu.service";
import { ReportsService } from "@services/reports.service";
import { OverlayService } from "@services/overlay.service";
import { DataExchangeService } from "@services/dataexchange.service";
import { Table, TableColumnHeading, SortType, Alignment, StaticDataSource } from "@classes/tables";
import { saveAs } from 'file-saver';
import { DateUtils } from "@classes/utils";
import moment from 'moment';

interface ReconciliationReportData {
	invoiceId: string;
	invoiceNumber: string;
	clientId: string;
	client: string;
	providerId: string;
	provider: string;
	billTotal: number;
	previouslyHeld: number;
	claimed: number;
	received: number;
	discrepancy: number;
	reconciled: boolean;
	status: boolean;
	deleted: boolean;
}

interface SummaryData {
	received: number;
	retained: number;
	released: number;
	payment: number;
	reportDate: string;
	generationDate: string;
}

class ReconciliationReportModel {
	private static readonly _minDate = moment([2020, 8, 22]);
	private static readonly _maxDate = moment().subtract(1, 'day');

	public static readonly storageKey = 'reconciliationReport.selectedDate';

	public dateStr: string;

	public get minDate(): string {
		return DateUtils.toString( ReconciliationReportModel._minDate.toDate(), DateUtils.defaultFormat );
	}

	public get maxDate(): string {
		return DateUtils.toString( ReconciliationReportModel._maxDate.toDate(), DateUtils.defaultFormat );
	}

	public get selectedDate(): Date {
		const m = DateUtils.parseMoment(this.dateStr, DateUtils.defaultFormat);
		if (!!m && m.isValid()) {
			return m.toDate();
		}
		return undefined;
	}

	constructor() {
		const lastDate = DataExchangeService.get(ReconciliationReportModel.storageKey);
		if (!!lastDate) {
			const m = DateUtils.parseMoment(lastDate, DateUtils.defaultFormat);
			if (m.isValid() && m.isSameOrAfter(ReconciliationReportModel._minDate) && m.isSameOrBefore(ReconciliationReportModel._maxDate)) {
				this.dateStr = DateUtils.toString(m.toDate(), DateUtils.defaultFormat);
				return;
			}
		}
		this.dateStr = DateUtils.toString(ReconciliationReportModel._maxDate.toDate(), DateUtils.defaultFormat);
	}
}

@Component({
	"templateUrl": "./reconciliationReport.component.html",
	"styleUrls": ["./reconciliationReport.component.scss"]
})
export class ReconciliationReportComponent extends PrivateComponent implements OnInit {

	private readonly _tableHeadings: TableColumnHeading[] = [
		{"propName": "invoiceNumber", "displayName": "Bill #", "sortType": SortType.text },
		{"propName": "client", "displayName": "Client", "sortType": SortType.text },
		{"propName": "provider", "displayName": "Provider", "sortType": SortType.text },
		{"propName": "total", "displayName": "Bill Total", "sortType": SortType.numeric, "alignment": Alignment.center},
		{"propName": "previouslyHeld", "displayName": "Held", "sortType": SortType.numeric, "alignment": Alignment.center},
		{"propName": "claimed", "displayName": "Claimed", "sortType": SortType.numeric, "alignment": Alignment.center},
		{"propName": "received", "displayName": "Received", "sortType": SortType.numeric, "alignment": Alignment.center},
		{"propName": "discrepancy", "displayName": "Discrepancy", "sortType": SortType.numeric, "alignment": Alignment.center},
		{"propName": "reconciled", "displayName": "Reconciled", "sortType": SortType.boolean, "alignment": Alignment.center}
		// {"propName": "status", "displayName": "Status", "sortType": SortType.text, "alignment": Alignment.center}
		// {"propName": "deleted", "displayName": "Deleted", "sortType": SortType.boolean, "alignment": Alignment.center}
	];

	private readonly _summaryTableHeadings: TableColumnHeading[] = [
		{"propName": undefined, "displayName": "Received From PRODA", "sortType": SortType.none, "alignment": Alignment.center },
		{"propName": undefined, "displayName": "Retaining Funds", "sortType": SortType.none, "alignment": Alignment.center },
		{"propName": undefined, "displayName": "Releasing Funds", "sortType": SortType.none, "alignment": Alignment.center },
		{"propName": undefined, "displayName": "Payment Batch Amount", "sortType": SortType.none, "alignment": Alignment.center }
	];

	private _dataLoaded: boolean = false;
	private _sourceData: ReconciliationReportData[] = undefined;
	private _summaryTable: Table<any> = new Table<any>(`reconciliationSummaryReport`, this._summaryTableHeadings);
	private _table: Table<ReconciliationReportData> = new Table<ReconciliationReportData>(`reconciliationReport`, this._tableHeadings);
	private _summaryData: SummaryData[] = undefined;

	public readonly model: ReconciliationReportModel = new ReconciliationReportModel();

	constructor(private reportsService: ReportsService) {
		super();

		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Reports', 'Reconciliation Report');
	}

	ngOnInit() {
		super.ngOnInit();

		if (this.user) {
			this.buildMenu();
			this.loadData();
		}
	}

	public get billStatuses(): InvoiceStatus[] {
		if (!this._dataLoaded) {
			return [];
		}

		return Array.from(this._sourceData.keys());
	}

	public get table(): Table<ReconciliationReportData> {
		return this._table;
	}

	public get summaryTable(): Table<any> {
		return this._summaryTable;
	}

	public async loadData() {

		if (this.model.selectedDate === undefined) {
			this.model.dateStr = DateUtils.toString( moment().subtract(1, 'days').toDate() );
		}

		this._dataLoaded = false;
		this._summaryData = undefined;
		this._table.sourceData = undefined;
		try {
			OverlayService.show();

			this._sourceData = await this.reportsService.reconciliationReport(this.model.selectedDate);
			this.calcSummaryData(this._sourceData);
			this._table.sourceData = StaticDataSource.from(this._sourceData);
			this._summaryTable.sourceData = StaticDataSource.from(this._summaryData);

			OverlayService.hide();
		}
		catch (e) {
			OverlayService.showError("Error", "Unable to display report");
			console.log(e);
		}
		finally {
			this._dataLoaded = true;
		}
	}

	private get summary(): any {
		return this._summaryData || [{
			"received": undefined,
			"released": undefined,
			"retained": undefined,
			"payment": undefined,
			"reportDate": undefined,
			"generationDate": undefined
		}];
	}

	public modelChanged() {
		DataExchangeService.set(this.model.dateStr, ReconciliationReportModel.storageKey);
		//console.log(this.model.dateStr);
		this.loadData();
	}


	private calcSummaryData(src: ReconciliationReportData[]): void {
		if (!src || src.length === 0) {
			this._summaryData = [{
				"received": 0,
				"retained": 0,
				"released": 0,
				"payment": 0,
				"reportDate": '',
				"generationDate": ''
			}];
			return;
		}

		this._summaryData = [{
			"received": src.reduce( (acc, cur) => { acc += cur.received; return acc; }, 0),
			"retained": src.reduce( (acc, cur) => { acc += cur.reconciled ? 0 : cur.received; return acc; }, 0),
			"released": src.reduce( (acc, cur) => { acc += cur.reconciled ? cur.previouslyHeld : 0; return acc; }, 0),
			"payment": src.reduce( (acc, cur) => { acc += cur.reconciled ? cur.previouslyHeld + cur.received : 0; return acc; }, 0),
			"reportDate": this.model.dateStr,
			"generationDate": DateUtils.toString(new Date(Date.now()))
		}];
	}

	get dataLoaded(): boolean {
		return this._dataLoaded;
	}

	public billStatus(status: InvoiceStatus): string {
		return InvoiceStatus.toString(status);
	}

	private buildMenu(): void {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.addHandler(
			'file-excel',
			'Download',
			() => { this.downloadReport(); },
			() => { return this.downloadDisabled(); }
		);
		menuBuilder.done();
	}

	private downloadDisabled(): boolean {
		return !this._dataLoaded || !this.table.hasData;
	}

	private downloadReport(): void {
		const downloader = new ReportDownloader(this._sourceData, this._summaryData[0]);
		downloader.downloadReport();
	}

}


class ReportDownloader {
	public constructor(private data: ReconciliationReportData[], private summary: SummaryData) {
	}

	private constructSummary(): string {
		const headers = ['Received From PRODA', 'Retaining Funds', 'Releasing Funds', 'Payment Batch Amount', 'Generation Date'];
		const data = [this.summary.received, this.summary.retained, this.summary.released, this.summary.payment, this.summary.generationDate];

		return headers.join(",") + "\n" + data.join(",") + "\n\n";
	}

	private constructDetailsCSV(): string {
		const headers = ['Report Date', 'Bill #', 'Client', 'Provider', 'Total', 'Held', 'Claimed', 'Received', 'Discrepancy', 'Reconciled', 'Status', 'Deleted'];
		const data = this.data.map( row => {
			return [
				this.summary.reportDate,
				row.invoiceNumber ? `"${row.invoiceNumber}"` : 'Not specified',
				`"${row.client}"`,
				`"${row.provider}"`,
				row.billTotal,
				row.previouslyHeld,
				row.claimed,
				row.received,
				row.discrepancy || '',
				row.reconciled ? 'true' : '',
				row.status || '',
				row.deleted ? 'true' : ''
			].join(",");
		})
		return headers.join(",") + "\n" + data.join("\n") + "\n";
	}

	private constructCSV(): string {
		return this.constructSummary() + this.constructDetailsCSV();
	}

	public downloadReport(): void {
		const csv = this.constructCSV();
		const file = new Blob([csv], { "type": "text/csv;charset=utf-8" });
		saveAs(file, "billing-entry-report.csv");
	}
}
