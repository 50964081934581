import { Component, OnInit } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { UserType, UserRole } from "@classes/user";
import { PermissionGroup, Permissions } from "@classes/permissions"
import { SuperUserService } from "@services/su.service";
import { OverlayService } from "@services/overlay.service";
import { ErrorUtils } from "@classes/errors";
import { Settings } from "@classes/settings";
import { MenuBuilder } from "@services/navmenu.service";

@Component({
	"styleUrls": ["./roleeditor.component.scss"],
	"templateUrl": "./roleeditor.component.html"
})
export class RoleEditorComponent extends PrivateComponent implements OnInit {

	protected allPermissions: PermissionGroup[] = [];
	protected roles: UserRole[] = [];
	protected adminGroupId: string;

	protected model: any = {
		"role": undefined,
		"permissions": {}
	};
	public dataLoaded: boolean = false;

	constructor(private suService: SuperUserService) {
		super();
		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('su', 'superuser');
	}

	ngOnInit() {
		super.ngOnInit();
		this.loadData();
	}

	private async loadData() {
		if (this.user) {

			const customPermissionsRole = Settings.instance.get('customPermissionsRole').value;

			this.allPermissions = Permissions.allPermissions();
			this.roles = await this.userService.getRoles();
			this.roles = this.roles.filter( role => role.id !== customPermissionsRole );
			this.dataLoaded = true;
			this.buildMenu();

			const suData = Settings.instance.get('superuser');
			if (suData) {
				this.adminGroupId = suData.group;
			}

		}
	}

	protected roleSelected() {

		this.suService.loadPermissions(this.model.role).then( permissions => {

			// Initialise the model as a map of all permissions as key
			this.model.permissions = this.allPermissions.reduce( (acc, group) => {

				group.permissions.forEach( (id, value) => {
					acc[id] = permissions.includes(id);
				});

				return acc;
			}, {});
		});

	}

	protected savePermissions() {
		const selectedPermissions = Object.keys(this.model.permissions).reduce( (acc, key) => {
			if (this.model.permissions[key]) {
				acc.push(key);
			}

			return acc;
		}, []);

		OverlayService.show();
		this.suService.savePermissions(this.model.role, selectedPermissions).then( () => {
			OverlayService.hide();
		}).catch( e => {
			OverlayService.hide();
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "An unknown error occurred"));
			console.log(e);
		})
	}

	private buildMenu(): void {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.done();
	}

	protected getPermissionValues(group: PermissionGroup): string[] {
		return Array.from( group.permissions.keys() );
	}

	protected getPermissionId(group: PermissionGroup, value: string): string {
		return group.permissions.get(value);
	}
}
