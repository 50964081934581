import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrivateComponent } from "@classes/private.component";
import { User, UserType } from "@classes/user";
import { AccountStatus } from "@classes/clientStatus";
import { Plan } from "@classes/plans";
import { PlanService } from "@services/plan.service";
import { MenuBuilder } from "@services/navmenu.service";
import { FileNote, FileNoteStatus } from "@classes/filenotes";
import { AttachedFile } from "@classes/files";
import { AttachmentTargetType, AttachmentTargetUtils  } from '@classes/attachments';
import { AttachmentsService } from "@services/attachments.service";
import { FileNotesService } from "@services/filenotes.service";
import { FileMimeTypes} from "@classes/files";
import { OverlayService } from "@services/overlay.service";
import { ErrorUtils } from "@classes/errors";
import { Utils } from "@classes/utils";
import { Table, TableColumnHeading, SortType, StaticDataSource } from "@classes/tables";
import { ClientUploadService } from "@services/clientupload.service";
import { config } from "../../../../config";
import moment from 'moment';

@Component({
	"selector": "participant-dashboard",
	"styleUrls": ["./participantdashboard.component.scss"],
	"templateUrl": "./participantdashboard.component.html"
})
export class ParticipantDashboardComponent extends PrivateComponent implements OnInit {

	@ViewChild('newNoteDialog')
	private noteDialog: TemplateRef<any>;

	@ViewChild('attachmentDialog')
	private attachmentDialog: TemplateRef<any>;

	private _isSelf: boolean = true;
	private _masqueradeUser: User = undefined;
	private _clientId: string = undefined;
	private _plans: Plan[] = undefined;
	private _filenotes: FileNote[] = undefined;
	private _fileMimeTypes: FileMimeTypes = new FileMimeTypes();
	private _showFileNotes: boolean = false;
	private _noteBeingEdited: FileNote = undefined;
	private _documents: AttachedFile[] = [];

	private readonly documentTableHeadings: TableColumnHeading[] = [
		{"propName": "filename", "displayName": "Name", "sortType": SortType.text },
		{"propName": "size", "displayName": "Size", "sortType": SortType.numeric },
		{"propName": null, "displayName": "Linked To", "sortType": SortType.none },
		{"propName": "dateAdded", "displayName": "Date Added", "sortType": SortType.date }
	];

	private documentsTable: Table<AttachedFile> = new Table('documentstable', this.documentTableHeadings);

	private readonly fileNoteStatus = {
		"open": FileNoteStatus.open,
		"completed": FileNoteStatus.completed,
		// "notStarted": FileNoteStatus.not_started
	};

	get showFileNotes(): boolean {
		return this._showFileNotes && !this.isSelf && this.isAdmin;
	}

	get masqueradeUser(): User {
		return this._masqueradeUser;
	}

	get masqueradeUserId(): string {
		return this._masqueradeUser ? this._masqueradeUser.id : "";
	}

	get isSelf(): boolean {
		return this._isSelf;
	}

	get isParticipant(): boolean {
		return this._masqueradeUser ? this._masqueradeUser.accountType === UserType.Participant : false;
	}

	get isSupportCoordinator(): boolean {
		return this._masqueradeUser ? this._masqueradeUser.accountType === UserType.SupportCoordinator : false;
	}

	get isAdmin(): boolean {
		return this.user.accountType === UserType.Admin;
	}

	get masqueradeUserName(): string {
		return this._masqueradeUser ? `${this._masqueradeUser.firstName} ${this._masqueradeUser.lastName}` : '';
	}

	get masqueradeUserStatus(): string {
		return this._masqueradeUser && this._masqueradeUser.status && this._masqueradeUser.status.accountStatus ? `${ AccountStatus.toString(this._masqueradeUser.status.accountStatus) }` : 'N/A';
	}
	

	get ndisNumber(): string {
		return this._masqueradeUser ? this._masqueradeUser.ndisNumber : '';
	}

	get currentUser(): User {
		return this._masqueradeUser;
	}

	get clientId(): string {
		return this._clientId;
	}

	get plans(): Plan[] {
		return this._plans;
	}

	get isPace(): boolean {
		if(this._plans && this._plans.length){
			return this._plans.filter( plan => plan.pace ).length > 0;
		}
		return false;
	}

	get fileNotes(): FileNote[] {
		return this._filenotes || [];
	}

	public get state(): string {
		return this._masqueradeUser.stateAndRegion;
	}

	constructor(
		private clientUploadService: ClientUploadService,
		private planService: PlanService,
		private route: ActivatedRoute,
		private fileNotesService: FileNotesService,
		private attachmentsService: AttachmentsService) {
		super();
	}

	private loadUser(userId: string): Promise<void> {
		return this.userService.loadUser(userId).then( user => {
			this._masqueradeUser = user;
			this.mruService.push(user);
		});
	}

	private loadPlans(userId: string): Promise<void> {
		return this.planService.listPlans(userId).then( plans => {
			this._plans = plans;
		});
	}

	private loadFileNotes(userId: string): Promise<void> {
		return this.fileNotesService.listNotes(userId).then( notes => {
			this._filenotes = notes;
		})
	}

	private loadData(clientId?: string): void {

		if (!this.user || !this.user.id) {
			return;
		}

		let promises = [];

		if (!this.isSelf) {
			promises.push( this.loadUser(clientId) );
		}

		if (!this.isSelf && this.isAdmin) {
			promises.push( this.loadFileNotes(clientId) );
		}

		if (this.isSelf && this.user.accountType === UserType.Participant) {
			promises.push( this.loadPlans( this.user.id ) );
		}
		else if (clientId) {
			promises.push( this.loadPlans( clientId ) );
		}

		Promise.all(promises).then( whatever => {
			this.buildMenu();
		});
	}

	ngOnInit() {
		super.ngOnInit();

		this.loadData();

		this.route.params.subscribe( params => {

			const userId = params.userId || this.user.id;
			this._isSelf = userId === this.user.id;
			this._clientId = userId;

			if (this.user.accountType === UserType.Admin) {
				if (!this.hasPermission('Account Details', 'View Other Account Details')) {
					this.router.navigate(["/accessdenied"]);
					return;
				}
			}

			this.loadData(params.userId);
		});
	}

	// Stuff for testing client uploads API for mobile app
	public get showTimSection(): boolean {
		return ['dev', 'local'].includes(config.stage);
	}


	public reloadNotes(delayMilliseconds: number = 0): Promise<void> {
		OverlayService.show();
		return new Promise( (resolve, reject) => {

			this.fileNotesService.invalidateCacheForUser(this._masqueradeUser.id);
			setTimeout(() => {
				this.loadFileNotes(this._masqueradeUser.id).then( () => {
					OverlayService.hide();
					resolve();
				});
			}, delayMilliseconds);
		});
	}

	public async listInvoices() {
		OverlayService.show();
		try {
			const response = await this.clientUploadService.load();
			//console.log(response);
		}
		catch (e) {
			console.log(e);
		}
		finally {
			OverlayService.hide();
		}
	}

	public async uploadInvoice(files: File[]) {
		if (!files || files.length === 0) {
			return;
		}

		OverlayService.show("Uploading file...");
		try {
			const file = files[0];
			await this.clientUploadService.testUpload(file, true);
		}
		catch (e) {
			console.log(e);
		}
		finally {
			OverlayService.hide();
		}
	}

	private buildMenu() {
		const menuBuilder = new MenuBuilder();
		//console.log(this.isSelf, this.clientId, this.masqueradeUserId);
		if (this.isSelf) {
			menuBuilder.addRoute("user", "Account Details", "/account");
			menuBuilder.addRoute("lock", "Change Password", "/password/change");
			menuBuilder.addHandler("sign-out-alt", "Log Out", () => { this.confirmLogout(); } );
			menuBuilder.addRoute("coins", "Provider Spend", `/${this.clientId}/providerspend`);
		}
		else {
			menuBuilder.addHome();
			menuBuilder.addBackButton();

			menuBuilder.addRoute("cog", "Account Details", `/account/${this.masqueradeUserId}`);
			if (this.isAdmin) {
				// menuBuilder.addRoute("link", "Support Coordinators", `/supportcoordinators/${this.masqueradeUserId}`);
				menuBuilder.addHandler("edit", "File Notes", () => { this.toggleFileNotes(); });
			}
			menuBuilder.addRoute("coins", "Provider Spend", `/${this.masqueradeUserId}/providerspend`);
		}
		menuBuilder.done();
	}

	private toggleFileNotes() {
		this._showFileNotes = !this._showFileNotes
	}

	private getFileIcon(file: AttachedFile): string {
		return this._fileMimeTypes.getIcon(file.mimeType);
	}

	private isFutureDate(date: Date): boolean {
		return moment(date).startOf('day').isSameOrAfter( moment().startOf('day') );
	}

	private isPastDate(date: Date): boolean {
		return moment(date).startOf('day').isSameOrBefore( moment().startOf('day') );
	}

	private dateBetweenDays(date: Date, numDaysFrom: number, numDaysTo: number): boolean {
		const cutoffStart = moment().add(numDaysFrom, 'days').startOf('day');
		const cutoffEnd = moment().add(numDaysTo, 'days').startOf('day');
		return moment(date).isBetween(cutoffStart, cutoffEnd, null, '[]');
	}

	private showNewNoteDialog() {
		this._noteBeingEdited = undefined;
		OverlayService.showTemplate(this.noteDialog);
	}

	private editNote(note: FileNote): void {
		this._noteBeingEdited = note;
		OverlayService.showTemplate(this.noteDialog);
	}

	private async deleteNote(note: FileNote) {
		OverlayService.hide();
		OverlayService.show(`Deleting note${Utils.ellipsis}`);
		try {
			await this.fileNotesService.deleteNote(note);
			const idx = this._filenotes.findIndex(item => item.id === note.id);
			if (idx >= 0) {
				this._filenotes.splice(idx, 1);
			}
			OverlayService.hide();
		}
		catch (e) {
			OverlayService.hide();
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "An unknown error occurred"));
		};
	}

	private conformDeleteNote(note: FileNote): void {
		OverlayService.showDialog("Confirm", "Are you sure you want to delete this note?", [{
			"text": "No - don't delete",
			"handler": () => { OverlayService.hide(); }
		}, {
			"text": "Yes - delete",
			"handler": () => { this.deleteNote(note); }
		}]);
	}

	private showAttachmentDialog(note: FileNote): void {
		const targets = [
			AttachmentTargetUtils.target(this._clientId, AttachmentTargetType.client),
			AttachmentTargetUtils.target(note.id, AttachmentTargetType.note)
		];

		OverlayService.showTemplate(this.attachmentDialog, {"targets": targets, "files": note.attachments});
	}

	private async saveAttachments($event: any) {
		if ($event.fileManager && $event.targets && $event.targets.length > 0) {
			OverlayService.show(`Saving${Utils.ellipsis}`);
			try {
				const result = await this.attachmentsService.saveAttachments($event.targets, $event.fileManager);

				result.savedFiles.forEach(file => {
					this._documents.push(file);
				});

				result.deletedFiles.forEach(fileId => {
					const idx = this._documents.findIndex( item => item.id === fileId );
					if (idx >= 0) {
						this._documents.splice(idx, 1);
					}
				});

				this.documentsTable.sourceData = StaticDataSource.from(this._documents);
				OverlayService.hide();
			}
			catch (e) {
				console.log(e);
				OverlayService.hide();
				OverlayService.showError("Error", "Unable to save attachment");
			}
		}
	}

	private saveNote(note: FileNote): void {
		OverlayService.show();
		this.fileNotesService.saveNote( note ).then( _ => {

			// Will be quick if the cache is still valid, otherwise a round-trip to the server is required
			return this.loadFileNotes(this.clientId);

		}).then( () => {

			OverlayService.hide();

		}).catch( e => {

			OverlayService.hide();
			console.log(e);
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "An unknown error occurred"));

		});
	}

	private confirmLogout(): void {
		OverlayService.showDialog("Confirm", "Are you sure you want to log out?", [{
			"text": "Cancel",
			"handler": () => { OverlayService.hide(); }
		}, {
			"text": "Log out",
			"handler": () => { OverlayService.hide(); this.userService.logout(); }
		}])
	}
}
