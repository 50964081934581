import { Component, OnInit, Input, ViewChild, TemplateRef, ViewEncapsulation } from '@angular/core';

import { UserAccountService } from "@services/accounts.service";
import { AttachmentsService } from "@services/attachments.service";
import { LinkedUserService } from "@services/linkedusers.service";
import { FileNotesService } from "@services/filenotes.service";

import { PrivateComponent } from "@classes/private.component";
import { AttachmentTargetType, AttachmentTargetUtils  } from '@classes/attachments';
import { ErrorUtils } from "@classes/errors";
import { OverlayService } from "@services/overlay.service";
import { User, UserAccount, RelationshipType, UserRelationship } from '@classes/user';
import { RelationshipDescription } from "@classes/relationshipDescription";
import { Table, TableColumnHeading, SortType, StaticDataSource, Alignment } from "@classes/tables";
import { Utils } from "@classes/utils";

@Component({
	"selector": "nominees",
	"styleUrls": ["./nominees.component.scss"],
	"templateUrl": "./nominees.component.html",
	"encapsulation": ViewEncapsulation.None // Required to allow style change on the ngxTypeahead child component that's embedded in this one
})
export class NomineesComponent extends PrivateComponent implements OnInit {

	private static dateFormat = 'DDMMYYYY';

	@ViewChild('editNomineeDialog') private editNomineeDialog: TemplateRef<any>;
	@ViewChild('authDialog') private authDialog: TemplateRef<any>;
	@ViewChild('removeAuthDialog') private removeAuthDialog: TemplateRef<any>;

	private _clientId: string;
	private _client: User;

	private _nominees: UserRelationship[] = [];
	private _dataLoaded: boolean = false;
	private _error: boolean = false;

	private userList: UserAccount[] = [];

	protected readonly relationshipType = {
		"nominee": RelationshipType.nominee,
		"authorised_representative": RelationshipType.authorised_representative,
		"support_coordinator": RelationshipType.support_coordinator
	};

	private readonly tableHeadings: TableColumnHeading[] = [
		{"propName": undefined, "displayName": "Primary", "sortType": SortType.none },
		{"propName": undefined, "displayName": "Nappa", "sortType": SortType.none, "alignment": Alignment.center},
		{"propName": undefined, "displayName": "Statements", "sortType": SortType.none, "alignment": Alignment.center},
		{"propName": "name", "displayName": "Name", "sortType": SortType.text },
		{"propName": "email", "displayName": "Email", "sortType": SortType.text },
		{"propName": "phone", "displayName": "Phone", "sortType": SortType.text },
		{"propName": "description", "displayName": "Relationship", "sortType": SortType.text },
		// {"propName": "dateFrom", "displayName": "Date From", "sortType": SortType.date },
		{"propName": "dateTo", "displayName": "Date To", "sortType": SortType.date },
		{"propName": undefined, "displayName": "", "sortType": SortType.none },
	];

	table: Table<UserRelationship> = new Table('nominees', this.tableHeadings);

	get dataLoaded(): boolean {
		return this._dataLoaded;
	}

	get error(): boolean {
		return this._error;
	}

	@Input()
	set clientId(value: string) {
		if (value !== undefined) {
			this._clientId = value;
			this.loadData();
		}
	}

	constructor(
		private linkedUserService: LinkedUserService,
		private accountsService: UserAccountService,
		private attachmentsService: AttachmentsService,
		private fileNotesService: FileNotesService) {
		super();
	}

	private async loadData() {
		this._dataLoaded = false;
		try {
			const promises: Promise<any>[] = [
				this.userService.loadUser(this._clientId),
				this.linkedUserService.getNominees(this._clientId)/*,
				this.accountsService.getAccountsThatCanBeNominees()*/
			];

			const data = await Promise.all(promises);
			this._client = data[0];
			this._nominees = data[1] || [];
			/*this.userList = data[2] || [];*/

			this.buildTableSourceData();
			this._error = false;
		}
		catch (e) {
			console.log(e);
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "An unknown error occurred"));
			this._error = true;
		}
		this._dataLoaded = true;
	}

	private buildTableSourceData(): void {
		this.table.sourceData = StaticDataSource.from(this._nominees);
	}

	protected addNominee(): void {
		this.router.navigate(['/relationship/', this._clientId, 'new']);
	}

	protected editNominee(relationship: UserRelationship): void {
		this.router.navigate(['/relationship/', this._clientId, relationship.userId]);
	}

	protected closeDialog(): void {
		OverlayService.hide();
	}

	protected async saveNominee(nominee: UserAccount, relationship: UserRelationship) {
		// OverlayService.show();
		// try {
		// 	await this.linkedUserService.save(nominee, relationship);
		// 	await this.loadData();
		// 	OverlayService.hide();
		// }
		// catch (e) {
		// 	console.log(e);
		// 	OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "An unknown error occurred"));
		// }
	}

	protected canSave(nominee: UserAccount, relationship: UserRelationship): boolean {
		return [nominee.firstName, nominee.lastName].map( Utils.trimString ).every( item => !!item ) && relationship.description !== undefined;
	}

	protected confirmDeleteNominee(relationship: UserRelationship): void {
		OverlayService.showDialog("Confirm Delete", `Are you sure you want to delete ${relationship.firstName} ${relationship.lastName} from the list of nominees?`, [{
			"text": "No - Don't delete",
			"handler": () => { OverlayService.hide(); }
		}, {
			"text": "Confirm - Delete",
			"handler": () => { this.unlink(relationship); }
		}]);
	}

	private async unlink(relationship: UserRelationship) {
		OverlayService.show();
		try {
			await this.linkedUserService.unlink(relationship);
			await this.loadData();
			OverlayService.hide();
		}
		catch (e) {
			console.log(e);
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "An unknown error occurred"));
		}
	}

	protected userSelected(context: UserAccount, selectedUser: UserAccount): void {
		context.id = selectedUser.id;
		context.firstName = selectedUser.firstName;
		context.lastName = selectedUser.lastName;
		context.name = selectedUser.name || `${context.firstName} ${context.lastName}`;
		context.email = selectedUser.email;
		context.phone = selectedUser.phone;
	}

	protected getStringValue(value: string): string {
		return Utils.optionalString(value, 'Not specified');
	}

	protected showAuthDialog(relationship: UserRelationship): void {
		if (!relationship.email) {
			OverlayService.showError("Error", "Nominee requires a valid and unique email address before they can be assigned account access");
			return;
		}

		const context = {
			"relationship": relationship,
			"attachmentData": {
				"files": [...relationship.attachments],
				"targets": [
					AttachmentTargetUtils.target(this._clientId, AttachmentTargetType.client),
					AttachmentTargetUtils.target(relationship.userId, AttachmentTargetType.linked_user)
				],
				"additionalData": {
					"authorised": relationship.type === RelationshipType.authorised_representative,
					"originalAuthState": relationship.type === RelationshipType.authorised_representative,
					"relationship": relationship
				}
			}
		};

		OverlayService.showTemplate(this.authDialog, context);
	}

	protected get clientName(): string {
		return `${this._client.firstName} ${this._client.lastName}`;
	}

	protected get clientNameApostrophe(): string {
		return this._client.lastName.slice(-1) === 's' ? "'" : "'s";
	}

	protected async saveConsentForm($event) {
		if ($event.fileManager && $event.targets && $event.targets.length > 0 && $event.additionalData.relationship) {
			OverlayService.show(`Saving${Utils.ellipsis}`);
			try {

				const result = await this.attachmentsService.saveAttachments($event.targets, $event.fileManager);

				await this.linkedUserService.authorise($event.additionalData.relationship, $event.additionalData.authorised);
				await this.loadData();
				this.fileNotesService.invalidateCacheForUser(this._clientId);

				if(!$event.additionalData.authorised && $event.additionalData.relationship.receiveStatements) {
					this.confirmDisableStatements($event.additionalData.relationship.userId);
				}
			}
			catch (e) {
				console.log(e);
				OverlayService.hide();
				OverlayService.showError("Error", "Unable to save attachment");
			}
		}
	}

	private async disableStatements(userId: string) {
		OverlayService.show(`Saving${Utils.ellipsis}`);
		let relationship = this._nominees[this._nominees.findIndex((nominee) => nominee.userId === userId)];
		relationship.receiveStatements = false;
		try {
			await this.linkedUserService.save(relationship);
			await this.loadData();
			OverlayService.hide();
		}
		catch (e) {
			console.log(e);
			OverlayService.hide();
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "An unknown error occurred"));
		}
	}

	public confirmDisableStatements(userId: string) {
		OverlayService.showDialog("Stop this nominee from receiving statements?", `Nappa access has been disabled for this nominee, would you also like to stop them from receiving statements?`, [
			{"text": "No", "handler": () => {
				OverlayService.hide();
			}},
			{"text": "Yes", "handler": () => {
				OverlayService.hide();
				this.disableStatements(userId);
			}}
		]);
	}

	public allDescriptions: () => RelationshipDescription[] = RelationshipDescription.allValues;
	public descriptionName: (description: RelationshipDescription) => string = RelationshipDescription.toString;
	
	public getBSB(nominee: UserAccount): string {
		return nominee.bankInfo && nominee.bankInfo.bsb ? nominee.bankInfo.bsb : '';
	}

	public getAccountNumber(nominee: UserAccount): string {
		return nominee.bankInfo && nominee.bankInfo.accountNumber ? nominee.bankInfo.accountNumber : '';
	}
}
