import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OverlayService } from '@services/overlay.service';
import { PrivateComponent } from "@classes/private.component";
import { UserType, UserAccount } from "@classes/user";
import { Address, AddressClass } from "@classes/address";
import { MenuBuilder } from "@services/navmenu.service";
import { Provider, ProviderService } from '@services/provider.service';
import { FileNotesService } from "@services/filenotes.service";
import { FileNote } from "@classes/filenotes";
import { BankInformation } from "@classes/bankinfo";
import { Utils } from "@classes/utils";
import { ErrorUtils } from "@classes/errors";
import { PaginatedTable, TableColumnHeading, SortType, Alignment, StaticDataSource } from "@classes/tables";
import { ABNValidator } from "@classes/abnValidator";
import { FileNotesComponent } from '@components/notes/notes.component';
import { InvoiceHeader } from '@classes/invoices';
import { ServiceAgreement } from "@classes/serviceAgreement";
import { IconName } from '@fortawesome/free-solid-svg-icons';
import { DefaultValueAccessor } from '@angular/forms';
import { isString } from 'lodash';

enum Tabs {
	provider, notes, contact, banking, address, clients, bills
}

@Component({
	"styleUrls": ["./provider.component.scss"],
	"templateUrl": "./provider.component.html"
})
export class ProviderComponent extends PrivateComponent implements OnInit {

	@ViewChild(FileNotesComponent) private notesComponent: FileNotesComponent;
	@ViewChild('confirmDeleteProviderDialog') private confirmDeleteDialog: TemplateRef<any>;

	public readonly maxNoteContentChars = 140;

	private _provider: Provider;
	private _bank: BankInformation;
	private _providerId: string;
	private _notes: FileNote[] = [];
	private _dataLoaded: boolean = false;
	private _tabs= {
		"address": 1
	};

	private _linkedClients: UserAccount[] = [];
	public get linkedClients(): UserAccount[] {
		return this._linkedClients;
	}

	// Table headings for list of plans
	private readonly tableHeadings: TableColumnHeading[] = [
		{"propName": "firstName", "displayName": "First Name", "sortType": SortType.text },
		{"propName": "lastName", "displayName": "Last Name", "sortType": SortType.text },
		{"propName": "ndisNumber", "displayName": "NDIS Number", "sortType": SortType.text },
		{"propName": undefined, "displayName": "Delete", "sortType": SortType.none, "alignment": Alignment.right }
	];

	linkedClientsTable: PaginatedTable<UserAccount> = new PaginatedTable('linkedClients', this.tableHeadings);


	private _clients: any[] = [];
	public get clients(): InvoiceHeader[] {
		return this._clients;
	}

	readonly states = ['', 'ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];

	private _currentTab: Tabs = Tabs.provider;
	public get currentTab(): Tabs { return this._currentTab; }

	public readonly tab = {
		"provider": Tabs.provider,
		"notes": Tabs.notes,
		"contact": Tabs.contact,
		// "bank": Tabs.banking,
		"banking": Tabs.banking,
		"address": Tabs.address,
		"clients": Tabs.clients,
		"bills": Tabs.bills
	};

	public get tabValues(): Tabs[] {
		return [Tabs.provider, Tabs.notes, Tabs.contact, Tabs.banking, Tabs.address, Tabs.clients, Tabs.bills];
	}

	protected checkPermission(tab: Tabs): boolean {
		switch (tab) {
			case Tabs.banking:
				return this.hasPermission('Providers', 'View Bank Details');

			default:
				return true;
		}
	}

	protected toIcon(icon: string): IconName {
		return icon as IconName;
	}

	public readonly tabText = new Map<Tabs, string>([
		[Tabs.provider, "Provider"],
		[Tabs.notes, "Notes"],
		[Tabs.contact, "Contact"],
		[Tabs.address, "Address"],
		[Tabs.banking, "Banking"],
		[Tabs.clients, "Linked Clients"],
		[Tabs.bills, "Bills"]
	]);

	public changeTab(tab: Tabs): boolean {
		this._currentTab = tab;
		return false;
	}

	get tabs(): any {
		return this._tabs;
	}

	get syncSuccess(): boolean {
		if(this.provider.sync_errors.success !== undefined) {
			return this.provider.sync_errors.success;
		} else if (this.provider.sync_errors) {
			return this.provider.sync_errors == "Sync Success";
		} else {
			return false;
		}
	}

	get provider(): Provider {
		return this._provider;
	}

	get bank(): BankInformation {
		return this._bank;
	}

	get postalAddress(): Address {
		this._provider.postalAddress = this._provider.postalAddress || AddressClass.blank();
		return this._provider.postalAddress;
	}

	get streetAddress(): Address {
		this._provider.streetAddress = this._provider.streetAddress || AddressClass.blank();
		return this._provider.streetAddress;
	}

	get dataLoaded(): boolean {
		return this._dataLoaded;
	}

	get isAdmin(): boolean {
		return this.user ? this.user.accountType === UserType.Admin : false;
	}

	get canEdit(): boolean {
		return !(this.provider.deleted || this.provider.id == "ca90a10e-b1e1-4019-89a8-1c2a02b6d3b5");
	}

	setTab(value: number): void {
		this._tabs.address = value;
	}

	get canSave(): boolean {
		return [
			this.emailValid,
			this.postcodeValid,
			this.postalPostcodeValid,
			this.phoneValid
		].every(Boolean);
	}

	get emailValid(): boolean {
		if(!this.provider.email) return true;
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return regex.test(this.provider.email.trim());
	}

	get postcodeValid(): boolean {
		if(!this.provider.streetAddress || !this.provider.streetAddress.postcode) return true;
		const regex = /^\d{4}$/;
		return regex.test(this.provider.streetAddress.postcode.trim());
	}

	get postalPostcodeValid(): boolean {
		if(!this.provider.postalAddress || !this.provider.postalAddress.postcode) return true;
		const regex = /^\d{4}$/;
		return regex.test(this.provider.postalAddress.postcode.trim());
	}

	get phoneValid(): boolean {
		if(!this.provider.phone) return true;
		const regex = /^[0-9 ()+]*$/;
		return regex.test(this.provider.phone.trim());
	}

	private buildMenu(): void {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.done();
	}

	public async loadNotes(forceReload: boolean = false) {
		this._notes = await this.notesService.providerNotes(this._providerId, forceReload);
	}
	public get stickyNotes(): FileNote[] {
		return this._notes.filter( note => note.sticky );
	}
	private async loadProvider(): Promise<void> {

		try {
			const result = await this.providerService.getProvider(this._providerId);
			await this.loadNotes();
			this._provider = result;
			this._bank = {
				"authcode": undefined,
				"authcodeRequested": false,
				"isUnlocked": false,
				"bankAccountName": result.bankAccountName,
				"bankAccountNumber": result.bankAccountNumber,
				"bankAccountBSB": result.bankAccountBSB,
				"bankAccountParticulars": result.bankAccountParticulars
			};
		}
		catch (e) {
			console.log(e);
		}
	}

	private async loadLinkedClients(): Promise<void> {
		try {
			this._linkedClients = await this.providerService.loadLinkedClients(this._providerId);
			this.linkedClientsTable.sourceData = StaticDataSource.from(this._linkedClients);
		}
		catch (e) {
			console.log(e);
			return Promise.resolve();
		}
	}

	public get fileNotes(): FileNote[] {
		return this._notes;
	}

	private async loadData(): Promise<void> {
		this._dataLoaded = false;
		if (!this.user) {
			return;
		}

		OverlayService.show();

		const promises = [
			this.loadProvider(),
			this.loadLinkedClients()//,
			//this.loadFilteredBills()
		];

		try {
			await Promise.all(promises);
			this._dataLoaded = true;
			OverlayService.hide();
		}
		catch (e) {
			OverlayService.hide();
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e) || "An unknown error occurred");
		}
	}

	private createNewProvider() {
		this._provider = this.providerService.newProvider();
		this._bank = {
			"authcode": undefined,
			"authcodeRequested": false,
			"isUnlocked": false,
			"bankAccountName": undefined,
			"bankAccountNumber": undefined,
			"bankAccountBSB": undefined,
			"bankAccountParticulars": undefined
		};
	}

	constructor(private providerService: ProviderService, private route: ActivatedRoute, private notesService: FileNotesService) {
		super();
		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Providers', 'View Providers');

		const original = DefaultValueAccessor.prototype.registerOnChange;

		DefaultValueAccessor.prototype.registerOnChange = function (fn) {
			return original.call(this, value => {
				const trimmed = isString(value) ? value.trim() : value;
				return fn(trimmed);
			});
		};
	}

	ngOnInit() {
		super.ngOnInit();
		this.buildMenu();

		this.route.params.subscribe( async params => {

			if (params.id === "new") {
				this.createNewProvider();
			}
			else {

				// If a provider Id has been specified on the path, load it
				this._providerId = params.id;
				if (this._providerId !== undefined) {
					await this.loadData();

					if (params.noteId) {

						const note = this._notes.find( note => note.id === params.noteId);
						if (note) {
							this.showNote(note);
						}
					}
				}
			}
		});

	}

	public save() {
		if (!this._bank.isUnlocked) {
			delete this._provider.bankAccountName;
			delete this._provider.bankAccountNumber;
			delete this._provider.bankAccountBSB;
			delete this._provider.bankAccountParticulars;
		}
		else {
			this._provider.bankAccountName = this._bank.bankAccountName;
			this._provider.bankAccountNumber = this._bank.bankAccountNumber;
			this._provider.bankAccountBSB = this._bank.bankAccountBSB;
			this._provider.bankAccountParticulars = this._bank.bankAccountParticulars;
		}

		if(this._provider.abn === "") {
			this._provider.abn = null;
		}

		if(this._provider.acn === "") {
			this._provider.acn = null;
		}

		OverlayService.show(`Saving${Utils.ellipsis}`);
		this.providerService.save(this._provider, this.bank.authcode).then( result => {
			if(this._providerId){
				this.loadData().then(result => {
					OverlayService.hide();
				});
			} else {
				this.router.navigate(["providers"]);
			}
		}).catch( err => {
			OverlayService.hide();
			const errorMessage = err.errorMessage || "Unable to save provider";
			OverlayService.showError("Error", errorMessage.replace(/^\[\d+\]\s/, ''));

		});
	}

	public validateAddress(){

		let {address1, address2, suburb, state,	postcode} = this._provider.postalAddress;
		const postalAddressValid = (address1 || address2) ? (suburb && state && postcode) : (!suburb && !state && !postcode);

		({address1, address2, suburb, state, postcode} = this._provider.streetAddress);
		const streetAddressValid = (address1 || address2) ? (suburb && state && postcode) : (!suburb && !state && !postcode);

		if (postalAddressValid && streetAddressValid) {
			this.save();
		} else {
			OverlayService.showError("Error", `${(!postalAddressValid ? "<p>Unable to save Postal Address, please add suburb, state, and postcode.</p>":"")} ${(!streetAddressValid ? "<p>Unable to save Street Address, please add suburb, state, and postcode.</p>":"")}`);
		}
	}

	public requestAuthCode() {

		OverlayService.show();
		this.providerService.requestAuthCode(this._providerId).then( result => {
			this._bank.authcodeRequested = result;
			OverlayService.hide();

			if (!result) {
				OverlayService.showError("Error", "Unable to change bank details for this provider");
			}
			else {
				OverlayService.showSuccess("Unlock code generated", "Please see your authorised staff member to obtain the unlock code");
			}
		}).catch( err => {

			OverlayService.hide();
			const errorMessage = err.errorMessage || "Unable to change bank details for this provider";
			OverlayService.showError("Error", errorMessage.replace(/^\[\d+\]\s/, ''));

		});
	}

	public unlockBankSection() {
		OverlayService.show();
		this.providerService.unlock(this._providerId, this._bank.authcode).then( result => {
			OverlayService.hide();
			this._bank.isUnlocked = result;

			if (!result) {
				OverlayService.showError("Error", "Invalid unlock code provided");
			}
			else {
				OverlayService.showSuccess("Success", "Banking details unlocked");
			}
		}).catch( err => {
			OverlayService.hide();
			OverlayService.showError("Error", err.message || "Unable to unlock banking details for this provider");
		});
	}

	public unlinkClient(clientId: string): boolean {
		this._linkedClients = this._linkedClients.filter( item => item.id !== clientId );
		this.linkedClientsTable.sourceData = StaticDataSource.from(this._linkedClients);
		this.providerService.unlinkClient(this._providerId, clientId);
		return false;
	}

	public get abnValid(): boolean {
		if (!this._provider || !this._provider.abn) {
			return true;
		}

		const validator = new ABNValidator();
		return validator.validate(this._provider.abn);
	}


	public showNote(note: FileNote) {
		setTimeout( () => {
			this.changeTab(Tabs.notes);
			setTimeout( () => { this.notesComponent.currentNote = note; }, 0);
		}, 0);
	}

	public async confirmDeleteProvider() {

		OverlayService.show();
		try {

			const serviceAgreements = await this.providerService.serviceAgreementsForProvider(this._provider.id);

			// Find any service agreement from each client (basically getting a distinct client list)
			const map = serviceAgreements.reduce( (acc, cur) => {
				acc.set(cur.client.id, cur);
				return acc;
			}, new Map<string, ServiceAgreement>());

			OverlayService.showTemplate(this.confirmDeleteDialog, Array.from(map.values()));

		}
		catch (e) {
			OverlayService.hide();
			console.log(e);
		}
	}

	public confirmDeleteProviderBankDetails(){
		OverlayService.showDialog("Confirm", "<p>Are you sure you want to delete these bank details?</p><p><strong>Note:</strong> If the Bank Details you are deleting are also in Xero, <br/>please remove them from Xero manually, as they will not <br/>automatically be removed when the next sync occurs.</p>", [{
			"text": "Don't Delete",
			"handler": OverlayService.hide
		}, {
			"text": "Delete",
			"handler": this.deleteProviderBankDetails.bind(this)
		}]);
	}

	public async deleteProviderBankDetails(){
		OverlayService.show("Deleting");
		try {
			await this.providerService.deleteBankInfo(this._providerId, this._bank);
			await this.loadData();
			OverlayService.hide();
			this._bank.authcode = null;
			this._bank.isUnlocked = false;
			this._bank.authcodeRequested = false;
		}
		catch (e) {
			console.log(e);
			OverlayService.hide();
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "Unable to delete bank details"));
		}
	}

	public closeDialog() {
		OverlayService.hide();
	}

	public async deleteProvider() {
		const defaultErrorMessage = "Unable to delete provider";
		OverlayService.show(`Deleting provider${Utils.ellipsis}`);
		try {
			const success = await this.providerService.deleteProvider(this._provider.id);
			this._provider.deleted = true;
			if (!success) {
				throw new Error(defaultErrorMessage);
			}

			OverlayService.hide();
			OverlayService.showSuccess("Success", "Provider deleted");
		}
		catch (e) {
			OverlayService.hide();
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, defaultErrorMessage));
		}
	}

	public abnPaste(event: ClipboardEvent) {
		const text = event.clipboardData.getData('text');
		const tidied = text.replace(/\s/g, '');
		event.preventDefault();
		this._provider.abn = tidied;
		// if (/^\d{11}$/.test(tidied)) {
		// 	event.preventDefault();
		// 	this._provider.abn = tidied;
		// }
	}

	protected bsbPaste(event: ClipboardEvent) {
		let stripped = event.clipboardData.getData('text');
		stripped = stripped.replace(/\D/g,'').substring(0, 6);
		event.preventDefault()
		this.bank.bankAccountBSB = stripped;
	}

	protected accPaste(event: ClipboardEvent) {
		let stripped = event.clipboardData.getData('text');
		stripped = stripped.replace(/\D/g,'').substring(0, 9);
		event.preventDefault()
		this.bank.bankAccountNumber = stripped;
	}
}
