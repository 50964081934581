import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { UserAccount, UserType } from '@classes/user';
import { FileNote } from "@classes/filenotes";
import { FileNotesService, DateFilter } from "@services/filenotes.service";
import { OverlayService } from "@services/overlay.service";
import { StorageService } from "@services/storage.service";
import { UserAccountService } from "@services/accounts.service";
import { Utils } from "@classes/utils";
import moment from 'moment';

@Component({
	"selector": "filenotes-widget",
	"styleUrls": ["./filenotes.component.scss"],
	"templateUrl": "./filenotes.component.html"
})
export class FileNotesWidgetComponent extends PrivateComponent implements OnInit {

	@ViewChild('filterDialog')
	private filterDialog: TemplateRef<any>;

	protected _userFilter: string = undefined;
	private _userFilterName: string = undefined;
	private _dataLoaded: boolean = false;
	private _src: FileNote[] = [];
	private _canChangeUser: boolean = true;
	protected _accounts: UserAccount[] = [];

	get canChangeUser(): boolean {
		return this._canChangeUser;
	}

	protected dateFilters: DateFilter[] = DateFilter.allValues();
	protected dateFilter: DateFilter = DateFilter.today;

	protected dateFilterDescription(value: DateFilter): string {
		return DateFilter.toString(value);
	}

	get dataLoaded(): boolean { return this._dataLoaded; }

	get myAlerts(): boolean {
		return !this._canChangeUser;
	}

	private myNotes(): FileNote[] {
		return this._src.filter( note => note.assignedTo.id === this.user.id );
	}

	private otherNotes(): FileNote[] {
		if (!!this._userFilter) {
			return this._src.filter( note => note.assignedTo.id === this._userFilter );
		}
		else {
			return this._src.filter( note => note.assignedTo.id !== this.user.id );
		}
	}

	private filterByUser(): FileNote[] {
		return this.canChangeUser ? this.otherNotes() : this.myNotes();
	}

	private isFileNote(item: FileNote | Date): item is FileNote {
		return (<FileNote>item).id !== undefined;
	}

	private isToday(item: FileNote | Date): boolean {

		if (!item) {
			return false;
		}

		if (this.isFileNote(item)) {
			return this.isToday(item.eventDate) || this.isToday(item.reminder);
		}
		else {
			return moment(item).startOf("day").isSame(moment().startOf("day"));
		}
	}

	private isThisWeek(item: FileNote | Date): boolean {
		if (!item) {
			return false;
		}

		if (this.isFileNote(item)) {
			return this.isThisWeek(item.eventDate) || this.isThisWeek(item.reminder);
		}
		else {
			return moment(item).startOf("week").isSame(moment().startOf("week"));
		}
	}

	private isOverdue(item: FileNote | Date): boolean {
		if (!item) {
			return false;
		}

		if (this.isFileNote(item)) {
			return this.isOverdue(item.eventDate) || this.isOverdue(item.reminder);
		}
		else {
			return moment(item).startOf("date").isSameOrBefore(moment().startOf("day"));
		}
	}

	private applyDateFilter(src: FileNote[]): FileNote[] {
		switch (this.dateFilter) {
			// case DateFilter.all:
			// 	return src;

			case DateFilter.week:
				return src.filter( item => this.isThisWeek(item) );

			case DateFilter.overdue:
				return src.filter( item => this.isOverdue(item) );

			default:
				return src.filter( item => this.isToday(item) );
		}
	}

	get notes(): FileNote[] {
		const stage1Notes = this.myAlerts ? this.myNotes() : this.otherNotes();
		return this.applyDateFilter(stage1Notes);
	}

	@Input()
	set assignedTo(userId: string) {
		this._userFilter = userId;
		if (!!userId) {
			this._canChangeUser = false;
		}
	}

	constructor(private fileNotesService: FileNotesService, private accountsService: UserAccountService) {
		super();
		this.allowedUserTypes = [UserType.Admin];
	}

	ngOnInit() {
		super.ngOnInit()
		this.loadData();
	}

	private get storageKeyComponent(): string {
		return this.canChangeUser ? 'all' : 'my';
	}

	private async loadAdminAccounts() {
		this._accounts = (await this.accountsService.getAdminAccounts()).filter( user => user.id !== this.user.id );
	}

	private async loadFileNotes() {
		// this._src = await this.fileNotesService.allNotes();
		const dateFilter = this.dateFilter === undefined ? DateFilter.today : this.dateFilter;
		this._src = await this.fileNotesService.dashboardNotes(dateFilter, this._userFilter);
	}

	private async loadData() {
		this.initDateFilterFromStorage();
		if(this._canChangeUser) this.initUserFilterFromStorage();

		const promises = [
			this.loadAdminAccounts(),
			this.loadFileNotes()
		];

		await Promise.all(promises);

		this._dataLoaded = true;
	}

	private initDateFilterFromStorage(): void {
		const storageKey = ['datefilter', this.storageKeyComponent, 'alerts'].join('.');
		const dateFilter: DateFilter = StorageService.get(storageKey);
		this.dateFilter = Utils.nullOrUndefined(dateFilter) ? DateFilter.today : dateFilter;
	}

	private initUserFilterFromStorage(): void {
		const storageKey = ['userfilter', this.storageKeyComponent, 'alerts'].join('.');
		this._userFilter = StorageService.get(storageKey) || undefined;
	}

	protected isFutureDate(date: Date): boolean {
		return moment(date).startOf('day').isSameOrAfter( moment().startOf('day') );
	}

	private gotoClient(clientId: string, noteId: string): void {
		this.router.navigate(['/account/', clientId, noteId]);
	}

	private gotoProvider(providerId: string, noteId: string): void {
		this.router.navigate(['/provider/', providerId, noteId]);
	}

	public gotoTarget(note: FileNote): void {
		if (!!note.providerId) {
			this.gotoProvider(note.providerId, note.id);
		}
		else if (!!note.clientId) {
			this.gotoClient(note.clientId, note.id);
		}
	}

	protected truncateContent(content: string): string {
		return Utils.truncateString(content);
	}

	protected showFilterDialog(): void {
		OverlayService.showTemplate(this.filterDialog);
	}

	protected closeDialog(): void {
		OverlayService.hide();
	}

	protected dateFilterChanged(): void {
		const storageKey = ['datefilter', this.storageKeyComponent, 'alerts'].join('.');
		StorageService.set(storageKey, this.dateFilter);
		this._dataLoaded = false;
		this.loadFileNotes().then( () => {
			this._dataLoaded = true;
		});
	}

	protected userFilterChanged(): void {
		this._userFilterName = undefined;
		const storageKey = ['userfilter', this.storageKeyComponent, 'alerts'].join('.');
		if (this._userFilter === undefined) {
			StorageService.clear(storageKey);
		}
		else {
			StorageService.set(storageKey, this._userFilter);
		}
	}

	protected get userFilterName(): string {
		if (this._userFilterName !== undefined) {
			return this._userFilterName;
		}

		if (this._userFilter !== undefined) {
			const user = this._accounts.find( item => item.id === this._userFilter );
			if (user) {
				this._userFilterName = user.name;
			}
		}

		return this._userFilterName || "anyone";
	}
}
