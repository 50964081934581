import { Component, OnInit } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { UserType } from '@classes/user';
import { MenuBuilder } from "@services/navmenu.service";

@Component({
	"templateUrl": "./reports.component.html",
	"styleUrls": ["./reports.component.scss"]
})
export class ReportsComponent extends PrivateComponent implements OnInit {

	constructor() {
		super();

		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Reports', 'Access Reports');
	}

	ngOnInit() {
		super.ngOnInit();

		if (this.user) {
			this.buildMenu();
		}
	}

	private buildMenu(): void {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.done();
	}
}
