import { Injectable } from '@angular/core';
import { RestService, API } from './rest.service';
import { FileNote, FileNoteStatus, FileNoteUtils, FileNoteCategory, TaskSummaryItem, UpdateNotesComponent } from '@classes/filenotes';
import { FileDownloader } from '@classes/files';
import { Cache } from "@classes/cache";
import moment from 'moment';

interface ReminderModel {
	type: string,
	from: Date,
	to: Date
}

export enum DateFilter {
	overdue, today, week, all
	// overdue, today, week
}

export namespace DateFilter {
	const descriptions = new Map<DateFilter, string>([
		[DateFilter.overdue, "Overdue items"],
		[DateFilter.today, "Items for today"],
		[DateFilter.week, "Items for this week"],
		[DateFilter.all, "All items"]
	]);

	const pgEnums = new Map<DateFilter, string>([
		[DateFilter.overdue, "overdue"],
		[DateFilter.today, "today"],
		[DateFilter.week, "this_week"],
		[DateFilter.all, "all"]
	]);

	export function toString(value: DateFilter): string {
		return descriptions.get(value);
	}

	export function allValues(): DateFilter[] {
		return Array.from(descriptions.keys());
	}

	export function pgEnum(value: DateFilter): string {
		return pgEnums.get(value);
	}
}


@Injectable({ providedIn: 'root' })
export class FileNotesService implements FileDownloader {

	static readonly maxCacheAgeSeconds = 30;

	private _notesCache = new Map<string, Cache<FileNote>>();
	private _allNotesCache = new Cache<FileNote>(FileNotesService.maxCacheAgeSeconds);

	private waitQueue: any[] = undefined;

	private getClientCache(clientId: string): Cache<FileNote> {
		if (!this._notesCache.has(clientId)) {
			this._notesCache.set(clientId, new Cache<FileNote>());
		}
		return this._notesCache.get(clientId);
	}

	constructor(private restService: RestService) {}

	public dashboardNotes(datefilter: DateFilter, userId?: string): Promise<FileNote[]> {
		let url = `notes/dashboard/${DateFilter.pgEnum(datefilter)}`;
		if (!!userId) {
			url += `/${userId}`;
		}

		return this.restService.get(API.user, url).then( data => {
			return data.map( FileNoteUtils.parse );
		});

	}

	public allNotes(): Promise<FileNote[]> {

		return new Promise( (resolve, reject) => {

			if (this._allNotesCache.valid) {
				return resolve(this._allNotesCache.items);
			}

			// If we're already requesting the data, add this request to the queue
			if (this.waitQueue !== undefined) {
				this.waitQueue.push( {
					"resolve": resolve,
					"reject": reject
				});
				return;
			}

			this.waitQueue = [];

			return this.restService.get(API.user, 'notes').then( data => {
				this._allNotesCache.items = data.map( FileNoteUtils.parse );

				while (this.waitQueue.length) {
					const request = this.waitQueue.pop();
					request.resolve.call(null, this._allNotesCache.items);
				}

				this.waitQueue = undefined;

				return resolve(this._allNotesCache.items);

			}).catch( e => {

				while (this.waitQueue.length) {
					const request = this.waitQueue.pop();
					request.reject.call(null, e);
				}

				this.waitQueue = undefined;

				return reject(e);
			});
		});
	}


	public saveNote(note: FileNote): Promise<FileNote> {
		const postData = {
			"note": FileNoteUtils.noteToJson(note)
		};

		let result: FileNote = undefined;

		return this.restService.post(API.filenotes, 'note', postData).then( response => {

			if (response.success) {
				result = FileNoteUtils.parse(response.note);

				const cache = this.getClientCache(note.clientId);
				if (cache.valid) {
					const idx = cache.items.findIndex(item => item.id === result.id);
					if (idx >= 0) {
						cache.items.splice(idx, 1, result);
					}
					else {
						cache.items.push(result);
					}
				}

				return Promise.resolve(result);
			}

			return Promise.reject("Unable to save note");
		});
	}

	private structureNotes(notes: FileNote[]): FileNote[] {

		// Create a map of notes so we can find each one quickly
		const noteMap = new Map<string, FileNote>();
		notes.forEach( note => {
			noteMap.set(note.id, note)
		});

		// Iterate through the list of notes, and assign child notes to their parents
		notes.forEach( note => {
			if (note.parent) {
				const parent = noteMap.get(note.parent);
				if (parent) {
					parent.replies = parent.replies || [];
					parent.replies.push(note);
				}
			}
		});

		// Return the notes without parents (the root notes)
		return notes.filter( note => !note.parent );
	}

	public listNotes(clientId: string): Promise<FileNote[]> {
		const cache = this.getClientCache(clientId);
		if (cache.valid) {
			return Promise.resolve(cache.items);
		}

		return this.restService.get(API.user, `notes/${clientId}`).then( data => {
			const notes = data.map( FileNoteUtils.parse );
			cache.items = this.structureNotes(notes);
			return Promise.resolve(cache.items);
		});
	}

	/**
	* Retrieves all file notes for a specified provider.
	*
	* @param {string} providerId
	* @returns {Promise<FileNote[]>} A promise that resolves to an array of filenotes
	*/
	public providerNotes(providerId: string, forceReload: boolean = false): Promise<FileNote[]> {
		const cache = this.getClientCache(providerId);
		if (forceReload) {
			cache.invalidate();
		}

		if (cache.valid) {
			return Promise.resolve(cache.items);
		}

		return this.restService.get(API.filenotes, `provider/${providerId}`).then( data => {
			const notes = data.map( FileNoteUtils.parse );
			cache.items = this.structureNotes(notes);
			return Promise.resolve(cache.items);
		});
	}

	public providerBillingNotes(providerId: string, forceReload: boolean = false): Promise<FileNote[]> {
		const cache = this.getClientCache(providerId);
		if (forceReload) {
			cache.invalidate();
		}

		if (cache.valid) {
			return Promise.resolve(cache.items.filter( note => !!note.billingNote ));
		}

		return this.restService.get(API.filenotes, `provider/${providerId}?billingNote=true`).then( data => {
			cache.items = data.map( FileNoteUtils.parse );
			return Promise.resolve(cache.items);
		});
	}

	public loadNote(noteId: string): Promise<FileNote> {
		return this.restService.get(API.filenotes, `note/${noteId}`).then( data => {
			return Promise.resolve( FileNoteUtils.parse(data) );
		});
	}

	public getBillingNotes(clientId: string): Promise<FileNote[]> {
		const cache = this.getClientCache(clientId);
		if (cache.valid) {
			return Promise.resolve(cache.items.filter( note => !!note.billingNote ));
		}

		return this.restService.get(API.user, `notes/${clientId}?billingNote=true`).then( data => {
			return Promise.resolve( data.map( FileNoteUtils.parse ) );
		});
	}


	public async deleteNote(note: FileNote): Promise<boolean> {
		const response = await this.restService.delete(API.user, `notes/${note.id}`);
		return !!response.success;
	}

	public loadAttachment(attachmentId: string): Promise<any> {
		return this.restService.get(API.attachments, `${attachmentId}`).then( response => {
			return response.content;
		});
	}

	public fileNoteCategories(): Promise<FileNoteCategory[]> {
		return this.restService.get(API.user, `note-categories`).then( response => {
			return response.categories;
		});
	}

	public invalidateCacheForUser(clientId: string): void {
		const cache = this.getClientCache(clientId);
		cache.invalidate();
	}

	public taskSummary(): Promise<TaskSummaryItem[]> {
		return this.restService.get(API.filenotes, 'summary').then( result => (result || []).map( TaskSummaryItem.parse ));
	}

	public taskMetadata(userId?: string, filenoteStatus?: FileNoteStatus, overdue?: boolean, reminder?: ReminderModel): Promise<number> {
		let url = "meta";
		let queryParams = [];

		if (userId === null) {
			url += `/unassigned`;
		}
		else if (userId) {
			url += `/${userId}`;
		}

		if (filenoteStatus !== undefined) {
			queryParams.push(`status=${FileNoteStatus.toPostgresEnum(filenoteStatus)}`);
		}
		if (!!overdue) {
			queryParams.push(`overdue=true`);
		}
		if (!!reminder){
			if(!!reminder.type) {
				queryParams.push(`type=${reminder.type}`);
			}
			if(!!reminder.from) {
				let from = moment(reminder.from).format('YYYY-MM-DD');
				queryParams.push(`from=${from}`);
			}
			if(!!reminder.to) {
				let to = moment(reminder.to).format('YYYY-MM-DD');
				queryParams.push(`to=${to}`);
			}
		}

		if (queryParams.length > 0) {
			url += '?' + queryParams.join('&');
		}

		return this.restService.get(API.filenotes, url).then( result => result.total );
	}

	public loadTasks(page: number, userId?: string, filenoteStatus?: FileNoteStatus, overdue?: boolean, reminder?: ReminderModel): Promise<FileNote[]> {
		let url = `tasks/${page}`;
		let queryParams = [];
		let from = moment(reminder.from).format('YYYY-MM-DD');
		let to = moment(reminder.to).format('YYYY-MM-DD');

		if (userId === null) {
			url += `/unassigned`;
		}
		else if (userId) {
			url += `/${userId}`;
		}

		if (filenoteStatus !== undefined) {
			queryParams.push(`status=${FileNoteStatus.toPostgresEnum(filenoteStatus)}`);
		}
		if (!!overdue) {
			queryParams.push(`overdue=true`);
		}
		if(!!reminder.type) {
			queryParams.push(`type=${reminder.type}`);
		}
		if(!!reminder.from) {
			queryParams.push(`from=${from}`);
		}
		if(!!reminder.to) {
			queryParams.push(`to=${to}`);
		}

		if (queryParams.length > 0) {
			url += '?' + queryParams.join('&');
		}

		return this.restService.get(API.filenotes, url).then( result => {
			return result.map( FileNoteUtils.parse );
		});
	}

	public updateNotes(notes: UpdateNotesComponent): Promise<UpdateNotesComponent> {
		const patchData = { updates: FileNoteUtils.updateNotesToJson(notes) };

		return this.restService.patch(API.filenotes, 'note/bulk', patchData).then( response => {
			return (response.success) ? Promise.resolve(response) : Promise.reject("Unable to save notes");
		});
	}
	
}
