import { Component, Input, ViewChild, TemplateRef } from '@angular/core';
import { OverlayService } from '@services/overlay.service';
import { PrivateComponent } from "@classes/private.component";
import { Provider } from '@services/provider.service';
import { Utils } from "@classes/utils";
import { ErrorUtils } from "@classes/errors";
import { FileNotesService } from "@services/filenotes.service";
import { AttachmentsService } from "@services/attachments.service";
import { AttachedFile, FileMimeTypes } from "@classes/files";
import { FileNote, FileNoteStatus } from "@classes/filenotes";
import { AttachmentTargetType, AttachmentTargetUtils  } from '@classes/attachments';
import { FileManager } from "@classes/filemanager";
import { IconName } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

enum Tabs {
	provider, notes, contact, banking, address, clients
}

@Component({
	"selector": "provider-notes",
	"styleUrls": ["./notes.component.scss"],
	"templateUrl": "./notes.component.html"
})
export class ProviderNotesComponent extends PrivateComponent {

	@ViewChild('newNoteDialog') private noteDialog: TemplateRef<any>;
	@ViewChild('attachmentDialog') private attachmentDialog: TemplateRef<any>;

	private _provider: Provider = undefined;
	private _notes: FileNote[] = [];
	private _loading: boolean = false;
	private _noteBeingEdited: FileNote = undefined;
	private _fileMimeTypes: FileMimeTypes = new FileMimeTypes();

	constructor(private notesService: FileNotesService, private attachmentsService: AttachmentsService) {
		super();
	}

	@Input()
	public set provider(value: Provider) {
		this._provider = value;
		this.reloadNotes(false);
	}

	public readonly fileNoteStatus = {
		"open": FileNoteStatus.open,
		"completed": FileNoteStatus.completed,
		// "notStarted": FileNoteStatus.not_started
	};

	public async reloadNotes(forceReload: boolean = true) {
		this._loading = true;
		try {
			if (this._provider !== undefined) {
				this._notes = await this.notesService.providerNotes(this._provider.id, forceReload);
				this._notes.sort( (a, b) => b.createdDate.valueOf() - a.createdDate.valueOf() );
			}
			else {
				this._notes = [];
			}
		}
		catch (e) {
			console.warn(e);
		}
		finally {
			this._loading = false;
		}
	}

	public showNewNoteDialog() {
		this._noteBeingEdited = undefined;
		OverlayService.showTemplate(this.noteDialog);
	}

	public editNote(note: FileNote): void {
		this._noteBeingEdited = note;
		OverlayService.showTemplate(this.noteDialog);
	}

	public get dataLoaded(): boolean {
		return !this._loading && this._provider !== undefined;
	}

	public get provider(): Provider {
		return this._provider;
	}

	public get notes(): FileNote[] {
		return this._notes;
	}

	public get noteBeingEdited(): FileNote {
		return this._noteBeingEdited;
	}

	private async deleteNote(note: FileNote) {
		OverlayService.hide();
		OverlayService.show(`Deleting note${Utils.ellipsis}`);
		try {
			await this.notesService.deleteNote(note);
			const idx = this._notes.findIndex(item => item.id === note.id);
			if (idx >= 0) {
				this._notes.splice(idx, 1);
			}
			OverlayService.hide();
		}
		catch (e) {
			OverlayService.hide();
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "An unknown error occurred"));
		};
	}


	public confirmDeleteNote(note: FileNote): void {
		OverlayService.showDialog("Confirm", "Are you sure you want to delete this note?", [{
			"text": "No - don't delete",
			"handler": () => { OverlayService.hide(); }
		}, {
			"text": "Yes - delete",
			"handler": () => { this.deleteNote(note); }
		}]);
	}

	public saveNote(note: FileNote): void {
		OverlayService.show();
		this.notesService.saveNote( note ).then( _ => {

			// Will be quick if the cache is still valid, otherwise a round-trip to the server is required
			return this.reloadNotes();

		}).then( () => {

			OverlayService.hide();

		}).catch( e => {

			OverlayService.hide();
			console.log(e);
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "An unknown error occurred"));

		});
	}

	public setNoteStatus(note: FileNote, status: FileNoteStatus): void {
		note.status = status;
		this.saveNote(note);
	}

	protected toIcon(icon: string): IconName {
		return icon as IconName;
	}

	public isFutureDate(date: Date): boolean {
		return moment(date).startOf('day').isSameOrAfter( moment().startOf('day') );
	}

	public isPastDate(date: Date): boolean {
		return moment(date).startOf('day').isSameOrBefore( moment().startOf('day') );
	}

	public dateBetweenDays(date: Date, numDaysFrom: number, numDaysTo: number): boolean {
		const cutoffStart = moment().add(numDaysFrom, 'days').startOf('day');
		const cutoffEnd = moment().add(numDaysTo, 'days').startOf('day');
		return moment(date).isBetween(cutoffStart, cutoffEnd, null, '[]');
	}

	public showAttachmentDialog(note: FileNote): void {
		const targets = [
			AttachmentTargetUtils.target(this.provider.id, AttachmentTargetType.provider),
			AttachmentTargetUtils.target(note.id, AttachmentTargetType.note)
		];

		OverlayService.showTemplate(this.attachmentDialog, {"targets": targets, "files": note.attachments});
	}

	public async saveAttachments($event: any) {
		if ($event.fileManager && $event.targets && $event.targets.length > 0) {
			OverlayService.show(`Saving${Utils.ellipsis}`);
			try {
				await this.attachmentsService.saveAttachments($event.targets, $event.fileManager);
				OverlayService.hide();
			}
			catch (e) {
				console.log(e);
				OverlayService.hide();
				OverlayService.showError("Error", "Unable to save attachment");
			}
		}
	}

	public getFileIcon(file: AttachedFile): IconName {
		return this._fileMimeTypes.getIcon(file.mimeType) as IconName;
	}

	public downloadDocument(document: AttachedFile) {
		FileManager.downloadFile(this.attachmentsService, document);
	}
}
