import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { OverlayService } from '@services/overlay.service';
import { PrivateComponent } from "@classes/private.component";
import { UserType } from "@classes/user";
import { MenuBuilder } from "@services/navmenu.service";
import { Provider, ProviderService } from '@services/provider.service';
import { PaginatedTable, TableColumnHeading, SortType, StaticDataSource } from "@classes/tables";
import { Utils } from "@classes/utils";
import { ErrorUtils } from "@classes/errors";
import { Observable, of, Subscriber, mergeMap } from 'rxjs';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

@Component({
	"styleUrls": ["./providers.component.scss"],
	"templateUrl": "./providers.component.html"
})
export class ProvidersComponent extends PrivateComponent implements OnInit {

	@ViewChild('mergeProviderDialog') private mergeProviderDialog: TemplateRef<any>;
	@ViewChild('confirmMergeDialog') private confirmMergeDialog: TemplateRef<any>;

	private _dataLoaded: boolean = false;

	get dataLoaded(): boolean {
		return this._dataLoaded;
	}

	private providers: Provider[];

	private readonly tableHeadings: TableColumnHeading[] = [
		{"propName": "name", "displayName": "Name", "sortType": SortType.text },
		{"propName": "email", "displayName": "Email", "sortType": SortType.text },
		{"propName": "abn", "displayName": "ABN", "sortType": SortType.text },
		{"propName": "bankAccountBSB", "displayName": "BSB", "sortType": SortType.text },
		{"propName": "bankAccountNumber", "displayName": "Account", "sortType": SortType.text }
	];

	table: PaginatedTable<Provider> = new PaginatedTable('providers', this.tableHeadings);

	constructor(private providerService: ProviderService) {
		super();
		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Providers', 'View Providers');
	}

	private buildMenu(): void {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.addRoute("plus-circle", "New Provider", "/provider/new");
		menuBuilder.addHandler("code-branch", "Merge Providers", () => {
			this.showMergeProviderDialog();
		}, null, () => {
			return this.mergeProviderVisible();
		});

		menuBuilder.done();
	}

	ngOnInit() {
		super.ngOnInit();

		this.buildMenu();

		if (this.user) {
			this.loadProviders();
		}
	}

	private loadProviders(): void {
		this._dataLoaded = false;

		OverlayService.show();
		this.providerService.getAllProviders().then( providers => {

			providers = providers.filter(provider => {
				return provider.id != "ca90a10e-b1e1-4019-89a8-1c2a02b6d3b5";
			});

			this.providers = providers;
			this.table.sourceData = StaticDataSource.from(providers);
			OverlayService.hide();
			this._dataLoaded = true;

		}).catch( e => {

			this._dataLoaded = true;
			OverlayService.hide();
			OverlayService.showError("Unable to load providers", e.message || "An unknown error occurred");

		});
	}

	public filterProviders(filterText: string): void {
		filterText = filterText.toLowerCase();
		const filteredProviders = this.providers.filter( provider => {
																return provider.name.toLowerCase().includes(filterText) || ((typeof provider.abn === 'string') && provider.abn.includes(filterText));
															});
		this.table.sourceData = StaticDataSource.from(filteredProviders);
	}

	public clearFilter(filterBox: any): void {
		filterBox.value = "";
		this.table.sourceData = StaticDataSource.from(this.providers);
	}

	private mergeProviderVisible(): boolean {
		return this.providers && this.providers.length > 1;
	}

	public showMergeProviderDialog() {
		const context = {
			"sourceProviders": new Observable((observer: Subscriber<string>) => {
				// Runs on every search
				observer.next(context.source.name);
			  })
				.pipe(
				  mergeMap((token: string) => this.getProvidersAsObservable(token))
				),
			"destProviders": new Observable((observer: Subscriber<string>) => {
				// Runs on every search
				observer.next(context.dest.name);
				})
				.pipe(
					mergeMap((token: string) => this.getProvidersAsObservable(token))
				),
			"confirmMerge": false,
			"source": {
				"name": undefined,
				"provider": undefined
			},
			"dest": {
				"name": undefined,
				"provider": undefined
			}
		};

		OverlayService.showTemplate(this.mergeProviderDialog, context);
	}

	public getProvidersAsObservable(token:string):Observable<Provider[]> {
		return of(
		  this.providers.filter((provider:Provider) => {
			let name = provider.name, abn = provider.abn;
			if(name === null)
				name = "";
			if(abn === null)
				abn = "";
			return name.toLowerCase().includes(token.toLowerCase()) || abn.toLowerCase().includes(token.toLowerCase());
		  })
		);
	  }

	public providerKeyUp(item: any) {
		if (item.provider && item.provider.name !== item.name) {
			item.provider = undefined;
		}
	}

	public providerSelected(event: TypeaheadMatch, toMerge: any) {
		toMerge.name = event.item.name;
		toMerge.provider = event.item;
	}

	public cancelDialog(): void {
		OverlayService.hide();
	}

	public canMerge(source: Provider, dest: Provider): boolean {
		return !!source && !!dest && source.id !== dest.id;
	}

	public confirmMerge(context: any) {
		context.confirmMerge = false;
		OverlayService.showTemplate(this.confirmMergeDialog, context);
	}

	public doMerge(source: Provider, dest: Provider) {
		OverlayService.hide();
		OverlayService.show("Merging" + Utils.ellipsis);
		this.providerService.mergeProviders(source.id, dest.id).then( () => {

			const idx = this.providers.findIndex( provider => provider.id === source.id );
			if (idx >= 0) {
				this.providers.splice(idx, 1);
			}
			OverlayService.hide();
			OverlayService.showSuccess("Merge Providers", "Providers merged successfully");

		}).catch( err => {
			OverlayService.hide();
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(err, "Unable to merge providers"));
		})
	}
}
