import { Component, OnInit } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { UserType } from "@classes/user";
import { Table, TableColumnHeading, SortType, Alignment, StaticDataSource } from "@classes/tables";
import { MenuBuilder } from "@services/navmenu.service";
import { DataImportService, DataImport } from "@services/dataimport.service";
import { OverlayService } from "@services/overlay.service";
import { NDSPService } from "@services/ndsp.service";

@Component({
	"styleUrls": ["./dataimports.component.scss"],
	"templateUrl": "./dataimports.component.html"
})
export class DataImportListComponent extends PrivateComponent implements OnInit {

	private _dataLoaded: boolean = false;
	private _files: DataImport[] = [];

	private _isLoading: boolean = false;

	private readonly tableHeadings: TableColumnHeading[] = [
		{"propName": "importedDate", "displayName": "Date", "sortType": SortType.date },
		{"propName": "dataImportType", "displayName": "Type", "sortType": SortType.numeric },
		{"propName": "filename", "displayName": "Filename", "sortType": SortType.text },
		{"propName": "importedByName", "displayName": "Submitted By", "sortType": SortType.name },
		{"propName": "count", "displayName": "Num Rows", "sortType": SortType.numeric, "alignment": Alignment.center }
	];

	table: Table<DataImport> = new Table('proddasubmissions', this.tableHeadings);

	get dataLoaded(): boolean {
		return this._dataLoaded;
	}

	constructor(private dataImportService: DataImportService, private ndspService: NDSPService) {
		super();

		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Data Import', 'Data Import Access');
	}

	private buildMenu(): void {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.addRoute('cloud-upload-alt', "Data Import", "/dataimport/new");
		menuBuilder.done();
	}

	private loadFiles(): void {
		if (!this.user) {
			return;
		}

		this._dataLoaded = false;

		OverlayService.show();
		this.dataImportService.loadFiles().then( result => {
			this._files = result;
			this.table.sourceData = StaticDataSource.from(this._files);
			this._dataLoaded = true;
			this.filter('');
			OverlayService.hide();
		}).catch( err => {
			OverlayService.hide();
			console.log(err);
		});

	}

	ngOnInit() {
		super.ngOnInit()

		this.buildMenu();
		this.loadFiles();
	}

	IsUnlocked(item: any): boolean {

		if (item) {
			return !item.locked;
		}
		return false;
	}

	public filter(value: string): void {
		const searchValue = (value || "").toLowerCase();

		const filteredFiles = this._files.filter( file => {
			return true;
		}).filter( file => {

			const searchableFields = [
				file.importedByName || "",
				file.filename || "",
				file.dataImportType || ""
			];

			return searchableFields.map( x => x.toLowerCase() ).some( prop => prop.includes(searchValue) );

		});

		this.table.sourceData = StaticDataSource.from(filteredFiles);
	}

	public clearFilter(filterBox: any): void {
		filterBox.value = "";
		this.loadFiles();
	}

	public closeDialogWindow() {
		OverlayService.hide();
	}

	public get isLoading(): boolean {
		return this._isLoading;
	}

	public async loadMore() {
		this._isLoading = true;
		try {
			const moreFiles = await this.dataImportService.loadFiles(this._files.length);
			this._files = this._files.concat(moreFiles);
			this.table.sourceData = StaticDataSource.from(this._files);
			this.filter('');
		}
		catch (e) {
			console.log(e);
		}
		finally {
			this._isLoading = false;
		}
	}
}
