export interface ProviderSpend {
	providerId: string;
	providerName: string;
	providerABN: string;
	supportCategoryNumber: number;
	totalSpend: number;
	numInvoices: number;
}

export namespace ProviderSpend {
	export function parse(src: any): ProviderSpend {
		return {
			"providerId": src.id,
			"providerName": src.name,
			"providerABN": src.abn,
			"supportCategoryNumber": Number(src.supportCategoryNumber),
			"totalSpend": Number(src.total),
			"numInvoices": Number(src.numInvoices),
		};
	}
}
