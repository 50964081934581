import { Component, OnInit, AfterViewInit, Output, EventEmitter, HostListener, Input } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { FileManager } from "@classes/filemanager";
import { OverlayService } from "@services/overlay.service";
import { UserType } from "@classes/user";

@Component({
	"selector": "invoice-attachment-dialog",
	"styleUrls": ["./invoiceattachments.component.scss"],
	"templateUrl": "./invoiceattachments.component.html"
})
export class InvoiceAttachmentComponent extends PrivateComponent implements OnInit, AfterViewInit {

	private _filemanager: FileManager;
	private _billEditable: boolean;

	get fileManager(): FileManager {
		return this._filemanager;
	}

	get billEditable(): boolean {
		return this._billEditable;
	}

	@Input()
	set filemanager(value: FileManager) {
		this._filemanager = value;
	}
	@Input()
	set billEditable(value: boolean) {
		this._billEditable = value;
	}

	@HostListener('window:keydown', ['$event'])
	interceptBrowserShortcuts(event: KeyboardEvent) {

		if (event.key === 'Escape') {
			event.preventDefault();
			event.stopPropagation();
			this.closeDialogWindow();
		}
	}

	@Output()
	dialogClosed = new EventEmitter<void>();

	closeDialogWindow(): void {
		OverlayService.hide();
		this.dialogClosed.emit();
	}

	ngOnInit() {
		super.ngOnInit()
	}

	constructor() {
		super();
		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Billing', 'Access billing');
	}

	ngAfterViewInit() {
		// Hello
	}
}
