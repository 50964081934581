import { Injectable } from '@angular/core';
import { API as AWS_API } from 'aws-amplify';
import { OfflineAuthService } from './offlineauth.service';

export enum API {
	system,
	admin,
	proda,
	supportcoordinator,
	user,
	invoices,
	plans,
	attachments,
	providers,
	dashboards,
	billing,
	reports,
	xero,
	su,
	statements,
	filenotes,
	supports
};

class APIUtils {
	private static readonly apiNames = new Map<API, string>([
		[API.system, "system"],
		[API.admin, "admin"],
		[API.proda, "proda"],
		[API.supportcoordinator, "supportcoordinator"],
		[API.user, "user"],
		[API.invoices, "invoices"],
		[API.plans, "plans"],
		[API.attachments, "attachments"],
		[API.providers, "providers"],
		[API.dashboards, "dashboards"],
		[API.billing, "billing"],
		[API.reports, "reports"],
		[API.xero, "xero"],
		[API.su, "superuser"],
		[API.statements, "statements"],
		[API.filenotes, "filenotes"],
		[API.supports, "supports"]
	]);

	public static apiName(api: API): string {
		return APIUtils.apiNames.get(api);
	}
}

@Injectable({ providedIn: 'root' })
export class RestService {

	constructor( private offlineAuthService: OfflineAuthService) {}

	/**
	* Adds an 'x-identityId' value to the HTTP request headers if we're running in a serverless-offline
	* environment. This acts as a shim for AWS_IAM authentication which is not supported by serverless-offline.
	*
	* @param {any} requestOptions
	* @return {any} The original request options object, with an additional header object appended if required.
	*/
	private addOfflineAuthHeader(requestOptions: any): any {
		if (this.offlineAuthService.isOffline()) {
			requestOptions = requestOptions || {}
			requestOptions.headers = requestOptions.headers || {};
			requestOptions.headers['x-identityId'] = this.offlineAuthService.getIdentity()
		}

		return requestOptions;
	}

	/**
	* Wrapper for the amplifyService.api().get method.
	*
	* @param {string} apiName The name of the API to call. This is defined in aws-settings.ts
	* @param {string} path The request path
	* @param {any} options Extra request params (see https://aws-amplify.github.io/docs/js/api#get)
	* @return {Promise<any>} A promise that resolves with the result of the HTTP request
	*/
	get(api: API, path: string, options?: any): Promise<any> {
		return AWS_API.get(APIUtils.apiName(api), path, this.addOfflineAuthHeader(options));
	}

	/**
	* Wrapper for the amplifyService.api().post method.
	*
	* @param {string} apiName The name of the API to call. This is defined in aws-settings.ts
	* @param {string} path The request path
	* @param {any} body The data to be POSTed with the request
	* @param {any} options Extra request params (see https://aws-amplify.github.io/docs/js/api#post)
	* @return {Promise<any>} A promise that resolves with the result of the HTTP request
	*/
	post(api: API, path: string, body: any, options?: any): Promise<any> {
		options = options || {};
		options.body = body;
		return AWS_API.post(APIUtils.apiName(api), path, this.addOfflineAuthHeader(options));
	}


	/**
	* Wrapper for the amplifyService.api().put method.
	*
	* @param {string} apiName The name of the API to call. This is defined in aws-settings.ts
	* @param {string} path The request path
	* @param {any} body The data to be sent with the request
	* @param {any} options Extra request params (see https://aws-amplify.github.io/docs/js/api#post)
	* @return {Promise<any>} A promise that resolves with the result of the HTTP request
	*/
	put(api: API, path: string, body: any, options?: any): Promise<any> {
		options = options || {};
		options.body = body;
		return AWS_API.put(APIUtils.apiName(api), path, this.addOfflineAuthHeader(options));
	}

	/**
	* Wrapper for the amplifyService.api().post method.
	*
	* @param {string} apiName The name of the API to call. This is defined in aws-settings.ts
	* @param {string} path The request path
	* @param {any} body Additional data to be sent in the request body
	* @param {any} options Extra request params (see https://aws-amplify.github.io/docs/js/api#post)
	* @return {Promise<any>} A promise that resolves with the result of the HTTP request
	*/
	delete(api: API, path: string, body?: any, options?: any): Promise<any> {
		options = options || {};
		options.body = body || {};
		return AWS_API.del(APIUtils.apiName(api), path, this.addOfflineAuthHeader(options));
	}

	/**
	* Wrapper for the amplifyService.api().patch method.
	*
	* @param {string} apiName The name of the API to call. This is defined in aws-settings.ts
	* @param {string} path The request path
	* @param {any} body The data to be sent with the request
	* @param {any} options Extra request params (see https://aws-amplify.github.io/docs/js/api#post)
	* @return {Promise<any>} A promise that resolves with the result of the HTTP request
	*/
	patch(api: API, path: string, body: any, options?: any): Promise<any> {
		options = options || {};
		options.body = body;
		return AWS_API.patch(APIUtils.apiName(api), path, this.addOfflineAuthHeader(options));
	}
}
