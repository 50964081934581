import { Injectable } from '@angular/core';
import { RestService, API } from './rest.service';
import { DateUtils } from "@classes/utils";
import { InvoiceStatus } from "@classes/invoices";

@Injectable({ "providedIn": 'root' })
export class ReportsService {

	constructor(private restService: RestService) {}

	public billingReport(reportPeriod: number, startDate?: Date, endDate?: Date): Promise<any> {
		const postData = {
			"period": reportPeriod,
			"startDate": DateUtils.toString(startDate),
			"endDate": DateUtils.toString(endDate)
		};

		return this.restService.post(API.reports, "billingreport", postData).then( result => {
			return Promise.resolve(result.report);
		});
	}

	public multipleSubmissionReport(): Promise<any[]> {
		return this.restService.get(API.reports, "multi-submissions").then( result => {
			return result.map( src => {
				return {
					"invoiceId": src.invoiceId,
					"invoiceDate": DateUtils.parse(src.invoiceDate, DateUtils.ISO8601msFormat),
					"clientId": src.clientId,
					"client": src.client,
					"providerId": src.providerId,
					"provider": src.provider,
					"invoiceNumber": src.invoiceNumber,
					"total": src.total,
					"status": InvoiceStatus.parse(src.status),
					"numLineItems": src.numLineItems,
					"numPaid": src.numPaid,
					"amountPaid": src.amountPaid,
					"numRejected": src.numRejected,
					"amountRejected": src.amountRejected,
					"numCancelled": src.numCancelled,
					"amountCancelled": src.amountCancelled,
					"numSubmitted": src.numSubmitted,
					"amountSubmitted": src.amountSubmitted,
					"numExtracts": src.numExtracts
				};
			});
		});
	}

	public reconciliationReport(date: Date): Promise<any[]> {
		const dateStr = DateUtils.toString(date);
		const url = `reconciliation/${dateStr}`;

		return this.restService.get(API.reports, url).then( result => {
			return result.map( src => {
				return {
					"invoiceId": src.invoiceId,
					"invoiceNumber": src.invoiceNumber,
					"billTotal": src.billTotal,
					"client": src.client,
					"clientId": src.clientId,
					"provider": src.provider,
					"providerId": src.providerId,
					"status": src.status,
					"deleted": src.deleted,
					"submissionDate": src.submissionDate,
					"previouslyHeld": src.previouslyHeld,
					"claimed": src.amountClaimed,
					"received": src.totalReceived,
					"discrepancy": src.discrepancy,
					"reconciled": src.reconciled
				};
			});
		});
	}
}
