import { DateUtils } from "@classes/utils";

export enum AccountStatus {
	prospective, active, inactive, deceased
}

export namespace AccountStatus {
	const values = new Map<AccountStatus, string>([ [AccountStatus.prospective, "Prospective"], [AccountStatus.active, "Active"], [AccountStatus.inactive, "Inactive"], [AccountStatus.deceased, "Deceased"] ]);
	const pgEnums = new Map<AccountStatus, string>([ [AccountStatus.prospective, "prospective"], [AccountStatus.active, "active"], [AccountStatus.inactive, "inactive"], [AccountStatus.deceased, "passed_away"] ])
	const parseMap = new Map<string, AccountStatus>([ ["prospective", AccountStatus.prospective], ["active", AccountStatus.active], ["inactive", AccountStatus.inactive], ["passed_away", AccountStatus.deceased] ])

	export function parse(src: string): AccountStatus {
		return parseMap.get(src);
	}

	export function toString(value: AccountStatus): string {
		return values.get(value);
	}

	export function toPostgresEnum(value: AccountStatus): string {
		return pgEnums.get(value);
	}

	export const allValues: AccountStatus[] = Array.from(values.keys());
}

export enum ExitReason {
	agency_managed, changed_pm, exit_ndis, rebuild_error, self_managed, build_failed, alternate_pm, other
}

export namespace ExitReason {
	const values = new Map<ExitReason, string>([
		[ExitReason.agency_managed, "Cancellation - Agency Managed"],
		[ExitReason.changed_pm, "Cancellation - Changed Plan Manager"],
		[ExitReason.exit_ndis, "Cancellation - Exit NDIS"],
		[ExitReason.rebuild_error, "Cancellation - Rebuild Error"],
		[ExitReason.self_managed, "Cancellation - Self Managed"],
		[ExitReason.build_failed, "Sign Up Failed - Unable to build"],
		[ExitReason.alternate_pm, "Sign Up Failed - Using a different plan manager"],
		[ExitReason.other, "Other"]
	]);

	const pgEnums = new Map<ExitReason, string>([
		[ExitReason.agency_managed, "agency_managed"],
		[ExitReason.changed_pm, "changed_pm"],
		[ExitReason.exit_ndis, "exit_ndis"],
		[ExitReason.rebuild_error, "rebuild_error"],
		[ExitReason.self_managed, "self_managed"],
		[ExitReason.build_failed, "build_failed"],
		[ExitReason.alternate_pm, "alternate_pm"],
		[ExitReason.other, "other"]
	]);

	const parseMap = new Map<string, ExitReason>([
		["agency_managed", ExitReason.agency_managed],
		["changed_pm", ExitReason.changed_pm],
		["exit_ndis", ExitReason.exit_ndis],
		["rebuild_error", ExitReason.rebuild_error],
		["self_managed", ExitReason.self_managed],
		["build_failed", ExitReason.build_failed],
		["alternate_pm", ExitReason.alternate_pm],
		["other", ExitReason.other]
	]);

	export function parse(src: string): ExitReason {
		return parseMap.get(src);
	}

	export function toString(value: ExitReason): string {
		return values.get(value);
	}

	export function toPostgresEnum(value: ExitReason): string {
		return pgEnums.get(value);
	}

	export const allValues: ExitReason[] = Array.from(values.keys());
}

export interface ClientStatus {
	accountStatus: AccountStatus;
	exitReason?: ExitReason;
	notes?: string;
	date?: Date;
	statusDate?: Date;
}

export namespace ClientStatus {
	export function parse(src: any): ClientStatus {

		// Return undefined if we're not looking at a participant record
		if (src.accountType !== 'participant') {
			return undefined;
		}

		return {
			"accountStatus": AccountStatus.parse(src.accountStatus),
			"exitReason": ExitReason.parse(src.exitReason),
			"notes": src.notes,
			"date": DateUtils.parse(src.date),
			"statusDate": DateUtils.parse(src.statusDate)
		};
	}

	export function toJSON(src: ClientStatus): any {
		if (!src) {
			return undefined;
		}

		return {
			"accountStatus": AccountStatus.toPostgresEnum(src.accountStatus),
			"exitReason": ExitReason.toPostgresEnum(src.exitReason),
			"notes": src.notes,
			"date": DateUtils.toString(src.date),
			"statusDate": DateUtils.toString(src.statusDate)
		};
	}

	export function clone(src: ClientStatus): ClientStatus {
		return !src ? undefined : {...src};
	}

	export function empty(): ClientStatus {
		return {
			"accountStatus": undefined,
			"exitReason": undefined,
			"notes": undefined,
			"statusDate": undefined,
			"date": undefined
		};
	}
}
