import { Component, OnInit } from '@angular/core';
import { RestService, API } from "@services/rest.service";

interface Model {
	offset: number;
}

@Component({
	"templateUrl": "./clientcheck.component.html"
})
export class ClientCheckComponent implements OnInit {

	private readonly MAX_CLOCK_SKEW = 4000;

	private _offsetData: Model = undefined;

	constructor(private restService: RestService) {
	}

	ngOnInit() {
		this.runCheck();
	}


	public  get loaded(): boolean {
		return this._offsetData !== undefined;
	}

	private async runCheck() {
		this._offsetData = await this.restService.post(API.system, "test", {"timestamp": new Date().valueOf()});
	}

	public get offset(): number {
		if (this._offsetData !== undefined) {
			return this._offsetData.offset;
		}
	}

	public get withinThreshold(): boolean {
		const abs = Math.abs(this.offset);
		return abs < this.MAX_CLOCK_SKEW;
	}
}
