import { Injectable } from '@angular/core';
import { RestService, API } from '@services/rest.service';
import { DBService } from "@services/db.service";
import { OverlayService } from "@services/overlay.service";
import { SupportCategory, SupportItem, SupportsVersion } from "@classes/supports";
import { Region, RegionUtils } from "@classes/regions";
import { EAssertionFailed, assert } from "@classes/errors";
import { DateUtils } from "@classes/utils";
import { SimpleWaitQueue } from "@classes/waitQueue";
import moment from "moment";

/**
* Provides mechanisms for obtaining items from the NDIS support catalog.
* For support categories:
*    Data can be fetched from a remote lambda function if it's not already stored locally.
* For support items:
*    Only locally stored data (from IndexedDB) is returned. If this data is not available, the requesting
*    component should use the "supports-loading-dialog" component to ensure the latest data is available.
*/
@Injectable({"providedIn": "root"})
export class SupportsService {

	private static readonly categoryListKey = 'supportcategories';
	private static readonly supportVersion = 'supportversions';

	/**
	* Flag indicating that we've checked the local copies of support categories and price guides,
	* and have downloaded everything required from the the server
	*/
	private _initialised: boolean = false;
	private _initialising: boolean = false;

	private _categoryWaitQueue = new SimpleWaitQueue<SupportCategory[]>();
	private _summaryWaitQueue = new SimpleWaitQueue<SupportsVersion[]>();
	private _initWaitQueue = new SimpleWaitQueue<void>();

	/**
	* List of all support dates and their last modified times from the server
	* Used to:
	*  1) Determine if we have out-of-date support data
	*  2) Finding the "dateFrom" key value to use when we're querying for supports for a region and date.
	*/
	private _supportVersions: SupportsVersion[];

	constructor(private restService: RestService) {
	}

	private showProgress(currentProgress: number, total: number) {
		OverlayService.show("Loading price guide " + (100 * currentProgress / total).toFixed(0) + "%");
	}

	private async downloadPrices(priceList: SupportsVersion[]) {

		if (!priceList || priceList.length === 0) {
			return;
		}

		const itemsToProcess = priceList.length * 2; // Two slow operations (download, insert into DB) for each item in the list
		const showProgress = priceList.length > 1;
		const dialogAlreadyVisible = OverlayService.visible; // Store the current state of the dialog service. We'll hide it again later if needed.
		let currentProgress = 0;

		const promises = priceList.map( async version => {
			const prices = await this.restService.get(API.supports, `supports/${version.id}`);

			if (showProgress) {
				this.showProgress(++currentProgress, itemsToProcess);
			}

			await DBService.supports.set( prices.map( item => SupportItem.parse(item) ) );

			if (showProgress) {
				this.showProgress(++currentProgress, itemsToProcess);
			}
		});

		await Promise.all(promises);

		// Hide the progress dialog we displayed if required.
		if (!dialogAlreadyVisible && showProgress) {
			OverlayService.hide();
		}
	}

	private async deletePrices(deleteList: SupportsVersion[]) {
		if (!deleteList || deleteList.length === 0) {
			return;
		}

		// Get a list of all regions
		const regions = RegionUtils.allValues();

		const promises = deleteList.reduce( (acc: Promise<any>[], cur: SupportsVersion) => {

			regions.forEach( region => {
				const keys = [region, cur.dateFrom];
				acc.push( DBService.supports.remove( IDBKeyRange.only(keys) ) );
			});

			return acc;
		}, []);

		// Wait for the deletes to finish.
		await Promise.all(promises);
	}

	private async syncPrices(downloadList: SupportsVersion[], deleteList: SupportsVersion[]) {
		const promises = [
			this.downloadPrices(downloadList),
			this.deletePrices(deleteList)
		];

		await Promise.all(promises);
	}

	private async getSupportsSummary() {
		const promises = [
			this.loadRemoteSupportData(),
			this.loadCachedSupportData()
		];

		const arrays = await Promise.all(promises);
		const [remote, local] = arrays.map( this.supportsVersionArrayToMap );

		// Find items we need to download and delete
		const downloadList = Array.from(remote.keys()).filter( key => !local.has(key) ).map( key => remote.get(key) );
		const deleteList = Array.from( local.keys() ).filter( key => !remote.has(key) ).map( key => local.get(key) );

		// Fetch any new prices, and delete obsolete ones
		await this.syncPrices(downloadList, deleteList);

		// Cache a copy of the supports versions.
		if (downloadList.length > 0 || deleteList.length > 0) {
			this._supportVersions = Array.from(remote.values());
			await DBService.nonVolatile.set(SupportsService.supportVersion, this._supportVersions);
		}
		else {
			this._supportVersions = Array.from(local.values());
		}

		return;
	}

	private supportsVersionArrayToMap(src: SupportsVersion[]): Map<string, SupportsVersion> {
		return src.reduce( (acc: Map<string, SupportsVersion>, cur: SupportsVersion) => {
			acc.set(cur.id, cur);
			return acc;
		}, new Map<string, SupportsVersion>() );
	}

	private async fetchRemoteSupportData(): Promise<SupportsVersion[]> {
		const result = await this.restService.get(API.supports, 'summary');
		return result.map( SupportsVersion.parse );
	}

	private async loadRemoteSupportData(): Promise<SupportsVersion[]> {
		return await this._summaryWaitQueue.enqueue( this.fetchRemoteSupportData.bind(this) );
	}

	private async loadCachedSupportData(): Promise<SupportsVersion[]> {
		const response = await DBService.nonVolatile.get(SupportsService.supportVersion);
		return !!response ? response.map( SupportsVersion.parse ) : [];
	}

	private async fetchSupportCategories(): Promise<SupportCategory[]> {
		const response = await this.restService.get(API.supports, 'supportcategories');
		const result = response.map( SupportCategory.parse );
		await DBService.nonVolatile.set(SupportsService.categoryListKey, result);
		return result;
	}

	/**
	* Returns all of the support categories in the system as an array.
	* Uses locally cached data, or fetches from remote server if local is unavailable.
	*/
	public async getSupportCategories(): Promise<SupportCategory[]> {

		const cachedList = await DBService.nonVolatile.get(SupportsService.categoryListKey);
		if (!!cachedList) {
			return cachedList;
		}

		return await this._categoryWaitQueue.enqueue( this.fetchSupportCategories.bind(this) );
	}

	private async doInitialise() {
		const promises: any[] = [
			this.getSupportCategories(),
			this.getSupportsSummary()
		];

		await Promise.all(promises);
		this._initialised = true;
	}

	public async initialise() {
		if (this._initialised) {
			return;
		}

		return await this._initWaitQueue.enqueue( this.doInitialise.bind(this) );
	}

	/**
	* Returns all of the support categories in the system, as a map keyed by support category number.
	*/
	public async getSupportCategoryMap(): Promise<Map<number, SupportCategory>> {
		const result = new Map<number, SupportCategory>();
		const categories = await this.getSupportCategories();
		categories.forEach(category => {
			result.set(category.id, category);
		});
		return result;
	}

	/**
	* Returns a support category, given a support category number
	*/
	public async getSupportCategory(categoryId: number): Promise<SupportCategory> {
		const categories = await this.getSupportCategories();
		return categories.find( item => item.id === categoryId );
	}

	private findStartOfSupportPeriod(date: Date): Date {
		const requestedDate = moment(date);
		let result: moment.Moment = this._supportVersions.reduce( (acc: moment.Moment, cur: SupportsVersion) => {

			// Iterate through the list of support versions. Use the "dateFrom" value of the current iteration
			// if it is a data less than the supplied parameter date, and it is greater than the current
			// result (or the current result is undefined).
			const m = moment(cur.dateFrom);
			if (m.isSameOrBefore(requestedDate) && (!acc || acc.isBefore(m))) {
				acc = m;
			}
			return acc;
		}, undefined);

		return !!result ? result.toDate() : undefined;
	}

	/**
	* Queries IndexedDB for supports that match the specified region, date and optional support category.
	*/
	public async getSupportsFor(region: Region, date: Date, supportItemNumber?: string, categoryId?: number, pace?: boolean): Promise<SupportItem[]> {

		// Make sure we have supports data stored in the DB, but may require a call to the remote server to get the list of
		// support versions.
		await this.initialise();

		try {
			assert(Array.isArray(this._supportVersions));
			assert(this._supportVersions.length > 0);
		}
		catch (e) {
			return [];
		}

		try {
			const dateKey = this.findStartOfSupportPeriod(date);
			assert(dateKey !== undefined);

			const indexName = !!supportItemNumber ? "supportNonUnique" : "plan";
			const keys: any[] = !!supportItemNumber ? [region, dateKey, supportItemNumber] : [region, dateKey];
			const keyRange: IDBKeyRange = IDBKeyRange.only( keys );
			const data = await DBService.supports.query(indexName, keyRange);
			data.sort((a, b) => a.supportItemNumber.localeCompare(b.supportItemNumber));

			const returnData = pace !== undefined ? data.filter((item, index, self) => {
				let duplicateIndex = self.findIndex((otherItem, otherIndex) => (
					(otherIndex !== index) && (otherItem.supportItemNumber === item.supportItemNumber)
				));
				return duplicateIndex === -1 || (pace ? item.supportCategoryId > 15 : item.supportCategoryId <= 15);
			}) : data;

			return categoryId ? returnData.filter( support => support.supportCategoryId === categoryId ) : returnData;
		}
		catch (e) {
			if (e instanceof EAssertionFailed) {
				return [];
			}
		}
	}

	public async getSupport(supportId: string): Promise<SupportItem> {
		await this.initialise();

		try {
			assert(Array.isArray(this._supportVersions));
			assert(this._supportVersions.length > 0);
			assert(!!supportId);
		}
		catch (e) {
			return undefined;
		}

		return await DBService.supports.get(supportId);
	}

	public get initialised(): boolean {
		return this._initialised;
	}
}
