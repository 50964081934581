export enum MessageType {
	success,
	error,
	warning
};

export class AsyncResult {
	readonly type: MessageType = undefined;
	readonly success: boolean = false;
	readonly message: string = undefined;

	constructor(type: MessageType, success: boolean, message: string = undefined) {
		this.type = type;
		this.success = success;
		this.message = message;
	}

	getClass(): string {
		switch (this.type) {
			case MessageType.error:
				return "alert alert-danger";
			case MessageType.success:
				return "alert alert-success";
			case MessageType.warning:
				return "alert alert-warning";
		}
	}
}

export class AsyncSuccess extends AsyncResult {
	constructor(message: string) {
		super(MessageType.success, true, message);
	}
}

export class AsyncError extends AsyncResult {
	constructor(message: string = undefined) {
		super(MessageType.error, false, message);
	}
}
