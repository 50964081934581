import { Component, ViewChild, TemplateRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { SearchService, SearchResult, SearchResultType, SearchResultUtils } from '@services/search.service';
import { Utils } from "@classes/utils";
import { Key } from "@classes/keys";
import { Subject } from 'rxjs';
import { concatWith, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { SearchPreferenceService } from '@services/searchpreference.service';

@Component({
	"selector": "global-search",
	"templateUrl": "./search.component.html",
	"styleUrls": ["./search.component.scss"],
	"host": {
		'(document:click)': 'onClick($event)',
	  },
})
export class GlobalSearchComponent {

	private readonly NONE_SELECTED = -1;

	@ViewChild('searchResultsDialog') private searchResultsDialog: TemplateRef<any>;

	@HostListener('keyup', ['$event'])
	onkeyup(event: KeyboardEvent) {

		switch (event.keyCode) {
			case Key.Escape:
				this.handleEscapeKey();
				event.preventDefault();
				break;

			case Key.Enter:
				this.handleEnterKey(event);
				event.preventDefault();
				break;

			default:
				this.keyup$.next(event);
		}
	}

	constructor(private searchService: SearchService, private router: Router, private searchPreference: SearchPreferenceService) {}

	ngOnInit() {
		this.keyup$.pipe(

			filter( e => this.filterArrowKeys(e) ),
			map( (e: KeyboardEvent) => e.keyCode)

		).subscribe( (keyCode: number) => {

			this.updateSelectedIndex(keyCode);

		});

		this.keyup$.pipe(

			filter( e => !this.filterArrowKeys(e) ),
			concatWith(),
			distinctUntilChanged()

		).subscribe( e => {

			this.showResults = false;

		});

		this.getSearchPreference();
	}

	onClick(event) {
		if (!document.getElementById("header").contains(event.target)) // or some similar check
		  this.showResults = false;
	}

	private async getSearchPreference() {
		let cachedPref = await this.searchPreference.get();
		if(cachedPref !== null) {
			this.searchType = cachedPref;
		}
	}

	ngOnDestroy() {
		this.keyup$.complete();
	}

	public whatTheUserTyped: string;
	public showResults: boolean = false;
	public searchActive: boolean = false;
	private keyup$ = new Subject<KeyboardEvent>();
	protected currentActiveItem: number = this.NONE_SELECTED;
	public searchResults: SearchResult[] = [];

	private currentSearch = {
		"timeoutHandle": undefined,
		"text": undefined
	};

	public resultTypes = {
		"client": SearchResultType.client,
		"user": SearchResultType.user,
		"support_coordinator": SearchResultType.support_coordinator,
		"provider": SearchResultType.provider,
		"bill": SearchResultType.bill,
		"note": SearchResultType.note,
		"admin": SearchResultType.admin
	};

	private textChanged(value: string) {
		this.whatTheUserTyped = value;
	}

	private handleEscapeKey() {
		if (this.showResults && this.searchResults.length > 0) {
			this.showResults = false;
		}
	}

	private filterArrowKeys(e: KeyboardEvent) {
		return [Key.ArrowDown, Key.ArrowUp].includes(e.keyCode);
	}

	private updateSelectedIndex(keyCode: number) {
		if (this.searchResults.length === 0) {
			this.currentActiveItem = this.NONE_SELECTED;
			return;
		}

		const increment = keyCode === Key.ArrowUp ? -1 : 1;
		let idx = this.currentActiveItem += increment;
		if (idx >= this.searchResults.length) {
			idx = 0;
		}
		else if (idx < 0) {
			idx = this.searchResults.length - 1;
		}

		this.currentActiveItem = idx;
		this.scrollSelectedItemIntoView();
	}

	private scrollSelectedItemIntoView() {
		window.setTimeout(() => {
			const activeButton = document.querySelector("button.active");
			if (activeButton) {
				activeButton.scrollIntoView({"behavior": "auto", "block": "nearest"});
			}
		}, 0);
	}

	private handleEnterKey($event: KeyboardEvent) {
		if (this.currentActiveItem > this.NONE_SELECTED && this.showResults) {
			this.selectResult( $event, this.searchResults[this.currentActiveItem], this.currentActiveItem );
		}
		else {
			this.doSearch();
		}
	}

	protected selectResult($event: KeyboardEvent|MouseEvent, item: SearchResult, idx: number) {

		this.currentActiveItem = idx;
		this.whatTheUserTyped = '';
		this.currentSearch.text = '';

		switch (item.type) {
			case SearchResultType.client:
				if ($event.shiftKey) {
					this.router.navigate([`/account/${item.id}`]);
				}
				else {
					this.router.navigate([`/dashboard/${item.id}`]);
				}
				break;

			case SearchResultType.support_coordinator:
				this.router.navigate([`/dashboard/${item.id}`]);
				break;

			case SearchResultType.user:
			case SearchResultType.admin:
				this.router.navigate([`/account/${item.id}`]);
				break;

			case SearchResultType.provider:
				this.router.navigate([`/provider/${item.id}`]);
				break;

			case SearchResultType.bill:
				this.router.navigate([`/billing/${item.id}`]);
				break;

			default:
				break;
		}

		this.showResults = false;
	}

	protected resultTypeName(resultType: SearchResultType): string {
		return SearchResultUtils.toString(resultType);
	}

	public searchType: SearchResultType = this.resultTypes.client;

	public get searchTypeDescription(): string {
		switch (this.searchType) {
			case SearchResultType.client:
				return 'Clients';
			case SearchResultType.user:
				return 'Nominees';
			case SearchResultType.support_coordinator:
				return 'Support Coordinators'; // obsolete
			case SearchResultType.provider:
				return 'Providers';
			case SearchResultType.bill:
				return 'Bills';
			case SearchResultType.admin:
				return 'Staff';
			default:
				return "Search For";
		}
	}

	public changeSearchType(searchType: SearchResultType) {
		this.searchType = searchType;

		this.searchPreference.set(searchType);
	}

	private get searchTypeBang(): string {
		switch (this.searchType) {
			case SearchResultType.client:
				return '!c';
			case SearchResultType.user:
				return '!u';
			case SearchResultType.support_coordinator:
				return '!s'; // obsolete
			case SearchResultType.provider:
				return '!p';
			case SearchResultType.bill:
				return '!b';
			case SearchResultType.admin:
				return '!a';
			default:
				return "";
		}


	}

	public doSearch() {
		let searchTerm = Utils.trimString(this.whatTheUserTyped);

		if (!!searchTerm) {

			if (this.searchType !== undefined) {
				searchTerm = searchTerm.replace(/![csupba]/g, '');
				searchTerm += this.searchTypeBang;
			}

			this.currentSearch.text = searchTerm;
			this.searchActive = true;

			this.searchService.search(this.currentSearch.text).then( results => {
				this.currentActiveItem = this.NONE_SELECTED;
				this.searchResults = results;
				this.showResults = true;
				this.searchActive = false;
			});

			if (this.searchResults.length) {
				this.showResults = true;
			}
		}
	}
}
