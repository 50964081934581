import { Component, OnInit } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { UserType, UserAccount } from "@classes/user";
import { Table, TableColumnHeading, SortType, StaticDataSource } from "@classes/tables";
import { InvoiceService } from "@services/invoice2.service";
import { UserAccountService } from "@services/accounts.service";
import { InvoiceHeader, InvoiceStatus, InvoiceStatusUtils } from '@classes/invoices';
import { StorageService } from "@services/storage.service";

@Component({
	"selector": "invoice-widget",
	"styleUrls": ["./invoiceWidget.component.scss"],
	"templateUrl": "./invoiceWidget.component.html"
})
export class InvoiceWidgetComponent extends PrivateComponent implements OnInit {

	private static readonly userFilterStorageKey = 'billingAlerts.userFilter';

	private _dataLoaded: boolean = false;
	private _admins: UserAccount[] = [];
	private _billingAlerts: InvoiceHeader[] = [];

	get dataLoaded(): boolean { return this._dataLoaded; }

	private readonly tableHeadings: TableColumnHeading[] = [
		{"propName": "status", "displayName": "Status", "sortType": SortType.text },
		{"propName": "clientName", "displayName": "Client", "sortType": SortType.name },
		{"propName": "providerName", "displayName": "Provider", "sortType": SortType.name },
		{"propName": "investigationTypeName", "displayName": "Category", "sortType": SortType.text },
		{"propName": "amount", "displayName": "Amount", "sortType": SortType.numeric },
		{"propName": "createdBy", "displayName": "Created By", "sortType": SortType.name },
		{"propName": "created", "displayName": "Date", "sortType": SortType.date }
	];

	table: Table<InvoiceHeader> = new Table('invoiceWidget', this.tableHeadings);

	constructor(private invoiceService: InvoiceService, private userAccountService: UserAccountService) {
		super();
		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Admin Dashboard', 'Billing Alerts');
	}

	ngOnInit() {
		super.ngOnInit()
		this.loadData();
	}

	private async loadBillingAlerts(): Promise<InvoiceHeader[]> {
		return this.invoiceService.billingAlertsData();
	}

	private async loadAdminAccounts(): Promise<UserAccount[]> {
		return this.userAccountService.getAdminsWithDraftInvoices();
	}

	private filterBillingAlerts(): InvoiceHeader[] {
		const src = this._billingAlerts.filter( invoice => {
			if ([this.filterAnyone, null].includes(this._userFilter)) {
				return true;
			}
			return invoice.createdBy === this._userFilter;
		});

		return src;
	}

	private initaliseUserFilter() {
		const storedValue = StorageService.get(InvoiceWidgetComponent.userFilterStorageKey);
		this._userFilter = storedValue === null ? this.user.id : storedValue;
	}

	private async loadData() {
		this._dataLoaded = false;
		try {

			this.initaliseUserFilter();

			const [data, admins] = await Promise.all([this.loadBillingAlerts(), this.loadAdminAccounts()]);
			this._billingAlerts = data;
			this.table.sourceData = StaticDataSource.from( this.filterBillingAlerts() );
			this._admins = admins.sort( (a, b) => a.name.localeCompare(b.name) );
		}
		catch (e) {
			console.log(e);
		}
		finally {
			this._dataLoaded = true;
		}
	}

	protected billStatusText(status: InvoiceStatus): string {
		return InvoiceStatusUtils.toString(status);
	}

	public get admins(): UserAccount[] {
		return this._admins;
	}

	private _userFilter: string|null = null;

	public get userFilter(): string|null {
		return this._userFilter;
	}

	public set userFilter(value: string|null) {
		this._userFilter = value;
		StorageService.set(InvoiceWidgetComponent.userFilterStorageKey, value);
		this.table.sourceData = StaticDataSource.from( this.filterBillingAlerts() );
	}

	public readonly filterAnyone: string = "-";
}
