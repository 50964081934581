import { Injectable } from '@angular/core';
import { RestService, API } from '@services/rest.service';
import { DateUtils } from "@classes/utils";

interface ReportData {
	date: Date;
	operatorId: string;
	operatorName: string;
	bills: number;
	lines: number;
}

namespace ReportData {
	export function parse(src: any): ReportData {
		return {
			"date": DateUtils.parse(src.date),
			"operatorId": src.operatorId,
			"operatorName": src.operatorName,
			"bills": src.bills,
			"lines": src.lines
		};
	}
}

export interface Operator {
	id: string;
	name: string;
	firstName: string;
	lastName: string;
}

namespace Operator {

	export function parse(src: any): Operator {
		return <Operator>{
			"id": src.operatorId,
			"name": [src.lastName, src.firstName].filter( value => !!value ).join(", "),
			"firstName": src.firstName,
			"lastName": src.lastName
		};
	}
}

export interface OperatorRecord {
	operator: Operator;
	bills: number;
	lines: number;
}

namespace OperatorRecord {

	export function parse(src: any): OperatorRecord {
		return {
			"operator": Operator.parse(src),
			"bills": src.bills,
			"lines": src.lines
		};
	}

	export function empty(operator: Operator): OperatorRecord {
		return {
			"operator": operator,
			"bills": 0,
			"lines": 0
		};
	}
}

export type DataEntryReport = Map<number, Map<string, OperatorRecord>>;

@Injectable({ "providedIn": 'root' })
export class DataEntryReportService {

	constructor(private restService: RestService) {}

	private allOperators(src: any[]): Map<string, Operator> {
		const result = new Map<string, Operator>();

		src.forEach( row => {
			const operator = Operator.parse(row);
			if (!result.has(operator.id)) {
				result.set(operator.id, operator);
			}
		});

		return result;
	}

	public getReport(startDate: Date, endDate: Date, currentStaffOnly: boolean = true): Promise<DataEntryReport> {
		const postData = {
			"startDate": DateUtils.toString(startDate),
			"endDate": DateUtils.toString(endDate),
			"currentStaffOnly": currentStaffOnly
		};

		return this.restService.post(API.reports, 'dataentry', postData).then( response => {

			if (response.length === 0) {
				return Promise.resolve(undefined);
			}

			const report = new Map<number, Map<string, OperatorRecord>>();
			const allOperators = this.allOperators(response);

			response.forEach( row => {

				const date: number = DateUtils.parse(row.date, 'YYYY-MM-DD').valueOf();

				if (!report.has(date)) {
					report.set(date, new Map<string, OperatorRecord>());
					allOperators.forEach( (operator, operatorId) => {
						report.get(date).set(operatorId, OperatorRecord.empty(operator));
					});
				}

				const dateRecord = report.get(date);
				const record = OperatorRecord.parse(row);
				report.get(date).set( record.operator.id, record );
			});

			return Promise.resolve(report);
		});
	}

	public operatorList(report: DataEntryReport): Operator[] {
		const firstRow: Map<string, OperatorRecord> = report.values().next().value;
		return Array.from( firstRow.values() ).map( record => record.operator ).sort( (a: Operator, b: Operator) => {
			if (a.id === null && b !== null) {
				return -1;
			}
			if (b.id === null && a.id !== null) {
				return 1;
			}
			if (a.id === null && b.id === null) {
				return 0;
			}
			return a.name.localeCompare(b.name);
		});
	}
}
