import { Injectable } from '@angular/core';
import { RestService, API } from './rest.service';

@Injectable({ providedIn: 'root' })
export class NDSPService {

	constructor(private restService: RestService) {}


	public loadInvoices(): Promise<any[]> {
		return this.restService.get(API.xero, "ndsp/invoices");
	}

	public markAsPaid(billIds: string[]): Promise<boolean> {
		const postData = {
			"invoices": billIds
		};

		return this.restService.post(API.xero, "ndsp/invoices", postData).then( data => {
			return Promise.resolve(!!data.success);
		});
	}
}
