export interface Entity {
	id: string;
	name: string;
}

export namespace Entity {
	export function parse(src: any): Entity {
		return {
			"id": src.id,
			"name": src.name
		};
	}

	export function toJSON(src: Entity): any {
		return {
			"id": src.id,
			"name": src.name
		};
	}

	export function clone(src: Entity): Entity {
		return {
			"id": src.id,
			"name": src.name
		};
	}

	export function blank(): Entity {
		return {
			"id": undefined,
			"name": undefined
		};
	}
}
