import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { User, UserType } from "@classes/user";
import { PermissionGroup, Permissions } from "@classes/permissions"
import { OverlayService } from "@services/overlay.service";
import { ErrorUtils } from "@classes/errors";
import { Settings } from "@classes/settings";

@Component({
	"selector": "account-permissions",
	"styleUrls": ["./permissions.component.scss"],
	"templateUrl": "./permissions.component.html"
})
export class AccountPermissionsComponent extends PrivateComponent implements OnInit {

	private _user: User;
	public allPermissions: PermissionGroup[] = [];
	protected model: any = {};

	protected adminGroupId: string;

	constructor() {
		super();
		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Account Details', 'View Permissions');
	}

	ngOnInit() {
		super.ngOnInit();
	}

	@Input()
	set account(value: User) {
		if (value !== undefined) {
			this._user = value;
			this.loadData();
		}
	}

	@Output()
	onSave: EventEmitter<User> = new EventEmitter<User>();

	private loadData() {
		this.allPermissions = Permissions.allPermissions();

		// Initialise the model as a map of all permissions as key
		this.model = this.allPermissions.reduce( (acc, group) => {

			group.permissions.forEach( (id, value) => {
				acc[id] = this._user.hasPermission(id);
			});

			return acc;
		}, {});

		const suData = Settings.instance.get('superuser');
		if (suData) {
			this.adminGroupId = suData.group;
		}
	}

	protected getPermissionValues(group: PermissionGroup): string[] {
		return Array.from( group.permissions.keys() );
	}

	protected getPermissionId(group: PermissionGroup, value: string): string {
		return group.permissions.get(value);
	}

	public get canEdit(): boolean {
		return this.hasPermission('Account Details', 'Edit Permissions');
	}

	protected savePermissions() {
		const newPermissions = Object.keys(this.model).reduce( (acc, key) => {
			if (this.model[key]) {
				acc.push(key);
			}

			return acc;
		}, []);

		OverlayService.show();
		this.userService.savePermissions(this._user.id, newPermissions).then( user => {

			OverlayService.hide();
			this.onSave.emit(user);

		}).catch( err => {

			OverlayService.hide();
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(err, "An unknown error occurred") );

		});
	}
}
