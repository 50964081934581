import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@services/user.service';
import { DataExchangeService } from '@services/dataexchange.service';
import { Auth } from 'aws-amplify';
import { OverlayService } from '@services/overlay.service';
import { Utils } from "@classes/utils";

@Component({
	"templateUrl": "./resetpassword.component.html",
	"styleUrls": ["./resetpassword.component.scss"]
})
export class ResetPasswordComponent implements OnInit {

	private _hidePassword: boolean = true;
	private resetData: any;

	get hidePassword(): boolean {
		return this._hidePassword;
	}

	toggleHidePassword(): void {
		this._hidePassword = !this._hidePassword;
	}

	model = {
		"username": "",
		"resetCode": "",
		"password": "",
		"confirmpwd": ""
	}

	private _hasUsername: boolean = false;
	get hasUsername(): boolean {
		return this._hasUsername;
	}

	private self: ResetPasswordComponent = this;

	constructor(private userService: UserService, private router: Router) {}

	ngOnInit() {
		if (this.userService.isLoggedIn()) {
			this.router.navigate(["/dashboard"]);
		}

		this.resetData = DataExchangeService.get("password.reset", true) || {};
		this._hasUsername = !!this.resetData.username;

	}

	getDeliveryMethod(): string {

		const method = this.resetData.method || undefined;

		const responseMap = {
			"SMS": " to your mobile phone",
			"EMAIL": " to your email address"
		};

		return responseMap[method] || "";
	}

	private passwordResetSuccess(data: any): Promise<any> {
		return this.userService.login(this.resetData.username || this.model.username, this.model.password).then( () => {
			this.router.navigate(["/dashboard"]);
			return;
		});
	}

	doReset(): void {
		OverlayService.show(`Resetting your password${Utils.ellipsis}`);
		Auth.forgotPasswordSubmit(this.resetData.username || this.model.username, this.model.resetCode, this.model.password).then( data => {

			return this.passwordResetSuccess(data);

		}).catch(err => {

			console.log(err);
			OverlayService.showError("Unable to reset your password", err.message || "Invalid code provided, please request a new code.");

		});
	}
}
