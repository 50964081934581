import { Component, Input, ViewEncapsulation } from '@angular/core';
import { PaginatedTable } from '@classes/tables';

@Component({
	"selector": "pagination",
	"styleUrls": ["./pagination.component.scss"],
	"templateUrl": "./pagination.component.html",
	"encapsulation": ViewEncapsulation.None
})
export class PaginationComponent {

	private _provider: PaginatedTable<any>;
	private _disabled: boolean = false;

	@Input()
	set provider(value: PaginatedTable<any>) {
		this._provider = value;
	}

	@Input()
	set disabled(value: boolean) {
		this._disabled = value;
	}

	@Input() align?: string = 'left';

	get provider(): PaginatedTable<any> {
		return this._provider;
	}

	get disabled(): boolean {
		return this._disabled;
	}

	first(): void {
		this._provider.currentPage = 1;
	}

	previous(): void {
		if (this._provider.currentPage > 1) {
			this._provider.currentPage -= 1;
		}
	}

	next(): void {
		if (this._provider.currentPage < this._provider.numPages) {
			this._provider.currentPage += 1;
		}
	}

	last(): void {
		this._provider.currentPage = this._provider.numPages;
	}
}
