import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrivateComponent } from "@classes/private.component";
import { UserType } from "@classes/user";
import { ErrorUtils } from "@classes/errors";
import { LineItemStatus, LineItemStatusUtils } from "@classes/invoices";
import { MenuBuilder } from "@services/navmenu.service";
import { Bank, ProdaService, ProdaSubmissionItem, ProdaSubmissionHistory } from "@services/proda.service";
import { OverlayService } from "@services/overlay.service";
import { InvoiceService } from "@services/invoice2.service";
import { ProviderService, Provider } from "@services/provider.service";
import { SupportDataType, SupportDataItem } from "@classes/invoices";
import { Settings } from "@classes/settings";
import moment from "moment";

@Component({
	"styleUrls": ["./reconcilliation.component.scss"],
	"templateUrl": "./reconcilliation.component.html"
})
export class ProdaReconcilliationComponent extends PrivateComponent implements OnInit {

	@ViewChild('addNoteDialog') private addNoteDialog: TemplateRef<any>;
	@ViewChild('resubmitDialog') private resubmitDialog: TemplateRef<any>;
	@ViewChild('resetInvoiceDialog') private resetInvoiceDialog: TemplateRef<any>;
	@ViewChild('resetLineItemDialog') private resetLineItemDialog: TemplateRef<any>;
	@ViewChild('deleteLineItemDialog') private deleteLineItemDialog: TemplateRef<any>;
	@ViewChild('confirmReconcileDialog') private confirmReconcileDialog: TemplateRef<any>;
	@ViewChild('confirmRejectDialog') private confirmRejectDialog: TemplateRef<any>;


	private _dataLoaded: boolean = false;
	private _submissionItem: ProdaSubmissionItem = undefined;
	private _history: ProdaSubmissionHistory[] = undefined;
	private _provider: Provider = undefined;

	private _invoiceSupportData: SupportDataType[] = undefined;

	private async getInvoiceSupportData(): Promise<void> {
		this._invoiceSupportData = await this.invoiceService.getInvoiceSupportData();
	}

	private inputParams: any = {};
	protected resubmit: any = {};

	public newNote: string = undefined;
	public investigationType: string = undefined;

	public showProvider(): void {
		if (this._submissionItem.providerId) {
			this.router.navigate([`/provider/${this._submissionItem.providerId}`]);
		}
	}

	public showInvoice(): void {
		if (this._submissionItem.invoiceId) {
			this.router.navigate([`/billing/${this._submissionItem.invoiceId}`]);
		}
	}

	public get isDiscrepancy(): boolean {
		return !!this._submissionItem && this._submissionItem.status === LineItemStatus.discrepancy;
	}

	public get isError(): boolean {
		return !!this._submissionItem && this._submissionItem.status === LineItemStatus.error;
	}

	public get hasResponse(): boolean {
		return !!this._submissionItem && this._submissionItem.status !== LineItemStatus.submitted;
	}

	public get isRejected(): boolean {
		return !!this._submissionItem && this._submissionItem.status === LineItemStatus.rejected;
	}

	private get partialPayment(): boolean {
		if (!this._submissionItem) {
			return false;
		}

		const d = Math.abs(this._submissionItem.amountClaimed - this._submissionItem.amountPaid);
		return !!this._submissionItem && (d >= 0.01 && d < this._submissionItem.amountClaimed);
	}

	public get history(): ProdaSubmissionHistory[] {
		return this._history;
	}

	public get itemStatus(): string {
		if (!this._submissionItem) {
			return "";
		}

		return LineItemStatusUtils.toString(this._submissionItem.status);
	}

	public get finalised(): boolean {
		return this._submissionItem && this._submissionItem.resolved || false;
	}

	private get displayResubmitButton(): boolean {
		// This is to hide or display the Resubmit button. If the setting is not in the settings table,
		// default logic is to display the Resubmit button

		// Not enabled if the data hasn't yet been loaded
		if (!this._submissionItem) {
			return true;
		}

		// Not enabled if it's not explicitly enabled in the settings
		const settings = Settings.instance;
		if (!settings.has('proda')) {
			return true;
		}

		const value = settings.get('proda');
		if (typeof(value.resubmitDiscrepancy) == "undefined") {
			return true;
		}
		if (!value.resubmitDiscrepancy) {
			return false;
		}

		// Only enabled if there are all items in the file are resolved and there are no errors
		return true;
	}

	private loadReconcilliationHistory(responseId: string): Promise<any> {
		return this.prodaService.getSubmissionHistory(responseId).then( result => {
			this._history = result;
			return Promise.resolve();
		});
	}

	public formatDate(date: Date): string {
		const m = moment(date);
		return m.format("LLLL");
	}

	private async loadData(submissionId: string, submissionItemId: string): Promise<any> {
		this._dataLoaded = false;
		OverlayService.show();

		try {
			this._submissionItem = await this.prodaService.getSubmissionItem(submissionId, submissionItemId);
			const promises = [
				this.loadReconcilliationHistory(submissionItemId),
				this.providerService.getProvider(this._submissionItem.providerId),
				this.getInvoiceSupportData()
			];
			// BD: workaround, throwing error in ng build, doesn't like trailing commas
			let y, x;
			[y,this._provider,x] = await Promise.all(promises);
			this._dataLoaded = true;
		}
		catch (err) {
			console.log(err);
		}
		finally {
			OverlayService.hide();
		}
	}

	get claimReference(): string { return this._submissionItem ? this._submissionItem.claimReference : ""; }
	get ndisNumber(): string { return this._submissionItem ? this._submissionItem.ndisNumber : ""; }
	get clientName(): string { return this._submissionItem ? this._submissionItem.clientName : ""; }
	get invoiceNumber(): string { return this._submissionItem ? this._submissionItem.invoiceNumber : ""; }
	get supportItem(): string { return this._submissionItem ? this._submissionItem.supportItem : ""; }
	get supportItemNumber(): string { return this._submissionItem ? this._submissionItem.supportItemNumber : ""; }
	get supportsDeliveredFrom(): string {
		return this._submissionItem ? moment(this._submissionItem.supportsDeliveredFrom).format('DD-MMM-YYYY') : "";
	}
	get amountClaimed(): number { return this._submissionItem ? this._submissionItem.amountClaimed : 0.00; }
	get quantity(): number { return this._submissionItem ? this._submissionItem.quantity : 0.00; }
	get priceLimit(): number { return this._submissionItem ? this._submissionItem.priceLimit : 0.00; }
	get amountPaid(): number { return this._submissionItem ? this._submissionItem.amountPaid : 0.00; }
	get discrepancy(): number { return this._submissionItem ? this._submissionItem.discrepancy || 0.00 : 0.00; }
	get hasResponseItem(): boolean { return this._submissionItem ? !!this._submissionItem.successFileItemId : false; }
	//get enriteAccount(): string { return this._submissionItem ? this._submissionItem.enriteAccountName : ""; }
	get reimbursement(): boolean { return this._submissionItem ? this._submissionItem.reimbursement : false; }
	get providerName(): string { return this._provider ? this._provider.name : ""; }
	get hasProvider(): boolean { return this._submissionItem ? !!this._submissionItem.providerId : false; }
	get providerABN(): string { return this._provider ? this._provider.abn : ""; }

	constructor(
		private prodaService: ProdaService,
		private providerService: ProviderService,
		private invoiceService: InvoiceService,
		private route: ActivatedRoute) {
		super();
		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Proda', 'PRODA Access');
	}

	private buildMenu(): void {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.done();
	}

	ngOnInit() {
		super.ngOnInit()

		this.buildMenu();

		this.route.params.subscribe( params => {

			// If an invoice Id has been specified on the path, load the invoice data
			const submissionId = params.submissionId;
			const submissionItemId = params.submissionItemId;
			if (submissionId !== undefined && submissionItemId !== undefined && this.user) {

				this.inputParams = {
					"submissionId": submissionId,
					"submissionItemId": submissionItemId
				};

				this.loadData(submissionId, submissionItemId);
			}
		});
	}

	public addNote(): Promise<any> {
		const noteContent = (this.newNote || "").trim();
		if (this.newNote) {
			OverlayService.show();

			return this.prodaService.addHistory(this._submissionItem.id, this.newNote).then( () => {

				this.newNote = undefined;
				OverlayService.hide();
				this.loadData(this.inputParams.submissionId, this.inputParams.submissionItemId);

			}).catch( err => {

				OverlayService.hide();
				console.log(err);
				OverlayService.showError("Error", "Unable to save comment");

			});
		}
		else {
			return Promise.resolve();
		}
	}

	public finalise(value: boolean, backToList: boolean = false): void {
		OverlayService.show();

		this.addNote().then( () => {

			return this.prodaService.finaliseResponseItem(this.inputParams.submissionId, this._submissionItem.id, this._submissionItem.responseItemId, value);

		}).then( () => {

			OverlayService.hide();

			return this.loadData(this.inputParams.submissionId, this.inputParams.submissionItemId);

		}).then( () => {

			if (backToList) {
				this.navigateBackToList();
			}

		}).catch( err => {

			OverlayService.hide();
			console.log(err);

		});
	}

	private navigateBackToList(): void {
		this.router.navigate([`/proda/submission/${this.inputParams.submissionId}`], {"skipLocationChange": true});
	}

	public showNoteDialog(): void {
		OverlayService.showTemplate(this.addNoteDialog);
	}

	protected closeDialog(): void {
		OverlayService.hide();
	}

	private sharedErrorHandler(e): void {
		OverlayService.hide();
		OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "An unknown error occurred"));
	}

	public confirmReconcileItem(): void {
		this.newNote = undefined;
		this.investigationType = undefined;
		OverlayService.showTemplate(this.confirmReconcileDialog);
	}

	protected reconcileItem(): void {
		OverlayService.show();
		this.prodaService.reconcileItem(this._submissionItem.id, this.newNote).then( () => {

			OverlayService.hide();
			this.router.navigate([`/proda/submission/${this.inputParams.submissionId}`], {"skipLocationChange": true});

		}).catch( e => {

			this.sharedErrorHandler(e);

		});
	}

	public confirmRejectItem(): void {
		this.newNote = undefined;
		this.investigationType = undefined;
		OverlayService.showTemplate(this.confirmRejectDialog);
	}

	protected rejectItem(): void {
		OverlayService.show();
		this.prodaService.rejectItem(this._submissionItem.id, this.newNote, this.investigationType).then( () => {

			OverlayService.hide();
			this.router.navigate([`/proda/submission/${this.inputParams.submissionId}`], {"skipLocationChange": true});

		}).catch( e => {

			this.sharedErrorHandler(e);

		});
	}

	protected resubmitItem(): void {
		OverlayService.show();
		this.prodaService.resubmitItem(this._submissionItem.id, Number(this.resubmit.quantity), Number(this.resubmit.rate), Number(this.resubmit.total), this.newNote).then( () => {

			OverlayService.hide();
			this.router.navigate([`/proda/submission/${this.inputParams.submissionId}`], {"skipLocationChange": true});

		}).catch( e => {

			this.sharedErrorHandler(e);

		});
	}

	public showResubmitDialog(): void {
		this.resubmit = {
			"quantity": 1,
			"total": this.discrepancy.toFixed(2),
			"rate": this.discrepancy.toFixed(2)
		};
		this.newNote = undefined;
		this.investigationType = undefined;

		OverlayService.showTemplate(this.resubmitDialog);
	}

	protected get resubmitTotalsInvalid(): boolean {
		const quantity = Number(this.resubmit.quantity);
		const rate = Number(this.resubmit.rate);
		const total = Number(this.resubmit.total);

		if (isNaN(quantity) || isNaN(rate) || isNaN(total)) {
			return true;
		}

		if (quantity === 0 || rate === 0 || total === 0) {
			return true;
		}

		if (Math.abs(total - (quantity * rate)) > 0.01) {
			return true;
		}

		return false;
	}

	protected resetInvoice(openInvoice: boolean) {
		OverlayService.show();
		this.prodaService.resetInvoice(this._submissionItem.id).then( invoiceId => {

			OverlayService.hide();
			if (openInvoice) {
				this.router.navigate([`/billing/${invoiceId}`]);
			}
			else {
				this.router.navigate([`/proda/submission/${this.inputParams.submissionId}`], {"skipLocationChange": true});
			}

		}).catch( e => {

			this.sharedErrorHandler(e);

		});
	}

	protected resetLineitem(openInvoice: boolean) {
		OverlayService.show();
		this.prodaService.resetLineitem(this._submissionItem.id).then( invoiceId => {

			OverlayService.hide();
			if (openInvoice) {
				this.router.navigate([`/billing/${invoiceId}`]);
			}
			else {
				this.router.navigate([`/proda/submission/${this.inputParams.submissionId}`], {"skipLocationChange": true});
			}

		}).catch( e => {

			this.sharedErrorHandler(e);

		});
	}

	protected confirmResetInvoice() {
		OverlayService.showTemplate(this.resetInvoiceDialog);
	}

	public confirmResetLineItem() {
		OverlayService.showTemplate(this.resetLineItemDialog);
	}

	public confirmDeleteItem() {
		OverlayService.showTemplate(this.deleteLineItemDialog);
	}

	public deleteItem() {
		OverlayService.show();
		this.prodaService.deleteLineItem(this._submissionItem.id).then( invoiceId => {

			OverlayService.hide();
			this.router.navigate([`/proda/submission/${this.inputParams.submissionId}`], {"skipLocationChange": true});

		}).catch( e => {

			this.sharedErrorHandler(e);

		});
	}

	public get investigationTypes(): SupportDataItem[] {
		if (!this._invoiceSupportData) {
			return [];
		}

		const tag = 'invType';
		const invType: SupportDataType[] = this._invoiceSupportData.filter( (supportDataTypeItem: SupportDataType) => supportDataTypeItem.tag === tag);
		return invType[0].supportData;
	}
}
