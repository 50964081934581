import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { OverlayService } from '@services/overlay.service';
import { FileNotesService } from '@services/filenotes.service';
import { PrivateComponent } from "@classes/private.component";
import { TaskSummaryItem } from "@classes/filenotes";
import { Table, TableColumnHeading, SortType, StaticDataSource, Alignment } from "@classes/tables";
import { UserType } from "@classes/user";
import { SelectedUser } from "../tasks.component";

@Component({
	"selector": "task-summary",
	"styleUrls": ["summary.component.scss"],
	"templateUrl": "./summary.component.html"
})
export class TaskSummaryComponent extends PrivateComponent implements OnInit {

	private _dataLoaded: boolean = false;
	private _src: any[] = [];
	private _selectedUser: SelectedUser = undefined;

	@Output()
	userChange = new EventEmitter<SelectedUser>();

	@Input()
	public set initialiseUser(value: SelectedUser) {
		this._selectedUser = value;
	}

	private readonly tableHeadings: TableColumnHeading[] = [
		{"propName": "name", "displayName": "User", "sortType": SortType.text },
		{"propName": "open", "displayName": "Open", "sortType": SortType.numeric, "alignment": Alignment.center },
		{"propName": "overdue", "displayName": "Overdue", "sortType": SortType.numeric, "alignment": Alignment.center },
		{"propName": "total", "displayName": "Total", "sortType": SortType.numeric, "alignment": Alignment.center }
	];

	table: Table<TaskSummaryItem> = new Table('tasksummary', this.tableHeadings);

	get dataLoaded(): boolean {
		return this._dataLoaded;
	}

	get selectedUserId(): string {
		return this._selectedUser && this._selectedUser.id;
	}

	constructor(private filenotesService: FileNotesService) {
		super();
		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Admin Dashboard', 'All Alerts');
	}

	ngOnInit() {
		super.ngOnInit();
		this.loadData();
	}

	private async loadData() {
		this._dataLoaded = false;
		if (this.user) {
			try {
				const result = await this.filenotesService.taskSummary();
				this.table.sourceData = StaticDataSource.from(result.filter((t) => { return (t.notStarted || t.open || t.overdue || t.total); }).sort( (a, b) => {
					if (a.userId === null && b.userId === null) {
						return a.name.localeCompare(b.name);
					}
					return a.userId === null ? -1 : 1;
				}));

				this._dataLoaded = true;
			} catch (e) {
				console.log(e);
			}
		}
	}

	get selectedUser(): SelectedUser {
		return this._selectedUser;
	}

	public selectUser(userId: string, name: string) {
		if (this.selectedUserId === userId) {
			this._selectedUser = undefined;
		}
		else {
			this._selectedUser = {"id": userId, "name": name};
		}
		this.userChange.next(this._selectedUser);
	}

	public refreshSignal(): void {
		this.loadData();
	}

	public closeDialog(): void {
		OverlayService.hide();
	}
}
