import { Component, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { PrivateComponent } from "@classes/private.component";
import { MenuBuilder } from "@services/navmenu.service";
import { User, UserType } from "@classes/user";
import { Plan, PlanStatus, PlanSupportCategory } from "@classes/plans";
import { PlanService } from "@services/plan.service";
import { ProviderSpend } from "@classes/providerSpend";
import { PlanBudget } from "@classes/budget";
import { SupportCategory } from "@classes/supports";
import { SupportsService } from "@services/supports.service";
import moment from 'moment';


@Component({
	"styleUrls": ["./planUtilisation.component.scss"],
	"templateUrl": "./planUtilisation.component.html"
})
export class PlanUtilisationComponent extends PrivateComponent implements OnInit {
	private readonly dateFormat: string = 'DD/MM/YYYY';

	private _dataLoaded: boolean = false;
	private _clientId: string|undefined = undefined;
	private _client: User = undefined;
	private _plans: Plan[] = [];
	private _supportCategories: SupportCategory[] = [];
	private _providerSpend: ProviderSpend[] = [];
	private _planUtilisationData: string = '';

	get isAdmin(): boolean {
		return this.user.accountType === UserType.Admin;
	}

	get supportCategories(): SupportCategory[] {
		return this._supportCategories;
	}

	get supportCategoriesLoaded(): boolean {
		return this._supportCategories.length > 0;
	}

	get planUtilisationData(): string {
		return this._planUtilisationData;
	}

	private async loadPlans(userId: string): Promise<void> {
		this._plans = await this.planService.listPlans(userId);
	}

	private async loadSupportCategories(): Promise<void> {
		this._supportCategories = await this.supportsService.getSupportCategories();
	}

	private async generatePlanUtilisationData() {
		if (this.plan === undefined) {
			return;
		}

		try {
			console.log("CLIENT", this._client);
			console.log("PLAN", this.plan);
			console.log("SUPPORT CATEGORIES", this._supportCategories);

			let clientName = `${this._client.givenName || this._client.firstName || ''} ${this._client.lastName || ''} - ${this._client.ndisNumber || ''}`;
			let planStart = moment(this.plan.startDate).format(this.dateFormat);
			let planEnd = moment(this.plan.endDate).format(this.dateFormat);

			let planData = [];

			//let headers = ['Name and NDIS Number','Budget','Plan Start Date', 'Plan End Date', 'Total Budget', 'Spending'];
			 //headers.join('\t')];

			// total categories
			planData.push(`${clientName}\tAll Categories\t${planStart}\t${planEnd}\t${this.plan.budget.total}\t${(this.plan.budget.paid+this.plan.budget.unknown)}`);
			// QUESTION: SHOULD TOTAL EXCLUDE PENDING BUDGETS

			// flexible core categories
			let coreSupports = this.plan.supportCategories.filter(cat =>  cat.supportCategory.id == 1 || cat.supportCategory.id == 2 || cat.supportCategory.id == 3 || cat.supportCategory.id == 4 || cat.supportCategory.id == 16 );
			let coreTotal = coreSupports.reduce((a, b) => a + (b.categoryBudget.total || 0), 0);
			let coreSpend = coreSupports.reduce((a, b) => a + (b.categoryBudget.paid || 0), 0) + coreSupports.reduce((a, b) => a + (b.categoryBudget.unknown || 0), 0);
			planData.push(`${clientName}\tFlexible Core Categories (CR)\t${planStart}\t${planEnd}\t${ (coreTotal || 'N/A') }\t${ (coreTotal ? coreSpend : 'N/A')}`);

			// each category
			this._supportCategories.forEach(category => {
				let supportInPlan = this.plan.supportCategories.find(cat =>  cat.supportCategory.id == category.id );
				let total = !!supportInPlan && !!supportInPlan.total ? supportInPlan.total : 'N/A';
				let spending = !!supportInPlan && !!supportInPlan.total ? (supportInPlan.categoryBudget.paid || 0) + (supportInPlan.categoryBudget.unknown || 0): 'N/A';
				let data = [];
				data.push(clientName);
				data.push(`${category.name} (${(category.id).toString().padStart(2, '0')})`);
				data.push(`${planStart}`);
				data.push(`${planEnd}`);
				data.push(`${total}`);
				data.push(`${spending}`);
				let line = data.join('\t');

				planData.push(line);
			});
			this._planUtilisationData = planData.join('\n');
			console.log(this._planUtilisationData);
		}
		catch (e) {
			console.log(e);
		}
	}

	private async loadClient(clientId: string) {
		this._client = await this.userService.loadUser(clientId);
	}

	private async loadData() {
		this._dataLoaded = false;
		try {

			await Promise.all([
				this.loadPlans(this._clientId),
				this.loadClient(this._clientId),
				this.loadSupportCategories()
			]);

			this.plan = this.currentPlan || this.plans.length ? this.plans[this.plans.length - 1] : undefined;
			if (this.plan !== undefined) {
				this.generatePlanUtilisationData();
			}
		}
		finally {
			this._dataLoaded = true;
		}
	}

	constructor(
		private route: ActivatedRoute, 
		private planService: PlanService,
		private supportsService: SupportsService
		) {
		super();
	}

	private buildMenu() {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.done();
	}

	ngOnInit() {
		super.ngOnInit();

		this.route.params.subscribe( params => {

			if (this.user) {
				this._clientId = params.clientId;
				this.loadData();
			}
		});

		this.buildMenu();
	}

	public get plans(): Plan[] {
		return this._plans.filter( plan => plan.status !== PlanStatus.proposed );
	}

	public get spendByProvider(): any {
		let data = this._providerSpend;

		const res = data.reduce((acc, curr) => {
			if ( !acc[curr.providerId] ) {
				acc[curr.providerId] = { 
					spends: [],
					providerId: curr.providerId,
					providerName: curr.providerName,
					providerABN: curr.providerABN,
					categories: [],
					totalSpend: 0,
					numInvoices: 0,
				};
			}
			acc[curr.providerId].spends.push(curr);
			acc[curr.providerId].categories.push(curr.supportCategoryNumber);
			acc[curr.providerId].totalSpend += curr.totalSpend;
			acc[curr.providerId].numInvoices += curr.numInvoices;
			return acc;
		},{});
		return Object.values(res);
	}

	public planSelected() {
		this.generatePlanUtilisationData();
	}

	private get currentPlan(): Plan {
		return this._plans.find( plan => plan.status === PlanStatus.current );
	}

	public readonly planStatus: (plan: PlanStatus) => string = PlanStatus.toString;
	public plan: Plan|undefined = undefined;

	public get dataLoaded(): boolean {
		return this._dataLoaded;
	}

	public get planSupportCategories(): PlanSupportCategory[] {
		return this.plan.supportCategories;
	}

	public planSupportCategory(categoryId: number): PlanSupportCategory {
		return this.plan.supportCategories.find( item => item.supportCategory.id === categoryId );
	}

	public spendCategory(categoryId: number): ProviderSpend[] {
		return this._providerSpend.filter( item => item.supportCategoryNumber === categoryId );
	}

	public paidAmount(budget: PlanBudget): number {
		return budget.paid + budget.unknown;
	}

	public remaining(budget: PlanBudget): number {
		return budget.total - (budget.paid + budget.unknown);
	}

	public get clientName(): string {
		return this._client && `${this._client.firstName} ${this._client.lastName}` || "";
	}

	public get clientId(): string {
		return this._clientId || "";
	}

	public get currentPlanId(): string {
		return this.plan.id || "";
	}

	public get ndisNumber(): string {
		return this._client && this._client.ndisNumber || "";
	}

	/* To copy Text from Textbox */
	public copyInput(inputElement){
		inputElement.select();
		document.execCommand('copy');
		inputElement.setSelectionRange(0, 0);
	}

	public showProviderInvoices(event: MouseEvent, providerId: string) {
		event.cancelBubble = true;
		this.router.navigate([`/invoices/${this._client.id}/${providerId}/${this.currentPlan.id}`]);
	}
}
