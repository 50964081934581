import { Component, OnInit } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { UserType } from '@classes/user';
import { MenuBuilder } from "@services/navmenu.service";
import { DataEntryReportService, DataEntryReport, Operator, OperatorRecord } from "@services/reports/dataEntryReport.service";
import { OverlayService } from "@services/overlay.service";
import { saveAs } from 'file-saver';
import { DateUtils } from "@classes/utils";
import moment from 'moment';

enum DisplayMode {
	bills, lines
}

@Component({
	"templateUrl": "./dataentry.component.html",
	"styleUrls": ["./dataentry.component.scss"]
})
export class DataEntryReportComponent extends PrivateComponent implements OnInit {

	private _dataLoaded: boolean = false;

	private sourceData: DataEntryReport;

	private _operators: Operator[] = undefined;
	private _dates: number[] = undefined;

	public get hasData(): boolean {
		return this.sourceData !== undefined;
	}

	public reportPeriods = [
		{"name": "This week", "value": 0},
		{"name": "Last week", "value": 1},
		{"name": "This month", "value": 2},
		{"name": "Last month", "value": 3},
		{"name": "Custom", "value": 4}
	];

	public readonly displayMode: any = {
		"bills": DisplayMode.bills,
		"lines": DisplayMode.lines
	};

	public mode: DisplayMode = DisplayMode.bills;
	public currentStaffOnly: boolean = true;

	private readonly dateFormat: string = 'DDMMYYYY';

	public model = {
		"currentStaffOnly": true,
		"reportPeriod": 0,
		"startDate": moment().startOf("week").format(this.dateFormat),
		"endDate": moment().endOf("week").format(this.dateFormat)
	};

	constructor(private reportsService: DataEntryReportService) {
		super();

		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Reports', 'Billing Entry Report');
	}

	ngOnInit() {
		super.ngOnInit();

		if (this.user) {
			this.buildMenu();
		}
	}

	public reportPeriodChange(value: number) {
		switch (value) {
			case 0:
				this.model.startDate = moment().startOf('week').format(this.dateFormat);
				this.model.endDate = moment().endOf('week').format(this.dateFormat);
				break;
			case 1:
				this.model.startDate = moment().startOf('week').subtract(1, 'week').format(this.dateFormat);
				this.model.endDate = moment().endOf('week').subtract(1, 'week').format(this.dateFormat);
				break;
			case 2:
				this.model.startDate = moment().startOf('month').format(this.dateFormat);
				this.model.endDate = moment().endOf('month').format(this.dateFormat);
				break;
			case 3:
				this.model.startDate = moment().startOf('month').subtract(1, 'month').format(this.dateFormat);
				this.model.endDate = moment().endOf('month').subtract(1, 'month').format(this.dateFormat);
				break;
		}
	}

	get dataLoaded(): boolean {
		return this._dataLoaded;
	}

	get operators(): Operator[] {
		if (this._operators === undefined) {
			this._operators = this.reportsService.operatorList(this.sourceData);
		}
		return this._operators;
	}

	get dates(): number[] {
		if (this._dates === undefined) {
			this._dates = Array.from( this.sourceData.keys() );
		}

		return this._dates;
	}

	private displayValue(operator: Operator, date: number): number {
		const item = this.sourceData.get(date).get(operator.id)
		const value = this.mode === DisplayMode.lines ? item.lines : item.bills
		return value === 0 ? undefined : value;
	}

	public displayBills(operator: Operator, date: number): number {
		const item = this.sourceData.get(date).get(operator.id)
		const value = item.bills;
		return value === 0 ? undefined : value;
	}

	public displayLines(operator: Operator, date: number): number {
		const item = this.sourceData.get(date).get(operator.id)
		const value = item.lines;
		return value === 0 ? undefined : value;
	}

	public formatDate(value: number): string {
		return moment(value).format('DD/MM/YYYY');
	}

	private buildMenu(): void {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.addHandler(
			'file-excel',
			'Download',
			() => { this.downloadReport(); },
			() => { return this.downloadDisabled(); }
		);
		menuBuilder.done();
	}

	private downloadDisabled(): boolean {
		return !this._dataLoaded || !this.hasData;
	}

	public async loadData() {
		this._dataLoaded = false;
		this.sourceData = undefined;
		this._operators = undefined;
		this._dates = undefined;
		try {
			OverlayService.show();
			this.sourceData = await this.reportsService.getReport(DateUtils.parse(this.model.startDate, this.dateFormat), DateUtils.parse(this.model.endDate, this.dateFormat), this.model.currentStaffOnly);
			OverlayService.hide();
		}
		catch (e) {
			OverlayService.hide();
			OverlayService.showError("Error", "Unable to display report");
			console.log(e);
		}
		finally {
			this._dataLoaded = true;
		}
	}


	private downloadReport(): void {
		const downloader = new ReportDownloader(this.sourceData, this.reportsService, this.mode);
		downloader.downloadReport();
	}

	public get reportSettingsInvalid(): boolean {

		// "This week", "last week", "this month", "last month" all should be good to go
		if ([0, 1, 2, 3].includes(this.model.reportPeriod)) {
			return false;
		}

		const startDate = DateUtils.parseMoment(this.model.startDate, this.dateFormat);
		const endDate = DateUtils.parseMoment(this.model.endDate, this.dateFormat);
		const now = moment();

		if ([null, undefined].includes(startDate) || [null, undefined].includes(endDate)) {
			return true;
		}

		if (!startDate.isValid() || !endDate.isValid()) {
			return true;
		}

		if (startDate.isAfter(endDate)) {
			return true;
		}

		if (startDate.isAfter(now)) {
			return true;
		}

		return false;
	}
}


class ReportDownloader {
	private readonly operators: Operator[];
	private readonly dates: number[];

	public constructor(private data: DataEntryReport, private reportsService: DataEntryReportService, private mode: DisplayMode) {
		this.operators = this.reportsService.operatorList(this.data);
		this.dates = Array.from( this.data.keys() );
	}

	private getHeader(): string {
		const line1Elements = [''].concat( this.operators.map( operator => '"' + [operator.lastName, operator.firstName].filter( value => !!value ).join(", ") + '",' ) );
		const line2Elements = ['Date'].concat( this.operators.map( operator => 'Bills,Lines' ) );

		return [line1Elements.join(","), line2Elements.join(",")].join("\n");
	}

	private formatDate(value: number): string {
		return moment(value).format('YYYY-MM-DD');
	}

	private constructCSV(): string {
		let rows: string[] = [ this.getHeader() ];

		rows = rows.concat( this.dates.map( date => {
			let result: string[] = [ this.formatDate(date) ];
			const row = this.data.get(date);

			result = result.concat( this.operators.map( operator => {
				const item: OperatorRecord = row.get(operator.id);
				return `${item.bills},${item.lines}`;
			}));

			return result.join(",");
		}) );

		return rows.join("\n");
	}

	public downloadReport(): void {
		const csv = this.constructCSV();
		const file = new Blob([csv], { "type": "text/csv;charset=utf-8" });
		saveAs(file, "billing-entry-report.csv");
	}
}
