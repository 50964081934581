import { DateUtils } from "@classes/utils";

export interface TrafficLightData {
	planId: string;
	userId: string;
	variance: number;
	varianceLevel: number;
	clientName: string;
	dashboardInclusionDate: Date;
	dashboardComment: string;
}

export class TrafficLightUtils {
	public static parse(src: any): TrafficLightData {
		return {
			"planId": src.planId,
			"userId": src.userId,
			"variance": src.variance,
			"varianceLevel": src.varianceLevel,
			"clientName": src.clientName,
			"dashboardInclusionDate": DateUtils.parse(src.dashboardInclusionDate),
			"dashboardComment": src.dashboardComment
		};
	}
}
