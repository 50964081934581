export interface FilterSettings {
	categories: Set<string>;
	search: string;
}

export namespace FilterSettings {
	export function clone(src: FilterSettings): FilterSettings {
		return {
			"search": src.search,
			"categories": new Set<string>( Array.from(src.categories) )
		};
	}
}
