import moment from 'moment';
import { PlanStatus } from './plans';

export enum AttachmentTargetType {
	invoice, provider, plan, client, note, linked_user, serviceAgreement, other
};

export class AttachmentTargetUtils {
	private static pgEnumValues = new Map<AttachmentTargetType, string>([
		[AttachmentTargetType.invoice, "invoice"],
		[AttachmentTargetType.provider, "provider"],
		[AttachmentTargetType.plan, "plan"],
		[AttachmentTargetType.client, "client"],
		[AttachmentTargetType.note, "note"],
		[AttachmentTargetType.linked_user, "linked_user"],
		[AttachmentTargetType.serviceAgreement, "service_agreement"],
		[AttachmentTargetType.other, "other"]
	]);

	private static values = new Map<string, AttachmentTargetType>([
		["invoice", AttachmentTargetType.invoice],
		["provider", AttachmentTargetType.provider],
		["plan", AttachmentTargetType.plan],
		["client", AttachmentTargetType.client],
		["note", AttachmentTargetType.note],
		["linked_user", AttachmentTargetType.linked_user],
		["service_agreement", AttachmentTargetType.serviceAgreement],
		["other", AttachmentTargetType.other]
	]);

	public static targetTypes(): any {
		const keys = Array.from(AttachmentTargetUtils.pgEnumValues.keys());

		return keys.reduce( (acc, key) => {
			acc[AttachmentTargetUtils.pgEnumValues.get(key)] = key;
			return acc;
		}, {});
	}

	public static toPostgresEnum(target: AttachmentTargetType): string {
		return AttachmentTargetUtils.pgEnumValues.get(target);
	}

	public static toJson(target: AttachmentTarget): any {
		return {
			"id": target.id,
			"type": AttachmentTargetUtils.pgEnumValues.get(target.type)
		};
	}

	public static target(id: string, type: AttachmentTargetType): AttachmentTarget {
		return <AttachmentTarget>{
			"id": id,
			"type": type
		};
	}

	public static parse(src: any): AttachmentTarget {
		let result = {
			"id": src.id,
			"type": AttachmentTargetUtils.values.get(src.type)
		};

		switch (result.type) {
			case AttachmentTargetType.invoice:
				(<InvoiceTarget>result).date = moment(src.date, 'YYYY-MM-DD').toDate();
				break;

			case AttachmentTargetType.plan:
				(<PlanTarget>result).status = PlanStatus.parse(src.status);
				break;

			case AttachmentTargetType.provider:
				(<ProviderTarget>result).name = src.name;
				break;

			case AttachmentTargetType.note:
				(<FileNoteTarget>result).title = src.title;
				break;
		}

		return result;
	}
}

export interface AttachmentTarget {
	id: string;
	type: AttachmentTargetType;
}

export interface InvoiceTarget extends AttachmentTarget {
	date: Date;
}

export interface PlanTarget extends AttachmentTarget {
	status: PlanStatus;
}

export interface ProviderTarget extends AttachmentTarget {
	name: string;
}

export interface FileNoteTarget extends AttachmentTarget {
	title: string;
}
