import { Component, OnInit, Input } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { User, LoginHistory } from "@classes/user";

@Component({
	"selector": "login-history",
	"styleUrls": [],
	"templateUrl": "./loginHistory.component.html"
})
export class LoginHistoryComponent extends PrivateComponent implements OnInit {

	private _clientId: string;
	private _client: User;
	private _loginHistoryLoaded: boolean = false;
	private _loginHistory: LoginHistory[] = undefined;

	@Input()
	set clientId(value: string) {
		this._clientId = value;
		this.loadClient();
	}

	get loginHistory(): LoginHistory[] {
		return this._loginHistory;
	}

	get loginHistoryLoaded(): boolean {
		return this._loginHistoryLoaded;
	}

	get hasCognitoAccount(): boolean {
		return this._client ? !!this._client.identityId : false;
	}

	constructor() {
		super();
	}

	ngOnInit() {
		super.ngOnInit();
	}

	private loadClient() {
		if (!this._clientId) {
			return;
		}

		this._client = undefined;
		this.userService.loadUser(this._clientId).then( client => {
			this._client = client;
			this.loadLoginHistory();
		});
	}

	private loadLoginHistory(): void {
		this._loginHistoryLoaded = false;

		if (this._clientId) {

			this.userService.getLoginHistory(this._clientId).then( history => {

				this._loginHistory = history;
				this._loginHistoryLoaded = true;

			}).catch( err => {

				console.log(err);
				this._loginHistoryLoaded = true;

			});
		}
	}
}
