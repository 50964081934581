import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceLocator } from '@services/locator.service';


@Component({
	// "styleUrls": ["./maintenance.component.scss"],
	"templateUrl": "./maintenance.component.html"
})
export class MaintenanceComponent {

	protected router: Router;

	constructor() {
		this.router = ServiceLocator.injector.get(Router);
	}

	goToDashboard(): void {
		window.location.href = window.location.href.replace("maintenance", "dashboard");
	}
}
