import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrivateComponent } from "@classes/private.component";
import { UserType } from "@classes/user";
import { FileMetaData, AttachedFile, FileMimeTypes, FileType, FileValidator } from "@classes/files";
import { MenuBuilder } from "@services/navmenu.service";
import { ProdaService, ProdaSubmission, ProdaSubmissionItem } from "@services/proda.service";
import { OverlayService } from "@services/overlay.service";
import { FileUploader } from 'ng2-file-upload';

abstract class ProdaFile extends FileValidator {
	abstract get claimReferenceIdx(): number;
	abstract get statusReferenceIdx(): number;
	abstract get errorMessageReferenceIdx(): number;
	abstract get fileType(): string;
}

class ProdaRemittance20180101 extends ProdaFile {
	private static readonly signature = 'PayeeBP,ProviderABN,PayReqNum,PayReqDocDate,ProvClaimRef,ItemID,ItemQty,UnitPrice,NetAmt,GSTAmt,AmtClaimed,ParticipantBPNumber,ParticipantName,SupportStartDate,SupportEndDate,ServiceBookingNum,GSTCode,BulkReqNum';
	private static readonly claimReferenceIdx: number = 4;
	private static readonly statusReferenceIdx: number = 0;
	private static readonly errorMessageReferenceIdx: number = 0;
	private static readonly fileType: string = 'Remittance';

	get fileSignature(): string {
		return ProdaRemittance20180101.signature;
	}

	get claimReferenceIdx(): number {
		return ProdaRemittance20180101.claimReferenceIdx;
	}

	get statusReferenceIdx(): number {
		return ProdaRemittance20180101.statusReferenceIdx;
	}

	get errorMessageReferenceIdx(): number {
		return ProdaRemittance20180101.errorMessageReferenceIdx;
	}

	get fileType(): string {
		return ProdaRemittance20180101.fileType;
	}
}

class ProdaRemittance20200101 extends ProdaFile {
	private static readonly signature = 'PayeeBP,Z4No,FinYrs,PayReqNum,PayReqDocDate,ProvClaimRef,ItemID,ItemQty,UnitPrice,AmountClaimed,AmountPaid,ParticipantBP,ParticipantName,SupportStartDate,SupportEndDate,ServiceBookingNum,BulkClmId,ClaimType,CancelRsn';
	private static readonly claimReferenceIdx: number = 5;
	private static readonly statusReferenceIdx: number = 0;
	private static readonly errorMessageReferenceIdx: number = 0;
	private static readonly fileType: string = 'Remittance';

	get fileSignature(): string {
		return ProdaRemittance20200101.signature;
	}

	get claimReferenceIdx(): number {
		return ProdaRemittance20200101.claimReferenceIdx;
	}

	get statusReferenceIdx(): number {
		return ProdaRemittance20200101.statusReferenceIdx;
	}

	get errorMessageReferenceIdx(): number {
		return ProdaRemittance20200101.errorMessageReferenceIdx;
	}

	get fileType(): string {
		return ProdaRemittance20200101.fileType;
	}
}

class ProdaSuccessFile20180101 extends ProdaFile {
	private static readonly signature = 'RegistrationNumber,NDISNumber,SupportsDeliveredFrom,SupportsDeliveredTo,SupportNumber,ClaimReference,Quantity,UnitPrice,GSTCode,PaidTotalAmount,Payment Request Number,Participant Name,Capped Price,Payment Request Status,Error Message,ClaimType,CancellationReason,';
	private static readonly claimReferenceIdx: number = 5;
	private static readonly statusReferenceIdx: number = 13;
	private static readonly errorMessageReferenceIdx: number = 14;
	private static readonly fileType: string = 'Success';

	get fileSignature(): string {
		return ProdaSuccessFile20180101.signature;
	}

	get claimReferenceIdx(): number {
		return ProdaSuccessFile20180101.claimReferenceIdx;
	}

	get statusReferenceIdx(): number {
		return ProdaSuccessFile20180101.statusReferenceIdx;
	}

	get errorMessageReferenceIdx(): number {
		return ProdaSuccessFile20180101.errorMessageReferenceIdx;
	}

	get fileType(): string {
		return ProdaSuccessFile20180101.fileType;
	}
}

class ProdaSuccessFile20201026 extends ProdaFile {
	private static readonly signature = 'RegistrationNumber,NDISNumber,SupportsDeliveredFrom,SupportsDeliveredTo,SupportNumber,ClaimReference,Quantity,UnitPrice,GSTCode,PaidTotalAmount,Payment Request Number,Participant Name,Capped Price,Payment Request Status,Error Message,ClaimType,CancellationReason,ABN of Support Provider,';
	private static readonly claimReferenceIdx: number = 5;
	private static readonly statusReferenceIdx: number = 13;
	private static readonly errorMessageReferenceIdx: number = 14;
	private static readonly fileType: string = 'Success';

	get fileSignature(): string {
		return ProdaSuccessFile20201026.signature;
	}

	get claimReferenceIdx(): number {
		return ProdaSuccessFile20201026.claimReferenceIdx;
	}

	get statusReferenceIdx(): number {
		return ProdaSuccessFile20201026.statusReferenceIdx;
	}

	get errorMessageReferenceIdx(): number {
		return ProdaSuccessFile20201026.errorMessageReferenceIdx;
	}

	get fileType(): string {
		return ProdaSuccessFile20201026.fileType;
	}
}

@Component({
	"styleUrls": ["./addresponse.component.scss"],
	"templateUrl": "./addresponse.component.html"
})
export class ProdaResponseComponent extends PrivateComponent implements OnInit {

	private mimeTypes: FileMimeTypes = new FileMimeTypes(FileType.csv);
	private _fileContent: string;
	private _submissionId: string;
	private _submissionItems: ProdaSubmissionItem[] = [];
	private _dataLoaded: boolean = false;
	private _canSave: boolean = false;
	private _matchCount: number = undefined;
	private _errorCount: number = undefined;
	private _submission: ProdaSubmission = undefined;
	private prodaFile: ProdaFile;

	public get canSave(): boolean {
		return this._canSave;
	}

	public get dataLoaded(): boolean {
		return this._dataLoaded;
	}

	get matchCount(): number {
		return this._matchCount;
	}

	get errorCount(): number {
		return this._errorCount;
	}

	public get fileContent(): string {
		const result = this._fileContent || "";
		return result.trim();
	}

	public dropZoneOver: boolean = false;
	public fileUploader:FileUploader = new FileUploader({ url: undefined });

	public attachedFile: AttachedFile = undefined;
	public get hasFile(): boolean {
		return !!this.attachedFile;
	}

	constructor(private prodaService: ProdaService, private route: ActivatedRoute) {
		super();

		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Proda', 'PRODA Access');
	}

	private buildMenu(): void {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.done();
	}

	get filename(): string {
		return this.hasFile ? this.attachedFile.name : "";
	}

	get filesize(): number {
		return this.hasFile ? this.attachedFile.size : 0;
	}

	get recordCount(): number {
		return this.hasFile ? Math.max(0, this.fileContent.split(/\r?\n/).length - 1) : 0;
	}

	ngOnInit() {
		super.ngOnInit()

		this.buildMenu();

		this.route.params.subscribe( params => {

			// If an invoice Id has been specified on the path, load the invoice data
			this._submissionId = params.id;
			if (this._submissionId !== undefined) {
				this.loadSubmission();
			}
		});
	}

	private loadSubmission() {
		if (!this.user || !this._submissionId) {
			return;
		}

		this._dataLoaded = false;
		OverlayService.show();

		this.prodaService.getSubmission(this._submissionId).then( submission => {

			this._submission = submission;
			return Promise.resolve();

		}).then( () => {

			return this.prodaService.loadSubmissionFile(this._submissionId);

		}).then( items => {

			OverlayService.hide();
			this._dataLoaded = true;
			this._submissionItems = items;

		}).catch( err => {

			OverlayService.hide();
			console.log(err);

		});
	}

	private getSubmittedClaimReferenceNumbers(): Set<string> {
		return new Set( this._submissionItems.map( item => item.claimReference ) );
	}

	private getReturnedClaimReferenceNumbers(): Set<string> {
		if (!this._fileContent) {
			return new Set<string>();
		}

		let lines = this._fileContent.trim().split(/\r?\n/);
		lines.shift(); // Ignore the header row

		const claimReferenceIdx = this.prodaFile.claimReferenceIdx;
		const claimReferences = lines.map( line => {
			const data = line.split(",");
			return data.length ? data[claimReferenceIdx] : undefined;
		});

		return new Set(claimReferences);
	}

	private getReturnedErrors(): string[] {
		let errors = [];

		if (!this._fileContent) {
			return errors;
		}

		let lines = this._fileContent.trim().split(/\r?\n/);
		lines.shift(); // Ignore the header row

		const errorMessageReferenceIdx = this.prodaFile.errorMessageReferenceIdx;
		const errorMessages = lines.map( line => {
			const data = line.split(",");

			console.log(errorMessageReferenceIdx);
			console.log(data[errorMessageReferenceIdx]);
			console.log(data);

			if ( data[errorMessageReferenceIdx] != "\"\"" ) {
				errors.push( data[errorMessageReferenceIdx] );
				return data.length ? data[errorMessageReferenceIdx] : undefined;
			} else {
				return undefined;
			}
		});

		return errors;
	}

	/**
	* Check the value of the "status" column in the response. If any items are still marked as "Open",
	* we cannot process this file.
	*/
	private hasOpenItems(): boolean {
		const openStatus = 'open';

		let lines = this._fileContent.trim().split(/\r?\n/);
		lines.shift(); // Ignore the header row

		const statusColumn = this.prodaFile.statusReferenceIdx;

		const statuses = lines.map( line => {
			const data = line.split(",");
			const itemStatus = data[statusColumn] || '';
			return itemStatus.toLowerCase();
		});

		return statuses.some( value => value === openStatus );
	}

	fileOverBase(event): void {
		this.dropZoneOver = event;
	}

	getIconFromMimeType(file: FileMetaData): string {
		return this.mimeTypes.getIcon(file.mimeType);
	}

	async fileDropped(droppedFiles: FileList|File[]): Promise<void> {
		this._canSave = false;
		this._matchCount = undefined;
		this._errorCount = undefined;

		if (droppedFiles.length > 0) {
			const file = droppedFiles[0];

			if (this.mimeTypes.isAllowed(file.type)) {

				const attachedFile = await AttachedFile.fromFile(file);
				this.validateFile( attachedFile ).then( result => {

					if (!result) {
						this.attachedFile = undefined;
						OverlayService.showError("Error", "This file is not in the expected format");
						return;
					}

					else {
						this.attachedFile = attachedFile;
						this.attachedFile.textContent.then( content => {
							this._fileContent = content.replace(/\r?\n/g, "\n");

							if (this.hasOpenItems()) {
								OverlayService.showError("Error", "There are still open items in this response file.<br /><br />You have probably downloaded the response file from PRODA before it has finished processing.");
								return;
							}

							const submittedClaims = this.getSubmittedClaimReferenceNumbers();
							const receivedClaims = this.getReturnedClaimReferenceNumbers();

							const receivedErrors = this.getReturnedErrors();

							this._errorCount = receivedErrors.length;

							this._matchCount = Array.from(receivedClaims.entries()).map( x => x[0] ).reduce( (acc, cur) => {
								return acc += submittedClaims.has(cur) ? 1 : 0, acc;
							}, 0);

							if (receivedClaims.size > submittedClaims.size) {
								console.log(`${receivedClaims.size} received, ${submittedClaims.size} claimed`);
								OverlayService.showError("Error", "This file contains more records than the PRODA submission.<br /><br />You have probably selected the wrong PRODA response file");
								return;
							}

							if (this.matchCount === 0) {
								OverlayService.showError("Error", "None of the claims in this file match those in the PRODA submission.<br /><br />You have probably selected the wrong PRODA response file");
								return;
							}

							this._canSave = true;
						});
					}

				});
			}
			else {
				OverlayService.showError("Error", `Cannot upload this file type (${file.type})`);
			}
		}
	}

	private async validateFile(prodaFile: AttachedFile): Promise<boolean> {
		const validators = {
			"remit20180101": new ProdaRemittance20180101(prodaFile),
			"remit20200101": new ProdaRemittance20200101(prodaFile),
			"success20180101": new ProdaSuccessFile20180101(prodaFile),
			"success20201026": new ProdaSuccessFile20201026(prodaFile)
		};

		if (await validators.remit20180101.isValid()) {
			this.prodaFile = validators.remit20180101;
			return true;
		}
		else if (await validators.remit20200101.isValid()) {
			this.prodaFile = validators.remit20200101;
			return true;
		}
		else if (await validators.success20180101.isValid()) {
			this.prodaFile = validators.success20180101;
			return true;
		}
		else if (await validators.success20201026.isValid()) {
			this.prodaFile = validators.success20201026;
			return true;
		}
		else {
			return false;
		}
	}

	private saveFile() {
		OverlayService.show();
		this.prodaService.saveProdaResponse(this.attachedFile, this._submissionId, this.prodaFile).then( fileId => {

			OverlayService.hide();
			this.router.navigate([`/proda/submission/${this._submissionId}`]);

		}).catch( e => {

			OverlayService.hide();

			if (e.response && e.response.data && e.response.data.errorMessage.includes('duplicate key value violates unique constraint')) {
				OverlayService.showError("Error", "This file has already been uploaded");
			}
			console.log(e);

		});
	}

	public checkSaveFile() {
		if (!this.hasFile) {
			return;
		}

		if (!!this._submission.responseId) {
			OverlayService.showDialog("Warning", "This PRODA submission already has a government response file attached to it.<br />Do you want to replace it?", [
				{"text": "Cancel", "handler": () => { OverlayService.hide(); }},
				{"text": "Replace", "handler": () => { OverlayService.hide(); this.saveFile(); }}
			])
		}
		else {
			this.saveFile();
		}


	}
}
