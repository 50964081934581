export class EInvalidArgument extends Error {
	constructor(message) {
		super(message);

		// Workaround to fix subclass of built-in classes
		// See https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
		Object.setPrototypeOf(this, EInvalidArgument.prototype);
	}
}

export class EUnauthorised extends Error {
	constructor(message) {
		super(message);

		// Workaround to fix subclass of built-in classes
		// See https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
		Object.setPrototypeOf(this, EUnauthorised.prototype);
	}
}

export class ErrorUtils {
	public static getErrorMessage(error: any, defaultErrorMessage?: string): string {
		error = error || {};
		const re = /^\[\d{3}\]\s/;
		const response = error.response || {};
		const responseData = response.data || {};

		return re.test(responseData.errorMessage) ? responseData.errorMessage.replace(re, '') : defaultErrorMessage;
	}
}

export class EAssertionFailed extends Error {
	constructor(message?: string) {
		super(message);

		// Workaround to fix subclass of built-in classes
		Object.setPrototypeOf(this, EAssertionFailed.prototype);
	}
}

export function assert(value: any, msg?: string): void {
	if (!value) {
		throw new EAssertionFailed(msg);
	}

	return;
}
