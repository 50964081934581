import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { SupportCategory, SupportItem } from "@classes/supports";
import { ServiceAgreement, ServiceAgreementCategory, ServiceAgreementItem, ServiceDelivered } from "@classes/serviceAgreement";
import { SupportsService } from "@services/supports.service";
import { Plan, PlanBudget, BudgetType } from "@classes/plans";
import { Provider } from "@classes/provider";
import { OverlayService } from "@services/overlay.service";
import { PlanService } from "@services/plan.service";
import { CacheSignalService } from "@services/cachesignal.service";
import { ErrorUtils } from "@classes/errors";
import { Utils, DateUtils } from "@classes/utils";
import moment from 'moment';
import { assert, EAssertionFailed } from "@classes/errors";

import { AttachedFile } from "@classes/files";
import { AttachmentsService } from "@services/attachments.service";
import { AttachmentTargetType, AttachmentTargetUtils  } from '@classes/attachments';
import { AttachmentType } from "@classes/attachmentType";

import { OverlapTest } from "./overlap";
import { OverspendTest, SupportItemBudgetTest } from "./overspend";

interface DateValue {
	value: string;
	valid: boolean;
	errorMessage: string;
	m: moment.Moment;
}

namespace DateValue {
	export function blank(): DateValue {
		return {
			"value": undefined,
			"valid": false,
			"errorMessage": "",
			"m": undefined
		};
	}
}

interface DateModel {
	startDate: DateValue,
	endDate: DateValue
};

type CategoryNumber = number;
type SupportItemNumber = string;

@Component({
	"selector": "service-agreement",
	"styleUrls": ["./serviceAgreement.component.scss"],
	"templateUrl": "./serviceAgreement.component.html"
})
export class ServiceAgreementComponent extends PrivateComponent implements OnInit {

	@ViewChild('attachmentDialog') private attachmentDialog: TemplateRef<any>;

	private static instanceCount: number = 0;
	private static dateFormat = "DDMMYYYY";

	public readonly instanceId: number = ++ServiceAgreementComponent.instanceCount;

	/**
	* Map of errors against a category in the service agreement.
	*/
	private _categoryErrors: Map<CategoryNumber, string> = new Map<CategoryNumber, string>();

	/**
	* Map of errors against a support item.
	*/
	private _supportErrors: Map<SupportItemNumber, string> = new Map<SupportItemNumber, string>();

	/**
	* Array of other service agreements on the current plan. Used to determine if there
	* are any errors / overlaps with other agreements.
	*/
	private _otherAgreements: ServiceAgreement[];

	/**
	* Map of support categories, with category number as the key.
	* Used to populate the drop-down list when adding a new category to the service agreement
	*/
	private _supportCategories: Map<number, SupportCategory> = undefined;

	/**
	* Map of promises that resolve to the name of a support item name in the service agreement, keyed by support item number.
	* Used to resolve support items names that are attached to a service agreement
	*/
	private _supportItemPromiseMap: Map<string, Promise<string>> = new Map<string, Promise<string>>();

	/**
	* Map of support items, keyed by support category.
	* Used to populate the drop down list of available support items when adding to the service agreement.
	*/
	private _categorySupportsMap: Map<number, SupportItem[]> = new Map<number, SupportItem[]>();

	/**
	* Map of services delivered for this provider that fall within the current plan. Key is the support category number.
	* Used to help calculate whether a service agreement has sufficient funding.
	*/
	private _servicesDelivered: Map<number, ServiceDelivered[]> = new Map<number, ServiceDelivered[]>();

	private _serviceAgreement: ServiceAgreement;

	private async loadSupportCategories() {
		this._supportCategories = await this.supportsService.getSupportCategoryMap();
	}

	private formatDate(date: Date): string {
		if (!date) {
			return "";
		}

		try {
			return moment(date).format(ServiceAgreementComponent.dateFormat);
		}
		catch (e) {
			return "";
		}
	}

	public model: DateModel = {
		"startDate": DateValue.blank(),
		"endDate": DateValue.blank()
	};

	@Input()
	public canEdit: boolean = false;

	@Input()
	public allowInlineSave: boolean = false;

	@Input()
	public set serviceAgreement(value: ServiceAgreement) {
		if (this._serviceAgreement !== value) {
			this._otherAgreements = (this._otherAgreements || []).filter( item => item !== value );
			this._serviceAgreement = value;
			this.model.startDate.value = this.formatDate(value.dateFrom);
			this.model.endDate.value = this.formatDate(value.dateTo);

			this._serviceAgreement.categories.forEach( category => {
				this.loadServicesDelivered(category.categoryNumber);
			});
		}
	}

	@Input()
	public set allServiceAgreements(value: ServiceAgreement[]) {
		this._otherAgreements = value || [];
		if (!!this._serviceAgreement) {
			this._otherAgreements = this._otherAgreements.filter( item => item !== this._serviceAgreement );
		}
	}

	public get serviceAgreement(): ServiceAgreement {
		return this._serviceAgreement;
	}

	private _plan: Plan;

	@Input()
	public set plan(value: Plan) {
		if (this._plan !== value) {
			this._plan = value;
			// this.checkDates();
		}
	}

	public get plan(): Plan {
		return this._plan;
	}

	@Output()
	public readonly serviceAgreementDeleted: EventEmitter<void> = new EventEmitter<void>();

	public get initialised(): boolean {
		return !!this.serviceAgreement && !!this.plan && this._supportCategories !== undefined;
	}

	constructor(
		private supportsService: SupportsService,
		private attachmentsService: AttachmentsService,
		private signalService: CacheSignalService,
		private planService: PlanService) {
		super();
	}

	ngOnInit() {
		super.ngOnInit();

		this.loadSupportCategories();
		this.checkDates();
	}

	public supportCategoryName(categoryNumber: number): string {
		const category = this._supportCategories.get(categoryNumber);
		return !!category ?  category.name : '';
	}

	private async loadSupportItem(supportItemNumber: string): Promise<string> {
		const supports = await this.supportsService.getSupportsFor(this.plan.region, this.plan.startDate, supportItemNumber, undefined, this.plan.pace);
		return supports.length ? supports[0].name : "Unknown";
	}

	public supportItemName(supportItemNumber: string): Promise<string> {
		if (!this._supportItemPromiseMap.has(supportItemNumber)) {
			this._supportItemPromiseMap.set(supportItemNumber, this.loadSupportItem(supportItemNumber));
		}
		return this._supportItemPromiseMap.get(supportItemNumber);
	}

	public setProvider(value: Provider) {
		this.serviceAgreement.provider = value;

		// Reload services delivered for the selected provider
		this._servicesDelivered.clear();
		this._serviceAgreement.categories.forEach( category => {
			this.loadServicesDelivered(category.categoryNumber);
		});
	}

	private deleteItem(category: ServiceAgreementCategory, item: ServiceAgreementItem) {
		const idx = category.items.findIndex( x => x.supportItemNumber === item.supportItemNumber );
		if (idx >= 0) {
			category.items.splice(idx, 1);
		}
		OverlayService.hide();
	}

	private deleteCategory(category: ServiceAgreementCategory) {
		const idx = this.serviceAgreement.categories.findIndex( x => x.categoryNumber === category.categoryNumber );
		if (idx >= 0) {
			this.serviceAgreement.categories.splice(idx, 1);
		}
		OverlayService.hide();
	}

	public confirmDeleteItem(category: ServiceAgreementCategory, item: ServiceAgreementItem) {
		OverlayService.showDialog("Delete this service agreement item?", "Are you sure you want to delete this item?", [{
			"text": "Don't delete",
			"handler": OverlayService.hide
		}, {
			"text": "Delete",
			"handler": this.deleteItem.bind(this, category, item)
		}]);
	}

	public confirmDeleteCategory(category: ServiceAgreementCategory) {
		OverlayService.showDialog("Delete this service agreement category?", "Are you sure you want to delete this category?", [{
			"text": "Don't delete",
			"handler": OverlayService.hide
		}, {
			"text": "Delete",
			"handler": this.deleteCategory.bind(this, category)
		}]);
	}

	private showCategoryForPlan(categoryNumber): boolean {
		if(!this._plan.pace && categoryNumber > 15)
			return false;
		else
			return true;
	}

	public get availableCategories(): SupportCategory[] {
		const currentCategoryNumbers = this.serviceAgreement.categories.map( category => category.categoryNumber );
		return Array
			.from( this._supportCategories.keys() )
			.filter( categoryNumber => !currentCategoryNumbers.includes(categoryNumber) && this.showCategoryForPlan(categoryNumber) )
			.sort( (a, b) => a - b )
			.map( categoryNumber => this._supportCategories.get(categoryNumber) );
	}

	private async loadServicesDelivered(supportCategoryNumber: number) {
		if (!this._servicesDelivered.has(supportCategoryNumber)) {
			const servicesDelivered = await this.planService.getAllocatedFunds(this._serviceAgreement.planId, this._serviceAgreement.provider.id, supportCategoryNumber);
			this._servicesDelivered.set(supportCategoryNumber, servicesDelivered);
		}
	}

	public async addCategory(newCategory: SupportCategory) {
		if (!newCategory) {
			return;
		}

		await this.loadServicesDelivered(newCategory.id);

		const category: ServiceAgreementCategory = {
			"categoryNumber": newCategory.id,
			"budget": PlanBudget.blank(BudgetType.serviceAgreementCategory),
			"exclusiveBudget": PlanBudget.blank(BudgetType.serviceAgreementCategory),
			"items": []
		};

		this.serviceAgreement.categories.push( category );
		this.serviceAgreement.categories.sort( (a, b) => a.categoryNumber - b.categoryNumber );
		this.addingCategory = false;

		this.loadSupportItems();
	}

	public async loadSupportItems() {
		const supportItems = await this.supportsService.getSupportsFor(this.plan.region, this.serviceAgreement.dateFrom, undefined, this.addItemCategory, this.plan.pace);
		this._categorySupportsMap.set(this.addItemCategory, supportItems);
	}

	public addSupportItem(supportItem: SupportItem) {
		if (!supportItem) {
			return;
		}

		const newItem: ServiceAgreementItem = {
			"supportItemName": supportItem.name,
			"supportItemNumber": supportItem.supportItemNumber,
			"budget": PlanBudget.blank(BudgetType.serviceAgreementItem)
		};

		const serviceAgreementCategory = this.serviceAgreement.categories.find( category => category.categoryNumber === this.addItemCategory );
		if (!serviceAgreementCategory) {
			return;
		}

		serviceAgreementCategory.items.push(newItem);
		serviceAgreementCategory.items.sort( (a, b) => a.supportItemNumber.localeCompare(b.supportItemNumber) );
		this.addItemCategory = undefined;
	}

	/**
	* Return a list of the permissible support items to add for the current category
	*/
	public get categorySupports(): SupportItem[] {
		if (this.addItemCategory === undefined) {
			return [];
		}

		if (!this._categorySupportsMap.has(this.addItemCategory)) {
			return [];
		}

		const supports = this
			._categorySupportsMap
			.get(this.addItemCategory)
			.sort( (a, b) => a.supportItemNumber.localeCompare(b.supportItemNumber) );

		const planCategory = this._plan.supportCategories.find( category => this.addItemCategory === category.supportCategory.id );
		assert(!!planCategory);
		const supportItemsNumbers = planCategory.supportItems.map( item => item.supportItemNumber );

		const itemsTotal = planCategory.supportItems.reduce( (acc, cur) => {
			acc += cur.itemBudget.total;
			return acc;
		}, 0);

		if (planCategory.categoryBudget.total > 0 && planCategory.categoryBudget.total - itemsTotal < 0.01) {
			return supports.filter( item => supportItemsNumbers.includes( item.supportItemNumber) );
		}
		else {
			return supports;
		}
	}

	public canSelectSupportItem(supportItemNumber: string): boolean {

		const currentCategory = this._serviceAgreement.categories.find( category => category.categoryNumber === this.addItemCategory );
		if (!currentCategory) {
			return false;
		}

		const currentItems = currentCategory.items.map( item => item.supportItemNumber );
		return !currentItems.includes(supportItemNumber);
	}

	public addingCategory: boolean = false;
	public addItemCategory: number = undefined;

	public async save() {
		OverlayService.show();
		try {
			this.serviceAgreement = await this.planService.saveServiceAgreement(this.serviceAgreement);
			OverlayService.hide();
		}
		catch (e) {
			console.log(e);
			OverlayService.showError("Unable to save service agreement", ErrorUtils.getErrorMessage(e, "Unknown error"));
		}
	}

	public confirmDeleteServiceAgreement() {
		OverlayService.showDialog("Delete this service agreement?", "Are you sure you want to delete this service agreement?", [{
			"text": "Don't delete",
			"handler": OverlayService.hide
		}, {
			"text": "Delete",
			"handler": this.deleteServiceAgreement.bind(this)
		}]);
	}

	private deleteServiceAgreement() {
		OverlayService.hide();
		this.serviceAgreementDeleted.next();
	}

	private parseDate(value: string): Date {
		return DateUtils.parse(value, ServiceAgreementComponent.dateFormat);
	}

	public checkDates() {
		this.startDateChanged();
		this.endDateChanged();

		if (this.model.startDate.valid && this.model.endDate.valid) {
			const mStart = moment(this._serviceAgreement.dateFrom);
			const mEnd = moment(this._serviceAgreement.dateTo);
			if (mStart.isAfter(mEnd)) {
				this.model.startDate.valid = false;
				this.model.endDate.valid = false;

				this.model.startDate.errorMessage = "Start date cannot be after end date";
				this.model.endDate.errorMessage = "End date cannot be before start date";
			}
		}
	}

	private startDateChanged() {
		if (!this.model || !this.plan) {
			return;
		}

		const startDate = this.parseDate(this.model.startDate.value);
		if (!startDate) {
			this.model.startDate.valid = false;
			this.model.startDate.errorMessage = undefined;
			this.model.startDate.m = undefined;
			return;
		}

		const planStart = moment(this.plan.startDate);
		const planEnd = moment(this.plan.endDate);

		this.model.startDate.m = moment(startDate);
		this.model.startDate.valid = this.model.startDate.m.isBetween(planStart, planEnd, undefined, '[]');
		if (this.model.startDate.valid) {
			this._serviceAgreement.dateFrom = startDate;
		}
		else {
			this.model.startDate.errorMessage = "Date is outside plan date range";
		}
	}

	private endDateChanged() {
		if (!this.model || !this.plan) {
			return;
		}

		const endDate = this.parseDate(this.model.endDate.value);
		if (!endDate) {
			this.model.endDate.valid = false;
			this.model.endDate.errorMessage = undefined;
			this.model.endDate.m = undefined;
			return;
		}

		const planStart = moment(this.plan.startDate);
		const planEnd = moment(this.plan.endDate);

		this.model.endDate.m = moment(endDate);
		this.model.endDate.valid = this.model.endDate.m.isBetween(planStart, planEnd, undefined, '[]');
		if (this.model.endDate.valid) {
			this._serviceAgreement.dateTo = endDate;
		}
		else {
			this.model.endDate.errorMessage = "Date is outside plan date range";
		}
	}

	private categoryBudgetTest(categoryNumber: number): void {
		const servicesDelivered = this._servicesDelivered.get(categoryNumber) || [];
		const test = new OverspendTest(this.serviceAgreement, this._otherAgreements, this._plan, this._servicesDelivered);
		test.hasOverspend(categoryNumber);
		test.checkSufficientBudgetForServiceAgreements(categoryNumber);
	}

	private overlap(categoryNumber: number): void {
		const test = new OverlapTest(this.serviceAgreement, this._otherAgreements);
		test.hasOverlap(categoryNumber);
	}

	private checkForCategoryErrors(categoryNumber: number) {
		this._categoryErrors.delete(categoryNumber);
		try {
			this.categoryBudgetTest(categoryNumber);
			this.overlap(categoryNumber);
		}
		catch (e) {
			if (e instanceof EAssertionFailed && !!e.message) {
				this._categoryErrors.set(categoryNumber, e.message);
				return;
			}

			throw e;
		}
	}

	private checkForSupportItemErrors(category: ServiceAgreementCategory, supportItem: ServiceAgreementItem) {
		this._supportErrors.delete(supportItem.supportItemNumber);
		try {
			const test = new SupportItemBudgetTest(this.serviceAgreement, this._servicesDelivered, category);
			test.checkValid(supportItem);
		}
		catch (e) {
			if (e instanceof EAssertionFailed && !!e.message) {
				this._supportErrors.set(supportItem.supportItemNumber, e.message);
				return;
			}

			throw e;
		}
	}

	public categoryErrorMessage(categoryNumber: CategoryNumber): string {
		return this._categoryErrors.get(categoryNumber);
	}

	public itemErrorMessage(supportItemNumber: SupportItemNumber): string {
		return this._supportErrors.get(supportItemNumber);
	}

	public hasCategoryError(categoryNumber: number): boolean {
		this.checkForCategoryErrors(categoryNumber);
		return this._categoryErrors.has(categoryNumber);
	}

	public hasItemError(category: ServiceAgreementCategory, supportItem: ServiceAgreementItem): boolean {
		this.checkForSupportItemErrors(category, supportItem);
		return this._supportErrors.has(supportItem.supportItemNumber);
	}

	public allocatedAmount(categoryNumber: number): number {
		const agreementStart = this.model.startDate.m;
		const agreementEnd = this.model.endDate.m;

		const servicesDelivered = this._servicesDelivered.get(categoryNumber);

		return (servicesDelivered || []).filter( item => {
			return moment(item.date).startOf('day').isBetween(agreementStart, agreementEnd, undefined, "[]");
		}).reduce( (total, serviceDelivered) => {
			return total + serviceDelivered.total;
		}, 0);
	}

	public itemAllocatedAmount(categoryNumber: number, supportItemNumber: string): number {
		const agreementStart = this.model.startDate.m;
		const agreementEnd = this.model.endDate.m;

		const servicesDelivered = this._servicesDelivered.get(categoryNumber);

		return (servicesDelivered || []).filter( item => {
			return moment(item.date).startOf('day').isBetween(agreementStart, agreementEnd, undefined, "[]");
		}).filter( item => {
			return item.supportItem === supportItemNumber;
		}).reduce( (total, serviceDelivered) => {
			return total + serviceDelivered.total;
		}, 0);
	}

	protected showAttachmentDialog(): void {
		const targets = [
			AttachmentTargetUtils.target(this.plan.client, AttachmentTargetType.client),
			AttachmentTargetUtils.target(this.plan.id, AttachmentTargetType.plan),
			AttachmentTargetUtils.target(this._serviceAgreement.provider.id, AttachmentTargetType.provider),
			AttachmentTargetUtils.target(this._serviceAgreement.id, AttachmentTargetType.serviceAgreement)
		];

		const files = this._serviceAgreement.attachments.map( AttachedFile.clone );
		OverlayService.showTemplate(this.attachmentDialog, {"targets": targets, "files": files});
	}

	public readonly defaultAttachmentType: AttachmentType = AttachmentType.sa;

	public saveAttachments($event) {
		setTimeout( async () => {
			if ($event.fileManager && $event.targets && $event.targets.length > 0) {
				OverlayService.show(`Saving${Utils.ellipsis}`);
				try {
					const result = await this.attachmentsService.saveAttachments($event.targets, $event.fileManager);

					result.savedFiles.forEach(file => {
						this._serviceAgreement.attachments.push(file);
					});

					result.deletedFiles.forEach(fileId => {
						const idx = this._serviceAgreement.attachments.findIndex( item => item.id === fileId );
						if (idx >= 0) {
							this._serviceAgreement.attachments.splice(idx, 1);
						}
					});

					this.signalService.signal('Attachments', result);

					OverlayService.hide();
				}
				catch (e) {
					console.log(e);
					OverlayService.hide();
					OverlayService.showError("Error", "Unable to save attachment");
				}
			}
		}, 0);
	}

	public get providerABN() {
		if (!this.serviceAgreement) {
			return '';
		}

		return this.serviceAgreement.provider.abn ? `ABN: ${this.serviceAgreement.provider.abn}` : 'No ABN';
	}

	public get canSave() {
		return this._categoryErrors.size === 0 && !!this._serviceAgreement.provider && this._serviceAgreement.provider.id;
	}

	public toNumber(value: any): number {
		const result = Number(value);
		return isNaN(result) ? undefined : result;
	}

}

