import { Region, RegionUtils } from "@classes/regions";
import { DateUtils } from "@classes/utils";
import moment from "moment";

/**
* Labels a date range with a single identifier.
*/
class SupportItemDateRange {

	private static instanceCounter: number = 0;

	private readonly startDate;
	private readonly endDate;
	readonly id: number = ++SupportItemDateRange.instanceCounter;

	constructor(startDate: Date, endDate: Date) {
		this.startDate = moment(startDate);
		this.endDate = moment(endDate);
	}

	public intersects(date: Date): boolean {
		return moment(date).isBetween(this.startDate, this.endDate, null, '[]');
	}
}

export type SupportItemCache = Map<string, SupportItem>;

export enum UnitOfMeasure {
	each, hour, day, week, month, year
}

export namespace UnitOfMeasure {
	const descriptions = new Map<UnitOfMeasure, string>([
		[UnitOfMeasure.each, "each"],
		[UnitOfMeasure.hour, "per hour"],
		[UnitOfMeasure.day, "per day"],
		[UnitOfMeasure.week, "per week"],
		[UnitOfMeasure.month, "per month"],
		[UnitOfMeasure.year, "per year"],
	]);

	const pgEnumValues = new Map<UnitOfMeasure, string>([
		[UnitOfMeasure.each, "EA"],
		[UnitOfMeasure.hour, "H"],
		[UnitOfMeasure.day, "D"],
		[UnitOfMeasure.week, "WK"],
		[UnitOfMeasure.month, "MON"],
		[UnitOfMeasure.year, "YR"],
	]);

	const values = new Map<string, UnitOfMeasure>([
		["EA", UnitOfMeasure.each],
		["H", UnitOfMeasure.hour],
		["D", UnitOfMeasure.day],
		["WK", UnitOfMeasure.week],
		["MON", UnitOfMeasure.month],
		["YR", UnitOfMeasure.year]
	]);

	export function toString(value: UnitOfMeasure): string {
		return descriptions.get(value);
	}

	export function toPostgresEnum(value: UnitOfMeasure): string {
		return pgEnumValues.get(value);
	}

	export function parse(value: string): UnitOfMeasure {
		return values.get(value);
	}

	export function allValues(): UnitOfMeasure[] {
		return Array.from(descriptions.keys());
	}
}

export interface SupportCategory {
	id: number;
	name: string;
}

export namespace SupportCategory {
	export function parse(src: any): SupportCategory {
		return {
			"id": src.id,
			"name": src.name
		};
	}

	export function toJSON(src: SupportCategory): any {
		return {
			"id": src.id,
			"name": src.name
		};
	}

	export function clone(src: SupportCategory): SupportCategory {
		return {
			"id": src.id,
			"name": src.name
		};
	}
}

export interface SupportItem {
	id: string;
	supportItemNumber: string;
	supportCategoryId: number;
	supportCategory: string;
	// registrationGroup: string;
	name: string;
	description: string;
	unitOfMeasure: UnitOfMeasure;
	quote: boolean;
	priceLimit?: number;
	priceControl: boolean;
	region: Region;
	dateFrom: Date;
	dateTo: Date;
	fixedRate?: number;
}

export namespace SupportItem {
	const dateFormatStr = 'YYYY-MM-DD';

	export function parse(data: any): SupportItem {
		return {
			"id": data.id,
			"supportItemNumber": data.supportItemNumber,
			"supportCategory": data.supportCategory,
			"supportCategoryId": data.supportCategoryNumber,
			"name": data.supportItem,
			"description": data.supportItem,
			"unitOfMeasure": UnitOfMeasure.parse(data.unitOfMeasure),
			// "registrationGroup": data.registrationGroup,
			"quote": data.quote,
			"priceLimit": Number(data.priceLimit),
			"priceControl": data.priceControl,
			"region": RegionUtils.parse(data.region),
			"dateFrom": moment(data.dateFrom).startOf('day').toDate(),
			"dateTo": moment(data.dateTo).startOf('day').toDate(),
			"fixedRate": data.rate
		};
	}

	export function toJSON(item: SupportItem): any {
		return {
			"id": item.id,
			"supportItemNumber": item.supportItemNumber,
			"supportCategoryId": item.supportCategoryId,
			"supportCategory": item.supportCategory,
			// "registrationGroup": item.registrationGroup,
			"name": item.name,
			"description": item.description,
			"unitOfMeasure": UnitOfMeasure.toPostgresEnum(item.unitOfMeasure),
			"quote": !!item.quote,
			"priceLimit": item.priceLimit,
			"priceControl": !!item.priceControl,
			"region": RegionUtils.toPostgresEnum(item.region),
			"dateFrom": moment(item.dateFrom).format(dateFormatStr),
			"dateTo": moment(item.dateTo).format(dateFormatStr),
			"fixedRate": item.fixedRate
		};
	}

	export function clone(src: SupportItem): SupportItem {
		return {
			"id": src.id,
			"supportItemNumber": src.supportItemNumber,
			"supportCategory": src.supportCategory,
			"supportCategoryId": src.supportCategoryId,
			"name": src.name,
			"description": src.description,
			"unitOfMeasure": src.unitOfMeasure,
			// "registrationGroup": src.registrationGroup,
			"quote": src.quote,
			"priceLimit": src.priceLimit,
			"priceControl": src.priceControl,
			"region": src.region,
			"dateFrom": DateUtils.clone(src.dateFrom),
			"dateTo": DateUtils.clone(src.dateTo),
			"fixedRate": src.fixedRate
		};
	}
}

export interface SupportsVersion {
	id: string;
	dateFrom: Date;
	dateTo: Date;
}

export namespace SupportsVersion {
	const dateFormatStr = 'YYYY-MM-DD';

	export function parse(src: any): SupportsVersion {
		return {
			"id": src.id,
			"dateFrom": moment(src.dateFrom).startOf('day').toDate(),
			"dateTo": moment(src.dateTo).startOf('day').toDate()
		};
	}

	export function toJSON(src: SupportsVersion): any {
		return {
			"id": src.id,
			"dateFrom": moment(src.dateFrom).format(dateFormatStr),
			"dateTo": moment(src.dateTo).format(dateFormatStr)
		};
	}
}
