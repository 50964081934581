import { DataExchangeService } from '@services/dataexchange.service';
import { Subject, Observable } from 'rxjs';
import moment from 'moment';

export type SortFunc = (a: any, b: any) => number;
export type FilterFunc = (a: any) => boolean;
export interface Filter {
	displayName: string;
	filterFunc: FilterFunc;
};

export enum SortType {
	none, name, text, date, numeric, boolean
};

export interface Sort {
	name: string,
	sortType: SortType
};

export enum SortDirection {
	ascending,
	descending
};

export enum Alignment {
	left, center, right, justify
};

export interface TableColumnHeading {
	propName: string;
	displayName: string;
	sortType: SortType;
	alignment?: Alignment;
	colspan?: number;
}

export interface PaginationProvider {
	resultsPerPage: number;
	currentPage: number;
	readonly numPages: number;
	numResults: number;
}

interface TableDataSource<T> {
	items: T[];
	length: number;
	getItems: (startIdx: number, endIdx: number, sortFunc?: SortFunc) => T[];
}

export class StaticDataSource<T> implements TableDataSource<T> {
	private _data: T[] = [];

	private constructor(data: T[]) {
		this._data = data;
	}

	get items(): T[] {
		return this._data;
	}

	get length(): number {
		return this._data.length;
	}

	getItems(startIdx: number, endIdx: number, sortFunc?: SortFunc): T[] {
		let items = [...this._data];
		if (sortFunc !== undefined) {
			items.sort(sortFunc);
		}
		return items.slice(startIdx, Math.min( endIdx, this.length ));
	}

	public static from<T>(data: T[]): TableDataSource<T> {
		return new StaticDataSource(data);
	}
}

// export class RemoteDataSource<T> implements TableDataSource<T> {
// 	private _numItems: number = undefined;

// 	get items(): T[] {
// 		return [];
// 	}

// 	get length(): number {
// 		return this._numItems;
// 	}

// }

export class SimplePaginator {
	private static readonly defaultResultsPerPage = 20;

	private _currentPage: number = 1;
	private _resultsPerPage: number = SimplePaginator.defaultResultsPerPage;
	private _numResults: number = 0;
	private _pageSubject = new Subject<number>();
	private _$pageObservable = this._pageSubject.asObservable();

	public init(numResults: number, pageSize: number, currentPage: number = 1) {
		this._numResults = numResults;
		this._resultsPerPage = pageSize;
		this._currentPage = Math.max(1, Math.min(currentPage, this.numPages));
	}

	set numResults(value: number) {
		this._numResults = value;
		this._currentPage = 1;
	}

	get pageChangeObservable(): Observable<number> {
		return this._$pageObservable;
	}

	get numResults(): number {
		return this._numResults;
	}

	get resultsPerPage(): number {
		return this._resultsPerPage;
	}

	set resultsPerPage(value: number) {
		// Must be a positive integer > 0
		this._resultsPerPage = Math.max(1, value);
	}

	get currentPage(): number {
		return this._currentPage;
	}

	set currentPage(value: number) {
		// Must be a positive integer > 0 and less than or equal to the number of pages
		this._currentPage = Math.max(1, Math.min(value, this.numPages));
		this._pageSubject.next(this._currentPage);
	}

	get numPages(): number {
		return Math.ceil( this._numResults / this.resultsPerPage );
	}
}

class Paginator<T> {
	private static readonly defaultResultsPerPage = 20;
	private static noSort: SortFunc = (a, b) => 0;

	private _src: TableDataSource<T>;
	private _currentPage: number = 1;

	private _resultsPerPage: number = Paginator.defaultResultsPerPage;
	private _sortFunc: SortFunc = Paginator.noSort;

	set sortFunc(value: SortFunc) {
		this._sortFunc = value || Paginator.noSort;
	}

	get resultsPerPage(): number {
		return this._resultsPerPage;
	}

	set resultsPerPage(value: number) {
		// Must be a positive integer > 0
		this._resultsPerPage = Math.max(1, value);
	}

	get numResults(): number {
		return this._src.length;
	}

	set src(source: TableDataSource<T>) {
		this._src = source;
		this.currentPage = 1;
	}

	get currentPage(): number {
		return this._currentPage;
	}

	set currentPage(value: number) {
		// Must be a positive integer > 0 and less than or equal to the number of pages
		this._currentPage = Math.max(1, Math.min(value, this.numPages));
	}

	get numPages(): number {
		return Math.ceil( this._src.length / this.resultsPerPage );
	}

	get items(): T[] {
		const startIdx = (this.currentPage - 1) * this.resultsPerPage;
		const endIdx = Math.min( startIdx + this._resultsPerPage, this._src.length );
		return this._src.getItems( startIdx, endIdx, this._sortFunc );
	}
}

export class Table<T> {

	private tableName: string;
	private columnMap = new Map<string, TableColumnHeading>();

	private sortMap = new Map<SortType, SortFunc>([
		[SortType.none, (a, b) => 0],
		[SortType.text, (a, b) => `${a}`.localeCompare(`${b}`)],
		[SortType.numeric, (a, b) => (a || 0) - (b || 0)],
		[SortType.date, (a, b) => moment(a).unix() - moment(b).unix()],
		[SortType.boolean, (a, b) => (a || 0) - (b || 0)],
		[SortType.name, (a, b) => {
				const nameA = `${a}`.split(" ").reverse().join(", ");
				const nameB = `${b}`.split(" ").reverse().join(", ");
				return nameA.localeCompare(nameB);
		}]
	]);

	columns: TableColumnHeading[] = [];
	moreColumns: TableColumnHeading[] = [];
	sortDirection: SortDirection = SortDirection.ascending;
	sortColumn: TableColumnHeading = undefined;
	alignment: any = {
		"center": Alignment.center,
		"left": Alignment.left,
		"right": Alignment.right,
		"justify": Alignment.justify
	};

	readonly notSortable = SortType.none;
	readonly sortAscending = SortDirection.ascending;
	readonly sortDescending = SortDirection.descending;

	private _filter: FilterFunc = undefined;
	// private _sourceData: any[] = [];
	protected _dataSource: TableDataSource<T> = undefined;
	protected _displayDataSource: Subject<T[]> = new Subject<T[]>();
	private displayData$ = this._displayDataSource.asObservable();

	// get displayData(): any[] {
	// 	return this.paginator.items;
	// }

	displayData: any[] = [];

	// set sourceData(source: any[]) {
	// 	this._sourceData = source;
	// 	if (this.paginator) {
	// 		this.paginator.items = this._sourceData;
	// 	}
	// 	this.updateDisplayData();
	// }

	set sourceData(source: TableDataSource<T>) {
		this._dataSource = source;
		this.updateDisplayData();
	}

	set filter(value: FilterFunc) {
		this._filter = value;
		this.updateDisplayData();
	}

	get hasData(): boolean {
		return this.displayData.length > 0;
	}

	/**
	* Creates a new table object.
	*
	* @constructor
	* @param tableName {string} Used to store in-memory sort information across tables
	* @param columnHeadings List of column headings to display on the table
	*/
	constructor(tableName: string, columnHeadings: TableColumnHeading[], moreColumnHeadings?: TableColumnHeading[]) {
		this.tableName = tableName;
		this.columns = columnHeadings;
		this.moreColumns = moreColumnHeadings;
		this.columns.forEach( heading => {
			this.columnMap.set(heading.propName, heading);
		});

		this.displayData$.subscribe( data => {
			// this.paginator.items = data;
			this.displayData = data;
		});

		this.sortColumn = DataExchangeService.get(`${this.tableName}.sort`);
		this.sortDirection = DataExchangeService.get(`${this.tableName}.sortDirection`);
	}

	isSortedColumn(column: TableColumnHeading): boolean {
		return this.sortColumn !== undefined && this.sortColumn.propName === column.propName;
	}

	protected getSortFunc(): SortFunc {

		if (this.sortColumn === undefined) {
			return this.sortMap.get(SortType.none);
		}

		const multiplier = this.sortDirection === SortDirection.descending ? -1 : 1;

		let sortFunc = this.sortMap.get(this.sortColumn.sortType);
		return (a, b) => {
			return multiplier * sortFunc(a[this.sortColumn.propName], b[this.sortColumn.propName]);
		};
	}

	protected updateDisplayData(): void {

		if (!this._dataSource) {
			return;
		}

		let unsortedData = [...this._dataSource.items];
		const items = unsortedData.sort( this.getSortFunc() );
		this._displayDataSource.next( items );
	}

	columnIsSorted(column: TableColumnHeading): boolean {
		return this.sortColumn && this.sortColumn.propName === column.propName;
	}

	setDefaultSort(sortColumn: TableColumnHeading, sortDirection: SortDirection) {
		this.sortDirection = sortDirection;
		this.sortColumn = sortColumn;
		DataExchangeService.set(this.sortDirection, `${this.tableName}.sortDirection`);
		DataExchangeService.set(this.sortColumn, `${this.tableName}.sort`);
	}

	changeSort(sortColumn: TableColumnHeading): void {

		DataExchangeService.set(true, `${this.tableName}.sortpreference.set`);
		if (this.sortColumn && this.sortColumn.propName === sortColumn.propName) {

			DataExchangeService.set(this.sortColumn, `${this.tableName}.sort`);

			if (this.sortDirection === SortDirection.ascending) {
				this.sortDirection = SortDirection.descending;
			}
			else if (this.sortDirection === SortDirection.descending) {
				this.sortDirection = SortDirection.ascending;
				DataExchangeService.set(undefined, `${this.tableName}.sort`);
				this.sortColumn = undefined;

				this.updateDisplayData();
				return;
			}
			else {
				this.sortDirection = SortDirection.ascending;
			}

			DataExchangeService.set(this.sortDirection, `${this.tableName}.sortDirection`);
		}
		else {
			this.sortDirection = SortDirection.ascending;
			this.sortColumn = sortColumn;
		}

		DataExchangeService.set(this.sortColumn, `${this.tableName}.sort`);
		DataExchangeService.set(this.sortDirection, `${this.tableName}.sortDirection`);

		this.updateDisplayData();
	}
}

export function getSortFunc(sortType: SortType, direction?: SortDirection): SortFunc {
	const multiplier = direction === SortDirection.descending ? -1 : 1;
	switch (sortType) {
		case SortType.none: return (a, b) => 0;
		case SortType.text: return (a, b) => multiplier * a.localeCompare(b);
		case SortType.numeric: return (a, b) => multiplier * (a - b);
		case SortType.date: return (a, b) => multiplier * (moment(a).unix() - moment(b).unix());
		case SortType.name: return (a, b) => {
			const nameA = a.split(" ").reverse().join(", ");
			const nameB = b.split(" ").reverse().join(", ");
			return multiplier * nameA.localeCompare(nameB);
		};
	}
}

export class PaginatedTable<T> extends Table<T> implements PaginationProvider {
	private paginator: Paginator<T>;

	constructor(tableName: string, columnHeadings: TableColumnHeading[]) {
		super(tableName, columnHeadings);
		this.paginator = new Paginator<T>();
	}

	get resultsPerPage(): number {
		return this.paginator ? this.paginator.resultsPerPage : Number.MAX_SAFE_INTEGER;
	}

	set resultsPerPage(value: number) {
		if (this.paginator) {
			this.paginator.resultsPerPage = value;
		}
	}

	get numResults(): number {
		return this.paginator.numResults;
	}

	get numPages(): number {
		return this.paginator ? this.paginator.numPages : 0;
	}

	get currentPage(): number {
		return this.paginator ? this.paginator.currentPage : 0;
	}

	set currentPage(value: number) {
		if (this.paginator) {
			this.paginator.currentPage = value;
			this.updateDisplayData();
		}
	}

	protected updateDisplayData(): void {

		// let unsortedData = [...this._dataSource.items];
		// if (this._filter !== undefined) {
		// 	unsortedData = unsortedData.filter(this._filter);
		// }

		// const items = unsortedData.sort( this.getSortFunc() );
		// if (this.paginator) {
		// 	this.paginator.items = items;
		// 	this._displayDataSource.next( this.paginator.items );
		// }
		// else {
		// 	this._displayDataSource.next( items );
		// }

		this.paginator.sortFunc = this.getSortFunc();
		this._displayDataSource.next( this.paginator.items );

	}


	set sourceData(source: TableDataSource<T>) {
		this._dataSource = source;
		this.paginator.src = source;
		this.updateDisplayData();
	}

}
