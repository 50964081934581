import { Injectable } from '@angular/core';
import { RestService, API } from './rest.service';
import { PlanService } from './plan.service';
import { BillingScheduleUtils, BillingSchedule } from '@classes/billing';
import { Cache } from "@classes/cache";
import { SupportItem } from "@classes/supports";
import { SupportsService } from "@services/supports.service";
import { Plan } from "@classes/plans";


@Injectable({ "providedIn": "root" })
export class BillingService {

	constructor(private restService: RestService, private planService: PlanService, private supportsService: SupportsService) {}

	private _cache: Map<string, Cache<BillingSchedule>> = new Map<string, Cache<BillingSchedule>>();
	private _supports: SupportItem[] = [];

	private getCache(planId: string): Cache<BillingSchedule> {
		if (!this._cache.has(planId)) {
			this._cache.set(planId, new Cache<BillingSchedule>());
		}

		return this._cache.get(planId);
	}

	public async getBillingSchedule(plan: Plan): Promise<BillingSchedule> {
		const cache = this.getCache(plan.id);
		if (cache.valid) {
			const items = cache.items;
			return items.length ? items[0] : undefined;
		}

		const [supports, response] = await Promise.all([
			this.supportsService.getSupportsFor(plan.region, plan.startDate),
			this.restService.get(API.billing, `plan/${plan.id}`)
		]);

		return BillingScheduleUtils.jsonToBillingSchedule(response.schedule);
	}

	public async newSchedule(plan: Plan): Promise<BillingSchedule> {
		this._supports = await this.supportsService.getSupportsFor(plan.region, plan.startDate);
		return BillingScheduleUtils.createNew(plan, this._supports);
	}

	public save(schedule: BillingSchedule, supportItems: SupportItem[]): Promise<BillingSchedule> {
		if (this._cache.has(schedule.planId)) {
			this._cache.delete(schedule.planId);
		}

		const postData = {
			"schedule": BillingScheduleUtils.toJson(schedule)
		};

		return this.restService.post(API.billing, "", postData).then( response => {

			const cache = this.getCache(schedule.planId);

			if (!response.schedule) {
				this._cache.delete(schedule.planId);
				return Promise.resolve( undefined );
			}

			const billingSchedule = BillingScheduleUtils.jsonToBillingSchedule(response.schedule);
			cache.items = [billingSchedule];

			return Promise.resolve( billingSchedule );
		});
	}

}
