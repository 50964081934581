export interface BankInformation {
	authcode: string;
	authcodeRequested: boolean;
	isUnlocked: boolean;
	bankAccountName: string;
	bankAccountNumber: string;
	bankAccountBSB: string;
	bankAccountParticulars: string;
}

export class BankInformationUtils {
	static newInfo(): BankInformation {
		return {
			"authcode": null,
			"authcodeRequested": false,
			"isUnlocked": false,
			"bankAccountName": null,
			"bankAccountNumber": null,
			"bankAccountBSB": null,
			"bankAccountParticulars": null
		};
	}

	static validateBsb(bsb: string): boolean {
		return /^\d{3}-?\d{3}$/.test(bsb);
	}

	static validateAccountNumber(accountNumber: string): boolean {
		return /^\d{5,9}$/.test(accountNumber);
	}

	static from(src: any): BankInformation {
		if (!src) {
			return undefined;
		}

		let result = BankInformationUtils.newInfo();
		result.bankAccountBSB         = src.bankAccountBSB || src.bsb || null;
		result.bankAccountName        = src.bankAccountName || src.accountName || null;
		result.bankAccountNumber      = src.bankAccountNumber || src.accountNumber || null;
		result.bankAccountParticulars = src.bankAccountParticulars || src.notes || null;

		return result;
	}
}
