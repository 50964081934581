import { Subject } from 'rxjs';
import { ServiceLocator } from "./locator.service";
import { Location } from '@angular/common';

export interface MenuItem {
	icon: string;
	text: string;
	route?: string;
	counter?: number;
	handler?: () => void;
	disabled: () => boolean;
	visible: () => boolean;
}

export class MenuBuilder {

	private _menuItems: MenuItem[] = [];
	private location: Location;

	private notDisabled: () => boolean = () => {
		return false;
	}

	private visible: () => boolean = () => {
		return true;
	}

	constructor() {
		this.location = ServiceLocator.injector.get(Location);
	}

	addHome(): void {
		this.addRoute("home", "Home", "/dashboard");
	}

	addBackButton(): void {
		this.addHandler("arrow-left", "Back", () => { this.location.back() });
	}

	addRoute(icon: string, text: string, route: string, disabled?: () => boolean, visible?: () => boolean) {
		this._menuItems.push({
			"icon": icon,
			"text": text,
			"route": route,
			"disabled": disabled || this.notDisabled,
			"visible": visible || this.visible
		});
	}

	addHandler(icon: string, text: string, handler: () => void, disabled?: () => boolean, visible?: () => boolean, counter: number = 0) {
		this._menuItems.push({
			"icon": icon,
			"text": text,
			"handler": handler,
			"counter": counter,
			"disabled": disabled || this.notDisabled,
			"visible": visible || this.visible
		});
	}

	done(): void {
		NavMenuService.setItems(this._menuItems);
	}
}

export class NavMenuService {

	static setItems(items: MenuItem[]): void {
		NavMenuService.menuItemsSource.next(items);
	}

	private static menuItemsSource = new Subject<MenuItem[]>();

	static menuItems$ = NavMenuService.menuItemsSource.asObservable();
}
