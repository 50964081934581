export interface Address {
	address1: string;
	address2?: string;
	suburb: string;
	state: string;
	postcode: string;
}

export class AddressClass implements Address {
	public address1: string;
	public address2?: string;
	public suburb: string;
	public state: string;
	public postcode: string;

	static readonly addressFields = ["address1", "address2", "suburb", "state", "postcode"]

	public static blank(): Address {
		return new AddressClass(undefined, undefined, undefined, undefined, undefined);
	}

	private constructor(address1, address2, suburb, state, postcode) {
		this.address1 = address1;
		this.address2 = address2;
		this.suburb = suburb;
		this.state = state;
		this.postcode = postcode;
	}

	public static parse(address1, address2, suburb, state, postcode): Address {
		return new AddressClass(address1, address2, suburb, state, postcode);
	}

	public static parseObject(src: Address|any): Address {
		if (!src) {
			return undefined;
		}

		return new AddressClass(src.address1, src.address2, src.suburb, src.state, src.postcode);
	}

	public static toString(src: Address, separator: string = ', '): string {
		if (!src) {
			return undefined;
		}

		const addressParts = [src.address1, src.address2, src.suburb];
		if (!!src.state && !!src.postcode) {
			addressParts.push(`${src.state} ${src.postcode}`);
		}
		else {
			addressParts.push(src.state);
			addressParts.push(src.postcode);
		}
		return addressParts.filter( x => !!x ).join(separator);
	}
}
