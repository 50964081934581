import { Injectable } from '@angular/core';
import { RestService, API } from './rest.service';
import { Cache } from "@classes/cache";

export enum AllocationType {
	remaining, paid, allocated
}

export interface PlanCategoryAllocation {
	id: string;
	supportCategoryId: string;
	supportCategory: string;
	categoryAllocation: number;
	pending: number;
	remaining: number;
	delivered: number;
	allocated?: number;
	variance?: number;
	varianceLevel?: number;
}

@Injectable({ "providedIn": 'root' })
export class PlanAllocationService {

	private static maxDataAgeSeconds: number = 5 * 60; // 5 mins * 60 seconds per minute


	private _cache = new Map<string, Cache<PlanCategoryAllocation>>();

	private waitQueue: any[] = [];
	private isLoading: boolean = false;

	constructor(private restService: RestService) {}

	private getCache(clientId: string): Cache<PlanCategoryAllocation> {
		if (!this._cache.has(clientId)) {
			this._cache.set(clientId, new Cache<PlanCategoryAllocation>( PlanAllocationService.maxDataAgeSeconds ));
		}

		return this._cache.get(clientId);
	}

	getData(clientId: string, forceReload: boolean = false): Promise<PlanCategoryAllocation[]> {

		const cache = this.getCache(clientId);

		if (forceReload) {
			cache.invalidate();
		}

		return new Promise( (resolve, reject) => {

			// Don't make a new request if an existing request is pending
			if (this.isLoading) {
				console.log("Queuing request");
				this.waitQueue.push( {
					"success": resolve,
					"error": reject
				});
				return;
			}

			if (cache.needsRefresh) {
				console.log("Loading data from server");
				this.isLoading = true;

				return this.restService.get(API.user, `plan/allocation/${clientId}`).then( result => {

					cache.items = result;
					console.log("Cache items set");
					this.isLoading = false;
					console.log(cache.items);

					while (this.waitQueue.length) {
						const queuedItem = this.waitQueue.pop();
						queuedItem.success.call(null, cache.items);
					}

					return resolve(cache.items);
				}).catch( e => {

					this.isLoading = false;
					while (this.waitQueue.length) {
						const queuedItem = this.waitQueue.pop();
						queuedItem.error.call(null, e);
					}

					return reject(e);

				});

			}
			else {
				return resolve( cache.items );
			}

		});

	}
}
