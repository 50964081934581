export interface Setting {
	name: string;
	value: any;
}

export class Settings {
	private constructor() {}

	private static _instance: Settings;

	private _settings: Map<string, any> = new Map<string, any>();

	public set(name: string, value: any): void {
		this._settings.set(name, value);
	}

	public setAll(items: Setting[]): void {
		this._settings.clear();
		items.forEach(item => {
			this._settings.set(item.name, item.value);
		});
	}

	public get(name: string): any {
		return this._settings.get(name);
	}

	public has(name: string): boolean {
		return this._settings.has(name);
	}

	public static get instance(): Settings {
		if (Settings._instance === undefined) {
			Settings._instance = new Settings();
		}

		return Settings._instance;
	}
}
