import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrivateComponent } from "@classes/private.component";
import { AttachedFile } from "@classes/files";
import { Table, TableColumnHeading, SortType, StaticDataSource } from "@classes/tables";
import { AttachmentTargetType, AttachmentTargetUtils  } from '@classes/attachments';
import { AttachmentType } from "@classes/attachmentType";
import { User, UserType } from "@classes/user";
import { MenuBuilder } from "@services/navmenu.service";
import { SupportCategory, SupportItem, UnitOfMeasure } from "@classes/supports";
import { Plan, PlanStatus, PlanSupportCategory, PlanSupportItem/*, ProviderToPlan*/ } from "@classes/plans";
import { PlanBudget, BudgetType } from "@classes/budget";
import { Region, RegionUtils } from "@classes/regions";
import { assert, ErrorUtils } from "@classes/errors";
import { PlanError, PlanErrorChecker } from "@classes/planErrorChecker";
import { PlanService } from "@services/plan.service";
import { Provider, ProviderService } from "@services/provider.service";
import { AttachmentsService } from "@services/attachments.service";
import { FileManager } from "@classes/filemanager";
import { UserAccountService } from "@services/accounts.service";
import { DataExchangeService } from "@services/dataexchange.service";
import { OverlayService } from "@services/overlay.service";
import { BillingService } from "@services/billing.service";
import { BillingSchedule, BillingItem } from "@classes/billing";
import { BillingScheduleManager } from "@classes/billingschedulemanager";
import { Utils, DateUtils } from "@classes/utils";
import { SupportsService } from "@services/supports.service";
import { ServiceAgreement } from "@classes/serviceAgreement";
import { CacheSignalService } from "@services/cachesignal.service";
import { takeUntil } from "rxjs/operators";
import moment from 'moment';

enum Tabs {
	summary, categories, documents, billing, serviceagreements, consumption
}

@Component({
	"styleUrls": ["./editplan.component.scss"],
	"templateUrl": "./editplan.component.html"
})
export class EditPlanComponent extends PrivateComponent implements OnInit {

	// @ViewChild('supportCategories') private categoryListTemplate: TemplateRef<any>;
	// @ViewChild('editCategory') private editCategoryTemplate: TemplateRef<any>;
	// @ViewChild('editSupportItem') private editSupportItemTemplate: TemplateRef<any>;
	// @ViewChild('providerDialog') private providerDialogTemplate: TemplateRef<any>;
	@ViewChild('attachmentDialog') private attachmentDialog: TemplateRef<any>;
	@ViewChild('mergePlanDialog') private mergePlanDialog: TemplateRef<any>;
	@ViewChild('confirmMergeDialog') private confirmMergeDialog: TemplateRef<any>;

	private _plan: Plan = undefined;
	private _allPlans: Plan[] = [];
	private _dataLoaded: boolean = false;
	private _newCategoryVisible: boolean = false;
	private _planChanged: boolean = false;
	private _supportCategories: SupportCategory[] = [];
	private _serviceAgreements: ServiceAgreement[] = [];

	private readonly attachmentTableHeadings: TableColumnHeading[] = [
		{"propName": "name", "displayName": "Name", "sortType": SortType.text },
		{"propName": "attachmentType", "displayName": "Type", "sortType": SortType.text },
		{"propName": "size", "displayName": "Size", "sortType": SortType.numeric },
		{"propName": "dateAdded", "displayName": "Date Added", "sortType": SortType.date }
	];
	attachmentsTable: Table<AttachedFile> = new Table('attachmentstable', this.attachmentTableHeadings);

	/**
	* Map of promises that resolve to a list of support items, keyed by support category.
	* Used to populate the drop down list of available support items when adding to the service agreement.
	*/
	private _categorySupportsMap: Map<number, Promise<SupportItem[]>> = new Map<number, Promise<SupportItem[]>>();

	public mergePlan: Plan;
	public confirmMergeChecked: boolean = false;

	/**
	* Stores a list of valid supports for the plan. Can only be populated once the plan header information
	* (dates and region) have been set.
	*/
	private _supportsCache: SupportItem[] = [];

	private _allProviders: Provider[] = [];
	private _billingSchedule: BillingSchedule = undefined;

	private _billingScheduleManager: BillingScheduleManager = new BillingScheduleManager();

	get bsm(): BillingScheduleManager {
		return this._billingScheduleManager;
	}

	private _planError: PlanError = undefined;
	private _planErrors: PlanError[] = [];
	private _hasPlanError: boolean = false;
	protected planStatus = {
		"draft": PlanStatus.proposed,
		"current": PlanStatus.current
	};

	get plan(): Plan {
		return this._plan;
	}

	get planErrors(): PlanError[] {
		return this._planErrors;
	}

	get billingSchedule(): BillingSchedule {
		return this._billingSchedule;
	}

	get visiblePlanErrors(): PlanError[] {
		const allErrors = this._planErrors;
		return allErrors.filter( error => !error.hidden );
	}

	public get serviceAgreements(): ServiceAgreement[] {
		return this._serviceAgreements;
	}

	private _currentTab: Tabs = Tabs.summary;
	public get currentTab(): Tabs { return this._currentTab; }
	public readonly tab = {
		"summary": Tabs.summary,
		"categories": Tabs.categories,
		"documents": Tabs.documents,
		"serviceagreements": Tabs.serviceagreements,
		"billing": Tabs.billing,
		"consumption": Tabs.consumption
	};
	
	constructor(
		private planService: PlanService,
		private providerService: ProviderService,
		private userAccountService: UserAccountService,
		private billingService: BillingService,
		private attachmentsService: AttachmentsService,
		private supportsService: SupportsService,
		private signalService: CacheSignalService,
		private route: ActivatedRoute
	) {
		super();

		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Account Details', 'Edit Plans');
	}

	public changeTab($event: MouseEvent, tab: Tabs): void {
		$event.stopPropagation();
		$event.preventDefault();
		this._currentTab = tab;
	}

	get allProviders(): Provider[] {
		return this._allProviders;
	}

	get planError(): PlanError {
		return this._planError;
	}

	setPlanChanged() {
		this._planChanged = true;
	}

	get newCategoryVisible(): boolean {
		return [PlanStatus.current, PlanStatus.proposed].includes(this._plan.status) && !!this._plan.id;
	}

	get supportCategories(): SupportCategory[] {
		return this._supportCategories;
	}

	get supportCategoriesLoaded(): boolean {
		return this._supportCategories.length > 0;
	}

	get planCategories(): PlanSupportCategory[] {
		const categories: PlanSupportCategory[] = Array.from( this.model.supportCategories.values() );
		return categories.sort( (a, b) => a.supportCategory.id - b.supportCategory.id );
	}

	get allowedPlanStatuses(): PlanStatus[] {
		return PlanStatus.allValues();
	}

	get isDraft(): boolean {
		return this._plan.status === this.planStatus.draft;
	}

	get categoryBudgetsError(): string {
		const supportCategories = Array.from(this.plan.supportCategories.values());
		if (supportCategories.some( category => category.total < 0)) {
			return `Please enter a budget for all support categories in this plan`;
		}

		const budgetDifference = this.categoryBudgetsTotal - this.model.total;
		const displayValue = Math.abs(budgetDifference).toFixed(2);

		if (this.isNotRoundingError(budgetDifference)) {
			if (budgetDifference > 0) {
				return `Category budgets exceeds plan budget (excess of $${displayValue})`;
			}
			else if (budgetDifference < 0) {
				return `Category budgets total is less than plan budget (difference of $${displayValue})`;
			}
		}
	}

	get categoryBudgetsTotal(): number {
		const catBudgetTotal = this.model.supportCategories.reduce( (total: number, category: PlanSupportCategory) => {
			const categoryTotal = Number(category.total);
			return total + (isNaN(categoryTotal) ? 0 : categoryTotal);
		}, 0);
		return Math.round(catBudgetTotal * 100)/100; //hotfix isd-1556 number float issue for decimal places
	}

	get categoryBudgetsMatchTotal(): boolean {
		if((this.model.total === undefined) || this.isNewPlan){
			return true;
		}
		return this.categoryBudgetsTotal == this.model.total;
	}

	readonly regions: Region[] = RegionUtils.allValues();
	readonly planStatuses: PlanStatus[] = PlanStatus.allValues();

	model: any = {
		"clientName": undefined,
		"client": undefined,
		"startDate": undefined,
		"endDate": undefined,
		"status": undefined,
		"ndisNumber": undefined,
		"total": undefined,
		"dashboardInclusionDate": undefined,
		"dashboardComment": undefined,
		"region": undefined,
		"supportCategories": new Map<number, PlanSupportCategory>(),
		"pace": undefined
	};

	supplementalModel: any = {
		"selectedSupportCategories": new Map<number, SupportCategory>(),
		"category": undefined
	};

	users: any[] = [];

	private buildMenu(): void {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.addRoute("list-ul", "List Accounts", "/listaccounts", undefined, () => this.hasPermission('Users', 'List Accounts') );

		if (this.hasPermission('Plans', 'Merge Plans')) {
			menuBuilder.addHandler("code-branch", "Merge Plans", () => {
				this.showMergePlanDialog();
			}, null, () => {
				return this.mergePlanVisible();
			});
		}

		menuBuilder.done();
	}

	// private loadUsers(): Promise<any> {
	// 	return this.userAccountService.getAccounts().then( users => {
	// 		this.users = users.filter( user => user.accountType === UserType.Participant).map( user => {
	// 			return {
	// 				"id": user.id,
	// 				"name": `${user.firstName} ${user.lastName}`,
	// 				"ndisNumber": user.ndisNumber
	// 			};
	// 		});
	// 	});
	// }

	private async loadSupports(plan: Plan): Promise<void> {
		this._supportsCache = await this.supportsService.getSupportsFor(plan.region, plan.startDate);
	}

	private async loadSupportCategories(): Promise<void> {
		this._supportCategories = await this.supportsService.getSupportCategories();
	}

	private async loadServiceAgreements(plan: Plan): Promise<void> {
		this._serviceAgreements = await this.planService.serviceAgreements(plan);
	}

	protected formatDate(date: Date): string {
		if (!date) {
			return "";
		}

		try {
			return moment(date, "DD/MM/YYYY").format("DD/MM/YYYY");
		}
		catch (e) {
			return "";
		}
	}

	private _client: User;
	public get client(): User {
		return this._client;
	}

	private async loadPlan(planId: string): Promise<void> {

		this._allPlans = await this.planService.siblingPlans(planId);
		this._plan = this._allPlans.find( plan => plan.id === planId );
		assert(this._plan);

		this.bsm.plan = this._plan;

		await Promise.all([
			this.loadSupports(this._plan),
			this.loadServiceAgreements(this._plan),
			this.loadSupportCategories()
		]);

		this._client = await this.userService.loadUser(this._plan.client);
		this.mruService.push(this._client);

		this.model = {
			"id": this._plan.id,
			"clientName": `${this._client.firstName} ${this._client.lastName}`,
			"client": this._plan.client,
			"startDate": this.formatDate(this._plan.startDate),
			"endDate": this.formatDate(this._plan.endDate),
			"status": this._plan.status,
			"ndisNumber": this._client.ndisNumber,
			"total": this._plan.total,
			"dashboardInclusionDate": this.formatDate(this._plan.dashboardInclusionDate),
			"dashboardComment": this._plan.dashboardComment,
			"region": this._plan.region,
			"supportCategories": this._plan.supportCategories,
			"pace": this._plan.pace
		};

		this.attachmentsTable.sourceData = StaticDataSource.from(this.plan.attachments);

		this.updateErrors();
	}

	private newPlan(): Promise<any> {
		return this.planService.newPlan().then( (plan: Plan) => {
			this._plan = plan;

			// Attempt to load the current user from the data exchange service.
			const clientId = DataExchangeService.get("planUser", false);
			this._plan.client = clientId;

			if (clientId) {
				return this.userService.loadUser(this._plan.client);
			}
			else {
				return Promise.resolve(undefined);
			}

		}).then( (user: User) => {

				if (user) {

					this.mruService.push(user);

					this.model = {
						"clientName": `${user.firstName} ${user.lastName}`,
						"client": user.id,
						"startDate": undefined,
						"endDate": undefined,
						"status": PlanStatus.proposed,
						"ndisNumber": user.ndisNumber,
						"total": undefined,
						"dashboardInclusionDate": undefined,
						"dashboardComment": undefined,
						"region": undefined,
						"supportCategories": [],
						"pace": false,
						// "supportCategories": new Map<number, PlanSupportCategory>()
					};
				}
				else {
					this.model = {
						"clientName": undefined,
						"client": undefined,
						"startDate": undefined,
						"endDate": undefined,
						"status": PlanStatus.proposed,
						"ndisNumber": undefined,
						"total": undefined,
						"dashboardInclusionDate": undefined,
						"dashboardComment": undefined,
						"region": undefined,
						"supportCategories": [],
						"pace": false,
						// "supportCategories": new Map<number, PlanSupportCategory>()
					};
				}

		});
	}

	// private loadProviders(): Promise<any> {
	// 	return this.providerService.getProviders().then( providers => {
	// 		this._allProviders = providers;
	// 		return Promise.resolve();
	// 	});
	// }

	getNDISNumber(value): string {
		return value || "Unknown";
	}

	ngOnInit() {
		super.ngOnInit()

		this.route.params.subscribe( params => {

			this._dataLoaded = false;
			if (this.user) {

				OverlayService.show();

				let promises: Promise<any>[] = [];

				if (params.id === "new") {
					promises.push( this.newPlan() );
				}
				else {
					promises.push( this.loadPlan(params.id) );
				}

				// promises.push( this.loadUsers() );
				// promises.push( this.loadProviders() );

				return Promise.all(promises).then( () => {
					this._dataLoaded = true;
					this.attachmentsTable.sourceData = StaticDataSource.from(this.plan.attachments);
					this.buildMenu();
					OverlayService.hide();
				});
			}
		});

		this.signalService.observable('Attachments').pipe( takeUntil(this.unsubscribe) ).subscribe( this.externalAttachmentUpdate.bind(this) );
	}

	/**
	* Handler called via subscription to the "Attachments" signal sent via the CacheSignalService.
	* Called in response to an update to attachments which may affect the plan being edited. The
	* only current source for this event is the "ServiceAgreements" component.
	*/
	private externalAttachmentUpdate(event: any) {
		if (!event) {
			return;
		}

		this.plan.attachments = this.plan.attachments || [];

		if (event.savedFiles && event.savedFiles.length) {
			event.savedFiles.forEach(file => {

				this.plan.attachments.push(file);
			});
		}

		if (event.deletedFiles && event.deletedFiles.length) {
			event.deletedFiles.forEach(fileId => {
				const idx = this.plan.attachments.findIndex( item => item.id === fileId );
				if (idx >= 0) {
					this.plan.attachments.splice(idx, 1);
				}
			});
		}

		this.attachmentsTable.sourceData = StaticDataSource.from(this.plan.attachments);
	}

	/**
	* Returns the amount of funding currently allocated to invoices.
	* Used to indicate current spending on a plan's budget rather than the "amount remaining", since
	* this value is independent of the budget's total
	*/
	public allocatedAmount(budget: PlanBudget): number {
		return Math.max(0, budget.paid + budget.pending + budget.draft + budget.unknown);
	}

	get dataLoaded(): boolean {
		return this._plan !== undefined && this._dataLoaded;
	}

	get isNewPlan(): boolean {
		return this.dataLoaded && !this._plan.id;
	}

	private parseDate(value: string): moment.Moment {
		return moment(value, 'DD/MM/YYYY');
	}

	get modelValid(): boolean {
		const conditions = [
			this.model.client !== undefined,
			this.model.startDate !== undefined,
			this.model.endDate !== undefined,
			this.model.total !== undefined,
			this.model.status !== undefined,
			this.model.region !== undefined,
			this.parseDate(this.model.startDate).isSameOrBefore(this.parseDate(this.model.endDate)),
			this.model.total > 0,
			this.parseDate(this.model.startDate).isAfter(moment('2012-12-31', 'YYYY-MM-DD')),
			this.parseDate(this.model.startDate).isBefore(moment('2050-12-31', 'YYYY-MM-DD')),
			this.parseDate(this.model.endDate).isAfter(moment('2012-12-31', 'YYYY-MM-DD')),
			this.parseDate(this.model.endDate).isBefore(moment('2050-12-31', 'YYYY-MM-DD')),
			this.model.pace !== undefined,
		];

		return conditions.every( condition => condition === true );
	}

	get planChanged(): boolean {
		const conditions = [
			this._planChanged === true,
			this.model.client !== this._plan.client,
			!(this.parseDate(this.model.startDate).isSame(moment(this._plan.startDate))),
			!(this.parseDate(this.model.endDate).isSame(moment(this._plan.endDate))),
			this.model.total !== this._plan.total,
			this.model.dashboardInclusionDate !== this._plan.dashboardInclusionDate,
			this.model.dashboardComment !== this._plan.dashboardComment,
			this.model.status !== this._plan.status,
			this.model.pace !== this._plan.pace,
		];

		return conditions.some( condition => condition === true );
	}

	get hasStartDateAndBudget(): boolean {
		const conditions = [
			this._plan.startDate !== undefined,
			this._plan.total !== undefined,
			this._plan.region !== undefined,
			this._plan.total > 0
		];
		return conditions.every(item => item === true);
	}

	clientSelected(item: User) {
		this._client = item;
		if (!!item) {
			this.model.clientName = item.name || undefined;
			this.model.client = item.id || undefined;
			this.model.ndisNumber = item.ndisNumber || undefined;
			this.model.region = undefined;
			this._plan.client = item.id || undefined;
		}
	}

	planStatusName: (planStatus: PlanStatus) => string = PlanStatus.toString;

	// addCategory() {

	// 	// Update the "supplemental model" of support categories to make sure it matches the
	// 	// current "live" selection
	// 	this.supplementalModel.selectedSupportCategories.clear();
	// 	Array.from(this.model.supportCategories.values()).forEach( (planCategory: PlanSupportCategory) => {
	// 		this.supplementalModel.selectedSupportCategories.set(planCategory.supportCategory.id, planCategory.supportCategory);
	// 	});

	// 	OverlayService.showTemplate(this.categoryListTemplate, this.supportCategories);
	// }

	// cancelAddCategory() {
	// 	this._newCategoryVisible = false;
	// }

	private async checkPlan(): Promise<boolean> {

		const allPlans = await this.planService.listPlans(this.model.client);

		if (this.model.status === PlanStatus.current) {

			// Check that there aren't any other plans currently marked as "current"
			const alreadyScheduled = allPlans.some( plan => plan.status === PlanStatus.current && plan.id !== this.model.id );
			if (alreadyScheduled) {

				OverlayService.showDialog(
					"Confirm",
					// "There is already a current plan for this client.<br />This plan's status will be changed to 'Expired' if you continue.", [
					"There is already a current plan for this client.<br />Are you sure you want to continue?", [
						{"text": "Cancel", "handler": () => { OverlayService.hide(); }},
						{"text": "Continue", "handler": () => { OverlayService.hide(); this.savePlan(); }}
					]);

				return Promise.resolve(false);
			}
		}

		return Promise.resolve(true);
	}

	private modelToPlan(): Plan {
		const modelStringFormat = 'DDMMYYYY';
		const result = Plan.newPlan();

		result.id = this.model.id;
		result.client = this.model.client;
		result.region = this.model.region;
		result.startDate = moment(this.model.startDate, modelStringFormat).toDate();
		result.endDate = moment(this.model.endDate, modelStringFormat).toDate();
		result.total = this.model.total;
		result.status = this.model.status;
		result.dashboardInclusionDate = DateUtils.parse(this.model.dashboardInclusionDate, modelStringFormat)
		result.dashboardComment = this.model.dashboardComment;
		result.supportCategories = (this.model.supportCategories || []).map( PlanSupportCategory.clone );
		result.attachments = null;
		result.pace = this.model.pace;

		return result;
	}

	private async createNewPlan(): Promise<void> {
		OverlayService.show();
		try {
			const plan = await this.planService.save( this.modelToPlan()  );
			OverlayService.hide();
			this.router.navigate([`/plan/${plan.id}`]);
		}
		catch (e) {
			console.log(e);
			OverlayService.hide();
			OverlayService.showError("Unable to create plan", ErrorUtils.getErrorMessage(e, "An unknown error occurred"));
		}
	}

	protected async saveBillingSchedule() {
		if (!this.bsm.hasSchedule) {
			return;
		}

		OverlayService.show();
		try {
			await this.bsm.saveBillingSchedule();
			OverlayService.hide();
		}
		catch (e) {
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "Unable to save billing schedule"));
		}
	}

	private isNotRoundingError(difference: number): boolean {
		return Math.abs(difference) > 0.001;
	}

	private checkServiceAgreementDates(): Promise<void> {
		return new Promise( (resolve, reject) => {

			const modelStringFormat = 'DDMMYYYY';
			const planStartDate = moment(this.model.startDate, modelStringFormat);
			const planEndDate = moment(this.model.endDate, modelStringFormat);

			const hasError = this._serviceAgreements.reduce( (acc, serviceAgreement) => {
				if (acc) {
					return true;
				}

				const startDate = moment(serviceAgreement.dateFrom);
				const endDate = moment(serviceAgreement.dateTo);

				return startDate.isBefore(planStartDate) || endDate.isAfter(planEndDate);
			}, false);

			if (!hasError) {
				return resolve();
			}

			OverlayService.showDialog("Adjust service agreement dates", "One or more service agreement dates are outside the date range of the plan.<br />Automatically change these to match the plan dates?", [{
				"text": "Cancel",
				"handler": () => {
					OverlayService.hide();
					reject();
				}
			}, {
				"text": "Adjust and save",
				"handler": () => {

					this._serviceAgreements.forEach( serviceAgreement => {

						const startDate = moment(serviceAgreement.dateFrom);
						const endDate = moment(serviceAgreement.dateTo);

						if (startDate.isBefore(planStartDate)) {
							serviceAgreement.dateFrom = planStartDate.toDate();
						}

						if (endDate.isAfter(planEndDate)) {
							serviceAgreement.dateTo = planEndDate.toDate();
						}
					});

					resolve();
				}
			}]);

		});
	}

	private async updatePlan(): Promise<void> {
		if (this._plan.status !== PlanStatus.proposed && this.model.status === PlanStatus.proposed) {
			OverlayService.show();
			[this._plan, this._serviceAgreements] = await this.planService.toDraft( this._plan.id );
			OverlayService.hide();
			return;
		}

		try {
			await this.checkServiceAgreementDates();

			OverlayService.show();
			try {
				[this._plan, this._serviceAgreements] = await this.planService.update( this.modelToPlan(), this._serviceAgreements );
				this.bsm.plan = this._plan;

				if (this.bsm.hasSchedule) {
					await this.bsm.saveBillingSchedule();
				}
				await this.userService.loadUser(this._plan.client, true);
				this.refreshModel();
				OverlayService.hide();
			}
			catch (e) {
				OverlayService.hide();
				OverlayService.showError("Unable to update plan", ErrorUtils.getErrorMessage(e, "An unknown error occurred"));
			}

		}
		catch (e) {
			// Don't try and save the plan
		}
	}

	protected async savePlanCategories(): Promise<void> {
		OverlayService.show();
		try {
			this._plan = await this.planService.savePlanCategories( this.modelToPlan() );
			this.refreshModel();
			OverlayService.hide();
		}
		catch (e) {
			console.log(e);
			OverlayService.hide();
			OverlayService.showError("Unable to update plan", ErrorUtils.getErrorMessage(e, "An unknown error occurred"));
		}
	}

	private refreshModel() {
		this.model.id = this._plan.id;
		this.model.client = this._plan.client;
		this.model.startDate = this.formatDate(this._plan.startDate);
		this.model.endDate = this.formatDate(this._plan.endDate);
		this.model.status = this._plan.status;
		this.model.dashboardInclusionDate = this.formatDate(this._plan.dashboardInclusionDate);
		this.model.dashboardComment = this._plan.dashboardComment;
		this.model.region = this._plan.region;
		this.model.total = this._plan.total;
		this.model.supportCategories = (this._plan.supportCategories || []).map( PlanSupportCategory.clone );
	}

	protected savePlan() {
		this.updateErrors();
		// from button -- !modelValid || (planErrors.length > 0 && model.status !== planStatus.draft)
		if(((this.hasPlanError || !this.categoryBudgetsMatchTotal) && this.model.status !== this.planStatus.draft) || !this.modelValid){
			return;
		}
		if (this.isNewPlan) {
			this.createNewPlan();
		} else {
			this.updatePlan();
		}
	}

	addSupportCategory(): void {
		this._newCategoryVisible = false;

		this.model.supportCategories.set(this.supplementalModel.supportCategoryToAdd.id, {
			"supportCategory": this.supplementalModel.supportCategoryToAdd,
			"budget": 0,
			"bookingCode": undefined,
			"supportItems": new Map<string, PlanSupportItem>()
		});

		this.supplementalModel.supportCategoryToAdd = undefined;
	}

	cancelDialog(): void {
		OverlayService.hide();
	}

	isCategorySelected(categoryId: number): boolean {
		return this.supplementalModel.selectedSupportCategories.has(categoryId);
	}

	supportCategoryChanged(category: SupportCategory, selected: boolean): void {
		if (selected) {
			this.supplementalModel.selectedSupportCategories.set(category.id, category);
		}
		else {
			this.supplementalModel.selectedSupportCategories.delete(category.id);
		}
	}

	getSupportItems(supportCategoryId: number): SupportItem[] {
		return this._supportsCache.filter( item => item.supportCategoryId === supportCategoryId );
		// return this._supportsCache.categorySupports(supportCategoryId);
	}

	private cantDeleteCategory(categoryId: number): void {
		OverlayService.showError("Error", "Can't delete this category because there are already bills assigned to it");
	}

	public addServiceAgreement() {
		const newServiceAgreement = ServiceAgreement.blank();
		newServiceAgreement.client.id = this._client.id;
		newServiceAgreement.client.name = `${this._client.firstName} ${this._client.lastName}`;
		newServiceAgreement.planId = this._plan.id;
		newServiceAgreement.dateFrom = new Date(this._plan.startDate);
		newServiceAgreement.dateTo = new Date(this._plan.endDate);

		this._serviceAgreements.unshift( newServiceAgreement );
	}

	/**
	* Removes a service agreement from the list.
	*/
	public async deleteServiceAgreement(item: ServiceAgreement) {
		const idx = this._serviceAgreements.indexOf(item);
		if (idx >= 0) {

			OverlayService.show();
			try {
				if (!!item.id) {
					await this.planService.deleteServiceAgreement(item);
				}

				this._serviceAgreements.splice(idx, 1);
				OverlayService.hide();
			}
			catch (e) {
				console.log(e);
				OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "Unable to delete service agreement") );
			}

		}
	}

	confirmDeleteCategory(categoryId: number): void {

		OverlayService.showDialog("Confirm Delete", "Are you sure you want to delete this support category from the plan?", [{
			"text": "Cancel",
			"handler": () => { OverlayService.hide(); }
		}, {
			"text": "Delete",
			"handler": this.deleteCategory.bind(this, categoryId)
		}]);
	}

	private deleteCategory(categoryId: number): void {
		OverlayService.hide();

		const categoryIdx = this.model.supportCategories.findIndex( item => item.supportCategory.id === categoryId );
		if (categoryIdx >= 0) {
			const category = this.model.supportCategories[categoryIdx];
			if (category.servicesDelivered === undefined || category.servicesDelivered === 0) {
				this.model.supportCategories.splice(categoryIdx, 1);
				this.updatePlan();
			}
		}
	}

	confirmDeleteSupportItem(categoryId: number, item: SupportItem): void {
		OverlayService.showDialog("Confirm Delete", "Are you sure you want to delete this support item from the plan?", [{
			"text": "Cancel",
			"handler": () => { OverlayService.hide(); }
		}, {
			"text": "Delete",
			"handler": this.deleteSupportItem.bind(this, categoryId, item.supportItemNumber)
		}]);
	}

	private cantDeleteSupportItem(categoryId: number, supportId: string): void {
		OverlayService.showError("Error", "Can't delete this support item because there are already bills assigned to it");
	}

	private deleteSupportItem(categoryId: number, supportItemNumber: string): void {

		try {
			// Find the category that the support item belongs to
			const category = this.model.supportCategories.find( item => item.supportCategory.id === categoryId );
			assert(!!category);

			// Find the support item's index in the categories support item array
			const itemIdx = category.supportItems.findIndex( item => item.supportItemNumber === supportItemNumber );
			assert(itemIdx >= 0);

			// Splice the item out
			category.supportItems.splice(itemIdx, 1);

			OverlayService.hide();
		}
		catch (e) {
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, 'Unable to remove support item') );
			return
		}

		// Save
		this.savePlanCategories();
	}

	public invalidSupportItemBudget(category): boolean {
		const itemBudgets = Array.from(category.supportItems.values()).reduce( (acc: number, cur: PlanSupportItem) => {
			acc += cur.total || 0;
			return acc;
		}, 0);

		const categoryBudget = category.total || 0;

		return categoryBudget < itemBudgets;
	}

	// public saveCategoryChanges(): void {
	// 	this.savePlanCategories();
	// }

	protected updateErrors(): void {
		const plan = this.modelToPlan();
		this._planErrors = PlanErrorChecker.validate(plan, this._serviceAgreements, this.otherPlans);

		// Don't run checks on a new plan, since there's no category or provider information to validate
		// if (this.isNewPlan) {
		// 	this._planErrors = [];
		// 	return false;
		// }

		this._hasPlanError = this.visiblePlanErrors.length > 0;
	}

	get hasPlanError(): boolean {
		return this._hasPlanError;
	}

	// showProvidersDialog(planComponent: PlanSupportCategory|PlanSupportItem): void {
	// 	OverlayService.showTemplate(this.providerDialogTemplate, {"planComponent": planComponent, "allProviders": this._allProviders});
	// }


	/**
	* Returns all support items that are valid for a particular support category
	*/
	supportItems(category: PlanSupportCategory): SupportItem[] {
		return this._supportsCache.filter( item => item.supportCategoryId === category.supportCategory.id).sort( (a, b) => a.name.localeCompare(b.name) );
		// return this._supportsCache.categorySupports(category.supportCategory.id).sort( (a, b) => a.name.localeCompare(b.name) );
	}

	/**
	* Returns all plan support items included in the specified plan category
	*/
	selectedSupportItems(category: PlanSupportCategory): PlanSupportItem[] {
		return Array.from( category.supportItems.values() );
	}

	/**
	* Determines if a specified support item is currently selected.
	* Used to determine checkbox state in the list of support items for a category
	*/
	public supportItemSelected(supportItemNumber: string): boolean {
		return this.supplementalModel.category.supportItems.some( support => support.supportItemNumber === supportItemNumber );
	}

	public supportItemChanged(categoryId: number, supportItem: SupportItem, checked: boolean): void {
		const category = this.supplementalModel.category;
		const idx = category.supportItems.findIndex( support => support.supportItemNumber === supportItem.supportItemNumber );

		if (idx >= 0) {
			category.supportItems.splice(idx, 1);
		}
		else {
			category.supportItems.push({
				"supportItemNumber": supportItem.supportItemNumber,
				"supportItem": SupportItem.clone(supportItem),
				"budget": undefined,
				"bookingCode": undefined
			});
		}
	}

	// showEditSupportItemDialog(item: PlanSupportItem): void {
	// 	let clone = PlanSupportItem.clone(item);
	// 	OverlayService.showTemplate(this.editSupportItemTemplate, clone);
	// }

	getMeasurementUnit: (unit: UnitOfMeasure) => string = UnitOfMeasure.toString;

	regionName: (region: Region) => string = RegionUtils.toString;

	saveSupportItem(item: PlanSupportItem): void {
		OverlayService.hide();

		const clone = PlanSupportItem.clone(item);
		const category = this.model.supportCategories.find( category => category.supportCategory.id === clone.supportItem.supportCategoryId );
		if (!category) {
			throw new Error("Can't find support category");
		}

		const idx = category.supportItems.findIndex( supportItem => item.supportItemNumber === supportItem.supportItemNumber );
		category.supportItems.splice(idx, 1, item);
		// let category = this.model.supportCategories.get(clone.supportItem.supportCategoryId);
		// category.supportItems.set(clone.supportItem.supportItemNumber, clone);
		this.updateErrors();
		this.setPlanChanged();
		this.savePlanCategories();
	}

	providersChanged(): void {
		this.setPlanChanged();
		this.savePlanCategories();
	}


	protected showAttachmentDialog(): void {
		const targets = [
			AttachmentTargetUtils.target(this.plan.client, AttachmentTargetType.client),
			AttachmentTargetUtils.target(this.plan.id, AttachmentTargetType.plan)
		];

		const files = this.plan.attachments.map( AttachedFile.clone );

		OverlayService.showTemplate(this.attachmentDialog, {"targets": targets, "files": files});
	}

	protected async saveAttachments($event) {
		if ($event.fileManager && $event.targets && $event.targets.length > 0) {
			OverlayService.show(`Saving${Utils.ellipsis}`);
			try {
				const result = await this.attachmentsService.saveAttachments($event.targets, $event.fileManager);

				result.savedFiles.forEach(file => {
					this.plan.attachments.push(file);
				});

				result.deletedFiles.forEach(fileId => {
					const idx = this.plan.attachments.findIndex( item => item.id === fileId );
					if (idx >= 0) {
						this.plan.attachments.splice(idx, 1);
					}
				});

				this.attachmentsTable.sourceData = StaticDataSource.from(this.plan.attachments);
				OverlayService.hide();
			}
			catch (e) {
				console.log(e);
				OverlayService.hide();
				OverlayService.showError("Error", "Unable to save attachment");
			}
		}
	}

	public planLabel(plan: Plan): string {
		const formatString = "Do MMM YYYY";
		const startDate = moment(plan.startDate).startOf('day').format(formatString);
		const endDate = moment(plan.endDate).startOf('day').format(formatString);
		const status = PlanStatus.toString(plan.status);
		return `${startDate} - ${endDate} (${status})`;
	}

	public showMergePlanDialog() {
		this.mergePlan = undefined;
		OverlayService.showTemplate(this.mergePlanDialog);
	}

	private mergePlanVisible(): boolean {
		return this._plan !== undefined && this._plan.id !== undefined && this._allPlans.length > 1;
	}

	public get otherPlans(): Plan[] {
		return this._allPlans.filter( plan => plan !== this._plan );
	}

	public confirmMerge() {
		this.confirmMergeChecked = false;
		OverlayService.showTemplate(this.confirmMergeDialog);
	}

	public doMerge() {
		OverlayService.hide();
		OverlayService.show("Merging" + Utils.ellipsis);
		this.planService.mergePlans(this.mergePlan.id, this._plan.id, this._plan.client).then( () => {

			return this.loadPlan(this._plan.id);
		}).then( () => {

			this.refreshModel();
			OverlayService.hide();

		}).catch( err => {
			OverlayService.hide();
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(err, "Unable to merge plans"));
		});
	}

	public get defaultAttachmentType(): AttachmentType {
		return AttachmentType.ndis_plan;
	}

	private showCategoryForPlan(categoryNumber): boolean {
		if(!this.model.pace && categoryNumber > 15)
			return false;
		else
			return true;
	}

	public get availableCategories(): SupportCategory[] {
		return this._supportCategories.filter(category => this.showCategoryForPlan(category.id)).sort( (a, b) => a.id - b.id );
		// const currentCategoryNumbers = this._plan.supportCategories.map( category => category.supportCategory.id );
		// return this._supportCategories
		// 	.filter( category => !currentCategoryNumbers.includes(category.id) )
		// 	.sort( (a, b) => a.id - b.id );
	}

	public categoryBudgetAlreadyExists(categoryNumber: number): boolean {
		const categories: number[] = Array.from( this.model.supportCategories.values() ).map( (item: PlanSupportCategory) => item.supportCategory.id );
		return categories.includes(categoryNumber);
	}

	public addingCategory: boolean = false;
	public addItemCategory: number = undefined;

	public addCategory(newCategory: SupportCategory) {
		if (!newCategory) {
			return;
		}

		const category: PlanSupportCategory = {
			"supportCategory": SupportCategory.clone(newCategory),
			"total": undefined,
			"categoryBudget": PlanBudget.blank(BudgetType.category),
			"bookingCode": undefined,
			"supportItems": []
		};

		this.model.supportCategories.push( category );
		this.model.supportCategories.sort( (a, b) => a.supportCategory.id - b.supportCategory.id );
		this.addingCategory = false;
	}

	public addSupportItem(supportItem: SupportItem) {
		if (!supportItem) {
			return;
		}

		const newItem: PlanSupportItem = {
			"supportItemNumber": supportItem.supportItemNumber,
			"supportItem": supportItem,
			"total": undefined,
			"itemBudget": PlanBudget.blank(BudgetType.supportItem),
			"bookingCode": undefined,
			"specifiedItem": false,
			"quantity": undefined
		};

		const planCategory = this.model.supportCategories.find( category => category.supportCategory.id === this.addItemCategory );
		if (!planCategory) {
			return;
		}

		planCategory.supportItems.push(newItem);
		planCategory.supportItems.sort( (a, b) => a.supportItemNumber.localeCompare(b.supportItemNumber) );
		this.addItemCategory = undefined;
	}

	public onPaceChanged() {
		this._categorySupportsMap.forEach((categorySupports, categoryNumber, self) => {
			self.set(categoryNumber, this.sortedCategorySupports(categoryNumber));
		});
	}

	private async sortedCategorySupports(categoryNumber: number): Promise<SupportItem[]> {
		const plan = this.modelToPlan();
		assert(!!plan.startDate);
		const supports = await this.supportsService.getSupportsFor(plan.region, plan.startDate, undefined, categoryNumber, plan.pace);
		return supports.sort( (a, b) => a.supportItemNumber.localeCompare(b.supportItemNumber) );
	}

	public supportItemAlreadySelected(category: PlanSupportCategory, supportItemNumber: string): boolean {
		const supports = this.selectedSupportItems(category);
		return supports.map( item => item.supportItemNumber ).includes(supportItemNumber);
	}

	public get categorySupports(): Promise<SupportItem[]> {
		if (this.addItemCategory === undefined) {
			return Promise.resolve([]);
		}

		if (!this._categorySupportsMap.has(this.addItemCategory)) {
			// this._categorySupportsMap.set(this.addItemCategory, this.supportsService.getSupportsFor(this.model.region, this.model.dateFrom, undefined, this.addItemCategory));
			this._categorySupportsMap.set(this.addItemCategory, this.sortedCategorySupports(this.addItemCategory));
		}

		return this._categorySupportsMap.get(this.addItemCategory);
	}

	
	public attachmentTypeName: (AttachmentType) => string = AttachmentType.toString;
	public filter: Set<AttachmentType> = new Set<AttachmentType>([
		AttachmentType.statement,
		AttachmentType.quote,
		AttachmentType.ndsp_doc,
		AttachmentType.rAndN,
		AttachmentType.support_letter,
		AttachmentType.ndis_plan,
		AttachmentType.sa,
		AttachmentType.other
	]);

	downloadDocument(document: AttachedFile) {
		FileManager.downloadFile(this.attachmentsService, document);
	}

}

