import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlanAllocationService, PlanCategoryAllocation } from '@services/planallocation.service';
import { PrivateComponent } from "@classes/private.component";
import { User, UserType } from "@classes/user";
import { Plan, PlanSupportCategory } from "@classes/plans";
import { PlanService } from "@services/plan.service";
import { PaginatedTable, TableColumnHeading, SortType, Alignment, StaticDataSource } from "@classes/tables";
import { MenuBuilder } from "@services/navmenu.service";
import { TransactionService, ServiceDelivered } from "@services/transaction.service";
import { colourList } from "@classes/colours";
import { Chart, DoughnutController, Tooltip, ArcElement } from 'chart.js';

interface PathParams {
	clientId?: string;
	planId: string;
	categoryId: number;
}

@Component({
	"styleUrls": ["./categorytransactions.component.scss"],
	"templateUrl": "./categorytransactions.component.html"
})
export class CategoryTransactionsComponent extends PrivateComponent implements OnInit, AfterViewInit {

	private static pluginRegistered: boolean = false;

	@ViewChild('chart')
	private chartRef: ElementRef;

	private viewInitDone: boolean = false;
	private chart: any;
	private defaultColours: any[] = [];

	private _isSelf: boolean = false;
	private _masqueradeUser: User = undefined;

	private _clientId: string = undefined;
	private _dataLoaded: boolean = false;
	private _plan: Plan = undefined;

	private _category: PlanSupportCategory = undefined;
	private _planCategoryAllocation: PlanCategoryAllocation = undefined;

	private _intl: Intl.NumberFormat = new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' });

	get hasCategoryInfo(): boolean {
		return !!this._planCategoryAllocation;
	}

	get categoryTotal(): string {
		if (!this._category) {
			return "";
		}

		return this._intl.format(this._category.categoryBudget.total);
	}

	get fundsPaid(): string {
		if (!this._category) {
			return "";
		}

		return this._intl.format(this._category.categoryBudget.paid + this._category.categoryBudget.unknown);
	}

	get fundsPending(): string {
		if (!this._category) {
			return "";
		}

		const budget = this._category.categoryBudget;
		const pending = this.isAdmin
		                ? budget.pending + budget.draft
		                : 0;

		return this._intl.format(pending);
	}

	get fundsRemaining(): string {
		if (!this._category) {
			return "";
		}

		const budget = this._category.categoryBudget;
		const remaining = this.isAdmin
		                ? budget.total - budget.paid - budget.pending - budget.draft - budget.unknown
		                : budget.total - budget.paid - budget.unknown;

		return this._intl.format(remaining);
	}

	get categoryName(): string {
		if (this._category) {
			return this._category.supportCategory.name;
		}

		return "";
	}

	get dataLoaded(): boolean {
		return this._dataLoaded;
	}

	private readonly tableHeadings: TableColumnHeading[] = [
		{"propName": "date", "displayName": "Service Date", "sortType": SortType.date },
		{"propName": "providerName", "displayName": "Account", "sortType": SortType.text },
		{"propName": "invoiceNumber", "displayName": "Bill #", "sortType": SortType.text },
		{"propName": "claimReference", "displayName": "Claim Ref", "sortType": SortType.text },
		{"propName": "supportItemName", "displayName": "Support", "sortType": SortType.text },
		{"propName": "total", "displayName": "Amount", "sortType": SortType.numeric, alignment: Alignment.right },
		{"propName": "paid", "displayName": "Paid", "sortType": SortType.numeric, alignment: Alignment.center }
	];


	table: PaginatedTable<ServiceDelivered> = new PaginatedTable('categoryTransactions', this.tableHeadings);

	get masqueradeUser(): User { return this._masqueradeUser; }
	get masqueradeUserId(): string { return this._masqueradeUser ? this._masqueradeUser.id : ""; }
	get isSelf(): boolean { return this._isSelf; }
	get isAdmin(): boolean { return this.user.accountType === UserType.Admin; }
	get masqueradeUserName(): string { return this._masqueradeUser ? `${this._masqueradeUser.firstName} ${this._masqueradeUser.lastName}` : ''; }

	constructor(
		private route: ActivatedRoute,
		private planService: PlanService,
		private transactionService: TransactionService,
		private planAllocationService: PlanAllocationService) {

		super();
	}

	private loadTransactions(clientId, planId, supportCategoryId) {
		if (!this.user) {
			return Promise.resolve();
		}

		return this.transactionService.getServicesDelivered(clientId, planId, supportCategoryId).then( data => {
			this.table.sourceData = StaticDataSource.from(data);
			console.log(this.table.displayData);
			return Promise.resolve();
		});
	}

	private loadClient() {
		if (!this.user) {
			return Promise.resolve();
		}

		return this.userService.loadUser(this._clientId).then( user => {
			this._masqueradeUser = user;
			return Promise.resolve();
		});
	}

	protected getColour(idx: number): string {
		if (!this.defaultColours || !this.defaultColours[idx]) {
			return "transparent";
		}

		return this.defaultColours[idx].html;
	}

	private loadPlan(userId: string, planId: string): Promise<void> {
		return this.planService.listPlans(userId).then( plans => {
			this._plan = plans.find( plan => plan.id === planId );

			if (!this._plan) {
				return Promise.reject("Unable to find plan");
			}

			return Promise.resolve();
		});
	}


	ngOnInit() {
		super.ngOnInit();

		this.route.params.subscribe( params => {

			if (!this.user) {
				return;
			}

			const routeParams: PathParams = {
				"clientId": params.clientId,
				"planId": params.planId,
				"categoryId": Number(params.categoryId)
			};

			this._clientId = routeParams.clientId || this.user.id;
			this._isSelf = this._clientId === this.user.id;

			if (this.user.accountType === UserType.Admin) {
				if (!this.hasPermission('Account Details', 'View Other Account Details')) {
					this.router.navigate(["/accessdenied"]);
					return;
				}
			}

			let promises = [];

			promises.push( this.loadPlan(this._clientId, routeParams.planId) );
			promises.push( this.loadTransactions(this._clientId, routeParams.planId, routeParams.categoryId) );

			if (!this.isSelf) {
				promises.push( this.loadClient() );
			}

			Promise.all(promises).then( () => {
				this.buildMenu();
				this._category = this._plan.supportCategories.find( category => category.supportCategory.id === routeParams.categoryId );
				this._dataLoaded = true;

				if (this.viewInitDone) {
					this.queueChartCreation();
				}
			});

		});

		this.buildMenu();
	}

	/**
	* Queues the creation of the chart. Needs to be placed at the end of the call stack so that Angular has chance
	* to render the canvas element to which our "chartRef" property points.
	*/
	private queueChartCreation() {
		window.setTimeout( () => { this.createChart(); }, 0);
	}

	private buildMenu() {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.done();
	}

	private byteToHex(value: number): string {
		let result = value.toString(16);
		return `0${result}`.slice(-2);
	}

	private createChart() {
		Chart.register(DoughnutController, Tooltip, ArcElement);

		// const dataPoints = [this._category.currentSpend, this._category.budget - this._category.currentSpend];
		const dataPoints = [
			this._category.categoryBudget.paid + this._category.categoryBudget.unknown,
			this.fundsPending,
			this.fundsRemaining
		];
		const chartLabels = ['Paid', 'Pending', 'Remaining'];
		const budget = this._category.categoryBudget.total;

		this.defaultColours = colourList(dataPoints.length).map( colour => {
			return {
				"rgb": `rgb(${colour.red}, ${colour.green}, ${colour.blue})`,
				"html": `#${this.byteToHex(colour.red)}${this.byteToHex(colour.green)}${this.byteToHex(colour.blue)}`
			};
		});


		if (this.chartRef !== undefined) {

			const allocationDataset = {
				"data": dataPoints,
				"backgroundColor": dataPoints.map( (item, idx) => {
					return this.defaultColours[idx].rgb;
				}),
				"borderColor": dataPoints.map( () => "#ccc" )
			};

			const formatter = new Intl.NumberFormat('en-AU', {
				"style": "currency",
				"currency": "AUD",
				"minimumFractionDigits": 2,
			});

			const legend = {
				display: false
			};

			const getOrCreateTooltip = (chart) => {
				let tooltipEl = chart.canvas.parentNode.querySelector('div');
			  
				if (!tooltipEl) {
				  tooltipEl = document.createElement('div');
				  tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
				  tooltipEl.style.borderRadius = '3px';
				  tooltipEl.style.color = 'white';
				  tooltipEl.style.opacity = 1;
				  tooltipEl.style.pointerEvents = 'none';
				  tooltipEl.style.position = 'absolute';
				  tooltipEl.style.transform = 'translate(-50%, 0)';
				  tooltipEl.style.transition = 'all .1s ease';
				  tooltipEl.style.whiteSpace = 'nowrap';
			  
				  const table = document.createElement('table');
				  table.style.margin = '0px';
			  
				  tooltipEl.appendChild(table);
				  chart.canvas.parentNode.appendChild(tooltipEl);
				}
			  
				return tooltipEl;
			  };
			  
			  const externalTooltipHandler = (context) => {
				// Tooltip Element
				const {chart, tooltip} = context;
				const tooltipEl = getOrCreateTooltip(chart);
			  
				// Hide if no tooltip
				if (tooltip.opacity === 0) {
				  tooltipEl.style.opacity = 0;
				  return;
				}
			  
				// Set Text
				if (tooltip.body) {
				  const titleLines = tooltip.title || [];
				  const bodyLines = tooltip.body.map(b => b.lines);
			  
				  const tableHead = document.createElement('thead');
			  
				  titleLines.forEach(title => {
					const tr = document.createElement('tr');
					tr.style.borderWidth = '0';
			  
					const th = document.createElement('th');
					th.style.borderWidth = '0';
					const text = document.createTextNode(title);
			  
					th.appendChild(text);
					tr.appendChild(th);
					tableHead.appendChild(tr);
				  });
			  
				  const tableBody = document.createElement('tbody');
				  bodyLines.forEach((body, i) => {
					const colors = tooltip.labelColors[i];
			  
					const span = document.createElement('span');
					span.style.background = colors.backgroundColor;
					span.style.borderColor = colors.borderColor;
					span.style.borderWidth = '2px';
					span.style.marginRight = '10px';
					span.style.height = '10px';
					span.style.width = '10px';
					span.style.display = 'inline-block';
			  
					const tr = document.createElement('tr');
					tr.style.backgroundColor = 'inherit';
					tr.style.borderWidth = '0';
			  
					const td = document.createElement('td');
					td.style.borderWidth = '0';
			  
					const text = document.createTextNode(body);
			  
					td.appendChild(span);
					td.appendChild(text);
					tr.appendChild(td);
					tableBody.appendChild(tr);
				  });
			  
				  const tableRoot = tooltipEl.querySelector('table');
			  
				  // Remove old children
				  while (tableRoot.firstChild) {
					tableRoot.firstChild.remove();
				  }
			  
				  // Add new children
				  tableRoot.appendChild(tableHead);
				  tableRoot.appendChild(tableBody);
				}
			  
				const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
			  
				// Display, position, and set styles for font
				tooltipEl.style.opacity = 1;
				tooltipEl.style.position = 'absolute';
				tooltipEl.style.left = positionX + tooltip.caretX + 'px';
				tooltipEl.style.top = positionY + tooltip.caretY + 'px';
				tooltipEl.style.font = tooltip.options.bodyFont.string;
				tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
			  };

			const tooltip = {
				// Disable the on-canvas tooltip
                enabled: false,
                external: externalTooltipHandler
			}

			const plugin = {
				id: 'donutCenterText',
				beforeDraw: (chart, args, options) => {

					if (options.elements.center) {
						const datasetCount = chart.getVisibleDatasetCount();
						const chartMeta = chart.getDatasetMeta(datasetCount-1).data.at(0);
						const innerRadius: number = Number(chartMeta.getProps(['innerRadius']));

						//Get ctx from string
						let ctx = chart.ctx;

						//Get options from the center object in options
						let centerConfig = options.elements.center;
						let fontStyle = centerConfig.fontStyle || 'Arial';
						let txt = centerConfig.text;
						let color = centerConfig.color || '#000';
						let sidePadding = centerConfig.sidePadding || 20;
						let sidePaddingCalculated = (sidePadding/100) * (innerRadius * 2)
						//Start with a base font of 30px
						ctx.font = "15px " + fontStyle;

						//Get the width of the string and also the width of the element minus 10 to give it 5px side padding
						let stringWidth = ctx.measureText(txt).width;
						let elementWidth = (innerRadius * 2) - sidePaddingCalculated;

						// Find out how much the font can grow in width.
						let widthRatio = elementWidth / stringWidth;
						let newFontSize = Math.floor(30 * widthRatio);
						let elementHeight = (innerRadius * 2);

						// Pick a new font size so it will not be larger than the height of label.
						let fontSizeToUse = Math.min(newFontSize, elementHeight);

						//Set font settings to draw it correctly.
						ctx.textAlign = 'center';
						ctx.textBaseline = 'middle';
						let centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
						let centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
						ctx.font = fontSizeToUse+"px " + fontStyle;
						ctx.fillStyle = color;

						//Draw text in center
						ctx.fillText(txt, centerX, centerY);
					}
				}
			};

			const chartOptions = {
				plugins: {
					legend: legend,
					tooltip: tooltip,
					donutCenterText: {
						elements: {
							center: {
								text: "Total: " + formatter.format(budget)
							}
						}
					}
				}
			};

			CategoryTransactionsComponent.pluginRegistered = true;

			if (this.chart) {
				this.chart.data.labels.pop();
				this.chart.data.datasets.pop();
				this.chart.data.datasets = [];
				this.chart.data.labels = chartLabels;
				this.chart.options = chartOptions;
				this.chart.update();
			}
			else {
				this.chart = new Chart(this.chartRef.nativeElement, {
					type: "doughnut",
					data: {
						labels: chartLabels,
						datasets: [allocationDataset]
					},
					options: chartOptions,
					plugins: [plugin]
				});
			}

		}
	}

	ngAfterViewInit() {
		this.viewInitDone = true;
		if (this._dataLoaded) {
			this.queueChartCreation();
		}
	}

}
