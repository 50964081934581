import { Injectable } from "@angular/core";
import { RestService, API } from "./rest.service";
import { XeroInvoice, XeroInvoiceUtils } from "@classes/xeroTypes";

/*
export class XeroFile {
	private static readonly propertyNames = ["ContactName", "EmailAddress", "POAddressLine1", "POAddressLine2", "POAddressLine3", "POAddressLine4", "POCity", "PORegion", "POPostalCode", "POCountry", "InvoiceNumber", "Reference", "InvoiceDate", "DueDate", "InventoryItemCode", "Description", "Quantity", "UnitAmount", "Discount", "AccountCode", "TaxType", "TrackingName1", "TrackingOption1", "TrackingName2", "TrackingOption2", "Currency", "BrandingTheme"];
	private static readonly requiredProperties = new Set<string>(["ContactName", "InvoiceNumber", "InvoiceDate", "DueDate", "Description", "Quantity", "UnitAmount", "AccountCode"]);
	private static readonly newline: string = "\r\n";

	readonly lineItems: XeroEntry[] = [];


	constructor(src: any) {
		this.lineItems = src;
	}

	private header(): string {
		return XeroFile.propertyNames.map( item => {
			return XeroFile.requiredProperties.has(item) ? `*${item}` : item;
		}).join(",");
	}

	private formatLineItem(item): string {
		return XeroFile.propertyNames.map( propName => {
			return item[propName] || "";
		}).join(",");
	}

	get csvFile(): string {
		let result = this.header() + XeroFile.newline;
		this.lineItems.forEach( lineItem => {
			result += this.formatLineItem(lineItem) + XeroFile.newline;
		});

		return result;
	}
}
*/

@Injectable({ "providedIn": "root" })
export class XeroService {

	constructor(private restService: RestService) {}

	public loadInvoices(): Promise<XeroInvoice[]> {
		return this.restService.get(API.xero, `summary`).then( data => {

			return data.map( XeroInvoiceUtils.parse );

		});
	}

	public providerSyncFile(): Promise<string> {
		return this.restService.get(API.xero, `providers`).then( response => {
			return Promise.resolve(response.file);
		});
	}

	public providerSyncConfirmation(): Promise<void> {
		return this.restService.post(API.xero, "sync/providers", {}).then( () => {
			return Promise.resolve();
		});
	}

	public xeroFile(): Promise<any> {
		return this.restService.get(API.xero, `invoices`);
	}

	public confirmImport(invoices: string[]): Promise<void> {
		const postData = {
			"invoices": invoices
		};

		return this.restService.post(API.xero, `sync/invoices`, postData).then( result => {
			return result.success ? Promise.resolve() : Promise.reject("An error occurred");
		});
	}
}
