import { Injectable } from '@angular/core';
import { RestService, API } from '@services/rest.service';
import { Address, AddressClass } from "@classes/address";
import { AccountStatus, ExitReason } from "@classes/clientStatus";


export interface ClientReportData {
	clientName: string;
	ndisNumber: string;
	email: string;
	phone: string;
	address: Address;
	state: string;
	status: AccountStatus;
	statusDate: Date;
	exitReason: ExitReason;
}

namespace ClientReportData {
	export function parse(src: any): ClientReportData {
		return {
			"clientName": src.clientName,
			"ndisNumber": src.ndisNumber,
			"email": src.email,
			"phone": src.phone,
			"address": AddressClass.parseObject(src.address),
			"state": src.state,
			"status": AccountStatus.parse(src.status),
			"statusDate": src.statusDate,
			"exitReason": ExitReason.parse(src.exitReason)
		};
	}
}

export interface ClientReportModel {
	state: string;
	status: AccountStatus;
	postcode: string;
}

export namespace ClientReportModel {
	export function toJSON(src: ClientReportModel): any {
		return {
			"state": src.state,
			"status": AccountStatus.toPostgresEnum(src.status),
			"postcode": src.postcode
		};
	}

	export function blank(): ClientReportModel {
		return {
			"state": undefined,
			"status": undefined,
			"postcode": undefined
		};
	}
}

@Injectable({ "providedIn": 'root' })
export class ClientReportService {
	constructor(private restService: RestService) {}

	public clientReport(filter: ClientReportModel): Promise<ClientReportData[]> {
		return this.restService.post(API.reports, "clientreport", ClientReportModel.toJSON(filter)).then( result => {
			return Promise.resolve(result.report.map( ClientReportData.parse ) );
		});
	}
}
