/**
* Simple, un-keyed wait queue
*/
export class SimpleWaitQueue<T> {
	private _queue: any[] = [];

	private add(resolve: any, reject: any): void {
		this._queue.push({"resolve": resolve, "reject": reject});
	}

	private resolve(result: T): void {
		while (this._queue.length) {
			const queuedRequest = this._queue.pop();
			queuedRequest.resolve.call(null, result);
		}
	}

	private reject(err: any): void {
		while (this._queue.length) {
			const queuedRequest = this._queue.pop();
			queuedRequest.reject.call(null, err);
		}
	}

	public enqueue(sourceFunc: () => Promise<T>): Promise<T> {

		return new Promise<T>( (resolve, reject) => {

			this.add(resolve, reject);

			if (this._queue.length === 1) {
				sourceFunc().then( result => {

					this.resolve(result)

				}).catch( err => {

					this.reject(err);

				});
			}
		});
	}
}