import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { UserType } from "@classes/user";
import { Table, TableColumnHeading, SortType, Alignment, StaticDataSource } from "@classes/tables";
import { MenuBuilder } from "@services/navmenu.service";
import { XeroService } from "@services/xero.service";
import { OverlayService} from "@services/overlay.service";
import { ErrorUtils } from "@classes/errors";
import { XeroInvoice } from "@classes/xeroTypes";
import { saveAs } from 'file-saver';

@Component({
	"styleUrls": ["./xero.component.scss"],
	"templateUrl": "./xero.component.html"
})
export class XeroComponent extends PrivateComponent implements OnInit {

	@ViewChild('syncDialog')
	private syncDialog: TemplateRef<any>;

	@ViewChild('xeroDialog')
	private xeroDialog: TemplateRef<any>;

	protected confirmSynced: boolean = false;
	protected confirmImported: boolean = false;

	protected syncFileDownloaded: boolean = false;
	protected xeroFileDownloaded: boolean = false;

	private invoiceList: string[] = [];

	private readonly tableHeadings: TableColumnHeading[] = [
		{"propName": undefined, "displayName": "", "sortType": SortType.none },
		{"propName": "extract", "displayName": "Extract #", "sortType": SortType.numeric },
		{"propName": "invoiceNumber", "displayName": "Bill #", "sortType": SortType.text },
		{"propName": "payee", "displayName": "Payee", "sortType": SortType.boolean },
		{"propName": "clientName", "displayName": "Client", "sortType": SortType.name },
		{"propName": "date", "displayName": "Bill Date", "sortType": SortType.date },
		{"propName": "total", "displayName": "Total", "sortType": SortType.numeric, "alignment": Alignment.right },
		{"propName": "lineItemCount", "displayName": "# Line Items", "sortType": SortType.numeric, "alignment": Alignment.center }
	];

	private readonly submissionTableName: string = 'xero.submissionCandidates';
	private readonly syncTableName: string = 'xero.syncRequired';
	private readonly missingBankDetailsTableName: string = 'xero.bankAccountRequired';

	public dataLoaded: boolean = false;

	protected submissionTable: Table<XeroInvoice> = new Table(this.submissionTableName, this.tableHeadings);
	protected syncTable: Table<XeroInvoice> = new Table(this.syncTableName, this.tableHeadings);
	protected bankTable: Table<XeroInvoice> = new Table(this.missingBankDetailsTableName, this.tableHeadings);

	/**
	* List of invoices that can be sent to Xero
	*/
	private submissionItems: XeroInvoice[] = [];

	/**
	* List of invoices that have been reconciled successfully, but the provider data has been updated and needs to be synced to Xero first.
	*/
	private syncItems: XeroInvoice[] = [];

	/**
	* List of invoices that have been reconciled successfully, but we're missing bank details.
	*/
	private missingBankDetailsItems: XeroInvoice[] = [];

	private static readonly requireBankDetails: boolean = false;

	constructor(private xeroService: XeroService) {
		super();
		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Proda', 'Xero export')
	}

	private buildMenu(): void {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.done();
	}

	ngOnInit() {
		super.ngOnInit()

		if (this.user) {
			this.buildMenu();
			this.loadData();
		}
	}

	private loadData() {
		OverlayService.show();
		this.xeroService.loadInvoices().then( data => {

			if (XeroComponent.requireBankDetails) {
				this.submissionItems = data.filter( item => item.hasBankDetails && !item.syncRequired );
				this.missingBankDetailsItems = data.filter( item => !item.hasBankDetails );
				this.syncItems = data.filter( item => item.hasBankDetails && item.syncRequired );
			}
			else {
				this.submissionItems = data.filter( item => !item.syncRequired );
				this.missingBankDetailsItems = [];
				this.syncItems = data.filter( item => item.syncRequired );
			}

			this.submissionTable.sourceData = StaticDataSource.from(this.submissionItems);
			this.bankTable.sourceData       = StaticDataSource.from(this.missingBankDetailsItems);
			this.syncTable.sourceData       = StaticDataSource.from(this.syncItems);

			this.dataLoaded = true;
			OverlayService.hide();

		}).catch( e => {

			this.dataLoaded = true;
			OverlayService.hide();
			console.log(e);
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "An unknown error occurred"));

		});
	}

	protected providerSync() {
		this.confirmSynced = false;
		this.syncFileDownloaded = false; // RRP: confirm this is correct. vMHB does not agree
		OverlayService.showTemplate(this.syncDialog);
	}

	protected fetchProviders() {
		OverlayService.show();
		this.xeroService.providerSyncFile().then( data => {

			const file = new Blob([data], { "type": "text/plain;charset=utf-8" });
			saveAs(file, "providers.csv");

			this.syncFileDownloaded = true;
			OverlayService.showTemplate(this.syncDialog);

		}).catch( e => {

			OverlayService.hide();
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "An unknown error occurred"));
			console.log(e);

		});
	}

	protected confirmSync() {
		OverlayService.show();
		this.xeroService.providerSyncConfirmation().then( () => {

			OverlayService.hide();
			this.dataLoaded = false;
			this.loadData();

		}).catch( e => {

			OverlayService.hide();
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "An unknown error occurred"));
			console.log(e);

		});
	}

	protected closeDialog(): void {
		OverlayService.hide();
	}

	protected showBillDownloadDialog() {
		this.confirmImported = false;
		this.xeroFileDownloaded = false;
		OverlayService.showTemplate(this.xeroDialog);
	}

	protected fetchBills() {
		OverlayService.show();
		this.invoiceList = [];
		this.xeroService.xeroFile().then( data => {

			this.invoiceList = data.invoices;
			const file = new Blob([data.file], { "type": "text/plain;charset=utf-8" });
			saveAs(file, "xero.csv");

			this.xeroFileDownloaded = this.invoiceList.length > 0;
			OverlayService.showTemplate(this.xeroDialog);

		}).catch( e => {

			OverlayService.hide();
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "An unknown error occurred"));
			console.log(e);

		});
	}

	protected confirmImport() {
		OverlayService.show();
		this.xeroService.confirmImport(this.invoiceList).then( () => {

			OverlayService.hide();
			this.dataLoaded = false;
			this.xeroFileDownloaded = false;
			this.invoiceList = [];
			this.loadData();

		}).catch( e => {

			OverlayService.hide();
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "An unknown error occurred"));
			console.log(e);

		});
	}
}
