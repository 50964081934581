import { Injectable } from '@angular/core';
import { RestService, API } from './rest.service';
import { AttachedFile } from '@classes/files';
import { Cache } from "@classes/cache";
import { FileValidator } from "@classes/files";
import { InvoiceStatus } from '@classes/invoices';

export enum DataImportType {
	bill
};

export interface ImportResult {
	dataImportId: string;
	invoiceIds: string[];
}

class DataImportUtils {
	private static readonly dataImportTypeNames = new Map<DataImportType, string>([
		[DataImportType.bill, "bill"]
	]);

	public static apiName(dataImportType: DataImportType): string {
		return DataImportUtils.dataImportTypeNames.get(dataImportType);
	}
}

abstract class DataImportFileValidator extends FileValidator {
	//abstract get claimReferenceIdx(): number;
	abstract get fileType(): string;
}

export interface Admin {
	id: string;
	name: string;
}

export interface DataImport {
	id: string;
	importedDate: Date;
	importedBy: string;
	importedByName: string;
	filename: string;
	count: number;
	dataImportType: DataImportType;
	locked: boolean;
	fileContent: string; // the file content (including header row)
	bills: DataImportInvoiceHeader[];
}

export interface DataImportInvoiceHeader {
	id: string;
	clientId: string;
	clientName: string;
	providerId: string;
	providerName: string;
	comment: string;
	gst?: number;
	invoiceDate: Date;
	invoiceNumber: string;
	status: InvoiceStatus;
	total: number;
	reimbursement: boolean;
}

export interface DataImportHistory extends Admin {
	// id: string;   // The id of the history item - inherited from Admin interface
	// name: string; // The name of the user that created the item - inherited from Admin interface
	user: string;    // The id of the user that created the item
	date: Date;      // The date the item was created
	comment: string; // The notes/comment attached to the item
}

@Injectable({ providedIn: 'root' })
export class DataImportService {

	private _dataImports = new Cache<DataImport>();
	private _dataImportInvoiceHeaders = new Map<string, Cache<DataImportInvoiceHeader>>();
	private _dataImportHistory = new Map<string, Cache<DataImportHistory>>();

	constructor(private restService: RestService) {}

	public clearCache(): void {
		this._dataImports.invalidate();
		this._dataImportInvoiceHeaders.forEach( cache => {
			cache.invalidate();
		});
		this._dataImportHistory.forEach( cache => {
			cache.invalidate();
		});
	}

	private getDataImportInvoiceHeaderCache(fileId): Cache<DataImportInvoiceHeader> {
		if (!this._dataImportInvoiceHeaders.has(fileId)) {
			this._dataImportInvoiceHeaders.set(fileId, new Cache<DataImportInvoiceHeader>());
		}

		return this._dataImportInvoiceHeaders.get(fileId);
	}

	private getDataImportHistoryCache(dataImportInvoiceId): Cache<DataImportHistory> {
		if (!this._dataImportHistory.has(dataImportInvoiceId)) {
			this._dataImportHistory.set(dataImportInvoiceId, new Cache<DataImportHistory>());
		}

		return this._dataImportHistory.get(dataImportInvoiceId);
	}

	public getAdmins(): Promise<Admin[]> {
		return this.restService.get(API.admin, "users/admins");
	}

	public saveDataImportUpload(file: AttachedFile, date: Date, userId: string): Promise<string> {

		return file.textContent.then( content => {

			const postData = {
				"filename": file.name,
				"content": content,
				"date": date,
				"userId": userId
			};

			return this.restService.post(API.admin, "dataimport", postData).then( result => {
				this._dataImports.invalidate();
				if (result.success && result.dataImportId) {
					return Promise.resolve(result.dataImportId);
				}
				else {
					return Promise.reject("Unknown response");
				}
			});
		});
	}

	public loadFiles(offset: number = 0, forceReload: boolean = false): Promise<DataImport[]> {

		if (forceReload) {
			this._dataImports.invalidate();
		}

		if (this._dataImports.valid && offset === 0) {
			return Promise.resolve(this._dataImports.items);
		}

		return this.restService.post(API.admin, "dataimports", {"offset": offset}).then( result => {

			const currentSet = (this._dataImports.valid) ? this._dataImports.items : [];
			const newItems = result.map( item => {
				return {
					"id": item.id,
					"importedDate": new Date(item.importedDate),
					"importedBy": item.importedBy,
					"importedByName": item.importedByName,
					"filename": item.filename,
					"count": item.count,
					"dataImportType": item.dataImportType,
					"locked": item.locked,
					"fileContent": item.fileContent
				};
			});

			this._dataImports.items = currentSet.concat(newItems);

			return newItems;
		});
	}

	public getDataImport(dataImportId: string): Promise<DataImport> {
		if (this._dataImports.valid) {
			const result = this._dataImports.items.find( item => item.id === dataImportId );
			if (result) {
				return Promise.resolve(result);
			}
		}

		return this.loadFiles(0, true).then( () => {
			const result = this._dataImports.items.find( item => item.id === dataImportId );
			return Promise.resolve(result);
		});
	}

	public getDataImportInvoiceHeader(dataImportId: string, dataImportInvoiceId: string): Promise<DataImportInvoiceHeader> {
		return this.loadDataImportFile(dataImportId).then( items => {

			const result = items.find( item => item.id === dataImportInvoiceId );
			return result ? Promise.resolve(result) : Promise.reject(undefined);

		});
	}

	private calcDiscrepancy(amount: number): number {
		const oneCent = 0.01;

		if (amount === null) {
			return null;
		}

		if (Math.abs(amount) < oneCent) {
			return null;
		}

		return amount;
	}

	public loadDataImportFile(dataImportId: string, forceReload?: boolean): Promise<DataImportInvoiceHeader[]> {
		const cache = this.getDataImportInvoiceHeaderCache(dataImportId);

		if (forceReload) {
			cache.invalidate();
		}

		if (cache.valid) {
			return Promise.resolve(cache.items);
		}

		return this.restService.get(API.admin, `dataimport/${dataImportId}`).then( data => {
			const items = data.dataImportBillData;
			const bills = data.bills;
			cache.items = bills.map( item => {
				return item;
			});
			return Promise.resolve(cache.items);
		});
	}

	public async saveDataImport(file: AttachedFile, dataImportId: string, validator: DataImportFileValidator): Promise<ImportResult> {
		let content = await file.textContent;

		if(content.indexOf('Service End Date') >= 0){
			let lines = content.split(/\r?\n/);
			lines.forEach((line, i) => {
				let l = line.split(',');
				l.splice(8,1);
				lines[i] = l.join(',');
			});
			content = lines.join("\n");
		}

		const postData = {
			"filename": file.name,
			"content": content,
			"dataImportId": dataImportId
		};

		const result = await this.restService.post(API.admin, 'dataimport', postData);

		const cache = this.getDataImportInvoiceHeaderCache(dataImportId);
		cache.invalidate();

		if (result.success && result.dataImportId) {
			return Promise.resolve(result);
		}
		else {
			return Promise.reject("Unknown response");
		}
	}

	public getDataImportHistory(dataImportInvoiceId: string, forceReload?: boolean): Promise<DataImportHistory[]> {
		const cache = this.getDataImportHistoryCache(dataImportInvoiceId);

		if (forceReload) {
			cache.invalidate();
		}

		if (cache.valid) {
			return Promise.resolve(cache.items);
		}

		return this.restService.get(API.admin, `dataimport/history/${dataImportInvoiceId}`).then( data => {
			cache.items = data.map( item => {
				return {
					"id": item.id,
					"user": item.staff,
					"name": item.staffName,
					"date": new Date(item.date),
					"comment": item.comment
				};
			});
			return Promise.resolve(cache.items);
		});
	}

	public addHistory(dataImportInvoiceId: string, comment: string): Promise<any> {
		const postData = {
			"dataImportInvoiceId": dataImportInvoiceId,
			"comment": comment
		};

		return this.restService.post(API.admin, 'dataimport/history', postData).then( data => {

			const cache = this.getDataImportHistoryCache(dataImportInvoiceId);
			cache.invalidate();

			return Promise.resolve();
		});
	}

	public getDataImportFile(dataImportId: string): Promise<any> {
		return this.restService.get(API.admin, `dataimport/file/${dataImportId}`).then( result => {
			if (result.success && result.document && result.filename) {
				return Promise.resolve({
					"document": result.document,
					"filename": result.filename
				});
			}

			return Promise.reject("An error occurred");
		});
	}

	private invalidateCaches() {
		this._dataImports.invalidate();
		Array.from(this._dataImportInvoiceHeaders.values()).forEach( cache => cache.invalidate() );
		Array.from(this._dataImportHistory.values()).forEach( cache => cache.invalidate() );
	}

}
