import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrivateComponent } from "@classes/private.component";
import { UserType } from "@classes/user";
import { FileMetaData, AttachedFile, FileMimeTypes, FileType, FileValidator } from "@classes/files";
import { Table, TableColumnHeading, SortType, StaticDataSource } from "@classes/tables";
import { InvoiceStatusUtils } from '@classes/invoices';
import { MenuBuilder } from "@services/navmenu.service";
import { DataImportService, DataImport, DataImportInvoiceHeader } from "@services/dataimport.service";
import { OverlayService } from "@services/overlay.service";
import { FileUploader } from 'ng2-file-upload';
import { ErrorUtils } from "@classes/errors";

abstract class DataImportFile extends FileValidator {
	abstract get fileType(): string;
	abstract get fileDescription(): string;
}

class BillLineItem20210408 extends DataImportFile {
	private static readonly signature = 'Client Name,NDIS Number,Provider ABN,Invoice Number,Invoice Date,Invoice Total $,Ticket Number (Freshdesk),Service Dates,Service Description,Service Line Item,Quantity,Service Total $';
	private static readonly fileType: string = 'LineItem';
	private static readonly fileDescription: string = 'Bill Line Item - this will create Bills with associated Line Items in a PREDRAFT status - ready for review and finalisation';

	get fileSignature(): string {
		return BillLineItem20210408.signature;
	}

	get fileType(): string {
		return BillLineItem20210408.fileType;
	}

	get fileDescription(): string {
		return BillLineItem20210408.fileDescription;
	}
}


class BillLineItem20220513 extends DataImportFile {
	private static readonly signatures = [
		'Client Name,NDIS Number,Provider ABN,Invoice Number,Invoice Date,Invoice Total $,Ticket Number (Freshdesk),Service Dates,Service Description,Service Line Item,Quantity,Service Total $',
		'Client Name,NDIS Number,Provider ABN,Invoice Number,Invoice Date,Invoice Total $,Ticket Number (Freshdesk),Service Dates,Service End Date,Service Description,Service Line Item,Quantity,Service Total $'];
	private static readonly fileType: string = 'LineItem';
	private static readonly fileDescription: string = 'Bill Line Item - this will create Bills with associated Line Items in a PREDRAFT status - ready for review and finalisation';

	get fileSignature(): string {
		return BillLineItem20220513.signatures.join('|');
	}

	get fileType(): string {
		return BillLineItem20220513.fileType;
	}

	get fileDescription(): string {
		return BillLineItem20220513.fileDescription;
	}
}

@Component({
	"styleUrls": ["./dataimport.component.scss"],
	"templateUrl": "./dataimport.component.html"
})
export class DataImportComponent extends PrivateComponent implements OnInit {

	private mimeTypes: FileMimeTypes = new FileMimeTypes(FileType.csv);
	private _fileContent: string;
	private _dataImportId: string;
	private dataImportInvoiceHeaders: DataImportInvoiceHeader[] = [];
	private _dataLoaded: boolean = false;
	private _canSave: boolean = false;
	private _matchCount: number = undefined;
	private _errorCount: number = undefined;
	private _dataImport: DataImport = undefined;
	private dataImportFile: DataImportFile;

	model: DataImport = undefined;

	private readonly tableHeadings: TableColumnHeading[] = [
		{"propName": "status", "displayName": "Status", "sortType": SortType.text },
		{"propName": "invoiceNumber", "displayName": "Inv. No.", "sortType": SortType.text },
		{"propName": "clientName", "displayName": "Client", "sortType": SortType.name },
		{"propName": "providerName", "displayName": "Provider", "sortType": SortType.name },
		{"propName": "total", "displayName": "Amount", "sortType": SortType.numeric }
	];

	tableImportedBill: Table<DataImportInvoiceHeader> = new Table('importedBill', this.tableHeadings);


	public get existingDataImport(): boolean {
		return !!this._dataImportId;
	}

	public get canSave(): boolean {
		return this._canSave;
	}

	public get dataLoaded(): boolean {
		return this._dataLoaded;
	}

	get matchCount(): number {
		return this._matchCount;
	}

	get errorCount(): number {
		return this._errorCount;
	}

	public get fileContent(): string {
		const result = this._fileContent || "";
		return result.trim();
	}

	public dropZoneOver: boolean = false;
	public fileUploader:FileUploader = new FileUploader({ url: undefined });

	public attachedFile: AttachedFile = undefined;
	public get hasFile(): boolean {
		return !!this.attachedFile;
	}

	public get fileType(): string {
		if (!!this.dataImportFile) {
			return this.dataImportFile.fileType;
		}
		return undefined;
	}

	public get fileDescription(): string {
		if (!!this.dataImportFile) {
			return this.dataImportFile.fileDescription;
		}
		return undefined;
	}

	constructor(private dataImportService: DataImportService, private route: ActivatedRoute) {
		super();

		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Data Import', 'Data Import Access');
	}

	private buildMenu(): void {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.done();
	}

	get filename(): string {
		return this.hasFile ? this.attachedFile.name : "";
	}

	get filesize(): number {
		return this.hasFile ? this.attachedFile.size : 0;
	}

	get recordCount(): number {
		return this.hasFile ? Math.max(0, this.fileContent.split(/\r?\n/).length - 1) : 0;
	}

	ngOnInit() {
		super.ngOnInit()

		this.buildMenu();

		this.route.params.subscribe( params => {

			// If data import Id has been specified on the path, load the data import data
			this._dataImportId = params.id;
			if (this._dataImportId !== undefined) {
				this.loadDataImport();
			}
		});
	}

	private loadDataImport() {
		if (!this.user || !this._dataImportId) {
			return;
		}

		this._dataLoaded = false;
		OverlayService.show();

		this.dataImportService.getDataImport(this._dataImportId).then( dataImport => {

			this._dataImport = dataImport;
			this.model = dataImport;
			return Promise.resolve();

		}).then( () => {

			return this.dataImportService.loadDataImportFile(this._dataImportId);

		}).then( items => {

			OverlayService.hide();
			this.tableImportedBill.sourceData = StaticDataSource.from(items);
			this.dataImportInvoiceHeaders = items;
			this._dataLoaded = true;

		}).catch( err => {

			OverlayService.hide();
			console.log(err);

		});
	}

	fileOverBase(event): void {
		this.dropZoneOver = event;
	}

	getIconFromMimeType(file: FileMetaData): string {
		return this.mimeTypes.getIcon(file.mimeType);
	}

	async fileDropped(droppedFiles: FileList|File[]): Promise<void> {
		this._canSave = false;
		this._matchCount = undefined;
		this._errorCount = undefined;

		if (droppedFiles.length > 0) {
			const file = droppedFiles[0];

			if (this.mimeTypes.isAllowed(file.type)) {
				const attachedFile = await AttachedFile.fromFile(file);
				this.validateFile( attachedFile ).then( result => {

					if (!result) {
						this.attachedFile = undefined;
						OverlayService.showError("Error", "This file is not in the expected format");
						return;
					}

					else {
						this.attachedFile = attachedFile;
						this.attachedFile.textContent.then( content => {
							this._fileContent = content.replace(/\r?\n/g, "\n");
							this._canSave = true;
						});
					}

				});
			}
			else {
				OverlayService.showError("Error", `Cannot upload this file type (${file.type})`);
			}
		}
	}

	private async validateFile(dataImportFile: AttachedFile): Promise<boolean> {
		const validators = {
			"billLine20220513": new BillLineItem20220513(dataImportFile),
		};

		if (await validators.billLine20220513.isValid()) {
			this.dataImportFile = validators.billLine20220513;
			return true;
		}
		else {
			return false;
		}
	}

	private async saveFile() {
		OverlayService.show();
		try {
			const importResult = await this.dataImportService.saveDataImport(this.attachedFile, this._dataImportId, this.dataImportFile);
			this._dataImportId = importResult.dataImportId;
			OverlayService.hide();

			if (importResult.invoiceIds.length === 1) {
				this.router.navigate([`/billing/${importResult.invoiceIds[0]}`]);
			}
			else {
				this.router.navigate([`/dataimport/${this._dataImportId}`]);
			}

		}
		catch (e) {

			OverlayService.hide();

			if (e.response && e.response.data && e.response.data.errorMessage.includes('duplicate key value violates unique constraint')) {
				OverlayService.showError("Error", "This file has already been uploaded");
			}

			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "Unable to import data from this file"));
			console.log(e);

		};
	}

	public checkSaveFile() {
		if (!this.hasFile) {
			return;
		}

		this.saveFile();

	}

	protected billStatusText(status: string): string {
		return InvoiceStatusUtils.toString(InvoiceStatusUtils.parse(status));
	}

}
