import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrivateComponent } from "@classes/private.component";
import { MenuBuilder } from "@services/navmenu.service";
import { OverlayService } from "@services/overlay.service";
import { UserType } from "@classes/user";
import { FileManager } from "@classes/filemanager";
import { Alignment, Table, TableColumnHeading, SortType, StaticDataSource } from "@classes/tables";
import { InvoiceService } from "@services/invoice2.service";
import { AttachmentsService } from "@services/attachments.service";
import { Invoice, InvoiceLineItem, LineItemStatus } from '@classes/invoices';
import { FileMetaData, AttachedFile, FileMimeTypes } from "@classes/files";
import { IconName } from '@fortawesome/free-solid-svg-icons';

@Component({
	"styleUrls": ["./invoice.component.scss"],
	"templateUrl": "./invoice.component.html"
})
export class ClientInvoiceComponent extends PrivateComponent implements OnInit {

	private _invoiceId: string = undefined;
	private _dataLoaded: boolean = false;
	private _attachments: AttachedFile[] = undefined;
	private _filemanager: FileManager;
	private mimeTypes: FileMimeTypes = new FileMimeTypes();

	get dataLoaded(): boolean {
		return this._dataLoaded;
	}

	public get isAdmin(): boolean {
		return this.user.accountType === UserType.Admin;
	}

	private readonly tableHeadings: TableColumnHeading[] = [
		{"propName": "date", "displayName": "Date", "sortType": SortType.date },
		{"propName": "claimReference", "displayName": "Claim Ref", "sortType": SortType.text },
		{"propName": "serviceName", "displayName": "Service", "sortType": SortType.text },
		{"propName": "quantity", "displayName": "Quantity", "sortType": SortType.text, "alignment": Alignment.center },
		{"propName": "total", "displayName": "Total", "sortType": SortType.numeric, "alignment": Alignment.right }
	];

	table: Table<InvoiceLineItem> = new Table('invoiceServicesDelivered', this.tableHeadings);

	invoice: Invoice = undefined;

	constructor(private invoiceService: InvoiceService, private attachmentsService: AttachmentsService, private route: ActivatedRoute) {
		super();
	}

	get attachments(): AttachedFile[] {
		if (this._attachments === undefined) {
			this._attachments = Array.from( this.invoice.attachments.values() );
		}

		return this._attachments;
	}

	private buildMenu() {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.done();
	}

	ngOnInit() {
		super.ngOnInit();

		this.route.params.subscribe( params => {

			this._invoiceId = params.id;
			this._dataLoaded = false;

			if (this.user) {

				// Make sure admin users have access to this page
				if (this.user.accountType === UserType.Admin) {
					if (!this.hasPermission('Account Details', 'View Other Account Details')) {
						this.router.navigate(["/accessdenied"]);
						return;
					}
				}

				OverlayService.show();
				this.invoiceService.loadInvoice(params.id).then( invoice => {

					const unknownProviders: string[] = ['ca90a10e-b1e1-4019-89a8-1c2a02b6d3b5', 'f338e5ca-409c-4cc3-a1a1-ef5812e6e85d'];

					if (unknownProviders.includes(invoice.providerId)) {
						invoice.providerName = '';
					}
					this.invoice = invoice;

					const invoiceAttachments = Array.from( invoice.attachments.values() );
					this._filemanager = new FileManager(invoiceAttachments, this.invoiceService);

					const lineItems = invoice.lineItems;
					this.table.sourceData = StaticDataSource.from(lineItems);

					this._dataLoaded = true;

					OverlayService.hide();

				}).catch( err => {

					OverlayService.hide();
					OverlayService.showError("Error", "Unable to load invoice");

				});
			}
		});

		this.buildMenu();
	}

	protected getIconFromMimeType(file: FileMetaData): IconName {
		return this.mimeTypes.getIcon(file.mimeType) as IconName;
	}

	private appendContent(w: any, content: string): void {
		w.document.body.appendChild(w.document.createElement('iframe')).src = content;
	}

	openFile(file): void {
		FileManager.downloadFile(this.attachmentsService, file, this.invoice.clientId);
	}

	public get invoiceTotal(): number {
		if (!this.invoice) {
			return undefined;
		}

		const discrepancyStatus = [LineItemStatus.paid, LineItemStatus.reconciled];
		// const totalDiscrepancy = Array.from(this.invoice.lineItems || []).filter( lineItem => discrepancyStatus.includes(lineItem.status) ).reduce( (total, lineItem) => {
		// 	return total + Number(lineItem.discrepancy) || 0;
		// }, 0);

		const totalPaid = Array
			.from(this.invoice.lineItems || [])
			.filter( lineItem => discrepancyStatus.includes(lineItem.status) )
			.reduce( (total, lineItem) => {
				return total + Number(lineItem.total) - (Number(lineItem.discrepancy) || 0);
			}, 0);
		return totalPaid;

		// return this.invoice.total - totalDiscrepancy;
	}

}
