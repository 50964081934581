import { ServiceAgreement, ServiceAgreementCategory, ServiceAgreementItem, ServiceDelivered } from "@classes/serviceAgreement";
import { Plan } from "@classes/plans";
import { PlanBudget } from "@classes/budget";
import { assert} from "@classes/errors";
import { FloatingPointUtils } from "@classes/utils";
import moment from "moment";

export class OverspendTest {

	private static readonly roundingErrorCutoff = 0.001;

	constructor(
		private agreement: ServiceAgreement,
		private otherAgreements: ServiceAgreement[],
		private plan: Plan,
		private servicesDelivered: Map<number, ServiceDelivered[]>) {}

	private calcOtherAgreementTotals(categoryNumber: number): number {
		const agreements = this.otherAgreements || [];
		return agreements
			.map( item => item.category(categoryNumber) )
			.filter( cat => !!cat && !!cat.budget )
			.map( cat => Number(cat.budget.total) || 0 )
			.reduce( (acc, cur) => { return acc + cur; }, 0);
	}

	/**
	* Calculates the total of all services already delivered by the provider between the service agreement dates.
	* Enables us to adjust the amount that's marked as "already allocated" correctly so that the provider budget
	* does not show a false error.
	*
	* Required in case we adjust the service agreement dates on the fly, so that we can't rely on the budget values
	* that come back from the server.
	*/
	public providerSpend(categoryNumber: number): number {

		const agreementStart = moment(this.agreement.dateFrom);
		const agreementEnd = moment(this.agreement.dateTo);

		const servicesDelivered = this.servicesDelivered.get(categoryNumber) || [];

		// Only include the services delivered between the SA start and end dates
		const saSpend = servicesDelivered.filter( item => {
			return moment(item.date).isBetween(agreementStart, agreementEnd, undefined, "[]");
		});

		// Add up the total of the services delivered
		const spendTotal = saSpend.reduce( (total, serviceDelivered) => {
			return total + serviceDelivered.total;
		}, 0);

		return spendTotal;
	}

	public hasOverspend(categoryNumber: number) {
		const planSupportCategory = this.plan.supportCategories.find( category => category.supportCategory.id === categoryNumber );
		assert(!!planSupportCategory, "Plan does not have budget for this support category");

		// Should never fail this test
		const category = this.agreement.category(categoryNumber);
		assert(!!category);

		// Nor this one
		const agreementCategory = this.agreement.categories.find( category => category.categoryNumber === categoryNumber );
		assert(!!agreementCategory);

		const alreadySpentByProvider = this.providerSpend(categoryNumber);

		const agreementBudget = agreementCategory.budget;
		assert(FloatingPointUtils.greaterThanOrEqualTo(agreementBudget.total || 0, alreadySpentByProvider), "Insufficient budget to fund existing allocation");

		// Check that the budget for the category does not exceed the plan's budget for the same category
		const categoryTotal = Number(category.budget.total || 0);
		const otherAgreementTotals = this.calcOtherAgreementTotals(categoryNumber);
		assert(!Number.isNaN(categoryTotal));

		// We can't definitively check that the amount entered does not exceed the budget here, because if plan dates have
		// changed we don't necessarily know the overall spend in the plan. Put a basic budget check in place here, and rely
		// on the server-side error checking to ensure the service agreement budgets are OK
		assert(FloatingPointUtils.lessThanOrEqualTo(categoryTotal, planSupportCategory.total - otherAgreementTotals), 'Exceeds available category budget');
	}

	public checkSufficientBudgetForServiceAgreements(categoryNumber: number): void {
		const category = this.plan.supportCategories.find( category => category.supportCategory.id === categoryNumber );
		assert(!!category, "Plan does not have budget for this support category");

		// Make sure the value we've got for the category budget is numeric
		const categoryTotal = Number(category.total);

		const saCategory = this.agreement.category(categoryNumber);
		if (!saCategory) {
			return;
		}

		// Find the amount already spent by the provider
		const alreadySpentByProvider = this.providerSpend(categoryNumber);

		// Make sure the overall funds remaining for the category >= funds remaining for the service agreement.
		const overallCategoryRemaining = alreadySpentByProvider + category.categoryBudget.total - category.categoryBudget.paid - category.categoryBudget.pending - category.categoryBudget.unknown;
		assert(FloatingPointUtils.lessThanOrEqualTo(saCategory.budget.total || 0, overallCategoryRemaining), `Insufficient funds to cover service agreements`);
		const budgetRemaining = (saCategory.budget.total || 0) - (saCategory.budget.paid || 0) - (saCategory.budget.pending || 0) - (saCategory.budget.unknown || 0);
		assert(FloatingPointUtils.lessThanOrEqualTo(budgetRemaining, overallCategoryRemaining), `Insufficient funds to cover service agreements`);

		// Calculate the total amount in quarantine. Don't rely on the value provided on the category budget here,
		// because the user may have edited the value
		const quarantineTotal = this.calcOtherAgreementTotals(categoryNumber) + saCategory.budget.total || 0;

		// If no quarantined total is specified, exit here.
		if (quarantineTotal < OverspendTest.roundingErrorCutoff) {
			return;
		}

		const budget = category.exclusiveBudget;

		// const adjustmentForNewAgreements = this.agreement.id ? 0 : this.providerSpend(categoryNumber);
		// const allocatedAmount = budget.paid + budget.pending + budget.draft + budget.unknown - adjustmentForNewAgreements;
		// const allocatedAmount = budget.paid + budget.pending + budget.draft + budget.unknown;
		const allocatedAmount = budget.paid + budget.pending + budget.draft + budget.unknown - alreadySpentByProvider;

		assert(FloatingPointUtils.lessThanOrEqualTo(quarantineTotal, categoryTotal - allocatedAmount), `Insufficient funds to cover service agreements`);
	}
}

export class SupportItemBudgetTest {
	constructor(
		private agreement: ServiceAgreement,
		private servicesDelivered: Map<number, ServiceDelivered[]>,
		private category: ServiceAgreementCategory) {}

	/**
	* Calculates the total of all services already delivered by the provider between the service agreement dates.
	* Enables us to adjust the amount that's marked as "already allocated" correctly so that the provider budget
	* does not show a false error.
	*/
	public providerSpend(supportItemNumber: string): number {

		const agreementStart = moment(this.agreement.dateFrom);
		const agreementEnd = moment(this.agreement.dateTo);

		const servicesDelivered = this.servicesDelivered.get(this.category.categoryNumber) || [];
		return (servicesDelivered || []).filter( item => {
			return item.supportItem === supportItemNumber;
		}).filter( item => {
			return moment(item.date).isBetween(agreementStart, agreementEnd, undefined, "[]");
		}).reduce( (total, serviceDelivered) => {
			return total + serviceDelivered.total;
		}, 0);
	}

	public checkValid(item: ServiceAgreementItem) {

		// Make sure the category is defined
		assert(!!this.category);

		// Find the amount already spent by the provider
		const alreadySpentByProvider = this.providerSpend(item.supportItemNumber);

		const categoryBudget = PlanBudget.numberify(this.category.budget);
		const itemBudget = PlanBudget.numberify(item.budget);

		const totalAvailable = categoryBudget.total
		                     - categoryBudget.draft
		                     - categoryBudget.pending
		                     - categoryBudget.unknown
		                     - categoryBudget.paid
		                     // - otherItemsTotal
		                     + alreadySpentByProvider;

		assert(FloatingPointUtils.greaterThanOrEqualTo(totalAvailable, itemBudget.total), "Item budget exceeds available category budget");
	}
}
