import { Component, OnInit } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { UserType } from "@classes/user";
import { AdminService, NewClient } from "@services/admin.service";
import { Table, TableColumnHeading, SortType, StaticDataSource } from "@classes/tables";

@Component({
	"selector": "new-clients",
	"styleUrls": ["./newclients.component.scss"],
	"templateUrl": "./newclients.component.html"
})
export class NewClientsComponent extends PrivateComponent implements OnInit {

	private _dataLoaded: boolean = false;
	private readonly _numDays: number = 7;

	get dataLoaded(): boolean { return this._dataLoaded; }
	get numDays(): number { return this._numDays; }

	private readonly tableHeadings: TableColumnHeading[] = [
		{"propName": "clientName", "displayName": "Client", "sortType": SortType.name },
		{"propName": "createdBy", "displayName": "Created By", "sortType": SortType.name },
		{"propName": "createdAt", "displayName": "Date Joined", "sortType": SortType.date },
		{"propName": "startDate", "displayName": "Plan Start Date", "sortType": SortType.date }
	];

	table: Table<NewClient> = new Table('newClients', this.tableHeadings);

	constructor(private adminService: AdminService) {
		super();
		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Admin Dashboard', 'New Clients');
	}

	ngOnInit() {
		super.ngOnInit()
		this.loadData();
	}

	private async loadData() {
		if (this.user) {
			const data = await this.adminService.newClients();
			this.table.sourceData = StaticDataSource.from(data);
			this._dataLoaded = true;
		}
	}

}
