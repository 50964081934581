import { Component, OnInit } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { UserType } from "@classes/user";
import { AdminService } from "@services/admin.service";
import { PaginatedTable, TableColumnHeading, SortType, StaticDataSource } from "@classes/tables";
import { TrafficLightData } from '@classes/trafficlights';

@Component({
	"selector": "clients-with-trafficlights",
	"styleUrls": ["./clientswithtrafficlights.component.scss"],
	"templateUrl": "./clientswithtrafficlights.component.html"
})
export class ClientsWithTrafficLightsComponent extends PrivateComponent implements OnInit {

	private _dataLoaded: boolean = false;

	get dataLoaded(): boolean { return this._dataLoaded; }

	private readonly tableHeadings: TableColumnHeading[] = [
		{"propName": "clientName", "displayName": "Client", "sortType": SortType.name },
		{"propName": undefined, "displayName": "", "sortType": SortType.none },
		{"propName": "variance", "displayName": "Variance %", "sortType": SortType.numeric },
		{"propName": "dashboardInclusionDate", "displayName": "Due On", "sortType": SortType.date },
		{"propName": "dashboardComment", "displayName": "Comment", "sortType": SortType.name }
	];

	table: PaginatedTable<TrafficLightData> = new PaginatedTable('clientsWithTrafficLights', this.tableHeadings);

	constructor(private adminService: AdminService) {
		super();
		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Admin Dashboard', 'Traffic Lights');
	}

	ngOnInit() {
		super.ngOnInit()

		this.loadData();
	}

	private async loadData() {
		if (this.user) {
			const trafficLights = await this.adminService.clientsWithTrafficLights();
			this.table.sourceData = StaticDataSource.from(trafficLights);
			this._dataLoaded = true;
		}
	}

}
