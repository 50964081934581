import { Injectable } from "@angular/core";
import { UserAccount, User, UserType, UserAccountUtils, UserRole, LoginHistory } from "@classes/user";
import { ClientStatus } from "@classes/clientStatus";
import { Router } from "@angular/router";
import { Auth, Hub } from "aws-amplify";
import { OverlayService } from "./overlay.service";
import { DataExchangeService } from "./dataexchange.service";
import { RestService, API } from "./rest.service";
import { CacheSignalService } from "@services/cachesignal.service";
import { OfflineAuthService } from "./offlineauth.service";
import { AsyncResult, AsyncSuccess, AsyncError } from "@classes/asyncresult";
import { Cache } from "@classes/cache";
import { AttachedFile } from "@classes/files";
import { AttachmentTargetUtils } from "@classes/attachments";
import { BankInformation, BankInformationUtils } from "@classes/bankinfo";
import { Settings } from "@classes/settings";
import { Utils, DateUtils } from "@classes/utils";
import { CacheRegistry } from "@classes/cache";
import { Permissions } from "@classes/permissions";
import { Gender } from "@classes/gender";
import { ContactPreference } from "@classes/contactPreference";
import { AttachmentType } from "@classes/attachmentType";
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import moment from "moment";

@Injectable({ providedIn: "root" })
export class UserService {

	private static defaultLoginFailureMessage = "Incorrect username or password.";

	private linkedSupportCoordinatorCache = new Map<string, UserAccount[]>();
	private rolesCache: Cache<UserRole> = new Cache<UserRole>(null);

	private signedIn = false;
	private user: any = null;
	private currentState = "";

	private loginHistoryCache = new Map<string, Cache<LoginHistory>>();
	private userCache = new Map<string, User>();

	// Stores queued requests for user data, to avoid multiple, simultaneous requests for the same data
	private waitQueue = new Map<string, any[]>();

	private listener = async (data?) => {
		
		if(data) this.currentState = data.payload.event;

		switch (this.currentState) {
			case "forgotPassword":

				this.getUserInfo(this.user, ["/password/change"]);
				return;
			case "signIn":
			case "autoSignIn":

				let route = this.router.url || DataExchangeService.getPath() || "/dashboard";
				if (route === "/login") {
					route = "/dashboard";
				}
				this.getUserInfo(this.user, [route]);
				return;
			default:
				Auth.currentAuthenticatedUser().catch(err => {
					this.signedIn = false;
				}).then(user => {
					if (user)
						this.signedIn = true;
				});
		}
	}

	private loadFromPostgres(identityId?: string, cognitoUser?: any): Promise<User> {

		let path = "user";
		if (identityId !== undefined) {
			path += `/${identityId}`;
		}

		return this.restService.get(API.system, path, undefined).then( result => {
			const user = new User(result.user, cognitoUser);
			if (identityId) {
				this.userCache.set(identityId, user);
			}

			return Promise.resolve(user);
		});
	}

	private parsePhoneNumber(src: string): string {
		if (!src) {
			return '';
		}

		try {
			const phoneUtil = PhoneNumberUtil.getInstance();
			const phoneNumber = phoneUtil.parseAndKeepRawInput(src, "AU");
			return phoneUtil.isValidNumber(phoneNumber) ? phoneUtil.format(phoneNumber, PhoneNumberFormat.E164) : src;
		}
		catch (e) {
			return '';
		}
	}

	public loadUser(identityId: string, forceReload: boolean = false): Promise<User> {

		// Reject if an identityId is not specified
		if (!identityId) {
			return Promise.reject(undefined);
		}

		if (forceReload) {
			this.userCache.delete(identityId);
		}

		// If we've already cached this user, then return it immediately
		const cachedUser = this.userCache.get(identityId);
		if (!!cachedUser) {
			console.log("Returning cached user");
			return Promise.resolve(cachedUser);
		}

		return new Promise( (resolve, reject) => {

			// If we're already requesting the data, add this request to the queue
			if (this.waitQueue.has(identityId)) {
				this.waitQueue.get(identityId).push( {
					"resolve": resolve,
					"reject": reject
				});
				return;
			}

			// Create a wait queue for requests for this identityId
			this.waitQueue.set(identityId, []);

			// Attempt to load the user from postgres
			return this.loadFromPostgres(identityId).then( user => {

				// If we have a successful result from the server, resolve all of the queued requests
				const queue = this.waitQueue.get(identityId);
				while (queue.length) {
					const queuedRequest = queue.pop();
					queuedRequest.resolve.call(null, user);
				}

				// Delete the wait queue
				this.waitQueue.delete(identityId);

				// Finally, resolve the initial request
				return resolve(user);
			}).catch( err => {

				// If an error occurs, reject all of the queued requests (if any)
				const queue = this.waitQueue.get(identityId);
				while (queue.length) {
					const queuedRequest = queue.pop();
					queuedRequest.reject.call(null, err);
				}

				// Then, delete the queue
				this.waitQueue.delete(identityId);

				// And finally, reject the initial request
				return reject(err);
			});
		});
	}

	private loadSettings(): Promise<any> {
		return this.restService.get(API.system, "settings").then( result => {

			const settings = Settings.instance;
			settings.setAll(result);
			return Promise.resolve();

		});
	}

	private getUserInfo(cognitoUser: any, redirectPath?: string[]): void {

		OverlayService.show(`Loading your account${Utils.ellipsis}`);

		let promise: Promise<any> = Promise.resolve();

		if (this.offlineAuthService.isOffline()) {
			
			promise = Auth.currentUserInfo().then( result => {
				this.offlineAuthService.setIdentity(result.id);
				return Promise.resolve();
			});
		}

		promise.then( () => {

			const promises = [
				this.loadFromPostgres(undefined, cognitoUser).then( user => {
					this.user = user;

					if (user.accountType === UserType.Admin) {
						return Permissions.load();
					}
					else {
						return Promise.resolve();
					}
				}),
				this.loadSettings()
			];

			// return this.loadFromPostgres(undefined, cognitoUser);
			return Promise.all(promises);

		}).then( () => {


			OverlayService.hide();
			this.signedIn = true;
			// this.user = new User(response, cognitoUser);
			// this.user = user;
			if (redirectPath)
				this.router.navigate(redirectPath);

		}).catch( err => {

			OverlayService.hide();
			this.signedIn = false;
			this.user = null;
			console.log(err);

		});
	}

	constructor(
		private router: Router,
		private restService: RestService,
		private signalService: CacheSignalService,
		private offlineAuthService: OfflineAuthService) {
			Hub.listen('auth', this.listener);
			Auth.currentAuthenticatedUser().catch(err => {
				this.signedIn = false;
			}).then(user => {
				if (user) {
					this.signedIn = true;
					this.user = user;
					this.currentState = "autoSignIn";
					this.listener();
				}
			});
		}

	isLoggedIn(): boolean {
		return this.signedIn && this.user !== null;
	}

	logout(): void {
		this.user = null;
		this.signedIn = false;
		CacheRegistry.clear();
		DataExchangeService.clear();
		Auth.signOut().then( () => {
			this.router.navigate(["/login"]);
		});
	}

	getUser(): User {
		return this.user;
	}

	/**
	* Alias of getUser
	*/
	getCurrentUser(): User {
		return this.user;
	}

	passwordChangeRequired(): boolean {
		return this.currentState === "requireNewPassword";
	}

	changePassword(oldPwd: string, newPwd: string): Promise<AsyncResult> {

		OverlayService.show(`Changing password${Utils.ellipsis}`);

		

		// Handle the (rare) cases when a user has been created in the console
		if (this.passwordChangeRequired()) {
			return Auth.completeNewPassword(this.user.cognitoUser, newPwd, null).then( result => {
				OverlayService.hide();
				return new AsyncSuccess("Password changed successfully");
			});
		}

		return Auth.changePassword(this.user.cognitoUser, oldPwd, newPwd).then( result => {

			OverlayService.hide();
			return new AsyncSuccess("Password changed successfully");

		}).catch( e => {

			OverlayService.hide();

			let message = e.message;
			if (e.name === "NotAuthorizedException") {
				message = "Invalid password";
			}

			return new AsyncError(message);

		});
	}

	login(username: string, password: string): Promise<any> {

		OverlayService.show(`Checking your credentials${Utils.ellipsis}`);

		return Auth.signIn(username, password).catch( err => {

			OverlayService.showError("Unable to log in", UserService.defaultLoginFailureMessage);

		});
	}

	getLinkedSupportCoordinators(userId: string): Promise<UserAccount[]> {

		if (this.linkedSupportCoordinatorCache.has(userId)) {
			return Promise.resolve( this.linkedSupportCoordinatorCache.get(userId) );
		}

		return this.restService.get(API.admin, `supportcoordinators/linked/${userId}`).then(result => {

			const data = result.map( UserAccountUtils.parse );

			this.linkedSupportCoordinatorCache.set(userId, data);
			return Promise.resolve( data );
		});
	}

	getAllSupportCoordinators(): Promise<UserAccount[]> {
		return this.restService.get(API.admin, `supportcoordinators`).then(result => {

			const data = result.map( UserAccountUtils.parse );
			return Promise.resolve( data );
		});
	}

	unlinkSupportCoordinator(supportCoordinatorId: string, clientId: string): Promise<any> {
		return this.restService.delete(API.admin, `supportcoordinators/${clientId}/${supportCoordinatorId}`).then( () => {
			this.linkedSupportCoordinatorCache.delete(clientId);
			return Promise.resolve();
		});
	}

	linkSupportCoordinators(clientId: string, supportCoordinatorIds: string[]): Promise<any> {
		return this.restService.post(API.admin, `supportcoordinators/${clientId}`, supportCoordinatorIds).then( () => {
			this.linkedSupportCoordinatorCache.delete(clientId);
			return Promise.resolve();
		});
	}

	getLoginHistory(clientId: string): Promise<LoginHistory[]> {

		if (!this.loginHistoryCache.has(clientId)) {
			this.loginHistoryCache.set(clientId, new Cache<LoginHistory>());
		}

		const cache = this.loginHistoryCache.get(clientId);
		if (cache.valid) {
			return Promise.resolve( cache.items );
		}

		return this.restService.get(API.admin, `loginhistory/${clientId}`).then( response => {
			cache.items = response;
			return Promise.resolve(cache.items);
		});
	}

	enableCognito(clientId: string): Promise<boolean> {
		return this.restService.post(API.admin, `permitlogin`, {"clientId": clientId}).then( () => {

			return Promise.resolve(true);

		}).catch( err => {

			console.log(err);
			return Promise.resolve(false);

		});
	}

	requestAuthCode(clientId: string): Promise<boolean> {
		return this.restService.get(API.admin, `authcode/client/${clientId}`).then( result => {

			return Promise.resolve(true);

		}).catch( err => {

			return Promise.reject(err.response.data);

		});
	}

	unlock(clientId: string, authcode: string): Promise<boolean> {
		const params = {
			"id": clientId,
			"authCode": authcode
		};

		return this.restService.post(API.admin, "unlock", params).then( result => {
			return Promise.resolve( result.valid );
		});
	}

	loadBankInfo(clientId: string): Promise<BankInformation> {
		return this.restService.get(API.user, `bankinfo/${clientId}`).then( result => {

			return Promise.resolve(BankInformationUtils.from(result));

		}).catch( err => {

			console.log(err);
			return Promise.resolve(BankInformationUtils.newInfo());

		});
	}

	loadUserDocuments(clientId: string): Promise<AttachedFile[]> {
		return this.restService.get(API.attachments, `client/${clientId}`).then( data => {

			const attachments = data.map( item => {

				let targets = undefined;
				if (item.targets) {
					targets = item.targets.map( AttachmentTargetUtils.parse );
				}

				return AttachedFile.fromMetaData({
					"id": item.id,
					"name": item.filename,
					"mimeType": item.mimeType,
					"size": Number(item.size),
					"md5": item.md5,
					"dateAdded": moment(item.dateAdded).toDate(),
					"attachmentType": AttachmentType.parse(item.attachmentType)
				}, targets);
			});

			return Promise.resolve(attachments);
		});
	}

	saveBankInfo(clientId: string, bankInfo: BankInformation): Promise<any> {

		const postData = {
			"clientId": clientId,
			"bankInfo": {
				"bsb": bankInfo.bankAccountBSB,
				"accountNumber": bankInfo.bankAccountNumber,
				"accountName": bankInfo.bankAccountName,
				"notes": bankInfo.bankAccountParticulars
			},
			"authcode": bankInfo.authcode
		};

		return this.restService.post(API.admin, "user/bankinfo", postData).then( () => {
			return Promise.resolve();
		});

	}

	deleteBankInfo(clientId: string, bankInfo: BankInformation): Promise<any> {

		const postData = {
			"clientId": clientId,
			"authcode": bankInfo.authcode
		};

		return this.restService.delete(API.admin, "user/bankinfo", postData).then( () => {
			return Promise.resolve();
		});

	}

	private dateToString(date: Date): string {
		return DateUtils.toString(date);
	}

	updateUser(user: User, updatePlans: boolean = false): Promise<any> {

		const postData = {
			"ndisNumber": user.ndisNumber,
			// "region": RegionUtils.toPostgresEnum(user.region),
			"firstName": user.firstName,
			"lastName": user.lastName,
			"givenName": user.givenName,
			"dob": this.dateToString(user.dob),
			"inactive": !!user.inactive,
			"address": user.address,
			"postalAddress": user.postalAddress,
			"email": user.email,
			"phone": this.parsePhoneNumber(user.phone),
			"status": ClientStatus.toJSON(user.status),
			"updatePlans": updatePlans,
			"receiveStatements": user.receiveStatements,
			"gender": Gender.toJSON(user.gender),
			"contactMethod": ContactPreference.toJSON(user.contactMethod),
			"disability": user.disability
		};

		return this.restService.put(API.system, `user/${user.id}`, postData).then( result => {

			if (result.success) {
				this.userCache.delete(user.id);

				if (this.user.id === result.user.id) {
					this.user = new User(result.user, this.user.cognitoUser);
				}

				return Promise.resolve(result.user);
			}
			return Promise.reject("Unable to update user");
		});
	}

	savePermissions(userId: string, permissions: string[]): Promise<User> {
		const postData = {
			"permissions": permissions
		};

		return this.restService.put(API.admin, `permissions/${userId}`, postData).then( result => {
			if (result.success) {
				const user = new User(result.user);
				this.userCache.set(userId, user);
				return Promise.resolve(user);
			}

			return Promise.reject("Failed to update user permissions");
		});
	}

	public getRoles(): Promise<UserRole[]> {
		if (this.rolesCache.valid) {
			return Promise.resolve(this.rolesCache.items);
		}

		return this.restService.get(API.system, `roles`).then( roles => {
			this.rolesCache.items = roles;
			return Promise.resolve(this.rolesCache.items);
		});
	}

	public saveRoles(userId: string, roles: string[]): Promise<User> {
		const postData = {
			"roles": roles
		};

		return this.restService.put(API.admin, `roles/${userId}`, postData).then( result => {
			if (result.success) {
				const user = new User(result.user);
				this.userCache.set(userId, user);
				return Promise.resolve(user);
			}

			return Promise.reject("Failed to update user roles");
		});
	}

	public requestStatement(userId: string, year: number, month: number, email: boolean): Promise<any> {
		const queryParam = email ? "?emailpdf" : "";
		const path = `statements/${year}/${month}/${userId}${queryParam}`;
		return this.restService.get(API.statements, path);
	}

}
