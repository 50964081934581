import { Component, OnInit, Input, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { AttachmentsService } from "@services/attachments.service";
import { OverlayService } from "@services/overlay.service";
import { AttachedFile } from "@classes/files";
import { AttachmentTarget } from "@classes/attachments";
import { AttachmentType } from "@classes/attachmentType";
import { FileManager } from "@classes/filemanager";
import { takeUntil } from 'rxjs/operators';

interface AttachmentState {
	hash: string;
	type: AttachmentType;
}

@Component({
	"selector": "attachment-dialog",
	"styleUrls": ["./attachmentDialog.component.scss"],
	"templateUrl": "./attachmentDialog.component.html"
})
export class AttachmentDialogComponent extends PrivateComponent implements OnInit {

	@ViewChild('filectl')
	private fileInput: ElementRef;

	private _filemanager: FileManager;
	// private _originalState: Set<string> = new Set<string>();
	private _originalState: Map<string, AttachmentState> = new Map<string, AttachmentState>();
	private _additionalData: any;
	private _allowSaveWhenUnchanged: boolean = false;
	private _attachmentType: AttachmentType = undefined;
	private _canChooseAttachmentType: boolean = true;

	private model = {
		"fileDescription": undefined
	};

	private _targets: AttachmentTarget[] = [];

	constructor(private attachmentsService: AttachmentsService) {
		super();
	}

	ngOnInit() {
		super.ngOnInit();
	}

	@Input('defaultType')
	public set attachmentType(value: AttachmentType) {
		this._attachmentType = value;
		// this._canChooseAttachmentType = false;
	}

	@Input()
	set allowSaveWhenUnchanged(value: boolean) {
		this._allowSaveWhenUnchanged = value;
	}

	get allowSaveWhenUnchanged(): boolean {
		return this._allowSaveWhenUnchanged;
	}

	@Input()
	set data(value: any) {
		this._targets = value.targets || [];
		this._additionalData = value.additionalData;
		this.setFiles(value.files || []);
	}

	private setFiles(files: AttachedFile[]) {

		this._filemanager = new FileManager(files, this.attachmentsService);

		this._filemanager.fileAdded$.pipe(takeUntil(this.unsubscribe)).subscribe( this.fileAdded.bind(this) );

		this.buildAttachmentStateMap(this._originalState, files);
	}

	private buildAttachmentStateMap(map: Map<string, AttachmentState>, files: AttachedFile[]) {
		map.clear();
		files.forEach( file => {
			map.set(file.md5, {
				"hash": file.md5,
				"type": file.attachmentType
			});
		});
	}

	@Output()
	onSave = new EventEmitter<any>();

	get fileManager(): FileManager {
		return this._filemanager;
	}

	get canChooseAttachmentType(): boolean {
		return this._canChooseAttachmentType;
	}

	get notChanged(): boolean {
		const currentState: Map<string, AttachmentState> = new Map<string, AttachmentState>();
		this.buildAttachmentStateMap(currentState, this._filemanager.attachedFiles);

		if (currentState.size !== this._originalState.size) {
			return false;
		}

		const notModified = Array.from(currentState.keys()).every( key => {
			if (!this._originalState.has(key)) {
				return false;
			}

			return this._originalState.get(key).type === currentState.get(key).type;
		});

		return notModified;
	}

	/**
	* Clear the file input field, otherwise adding a file, deleting it and re-adding it fails.
	* Set the default attachmentType.
	*/
	private fileAdded(file: AttachedFile) {
		file.attachmentType = this._attachmentType;
		this.fileInput.nativeElement.value = '';
	}

	public saveAttachments() {
		this.onSave.emit({
			"fileManager": this._filemanager,
			"targets": this._targets,
			"additionalData": this._additionalData
		});
		OverlayService.hide();
	}

	public closeDialogWindow() {
		OverlayService.hide();
	}

	protected downloadFile(file: AttachedFile) {
		FileManager.downloadFile(this.attachmentsService, file);
	}

	public allAttachmentTypes: AttachmentType[] = AttachmentType.allValues;
	public attachmentTypeName: (AttachmentType) => string = AttachmentType.toString;
}
