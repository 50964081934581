import { Component, Input, Output, EventEmitter, ViewChild, TemplateRef, OnInit } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { User } from "@classes/user";
import { Provider } from "@services/provider.service";
import { OverlayService } from "@services/overlay.service";
import { AttachedFile } from "@classes/files";
import { AttachmentsService } from "@services/attachments.service";
import { Table, TableColumnHeading, SortDirection, SortType, Alignment, StaticDataSource } from "@classes/tables";
import { AttachmentTargetType, AttachmentTargetUtils  } from '@classes/attachments';
import { FileMimeTypes } from "@classes/files";
import { FileNote, FileNoteStatus, FileNoteUtils } from "@classes/filenotes";
import { FileNotesService } from "@services/filenotes.service";
import { FreshdeskService } from "@services/freshdesk.service";
import { FileManager } from "@classes/filemanager";
import { ErrorUtils } from "@classes/errors";
import { Utils } from "@classes/utils";
import { IconName } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

@Component({
	"selector": "filenotes",
	"styleUrls": ["./notes.component.scss"],
	"templateUrl": "./notes.component.html"
})
export class FileNotesComponent extends PrivateComponent implements OnInit {

	@ViewChild('newNoteDialog')
	private noteDialog: TemplateRef<any>;

	@ViewChild('attachmentDialog')
	private attachmentDialog: TemplateRef<any>;

	private _fileMimeTypes: FileMimeTypes = new FileMimeTypes();

	private readonly tableHeadings: TableColumnHeading[] = [
		{"propName": "createdDate", "displayName": "Created", "sortType": SortType.date },
		{"propName": "title", "displayName": "Title", "sortType": SortType.text },
		{"propName": "status", "displayName": "Status", "sortType": SortType.numeric, "alignment": Alignment.center },
		{"propName": "lastModified", "displayName": "Modified", "sortType": SortType.text },
		// {"propName": "lastModifiedBy.name", "displayName": "By", "sortType": SortType.text },
		{"propName": undefined, "displayName": "Replies", "sortType": SortType.none, "alignment": Alignment.center },
		{"propName": undefined, "displayName": "Ticket", "sortType": SortType.none, "alignment": Alignment.center },
		{"propName": undefined, "displayName": "", "sortType": SortType.none, "alignment": Alignment.center },
	];

	readonly table: Table<FileNote> = new Table('filentoes', this.tableHeadings);

	private _notes: FileNote[];

	constructor(private fileNotesService: FileNotesService,
		private attachmentsService: AttachmentsService,
		private freshdeskService: FreshdeskService) {
		super();
	}

	private reloadNote(note: FileNote) {
		const noteId = note.parent || note.id;
		this._currentNote = this._notes.find( n => n.id === noteId );
		if( !!this._currentNote.replies && this._currentNote.replies.length > 1 ) { 
			this._currentNote.replies.sort( (a, b) => {
				try {
					return a.createdDate.valueOf() - b.createdDate.valueOf();
				}
				catch (e) {
					return 0;
				}
			});
		}
	}

	@Input()
	public set notes(value: FileNote[]) {
		this._notes = value;
		this.table.sourceData = StaticDataSource.from(this._notes);

		if (this.currentNote) {
			this.reloadNote(this.currentNote);
		}
	}

	@Input('user')
	public userTarget: User;

	@Input('provider')
	public providerTarget: Provider;

	@Output()
	public reload: EventEmitter<number> = new EventEmitter<number>();

	@Output()
	public attachmentsChanged: EventEmitter<number> = new EventEmitter<number>();

	public get notes(): FileNote[] {
		return this._notes;
	}

	ngOnInit() {
		super.ngOnInit();
		if (this.table.sortColumn === undefined) {
			this.table.sortColumn = this.tableHeadings[0];
			this.table.sortDirection = SortDirection.ascending;
			this.table.changeSort(this.table.sortColumn);
		}
	}

	private _currentNote: FileNote = undefined;

	public get currentNote(): FileNote {
		return this._currentNote;
	}

	protected get currentIcon(): IconName {
		return this._currentNote.icon as IconName;
	}

	public set currentNote(value: FileNote) {
		if (value === undefined) {
			this._currentNote = undefined;
		}
		else {
			this.reloadNote(value);
		}
	}

	get referenceCode(): string {
		return this._currentNote.referenceCode;
	}

	public ticketUrl(code): string {
		if(code.length > 7 && code.startsWith('0'))
			return ""; //'https://salesforceredirector.mpm.com.au/' + code;
		else
		return this.freshdeskService.getTicketUrl(code) || "";
	}

	public isValidTicketNumber(code): boolean {
		const SF = /^0\d{7}$/;
		const FD = /^\d{7}$/;
		return FD.test(code) || SF.test(code);
	}

	public get currentNoteCreatedDate(): string {
		return moment(this._currentNote.createdDate).format('D/M/YY h:mm a');
	}

	public get currentNoteLastModifiedDate(): string {
		if(this._currentNote.lastModified instanceof Date && this._currentNote.lastModified.valueOf())
			return moment(this._currentNote.lastModified).format('D/M/YY h:mm a');
		else
			return '';
	}

	public readonly maxNoteContentChars: number = 100;

	public readonly fileNoteStatus = {
		"open": FileNoteStatus.open,
		"completed": FileNoteStatus.completed,
		// "notStarted": FileNoteStatus.not_started
	};

	// private showNewNoteDialog() {
	// 	this._noteBeingEdited = undefined;
	// 	OverlayService.showTemplate(this.noteDialog);
	// }

	protected editNote(note: FileNote): void {
		OverlayService.showTemplate(this.noteDialog, note);
	}

	private findNoteIndex(id: string, collection: FileNote[]): number {
		return collection.findIndex(item => item.id === id);
	}

	private async deleteNote(note: FileNote) {
		OverlayService.hide();
		OverlayService.show(`Deleting note${Utils.ellipsis}`);
		try {
			await this.fileNotesService.deleteNote(note);

			if (!note.parent) {
				this._currentNote = undefined;
			}

			OverlayService.hide();
			this.reload.emit();
		}
		catch (e) {
			OverlayService.hide();
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "An unknown error occurred"));
		};
	}

	protected confirmDeleteNote(note: FileNote): void {
		OverlayService.showDialog("Confirm", "Are you sure you want to delete this note?", [{
			"text": "No - don't delete",
			"handler": () => { OverlayService.hide(); }
		}, {
			"text": "Yes - delete",
			"handler": () => { this.deleteNote(note); }
		}]);
	}

	protected saveNote(note: FileNote): void {

		OverlayService.hide();
		setTimeout( () => {
			OverlayService.show(`Saving${Utils.ellipsis}`);
			this.fileNotesService.saveNote( note ).then( _ => {

				// Will be quick if the cache is still valid, otherwise a round-trip to the server is required
				this.reload.emit();

			}).then( () => {

				OverlayService.hide();

			}).catch( e => {

				OverlayService.hide();
				console.log(e);
				OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "An unknown error occurred"));

			});
		}, 0);

	}

	protected setNoteStatus(note: FileNote, status: FileNoteStatus): void {
		note.status = status;
		this.saveNote(note);
	}

	public showNewNoteDialog() {
		const newNote = FileNoteUtils.newNote();
		if (this.userTarget) {
			newNote.clientId = this.userTarget.id;
			newNote.clientName = `${this.userTarget.firstName} ${this.userTarget.lastName}`;
		}
		else {
			newNote.providerId = this.providerTarget.id;
			newNote.providerName = this.providerTarget.name;
		}
		OverlayService.showTemplate(this.noteDialog, newNote);
	}

	public addChildNote(note: FileNote) {
		const newNote = FileNoteUtils.newNote();

		if (this.userTarget) {
			newNote.clientId = this.userTarget.id;
			newNote.clientName = `${this.userTarget.firstName} ${this.userTarget.lastName}`;
		}
		else if (this.providerTarget) {
			newNote.providerId = this.providerTarget.id;
			newNote.providerName = this.providerTarget.name;
		}
		newNote.parent = note.id;
		OverlayService.showTemplate(this.noteDialog, newNote);
	}

	protected showAttachmentDialog(note: FileNote): void {
		const targets = [
			AttachmentTargetUtils.target(note.id, AttachmentTargetType.note)
		];

		if (!!this.userTarget) {
			targets.push( AttachmentTargetUtils.target(this.userTarget.id, AttachmentTargetType.client) );
		}

		if (!!this.providerTarget) {
			targets.push( AttachmentTargetUtils.target(this.providerTarget.id, AttachmentTargetType.provider) );
		}

		OverlayService.showTemplate(this.attachmentDialog, {"targets": targets, "files": note.attachments});
	}

	protected getIcon(file: AttachedFile): IconName {
		return this._fileMimeTypes.getIcon(file.mimeType) as IconName;
	}

	public downloadDocument(document: AttachedFile) {
		FileManager.downloadFile(this.attachmentsService, document);
	}

	protected async saveAttachments($event: any) {
		if ($event.fileManager && $event.targets && $event.targets.length > 0) {
			OverlayService.show(`Saving${Utils.ellipsis}`);
			try {

				const result = await this.attachmentsService.saveAttachments($event.targets, $event.fileManager);
				this.attachmentsChanged.emit();
				OverlayService.hide();
			}
			catch (e) {
				console.log(e);
				OverlayService.hide();
				OverlayService.showError("Error", "Unable to save attachment");
			}
		}
	}

	public get target(): User | Provider {
		return this.userTarget || this.providerTarget;
	}

	public countAttachments(note: FileNote): number {
		const replies = note.replies || [];
		return replies.reduce( (acc, cur) => {
			const attachments = cur.attachments || [];
			acc += attachments.length;
			return acc;
		}, (note.attachments || []).length);
	}

	public noteStatus: (FileNote) => string = FileNoteStatus.toString;
	public readonly noteStatusCompleted: FileNoteStatus = FileNoteStatus.completed;
}
