import { AttachedFile } from './files';
import moment from 'moment';

export enum InvoiceStatus {
	predraft, draft, locked, submitted, reconciled, paid, investigation
}

export namespace InvoiceStatus {
	export function toString(status: InvoiceStatus): string {
		switch (status) {
			case InvoiceStatus.predraft: return "Pre-Draft";
			case InvoiceStatus.draft: return "Draft";
			case InvoiceStatus.locked: return "Locked";
			case InvoiceStatus.submitted: return "Submitted";
			case InvoiceStatus.reconciled: return "Reconciled";
			case InvoiceStatus.paid: return "Paid";
			case InvoiceStatus.investigation: return "Investigation";
		}
	}

	export function parse(value: string): InvoiceStatus {
		if(!value) return undefined;
		switch (value.toLowerCase()) {
			case "predraft": return InvoiceStatus.predraft;
			case "draft": return InvoiceStatus.draft;
			case "locked": return InvoiceStatus.locked;
			case "submitted": return InvoiceStatus.submitted;
			case "reconciled": return InvoiceStatus.reconciled;
			case "paid": return InvoiceStatus.paid;
			case "investigation": return InvoiceStatus.investigation;
			default: return undefined;
		}
	}

	export function allValues(): InvoiceStatus[] {
		return [
			InvoiceStatus.predraft,
			InvoiceStatus.draft,
			InvoiceStatus.locked,
			InvoiceStatus.submitted,
			InvoiceStatus.reconciled,
			InvoiceStatus.paid,
			InvoiceStatus.investigation
		];
	}
}

export enum LineItemStatus {
	submitted, rejected, discrepancy, reconciled, locked, paid, error, cancelled, deleted
}

export class LineItemStatusUtils {
	private constructor() {}

	private static descriptions = new Map<LineItemStatus, string>([
		[LineItemStatus.submitted, "Submitted"],
		[LineItemStatus.rejected, "Rejected"],
		[LineItemStatus.discrepancy, "Discrepancy"],
		[LineItemStatus.reconciled, "Reconciled"],
		[LineItemStatus.locked, "Locked"],
		[LineItemStatus.paid, "Paid"],
		[LineItemStatus.error, "Error"],
		[LineItemStatus.deleted, "Deleted"],
		[LineItemStatus.cancelled, "Cancelled"]
	]);

	private static pgEnumValues = new Map<LineItemStatus, string>([
		[LineItemStatus.submitted, "submitted"],
		[LineItemStatus.rejected, "rejected"],
		[LineItemStatus.discrepancy, "discrepancy"],
		[LineItemStatus.reconciled, "reconciled"],
		[LineItemStatus.locked, "locked"],
		[LineItemStatus.paid, "paid"],
		[LineItemStatus.error, "error"],
		[LineItemStatus.deleted, "deleted"],
		[LineItemStatus.cancelled, "cancelled"]
	]);

	private static values = new Map<string, LineItemStatus>([
		["submitted", LineItemStatus.submitted],
		["rejected", LineItemStatus.rejected],
		["discrepancy", LineItemStatus.discrepancy],
		["reconciled", LineItemStatus.reconciled],
		["locked", LineItemStatus.locked],
		["paid", LineItemStatus.paid],
		["error", LineItemStatus.error],
		["deleted", LineItemStatus.deleted],
		["cancelled", LineItemStatus.cancelled]
	]);

	private static allowedTransitions = new Map<LineItemStatus, LineItemStatus[]> ([
		[LineItemStatus.submitted, [LineItemStatus.discrepancy, LineItemStatus.reconciled]],
		[LineItemStatus.discrepancy, [LineItemStatus.rejected, LineItemStatus.reconciled]],
		[LineItemStatus.rejected, []],
		[LineItemStatus.reconciled, [LineItemStatus.locked]]
	]);

	static toString(status: LineItemStatus): string {
		return LineItemStatusUtils.descriptions.get(status);
	}

	static toPostgresEnum(status: LineItemStatus): string {
		return LineItemStatusUtils.pgEnumValues.get(status);
	}

	static parse(value: string): LineItemStatus {
		return LineItemStatusUtils.values.get(value);
	}

	static allValues(): LineItemStatus[] {
		return Array.from(LineItemStatusUtils.descriptions.keys());
	}
}

export enum GSTCode {
	P1, P2, P5
}

export class GSTCodeUtils {
	private constructor() {}

	private static descriptions = new Map<GSTCode, string>([
		[GSTCode.P1, "P1 - GST Included"],
		[GSTCode.P2, "P2 - GST Free"],
		[GSTCode.P5, "P5 - GST Out of scope"]
	]);

	private static pgEnumValues = new Map<GSTCode, string>([
		[GSTCode.P1, "P1"],
		[GSTCode.P2, "P2"],
		[GSTCode.P5, "P5"]
	]);

	private static values = new Map<string, GSTCode>([
		["P1", GSTCode.P1],
		["P2", GSTCode.P2],
		["P5", GSTCode.P5]
	]);

	static toString(gstCode: GSTCode): string {
		return GSTCodeUtils.descriptions.get(gstCode);
	}

	static toPostgresEnum(gstCode: GSTCode): string {
		return GSTCodeUtils.pgEnumValues.get(gstCode);
	}

	static parse(value: string): GSTCode {
		return GSTCodeUtils.values.get(value);
	}

	static allValues(): GSTCode[] {
		return Array.from(GSTCodeUtils.descriptions.keys());
	}
}

export class InvoiceStatusUtils {
	private constructor() {}

	private static descriptions = new Map<InvoiceStatus, string>([
		[InvoiceStatus.predraft, "Pre-Draft"],
		[InvoiceStatus.draft, "Draft"],
		[InvoiceStatus.locked, "Locked"],
		[InvoiceStatus.submitted, "Submitted"],
		[InvoiceStatus.reconciled, "Reconciled"],
		[InvoiceStatus.paid, "Paid"],
		[InvoiceStatus.investigation, "Investigation"]
	]);

	private static pgEnumValues = new Map<InvoiceStatus, string>([
		[InvoiceStatus.predraft, "predraft"],
		[InvoiceStatus.draft, "draft"],
		[InvoiceStatus.locked, "locked"],
		[InvoiceStatus.submitted, "submitted"],
		[InvoiceStatus.reconciled, "reconciled"],
		[InvoiceStatus.paid, "paid"],
		[InvoiceStatus.investigation, "investigation"]
	]);

	private static values = new Map<string, InvoiceStatus>([
		["predraft", InvoiceStatus.predraft],
		["draft", InvoiceStatus.draft],
		["locked", InvoiceStatus.locked],
		["submitted", InvoiceStatus.submitted],
		["reconciled", InvoiceStatus.reconciled],
		["paid", InvoiceStatus.paid],
		["investigation", InvoiceStatus.investigation]
	]);

	static toString(paymentMethod: InvoiceStatus): string {
		return InvoiceStatusUtils.descriptions.get(paymentMethod);
	}

	static toPostgresEnum(paymentMethod: InvoiceStatus): string {
		return InvoiceStatusUtils.pgEnumValues.get(paymentMethod);
	}

	static parse(value: string): InvoiceStatus {
		return InvoiceStatusUtils.values.get(value);
	}

	static allValues(): InvoiceStatus[] {
		return Array.from(InvoiceStatusUtils.descriptions.keys());
	}
}

export interface InvoiceLineItem {
	id: string;
	date: Date;
	supportItem: string;
	supportItemNumber: string;
	serviceName: string;
	quantity: string;
	rate: number;
	total: number;
	discrepancy: number;
	gstApplicable: boolean;
	gstCode: GSTCode;
	status: LineItemStatus;
	claimReference: string;
	sortOrder?: number;
}

export namespace InvoiceLineItem {
	export function toJSON(lineItem: InvoiceLineItem): any {
		return {
			"id": lineItem.id,
			"date": moment(lineItem.date).format('YYYY-MM-DD'),
			"serviceId": lineItem.supportItem,
			"quantity": Number(lineItem.quantity),
			"rate": Number(lineItem.rate),
			"total": Number(lineItem.total),
			"discrepancy": lineItem.discrepancy,
			"gstCode": GSTCodeUtils.toPostgresEnum(lineItem.gstCode),
			"status": LineItemStatusUtils.toPostgresEnum(lineItem.status),
			"claimReference": lineItem.claimReference,
			"sortOrder": lineItem.sortOrder
		};
	}
}

export interface InvoiceHeader {
	id: string;
	clientId: string;
	clientName: string;
	providerId: string;
	providerName: string;
	adjustment: number;
	comment: string;
	gst?: number;
	date: Date;
	readonly paymentDate?: Date;
	invoiceNumber: string;
	status: InvoiceStatus;
	total: number;
	clientNDISNumber?: string;
	providerAbn?: string;
	created?: Date;
	createdBy?: string;
	createdByName?: string;
	reimbursement: boolean;
	reimbursementRecipient?: string;
	payee?: string;
	numLines?: number;
	ticketNumber: string;
	deleted?: boolean;
	investigationTypeId: string;
	investigationTypeName: string;
	investigationDate: Date;
	investigationUserId: string;
	finalisedBy?: string;
	finalisedByUsername?: string;
	finalisedDate?: Date;
}

export interface Invoice extends InvoiceHeader {
	lineItems: InvoiceLineItem[];
	nonActiveLineItems: InvoiceLineItem[];
	attachments?: Set<AttachedFile>;
	submissions: any[];
	// locked: boolean; // Invoice has been (or is in the process of being) submitted to the government.
	// closed: boolean; // Data entry for this invoice is complete, and it can be submitted to the government
}

export type SupportDataItem = {"id": string, "name": string, "sortOrder": number};
// export type SupportDataItemArray = SupportDataItem[];

export type SupportDataType = {"id": string, "tag": string, "name": string, "sortOrder": number, "supportData": SupportDataItem[]};
// export type SupportDataTypeArray = SupportDataTypeItem[];

export namespace Invoice {
	export const investigationTypeDuplicate: string = 'ec360219-415f-32ef-9d1a-25708d8c90ef';

	export function patchToJSON(src: any): any {
		let patch = {
			"clientId": src.clientId,
			"providerId": src.providerId,
			"invoiceNumber": src.invoiceNumber,
			"comment": src.comment,
			"total": Number(src.total),
			"gst": Number(src.gst),
			"date": moment(src.date).format('YYYY-MM-DD'),
			"reimbursement": typeof src.reimbursement == 'boolean'  ? src.reimbursement : undefined,
			"reimbursementRecipient": typeof src.reimbursementRecipient == 'string' ? src.reimbursementRecipient : undefined,
			"status": InvoiceStatusUtils.toPostgresEnum(src.status),
			"lineItems": undefined,
			"ticketNumber": src.ticketNumber
		};

		if (src.lineItems) {
			patch.lineItems = src.lineItems.map( InvoiceLineItem.toJSON )
		}

		if (!/^\d{7}$/.test(src.ticketNumber)) {
			delete patch.ticketNumber;
		}

		Object.keys(patch).forEach(key => {
			if (src[key] === undefined) {
				delete patch[key];
			}
		});

		return patch;
	}
}

export class InvoiceUtils {

	private static parseLineItems(invoice: Invoice, data: any): void {
		data.lineItems.forEach( lineItemData => {
			invoice.lineItems.push({
				"id": lineItemData.id,
				"date": moment(lineItemData.date).toDate(),
				"supportItem": lineItemData.supportItem,
				"supportItemNumber": lineItemData.supportItemNumber,
				"serviceName": lineItemData.supportItemName,
				"quantity": lineItemData.quantity,
				"rate": lineItemData.rate,
				"total": lineItemData.total,
				"discrepancy": lineItemData.discrepancy,
				"gstApplicable": undefined,
				"gstCode": GSTCodeUtils.parse(lineItemData.gstCode),
				"status": LineItemStatusUtils.parse(lineItemData.status),
				"claimReference": lineItemData.claimReference,
				"sortOrder": Number(lineItemData.sortOrder) || null
			});
		});
	}

	private static parseNonActiveLineItems(invoice: Invoice, data: any): void {
		data.nonActiveLineItems.forEach( nonActiveLineItemData => {
			invoice.nonActiveLineItems.push({
				"id": nonActiveLineItemData.id,
				"date": moment(nonActiveLineItemData.date).toDate(),
				"supportItem": nonActiveLineItemData.supportItem,
				"supportItemNumber": nonActiveLineItemData.supportItemNumber,
				"serviceName": nonActiveLineItemData.supportItemName,
				"quantity": nonActiveLineItemData.quantity,
				"rate": nonActiveLineItemData.rate,
				"total": nonActiveLineItemData.total,
				"discrepancy": nonActiveLineItemData.discrepancy,
				"gstApplicable": undefined,
				"gstCode": GSTCodeUtils.parse(nonActiveLineItemData.gstCode),
				"status": LineItemStatusUtils.parse(nonActiveLineItemData.status),
				"claimReference": nonActiveLineItemData.claimReference
			});
		});
	}

	private static parseAttachments(invoice: Invoice, data: any): void {
		const attachments = data.attachments || [];
		attachments.forEach( attachmentData => {
			const attachedFile = AttachedFile.parse(attachmentData);
			invoice.attachments.add( attachedFile );
		});
	}

	static jsonToInvoice(data: any): Invoice {
		let invoice: Invoice = {
			"id": data.id,
			"clientId": data.clientId,
			"clientName": data.clientName,
			"clientNDISNumber": data.clientNDISNumber,
			"providerId": data.providerId,
			"providerName": data.providerName,
			"providerAbn": data.providerAbn,
			"invoiceNumber": data.invoiceNumber,
			"comment": data.comment,
			"adjustment": data.adjustment,
			"date": moment(data.invoiceDate).toDate(),
			"created": moment(data.createdDate).toDate(),
			"createdBy": data.createdBy,
			"createdByName": data.createdByName,
			"reimbursement": data.reimbursement,
			"reimbursementRecipient": data.reimbursementRecipient,
			"payee": data.payee,
			"total": data.total,
			"gst": data.gst,
			"lineItems": [],
			"nonActiveLineItems": [],
			"attachments": new Set<AttachedFile>(),
			"submissions": data.submissions,
			"status": InvoiceStatusUtils.parse(data.status),
			"ticketNumber": data.ticketNumber,
			"deleted": !!data.deleted,
			"investigationTypeId": data.investigationTypeId || undefined,
			"investigationTypeName": data.investigationTypeName,
			"investigationDate": data.investigationDate === null ? null : moment(data.investigationDate).toDate(),
			"investigationUserId" : data.investigationUserId
			// "locked": !!data.locked,
			// "closed": !!data.closed
		};

		InvoiceUtils.parseLineItems(invoice, data);
		InvoiceUtils.parseNonActiveLineItems(invoice, data);
		InvoiceUtils.parseAttachments(invoice, data);

		return invoice;
	}

	static invoiceToJson(invoice: Invoice): any {

		// NB we ignore attached files in this method, as they are sent to a separate microservice
		return {
			"id": invoice.id,
			"clientId": invoice.clientId,
			"providerId": invoice.providerId,
			"invoiceNumber": invoice.invoiceNumber,
			"comment": invoice.comment,
			"total": Number(invoice.total),
			"gst": Number(invoice.gst),
			"adjustment": Number(invoice.adjustment),
			"date": moment(invoice.date).format('YYYY-MM-DD'),
			"created": invoice.created !== undefined ? moment(invoice.created).format() : undefined,
			"reimbursement": !!invoice.reimbursement,
			"reimbursementRecipient": !!invoice.reimbursement ? invoice.reimbursementRecipient : undefined,
			"payee": invoice.payee,
			// "closed": !!invoice.closed,
			"submissions": invoice.submissions,
			"status": InvoiceStatusUtils.toPostgresEnum(invoice.status),
			"ticketNumber": invoice.ticketNumber,
			"investigationTypeId": invoice.investigationTypeId,
			"lineItems": Array.from(invoice.lineItems.values()).map( InvoiceLineItem.toJSON ),
			"nonActiveLineItems": Array.from(invoice.nonActiveLineItems.values()).map( InvoiceLineItem.toJSON ),
			"investigationDate": invoice.investigationDate,
			"investigationUserId": invoice.investigationUserId,
			"finalisedBy": invoice.finalisedBy || null,
			"finalisedByUsername": invoice.finalisedByUsername || null,
			"finalisedDate": invoice.finalisedDate ? moment(invoice.finalisedDate).format() : null,
		};
	}

	static newInvoice(): Invoice {
		return {
			"id": undefined,
			"clientId": undefined,
			"clientName": undefined,
			"providerId": undefined,
			"providerName": undefined,
			"invoiceNumber": undefined,
			"comment": undefined,
			"adjustment": undefined,
			"date": undefined,
			"created": undefined,
			"reimbursement": undefined,
			"payee": undefined,
			"total": undefined,
			"gst": undefined,
			"lineItems": [],
			"nonActiveLineItems": [],
			"attachments": new Set<AttachedFile>(),
			"submissions": undefined,
			"status": InvoiceStatus.draft,
			"ticketNumber": undefined,
			"investigationTypeId": undefined,
			"investigationTypeName": undefined,
			"investigationDate": null,
			"investigationUserId": null
			// "locked": false,
			// "closed": false
		};
	}

	static newLineItem(): InvoiceLineItem {
		return {
			"id": undefined,
			"date": undefined,
			"supportItemNumber": undefined,
			"supportItem": undefined,
			"serviceName": undefined,
			"quantity": undefined,
			"rate": undefined,
			"total": undefined,
			"discrepancy": undefined,
			"gstApplicable": undefined,
			"gstCode": GSTCode.P2,
			"status": undefined,
			"claimReference": undefined
		};
	}

	static cloneInvoice(src: Invoice): Invoice {
		let result = {...src};

		result.lineItems = [];
		src.lineItems.forEach( lineItem => {
			result.lineItems.push( InvoiceUtils.cloneLineItem(lineItem) );
		});

		result.nonActiveLineItems = [];
		src.nonActiveLineItems.forEach( nonActiveLineItem => {
			result.nonActiveLineItems.push( InvoiceUtils.cloneLineItem(nonActiveLineItem) );
		});

		result.attachments = new Set<AttachedFile>();
		src.attachments.forEach( attachment => {
			const clonedAttachment = AttachedFile.fromMetaData(attachment);
			result.attachments.add( attachment );
		});

		return result;
	}

	static cloneLineItem(src: InvoiceLineItem): InvoiceLineItem {
		return {...src};
	}
}
