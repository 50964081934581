import { BankInformation, BankInformationUtils } from "@classes/bankinfo";

export interface ReimbursementRecipient {
	id: string;
	name: string;
	bankInfo: BankInformation;
}

export namespace ReimbursementRecipient {
	export function parse(src: any): ReimbursementRecipient {
		return {
			"id": src.id,
			"name": src.name,
			"bankInfo": BankInformationUtils.from(src.bankInfo)
		};
	}
}
