export enum AttachmentType {
	invoice, statement, quote, ndsp_doc, rAndN, support_letter, ndis_plan, sa, other
}

export namespace AttachmentType {

	const values = new Map<AttachmentType, string>([
		[AttachmentType.invoice, "Invoice"],
		[AttachmentType.statement, "Statement"],
		[AttachmentType.quote, "Quote"],
		[AttachmentType.ndsp_doc, "NDSP Document"],
		[AttachmentType.rAndN, "R&N"],
		[AttachmentType.support_letter, "Support Letter"],
		[AttachmentType.ndis_plan, "NDIS Plan"],
		[AttachmentType.sa, "Service Agreement"],
		[AttachmentType.other, "Other"]
	]);

	const pgEnums = new Map<AttachmentType, string>([
		[AttachmentType.invoice, "invoice"],
		[AttachmentType.statement, "statement"],
		[AttachmentType.quote, "quote"],
		[AttachmentType.ndsp_doc, "ndsp_doc"],
		[AttachmentType.rAndN, "r&n"],
		[AttachmentType.support_letter, "support_letter"],
		[AttachmentType.ndis_plan, "ndis_plan"],
		[AttachmentType.sa, "sa"],
		[AttachmentType.other, "other"]
	]);

	const parseMap = new Map<string, AttachmentType>([
		["invoice", AttachmentType.invoice],
		["statement", AttachmentType.statement],
		["quote", AttachmentType.quote],
		["ndsp_doc", AttachmentType.ndsp_doc],
		["r&n", AttachmentType.rAndN],
		["support_letter", AttachmentType.support_letter],
		["ndis_plan", AttachmentType.ndis_plan],
		["sa", AttachmentType.sa],
		["other", AttachmentType.other]
	]);

	export function parse(src: string): AttachmentType {
		return parseMap.get(src);
	}

	export function toString(value: AttachmentType): string {
		return values.get(value);
	}

	export function toPostgresEnum(value: AttachmentType): string {
		return pgEnums.get(value);
	}

	export const allValues: AttachmentType[] = Array.from(values.keys());
}
