export type EnumMetadata = [string, string]; // pg Enum value, human-readable description

export abstract class EnumUtils<T> {

	private descriptions: Map<T, string>;
	private pgEnumValues: Map<T, string>;
	private values: Map<string, T>;

	public toString(value: T): string {
		return this.descriptions.get(value);
	}

	public toPostgresEnum(value: T): string {
		return this.pgEnumValues.get(value);
	}

	public parse(value: string): T {
		return this.values.get(value);
	}

	public allValues(): T[] {
		return Array.from(this.descriptions.keys());
	}

	protected constructor(src: Map<T, EnumMetadata>) {
		const pgEnums: [T, string][] = Array.from( src.keys() ).map( (key: T) => {
			const result: [T, string] = [key, src.get(key)[0]];
			return result;
		} );

		const descriptions: [T, string][] = Array.from( src.keys() ).map( (key: T) => {
			const result: [T, string] = [key, src.get(key)[1]];
			return result;
		} );

		const values: [string, T][] = Array.from( src.keys() ).map( (key: T) => {
			const result: [string, T] = [src.get(key)[0], key];
			return result;
		} );

		this.descriptions = new Map<T, string>(descriptions);
		this.pgEnumValues = new Map<T, string>(pgEnums);
		this.values = new Map<string, T>(values);
	}
}
