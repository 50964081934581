/**
* Validates an ABN according to the specification found at: https://abr.business.gov.au/Help/AbnFormat
* To verify an ABN:
*
*   1. Subtract 1 from the first (left-most) digit of the ABN to give a new 11 digit number
*   2. Multiply each of the digits in this new number by a "weighting factor" based on its position as shown in the table below
*   3. Sum the resulting 11 products
*   4. Divide the sum total by 89, noting the remainder
*   5. If the remainder is zero the number is a valid ABN

*/
export class ABNValidator {

	private readonly formatTest = /^\d{11}$/;

	private getWeighting(idx: number): number {
		switch (idx) {
			case 0: return 10;
			default: return idx * 2 - 1;
		}
	}

	private validateNumber(value: string): boolean {
		let digits: number[] = `${value}`.split('').map( item => Number(item) );

		// Step 1
		digits[0] = digits[0] - 1;

		// Step 2
		digits = digits.map( (digit, idx) => { return digit * this.getWeighting(idx); } );

		// Step 3
		const sum = digits.reduce( (acc, cur) => {
			return acc + cur;
		}, 0);

		// Step 4
		const remainder = sum % 89;

		return remainder === 0;
	}

	public validate(value: string): boolean {

		// Check that the supplied value contains only 11 digits
		if (!this.formatTest.test(value)) {
			return false;
		}

		return this.validateNumber(value);
	}
}
