import { Injectable } from '@angular/core';
import { RestService, API } from './rest.service';
import { AttachedFile } from '@classes/files';
import { KeyValuePair } from  '@classes/keyvaluepair';
import { InvoiceStatus } from "@classes/invoices";

import moment from "moment";

export interface InvoiceServiceDelivered {
	client: string;
	clientName: string;
	serviceDelivered: string;
	serviceName: string;
	claimReference?: string;
	account: string;
	accountName: string;
	provider: KeyValuePair;
	total: number;
	serviceDate: Date;
	comments: string;
	invoiceId?: string;
	reimbursement: boolean;
}

export interface Invoice {
	id: string;
	extractId: string;
	client: string; // This is the salesforce ID
	clientId: string; // This is the Postgres GUID
	clientName: string;
	account: string;
	accountName: string;
	provider: KeyValuePair;
	providerName: string;
	invoiceNumber: string;
	comment: string;
	total: number;
	servicesTotalInPlan?: number;
	gst?: number;
	adjustment: number;
	date: Date;
	servicesDelivered: Set<string>;
	reimbursement: boolean;
	status?: InvoiceStatus;
}

export interface EnriteExtract {
	id: string;
	name: string;
}

export interface ExtractBundle {
	extract: EnriteExtract;
	services: InvoiceServiceDelivered[]
}

export interface InvoiceBundle {
	invoice: Invoice;
	servicesDelivered: InvoiceServiceDelivered[]
	attachments: AttachedFile[]
}

@Injectable({ providedIn: 'root' })
export class InvoiceService {

	constructor(private restService: RestService) {}

	private mapServices(src): InvoiceServiceDelivered[] {
		src = src || [];
		return src.map( item =>{

			let result: InvoiceServiceDelivered = {
				"invoiceId": item.invoiceId,
				"client": item.client,
				"clientName": `${item.firstName} ${item.lastName}`,
				"account": item.account,
				"accountName": item.accountName,
				"provider": {
					"id": item.providerId,
					"value": item.providerName
				},
				"total": Number(item.total),
				"claimReference": item.claimReference || undefined,
				"serviceDate": new Date(item.serviceDate),
				"comments": item.comments,
				"serviceDelivered": item.serviceDelivered,
				"serviceName": item.serviceName,
				"reimbursement": item.reimbursement
			};

			return result;
		});
	}

	private mapInvoices(src): Invoice[] {
		src = src || [];
		return src.map( item => {
			return {
				"id": item.id,
				"extractId": item.extract,
				"client": item.client,
				"clientId": item.clientId,
				"clientName": item.clientName,
				"account": item.account,
				"provider": {
					"id": item.providerId,
					"value": item.providerName
				},
				"providerName": item.providerName,
				"accountName": item.accountName,
				"invoiceNumber": item.invoiceNumber,
				"adjustment": item.adjustment,
				"comment": item.comment,
				"total": item.total,
				"gst": item.gst,
				"servicesTotalInPlan": item.servicesTotalInPlan,
				"date": item.invoiceDate,
				"reimbursement": item.reimbursement,
				"status": InvoiceStatus.parse(item.status),
				"servicesDelivered": new Set<string>()
			};
		});
	}

	private mapAttachments(src): AttachedFile[] {
		return src.map( item => {

			return AttachedFile.fromMetaData({
				"id": item.id,
				"name": item.name,
				"size": item.size,
				"md5": item.md5,
				"mimeType": item.mimeType
			});

		});
	}

	getInvoices(): Promise<Invoice[]> {
		return this.restService.get(API.admin, 'invoices').then( result => {
			return Promise.resolve( this.mapInvoices(result.invoices) );
		});
	}

	searchInvoices(searchText: string): Promise<Invoice[]> {
		return this.restService.get(API.admin, `invoices?q=${searchText}`).then( result => {
			return Promise.resolve( this.mapInvoices(result.invoices) );
		});
	}

	getInvoice(invoiceId: string): Promise<InvoiceBundle> {
		return this.restService.get(API.admin, `invoice/${invoiceId}`).then( response => {

			let result = {
				"invoice": {
					"id": response.invoice.id,
					"extractId": response.invoice.extract,
					"client": response.invoice.client,
					"clientId": response.invoice.clientId,
					"clientName": response.invoice.clientName,
					"account": response.invoice.account,
					"accountName": response.invoice.accountName,
					"provider": {
						"id": response.invoice.providerId,
						"value": response.invoice.providerName
					},
					"providerName": response.invoice.providerName,
					"invoiceNumber": response.invoice.invoiceNumber,
					"total": response.invoice.total,
					"gst": response.invoice.gst,
					"adjustment": response.invoice.adjustment,
					"comment": response.invoice.comment,
					"date": response.invoice.invoiceDate,
					"reimbursement": response.invoice.reimbursement || false,
					"servicesDelivered": new Set<string>(response.invoice.servicesDelivered)
				},
				"servicesDelivered": this.mapServices(response.servicesDelivered),
				"attachments": this.mapAttachments(response.attachments)
			};

			return Promise.resolve(result);
		});
	}

	unassignedServicesDelivered(): Promise<InvoiceServiceDelivered[]> {
		return this.restService.get(API.admin, 'transactions/unassigned').then( result => {

			return Promise.resolve( this.mapServices(result) );

		});
	}

	loadServicesFromExtract(extractId): Promise<ExtractBundle> {
		return this.restService.get(API.admin, `transactions/extract/${extractId}`).then( result => {

			return Promise.resolve( {
				"extract": result.extract,
				"services": this.mapServices(result.services)
			});

		});
	}

	static emptyInvoice(): Invoice {
		return {
			"id": undefined,
			"extractId": undefined,
			"client": undefined,
			"clientId": undefined,
			"clientName": undefined,
			"account": undefined,
			"accountName": undefined,
			"provider": {
				"id": undefined,
				"value": undefined
			},
			"providerName": undefined,
			"invoiceNumber": undefined,
			"total": undefined,
			"comment": undefined,
			"adjustment": undefined,
			"gst": undefined,
			"date": undefined,
			"reimbursement": false,
			"servicesDelivered": new Set<string>()
		};
	}

	saveInvoice(invoice: Invoice, extractId: string, newFiles: AttachedFile[], deletedFiles: string[]): Promise<any> {

		return Promise.all( newFiles.map( file => file.content )).then( fileContents => {

			const fileData = newFiles.map( (file, idx) => {
				return {
					"name": file.name,
					"mimeType": file.mimeType,
					"content": fileContents[idx].replace(/^.*,/, ''), // strip out the "data:[<mediatype>][;base64]," header info
					"size": file.size,
					"md5": file.md5
				};
			});

			const servicesDelivered = Array.from( invoice.servicesDelivered.values() );
			const postData = {
				"invoice": {
					"id": invoice.id,
					"extractId": extractId,
					"client": invoice.client,
					"account": invoice.account,
					"invoiceNumber": invoice.invoiceNumber,
					"date": moment(invoice.date).toISOString(),
					"total": Number(invoice.total) || 0.00,
					"adjustment": Number(invoice.adjustment) || 0.00,
					"gst": Number(invoice.gst) || 0.00,
					"comment": invoice.comment,
					"servicesDelivered": servicesDelivered,
					"reimbursement": invoice.reimbursement
				},
				"provider": invoice.provider.id,
				"deletedFiles": deletedFiles,
				"newFiles": fileData
			};

			return this.restService.post(API.admin, 'invoice', postData).then( result => {
				return Promise.resolve(result);
			});

		});
	}

	getSupportCoordinatorInvoices(supportCoordinatorId: string): Promise<Invoice[]> {
		return this.restService.get(API.supportcoordinator, `invoices/${supportCoordinatorId}`).then( result => {
			return Promise.resolve( this.mapInvoices(result) );
		});
	}

	enriteSync(): Promise<InvoiceServiceDelivered[]> {
		return this.restService.get(API.admin, "transactions/unassigned/refresh").then( result => {
			return Promise.resolve( this.mapServices(result) );
		});
	}

	loadExtract(extractId: string): Promise<ExtractBundle> {
		return this.restService.get(API.admin, `extract/${extractId}`).then( result => {

			return Promise.resolve( {
				"extract": result.extract,
				"services": this.mapServices(result.services)
			});

			// return Promise.resolve( this.mapServices(result) );
		});
	}

	loadAttachment(attachmentId: string): Promise<any> {
		return this.restService.get(API.user, `invoice/attachment/${attachmentId}`);
	}

	deleteInvoice(invoiceId: string): Promise<boolean> {
		return this.restService.delete(API.admin, `invoice/${invoiceId}`).then( result => {
			return Promise.resolve( result.success );
		});
	}

	public async loadProviderInvoices(providerId: string, planId: string, showReimbursements: boolean=false): Promise<Invoice[]> {
		const data = await this.restService.get(API.invoices, `provider/${providerId}/plan/${planId}?reimbursements=${showReimbursements}`);
		return this.mapInvoices(data);
	}
}
