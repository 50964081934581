import { Component, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { PrivateComponent } from "@classes/private.component";
import { User } from "@classes/user";
import { MenuBuilder } from "@services/navmenu.service";
import { OverlayService } from "@services/overlay.service";

@Component({
	"selector": "support-coordinator-dashboard",
	// "styleUrls": ["./dashboard.component.scss"],
	"templateUrl": "./dashboard.component.html"
})
export class SupportCoordinatorDashboardComponent extends PrivateComponent implements OnInit {
	private _isSelf: boolean = false;
	private _masqueradeUser: User = undefined;

	get masqueradeUser(): User {
		return this._masqueradeUser;
	}

	get masqueradeUserId(): string {
		return this._masqueradeUser ? this._masqueradeUser.id : "";
	}

	get isSelf(): boolean {
		return this._isSelf;
	}

	get masqueradeUserName(): string {
		return this._masqueradeUser ? `${this._masqueradeUser.firstName} ${this._masqueradeUser.lastName}` : '';
	}

	get supportCoordinatorId(): string {
		return this.isSelf ? this.user.id : this.masqueradeUserId;
	}

	constructor(private route: ActivatedRoute) {
		super();
	}

	ngOnInit() {
		super.ngOnInit();

		this.route.params.subscribe( params => {

			const userId = params.userId || this.user.identityId;
			this._isSelf = userId === this.user.identityId;

			if (!this.isSelf) {
				this.userService.loadUser(params.userId).then( user => {
					this._masqueradeUser = user;
					this.mruService.push(user);
					this.buildMenu();
				});
			}
			else {
				this.buildMenu();
			}

		});
	}

	private buildMenu() {
		const menuBuilder = new MenuBuilder();
		if (this.isSelf) {
			menuBuilder.addRoute("user", "Account Details", "/account");
			menuBuilder.addRoute("lock", "Change Password", "/password/change");
			menuBuilder.addHandler("sign-out-alt", "Log Out", () => { this.confirmLogout(); } );
		}
		else {
			menuBuilder.addHome();
			menuBuilder.addBackButton();
			menuBuilder.addRoute("cog", "Account Details", `/account/${this.masqueradeUserId}`);
		}

		menuBuilder.done();
	}

	private confirmLogout(): void {
		OverlayService.showDialog("Confirm", "Are you sure you want to log out?", [{
			"text": "Cancel",
			"handler": () => { OverlayService.hide(); }
		}, {
			"text": "Log out",
			"handler": () => { OverlayService.hide(); this.userService.logout(); }
		}])
	}
}
