import { RestService, API } from '@services/rest.service';
import { ServiceLocator } from '@services/locator.service';
import { EInvalidArgument } from "@classes/errors";

export interface PermissionGroup {
	id: string;
	name: string;
	permissions: Map<string, string>;
}

export class EPermissionsNotLoaded extends Error {
	constructor(message?: string) {
		super(message);

		// Workaround to fix subclass of built-in classes
		// See https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
		Object.setPrototypeOf(this, EPermissionsNotLoaded.prototype);
	}
}

export class Permissions {
	private static instance: Permissions;
	private loaded: boolean = false;

	// private _data: Map<string, Map<string, string>> = new Map<string, Map<string, string>>();

	private _data: Map<string, PermissionGroup> = new Map<string, PermissionGroup>();

	private _restService: RestService;
	private _waitQueue: any[] = [];
	private _loading: boolean = false;

	private constructor() {
		this._restService = ServiceLocator.injector.get(RestService);
	}

	private static getInstance(): Permissions {
		if (Permissions.instance === undefined) {
			Permissions.instance = new Permissions();
		}
		return Permissions.instance;
	}

	public static load(): Promise<void> {
		const instance = Permissions.getInstance();
		return instance.load();
	}

	private load(): Promise<void> {

		if (this.loaded) {
			return Promise.resolve();
		}

		return new Promise( (resolve, reject) => {

			this._waitQueue.push({
				"resolve": resolve,
				"reject": reject
			});

			if (this._loading) {
				return;
			}

			this._loading = true;
			this._restService.get(API.admin, "permissions").then( result => {
				// Parse the result
				result.permissions.forEach( row => {
					const permissionGroup = {
						"id": row.groupId,
						"name": row.group,
						"permissions": new Map<string, string>()
					};

					Object.keys(row.permissions).forEach( key => {
						permissionGroup.permissions.set(key.toLowerCase(), row.permissions[key].toLowerCase());
					});
					this._data.set(row.group.toLowerCase(), permissionGroup);
				});
			}).then( () => {

				while (this._waitQueue.length) {
					const promise = this._waitQueue.pop();
					promise.resolve.call(null);
				}

				this._loading = false;
				this.loaded = true;

			}).catch( err => {

				while (this._waitQueue.length) {
					const promise = this._waitQueue.pop();
					promise.reject.call(null, err);
				}

				this._loading = false;
				this.loaded = false;
			});
		});
	}

	public static getPermissionId(groupName: string, permissionName: string): string {

		const instance = Permissions.getInstance();
		if (!instance.loaded) {
			throw new EPermissionsNotLoaded();
		}

		const group = instance._data.get(groupName.toLowerCase());
		if (group === undefined) {
			throw new EInvalidArgument("Invalid group name");
		}

		const permissionId = group.permissions.get(permissionName.toLowerCase());
		if (permissionId === undefined) {
			throw new EInvalidArgument("Invalid permission name");
		}

		return permissionId;
	}

	public static allPermissions(): PermissionGroup[] {
		const instance = Permissions.getInstance();
		if (!instance.loaded) {
			throw new EPermissionsNotLoaded();
		}

		return Array.from(instance._data.keys()).reduce( (acc, key) => {
			const group = instance._data.get(key);

			acc.push(group);
			return acc;
		}, []);
	}
}
