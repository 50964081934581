import { DateUtils } from "@classes/utils";

export interface XeroEntry {
	ContactName:       string;
	EmailAddress:      string;
	POAddressLine1:    string;
	POAddressLine2:    string;
	POAddressLine3:    string;
	POAddressLine4:    string;
	POCity:            string;
	PORegion:          string;
	POPostalCode:      string;
	POCountry:         string;
	InvoiceNumber:     string;
	Reference:         string;
	InvoiceDate:       string;
	DueDate:           string;
	InventoryItemCode: string;
	Description:       string;
	Quantity:          string;
	UnitAmount:        string;
	Discount:          string;
	AccountCode:       string;
	TaxType:           string;
	TrackingName1:     string;
	TrackingOption1:   string;
	TrackingName2:     string;
	TrackingOption2:   string;
	Currency:          string;
	BrandingTheme:     string;
};

export interface XeroInvoice {
	id: string;
	clientName: string;
	providerId: string;
	payee: string;
	providerName: string;
	invoiceNumber: string;
	total: number;
	date: Date;
	lineItemCount: number;
	hasBankDetails: boolean;
	reimbursement: boolean;
	syncRequired: boolean;
	extract: number;
}


export class XeroInvoiceUtils {
	public static parse(src: any): XeroInvoice {
		return {
			"id": src.id,
			"clientName": src.client,
			"providerId": src.providerId,
			"providerName": src.providerName,
			"invoiceNumber": src.invoiceNumber,
			"payee": src.payee,
			"total": src.total,
			"date": DateUtils.parse(src.invoiceDate, DateUtils.ISO8601msFormat),
			"lineItemCount": src.totalLineItems,
			"hasBankDetails": src.hasBankDetails,
			"reimbursement": src.reimbursement,
			"syncRequired": src.syncRequired,
			"extract": src.extract
		};
	}
}
