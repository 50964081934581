import { Component, OnInit, AfterViewInit, ViewChild, ViewChildren, HostListener, ElementRef, TemplateRef, QueryList } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrivateComponent } from "@classes/private.component";
import { UserService } from '@services/user.service';
import { User, UserType } from "@classes/user";
import { Invoice, InvoiceLineItem, InvoiceUtils, InvoiceStatus, LineItemStatus, InvoiceStatusUtils, LineItemStatusUtils, GSTCode, SupportDataType, SupportDataItem } from "@classes/invoices";
import { InvoiceService } from "@services/invoice2.service";
import { FileNotesService } from "@services/filenotes.service";
import { AttachedFile } from "@classes/files";
import { AttachmentType } from "@classes/attachmentType";
import { FileManager } from "@classes/filemanager";
import { SupportItem, SupportItemCache } from "@classes/supports";
import { Plan, PlanStatus } from "@classes/plans";
import { Utils } from "@classes/utils";
import { EUnauthorised, ErrorUtils } from "@classes/errors";
import { PlanService } from "@services/plan.service";
import { OverlayService } from "@services/overlay.service";
import { ProviderService, Provider } from "@services/provider.service";
import { UserAccountService } from "@services/accounts.service";
import { MD5 } from 'crypto-js';
import { NgForm } from '@angular/forms';
import { InvoiceValidator, InvoiceValidationItem } from "./invoice.validation";
import { FileNote } from "@classes/filenotes";
import { MenuBuilder } from "@services/navmenu.service";
import { DataExchangeService } from "@services/dataexchange.service";
import { FreshdeskService } from "@services/freshdesk.service";
import { takeUntil } from 'rxjs/operators';
import { InvoiceProviderSearchComponent } from './providerSearch/providerSearch.component';
import { InvoiceClientSearchComponent } from "./clientSearch/clientSearch.component";
import { SupportsService } from "@services/supports.service";
import { ReimbursementRecipient } from "@classes/reimbursementRecipient";
import { DateUtils } from "@classes/utils";
import { RegionUtils } from "@classes/regions";
import moment from 'moment';

import { ValidationResult } from "./lineitem/invoicelineitem.businessrules";
import { IconName } from '@fortawesome/free-solid-svg-icons';
// import { LineItemMapper, PlanBudgetMapper, ProviderBudgetMapper } from "./lineitem/invoicemappers";
//import { NG_MODEL_WITH_FORM_CONTROL_WARNING } from '@angular/forms/src/directives';

type DateIncrement = -1 | 1;
type DateIncrementUnit = 'days' | 'months';

interface LineItemWarning {
	warning: boolean;
	message: string;
}

interface DuplicateInvoice {
	id: string;
	invoiceNumber: string;
	ticketNumber: string;
}

interface InvoiceError {
	error: boolean;
	errorMessage: string;
	warningMessage: string;
	badLines: InvoiceLineItem[];
}

namespace ProviderOrReimbursementDiscrepancy {
	const systemProviders: string[] = [
		'ca90a10e-b1e1-4019-89a8-1c2a02b6d3b5',
		'29a1878c-5b41-47c3-9bbf-59a9e54f9ede'
	];

	export const providerDiscrepancyInvestigationType: string = 'eebcc47f-73bb-3302-a08d-7f7e1f191274';

	export function systemProviderSelected(providerId: string): boolean {
		return systemProviders.includes(providerId);
	}
}

@Component({
	"styleUrls": ["./editinvoice.component.scss"],
	"templateUrl": "./editinvoice.component.html"

})
export class EditInvoiceComponent extends PrivateComponent implements OnInit, AfterViewInit {

	@ViewChild(InvoiceProviderSearchComponent)
	private _providerField: InvoiceProviderSearchComponent;

	@ViewChild(InvoiceClientSearchComponent)
	private _clientField: InvoiceClientSearchComponent;

	@ViewChild('invoiceTotalControl')      private invoiceTotalControl: ElementRef;
	@ViewChild('invoiceGSTControl')        private invoiceGSTControl: ElementRef;
	@ViewChild('invoiceAdjustmentControl') private invoiceAdjustmentControl: ElementRef;
	@ViewChild('invoiceNumberControl')     private invoiceNumberControl: ElementRef;
	@ViewChild('invoiceDateControl')       private invoiceDateControl: ElementRef;
	@ViewChild('invoiceCommentControl')    private invoiceCommentControl: ElementRef;

	@ViewChild('lineItemDialog')           private lineItemDialog: TemplateRef<any>;
	@ViewChild('noPlansDialog')            private noPlansDialog: TemplateRef<any>;
	@ViewChild('keyboardShortcutsDialog')  private keyboardShortcutsDialog: TemplateRef<any>;
	@ViewChild('attachmentDialog')         private attachmentDialog: TemplateRef<any>;
	@ViewChild('clientPlansDialog')        private clientPlansDialog: TemplateRef<any>;
	@ViewChild('saveDialog')               private saveDialog: TemplateRef<any>;
	@ViewChild('reopenDialog')             private reopenDialog: TemplateRef<any>;
	@ViewChild('duplicateDialog')          private duplicateDialog: TemplateRef<any>;

	@ViewChild('invoiceForm')              private invoiceForm: NgForm;

	@ViewChildren('dialogButton')          private buttons: QueryList<any>;

	private static readonly emptyString = "";

	private _providers: Provider[] = [];
	private _invoice: Invoice = undefined;

	private _errors: InvoiceError = undefined;

	/**
	* A clone of the line items that were included in the invoice when it was loaded. These serve
	* as a reference so that we can adjust the spend on a plan when line items are edited.
	*/
	private _originalLineItems: InvoiceLineItem[] = [];
	private _originalStatus: InvoiceStatus = undefined;
	private _clients: any[] = [];
	private _submissions: any[] = [];
	private _dateFocused: boolean = false;
	private _clientPlans: Plan[] = [];
	private _cancellableDialog: boolean = false;
	private _lineItem: InvoiceLineItem = undefined;
	private _investigationUser: User = undefined;
	// private _supportItems: Map<string, SupportItem[]> = new Map<string, SupportItem[]>(); // PlanId, List of Supports
	private _dontRefocus: boolean = false;
	private _filemanager: FileManager;
	private _billingNotes: FileNote[] = [];
	private _invoiceSupportData: SupportDataType[] = undefined;
	private _invoiceDataLoaded: boolean = true;
	private _rollbackState: Invoice;

	private _supportsCache: SupportItemCache = new Map<string, SupportItem>(); // Keyed by support ID. Lists every support item on the invoice.

	private _duplicates: DuplicateInvoice[] = [];

	private _autoSave: boolean = false;
	private _manualSave: boolean = false;
	private _masterMode: boolean = false;
	private _refreshNeeded: boolean = false;

	public errors: InvoiceValidationItem[] = [];
	public warnings: InvoiceValidationItem[] = [];

	private _returnToInvestigationsPage: boolean = false;

	public readonly invoiceStatuses: InvoiceStatus[] = InvoiceStatus.allValues();

	private get saving(): boolean {
		return this._autoSave || this._manualSave;
	}

	public get masterMode(): boolean {
		return this._masterMode;
	}

	public get hasBeenInvestigated() {
		return this.model.invoice.investigationUserId !== null || this.model.invoice.investigationDate !== null;
	}

	public invoiceStatusName(status: InvoiceStatus): string {
		return InvoiceStatus.toString(status);
	}

	private oldInvoiceData: string = undefined;
	private _reimbursementOptions: ReimbursementRecipient[] = [];
	private _reimbursementRecipientsWithBankAccount: ReimbursementRecipient[] = [];
	private _reimbursementRecipientsWithoutBankAccount: ReimbursementRecipient[] = [];
	/**
	* Used for the ngxMask component
	*/
	currencyPattern = {
		"0": {
			"pattern": new RegExp('\d+(\.\d{0,2})?')
		}
	};

	public readonly defaultAttachmentType = AttachmentType.invoice;
	public readonly lineItemStatusPaid = LineItemStatus.paid;
	public readonly lineItemStatusReconciled = LineItemStatus.reconciled;

	private _loading: boolean = false;

	private lastFocusedControl: any = undefined; // Used to re-focus a control when a dialog is cancelled

	/**
	* Map of keyboard shortcuts that we'll implement, and their associated handler functions
	*/
	private readonly keyMap: Map<string, () => void> = new Map<string, () => void>([
		['r', this.toggleReimbursement],
		['l', this.addLineItem],
		['b', this.goBack],
		['s', this.showSaveInvoiceDialog],
		['a', this.showAttachmentDialog]
	]);

	private readonly focusMap: Map<string, ElementRef> = new Map<string, ElementRef>();

	public invoiceStatus: any = InvoiceStatusUtils.allValues().reduce( (acc, cur) => {
		acc[InvoiceStatusUtils.toPostgresEnum(cur)] = cur;
		return acc;
	}, {});

	get investigationTypes(): SupportDataItem[] {
		if (!!this._invoiceSupportData) {
			let tag = 'invType';
			let invType: SupportDataType[] = this._invoiceSupportData.filter( (supportDataTypeItem: SupportDataType) => supportDataTypeItem.tag === tag);
			return invType[0].supportData;
		}
	}

	/**
	* Stores the focus state of the "Invoice Date" UI form field. Used so that we can intercept
	* keyboard events when this field is focused, and change date based on up and down arrow keys.
	* Triggered by the "focus" and "blur" events on the control.
	*
	* @param {boolean} value The focus state for the control.
	*/
	set dateFocused(value: boolean) {
		this._dateFocused = value;
	}

	/**
	* Sets the focus to the specified UI form field. Cancels event propagation from a keyboard event if supplied.
	* Initially coded to handle keyboard shortcuts to allow quick focus of controls.
	*
	* @param {ElementRef} control The UI control to focus
	*/
	private focusControl(control: ElementRef) {
		if (control && control.nativeElement) {
			setTimeout(() => { control.nativeElement.focus(); }, 0);
		}
	}

	get fileManager(): FileManager {
		return this._filemanager;
	}

	get clientPlans(): Plan[] {
		return this._clientPlans;
	}

	get isPace(): boolean {
		if(this._clientPlans && this._clientPlans.length){
			return this._clientPlans.filter( plan => plan.pace ).length > 0;
		}
		return false;
	}

	get invoiceDataLoaded(): boolean {
		return this._invoiceDataLoaded;
	}

	get billingNotes(): FileNote[] {
		return this._billingNotes;
	}

	get billEditable(): boolean {
		return !this.model.invoice.deleted && ([InvoiceStatus.draft, InvoiceStatus.investigation].includes(this.model.invoice.status) || this.masterMode);
	}

	protected lineItemStatus(status: LineItemStatus): string {
		return LineItemStatusUtils.toString(status);
	}

	public readonly maxNoteContentChars = 140;

	public openNote(note: FileNote) {

		const path = note.providerId ? `/provider/${note.providerId}/${note.id}` : `/account/${note.clientId}/${note.id}`;
		const url = this.router.serializeUrl(
			this.router.createUrlTree([path])
		);

		window.open(url, '_notewin');
	}

	public get ticketUrl(): string {
		if(this.model.invoice.ticketNumber.length > 7 && this.model.invoice.ticketNumber.startsWith('0'))
			return ""; //'https://salesforceredirector.mpm.com.au/' + this.model.invoice.ticketNumber;
		else
			return this.freshdeskService.getTicketUrl(this.model.invoice.ticketNumber) || "";
	}

	/**
	* Increments or decrements a date value in the model by either a day or a month.
	* Values changed are either the invoice date, or the date of the current invoice line item.
	*
	* @param {DateIncrement} direction The direction to change the date (either +1 or -1)
	* @param {DateIncrementUnit} unit The date unit (either day or month) to modify
	*/
	private changeDate(direction: DateIncrement, unit: DateIncrementUnit): void {
		const dateStr = this.model.invoice.date;
		const now = moment().startOf('day');

		if (dateStr === undefined) {
			this.model.invoice.date = now.toDate();
			this.model.invoiceDate = now.format('DDMMYYYY');
			return;
		}

		let newDateValue = moment(this.model.invoice.date).add(direction, unit);
		if (newDateValue.isAfter(now)) {
			newDateValue = now;
		}

		this.model.invoice.date = newDateValue.toDate();
		this.model.invoiceDate = newDateValue.format('DDMMYYYY');
	}

	/**
	* Stops propagation and default behaviour of a keyboard event
	* Used to intercept keyDown events on the host, and prevent the browser from applying default behaviour
	* (eg Ctrl+R should not reload the page)
	*
	* @param {KeyboardEvent} event
	*/
	private cancelEvent(event: KeyboardEvent): void {
		event.stopImmediatePropagation();
		event.stopPropagation();
		event.preventDefault();
	}

	/**
	* Intercepts keyDown events to cancel browser default behaviour for certain keys.
	* Currently cancelling default behaviour for:
	* - Ctrl+L (default behaviour = focus location bar)
	* - Ctrl+R (default behaviour = reload page)
	* - Ctrl+A (default behaviour = select all)
	*/
	@HostListener('window:keydown', ['$event'])
	interceptBrowserShortcuts(event: KeyboardEvent) {

		// If the invoice is already deleted, just process the browser default handler for the key event
		if (this.model.invoice.deleted) {
			return;
		}

		// Don't process any keystrokes if we're saving the invoice
		if (this.saving) {
			this.cancelEvent(event);
			return;
		}

		// Don't do anything if a dialog window is being displayed, except handle the "escape" key to close the dialog
		if (OverlayService.visible) {

			if (event.key === 'Escape') {
				event.preventDefault();
				event.stopPropagation();
				OverlayService.hide();
				this.refocusLastControl();
			}
			return;
		}

		const shortcutKeys = Array.from(this.keyMap.keys()).concat( Array.from( this.focusMap.keys() ) );
		if (event.ctrlKey && shortcutKeys.includes(event.key)) {

			// if (document.activeElement instanceof HTMLElement) {
			// 	document.activeElement.blur();
			// }

			this.cancelEvent(event);

			if (this.focusMap.has(event.key)) {
				let control: ElementRef = this.focusMap.get(event.key);
				this.focusControl(control);
			}
		}
	}


	/**
	* Handles keyUp events to implement keyboard shortcuts on page
	*/
	@HostListener('window:keyup', ['$event'])
	keyboardShortcut(event: KeyboardEvent) {

		// If the invoice is already deleted, just process the browser default handler for the key event
		if (this.model.invoice.deleted) {
			return;
		}

		// Don't process any further shortcut keys if a dialog is being displayed
		if (OverlayService.visible) {
			return;
		}

		// Date controls can use up/down arrows as a shortcut to increment and decrement their value
		if (this._dateFocused) {
			switch (event.key) {
				case "ArrowUp": {
					this.changeDate(+1, event.ctrlKey ? 'months' : 'days');
					break;
				}
				case "ArrowDown": {
					this.changeDate(-1, event.ctrlKey ? 'months' : 'days');
					break;
				}
			}
		}

		// Handle any other keyboard shortcuts here
		const handler = this.keyMap.get(event.key);
		if (event.ctrlKey && handler !== undefined) {
			this.cancelEvent(event);
			handler.call(this);
		}
	}

	goBack(): void {
		const route = this._returnToInvestigationsPage ? "/investigation" : "/billing";
		this.router.navigate([route]);
	}

	get hasPlans(): boolean {
		return this._clientPlans.length > 0;
	}

	/**
	* Stores a reference to the last focused control when a dialog is shown. Used to return focus to this control
	* when the dialog is closed.
	*
	* @param {FocusEvent} event
	*/
	setLastFocusedControl(event: FocusEvent): void {
		this.lastFocusedControl = event.target;
		this.autosave();
	}

	/**
	* Loads all plans for the selected client. Filters out any plans that are in the "Proposed" state. This
	* should leave just current, terminated and other past plans.
	*
	* @param {string} clientId
	*/
	private async loadPlans(clientId: string): Promise<void> {
		if (!clientId) {
			return;
		}

		this._loading = true;
		const plans = await this.planService.listPlans(clientId);
		this._clientPlans = plans.filter( plan => plan.status !== PlanStatus.proposed ).map( Plan.clone );
		this._loading = false;
	}

	private async loadBillingNotes(clientId: string): Promise<void> {
		if (!clientId) {
			return;
		}


		const notes = await this.fileNotesService.getBillingNotes(clientId);
		this._billingNotes = this._billingNotes.filter( x => !!x.providerId ); // ⟵ Remove existing client billing notes
		if (notes.length > 0) {
			this._billingNotes = this._billingNotes.concat(notes); // ⟵ Append new billing notes for the client
		}
	}

	private async getInvoiceSupportData(): Promise<void> {
		this._invoiceSupportData = await this.invoiceService.getInvoiceSupportData('edit');
	}

	/**
	* Toggles the state of the "reimbursement" flag on the current invoices.
	* Called in response to a click on the form's checkbox, or on a shortcut key press (ctrl+R)
	*/
	private toggleReimbursement(): void {
		if (this.billEditable) {
			this.model.invoice.reimbursement = !this.model.invoice.reimbursement;
		}
	}

	/**
	* Selection of handlers that respond to events triggered by the user editing the invoice form.
	*/
	public readonly formEvents = {
		"clientSelected": async (client) => {
			this.model.clientName = client.name;
			this.model.invoice.clientId = client.id;
			this.model.invoice.clientName = client.name;
			this.model.clientNDISNumber = client.ndisNumber;
			this.model.dob = client.dob; //orashid

			let user;
			[user, this._reimbursementOptions] = await Promise.all([
				this.userService.loadUser(client.id),
				this.invoiceService.getReimbursementOptions(client.id)
			]);
			this.model.clientState = user.stateAndRegion;

			if (this.focusMap.has('2')) {
				let control: ElementRef = this.focusMap.get('2');
				this.focusControl(control);
			}

			// this.focusControl(this._providerField.element);
			this.loadPlans(client.id);
			this.loadBillingNotes(client.id);
			this.autosave();
		},
		"clientKeyUp": () => {
			if (this.model.clientName !== this.model.invoice.clientName) {
				this.model.invoice.clientId = undefined;
				this.model.invoice.clientName = undefined;
				this.model.clientNDISNumber = undefined;
				this.model.clientState = undefined;
				this._clientPlans = [];
			}
		},
		"providerSelected": (provider) => {
			this.model.providerName = provider.name;
			this.model.invoice.providerId = provider.id;
			this.model.invoice.providerName = provider.name;
			this.model.providerABN = provider.abn;

			if (!!this._invoiceSupportData) {
				let tag = 'invType';
				let invType: SupportDataType[] = this._invoiceSupportData.filter( (supportDataTypeItem: SupportDataType) => supportDataTypeItem.tag === tag);
				return invType[0].supportData;
			}

			if (ProviderOrReimbursementDiscrepancy.systemProviderSelected(provider.id)) {
				this.model.invoice.investigationTypeId = ProviderOrReimbursementDiscrepancy.providerDiscrepancyInvestigationType;
			}

			this.focusControl(this.invoiceNumberControl);
			this.loadProviderBillingNotes(provider.id);
			this.autosave();
		},
		"providerKeyUp": () => {
			if (this.model.providerName !== this.model.invoice.providerName) {
				this.model.invoice.providerId = undefined;
				this.model.invoice.providerName = undefined;
				this.model.providerABN = undefined;
			}
		},
		"setDate": () => {
			try {
				const m = moment(this.model.invoiceDate, 'DDMMYYYY', true);
				if (m.isValid()) {
					this.model.invoice.date = m.startOf('day').toDate();
					this.runDuplicateCheck();
				}
				else {
					this.model.invoice.date = undefined;
				}
			}
			catch (e) {
				this.model.invoice.date = undefined;
			}
		}
	};

	private clearProviderBillingNotes() {
		this._billingNotes = this._billingNotes.filter( x => !!x.clientId ) // ⟵ Remove any billing notes for the previous provider
	}

	private async loadProviderBillingNotes(providerId: string) {

		if (!providerId) {
			return;
		}

		const notes = await this.fileNotesService.providerBillingNotes(providerId, false);
		this.clearProviderBillingNotes();
		if (notes.length > 0) {
			this._billingNotes = this._billingNotes.concat(notes); // ⟵ Append billing notes for the new provider
		}
	}

	private calcInvoiceHash(invoice: any): string {
		return MD5(JSON.stringify(InvoiceUtils.invoiceToJson(invoice))).toString();
	}

	private get invoiceChanged(): boolean {
		this.oldInvoiceData = this.oldInvoiceData || this.calcInvoiceHash(this._invoice || {});
		const newInvoiceData = this.calcInvoiceHash(this.model.invoice);
		return newInvoiceData.localeCompare(this.oldInvoiceData) !== 0;
	}

	public get duplicates(): DuplicateInvoice[] {
		const dupes = this._duplicates;
		return dupes.slice(0, this.duplicateDisplayLimit);
	}

	public get duplicateCount(): number {
		return this._duplicates.length;
	}

	public get duplicateDisplayLimit(): number {
		return 10;
	}

	private async saveInvoiceFunc(): Promise<void> {
		const startTime = this.isNewInvoice ? this.model.startTime : undefined;

		try {
			const response = await this.invoiceService.saveInvoice(this.model.invoice, null, startTime);
			this.planService.updateCache(response.invoice.clientId, response.plans);
			this._clientPlans = response.plans;
			this.oldInvoiceData = this.calcInvoiceHash(this.model.invoice);

			if (this.isNewInvoice) {
				OverlayService.hide();
				this.router.navigate([`/billing/${response.invoice.id}`]);
			}
			else {
				this._autoSave = false;
				this.model.invoice = InvoiceUtils.cloneInvoice(response.invoice);
				if (this._refreshNeeded) {
					this.loadInvoiceProvider(this.model.invoice.id).then(() => {
						return this.loadInvoice(this.model.invoice.id);
					}).then(() => {
						this._refreshNeeded = false;
						OverlayService.hide();
					});
				}
			}

		}
		catch (e) {
			this._autoSave = false;
			throw e;
		};
	}

	private validate() {
		if (this.model.invoice.clientId !== undefined && !this._loading) {
			const result = InvoiceValidator.validate(this.model.invoice, this._clientPlans, this._supportsCache, this._reimbursementOptions);

			this.errors = result.filter( item => item && item.isError );
			this.errorLines = this.errors.flatMap( item => item.affectedLines );
			this.warnings = result.filter( item => item && item.isWarning );
			this.warningLines = this.warnings.flatMap( item => item.affectedLines );
		}
	}

	private autosave() {
		// validate before saving
		this.validate();

		if (!this._masterMode && this.canSaveInvoice && !this.saving && (this.isNewInvoice || this.invoiceChanged)) {

			this._autoSave = true;
			this.model.invoice.gst = this.invoiceGST;

			if (this.isNewInvoice) {
				OverlayService.show(`Saving${Utils.ellipsis}`);
				this.invoiceService.checkForDuplicates(this.model.invoice).then( duplicates => {

					return duplicates.length > 0 ? this.showDuplicateWarningDialog(duplicates) : Promise.resolve(false);

				}).then( dontSave => {

						if (dontSave) {
							this._autoSave = false;
							OverlayService.hide();
							return;
						}

						this._refreshNeeded = false;
						this.saveInvoiceFunc();
				}).catch( err => {
						this._autoSave = false;
						OverlayService.hide();
						OverlayService.showError("Error", ErrorUtils.getErrorMessage(err, "An unknown error occurred"));
						return;
				});

			}
			else {
				if (this._refreshNeeded) 
					OverlayService.show();
				this.saveInvoiceFunc();
			}
		}
	}



	public model: any = {
		"clientName": undefined,
		"clientNDISNumber": undefined,
		"providerName": undefined,
		"providerABN": undefined,
		"invoiceDate": undefined,
		"dob": undefined,  //orashid
		"invoice": InvoiceUtils.newInvoice()
	};

	get isNewInvoice(): boolean {
		return this._invoice && !this._invoice.id;
	}

	get clients(): any[] {
		return this._clients;
	}

	get submissions(): any[] {
		return this._submissions;
	}

	get providers(): Provider[] {
		return this._providers;
	}

	get originalLineItems(): InvoiceLineItem[] {
		return this._originalLineItems;
	}

	get investigationUserFullName(): string {
		if(this._investigationUser !== undefined)
			return this._investigationUser.firstName + " " + this._investigationUser.lastName;
		else
			return "";
	}

	// get supportItems(): SupportItem[] {
	// 	return this._supportItems;
	// }

	constructor(
		private invoiceService: InvoiceService,
		private userAccountService: UserAccountService,
		public userService: UserService,
		private providerService: ProviderService,
		private planService: PlanService,
		private fileNotesService: FileNotesService,
		private freshdeskService: FreshdeskService,
		private supportsService: SupportsService,
		private route: ActivatedRoute) {

		super();
		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Billing', 'Access billing');
		this._filemanager = new FileManager(undefined, this.planService);
		this._filemanager.fileAdded$.pipe(takeUntil(this.unsubscribe)).subscribe( this.fileAdded.bind(this) );
	}

	private fileAdded(file: AttachedFile) {
		file.attachmentType = AttachmentType.invoice;
	}

	private buildMenu(): void {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();
		menuBuilder.addHandler('keyboard', "Keyboard Shortcuts", () => { this.showKeyboardShortcuts(); });
		menuBuilder.addHandler('list-ul', "Client Plans", () => { this.showPlans(); }, null, () => {
			const plans = this._clientPlans;
			return plans.length > 0;
		});
		menuBuilder.addHandler('toggle-off', 'Override Mode Off', () => { this.enableMasterMode(); }, null, () => {
			return this.hasPermission('Billing', 'Master Mode') && !this.isNewInvoice && !this._masterMode;
		});
		menuBuilder.addHandler('toggle-on', 'Override Mode On', () => { this.enableMasterMode(); }, null, () => {
			return this.hasPermission('Billing', 'Master Mode') && !this.isNewInvoice && this._masterMode;
		});

		// menuBuilder.addHandler('exclamation-triangle', "Duplicate Check", () => {
		// 	this.invoiceService.checkForDuplicates(this.model.invoice, this._filemanager);
		// });

		menuBuilder.done();
	}

	private enableMasterMode() {
		this._masterMode = !this._masterMode;
		if (this._masterMode) {
			this._rollbackState = InvoiceUtils.cloneInvoice(this.model.invoice);
		}
		else {

			const rollbackHash = this.calcInvoiceHash(this._rollbackState);
			const currentHash = this.calcInvoiceHash(this.model.invoice);
			if (currentHash !== rollbackHash) {

				OverlayService.showDialog("Confirm", "You have unsaved changes to this bill.", [{
					"text": "Discard changes",
					"handler": () => {
						this.model.invoice = this._rollbackState;
						this._rollbackState = undefined;
						OverlayService.hide();
					}
				}, {
					"text": "Save changes",
					"handler": () => {
						OverlayService.hide();
						this.masterModeSaveInvoice();
					}
				}])

			}
			else {
				this.model.invoice = this._rollbackState;
				this._rollbackState = undefined;
				OverlayService.hide();
			}
		}
	}

	private showKeyboardShortcuts(): void {
		OverlayService.showTemplate(this.keyboardShortcutsDialog);
	}

	// private loadClients(): Promise<any> {
	// 	return this.userAccountService.getAccounts().then( users => {
	// 		this._clients = users.filter( user => user.accountType === UserType.Participant).map( user => {
	// 			return {
	// 				"id": user.id,
	// 				"name": `${user.firstName} ${user.lastName}`,
	// 				"ndisNumber": user.ndisNumber
	// 			};
	// 		});
	// 	});
	// }

	private showPlans(): void {
		OverlayService.showTemplate(this.clientPlansDialog);
	}

	private loadInvoiceProvider(invoiceId: string): Promise<any> {
		return this.providerService.getProviderForInvoice(invoiceId).then( provider => {
			this.model.provider = provider;
			return Promise.resolve();
		});
	}

	private _client: User;
	public get client(): User {
		return this._client;
	}


	// private loadProviders(): Promise<any> {
	// 	return this.providerService.getProviders().then( providers => {
	// 		this._providers = providers;
	// 		return Promise.resolve();
	// 	});
	// }

	private async loadPlanSupports(plan: Plan): Promise<any> {
		return;
		// return this.planService.getSupportItems(plan).then( supports => {
		// 	this._supportItems.set(plan.id, supports);
		// });
	}

	private cloneInvoiceData(invoice: Invoice) {
		this._invoice = invoice;

		// A clone of the line items on the invoice
		this._originalLineItems = invoice.lineItems.map( InvoiceUtils.cloneLineItem );

		this.model.invoice = InvoiceUtils.cloneInvoice(invoice);
		this.model.clientName = invoice.clientName;
		this.model.clientNDISNumber = invoice.clientNDISNumber;
		this.model.providerName = invoice.providerName;
		this.model.providerABN = invoice.providerAbn;
		this.model.invoiceDate = moment(invoice.date).format('DDMMYYYY');


		if (invoice.attachments && invoice.attachments.size > 0) {
			this._filemanager.attachedFiles = Array.from( invoice.attachments );
		}

		if (invoice.submissions) {
			this._submissions = invoice.submissions;
		}
	}

	private async runDuplicateCheck(): Promise<void> {
		if (!this.model.invoice.id) {
			return
		}

		try {
			const duplicates = await this.invoiceService.checkForDuplicates(this.model.invoice, this._filemanager);
			this._duplicates = <DuplicateInvoice[]>duplicates;
		}
		catch (e) {
			// Ignore
		}
	}

	private async loadClient(id: string): Promise<void> {
		this._client = await this.userService.loadUser(id);
		this.model.clientState = this._client.stateAndRegion;
		this.model.dob = this._client.dob; //orashid
	}

	private async loadSupports(): Promise<void> {
		const promises = this.model.invoice.lineItems.map( (lineItem: InvoiceLineItem) => {
			return this.supportsService.getSupport(lineItem.supportItem);
		});

		const supports: SupportItem[] = await Promise.all(promises);
		supports.forEach( support => {
			if (!!support) {
				this._supportsCache.set(support.id, support);
			}
		});
	}

	private loadInvoice(invoiceId: string): Promise<any> {

		return this.invoiceService.loadInvoice(invoiceId).then( async invoice => {
			
			if (invoice.deleted && !this.hasPermission('Billing', 'View deleted bills')) {
				return Promise.reject(new EUnauthorised("You do not have permission to view deleted bills"));
			}

			const supportsPromises = invoice.lineItems.map( async lineItem => {
				const support = await this.supportsService.getSupport(lineItem.supportItem);
				if (!!support) {
					this._supportsCache.set(support.id, support);
				}
			});

			await Promise.all(supportsPromises);

			this.cloneInvoiceData(invoice);
			this._originalStatus = this.model.invoice.status;

			const promises = [
				this.loadClient(this.model.invoice.clientId),
				this.loadBillingNotes(this.model.invoice.clientId),
				this.loadPlans(this.model.invoice.clientId),
				this.loadProviderBillingNotes(this.model.invoice.providerId),
				this.runDuplicateCheck(),
				this.getInvoiceSupportData(),
				this.loadReimbursementOptions(this.model.invoice.clientId),
				this.loadSupports()
			];

			return Promise.all(promises);

		}).then( () => {

			// Used to prevent the autosave feature toggling more than once and creating multiple, new invoices.
			this._autoSave = false;

			const plans: Set<Plan> = new Set<Plan>();
			this.model.invoice.lineItems.forEach( (lineItem: InvoiceLineItem) => {
				const plan = this.findPlanFromLineItemDate(lineItem.date);
				if (plan && !plans.has(plan)) {
					plans.add(plan);
				}
			});

			// this._supportItems.clear();
			// const promises = Array.from(plans).map( plan => {
			// 	return this.loadPlanSupports(plan);
			// });

			// return Promise.all(promises);

		}).then( () => {

			this.adjustPlanTotals();
			return Promise.resolve();

		}).then( () => {

			this._invoiceDataLoaded = true;
			this.validate();
			return Promise.resolve();

		}).catch( err => {

			if (err instanceof EUnauthorised) {
				console.log(err.message);
			}
			else {
				console.log(err);
			}
			this.router.navigate(['billing']);
		});
	}

	private adjustPlanTotals(): void {
		// At this point, we should have loaded the plans, supports for the plans, and the invoice data (including line items).
		// We're now going to iterate through the line items on the invoice and deduct their value from the totals for the relevant plan.

		this._originalLineItems.forEach( (lineItem: InvoiceLineItem) => {

			// Step 1: Find the plan that covers the line item service date
			const plan: Plan = this.findPlanFromLineItemDate(lineItem.date);
			if (!plan) {
				return;
			}

			const isDraftItem = this.model.invoice.status === InvoiceStatus.draft
			                  && ((lineItem.status === undefined) || (!([LineItemStatus.paid, LineItemStatus.deleted, LineItemStatus.discrepancy].includes(lineItem.status))));

			// Step 2: Adjust the overall plan budget by the amount for the current line item
			plan.budget.remaining += lineItem.total;
			if (lineItem.status === LineItemStatus.paid) {
				plan.budget.paid -= lineItem.total;
			} else {
				plan.budget.pending -= lineItem.total;

				if (isDraftItem) {
					plan.budget.draft -= lineItem.total;
				}
			}

			// Step 3: Adjust the budget for each category

			// 3a: Find the support for the line item
			// const supportItem = this._supportItems.get(plan.id).find( item => item.id === lineItem.supportItem );
			const supportItem = this._supportsCache.get(lineItem.supportItem);

			if (supportItem && supportItem.supportCategoryId) {

				// Find the budget for the support category in the plan
				// const planCategory = plan.supportCategories.get(supportItem.supportCategoryId);
				const planCategory = plan.supportCategories.find( category => category.supportCategory.id === supportItem.supportCategoryId);

				// 3b: Adjust the category budget
				if (planCategory && planCategory.categoryBudget) {

					if (lineItem.status === LineItemStatus.paid) {
						planCategory.categoryBudget.paid -= lineItem.total;
					} else {
						planCategory.categoryBudget.pending -= lineItem.total;
					}

					if (isDraftItem) {
						planCategory.categoryBudget.draft -= lineItem.total;
					}


					// // 3c: Determine if the provider has quarantined funds
					// const providers = planCategory.providers || [];
					// const provider = providers.find( item => item.providerId === this.model.invoice.providerId );
					// if (provider) {
					// 	planCategory.categoryBudget.quarantineSpend -= lineItem.total;

					// 	if (lineItem.status === LineItemStatus.paid) {
					// 		provider.budget.paid -= lineItem.total;
					// 	} else {
					// 		provider.budget.pending -= lineItem.total;
					// 	}

					// 	if (isDraftItem) {
					// 		provider.budget.draft -= lineItem.total;
					// 	}

					// }
					// else {
					// 	planCategory.categoryBudget.exQuarantineSpend -= lineItem.total;
					// }
				}
			}

		});
	}

	private newInvoice(): Promise<any> {
		this._invoice = InvoiceUtils.newInvoice();

		this.model = {
			"clientName": undefined,
			"clientNDISNumber": undefined,
			"providerName": undefined,
			"providerABN": undefined,
			"invoiceDate": undefined,
			"provider": undefined,
			"clientState": undefined,
			"dob": undefined,  //orashid
			"invoice": InvoiceUtils.newInvoice(),
			"startTime": new Date()
		};

		this._clientPlans = [];
		this._lineItem = undefined;

		return Promise.resolve();
	}

	private loadInvestigationUser() {
		if(typeof this.model.invoice.investigationUserId === 'string') {
			this.userService.loadUser(this.model.invoice.investigationUserId).then((value) => {
				this._investigationUser = value;
			});
		}
	}

	private async loadInvoiceData(routeParams) {
		this._invoiceDataLoaded = false;
		let promises = [];
		const invoiceId = routeParams.id;
		// promises.push( this.loadClients() );
		promises.push( this.supportsService.initialise() );
		promises.push( invoiceId === 'new' ? this.getInvoiceSupportData() : this.loadInvoiceProvider(invoiceId) );
		promises.push( invoiceId === 'new' ? this.newInvoice() : this.loadInvoice(invoiceId) );

		OverlayService.show();
		try {
			await Promise.all(promises);
		}
		catch (err) {
			console.log(err);
		}

		if (this.hasBeenInvestigated)
			this.loadInvestigationUser();

		const ctx = {
			"lineItem": InvoiceUtils.newLineItem(),
			"lineItems": this._originalLineItems,
			"providerId": this.model.invoice.providerId,
			"plans": this.clientPlans,
			"clientDOB": this.dob,
		};

		OverlayService.hide();
	}

	/**
	* 1. Set input focus on the client name / NDIS number field.
	* this._clientField is an NgxTypeAheadComponent instance, and therefore we need to dig down a level to access the "nativeElement" property
	*
	* 2. Subscribe to a list of changes to dialog buttons
	* Set keyboard focus to the first button if one exists
	*/
	ngAfterViewInit() {
		this.focusControl(this._clientField.inputElement);

		// Look for changes to the list of "dialogButton" elements. If we find any, set focus to the first one in the list
		this.buttons.changes.subscribe( list => {
			if (list.length > 0) {
				const button = list.first;
				setTimeout(() => {
					button.nativeElement.focus();
				}, 0);
			}
		});

		this.focusMap.set('1', this._clientField.inputElement);
		this.focusMap.set('2', this._providerField.inputElement);
		this.focusMap.set('3', this.invoiceNumberControl);
		this.focusMap.set('4', this.invoiceDateControl);
		this.focusMap.set('5', this.invoiceTotalControl);
		// this.focusMap.set('6', this.invoiceGSTControl);
		this.focusMap.set('6', this.invoiceAdjustmentControl);
		this.focusMap.set('7', this.invoiceCommentControl);
	}

	ngOnInit() {
		super.ngOnInit();

		if (this.user) {
			this.buildMenu();
			this.route.params.subscribe( params => this.loadInvoiceData(params) );
		}

		this._returnToInvestigationsPage = DataExchangeService.get("investigations", true) || false;
	}

	/**
	* Converts a value to a number. Returns zero if the input is not a number, or cannot be converted to a number
	*
	* @param {number|string} value The input value
	* @return {number}
	*/
	private numberValue(value: number | string): number {
		if (!value) {
			return 0;
		}

		const result = Number(value);
		if (isNaN(result)) {
			return 0;
		}

		return result;
	}

	get invoiceTotal(): number {
		return this.numberValue(this.model.invoice.total) + this.numberValue(this.model.invoice.gst) + this.numberValue(this.model.invoice.adjustment);
	}

	/**
	* Formatting function for NDIS number in the ngxTypeahead dropdown list.
	* Returns the ndisNumber as string if defined, or "Unknown" otherwise.
	*
	* @param {string} value The value of the ndisNumber field on the client record
	* @return {string}
	*/
	protected getNDISNumber(value: string): string {
		return value || "Unknown";
	}

	/**
	* Creates a new invoice line item and opens the "line item editor" dialog
	*/
	protected addLineItem(): void {
		if (!this.billEditable || this.masterMode) {
			return;
		}
		if (!this.model.invoice.clientId) {
			OverlayService.showError("Error", "Please select the client before adding line items");
			return;
		}
		if (!this.model.invoice.providerId) {
			OverlayService.showError("Error", "Please select the provider before adding line items");
			return;
		}
		if (!this.model.invoice.id) {
			OverlayService.showError("Error", "Please save this bill before adding line items");
			return;
		}
		if (document.activeElement instanceof HTMLElement) {
			document.activeElement.blur();
		}
		const ctx = {
			"lineItem": InvoiceUtils.newLineItem(),
			"lineItems": this._originalLineItems,
			"providerId": this.model.invoice.providerId,
			"plans": this.clientPlans,
			"clientDOB": this.dob,
		};
		OverlayService.showTemplate(this.lineItemDialog, ctx);
	}

	protected closeDialogWindow(): void {
		OverlayService.hide();
	}

	protected cancelDialog(): void {
		OverlayService.hide();
		this._lineItem = undefined;
		this._cancellableDialog = false;
		this._dontRefocus = false;
		if (this.lastFocusedControl !== undefined) {
			setTimeout(() => {
				this.lastFocusedControl.focus();
				this.lastFocusedControl = undefined;
			}, 0);
		}
	}

	private removeLineItem(lineItem: InvoiceLineItem, doSave: boolean = false): void {
		let index = this.model.invoice.lineItems.findIndex(currentLineItem => currentLineItem.id == lineItem.id);

		if (index > -1) {
			this.model.invoice.lineItems.splice(index, 1);
		} else {
			index = this.model.invoice.nonActiveLineItems.findIndex(currentLineItem => currentLineItem.id == lineItem.id);
			
			if (index > -1)
				this.model.invoice.nonActiveLineItems.splice(index, 1);
		}
		
		if (doSave) {
			this.autosave();
		}
	}

	// private hasError(lineItem: InvoiceLineItem): boolean {
	// 	return this.errors.badLines.includes(lineItem);
	// }

	private sortLineItems(): void {
		const lineItems: InvoiceLineItem[] = Array.from(this.model.invoice.lineItems);
		const nonActiveLineItems: InvoiceLineItem[] = Array.from(this.model.invoice.nonActiveLineItems);

		this.model.invoice.lineItems = new Set(lineItems.sort( (a, b) => a.sortOrder - b.sortOrder ));
		this.model.invoice.nonActiveLineItems = new Set(nonActiveLineItems.sort( (a, b) => a.sortOrder - b.sortOrder ));
	}

	private updateLineItem(oldLineItem: InvoiceLineItem, newLineItem): void {
		let index = this.model.invoice.lineItems.findIndex(currentLineItem => currentLineItem.id == oldLineItem.id);

		if (index > -1) {
			this.model.invoice.lineItems[index] = newLineItem;
			return;
		}

		index = this.model.invoice.nonActiveLineItems.findIndex(currentLineItem => currentLineItem.id == oldLineItem.id);

		if (index > -1) {
			this.model.invoice.nonActiveLineItems[index] = newLineItem;
		}
	}

	protected saveLineItem($event: InvoiceLineItem): void {
		if (this._lineItem !== undefined) {
			//this.removeLineItem(this._lineItem);
			this.updateLineItem(this._lineItem, $event);

			this._lineItem = undefined;
		} else if ([LineItemStatus.cancelled, LineItemStatus.rejected].includes($event.status)) {
			this.model.invoice.nonActiveLineItems.push( $event );
		}
		else {
			this.model.invoice.lineItems.push( $event );
		}

		//this.sortLineItems();
		this.model.invoice.lineItems.sort( (a, b) => a.sortOrder - b.sortOrder );
		this.model.invoice.nonActiveLineItems.sort( (a, b) => a.sortOrder - b.sortOrder );

		if (!!$event.supportItem && !this._supportsCache.get($event.supportItem)) {
			this.supportsService.getSupport($event.supportItem).then( supportItem => {
				this._supportsCache.set($event.supportItem, supportItem);

				this.autosave();
			});
		} else {
			this.autosave();
		}
	}

	private async deleteInvoice() {
		OverlayService.show();
		try {
			await this.invoiceService.deleteInvoice(this.model.invoice);
			OverlayService.hide();
			this.router.navigate(["/billing"]);
		}
		catch (e) {
			OverlayService.hide();
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "Unable to delete bill"));
		}
	}

	protected confirmDeleteInvestigationInvoice() {
		const lineItems: InvoiceLineItem[] = this.model.invoice.lineItems;
		const nonActive: InvoiceLineItem[] = this.model.invoice.nonActiveLineItems;

		if (!this._masterMode && lineItems.concat(nonActive).filter( lineItem => lineItem.status !== LineItemStatus.cancelled ).some( lineItem => !!lineItem.claimReference )) {
			OverlayService.showError("Error", "Can't delete this bill because one or more line items have been attached to an extract");
			return;
		}

		this.confirmDeleteInvoice();
	}

	protected confirmDeleteInvoice(status?: InvoiceStatus): void {
		const statusString = status ? InvoiceStatus.toString(status).toLowerCase() : null;
		const msg = status ? [`This bill has been ${statusString}.`, 'Are you sure you want to delete it?'].join('<br />') : "Are you sure you want to delete this bill?";
		OverlayService.showDialog("Confirm Delete", msg, [{
			"text": "Don't Delete",
			"handler": () => { OverlayService.hide(); }
		}, {
			"text": "Delete",
			"handler": () => {
				OverlayService.hide();
				this.deleteInvoice();
			}
		}]);
	}

	protected confirmDeleteLineItem(lineItem: InvoiceLineItem): void {
		OverlayService.showDialog("Confirm Delete", "Are you sure you want to delete the line item?", [{
			"text": "Don't Delete",
			"handler": () => { OverlayService.hide(); }
		}, {
			"text": "Delete",
			"handler": () => {
				OverlayService.hide();
				this.removeLineItem(lineItem, true);
			}
		}]);
	}

	protected editLineItem(lineItem: InvoiceLineItem): void {
		this._lineItem = lineItem;
		this._dontRefocus = false;

		const otherLineItems = this._originalLineItems.filter(currentLineItem => {
			return currentLineItem.id !== lineItem.id;
		});

		OverlayService.showTemplate(this.lineItemDialog, {
			"lineItem": InvoiceUtils.cloneLineItem(lineItem), // Edit a copy of the line item, not the original
			"lineItems": otherLineItems,
			"providerId": this.model.invoice.providerId,
			"plans": this.clientPlans,
			"clientDOB": this.dob,
		});
	}

	protected toIcon(icon: string): IconName {
		return icon as IconName;
	}

	protected lineItemDialogClosed(addAnotherLineItem) {
		this._lineItem = undefined;
		this._cancellableDialog = false;
		this._dontRefocus = false;

		if (addAnotherLineItem) {

			// If we're adding another line item, re-open the dialog
			// Using a timeout to work around keyboard focus issues.
			setTimeout(() => { this.addLineItem(); }, 0);
		}
		else {
			// Otherwise, attempt to set keyboard focus to the last control on the main screen
			//this.refocusLastControl();
			this.runDuplicateCheck();
		}
	}

	private refocusLastControl(): void {
		if (this.lastFocusedControl !== undefined) {
			setTimeout(() => {
				this.lastFocusedControl.focus();
				this.lastFocusedControl = undefined;
			}, 0);
		}
	}

	private lockInvoice(): void {
		if (this.canFinaliseInvoice) {
			this.model.invoice.status = InvoiceStatus.locked;
			this.saveInvoice(true);
		}
		else {
			OverlayService.showError("Error", "Cannot finalise this invoice. Please fix errors and try again");
		}
	}

	private setStatus(status: InvoiceStatus) {

		this._rollbackState = InvoiceUtils.cloneInvoice(this.model.invoice);

		// Can only switch to the allowed statuses here
		const allowedStatus = [InvoiceStatus.paid, InvoiceStatus.reconciled];
		if (!allowedStatus.includes(status)) {
			return;
		}

		switch (status) {
			case InvoiceStatus.paid:
				this.model.invoice.lineItems.forEach( (lineItem: InvoiceLineItem) => {
					lineItem.status = LineItemStatus.paid;
				});
				break;

			case InvoiceStatus.reconciled:
				this.model.invoice.lineItems.forEach( (lineItem: InvoiceLineItem) => {

					if ([null, undefined, LineItemStatus.locked, LineItemStatus.submitted].includes(lineItem.status)) {
						lineItem.status = LineItemStatus.reconciled;
					}
				});
				break;
		}

		this.model.invoice.status = status;
		this.masterModeSaveInvoice();
	}

	/**
	* Can only reconcile an invoice if all it's active line items have a claim reference
	*/
	private get canReconcile(): boolean {
		const lineItems = this.model.invoice.lineItems;
		return lineItems.every( (lineItem: InvoiceLineItem) => {

			if ([null, undefined, LineItemStatus.locked, LineItemStatus.submitted].includes(lineItem.status)) {
				return !!lineItem.claimReference;
			}

			return true;
		});
	}

	private async loadReimbursementOptions(clientId) {
		this._reimbursementOptions = await this.invoiceService.getReimbursementOptions(clientId);
		this._reimbursementRecipientsWithBankAccount = this._reimbursementOptions.filter( x => !!x.bankInfo );
		this._reimbursementRecipientsWithoutBankAccount = this._reimbursementOptions.filter( x => !x.bankInfo );
	}

	private findPlanForLineItem(plans: Plan[], lineItem: InvoiceLineItem): Plan {
		if (!lineItem || !lineItem.date) {
			return undefined;
		}

		const lineItemDate = moment(lineItem.date);

		const allowedPlanStatuses = this._masterMode
		                          ? [PlanStatus.current, PlanStatus.expired, PlanStatus.terminated, PlanStatus.deceased]
		                          : [PlanStatus.current, PlanStatus.expired, PlanStatus.deceased];

		const matchingPlans = this._clientPlans.filter(plan => {
			const startDate = moment(plan.startDate).startOf('day');
			const endDate = moment(plan.endDate).endOf('day');

			return lineItemDate.isBetween(startDate, endDate, null, '[]') && allowedPlanStatuses.includes(plan.status);
		});


		return matchingPlans.length === 1 ? matchingPlans.pop() : undefined;
	}


	/**
	* Iterate through the line items and change the supports associated with them if necessary.
	* This can be required if the client is changed and the new client's plans are for
	* a different service region.
	*
	* A good example of this is when an invoice imported from CSV has "Unknown Client" updated.
	*/
	private async adjustSupportRegions() {
		const plans = this._clientPlans;
		const lineItems: InvoiceLineItem[] = this.model.invoice.lineItems;

		const promises = lineItems.map( async lineItem => {
			const plan = this.findPlanForLineItem(plans, lineItem);
			if (plan) {
				const supportItem = await this.findSupportItem(plan, lineItem);
				if (supportItem) {
					lineItem.supportItem = supportItem.id;
					this._supportsCache.set(supportItem.id, supportItem);
				}
			}
		});

		await Promise.all(promises);
	}

	private async findSupportItem(plan: Plan, lineItem: InvoiceLineItem): Promise<SupportItem> {
		const foundSupports = await this.supportsService.getSupportsFor(plan.region, lineItem.date, lineItem.supportItemNumber);
		if (Array.isArray(foundSupports) && foundSupports.length === 1) {
			return foundSupports[0];
		}
	}

	public async setClient(value: User) {
		if (!value) {
			this.model.clientName = undefined;
			this.model.invoice.clientId = undefined;
			this.model.invoice.clientName = undefined;
			this.model.clientNDISNumber = undefined;
			this.model.dob = undefined; //orashid
			this._client = undefined;
			return;
		}

		if (this._client !== value) {

			this.model.clientName = `${value.firstName} ${value.lastName}`;
			this.model.invoice.clientId = value.id;
			this.model.invoice.clientName = `${value.firstName} ${value.lastName}`;
			this.model.clientNDISNumber = value.ndisNumber;
			this._client = value;
			this.model.dob = value.dob; //orashid

			// const user = await this.userService.loadUser(client.id);
			this.model.clientState = value.stateAndRegion;
			
			if (this.focusMap.has('2')) {
				let control: ElementRef = this.focusMap.get('2');
				this.focusControl(control);
			}
			
			// this.focusControl(this._providerField.element);
			await this.loadPlans(value.id);
			await this.adjustSupportRegions();
			this.loadBillingNotes(value.id);
			this.loadReimbursementOptions(this.model.invoice.clientId);
			this.autosave();


			if (this.model.invoice.providerId || this.model.invoice.total) {
				this.runDuplicateCheck();
			}
		}

		this.validate();
	}

	public setProvider(value: Provider) {
		if (this.model.provider !== value) {
			this.model.provider = value;
			this.model.invoice.providerId = !!value ? value.id : undefined;
			this.model.providerName = !!value ? value.name : undefined; // Don't think this is used any more

			if (!!value) {
				if (ProviderOrReimbursementDiscrepancy.systemProviderSelected(value.id)) {
					this.model.invoice.investigationTypeId = ProviderOrReimbursementDiscrepancy.providerDiscrepancyInvestigationType;
				}

				if (!!value.id) {
					this.loadProviderBillingNotes(value.id);
				}

				if (this.model.invoice.invoiceNumber || this.model.invoice.total) {
					this.runDuplicateCheck();
				}

				this._refreshNeeded = true;
			}
			else {
				this.clearProviderBillingNotes();
				
			}
			this.autosave();
		}
	}

	public showInvoiceStatusDialog(status: InvoiceStatus): void {
		// Can only switch to the allowed statuses here
		const allowedStatus = [InvoiceStatus.paid, InvoiceStatus.reconciled];
		if (!allowedStatus.includes(status)) {
			return;
		}

		if (status === InvoiceStatus.reconciled && !this.canReconcile) {
			OverlayService.showError("Error", "Cannot mark this bill as reconciled because not all line items have a claim reference");
			return;
		}

		const statusString = InvoiceStatus.toString(status).toLowerCase();

		OverlayService.showDialog(`Mark bill as ${statusString}`, `Please confirm that you want to mark this bill<br />(and all it's active line items) as ${statusString}`, [{
			"text": "Cancel",
			"handler": () => { OverlayService.hide(); }
		}, {
			"text": `Mark as ${statusString}`,
			"handler": () => { this.setStatus(status); }
		}]);
	}

	public showSaveInvoiceDialog(): void {
		if (this._masterMode) {
			this.masterModeSaveInvoice();
		}
		else if (!OverlayService.visible && this.canSaveInvoice && this.billEditable) {
			OverlayService.showTemplate(this.saveDialog);
		}
	}

	private showReopenInvoiceDialog(): void {

		if (!OverlayService.visible && this.canReopenInvoice) {
			OverlayService.showTemplate(this.reopenDialog);
		}
	}

	private showDuplicateWarningDialog(duplicates: string[]) {
		return new Promise<boolean>( (resolve, reject) => {

			OverlayService.showTemplate(this.duplicateDialog, {"duplicates": duplicates, "isNew": this.isNewInvoice, "resolve": (proceed: boolean) => {
				resolve(!proceed);
			}});
		});
	}

	private async hasDuplicateInvoiceNumber(): Promise<boolean> {
		if ([InvoiceStatus.locked, InvoiceStatus.investigation].includes(this.model.invoice.status)) {

			try {
				const duplicates = await this.invoiceService.checkForDuplicates(this.model.invoice);
				return (duplicates || []).length > 0 ? this.showDuplicateWarningDialog(duplicates) : false;
			}
			catch (e) {
				return false;
			}

		}
		else {
			return false;
		}
	}

	private generatePatchData(): any {
		const complexKeys = ['lineItems', 'nonActiveLineItems', 'attachments', 'submissions'];
		let patchData = Object.keys(this.model.invoice).reduce( (acc, key) => {

			// Ignore the "complex" keys for the moment, we'll consider them in the next stage
			if (complexKeys.includes(key)) {
				return acc;
			}

			if (this.model.invoice[key] !== this._rollbackState[key]) {
				acc[key] = this.model.invoice[key];
			}

			return acc;

		}, {});

		let modifiedLineItems = [];

		const allLineItems = Array.from(this._rollbackState.lineItems).concat( Array.from(this._rollbackState.nonActiveLineItems) );

		// Line items
		this.model.invoice.lineItems.forEach( lineItem => {
			if (!!lineItem.id) {
				const lineItemHash = MD5(JSON.stringify(lineItem)).toString();
				const matchedLine = allLineItems.find( line => line.id === lineItem.id );
				if (matchedLine) {
					const matchedHash = MD5(JSON.stringify(matchedLine)).toString();
					if (matchedHash !== lineItemHash) {
						modifiedLineItems.push(lineItem);
					}
				}
				else {
					modifiedLineItems.push(lineItem);
				}
			}
		});

		this.model.invoice.nonActiveLineItems.forEach( lineItem => {
			if (!!lineItem.id) {
				const lineItemHash = MD5(JSON.stringify(lineItem)).toString();
				const matchedLine = allLineItems.find( line => line.id === lineItem.id );
				if (matchedLine) {
					const matchedHash = MD5(JSON.stringify(matchedLine)).toString();
					if (matchedHash !== lineItemHash) {
						modifiedLineItems.push(lineItem);
					}
				}
				else {
					modifiedLineItems.push(lineItem);
				}
			}
		});

		if (modifiedLineItems.length > 0) {
			patchData['lineItems'] = modifiedLineItems;
		}

		return patchData;
	}

	private masterModeSaveInvoice() {
		this._manualSave = true;
		OverlayService.show();


		const patchData = this.generatePatchData();

		this.invoiceService.patchInvoice(this.model.invoice.id, this.model.invoice.clientId, this.model.invoice.providerId, patchData, this._filemanager).then( returnedInvoice => {

			this.cloneInvoiceData(returnedInvoice);
			this.oldInvoiceData = this.calcInvoiceHash(this.model.invoice);
			this._manualSave = false;
			this._masterMode = false;
			this._rollbackState = undefined;
			OverlayService.hide();

		}).catch(e => {

			OverlayService.hide();
			OverlayService.showError("Unable to save invoice", ErrorUtils.getErrorMessage(e, "An unknown error occurred"));
			this._manualSave = false;
			this._masterMode = false;
			this.model.invoice = this._rollbackState;
			this._rollbackState = undefined;

		});
	}

	private async saveInvoice(goBack?: boolean): Promise<void> {

		if (this._masterMode) {
			// Don't go down this path if we're in master mode
			return;
		}

		// Don't save the invoice if a dialog is already open, or we're already saving the invoice
		if (this.saving || OverlayService.visible) {
			return;
		}

		// If we're trying to finalise the invoice, make sure it's in a suitable state to do so
		if (this.model.invoice.status === InvoiceStatus.locked) {
			if (!this.canFinaliseInvoice) {
				return;
			}
		}

		// For draft and investigation states, makes sure all error conditions are satisfied before saving
		else if (!this.canSaveInvoice) {
			return;
		}

		// If we're finalising the bill, or sending to investigation, make sure it's got an attachment
		if (this.missingAttachment()) {

			// if ([InvoiceStatus.locked, InvoiceStatus.investigation].includes(this.model.invoice.status)) {
			// 	this.model.invoice.status = InvoiceStatus.draft;
			// }
			OverlayService.showError("Error", "Please add an attachment before continuing");
			return;
		}

		// If we're sending to investigation, make sure it's got an Investigation Type
		if (this.missingInvestigationType) {
			if ([InvoiceStatus.investigation].includes(this.model.invoice.status)) {
				this.model.invoice.status = this._originalStatus ? this._originalStatus : InvoiceStatus.draft;
				OverlayService.showError("Error", "Please set the Investigation Type before continuing");
				return;
			}
		}

		//If setting at investigation, set investigation date and investigation user
		if((this.model.invoice.status == InvoiceStatus.investigation)) {
			if (!(this.model.invoice.investigationDate instanceof Date))
				this.model.invoice.investigationDate = moment();

			if (typeof this.model.invoice.investigationUserId !== 'string') {
				this.model.invoice.investigationUserId = this.userService.getCurrentUser().id;
			}
		} else if ((this.model.invoice.investigationUserId === undefined) || (this.model.invoice.investigationDate === undefined)) {
			this.model.invoice.investigationUserId = null;
			this.model.invoice.investigationDate = null;
		}

		// If we're finalising the bill from DRAFT, make sure it's got no InvestigationType set.
		if (!!this.model.invoice.investigationTypeId) {
			if (this._originalStatus == InvoiceStatus.draft && [InvoiceStatus.locked].includes(this.model.invoice.status)) {
				this.model.invoice.status = InvoiceStatus.draft;
				OverlayService.showError("Error", "Please send this bill to Investigation for review");
				return;
			}
		}

		this._manualSave = true;
		OverlayService.show();

		if (this.model.invoice.status === InvoiceStatus.locked) {
			let user = this.userService.getCurrentUser();
			this.model.invoice.finalisedBy = user.id;
			this.model.invoice.finalisedByUsername = user.email;
			this.model.invoice.finalisedDate = new Date();
		}

		const startTime = this.isNewInvoice ? this.model.startTime : undefined;
		this.model.invoice.gst = this.invoiceGST;
		try {
			const response = await this.invoiceService.saveInvoice(this.model.invoice, this._filemanager, startTime);
			this.planService.updateCache(response.invoice.clientId, response.plans);
			this._clientPlans = response.plans;
		// this.invoiceService.saveInvoice(this.model.invoice, this._filemanager, startTime).then( returnedInvoice => {
			OverlayService.hide();

			if (goBack) {
				DataExchangeService.set(response.invoice.id, 'lastSavedInvoice');
				this.goBack();
			}
			else {
				this.oldInvoiceData = this.calcInvoiceHash(this.model.invoice);
			}

			this._manualSave = false;
		}
		catch (e) {
			console.log(e);
			OverlayService.hide();
			OverlayService.showError("Unable to save invoice", ErrorUtils.getErrorMessage(e, "An unknown error occurred"));
			this._manualSave = false;

		};
	}

	/**
	* Checks to see if what's been pasted into the provider's box looks like an ABN. Removes spaces
	* and tests for 11 digits.
	*/
	public abnPaste(event: ClipboardEvent) {
		const text = event.clipboardData.getData('text');
		const tidied = text.replace(/\s/g, '');
		if (/^\d{11}$/.test(tidied)) {
			event.preventDefault();
			this.model.providerName = tidied;
		}
	}

	public onTicketPaste(event: ClipboardEvent) {
		const clipboardData: string = event.clipboardData.getData('text');
		const re = /^https:\/\/ndsp-planmanagers\.freshdesk\.com\/a\/tickets\/(\d{7})$/;
		const matches = clipboardData.match(re);
		if ((matches !== null) && (matches.length === 2)) {
			event.preventDefault();
			this.model.invoice.ticketNumber = matches[1];
		}
	}

	public checkInvoiceNumberKey(event: KeyboardEvent) {
		if (/[^a-z0-9\s]/i.test(event.key)) {
			event.preventDefault();
		}
	}

	public invoiceNumberPaste(event: ClipboardEvent) {
		event.preventDefault();

		const text = event.clipboardData.getData('text') || '';
		const tidied = text.replace(/[^a-z0-9\s]/ig, '');
		this.model.invoice.invoiceNumber = tidied.trim().substring(0, 140);
	}

	public get isValidTicketNumber(): boolean {
		const SF = /^0\d{7}$/;
		const FD = /^\d{7}$/;
		return FD.test(this.model.invoice.ticketNumber) || SF.test(this.model.invoice.ticketNumber);
	}

	public get canSaveInvoice(): boolean {
		if (this._masterMode) {
			return true;
		}

		const allowedSaveStates = [InvoiceStatus.draft, InvoiceStatus.investigation];

		const conditions = [
			allowedSaveStates.includes(this.model.invoice.status),
			this.model.invoice.clientId !== undefined,                                                  // Must select a client
			this.model.invoice.providerId !== undefined,                                                // Must select a provider
			this.model.invoice.invoiceNumber !== undefined,                                             // Must enter an invoice number
			Utils.trimString(this.model.invoice.invoiceNumber) !== "",                                  // Invoice number can't be spaces
			this.model.invoice.date !== undefined,                                                      // Must choose an invoice date
			this.model.invoice.total > 0,                                                               // Invoice total must be greater than $0.00
			(this.model.invoice.gst === undefined || this.model.invoice.gst < this.model.invoice.total) // GST component can't be greater than the invoice total
		];

		return conditions.every( item => item === true );
	}

	public get hasReimbursementBankDetails(): boolean {
		const reimbursementRecipient = this._reimbursementRecipientsWithBankAccount.find( item => item.id === this.model.invoice.reimbursementRecipient );
		return !!reimbursementRecipient;
	}

	private get canFinaliseInvoice(): boolean {

		// const errors = InvoiceValidator.validate(this.model.invoice, this._clientPlans, this._supportsCache).filter( item => item.isError );

		const conditions = [
			this.errorLines.length === 0,                                                               // All validation rules have been met
			this.model.invoice.clientId !== undefined,                                                  // Must select a client
			this.model.invoice.providerId !== undefined,                                                // Must select a provider
			this.model.invoice.invoiceNumber !== undefined,                                             // Must enter an invoice number
			Utils.trimString(this.model.invoice.invoiceNumber) !== "",                                  // Invoice number can't be spaces
			this.model.invoice.date !== undefined,                                                      // Must choose an invoice date
			this.model.invoice.lineItems.length > 0,                                                      // Must have at least one line item on the invoice
			this.model.invoice.total > 0,                                                               // Invoice total must be greater than $0.00
			!this.model.invoice.reimbursement || this.hasReimbursementBankDetails,
			(this.model.invoice.gst === undefined || this.model.invoice.gst < this.model.invoice.total) // GST component can't be greater than the invoice total
		];

		return conditions.every( item => item === true );
	}

	public get hasAttachment(): boolean {
		return this._filemanager.attachedFiles.length > 0;
	}

	public get canReopenInvoice(): boolean {
		// basically copied from canSaveInvoice.
		// const allowedSaveStates = [InvoiceStatus.locked, InvoiceStatus.submitted, InvoiceStatus.paid, InvoiceStatus.reconciled];
		const allowedSaveStates = [InvoiceStatus.locked, InvoiceStatus.submitted, InvoiceStatus.reconciled];

		const conditions = [
			allowedSaveStates.includes(this.model.invoice.status),
			this.model.invoice.clientId !== undefined,                                                  // Must select a client
			this.model.invoice.providerId !== undefined,                                                // Must select a provider
			this.model.invoice.invoiceNumber !== undefined,                                             // Must enter an invoice number
			Utils.trimString(this.model.invoice.invoiceNumber) !== "",                                  // Invoice number can't be spaces
			this.model.invoice.date !== undefined,                                                      // Must choose an invoice date
			this.model.invoice.total > 0,                                                               // Invoice total must be greater than $0.00
			(this.model.invoice.gst === undefined || this.model.invoice.gst < this.model.invoice.total) // GST component can't be greater than the invoice total
		];

		return conditions.every( item => item === true );
	}

	private missingAttachment(status?: InvoiceStatus): boolean {
		const statesThatRequireAnAttachment = [InvoiceStatus.locked, InvoiceStatus.investigation];
		const attachmentRequired = statesThatRequireAnAttachment.includes(status || this.model.invoice.status);
		return attachmentRequired && this._filemanager.attachedFiles.length < 1;
	}

	private get missingInvestigationType(): boolean {
		const statesThatRequireAnInvestigationType = [InvoiceStatus.investigation];
		const investigationTypeRequired = statesThatRequireAnInvestigationType.includes(this.model.invoice.status);
		return investigationTypeRequired && (this.model.invoice.investigationTypeId === null || this.model.invoice.investigationTypeId === undefined);
	}

	private get discrepancy(): number {
		const paidStatus = [LineItemStatus.paid, LineItemStatus.reconciled];
		const discrepancies: number[] = this.model.invoice.lineItems.filter( (lineItem: InvoiceLineItem) => paidStatus.includes(lineItem.status) ).map( (item: InvoiceLineItem) => Number(item.discrepancy || 0) );
		return discrepancies.reduce( (acc, cur) => {
			return acc + cur;
		}, 0);
	}

	public get totalDiscrepancy(): string {
		// const discrepancies: number[] = Array.from(this.model.invoice.lineItems).map( (item: InvoiceLineItem) => Number(item.discrepancy || 0) );
		// const result = discrepancies.reduce( (acc, cur) => {
		// 	return acc += cur, acc;
		// }, 0);

		return this.discrepancy.toFixed(2);
	}

	public get totalPaid(): string {
		if ([InvoiceStatus.reconciled, InvoiceStatus.paid].includes(this.model.invoice.status)) {

			const paidLineItemStatus = [LineItemStatus.paid, LineItemStatus.reconciled];
			const allLines: InvoiceLineItem[] = this.model.invoice.lineItems;
			const paidLines: InvoiceLineItem[] = allLines.filter( (lineItem: InvoiceLineItem) => paidLineItemStatus.includes( lineItem.status ) );
			const totalPaid: number = paidLines.reduce( (total: number, lineItem: InvoiceLineItem) => {
					return total + Number(lineItem.total) - (Number(lineItem.discrepancy) || 0);
				}, 0);

			return totalPaid.toFixed(2);
		}
		return undefined;
	}

	protected errorLines: InvoiceLineItem[] = [];
	protected warningLines: InvoiceLineItem[] = [];

	public hasError(lineItem: InvoiceLineItem): boolean {
		return this.errorLines.includes(lineItem) || this.warningLines.includes(lineItem);
	}

	/* public get warnings(): InvoiceValidationItem[] {
		if (this.model.invoice.clientId === undefined || this._loading) {
			return [];
		}

		const result = InvoiceValidator.validate(this.model.invoice, this._clientPlans, this._supportsCache, this._reimbursementOptions).filter( item => item && item.isWarning );
		this.warningLines = result.flatMap( item => item.affectedLines );

		return result;
	}

	public get errors(): InvoiceValidationItem[] {

		if (this.model.invoice.clientId === undefined || this._loading) {
			return [];
		}

		const result = InvoiceValidator.validate(this.model.invoice, this._clientPlans, this._supportsCache, this._reimbursementOptions).filter( item => item && item.isError );
		this.errorLines = result.flatMap( item => item.affectedLines );

		return result;
	} */

	public showAttachmentDialog(): void {
		//if (!this.billEditable) {
		//	return;
		//}

		if (document.activeElement instanceof HTMLElement) {
			document.activeElement.blur();
		}

		OverlayService.showTemplate(this.attachmentDialog);
	}

	private findPlanFromLineItemDate(date: Date): Plan {
		const lineItemDate = moment(date);

		const plans = this._clientPlans;

		const matchingPlans = plans.filter(plan => {
			const startDate = moment(plan.startDate).startOf('day');
			const endDate = moment(plan.endDate).endOf('day');
			return lineItemDate.isBetween(startDate, endDate, null, '[]');
		});

		return matchingPlans.pop();
	}

	public get supportsCache(): SupportItemCache {
		return this._supportsCache;
	}

	private hasLineItemWarning(lineItem: InvoiceLineItem): ValidationResult {
		return { "valid": true, "errors": [] };

		// const plan = this.findPlanFromLineItemDate(lineItem.date);
		// const support = this._supportsCache.get( lineItem.id );

		// const lineItemMapper = new LineItemMapper(this.lineItems, this._clientPlans, this._supportsCache);
		// const budgetMapper = new PlanBudgetMapper(plan, this._supportsCache);
		// const providerBudgetMapper = new ProviderBudgetMapper(plan, this._invoice.providerId, this._supportsCache);

		// const validator = new LineItemValidator(lineItemMapper, budgetMapper, providerBudgetMapper, plan); // , this._lineItemPlan);
		// const validationResult = validator.validate(lineItem, support, this._invoice.providerId);

		// if (lineItem.status === LineItemStatus.cancelled) {
		// 	return { "valid": true, "errors": [] };
		// } else {
		// 	return validationResult;
		// }
	}

	private async preFinaliseCheck() {

		if (this.model.invoice.status === InvoiceStatus.investigation) {

			// If the bill is already in investigation, don't perform additional duplicate checks.
			// Assume the user has verified that this bill is OK to be finalised
			this.lockInvoice();
		}
		else {

			// If the bill is coming from a draft state, check for duplicates now

			try {
				const duplicates = await this.invoiceService.checkForDuplicates(this.model.invoice, this._filemanager);

				if ((duplicates || []).length > 0) {
					OverlayService.showTemplate(this.duplicateDialog, {"duplicates": duplicates, "resolve": (proceed: string) => {
						switch (proceed) {
							case 'cancel':
								this.closeDialogWindow();
							break;
							
							case 'investigate':
								this.investigateDuplicate();
							break;
							
							case 'save':
								this.ignoreDuplicate();
							break;
						}
					}});
				}
				else {
					this.lockInvoice();
				}
			}
			catch (e) {
				this.lockInvoice();
			}
		}
	}

	private investigateDuplicate() {
		OverlayService.hide();
		setTimeout(() => {
			const duplicateWarning = 'Auto Note: Potential duplicate bill detected';
			const comment = this.model.invoice.comment || "";
			if (!comment.startsWith(duplicateWarning)) {
				this.model.invoice.comment = [duplicateWarning, this.model.invoice.comment].filter( x => !!x ).join("\n");
			}
			this.model.invoice.status = InvoiceStatus.investigation;
			this.model.invoice.investigationTypeId = Invoice.investigationTypeDuplicate;
			this.saveInvoice(true);
		}, 0);
	}

	private ignoreDuplicate() {
		OverlayService.hide();
		setTimeout(() => {
			const duplicateWarning = 'Auto Note: Potential duplicate bill detected but still finalised.';
			const comment = this.model.invoice.comment || "";
			if (!comment.startsWith(duplicateWarning)) {
				this.model.invoice.comment = [duplicateWarning, this.model.invoice.comment].filter( x => !!x ).join("\n");
			}
			this.lockInvoice();
			this.saveDialogClosed();
		}, 0);
	}

	protected saveDialogClosed(status?: InvoiceStatus): void {

		if (this.missingAttachment(status)) {
				setTimeout(() => {
					OverlayService.showError("Error", "Please add an attachment before continuing");
				}, 0);
			return;
		}

		switch (status) {

			case InvoiceStatus.draft:
				setTimeout(() => { this.saveInvoice(true); }, 0);
				break;

			case InvoiceStatus.locked:
				setTimeout(() => {
					this.preFinaliseCheck();
				}, 0);
				break;

			case InvoiceStatus.investigation:
				setTimeout(() => {
					this.model.invoice.status = InvoiceStatus.investigation;
					this.saveInvoice(true);
				}, 0);
				break;

			default:
				break;
		}
	}


	protected reopenDialogClosed(status?: InvoiceStatus): void {
		switch (status) {

			case InvoiceStatus.investigation:
				setTimeout(() => {
					this.model.invoice.status = InvoiceStatus.investigation;
					this.saveInvoice(false);
				}, 0);

				break;

			default:
				break;
		}
	}

	public get invoiceGST(): number {
		const gstRate = 0.1;

		const lineItems: InvoiceLineItem[] = this.model.invoice.lineItems;

		return lineItems.reduce( (acc, lineItem) => {
			if (lineItem.gstCode === GSTCode.P1) {
				acc += (gstRate * lineItem.total) / (1 + gstRate);
			}
			return acc;
		}, 0);
	}

	public get dob():string {
		if (this.model.dob) {
			let years = moment().diff(moment(this.model.dob, 'YYYY-MM-DD'), 'years');
			return `${moment(this.model.dob, 'YYYY-MM-DD').format('DD/MM/yyyy')} (${years} years old)`;
		} else {
			return ``;
		}
	}

	public get reimbursementRecipientsWithBankAccount(): ReimbursementRecipient[] {
		return this._reimbursementRecipientsWithBankAccount;
	}

	public get reimbursementRecipientsWithoutBankAccount(): ReimbursementRecipient[] {
		return this._reimbursementRecipientsWithoutBankAccount;
	}

	public duplicateCheck() {
		this.validate();
		this.runDuplicateCheck();
	}

	public get mostRecentPlan(): string {
		const now = new Date().valueOf();
		const planSort = (a: Plan, b: Plan) => a.startDate.valueOf() - b.startDate.valueOf();

		// Find all non-draft plans with a start date of < now, and order by start date
		const plans = (this._clientPlans).filter( plan => plan.startDate.valueOf() < now && plan.status !== PlanStatus.proposed).sort( planSort );

		if (plans.length > 0) {

			// Take the most recent (ie the one with the largest start date - so the last one in the list)
			const mostRecentPlan = plans.pop();

			const dateFormat = 'DD/MM/YYYY';
			const startDate = DateUtils.toString(mostRecentPlan.startDate, dateFormat);
			const endDate = DateUtils.toString(mostRecentPlan.endDate, dateFormat);
			const region = RegionUtils.toString(mostRecentPlan.region).toUpperCase()
			const pace = mostRecentPlan.pace ? ' (PACE)' : '';

			return `${startDate} - ${endDate} (${PlanStatus.toString(mostRecentPlan.status)}) (Region: ${region})${pace}`;
		}
		else {
			return "No eligible plans found";
		}
	}
}
