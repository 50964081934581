import { Invoice, InvoiceLineItem, InvoiceStatus, LineItemStatus } from "@classes/invoices";
import { SupportItem, SupportItemCache } from "@classes/supports";
import { Plan, PlanStatus, PlanBudget, BudgetType } from "@classes/plans";
import { assert, EAssertionFailed } from "@classes/errors";
import { ReimbursementRecipient } from "@classes/reimbursementRecipient";
import { LineItemValidator } from "./lineitem/invoicelineitem.businessrules";
import { FloatingPointUtils as FPUtils } from "@classes/utils";
import moment from 'moment';

class ValidationConstants {
	private static readonly ignoreLineItemStatus: LineItemStatus[] = [
		LineItemStatus.paid,
		LineItemStatus.reconciled,
		LineItemStatus.deleted,
		LineItemStatus.cancelled,
		LineItemStatus.rejected,
		LineItemStatus.error
	];

	public static ignoreLineItem(lineItem: InvoiceLineItem): boolean {
		if (!lineItem) {
			return true;
		}

		return ValidationConstants.ignoreLineItemStatus.includes(lineItem.status);
	}
}

class EInvoiceValidationException extends Error {
	constructor(message: string, public errorLines: InvoiceLineItem[] = []) {
		super(message);

		// Workaround to fix subclass of built-in classes
		Object.setPrototypeOf(this, EInvoiceValidationException.prototype);
	}
}

export abstract class InvoiceValidationItem {
	public affectedLines: InvoiceLineItem[];

	constructor(public readonly message: string) {}

	public get isError(): boolean {
		return this instanceof InvoiceError;
	}

	public get isWarning(): boolean {
		return this instanceof InvoiceWarning;
	}
}

export class InvoiceWarning extends InvoiceValidationItem {}

export class InvoiceError extends InvoiceValidationItem {
	constructor(msg: string, public readonly fatal: boolean) {
		super(msg);
	}
}

abstract class ValidationRule {
	protected _errorMessage: string = "";

	protected _validationResult: InvoiceValidationItem = undefined;

	public get result(): InvoiceValidationItem {
		return this._validationResult;
	}

	public get valid(): boolean {
		return this._validationResult === undefined;
	}

	public abstract validate();

	constructor() {}
}

export class InvoiceValidator {

	private static instance: InvoiceValidator;

	private constructor(private invoice: Invoice, private plans: Plan[]) {}

	private static createRules(invoice: Invoice, plans: Plan[], supports: SupportItemCache, reimbursementRecipients: ReimbursementRecipient[]): ValidationRule[] {
		return [
			new PlaceHolderRule(invoice),
			new SuspiciousDateRule(invoice),
			new PlaceholderProviderRule(invoice),
			new HasPlanRule(plans),
			new NoInvoiceTotalRule(invoice),
			new LineItemsTotalRule(invoice),
			new HasReimbursementDataRule(invoice, reimbursementRecipients),
			new LineItemsValidRule(invoice),
			new LineItemBudgetRule(invoice, plans, supports),
			new BudgetErrorRule(invoice, plans, supports),
			new InvalidTicketNumber(invoice)
		];
	}

	public static validate(invoice: Invoice, plans: Plan[], supports: SupportItemCache, reimbursementRecipients: ReimbursementRecipient[]): InvoiceValidationItem[] {

		try {
			// Don't run validation against an invoice that's been deleted
			assert(!invoice.deleted);

			// Don't run validation against an invoice that's already been paid or submitted.
			assert([InvoiceStatus.draft, InvoiceStatus.investigation].includes(invoice.status));
		}
		catch (e) {
			if (e instanceof EAssertionFailed) {
				return [];
			}
			throw e;
		}

		const validationErrors = [];

		const rules = InvoiceValidator.createRules(invoice, plans, supports, reimbursementRecipients);

		// Use Array.every to short-circuit the loop if a fatal condition is met.
		rules.every( rule => {
			rule.validate();

			if (rule.valid) {
				return true;
			}

			validationErrors.push(rule.result);
			return !(rule.result instanceof InvoiceError && (<InvoiceError>rule.result).fatal);
		});

		return validationErrors;
	}
}

class PlaceHolderRule extends ValidationRule {

	private readonly placeholders = {
		"client": "1de758cd-5131-414f-9fe4-b7fa86f03beb",
		"provider": "bf3da13d-7566-4cca-b2f2-f3dabfda2ded"
	};

	constructor(private invoice: Invoice) {
		super();
	}

	public validate() {
		if (this.invoice.clientId === this.placeholders.client) {
			this._validationResult = new InvoiceError("Please select the client for this invoice", true);
			return;
		}

		if (this.invoice.providerId === this.placeholders.provider) {
			this._validationResult = new InvoiceError("Please select the provider for this invoice", true);
			return;
		}
	}
}

class HasPlanRule extends ValidationRule {

	constructor(private plans: Plan[]) {
		super();
	}

	public validate() {
		const valid = this.plans ? this.plans.length > 0 : false;
		if (!valid) {
			this._validationResult = new InvoiceError("There are no plans defined for this client", true);
		}
	}
}

class InvalidTicketNumber extends ValidationRule {
	constructor(private invoice: Invoice) {
		super();
	}

	private isValidTicketNumber(ticketNumber: string): boolean {
		const SF = /^0\d{7}$/;
		const FD = /^\d{7}$/;
		return FD.test(ticketNumber) || SF.test(ticketNumber) || !ticketNumber;
	}

	public validate() {
		if(!this.isValidTicketNumber(this.invoice.ticketNumber))
			this._validationResult = new InvoiceError("Ticket/case number invalid - Freshdesk ticket numbers should be 7 digits long, SalesForce case numbers should be 8 digits long with a leading zero", true);
	}
}

class SuspiciousDateRule extends ValidationRule {

	constructor(private invoice: Invoice) {
		super();
	}

	public validate() {
		const date = this.invoice && moment(this.invoice.date) || false;
		const ndisStart = moment(new Date(2013, 0, 1));
		const today = moment().startOf('day');

		if (!date) {
			this._validationResult = new InvoiceError("Please enter the bill date", true);
			return;
		}

		if (date.isBefore(ndisStart)) {
			this._validationResult = new InvoiceError("Bill date is not valid", true);
		}
		else if (date.isAfter(today)) {
			this._validationResult = new InvoiceError("Bill date cannot be in the future", true);
		}
	}
}

class PlaceholderProviderRule extends ValidationRule {

	private placeholder = "bf3da13d-7566-4cca-b2f2-f3dabfda2ded";

	constructor(private invoice: Invoice) {
		super();
	}

	public validate() {
		const provider = this.invoice && this.invoice.providerId || false;
		const valid = !!provider && provider !== this.placeholder;
		if (!valid) {
			this._validationResult = new InvoiceError("Please select the provider for this bill", true);
		}
	}
}




abstract class InvoiceTotalRule extends ValidationRule {
	constructor(protected invoice: Invoice) {
		super();
	}

	protected get invoiceTotal(): number {
		// RRP: Don't include GST in the invoice total calculation
		// return Number(this.invoice.total || 0) + Number(this.invoice.gst || 0) + Number(this.invoice.adjustment || 0);
		return Number(this.invoice.total || 0) + Number(this.invoice.adjustment || 0);
	}

	protected get lineItemTotal(): number {
		const finalisedStatuses = [LineItemStatus.reconciled, LineItemStatus.rejected, LineItemStatus.locked, LineItemStatus.paid];
		return Array.from(this.invoice.lineItems).reduce( (result: number, lineItem: InvoiceLineItem) => {
			const discrepancy = finalisedStatuses.includes(lineItem.status) ? Number(lineItem.discrepancy || 0) : 0;
			return result + Number(lineItem.total || 0) - discrepancy;
		}, 0);
	}

	protected get discrepancyTotal(): number {
		return Array.from(this.invoice.lineItems).reduce( (result: number, lineItem: InvoiceLineItem) => {
			return result + Number(lineItem.discrepancy || 0);
		}, 0);
	}
}

class NoInvoiceTotalRule extends InvoiceTotalRule {
	constructor(invoice: Invoice) {
		super(invoice);
	}

	public validate() {
		if (!this.invoice || this.invoice.lineItems.length === 0) {
			return;
		}

		const valid = this.invoiceTotal > 0 || Number(this.invoice.adjustment || 0) !== 0;
		if (!valid) {
			this._validationResult = new InvoiceError("Please enter the bill total", true);
		}
	}
}

class LineItemsTotalRule extends InvoiceTotalRule {
	constructor(invoice: Invoice) {
		super(invoice);
	}

	public validate() {

		if (!this.invoice || this.invoice.lineItems.length === 0) {
			return;
		}

		const oneCent = 0.01;
		const lineItemsTotal = this.lineItemTotal;
		const invoiceTotal = this.invoiceTotal;
		const diff = Math.abs( Math.round(100 * (lineItemsTotal - invoiceTotal)) / 100 );

		if (diff >= oneCent) {
			const printableLineItemTotal = lineItemsTotal.toLocaleString("en-AU", {
				style: "currency",
				currency: "AUD",
				minimumFractionDigits: 2
			});

			const printableInvoiceTotal = invoiceTotal.toLocaleString("en-AU", {
				style: "currency",
				currency: "AUD",
				minimumFractionDigits: 2
			});

			const underError = `Line items total (${printableLineItemTotal}) is less than invoice total (${printableInvoiceTotal})`;
			const overError = `Line items total (${printableLineItemTotal}) exceeds invoice total (${printableInvoiceTotal})`;

			if (lineItemsTotal < invoiceTotal) {
				this._validationResult = new InvoiceError(underError, true);
			}
			else {
				this._validationResult = new InvoiceError(overError, true);
			}
		}
	}
}

class LineItemsValidRule extends ValidationRule {

	constructor(private invoice: Invoice) {
		super();
	}

	private checkHasSupport() {
		const errorLines = Array.from(this.invoice.lineItems).reduce( (result, lineItem) => {
			if (!lineItem.supportItemNumber) {
				result.push(lineItem);
			}
			return result;
		}, []);

		if (errorLines.length) {
			throw new EInvoiceValidationException("Line item has missing support item", errorLines);
		}
	}

	private checkHasQuantity() {
		const errorLines = Array.from(this.invoice.lineItems).reduce( (result, lineItem) => {
			const quantity = Number(lineItem.quantity);
			if (quantity <= 0 || isNaN(quantity)) {
				result.push(lineItem);
			}
			return result;
		}, []);

		if (errorLines.length) {
			throw new EInvoiceValidationException("Line item has missing quantity", errorLines);
		}
	}

	private checkValidDate() {

		const ndisStart = moment(new Date(2013, 0, 1));
		const today = moment().startOf('day');

		const errorLines = Array.from(this.invoice.lineItems).reduce( (result, lineItem) => {

			const date = lineItem.date && moment(lineItem.date) || false;
			if (!date) {
				result.push(lineItem);
			}
			else if (date.isBefore(ndisStart)) {
				result.push(lineItem);
			}
			else if (date.isAfter(today)) {
				result.push(lineItem);
			}

			return result;
		}, []);

		if (errorLines.length) {
			throw new EInvoiceValidationException("Invalid line item date", errorLines);
		}
	}

	public validate() {
		if (!this.invoice || this.invoice.lineItems.length === 0) {
			return;
		}

		try {

			this.checkHasSupport();
			this.checkHasQuantity();
			this.checkValidDate();

		}
		catch (e) {
			if (e instanceof EInvoiceValidationException) {
				this._validationResult = new InvoiceError(e.message, true);
				this._validationResult.affectedLines = e.errorLines;
				return;
			}
			throw e;
		}
	}
}

/**
* Helper class to reduce duplicated code between the BudgetRule and BudgetWarningRule
* Generates a map of budgets indexed by line item. Allows easy access to the PlanBudget
* instance that each line item should be billed against.
*/
class BudgetMapper {
	constructor(
		private lineItems: InvoiceLineItem[],
		private plans: Plan[],
		private supports: SupportItemCache,
		private providerId: string) {
		this.runMapping();
	}

	private readonly planMap = new Map<InvoiceLineItem, Plan>();

	private findPlanForLineItem(lineItem: InvoiceLineItem): Plan {
		if (!lineItem || !lineItem.date) {
			return undefined;
		}

		const allowedStatuses = [PlanStatus.current, PlanStatus.expired, PlanStatus.deceased];

		const lineItemDate = moment(lineItem.date);

		const matchingPlans = this.plans.filter(plan => {
			const startDate = moment(plan.startDate).startOf('day');
			const endDate = moment(plan.endDate).endOf('day');

			return allowedStatuses.includes(plan.status) && lineItemDate.isBetween(startDate, endDate, null, '[]');
		});


		return matchingPlans.length === 1 ? matchingPlans.pop() : undefined;
	}

	/**
	*	Creates a one-to-one mapping between each InvoiceLineItem on an invoice and the plan that it will be billed against
	*/
	private mapPlans(): void {
		this.lineItems.reduce( (acc, lineItem) => {
			const plan = this.findPlanForLineItem(lineItem);
			if (!!plan) {
				acc.set(lineItem, plan);
			}
			return acc;
		}, this.planMap);
	}

	/**
	* Creates a one-to-one mapping between each InvoiceLineItem on an invoice and the PlanBudget that it relates to.
	*/
	private mapBudgets(): void {
		this.lineItems.reduce( (acc, lineItem) => {
			const plan = this.planMap.get(lineItem);
			const supportItem = this.supports.get(lineItem.supportItem);
			if (!plan || !supportItem) {
				return acc;
			}

			const budget = plan.findBudget(supportItem, lineItem.date, this.providerId, true);
			acc.set(lineItem, budget);
			return acc;
		}, this.budgets);
	}

	/**
	* Creates a one-to-many mapping between a PlanBudget and the line items that the budget covers
	*/
	private mapLines(): void {
		this.lineItems.forEach( lineItem => {
			const budget = this.budgets.get(lineItem);

			if (!!budget) {

				if (!this.lines.has(budget)) {
					this.lines.set(budget, []);
				}

				const linesArray = this.lines.get(budget);
				linesArray.push(lineItem);
			}
		});
	}

	private runMapping(): void {
		this.planMap.clear();
		this.budgets.clear();
		this.lines.clear();

		this.mapPlans();
		this.mapBudgets();
		this.mapLines();
	}

	public readonly budgets = new Map<InvoiceLineItem, PlanBudget>();
	public readonly lines = new Map<PlanBudget, InvoiceLineItem[]>()
}


class BudgetErrorRule extends ValidationRule {

	constructor(private invoice: Invoice, private plans: Plan[], private supports: SupportItemCache) {
		super();
	}

	private getErrorMessage(budget: PlanBudget, supportItem: SupportItem): string {

		const categoryId = supportItem.supportCategoryId.toLocaleString('en', {"minimumIntegerDigits": 2});

		const msgPrefix = "Insufficient funds in";

		switch (budget.budgetType) {
			case BudgetType.supportItem:
				return `${msgPrefix} support item budget for ${supportItem.name}`;

			case BudgetType.category:
				return `${msgPrefix} category budget for ${categoryId} ${supportItem.supportCategory}`;

			case BudgetType.serviceAgreementItem:
				return `${msgPrefix} service agreement item budget for ${supportItem.name}`;

			case BudgetType.serviceAgreementCategory:
				return `${msgPrefix} service agreement category budget for ${categoryId } ${supportItem.supportCategory}`;

			default:
				return `${msgPrefix} plan budget`;
		}
	}

	public validate() {

		if (!this.invoice) {
			return;
		}

		// Find a list of all the active lines on the invoice
		const activeLines = Array.from(this.invoice.lineItems).filter( lineItem => lineItem.status === undefined );
		if (activeLines.length === 0) {

			// If there are no active lines, then there's nothing to validate at this stage
			return;
		}

		const mapper = new BudgetMapper(activeLines, this.plans, this.supports, this.invoice.providerId);

		// Now that we've got a list of all the budgets for each active line, check that we're not overspending on any of them
		activeLines.forEach( (lineItem: InvoiceLineItem) => {

			if (!!this._validationResult) {
				return;
			}

			const budget = mapper.budgets.get(lineItem);
			if (!budget) {
				this._validationResult = new InvoiceError(`Missing budget for support item ${lineItem.supportItemNumber}`, true);
				this._validationResult.affectedLines = [lineItem];
				return;
			}

			const totalSpend = budget.paid + budget.pending + budget.draft + budget.unknown;
//			if (!this._validationResult && totalSpend > budget.total) {
			if (!this._validationResult && FPUtils.greaterThan(totalSpend, budget.total)) {
				this._validationResult = new InvoiceError(this.getErrorMessage(budget, this.supports.get(lineItem.supportItem)), true);
				this._validationResult.affectedLines = [lineItem];
			}
		});
	};
}


class BudgetWarningRule extends ValidationRule {

	constructor(protected invoice: Invoice, protected plans: Plan[], protected supports: SupportItemCache) {
		super();
	}

	private getErrorMessage(budget: PlanBudget, supportItem: SupportItem): string {

		const categoryId = supportItem.supportCategoryId.toLocaleString('en', {"minimumIntegerDigits": 2});

		const msgPrefix = "Insufficient funds in";

		switch (budget.budgetType) {
			case BudgetType.supportItem:
				return `${msgPrefix} support item budget for ${supportItem.name}`;

			case BudgetType.category:
				return `${msgPrefix} category budget for ${categoryId} ${supportItem.supportCategory}`;

			case BudgetType.serviceAgreementItem:
				return `${msgPrefix} service agreement item budget for ${supportItem.name}`;

			case BudgetType.serviceAgreementCategory:
				return `${msgPrefix} service agreement category budget for ${categoryId } ${supportItem.supportCategory}`;

			default:
				return `${msgPrefix} plan budget`;
		}
	}

	private getWarningMessage(budget: PlanBudget, supportItem: SupportItem): string {

		const categoryId = supportItem.supportCategoryId.toLocaleString('en', {"minimumIntegerDigits":2});

		const msgPrefix = "Warning: This bill can be finalised, but doing so may cause other bills in draft or investigation to exceed the";

		switch (budget.budgetType) {
			case BudgetType.supportItem:
				return `${msgPrefix} support item budget for ${supportItem.name}`;

			case BudgetType.category:
				return `${msgPrefix} category budget for ${categoryId} ${supportItem.supportCategory}`;

			case BudgetType.serviceAgreementItem:
				return `${msgPrefix} service agreement item budget for ${supportItem.name}`;

			case BudgetType.serviceAgreementCategory:
				return `${msgPrefix} service agreement category budget for ${categoryId } ${supportItem.supportCategory}`;

			default:
				return `${msgPrefix} plan budget`;
		}
	}

	public validate() {

		if (!this.invoice) {
			return;
		}

		// Find a list of all the active lines on the invoice
		const activeLines = Array.from(this.invoice.lineItems).filter( lineItem => lineItem.status === undefined );
		if (activeLines.length === 0) {

			// If there are no active lines, then there's nothing to validate at this stage
			return;
		}

		const mapper = new BudgetMapper(activeLines, this.plans, this.supports, this.invoice.providerId);

		const budgets = Array.from(mapper.lines.keys());
		if (budgets.length === 0) {
			return;
		}

		budgets.forEach( budget => {

			const budgetLines = mapper.lines.get(budget);

			const lineItemsTotal = budgetLines.reduce( (total: number, lineItem: InvoiceLineItem) => {
				total += lineItem.total - lineItem.discrepancy;
				return total;
			}, 0);

			const currentSpend = budget.paid + budget.pending + budget.unknown; // Current committed funds, does not include amount in draft
			const otherInvoicesInDraft = budget.draft - lineItemsTotal; // Amount in draft on other invoices, excludes this invoice

			const available = budget.total - budget.quarantined;

			// If there is no money available in the budget, exit now with an error.
			if (FPUtils.lessThan(available, lineItemsTotal)) {
				this._validationResult = new InvoiceError(this.getErrorMessage(budget, this.supports.get(budgetLines[0].supportItem)), true);
				this._validationResult.affectedLines = budgetLines;
				return;
			}

			// If the total budget is greater than this invoice's value plus the current spend (including funds in draft),
			// then the plan has sufficient funding and no error or warning is required.
			if (FPUtils.greaterThanOrEqualTo(available, currentSpend + otherInvoicesInDraft + lineItemsTotal)) {
				return;
			}

			// If this budget has sufficient funds by using amount from other invoices in draft, raise a warning
			if (FPUtils.greaterThanOrEqualTo(available, currentSpend + lineItemsTotal)) {
				this._validationResult = new InvoiceWarning(this.getWarningMessage(budget, this.supports.get(budgetLines[0].supportItem)));
				this._validationResult.affectedLines = budgetLines;
			}
			// Otherwise, raise an error
			else {
				this._validationResult = new InvoiceError(this.getErrorMessage(budget, this.supports.get(budgetLines[0].supportItem)), true);
				this._validationResult.affectedLines = budgetLines;
			}
		});

	};
}

class HasReimbursementDataRule extends ValidationRule {

	constructor(private invoice: Invoice, private reimbursementRecipients: ReimbursementRecipient[]) {
		super();
	}

	public validate() {
		if (!this.invoice.reimbursement) {
			return;
		}

		if (!this.invoice.reimbursementRecipient) {
			this._validationResult = new InvoiceError("Please select a reimbursement recipient", true);
			return;
		}

		const recipient = this.reimbursementRecipients.find( item => item.id === this.invoice.reimbursementRecipient );
		if (!recipient) {
			this._validationResult = new InvoiceError("Please select a reimbursement recipient", true);
			return;
		}

		if (!recipient.bankInfo) {
			this._validationResult = new InvoiceWarning("No bank account information for this reimbursement recipient");
		}
	}
}

class LineItemBudgetRule extends ValidationRule {

	constructor(private invoice: Invoice, private plans: Plan[], private supports: SupportItemCache) {
		super();
	}

	private findPlanForLineItem(lineItem: InvoiceLineItem, plans: Plan[]): Plan {
		return plans.find( plan => {
			if (!lineItem.date) {
				return false;
			}
			return plan.startDate.valueOf() <= lineItem.date.valueOf() && plan.endDate.valueOf() >= lineItem.date.valueOf();
		});
	}

	private generatePlanMap(): Map<InvoiceLineItem, Plan> {
		const planMap = new Map<InvoiceLineItem, Plan>();

		const linesWithoutPlan = [];

		this.invoice.lineItems.forEach( lineItem => {
			const plan = this.findPlanForLineItem(lineItem, this.plans);
			if (!plan) {
				linesWithoutPlan.push(lineItem);
			}
			else {
				planMap.set(lineItem, plan);
			}
		});

		if (linesWithoutPlan.length > 0) {
			throw new EInvoiceValidationException("No plan for line item", linesWithoutPlan);
		}

		return planMap;
	}

	private generateSupportItemMap(): Map<InvoiceLineItem, SupportItem> {
		const result = new Map<InvoiceLineItem, SupportItem>();
		this.invoice.lineItems.forEach( lineItem => {
			let support = this.supports.get(lineItem.supportItem);
			if (!support) {
				throw new EInvoiceValidationException("Support for line item not found", [lineItem]);
			}
			result.set(lineItem, support);
		});
		return result;
	}

	public validate() {
		// Map each line item to a plan

		try {
			const planMap = this.generatePlanMap();
			const supportMap = this.generateSupportItemMap();

			const lineItems = Array.from(this.invoice.lineItems);
			const validator = new LineItemValidator(lineItems, supportMap, planMap, true);

			this.invoice.lineItems.forEach( lineItem => {

				const result = validator.validate(lineItem, this.invoice.providerId);
				if (!result.valid && result.errors.length) {
					throw new EInvoiceValidationException(result.errors[0].errorMessage, [lineItem]);
				}
			});
		}
		catch (e) {
			if (e instanceof EInvoiceValidationException) {
				this._validationResult = new InvoiceError(e.message, true);
				this._validationResult.affectedLines = e.errorLines;
				return;
			}
			throw e;
		}
	}
}
