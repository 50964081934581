import { SupportItem } from "@classes/supports";
import { Plan } from "@classes/plans";
import { EInvalidArgument } from "@classes/errors";
import moment from "moment";

export interface BillingItem {
	id: string;
	date: Date;
	dateStr?: string;
	supportItemId: string;
	supportItemName: string;
	supportItemNumber: string;
	invoiceId: string;
	amount: number;
}

export abstract class BillingScheduleItem implements BillingItem {

	private static dateFormat = "DDMMYYYY";

	private _dateStr: string;

	get dateStr(): string {
		return this._dateStr;
	}

	set dateStr(value: string) {
		this._dateStr = value;
		const m = moment(this._dateStr, BillingScheduleItem.dateFormat, true);
		this.date = m.toDate();
	}

	public readonly id: string;
	public date: Date;
	public readonly supportItemId: string;
	public readonly supportItemName: string;
	public readonly supportItemNumber: string;
	public readonly invoiceId: string;
	public amount: number;

	constructor(data: any) {
		this.id = data.id;
		this.date = data.date;
		this._dateStr = this.date ? moment(this.date).format(BillingScheduleItem.dateFormat) : "";
		this.supportItemId = data.supportItemId;
		this.supportItemName = data.supportItemName;
		this.supportItemNumber = data.supportItemNumber;
		this.invoiceId = data.invoiceId;
		this.amount = data.amount;
	}

	private incrementDate(numDays: number) {
		if (this.invoiceId || !this.date) {
			// Don't change date for an item that's already been billed
			return;
		}

		const newDate = moment(this.date).add(numDays, 'days');
		this.date = newDate.toDate();
		this._dateStr = newDate.format(BillingScheduleItem.dateFormat);
	}

	public addDay() {
		this.incrementDate(1);
	}

	public subtractDay() {
		this.incrementDate(-1);
	}

	public clone(): BillingItem {
		const data = {
			"id": this.id,
			"date": this.date,
			"supportItemId": this.supportItemId,
			"supportItemName": this.supportItemName,
			"supportItemNumber": this.supportItemNumber,
			"invoiceId": this.invoiceId,
			"amount": this.amount
		};

		if (this instanceof SetupFee) {
			return new SetupFee(data);
		}
		else if (this instanceof ManagementFee) {
			return new ManagementFee(data);
		}
		else {
			return data;
		}
	}
}

export class SetupFee extends BillingScheduleItem {
	constructor(data: any) {
		super(data);
	}
}

export class ManagementFee extends BillingScheduleItem {
	constructor(data: any) {
		super(data);
	}
}

export class BillingSchedule {
	public id: string;
	public items: BillingItem[] = [];

	constructor(public planId: string) {
	}
}

export class BillingScheduleUtils {

	private static readonly dateFormat: string = "YYYY-MM-DD";

	public static readonly planManagementSupportItems = {
		"setup": "14_033_0127_8_3",
		"monthlyFee": "14_034_0127_8_3"
	};

	// public static findSupportFromItem(item: BillingItem, supports: SupportItem[]): SupportItem {
	// 	let itemCode: string = undefined;

	// 	if (item instanceof SetupFee) {
	// 		itemCode = BillingScheduleUtils.planManagementSupportItems.setup;
	// 	}
	// 	else if (item instanceof ManagementFee) {
	// 		itemCode = BillingScheduleUtils.planManagementSupportItems.monthlyFee;
	// 	}

	// 	if (!itemCode) {
	// 		return undefined;
	// 	}

	// 	return BillingScheduleUtils.findSupport(itemCode, supports);
	// }

	private static findSupport(supportItemNumber: string, supports: SupportItem[]): SupportItem {
		return supports.find( support => support.supportItemNumber === supportItemNumber );
	}

	public static createSetupFee(plan: Plan, setFeeSupportItem: SupportItem): BillingItem {

		if (!setFeeSupportItem) {
			throw new EInvalidArgument("Cannot find Plan Management setup fee support item");
		}

		return new SetupFee({
			"id": undefined,
			"date": plan.startDate,
			"supportItemId": setFeeSupportItem.id,
			"supportItemName": setFeeSupportItem.name,
			"supportItemNumber": BillingScheduleUtils.planManagementSupportItems.setup,
			"invoiceId": undefined,
			"amount": setFeeSupportItem.priceControl && setFeeSupportItem.priceLimit ? Number(setFeeSupportItem.priceLimit) : 0
		});
	}

	public static createManagementFee(plan: Plan, date: Date, monthlyFeeSupportItem: SupportItem): BillingItem {

		// NB: Use the plan start date as these fees are not allowed to change with price schedule updates due to being a "specified item"
		if (!monthlyFeeSupportItem) {
			throw new EInvalidArgument("Cannot find Plan Management monthly fee support item");
		}

		return new ManagementFee({
			"id": undefined,
			"date": date,
			"supportItemId": monthlyFeeSupportItem.id,
			"supportItemName": monthlyFeeSupportItem.name,
			"supportItemNumber": BillingScheduleUtils.planManagementSupportItems.monthlyFee,
			"invoiceId": undefined,
			"amount": monthlyFeeSupportItem.priceControl && monthlyFeeSupportItem.priceLimit ? Number(monthlyFeeSupportItem.priceLimit) : 0
		});
	}

	private static itemToJson(item: BillingItem): any {
		return {
			"id": item.id,
			"date": moment(item.date).format(BillingScheduleUtils.dateFormat),
			"supportItemId": item.supportItemId,
			"invoiceId": item.invoiceId,
			"amount": Number(item.amount)
		};
	}

	public static toJson(schedule: BillingSchedule): any {
		return {
			"id": schedule.id,
			"planId": schedule.planId,
			"items": schedule.items.map( item => BillingScheduleUtils.itemToJson(item) )
		};
	}

	public static createNew(plan: Plan, supports: SupportItem[]): BillingSchedule {

		// Check that the plan start and end dates are valid
		let start = moment(plan.startDate);
		const end = moment(plan.endDate);
		if (!start.isValid() || !end.isValid()) {
			throw new EInvalidArgument("Invalid date range supplied");
		}

		if (start.isSameOrAfter(end)) {
			throw new EInvalidArgument("Start date must be before end date");
		}

		const result = new BillingSchedule(plan.id);

		const setupFeeSupport = BillingScheduleUtils.findSupport(BillingScheduleUtils.planManagementSupportItems.setup, supports);
		const managementFeeSupport = BillingScheduleUtils.findSupport(BillingScheduleUtils.planManagementSupportItems.monthlyFee, supports);

		result.items.push( BillingScheduleUtils.createSetupFee(plan, setupFeeSupport) );

		do {

			result.items.push( BillingScheduleUtils.createManagementFee(plan, start.toDate(), managementFeeSupport) );
			start = start.add(1, 'month');

		} while (start.isBefore(end));

		return result;
	}

	private static parseItemJson(json: any): BillingItem {
		const data = {
			"id": json.id,
			"date": moment(json.date, BillingScheduleUtils.dateFormat).toDate(),
			"supportItemId": json.supportItemId,
			"supportItemName": json.supportItem,
			"supportItemNumber": json.supportItemNumber,
			"invoiceId": json.invoiceId,
			"amount": json.amount
		};

		if (json.supportItemNumber === BillingScheduleUtils.planManagementSupportItems.monthlyFee) {
			return new ManagementFee(data);
		}
		else if (json.supportItemNumber === BillingScheduleUtils.planManagementSupportItems.setup) {
			return new SetupFee(data);
		}

		return undefined;
	}


	public static jsonToBillingSchedule(json: any): BillingSchedule {
		const result = new BillingSchedule(json.planId);
		result.id = json.id;
		result.items = json.items.map( item => BillingScheduleUtils.parseItemJson(item) );

		return result;
	}
}
