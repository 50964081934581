export enum Region {
	nsw, act, vic, qld, sa, wa, nt, tas, remote, veryRemote
};

export namespace Region {
	const values = new Map<string, Region>([
		["NSW", Region.nsw],
		["ACT", Region.act],
		["VIC", Region.vic],
		["QLD", Region.qld],
		["SA", Region.sa],
		["WA", Region.wa],
		["NT", Region.nt],
		["TAS", Region.tas],
		["REMOTE", Region.remote],
		["VERY REMOTE", Region.veryRemote]
	]);

	const pgEnumValues = new Map<Region, string>([
		[Region.nsw, "NSW"],
		[Region.act, "ACT"],
		[Region.vic, "VIC"],
		[Region.qld, "QLD"],
		[Region.sa, "SA"],
		[Region.wa, "WA"],
		[Region.nt, "NT"],
		[Region.tas, "TAS"],
		[Region.remote, "REMOTE"],
		[Region.veryRemote, "VERY REMOTE"]
	]);

	const descriptions = new Map<Region, string>([
		[Region.nsw, "NSW"],
		[Region.act, "ACT"],
		[Region.vic, "VIC"],
		[Region.qld, "QLD"],
		[Region.sa, "SA"],
		[Region.wa, "WA"],
		[Region.nt, "NT"],
		[Region.tas, "TAS"],
		[Region.remote, "Remote"],
		[Region.veryRemote, "Very Remote"]
	]);

	export function toString(region: Region): string {
		return descriptions.get(region);
	}

	export function toPostgresEnum(region: Region): string {
		return pgEnumValues.get(region);
	}

	export function parse(value: string): Region {
		return values.get(value);
	}
}

export class RegionUtils {
	private static descriptions = new Map<Region, string>([
		[Region.nsw, "NSW"],
		[Region.act, "ACT"],
		[Region.vic, "VIC"],
		[Region.qld, "QLD"],
		[Region.sa, "SA"],
		[Region.wa, "WA"],
		[Region.nt, "NT"],
		[Region.tas, "TAS"],
		[Region.remote, "Remote"],
		[Region.veryRemote, "Very Remote"]
	]);

	private static pgEnumValues = new Map<Region, string>([
		[Region.nsw, "NSW"],
		[Region.act, "ACT"],
		[Region.vic, "VIC"],
		[Region.qld, "QLD"],
		[Region.sa, "SA"],
		[Region.wa, "WA"],
		[Region.nt, "NT"],
		[Region.tas, "TAS"],
		[Region.remote, "REMOTE"],
		[Region.veryRemote, "VERY REMOTE"]
	]);

	private static values = new Map<string, Region>([
		["NSW", Region.nsw],
		["ACT", Region.act],
		["VIC", Region.vic],
		["QLD", Region.qld],
		["SA", Region.sa],
		["WA", Region.wa],
		["NT", Region.nt],
		["TAS", Region.tas],
		["REMOTE", Region.remote],
		["VERY REMOTE", Region.veryRemote]
	]);

	static toString(region: Region): string {
		return RegionUtils.descriptions.get(region);
	}

	static toPostgresEnum(region: Region): string {
		return RegionUtils.pgEnumValues.get(region);
	}

	static parse(value: string): Region {
		return RegionUtils.values.get(value);
	}

	static allValues(): Region[] {
		return Array.from(RegionUtils.descriptions.keys());
	}
}
