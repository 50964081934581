import { Component, OnInit } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { UserType } from "@classes/user";
import { AdminService, ExpiringPlan } from "@services/admin.service";
import { Table, TableColumnHeading, SortType, StaticDataSource } from "@classes/tables";
import moment from 'moment';

@Component({
	"selector": "plans-expiring-soon",
	"styleUrls": ["./plansexpiringsoon.component.scss"],
	"templateUrl": "./plansexpiringsoon.component.html"
})
export class PlansExpiringSoonComponent extends PrivateComponent implements OnInit {

	private _dataLoaded: boolean = false;
	get dataLoaded(): boolean { return this._dataLoaded; }

	private readonly tableHeadings: TableColumnHeading[] = [
		{"propName": "clientName", "displayName": "Client", "sortType": SortType.name },
		{"propName": "endDate", "displayName": "Plan Expires", "sortType": SortType.date}
	];

	table: Table<ExpiringPlan> = new Table('expiringSoon', this.tableHeadings);

	constructor(private adminService: AdminService) {
		super();
		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Admin Dashboard', 'Plans Expiring Soon');
	}

	ngOnInit() {
		super.ngOnInit()
		this.loadData();
	}

	private async loadData() {
		if (this.user) {
			const data = await this.adminService.plansExipiringSoon();
			this.table.sourceData = StaticDataSource.from(data);
			this._dataLoaded = true;
		}
	}

	expiryTime(value: Date) {
		const planExpires = moment(value).startOf('day');
		const now = moment().startOf('day');

		if (planExpires.isSame(now)) {
			return "Today";
		}
		const duration = moment.duration(now.diff(planExpires));
		return duration.humanize();
	}
}
