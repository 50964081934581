import { Region, RegionUtils } from "@classes/regions";
import { Address, AddressClass } from "@classes/address";
import { Utils, DateUtils } from "@classes/utils";
import { EnumUtils, EnumMetadata } from "@classes/enumUtils";
import { AttachedFile } from "@classes/files";
import { ClientStatus } from "@classes/clientStatus";
import { RelationshipDescription } from "@classes/relationshipDescription";
import { Gender } from "@classes/gender";
import { ContactPreference } from "@classes/contactPreference";
import moment from "moment";

export interface UserRole {
	id: string;
	name: string;
	description: string;
}

export enum UserType {
	Admin, SupportCoordinator, Participant, User
}

export namespace UserType {
	const values = new Map<UserType, string>([
		[UserType.Admin, 'Admin'],
		[UserType.SupportCoordinator, 'Support Coordinator'],
		[UserType.Participant, 'Client'],
		[UserType.User, 'Nominee / Authorised Representative']
	]);

	const pgEnums = new Map<UserType, string>([
		[UserType.Admin, 'admin'],
		[UserType.SupportCoordinator, 'support_coordinator'],
		[UserType.Participant, 'participant'],
		[UserType.User, 'user']
	]);

	const parseMap = new Map<string, UserType>([
		['admin', UserType.Admin],
		['support_coordinator', UserType.SupportCoordinator],
		['participant', UserType.Participant],
		['user', UserType.User]
	]);

	export function parse(src: string): UserType {
		return parseMap.get(src);
	}

	export function toString(value: UserType): string {
		return values.get(value);
	}

	export function toPostgresEnum(value: UserType): string {
		return pgEnums.get(value);
	}

	export function allValues(): UserType[] {
		return Array.from(values.keys());
	}
}

/**
* Data structure that represents an account in the system
*/
export interface UserAccount {
	id: string;
	firstName?: string;
	lastName?: string;
	givenName?: string;
	name: string;
	accountType: UserType;
	createdBy: string;
	createdById: string;
	createdAt: Date;
	phone: string;
	email: string;
	ndisNumber?: string;
	status?: ClientStatus;
	hasLogin: boolean;
	gender?: Gender;
	contactMethod?: ContactPreference;
	disability?: string;
	dob?: Date;  //orashid
	deleted?: boolean;
	[propName: string]: any; // Allow additional properties
}

export enum RelationshipType {
	nominee, support_coordinator, authorised_representative
}

export class RelationshipTypeUtils extends EnumUtils<RelationshipType> {
	private static _instance: RelationshipTypeUtils;
	private static values = new Map<RelationshipType, EnumMetadata>([
		[RelationshipType.nominee, ['nominee', 'Admin']],
		[RelationshipType.support_coordinator, ['support_coordinator', 'Support Coordinator']],
		[RelationshipType.authorised_representative, ['authorised_representative', 'Authorised Representative']]
	]);

	protected constructor(src: Map<RelationshipType, EnumMetadata>) {
		super(src);
	}

	public static get instance(): RelationshipTypeUtils {
		if (RelationshipTypeUtils._instance === undefined) {
			RelationshipTypeUtils._instance = new RelationshipTypeUtils(RelationshipTypeUtils.values);
		}

		return RelationshipTypeUtils._instance;
	}
}

export class BankInfo {
	bsb: string;
	accountNumber: string;
}

export interface UserRelationship {
	userId: string;
	clientId: string;
	type: RelationshipType,
	validFrom?: Date;
	validTo?: Date;
	description: RelationshipDescription;
	notes: string;
	receiveStatements: boolean;
	isPrimaryContact: boolean;
	attachments: AttachedFile[];
	firstName?: string;
	lastName?: string;
	nickname?: string;
	email?: string;
	phone?: string;
	bankInfo?: BankInfo;
	dob?: Date; //orashid
}

export class UserRelationshipUtils {

	public static create(): UserRelationship {
		return {
			"userId": undefined,
			"clientId": undefined,
			"type": undefined,
			"validFrom": undefined,
			"validTo": undefined,
			"description": undefined,
			"notes": undefined,
			"receiveStatements": undefined,
			"isPrimaryContact": undefined,
			"attachments": [],
			"firstName": undefined,
			"lastName": undefined,
			"nickname": undefined,
			"email": undefined,
			"phone": undefined,
			"dob": undefined //orashid
		};
	}

	public static parse(src: any): UserRelationship {
		return {
			"userId": src.userId,
			"clientId": src.clientId,
			"type": RelationshipTypeUtils.instance.parse(src.type),
			"validFrom": DateUtils.parse(src.validFrom, null),
			"validTo": DateUtils.parse(src.validTo, null),
			"description": RelationshipDescription.parse(src.description),
			"notes": src.notes,
			"receiveStatements": src.receiveStatements,
			"isPrimaryContact": !!src.isPrimaryContact,
			"attachments": (src.attachments || []).map( props => AttachedFile.fromMetaData(props) ),
			"firstName": src.firstName,
			"lastName": src.lastName,
			"nickname": src.nickname,
			"email": src.email,
			"phone": src.phone,
			"bankInfo": src.bankInfo ? { "bsb": src.bankInfo.bsb, "accountNumber": src.bankInfo.accountNumber } : undefined,
			"dob": src.dob  //orashid
		};
	}

	public static toJSON(src: UserRelationship): any {
		return {
			"userId": src.userId,
			"clientId": src.clientId,
			"type": RelationshipTypeUtils.instance.toPostgresEnum(src.type),
			"validFrom": DateUtils.toString(src.validFrom),
			"validTo": DateUtils.toString(src.validTo),
			"description": RelationshipDescription.toJSON(src.description),
			"receiveStatements": !!src.receiveStatements,
			"isPrimaryContact": !!src.isPrimaryContact,
			"notes": Utils.trimString(src.notes) || undefined,
			"firstName": src.firstName,
			"lastName": src.lastName,
			"nickname": src.nickname,
			"email": src.email,
			"phone": src.phone,
			"bankInfo": src.bankInfo ? {...src.bankInfo} : undefined,
			"dob": src.dob //orashid
		};
	}

	public static clone(src: UserRelationship): UserRelationship {
		return {
			"userId": src.userId,
			"clientId": src.clientId,
			"type": src.type,
			"validFrom": src.validFrom,
			"validTo": src.validTo,
			"description": src.description,
			"notes": src.notes,
			"receiveStatements": src.receiveStatements,
			"isPrimaryContact": !!src.isPrimaryContact,
			"attachments": [...src.attachments],
			"firstName": src.firstName,
			"lastName": src.lastName,
			"nickname": src.nickname,
			"email": src.email,
			"phone": src.phone,
			"bankInfo": src.bankInfo ? {...src.bankInfo} : undefined,
			"dob": src.dob //orashid
		};
	}
}

export class UserAccountUtils {
	public static parse(src: any): UserAccount {
		return {
			"id": src.id,
			"name": src.name || `${src.firstName} ${src.lastName}`,
			"accountType": UserType.parse(src.accountType),
			"createdBy": src.createdBy,
			"createdById": src.createdById,
			"createdAt": DateUtils.parse(src.createdAt),
			"phone": src.phone,
			"email": src.email,
			"ndisNumber": src.ndisNumber,
			"firstName": src.firstName,
			"lastName": src.lastName,
			"status": ClientStatus.parse(src),
			"givenName": src.givenName,
			"receiveStatements": src.receiveStatements,
			"hasLogin": !!src.hasLogin,
			"gender": Gender.parse(src.gender),
			"contactMethod": ContactPreference.parse(src.contactMethod),
			"disability": src.disability,
			"dob": src.dob, //orashid
			"deleted": src.deleted,
		};
	}

	public static create(): UserAccount {
		return {
			"id": undefined,
			"name": undefined,
			"accountType": undefined,
			"createdBy": undefined,
			"createdById": undefined,
			"createdAt": undefined,
			"phone": undefined,
			"email": undefined,
			"ndisNumber": undefined,
			"firstName": undefined,
			"lastName": undefined,
			"givenName": undefined,
			"status": undefined,
			"receiveStatements": undefined,
			"hasLogin": false,
			"gender": undefined,
			"contactMethod": undefined,
			"disability": undefined,
			"dob": undefined, //orashid
			"deleted": undefined,
		};
	}

	public static toJSON(src: UserAccount): any {
		return {
			"id": src.id,
			"name": src.name,
			"accountType": UserType.toPostgresEnum(src.accountType),
			"createdBy": src.createdBy,
			"createdById": src.createdById,
			"createdAt": DateUtils.toString(src.createdAt),
			"phone": Utils.trimString(src.phone) || undefined,
			"email": Utils.trimString(src.email) || undefined,
			"ndisNumber": Utils.trimString(src.ndisNumber) || undefined,
			"firstName": src.firstName,
			"lastName": src.lastName,
			"givenName": src.givenName,
			"receiveStatements": src.receiveStatements,
			"status": ClientStatus.toJSON(src.status),
			"gender": Gender.toJSON(src.gender),
			"contactMethod": ContactPreference.toJSON(src.contactMethod),
			"disability": src.disability,
			"dob": src.dob, //orashid
			"deleted": src.deleted,
		};
	}

	public static clone(src: UserAccount): UserAccount {
		return {
			"id": src.id,
			"name": src.name,
			"accountType": src.accountType,
			"createdBy": src.createdBy,
			"createdById": src.createdById,
			"createdAt": src.createdAt,
			"phone": src.phone,
			"email": src.email,
			"ndisNumber": src.ndisNumber,
			"firstName": src.firstName,
			"lastName": src.lastName,
			"givenName": src.givenName,
			"hasLogin": src.hasLogin,
			"receiveStatements": src.receiveStatements,
			"status": ClientStatus.clone(src.status),
			"gender": src.gender,
			"contactMethod": src.contactMethod,
			"disability": src.disability,
			"dob": src.dob, //orashid
			"deleted": src.deleted,
		};
	}
}

export interface LoginHistory {
	date: Date;
	userId: string;
	trigger: string;
}

export class User implements UserAccount {
	readonly id: string;
	firstName: string;
	lastName: string;
	givenName: string;
	identityId: string;
	userType: UserType;
	email: string;
	phone: string;
	readonly cognitoUser: any;
	readonly autocreateAccount: boolean;
	readonly accountError: boolean;
	readonly uniqueEmail: boolean;
	readonly uniquePhone: boolean;
	dob: Date;
	ndisNumber: string;
	region?: Region;
	address?: Address;
	postalAddress?: Address;
	inactive: boolean;
	createdBy: string;
	createdById: string;
	createdAt: Date;
	accountType: UserType;
	lastAccess: Date;
	hasLogin: boolean;
	status: ClientStatus;
	receiveStatements: boolean;
	gender: Gender;
	contactMethod: ContactPreference;
	disability: string;
	deleted: boolean;
	last_sync_at: string;
	sync_errors: any;

	permissions: string[] = [];
	roles: string[] = [];

	static userTypeDisplayName(userType: UserType): string {
		return UserType.toString(userType);
	}

	get name(): string {
		return `${this.firstName} ${this.lastName}`;
	}

	constructor(src: any, cognitoUser?: any)	{
		this.id = src.id;
		this.firstName = src.firstName;
		this.lastName = src.lastName;
		this.givenName = src.givenName;
		this.identityId = src.cognitoIdentity;
		this.accountType =  Object.values(UserType).includes(src.accountType) ? src.accountType : UserType.parse(src.accountType);
		this.ndisNumber = src.ndisNumber;
		this.email = src.email;
		this.phone = src.phone;
		this.inactive = !!src.inactive;
		this.autocreateAccount = !!src.autocreateAccount;
		this.accountError = !!src.accountError;
		this.cognitoUser = cognitoUser;
		this.uniqueEmail = !!src.email && !!src.uniqueEmail;
		this.uniquePhone = !!src.phone && !!src.uniquePhone;
		this.region = RegionUtils.parse(src.region);
		this.dob = DateUtils.parse(src.dob);
		this.createdBy = src.createdBy;
		this.createdById = src.createdById;
		this.createdAt = DateUtils.parse(src.createdAt, null);
		this.lastAccess = DateUtils.parse(src.lastAccess, null);
		this.permissions = src.permissions || [];
		this.roles =  src.roles || [];
		this.hasLogin = src.hasLogin;
		this.status = ClientStatus.parse(src) || ClientStatus.empty();
		this.receiveStatements = src.receiveStatements;
		this.gender = Gender.parse(src.gender);
		this.contactMethod = ContactPreference.parse(src.contactMethod);
		this.disability = src.disability;
		this.dob = src.dob; //orashid 
		this.deleted = src.deleted;
		this.address = AddressClass.parseObject(src.address || {});
		this.postalAddress = AddressClass.parseObject(src.postalAddress || {});
		this.last_sync_at = src.last_sync_at ? moment(src.last_sync_at).format("DD-MM-YYYY hh:mm:ss") : undefined;
		this.sync_errors = this.parseSyncErrors(src.sync_errors);
	}

	private parseSyncErrors(sync_errors): any {
		try {
			return JSON.parse(sync_errors).body;
		} catch {
			return sync_errors;
		}
	}

	public hasPermission(permissionId: string): boolean {
		return this.permissions.includes(permissionId);
	}

	public hasRole(roleId: string): boolean {
		return this.roles.includes(roleId);
	}

	public static parse(src: any): User {
		return new User(src);
	}

	public get stateAndRegion(): string {
		const state = (this.address.state || this.postalAddress.state || '').toUpperCase().trim();
		const postcode = (this.address.postcode || this.postalAddress.postcode || '').trim();
		if (state == '' && postcode == ''){
			return 'N/A';
		} else {
			return state != '' && postcode != '' ? `${postcode}, ${state}` : `${postcode}${state}`;
		}
	}
}
