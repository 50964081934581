import { User } from "@classes/user";
import { Plan, PlanStatus } from "@classes/plans";
// import { Provider, ProviderService } from "@services/provider.service";
import { PlanService } from "@services/plan.service";
import { UserService } from "@services/user.service";
import { ServiceAgreement } from "@classes/serviceAgreement";
import { ServiceLocator } from '@services/locator.service';

export class QuarantinedFundsData {

	// - Private ----------------------------------------------------------------
	private _client: User;
	// private _allProviders: Provider[] = [];
	private _plans: Plan[] = [];
	private _serviceAgreements: ServiceAgreement[] = [];
	private _loaded: boolean = false;

	private userService: UserService;
	private planService: PlanService;
	// private providerService: ProviderService;

	private loadClient(): Promise<void> {
		return this.userService.loadUser(this.clientId).then( result => {
			this._client = result;
			return Promise.resolve();
		});
	}

	private loadPlans(): Promise<void> {
		return this.planService.listPlans(this.clientId).then( result => {
			this._plans = result;
			return Promise.resolve();
		});
	}

	// removed 31/8/22 nd-198 all providers lambda error
	// private loadAllProviders(): Promise<void> {
	// 	return this.providerService.getProviders().then( result => {
	// 		this._allProviders = result;
	// 		return Promise.resolve();
	// 	});
	// }

	// - Public -----------------------------------------------------------------
	constructor(private clientId: string) {
		this.userService = ServiceLocator.injector.get(UserService);
		// this.providerService = ServiceLocator.injector.get(ProviderService);
		this.planService = ServiceLocator.injector.get(PlanService);
	}

	// - Accessors --------------------------------------------------------------
	public get plans(): Plan[] {
		return this._plans || [];
	}

	public get currentPlan(): Plan {
		return this._plans.find( plan => plan.status === PlanStatus.current );
	}

	public get loaded(): boolean {
		return this._loaded;
	}

	// public get allProviders(): Provider[] {
	// 	return this._allProviders;
	// }

	public get serviceAgreements(): ServiceAgreement[] {
		return this._serviceAgreements;
	}

	public get client(): User {
		return this._client;
	}

	// - Methods ----------------------------------------------------------------
	public load(): Promise<void> {
		const promises = [
			this.loadClient(),
			// this.loadAllProviders(),
			this.loadPlans()
		];

		return Promise.all(promises).then( () => {
			this._loaded = true;
			return Promise.resolve();
		});
	}

	public async saveQuarantinedFunds(plan: Plan): Promise<void> {
		await this.planService.saveQuarantinedFunds(plan);
	}

	public async loadServiceAgreements(plan: Plan): Promise<void> {
		if (!plan) {
			this._serviceAgreements = [];
			return;
		}
		this._serviceAgreements = await this.planService.serviceAgreements(plan);
	}
}

