import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { User, UserType, UserRole } from "@classes/user";
import { OverlayService, DialogButton } from "@services/overlay.service";
import { ErrorUtils } from "@classes/errors";
import { Settings } from "@classes/settings";

@Component({
	"selector": "account-roles",
	"styleUrls": ["./roles.component.scss"],
	"templateUrl": "./roles.component.html"
})
export class AccountRolesComponent extends PrivateComponent implements OnInit {

	private _user: User;
	protected roles: UserRole[] = [];
	protected model: any = {
		"roles": {}
	};

	protected customPermissionsRole: string;
	public overrideCustomPermissions: boolean = false;

	constructor() {
		super();
		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Account Details', 'View Roles');
	}

	ngOnInit() {
		super.ngOnInit();
	}

	@Input()
	set account(value: User) {
		if (value !== undefined) {
			this._user = value;
			this.loadData();
		}
	}

	@Output()
	onSave: EventEmitter<User> = new EventEmitter<User>();

	public get saveButtonVisible(): boolean {
		let result = this.hasPermission('Account Details', 'Edit Roles');
		if (this.customPermissions) {
			result = result && this.overrideCustomPermissions;
		}

		return result;
	}

	public get customPermissions(): boolean {
		return this.model.roles[this.customPermissionsRole] === true;
	}

	private async loadData() {
		if (this.user) {

			this.roles = await this.userService.getRoles();
			this.model.roles = this.roles.reduce( (acc, role) => {
				acc[role.id] = this._user.hasRole(role.id);
				return acc;
			}, {});

			// Filter out the custom permissions role so that it's not displayed on the screen to the user
			this.customPermissionsRole = Settings.instance.get('customPermissionsRole').value;
		}
	}

	/**
	* Returns an array of the selected role ids for the user. Removes the special "Custom Permissions" role from this list, as it's handled
	* as a special case on the back end.
	*/
	private get selectedRoleIds(): string[] {
		return Object.keys(this.model.roles).filter( id => !!this.model.roles[id] ).filter( roleId => roleId !== this.customPermissionsRole);
	}

	/**
	* Determines if any roles have been selected for the user. Ignores the "Custom Permissions" role.
	* @return { boolean } Returns true if no roles are selected, false otherwise.
	*/
	protected get noRolesSelected(): boolean {
		return this.selectedRoleIds.length === 0;
	}

	protected showWarningDialog() {

		if (!this.customPermissions) {
			this.saveRoles();
			return;
		}

		const btns: DialogButton[] = [{
			"text": "Cancel",
			"handler": () => { OverlayService.hide(); }
		}, {
			"text": "Save",
			"handler": () => { this.saveRoles(); }
		}];

		OverlayService.showDialog("Confirm", "Saving roles will override any custom permissions assigned to this user.<br />Are you sure you want to continue?", btns);
	}

	private saveRoles() {
		OverlayService.show();
		this.userService.saveRoles(this._user.id, this.selectedRoleIds).then( user => {

			OverlayService.hide();
			this.onSave.emit(user);

		}).catch( e => {

			OverlayService.hide();
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "An unknown error occurred"));

		});
	}
}
