import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrivateComponent } from "@classes/private.component";
import { UserType } from "@classes/user";
import { ErrorUtils } from "@classes/errors";
import { LineItemStatus, LineItemStatusUtils } from "@classes/invoices";
import { Table, TableColumnHeading, SortType, Alignment, SortDirection, StaticDataSource } from "@classes/tables";
import { MenuBuilder } from "@services/navmenu.service";
import { ProdaService, ProdaSubmission, ProdaSubmissionItem } from "@services/proda.service";
import { OverlayService } from "@services/overlay.service";
import { DataExchangeService } from "@services/dataexchange.service";
import { XeroService } from "@services/xero.service";
import { saveAs } from 'file-saver';
import { Settings } from "@classes/settings";
import { config } from "../../../../../config";
import moment from 'moment';

@Component({
	"styleUrls": ["./submission.component.scss"],
	"templateUrl": "./submission.component.html"
})
export class ProdaSubmissionComponent extends PrivateComponent implements OnInit {
	private _dataLoaded: boolean = false;
	private fileId: string = undefined;
	private _fileItems: ProdaSubmissionItem[] = []
	private _submission: ProdaSubmission = undefined;

	public detailsVisible: boolean = true;
	public onlyErrors: boolean = false;

	get dataLoaded(): boolean { return this._dataLoaded; }
	get submissionId(): string { return this.fileId; }
	get extractId(): string { return this._submission ? this._submission.extract : ""; }
	get filename(): string { return this._submission ? this._submission.filename : ""; }
	get responseFilename(): string { return this._submission ? this._submission.responseFilename : ""; }

	get hasResponseFile(): boolean { return !!this._submission }
	get prodaFileType(): string { return this._submission ? this._submission.prodaFileType : ""; }
	get claimCount(): number { return this._submission ? this._submission.count : undefined; }
	get reconciled(): number { return this._submission ? this._submission.reconciled : undefined; }
	get discrepancies(): number { return this._submission ? this._submission.discrepancies : undefined; }
	get isLocked(): boolean { return this._submission ? this._submission.locked : false; }

	get totalClaimed(): number {
		return this._submission ? this._fileItems.reduce( (acc, cur) => {
			return acc + cur.amountClaimed;
		}, 0) : undefined;
	}

	get totalPaid(): number {
		return this._submission ? this._fileItems.reduce( (acc, cur) => {
			return acc + cur.amountPaid;
		}, 0) : undefined;
	}

	private readonly tableHeadings: TableColumnHeading[] = [
		{"propName": "invoiceNumber", "displayName": "Bill #", "sortType": SortType.text },
		{"propName": "claimReference", "displayName": "Claim Ref", "sortType": SortType.text },
		{"propName": "providerName", "displayName": "Provider", "sortType": SortType.text },
		{"propName": "clientName", "displayName": "Client", "sortType": SortType.name },
		{"propName": "amountClaimed", "displayName": "Amount Claimed", "sortType": SortType.numeric, "alignment": Alignment.center },
		{"propName": "amountPaid", "displayName": "Amount Paid", "sortType": SortType.numeric, "alignment": Alignment.center },
		{"propName": "discrepancy", "displayName": "Discrepancy", "sortType": SortType.numeric, "alignment": Alignment.center },
		{"propName": "resolved", "displayName": "Status", "sortType": SortType.numeric, "alignment": Alignment.center },
		{"propName": "", "displayName": "", "sortType": SortType.none, "alignment": Alignment.center }
	];

	private readonly tableName: string = 'proda.submission';

	table: Table<ProdaSubmissionItem> = new Table(this.tableName, this.tableHeadings);

	private buildMenu(): void {
		const menuBuilder = new MenuBuilder();
		menuBuilder.addHome();
		menuBuilder.addBackButton();

		if (!this._submission.responseId) {
			menuBuilder.addHandler('undo', 'Reset Submission', () => { this.confirmResetSubmission(); });
		}

		menuBuilder.addHandler('cloud-upload-alt', "PRODA Upload", () => {
			this.generatePRODASubmissionFile();
		});

		if (['local', 'dev', 'qa', 'uat', 't2p'].includes(config.stage)) {
			menuBuilder.addHandler('code', "Dummy Success File", () => { this.generateDummySuccessFile(); }, () => !!this._submission.responseId);
		}

		if (!this._submission.locked) {

			menuBuilder.addRoute('cloud-download-alt', "PRODA Response", `/proda/response/new/${this.fileId}`, () => !!this._submission.responseId);

			if (!!this._submission.responseId) {
				// menuBuilder.addHandler('file-invoice-dollar', 'Generate ABA File', () => {
				// 	this.generateABAFile();
				// }, () => {
				// 	return this.disableABAMenuItem();
				// });

				// menuBuilder.addHandler('key', 'Lock ABA File', () => {
				// 	this.lockABAFile();
				// }, () => {
				// 	return this.disableABAMenuItem();
				// });

				// menuBuilder.addHandler('file-upload', 'Send to Xero', () => {
				// 	this.sendToXero();
				// }, () => {
				// 	return this.disableXeroMenuItem();
				// });
			}
		}
		// else {
		// 	menuBuilder.addHandler('file-invoice-dollar', 'Download ABA File', () => {
		// 		this.downloadABAFile();
		// 	}, () => {
		// 		return this.disableABAMenuItem();
		// 	});
		// }

		menuBuilder.done();
	}

	private disableABAMenuItem(): boolean {

		// Not enabled if the data hasn't yet been loaded
		if (!this._dataLoaded) {
			return true;
		}

		// Not enabled if it's not explicitly enabled in the settings
		const settings = Settings.instance;
		if (!settings.has('proda')) {
			return true;
		}

		const value = settings.get('proda');
		if (!value.abaEnabled) {
			return true;
		}

		// Only enabled if there are all items in the file are resolved and there are no errors
		return this._fileItems.some( item => item.resolved === false || this.hasError(item) );
	}

	private disableXeroMenuItem(): boolean {

		// Not enabled if the data hasn't yet been loaded
		if (!this._dataLoaded) {
			return true;
		}

		// Not enabled if there are no file items
		if (this._fileItems.length === 0) {
			return true;
		}

		// Only enabled if there are all items in the file are resolved and there are no errors
		return this._fileItems.some( item => item.resolved === false || this.hasError(item) );
	}

	private loadFile(): Promise<any> {
		if (!this.user || !this.fileId) {
			return Promise.reject(undefined);
		}

		this._dataLoaded = false;
		OverlayService.show();

		return this.prodaService.getSubmission(this.fileId).then( submission => {

			this._submission = submission;
			return this.prodaService.loadSubmissionFile(this.fileId);

		}).then( items => {

			OverlayService.hide();
			this._dataLoaded = true;
			this._fileItems = items;
			this.table.sourceData = StaticDataSource.from(this._fileItems);

			return Promise.resolve();

		}).catch( err => {

			OverlayService.hide();
			console.log(err);

		});
	}

	ngOnInit() {
		super.ngOnInit()

		const preferenceSet = DataExchangeService.get(`${this.tableName}.sortpreference.set`);
		if (!preferenceSet) {
			this.table.setDefaultSort(this.tableHeadings[7], SortDirection.ascending);
		}

		this.route.params.subscribe( params => {

			// If an invoice Id has been specified on the path, load the invoice data
			this.fileId = params.id;
			if (this.fileId !== undefined) {
				this.loadFile().then( () => {
					this.buildMenu();
				}).catch( err => { });
			}
		});
	}

	constructor(private prodaService: ProdaService, private route: ActivatedRoute, private xeroService: XeroService) {
		super()

		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Proda', 'PRODA Access');
}

	private generateABAFile(): void {
		OverlayService.show();
		const processingDate = moment().format('YYYYMMDD_HHmm');
		this.prodaService.generateABAFile(this.submissionId).then( abaFile => {

			const file = new Blob([abaFile], { "type": "text/plain;charset=utf-8" });
			//saveAs(file, "abafile.txt");
			saveAs(file, `${this._submission.extract}_${processingDate}_${this.submissionId}_aba.txt`);
			OverlayService.hide();

		}).catch( err => {

			OverlayService.hide();
			OverlayService.showError("Error", "Unable to generate ABA File");

		});
	}

	private downloadABAFile(): void {
		OverlayService.show();
		const processingDate = moment().format('YYYYMMDD_HHmm');
		this.prodaService.downloadABAFile(this.submissionId).then( abaFile => {

			const file = new Blob([abaFile], { "type": "text/plain;charset=utf-8" });
			//saveAs(file, "abafile.txt");
			saveAs(file, `${this._submission.extract}_${processingDate}_${this.submissionId}_aba.txt`);
			OverlayService.hide();

		}).catch( err => {

			OverlayService.hide();
			OverlayService.showError("Error", "Unable to download ABA File");

		});
	}

	private errorTitle(item: ProdaSubmissionItem): string | undefined {
		if (item.errorMessage != null) {
			// See if we have a structured Error Message - if not, proceed as normal
			const parsedErrorMessage = JSON.parse( JSON.stringify( item.errorMessage ) );
			if (parsedErrorMessage && parsedErrorMessage.title) {
				return parsedErrorMessage.title;
			}
		}

		if (!item.responseItemId) {
			return "Error";
		}

		if (!item.resolved && item.discrepancy !== null) {
			return "Payment Discrepancy"
		}

		if (!this.prodaService.validateBankInfo(item.bank) && item.reimbursement) {
			return "Reimbursement Claim";
		}

		return "Missing Or Incomplete Data";
	}

	errorReason(item: ProdaSubmissionItem): string | undefined {
		if (item.errorMessage != null) {
			// See if we have a structured Error Message - if not, proceed as normal
			const parsedErrorMessage = JSON.parse( JSON.stringify( item.errorMessage ) );
			if (parsedErrorMessage && parsedErrorMessage.message) {
				return parsedErrorMessage.message;
			}
		}

		if (item.status == LineItemStatus.discrepancy) {
			return "Discrepancy found";
		}

		if (!item.responseItemId && !item.resolved) {
			return "Unable to find matching Proda payment";
		}

		if (!item.providerId) {
			return "Provider not set";
		}

		if (!item.resolved) {

			return item.discrepancy === null ? "Item not marked as resolved" : "Unresolved discrepancy";

		}

		if (!this.prodaService.validateBankInfo(item.bank)) {
			return item.reimbursement ? `Missing or invalid bank information for client` : `Missing or invalid bank information for provider ${item.providerName}`;
		}
	}

	hasDiscrepancy(item: ProdaSubmissionItem): boolean {
		if (!item.responseId) {
			return false;
		}

		if (!item.responseItemId && !item.resolved) {
			return true;
		}

		return item.discrepancy !== null && !item.resolved;
	}

	isDeleted(item: ProdaSubmissionItem): boolean {
		return item.status === LineItemStatus.deleted;
	}

	hasError(item: ProdaSubmissionItem): boolean {
		if (!item.responseId) {
			return false;
		}

		return (!item.responseItemId && !item.successFileItemId) || !item.providerId || !item.resolved || !this.prodaService.validateBankInfo(item.bank);
	}

	showError(event: Event, item: ProdaSubmissionItem): boolean {
		event.cancelBubble = true;

		OverlayService.showError(this.errorTitle(item), this.errorReason(item));
		return false;
	}

	filter(value: string): void {

		const searchValue = (value || "").toLowerCase();

		const filteredItems = this._fileItems.filter( item => {

			return this.onlyErrors ? this.hasError(item) : true;

		}).filter( item => {

			const searchableFields = [
				item.invoiceNumber || "",
				item.claimReference || "",
				item.clientName || "",
				item.providerName || "",
				item.amountClaimed ? `${item.amountClaimed}` : "",
				item.amountPaid ? `${item.amountPaid}` : "",
				item.discrepancy ? `${item.discrepancy}` : ""
			];

			return searchableFields.map( x => x.toLowerCase() ).some( prop => prop.includes(searchValue) );

		});

		this.table.sourceData = StaticDataSource.from(filteredItems);
	}

	clearFilter(control): void {
		control.value = '';
		this.table.sourceData = StaticDataSource.from(this._fileItems);
	}

	lockABAFile(): void {
		OverlayService.show();
		this.prodaService.lockABAFile(this.fileId).then( () => {
			OverlayService.hide();
			OverlayService.showSuccess("ABA File Locked","Remittance advice notifications queued for dispatch");

			this._submission.locked = true;
			this.prodaService.clearCache();
			this.buildMenu();

		}).catch( err => {
			OverlayService.hide();
			let msg = "Failed to lock ABA File";
			const re = /^\[\d{3}\]\s/;
			if (err.response && err.response.data && re.test(err.response.data.errorMessage)) {
				msg = err.response.data.errorMessage.replace(re, '');
			}
			OverlayService.showError("Error", msg);
		});
	}

	private async generatePRODASubmissionFile() {
		OverlayService.show();
		try {
			const submissionFile = await this.prodaService.getPRODASubmissionFile(this._submission.id);
			const file = new Blob([submissionFile.document], { "type": "text/plain;charset=utf-8" });
			saveAs(file, submissionFile.filename);
			OverlayService.hide();
		}
		catch(e) {
			OverlayService.hide();
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "An unknown error occurred"));
		}
	}

	private confirmResetSubmission() {
		OverlayService.showDialog(
			"Confirm",
			"Are you sure you want to reset this submission?<br />All services delivered and invoices will be returned to the 'locked' state",
			[{
				"text": "Don't reset",
				"handler": () => { OverlayService.hide(); }
			}, {
				"text": "Reset",
				"handler": () => {
					OverlayService.hide();
					this.resetSubmission();
				}
			}]
		);
	}

	private resetSubmission(): void {
		OverlayService.show();
		this.prodaService.resetSubmission(this.submissionId).then( () => {
			OverlayService.hide();
			this.router.navigate(['/paymentfiles'])
		}).catch( e => {
			OverlayService.hide();
			console.log(e);
			OverlayService.showError("Error", ErrorUtils.getErrorMessage(e, "An unknown error occurred"));
		});
	}

	private generateDummySuccessFile() {
		let r = 80000000 + Math.floor(Math.random() * 10000000);
		let result: string[] = ['RegistrationNumber,NDISNumber,SupportsDeliveredFrom,SupportsDeliveredTo,SupportNumber,ClaimReference,Quantity,UnitPrice,GSTCode,PaidTotalAmount,Payment Request Number,Participant Name,Capped Price,Payment Request Status,Error Message,ClaimType,CancellationReason,ABN of Support Provider,'];


		this._fileItems.forEach( item => {

			console.log(item);

			let line = [
				'4050019899', // registration number
				item.ndisNumber,
				moment(item.supportsDeliveredFrom).format('DD/MM/YYYY'), // Supports delivered from
				moment(item.supportsDeliveredFrom).format('DD/MM/YYYY'), // Supports delivered to
				item.supportItemNumber,
				item.claimReference,
				Number(item.quantity).toFixed(3),
				Number(item.amountClaimed / item.quantity).toFixed(3),
				item.gstCode,
				Number(item.amountClaimed).toFixed(3), // Paid total amount
				r++, // Payment Request Number,
				item.clientName,
				'No',
				'SUCCESSFUL',
				'""',
				undefined,
				undefined,
				undefined,
				undefined
			];

			result.push(line.join(","));
		});

		const file = new Blob([result.join("\n")], { "type": "text/plain;charset=utf-8" });
		saveAs(file, `${this._submission.extract}_${this.submissionId}_response.csv`);
	}

	public lineItemStatusString(status: LineItemStatus): string {
		return LineItemStatusUtils.toString(status);
	}
}
