import { Component, OnInit, Input } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { User } from "@classes/user";
import { SupportCoordinatorService } from "@services/supportcoordinator.service";
import * as tables from "@classes/tables";

@Component({
	"selector": "support-coordinator-clients",
	"styleUrls": [],
	"templateUrl": "./supportcoordinatorclients.component.html"
})
export class SupportCoordinatorClientsComponent extends PrivateComponent implements OnInit {

	private readonly tableHeadings: tables.TableColumnHeading[] = [
		{"propName": "clientName", "displayName": "Client Name", "sortType": tables.SortType.name },
		{"propName": "ndisNumber", "displayName": "NDIS Number", "sortType": tables.SortType.text },
		{"propName": undefined, "displayName": "", "sortType": tables.SortType.none }
	];

	private _supportCoordinatorId: string = undefined;
	private _dataLoaded: boolean = false;

	private get isSelf(): boolean {
		return this.user ? this.user.id === this.supportCoordinatorId : false;
	}

	@Input()
	set supportCoordinatorId(value: string) {
		this._supportCoordinatorId = value;
		this.loadClients();
	}

	get supportCoordinatorId(): string {
		return this._supportCoordinatorId;
	}

	table: tables.Table<User> = new tables.Table('supportCoordinatorClients', this.tableHeadings);

	get dataLoaded(): boolean {
		return this._dataLoaded;
	}

	readonly ascending: tables.SortDirection = tables.SortDirection.ascending;
	readonly descending: tables.SortDirection = tables.SortDirection.descending;

	constructor(private supportCoordinatorService: SupportCoordinatorService) {
		super();
	}

	ngOnInit() {
		super.ngOnInit();
	}

	private loadClients(forceReload: boolean = false) {
		this._dataLoaded = false;

		if (!this.supportCoordinatorId) {
			return;
		}

		this.supportCoordinatorService.getClients(this.supportCoordinatorId, forceReload).then( result => {

			this._dataLoaded = true;
			this.table.sourceData = tables.StaticDataSource.from(result);

		}).catch( err => {

			console.log(err);
			this._dataLoaded = true;
			this.table.sourceData = tables.StaticDataSource.from([]);

		});
	}

	forceReload(): void {
		this.loadClients(true);
	}
}
