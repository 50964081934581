import { Cache } from 'aws-amplify';
import { UserService } from './user.service';
import { ServiceLocator } from './locator.service';

export class StorageService {

	private static instance: StorageService;

	private constructor(private cache: Cache|any) {}

	private static getInstance(): StorageService {
		if (StorageService.instance === undefined) {

			const userService = ServiceLocator.injector.get(UserService);
			const user = userService.getUser();

			if (user !== undefined && user.id !== undefined) {
				const config = {
					"keyPrefix": user.id,
					"storage": window.localStorage
				};

				StorageService.instance = new StorageService( Cache.createInstance(config) );
			}
		}

		return StorageService.instance;
	}

	public static set(key: string, value: any): void {
		const instance = StorageService.getInstance();
		if (instance !== undefined) {
			instance.cache.setItem(key, value);
		}
	}

	public static get(key: string): any {
		const instance = StorageService.getInstance();
		if (instance !== undefined) {
			return instance.cache.getItem(key);
		}
	}

	public static clear(key: string): void {
		const instance = StorageService.getInstance();
		if (instance !== undefined) {
			return instance.cache.removeItem(key);
		}
	}

	public static clearAll(): void {
		const instance = StorageService.getInstance();
		if (instance !== undefined) {
			return instance.cache.clear();
		}
	}
}
