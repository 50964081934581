import { Injectable } from '@angular/core';
import { RestService, API } from './rest.service';
import { Cache } from '@classes/cache';
import { UserAccount } from '@classes/user';
import { BankInformation } from "@classes/bankinfo";
import { Address, AddressClass } from '@classes/address';
import { AttachedFile } from '@classes/files';
import { ProdaService } from './proda.service';
import { ServiceAgreement } from "@classes/serviceAgreement";
import { SyncErrorService } from './syncerror.service';
import moment from 'moment';

export interface Provider {
	id: string;
	name: string;
	legalName?: string;
	postalAddress?: Address;
	streetAddress?: Address;
	abn?: string;
	acn?: string;
	tfn?: string;
	firstName?: string;
	lastName?: string;
	phone?: string;
	email?: string;
	bankAccountNumber?: string;
	bankAccountBSB?: string;
	bankAccountName?: string;
	bankAccountParticulars?: string;
	deleted: boolean;
	abnList?: any[];
	last_sync_at?: string;
	sync_errors?: any;
}

export interface LinkedProvider {
	id: string;
	name: string;
	provider: Provider;
	lastModified: Date;
	attachments: AttachedFile[];
}

@Injectable({ providedIn: 'root' })
export class ProviderService {

	private _providerCache = new Cache<Provider>();

	constructor(private restService: RestService, private prodaService: ProdaService, private syncErrorService: SyncErrorService) {}

	private parseProvider(item: any): Provider {
		return {
			"id": item.id,
			"name": item.name,
			"legalName": item.legalName,
			"bankAccountName": item.bankAccountName,
			"bankAccountNumber": item.bankAccountNumber,
			"bankAccountBSB": item.bankAccountBSB,
			"firstName": item.firstName,
			"lastName": item.lastName,
			"phone": item.phone,
			"email": item.email,
			"abn": item.abn,
			"acn": item.acn,
			"tfn": item.taxFileNumber,
			"bankAccountParticulars": item.bankAccountParticulars,
			"deleted": item.deleted,
			"postalAddress": AddressClass.parse(item.postalAddress1, item.postalAddress2, item.postalSuburb, item.postalState, item.postalPostcode),
			"streetAddress": AddressClass.parse(item.streetAddress1, item.streetAddress2, item.streetSuburb, item.streetState, item.streetPostcode),
			"last_sync_at": item.last_sync_at ? moment(item.last_sync_at).format("DD-MM-YYYY hh:mm:ss") : undefined,
			"sync_errors": this ? this.parseSyncErrors(item.sync_errors) : undefined
		};
	}

	private parseSyncErrors(sync_errors): any {
		try {
			sync_errors = JSON.parse(sync_errors).body;
		} finally {
			return this.syncErrorService.format(sync_errors);
		}
	}

	private mapProvider(src): Provider[] {
		src = src || [];
		return src.map( this.parseProvider );
	}

	async getAllProviders(forceReload?: boolean): Promise<Provider[]> {

		if (forceReload) {
			this._providerCache.invalidate();
		}

		if (this._providerCache.needsRefresh) {

			const metaInfo = await this.restService.get(API.providers, 'providers/info');
			let total = metaInfo.count
			let results = [];
			let count = 5000;
			let page = 0;
			let promises = [];

			if(total > 0){
				while (page*count < total){
					promises.push(this.getProviders(count, page).then(nextResult => {
						results = results.concat(nextResult);
					}));
					page++;
				}
			}
			await Promise.all(promises);
			this._providerCache.items = this.mapProvider(results);
		}

		return Promise.resolve( this._providerCache.items );
	}

	async getProviders(count, page){
		return await this.restService.get(API.providers, `providers/${count}/${page}`);
	}

	async getProviderForInvoice(invoiceId: string): Promise<Provider> {
		const result = await this.restService.get(API.providers, `provider/invoice/${invoiceId}`);
		let provider = this.parseProvider(result);

		if (!!provider.abn) {
			provider.abnList = await this.restService.get(API.providers, `provider/abncount/${provider.abn}`);
		}

		return provider;
	}

	getProvider(providerId: string): Promise<Provider> {

		return this.restService.get(API.providers, `provider/${providerId}`).then( result => {
			return this.parseProvider(result);
		});

		// return this.getProviders().then( providers => {

		// 	const result = providers.find( item => item.id === providerId );
		// 	if (result) {
		// 		return Promise.resolve(result);
		// 	}

		// 	return Promise.reject("Provider not found");
		// });
	}

	async loadLinkedClients(providerId: string): Promise<UserAccount[]> {
		const clients: any[] = await this.restService.get(API.providers, `provider/${providerId}/clients`);
		return clients;
		// return clients.map( item => {
		// 	return {
		// 		"id": item.id,
		// 		"identityId": undefined,
		// 		"firstName": item.firstName,
		// 		"lastName": item.lastName,
		// 		"ndisNumber": item.ndisNumber,
		// 		"inactive": !!item.inactive
		// 	};
		// });
	}

	async loadFilteredBills(providerId: string, clientId: string, filter: any, print: boolean = false): Promise<any> {
		const params = {
			"providerId": providerId,
			"clientId": clientId,
			"filter": filter
		};
		if (print) {
			return this.restService.post(API.providers, "statement", params).then( result => {
				return Promise.resolve( result );
			});
		} else {
			return this.restService.post(API.providers, "invoices", params).then( result => {
				return Promise.resolve( result );
			});
		}
	}

	requestAuthCode(providerId: string): Promise<boolean> {
		return this.restService.get(API.providers, `authcode/provider/${providerId}`).then( result => {

			return Promise.resolve(true);

		}).catch( err => {

			return Promise.reject( err.response.data);

		});
	}

	unlock(providerId: string, authcode: string): Promise<boolean> {
		const params = {
			"id": providerId,
			"authCode": authcode
		};

		return this.restService.post(API.providers, "unlock", params).then( result => {
			return Promise.resolve( result.valid );
		});
	}

	newProvider(): Provider {
		return {
			"id":undefined,
			"name":undefined,
			"legalName":undefined,
			"postalAddress":undefined,
			"streetAddress":undefined,
			"abn":undefined,
			"acn":undefined,
			"tfn":undefined,
			"firstName":undefined,
			"lastName":undefined,
			"phone":undefined,
			"email":undefined,
			"bankAccountNumber":undefined,
			"bankAccountBSB":undefined,
			"bankAccountName":undefined,
			"bankAccountParticulars":undefined,
			"deleted": undefined,
			"last_sync_at": undefined,
			"sync_errors": undefined
		};
	}

	save(provider: Provider, authCode?: string): Promise<boolean> {

		let providerData = {
			"id": provider.id,
			"name": provider.name,
			"legalName": provider.legalName,
			"postalAddress": provider.postalAddress,
			"streetAddress": provider.streetAddress,
			"abn": provider.abn,
			"acn": provider.acn,
			"taxFileNumber": provider.tfn,
			"firstName": provider.firstName,
			"lastName": provider.lastName,
			"phone": provider.phone,
			"email": provider.email,
			"bankAccountNumber": provider.bankAccountNumber,
			"bankAccountBSB": provider.bankAccountBSB,
			"bankAccountName": provider.bankAccountName,
			"bankAccountParticulars": provider.bankAccountParticulars
		};

		// Do not include the banking information if an unlock code hasn't been provided
		if (!authCode) {
			delete providerData.bankAccountName;
			delete providerData.bankAccountNumber;
			delete providerData.bankAccountParticulars;
		}

		let postData = {
			"provider": providerData,
			"authCode": authCode
		};

		return this.restService.post(API.providers, "provider", postData).then( result => {
			this.prodaService.clearCache();
			this._providerCache.invalidate();
			return Promise.resolve( result.success );
		});
	}

	public async deleteBankInfo(providerId: string, bankInfo: BankInformation): Promise<boolean> {
		try {
			const postData = {
				"providerId": providerId,
				"authcode": bankInfo.authcode
			};

			const response = await this.restService.delete(API.providers, "provider/bankinfo", postData);

			return response;
		} catch (e) {
			throw(e);
		}
	}

	public async unlinkClient(providerId: string, clientId: string): Promise<any> {
		await this.restService.delete(API.providers, `provider/${providerId}/${clientId}`);
		return;
	}

	private attachmentMapper(data: any): AttachedFile {
		return AttachedFile.fromMetaData({
			"id": data.id,
			"name": data.filename,
			"mimeType": data.mimeType,
			"size": Number(data.size),
			"md5": data.md5,
			"dateAdded": moment(data.dateAdded).toDate()
		});
	};

	private linkedProviderMapper(item: any): LinkedProvider {
		const m = moment(item.lastModified, "YYYY-MM-DD");
		const date = m.isValid() ? m.startOf('day').toDate() : undefined;
		const attachmentData = item.attachments || [];
		return {
			"id": item.id,
			"name": item.name,
			"provider": item.provider,
			"lastModified": date,
			"attachments": attachmentData.map( data => this.attachmentMapper(data) )
		};
	}

	public async loadLinkedProviders(clientId: string): Promise<LinkedProvider[]> {
		const data = await this.restService.get(API.providers, `providers/linkedto/${clientId}`);
		return data.map( item => this.linkedProviderMapper(item) );
	}

	public async attachFile(clientId: string, providerId: string, file: AttachedFile, description?: string): Promise<void> {

		return await file.content.then( content => {
			return {
				"file": {
					"name": file.name,
					"mimeType": file.mimeType,
					"content": content.replace(/^.*,/, ''), // strip out the "data:[<mediatype>][;base64]," header info
					"size": file.size,
					"md5": file.md5
				},
				"metadata": {
					"description": description || null,
					"targets": [{
						"id": clientId,
						"type": "client",
					}, {
						"id": providerId,
						"type": "provider",
					}]
				}
			};
		}).then( postData => {

			return this.restService.put(API.attachments, "", postData);

		}).then( () => {

			return Promise.resolve();

		});
	}

	public linkProviders(clientId: string, providers: Provider[]): Promise<void> {
		const postData = {
			"clientId": clientId,
			"providers": providers.map( provider => provider.id )
		};

		return this.restService.put(API.providers, "providers", postData).then( () => {
			return Promise.resolve();
		});
	}

	public mergeProviders(source: string, dest: string): Promise<void> {
		const postData = {
			"source": source,
			"dest": dest
		};

		return this.restService.post(API.providers, "merge", postData).then( result => {
			if (result.success) {

				// Remove the deleted provider from the cache, if necessary.
				const providers = this._providerCache.items;
				if (providers !== undefined) {
					const idx = providers.findIndex( provider => provider.id === source );
					if (idx >= 0) {
						providers.splice(idx, 1);
					}
					this._providerCache.items = providers;
				}
				return Promise.resolve();
			}
			return Promise.reject("Merge failed");
		});
	}

	public async invoiceProviderSearch(searchPhrase: string, unrestricted: boolean = false): Promise<Provider[]> {
		const postData = {
			"search": searchPhrase,
			"unrestricted": !!unrestricted
		};

		return this.restService.post(API.providers, "search", postData).then( result => {
			return result.map( this.parseProvider );
		});

	}

	public async deleteProvider(providerId: string): Promise<boolean> {
		const response = await this.restService.delete(API.providers, `provider/${providerId}`, {});
		if (response.success) {
			this._providerCache.invalidate();
		}

		return response.success;
	}

	public async serviceAgreementsForProvider(providerId: string): Promise<ServiceAgreement[]> {
		const response = await this.restService.get(API.providers, `provider/serviceAgreements/${providerId}`);
		return response.map( ServiceAgreement.parse );
	}
}
