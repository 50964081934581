import { Component, OnInit, Input } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { Table, TableColumnHeading, SortType, Alignment, StaticDataSource } from "@classes/tables";
import { User, UserRelationship, RelationshipType } from "@classes/user";
import { RelationshipDescription } from "@classes/relationshipDescription";
import { assert } from "@classes/errors";
import { LinkedUserService } from "@services/linkedusers.service";

@Component({
	"selector": "linked-clients",
	"styleUrls": ["./linkedClients.component.scss"],
	"templateUrl": "./linkedClients.component.html"
})
export class LinkedClientsComponent extends PrivateComponent implements OnInit {

	// Table headings for list of plans
	private readonly tableHeadings: TableColumnHeading[] = [
		{"propName": "name", "displayName": "Name", "sortType": SortType.none },
		{"propName": "description", "displayName": "Description", "sortType": SortType.none},
		{"propName": "validTo", "displayName": "Valid Until", "sortType": SortType.none, "alignment": Alignment.center},
		{"propName": "auth", "displayName": "Auth", "sortType": SortType.none, "alignment": Alignment.center},
		{"propName": "isPrimaryContact", "displayName": "Primary Contact", "sortType": SortType.none, "alignment": Alignment.center},
		{"propName": "receiveStatements", "displayName": "Statements", "sortType": SortType.none, "alignment": Alignment.center}
	];

	private _user: User;
	private _clients: UserRelationship[] = [];
	private _dataLoaded: boolean = false;

	private async loadData() {
		this._dataLoaded = false;
		try {
			assert(this._user);
			this._clients = await this.linkedUserService.getLinkedClients(this._user.id);
		}
		catch (e) {
			console.log(e);
			this._clients = [];
		}
		finally {
			this.table.sourceData = StaticDataSource.from(this._clients);
			this._dataLoaded = true;
		}
	}

	constructor(private linkedUserService: LinkedUserService) {
		super();
	}

	ngOnInit() {
		super.ngOnInit();
	}

	@Input()
	public set user(value: User) {
		if (value !== this._user) {
			this._user = value;
			this.loadData();
		}
	}

	public descriptionName: (description: RelationshipDescription) => string = RelationshipDescription.toString;

	public get dataLoaded(): boolean {
		return this._dataLoaded;
	}

	public isAuthorised(link: UserRelationship): boolean {
		return link.type === RelationshipType.authorised_representative;
	}

	public readonly table: Table<UserRelationship> = new Table('linkedClients', this.tableHeadings);
}
