import { Component, OnInit } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { UserType } from "@classes/user";
import { AdminService } from "@services/admin.service";
import { Table, TableColumnHeading, SortType, StaticDataSource } from "@classes/tables";

@Component({
	"selector": "clients-without-plans",
	"styleUrls": ["./clientswithoutplans.component.scss"],
	"templateUrl": "./clientswithoutplans.component.html"
})
export class ClientsWithoutPlansComponent extends PrivateComponent implements OnInit {

	private _dataLoaded: boolean = false;
	get dataLoaded(): boolean { return this._dataLoaded; }

	private readonly tableHeadings: TableColumnHeading[] = [
		{"propName": "clientName", "displayName": "Client", "sortType": SortType.name },
		{"propName": "planEnded", "displayName": "Plan Expired", "sortType": SortType.date}
	];

	table: Table<any> = new Table('clientsWithoutPlans', this.tableHeadings);

	constructor(private adminService: AdminService) {
		super();
		this.allowedUserTypes = [UserType.Admin];
		this.requirePermission('Admin Dashboard', 'Clients Without Plans');
	}

	ngOnInit() {
		super.ngOnInit()
		this.loadData();
	}

	private async loadData() {
		const data = await this.adminService.clientsWithoutPlans();
		this.table.sourceData = StaticDataSource.from(data);
		this._dataLoaded = true;
	}

}
