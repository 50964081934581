import { NgModule }                                from '@angular/core';
import { RouterModule, Routes }                    from '@angular/router';

import { LoginComponent }                          from './components/login/login.component';
import { ForgotPasswordComponent }                 from './components/password/forgot/forgotpassword.component';
import { DashboardComponent }                      from './components/dashboard/dashboard.component';
import { ChangePwdComponent }                      from './components/password/change/changepassword.component';
import { ResetPasswordComponent }                  from './components/password/reset/resetpassword.component';
import { CreateUserComponent }                     from './components/createuser/createuser.component';
import { AccessDeniedComponent }                   from './components/accessdenied/accessdenied.component';
import { AccountDetailsComponent }                 from './components/accountdetails/accountdetails.component';
import { CategoryTransactionsComponent }           from './components/participant/categorytransactions/categorytransactions.component';
// import { TransactionComponent }                    from './components/administrator/transactions/show/transaction.component';
import { ListAccountsComponent }                   from './components/listaccounts/listaccounts.component';
import { LinkedSupportCoordinatorsComponent }      from './components/administrator/linkedaccounts/linkedaccounts.component';
import { ClientInvoiceComponent }                  from './components/participant/invoices/show/invoice.component';
import { AllClientInvoicesComponent }              from './components/participant/invoices/all/allinvoices.component';
import { ProvidersComponent }                      from './components/administrator/providers/list/providers.component';
import { ProviderComponent }                       from './components/administrator/providers/edit/provider.component';
import { NewExtractComponent }                     from './components/administrator/proda/newextract/newextract.component';
// import { GovernmentPaymentFileComponent }          from './components/administrator/proda/addFile/addFile.component';
import { ProdaFileListComponent }                  from './components/administrator/proda/list/listFiles.component';
import { ProdaSubmissionComponent }                from './components/administrator/proda/submission/submission.component';
import { XeroComponent }                           from './components/administrator/proda/xero/xero.component';
import { ProdaResponseComponent }                  from './components/administrator/proda/addResponse/addresponse.component';
import { ProdaReconcilliationComponent }           from './components/administrator/proda/reconcilliation/reconcilliation.component';
import { MaintenanceComponent }                    from './components/maintenance/maintenance.component';
import { FindInvoiceComponent }                    from './components/administrator/v2invoicing/find/findinvoice.component';
import { EditInvoiceComponent }                    from './components/administrator/v2invoicing/edit/editinvoice.component';
import { EditPlanComponent }                       from './components/administrator/plans/edit/editplan.component';
// import { NDISPriceListComponent }                  from './components/administrator/prices/list/ndisprices.component';
// import { NDISPriceEditComponent }                  from './components/administrator/prices/edit/editprice.component';
import { TasksComponent }                          from './components/administrator/tasks/tasks.component';
import { ReportsComponent }                        from './components/reports/reports.component';
import { BillingReportComponent }                  from './components/reports/billing/billingreport.component';
import { ClientReportComponent }                   from './components/reports/client/clientreport.component';
import { QuarantinedFundsComponent }               from './components/accountdetails/quarantinedFunds/quarantinedfunds.component';
import { RoleEditorComponent }                     from './components/su/roleeditor/roleeditor.component';
import { MultipleSubmissionReportComponent }       from './components/reports/invoices/invoices.component';
import { ReconciliationReportComponent }           from './components/reports/reconciliation/reconciliationReport.component';
import { DataEntryReportComponent }                from './components/reports/dataEntry/dataentry.component';
import { BillsUnderInvestigationComponent }        from './components/administrator/v2invoicing/investigations/investigations.component';
import { DataImportComponent }                     from './components/administrator/dataimport/edit/dataimport.component';
import { DataImportListComponent }                 from './components/administrator/dataimport/list/dataimports.component';
import { ImportedBillsComponent }                  from './components/administrator/v2invoicing/importedBills/importedbills.component';
import { ClientCheckComponent }                    from './components/clientcheck/clientcheck.component';
import { NomineeComponent }                        from "./components/accountdetails/nominee/nominee.component";
import { ProviderSpendComponent }                  from "./components/providerSpend/providerSpend.component";
import { PlanUtilisationComponent }                from "./components/planUtilisation/planUtilisation.component";
import { ProviderInvoicesComponent }               from "./components/providerInvoices/providerInvoices.component";

const routes: Routes = [
	{ "path": "", "redirectTo": "/login", "pathMatch": "full" },
	{ "path": "login", "component": LoginComponent },
	{ "path": "maintenance", "component": MaintenanceComponent },
	{ "path": "password/forgot", "component": ForgotPasswordComponent },
	{ "path": "password/change", "component": ChangePwdComponent },
	{ "path": "password/reset", "component": ResetPasswordComponent },
	{ "path": "dashboard/:userId", "component": DashboardComponent },
	{ "path": "dashboard", "component": DashboardComponent },
	{ "path": "account", "component": AccountDetailsComponent },
	{ "path": "account/:userId", "component": AccountDetailsComponent },
	{ "path": "account/:userId/quarantined-funds", "component": QuarantinedFundsComponent },
	{ "path": "account/:userId/:noteId", "component": AccountDetailsComponent },
	{ "path": "createuser", "component": CreateUserComponent },
	{ "path": "accessdenied", "component": AccessDeniedComponent },
	{ "path": "clockcheck", "component": ClientCheckComponent },
	{ "path": "transactions/:planId/:categoryId", "component": CategoryTransactionsComponent },
	{ "path": "transactions/:clientId/:planId/:categoryId", "component": CategoryTransactionsComponent },
	// { "path": "transaction/:id", "component": TransactionComponent },
	{ "path": "supportcoordinators/:clientId", "component": LinkedSupportCoordinatorsComponent },
	{ "path": "listaccounts", "component": ListAccountsComponent },
	// { "path": "old/invoices", "component": InvoiceLandingComponent },
	// { "path": "old/invoices/new", "component": EditInvoiceComponentOld },
	// { "path": "old/invoice/:id", "component": EditInvoiceComponentOld },
	{ "path": "plan/:id", "component": EditPlanComponent },
	// { "path": "billing/new", "component": EditInvoiceComponent },
	{ "path": "billing/:id", "component": EditInvoiceComponent },
	{ "path": "client/invoices/:clientId", "component": AllClientInvoicesComponent },
	{ "path": "client/invoice/:id", "component": ClientInvoiceComponent },
	{ "path": "providers", "component": ProvidersComponent },
	{ "path": "provider/:id", "component": ProviderComponent },
	{ "path": "provider/:id/:noteId", "component": ProviderComponent },
	{ "path": "proda/submission/new", "component": NewExtractComponent },
	// { "path": "proda/submission/new", "component": GovernmentPaymentFileComponent },
	{ "path": "proda/submission/:id", "component": ProdaSubmissionComponent },
	{ "path": "proda/submission/:submissionId/:submissionItemId", "component": ProdaReconcilliationComponent },
	{ "path": "proda/response/new/:id", "component": ProdaResponseComponent },
	{ "path": "proda/xero", "component": XeroComponent },
	{ "path": "paymentfiles", "component": ProdaFileListComponent },
	{ "path": "billing", "component": FindInvoiceComponent },
	{ "path": "investigation", "component": BillsUnderInvestigationComponent },
	// { "path": "prices", "component": NDISPriceListComponent },
	// { "path": "price/new", "component": NDISPriceEditComponent },
	// { "path": "price/:date/:id", "component": NDISPriceEditComponent },
	{ "path": "reports", "component": ReportsComponent },
	{ "path": "reports/billing/summary", "component": BillingReportComponent },
	{ "path": "reports/billing/daily", "component": DataEntryReportComponent },
	{ "path": "reports/client", "component": ClientReportComponent },
	{ "path": "reports/submissions", "component": MultipleSubmissionReportComponent },
	{ "path": "reports/reconciliation", "component": ReconciliationReportComponent },
	{ "path": "superuser/roles-editor", "component": RoleEditorComponent },
	{ "path": "tasks", "component": TasksComponent },
	{ "path": "dataimport/new", "component": DataImportComponent },
	{ "path": "dataimport/:id", "component": DataImportComponent },
	{ "path": "dataimports", "component": DataImportListComponent },
	{ "path": "imported", "component": ImportedBillsComponent },
	{ "path": "relationship/:clientId/:userId", "component": NomineeComponent },
	{ "path": ":clientId/providerspend", "component": ProviderSpendComponent },
	{ "path": ":clientId/planutilisation", "component": PlanUtilisationComponent },
	{ "path": "invoices/:clientId/:providerId/:planId", "component": ProviderInvoicesComponent }

];

@NgModule({
	imports: [ RouterModule.forRoot(routes) ],
	exports: [ RouterModule ]
})

export class AppRoutingModule {}
