import { AttachedFile } from "./files";
import { DateUtils } from "@classes/utils";
import { Entity } from "@classes/entity";
import { PlanBudget, BudgetType } from "@classes/budget";
import { assert } from "@classes/errors";
import { Provider } from "@classes/provider";

// <region> Interfaces ---------------------------------------------------------

export interface ServiceAgreementItem {
	id?: string;
	supportItemNumber: string;
	supportItemName: string;
	budget: PlanBudget;
}

export interface ServiceDelivered {
	date: Date,
	categoryNumber: number;
	supportItem: string;
	total: number;
}

export interface ServiceAgreementCategory {
	id?: string;
	categoryNumber: number;
	budget: PlanBudget;
	exclusiveBudget: PlanBudget;
	items: ServiceAgreementItem[];
}

export type ServiceAgreementBudget = ServiceAgreementCategory | ServiceAgreementItem;

export interface ServiceAgreement {
	id?: string;
	client: Entity;
	provider: Provider;
	dateFrom: Date;
	dateTo: Date;
	planId: string;
	categories: ServiceAgreementCategory[];
	notes: string;
	// planServicesDelivered: ServiceDelivered[];
}
// </region>

// <region> Classes
export class ServiceAgreement {
	public id?: string;
	public client: Entity = Entity.blank();
	public provider: Provider = Provider.blank();
	public dateFrom: Date;
	public dateTo: Date;
	public planId: string;
	public categories: ServiceAgreementCategory[] = [];
	public notes: string;
	public attachments: AttachedFile[] = [];

	public category(categoryNumber: number): ServiceAgreementCategory {
		return this.categories.find( category => category.categoryNumber === categoryNumber );
	}

	public toJSON(): string {
		return ServiceAgreement.toJSON(this);
	}

	private static categorySort(a: ServiceAgreementCategory, b: ServiceAgreementCategory): number {
		return a.categoryNumber - b.categoryNumber;
	}

	public static parse(src: any): ServiceAgreement {
		if (!src) {
			return undefined;
		}

		const result = new ServiceAgreement();
		result.id = src.id;
		result.client = Entity.parse(src.client);
		result.provider = Provider.parse(src.provider);
		result.dateFrom = DateUtils.parse(src.dateFrom);
		result.dateTo = DateUtils.parse(src.dateTo);
		result.planId = src.planId;
		result.categories = (src.categories || []).map( ServiceAgreementCategory.parse ).sort( ServiceAgreement.categorySort );
		result.notes = src.notes;
		// result.planServicesDelivered = (src.servicesDelivered || []).map( ServiceDelivered.parse );
		result.attachments = (src.attachments || []).map( (item: AttachedFile) => {
			return AttachedFile.parse(item);
		} );
		return result;
	}

	public static toJSON(src: ServiceAgreement): any {
		assert( !!src );
		return {
			"id": src.id,
			"client": Entity.toJSON(src.client),
			"provider": Provider.toJSON(src.provider),
			"dateFrom": DateUtils.toString(src.dateFrom),
			"dateTo": DateUtils.toString(src.dateTo),
			"planId": src.planId,
			"categories": (src.categories || []).map( ServiceAgreementCategory.toJSON ),
			"notes": src.notes
		};
	}

	public static clone(src: ServiceAgreement): ServiceAgreement {
		assert( !!src );
		const result = new ServiceAgreement();
		result.id = src.id;
		result.client = Entity.clone(src.client);
		result.provider = Provider.clone(src.provider);
		result.dateFrom = DateUtils.clone(src.dateFrom);
		result.dateTo = DateUtils.clone(src.dateTo);
		result.planId = src.planId;
		result.categories = (src.categories || []).map( ServiceAgreementCategory.clone );
		result.notes = src.notes;
		// result.planServicesDelivered = (src.planServicesDelivered || []).map( ServiceDelivered.clone );
		result.attachments = (src.attachments || []).map( (item: AttachedFile) => {
			return AttachedFile.fromMetaData(item);
		} );
		return result;
	}

	public static blank(): ServiceAgreement {
		return new ServiceAgreement();
	}
}
// </region>

// <region> Namespaces ---------------------------------------------------------
export namespace ServiceAgreementItem {
	export function parse(src: any): ServiceAgreementItem {
		if (!src) {
			return undefined;
		}

		return {
			"id": src.id,
			"supportItemNumber": src.supportItemNumber,
			"supportItemName": src.supportItemName,
			"budget": PlanBudget.parse(src.budget, BudgetType.serviceAgreementItem)
		};
	}

	export function toJSON(src: ServiceAgreementItem): any {
		assert( !!src );
		return {
			"id": src.id,
			"supportItemNumber": src.supportItemNumber,
			"supportItemName": src.supportItemName,
			"budget": PlanBudget.toJSON(src.budget)
		};
	}
}

export namespace ServiceAgreementCategory {
	export function parse(src: any): ServiceAgreementCategory {
		if (!src) {
			return undefined;
		}

		return {
			"id": src.id,
			"categoryNumber": Number(src.categoryNumber),
			"budget": PlanBudget.parse(src.budget, BudgetType.serviceAgreementCategory),
			"exclusiveBudget": PlanBudget.parse(src.exclusiveBudget, BudgetType.serviceAgreementCategory),
			"items": (src.items || []).map( ServiceAgreementItem.parse )
		};
	}

	export function toJSON(src: ServiceAgreementCategory): any {
		assert( !!src );
		return {
			"id": src.id,
			"categoryNumber": src.categoryNumber,
			"budget": PlanBudget.toJSON(src.budget),
			"exclusiveBudget": PlanBudget.toJSON(src.exclusiveBudget),
			"items": (src.items || []).map( ServiceAgreementItem.toJSON )
		};
	}

	export function clone(src: ServiceAgreementCategory): ServiceAgreementCategory {
		return ServiceAgreementCategory.parse( ServiceAgreementCategory.toJSON(src) );
	}
}

export namespace ServiceDelivered {
	export function parse(src: any): ServiceDelivered {
		return {
			"date": DateUtils.parse(src.date, DateUtils.ISO8601msFormat),
			"categoryNumber": Number(src.supportCategoryNumber),
			"supportItem": src.supportItemNumber,
			"total": Number(src.total)
		};
	}

	export function clone(src: ServiceDelivered): ServiceDelivered {
		return {
			"date": DateUtils.clone(src.date),
			"categoryNumber": src.categoryNumber,
			"supportItem": src.supportItem,
			"total": src.total
		};
	}
}
// </region>
