import { PrivateComponent } from "@classes/private.component";
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SupportDataItem, SupportDataType } from "@classes/invoices";
import { InvoiceService } from "@services/invoice2.service";
import { FilterSettings } from "./filterSettings";

@Component({
	"selector": "investigation-filter",
	"styleUrls": ["./investigationFilter.component.scss"],
	"templateUrl": "./investigationFilter.component.html"
})
export class InvestigationFilterComponent extends PrivateComponent implements OnInit {

	private _invoiceSupportData: SupportDataType[] = undefined;

	private async getInvoiceSupportData(): Promise<void> {
		this._invoiceSupportData = await this.invoiceService.getInvoiceSupportData();
	}

	@Input()
	public filter: FilterSettings = {
		"categories": new Set<string>(),
		"search": ""
	};

	@Output()
	public changed: EventEmitter<void> = new EventEmitter<void>();

	public allInvestigationTypes: SupportDataItem[];

	public investigationTypeName(value: SupportDataItem): string {
		return value.name;
	}

	public isSelected(value: SupportDataItem) {
		return this.filter.categories.has(value.id);
	}

	public toggleAll() {
		this.allInvestigationTypes.forEach( investigationType => {
			const method = this.filter.categories.has(investigationType.id) ? this.filter.categories.delete : this.filter.categories.add;
			method.call(this.filter.categories, investigationType.id);
		});
		this.search();
	}

	public toggleFilter(value: SupportDataItem) {

		const method = this.filter.categories.has(value.id) ? this.filter.categories.delete : this.filter.categories.add;
		method.call(this.filter.categories, value.id);
		this.search();
	}

	constructor(private invoiceService: InvoiceService) {
		super();
	}

	ngOnInit() {
		super.ngOnInit();

		const invTypeSupportDataTypeId: string = "3242c9f5-dcaa-3e85-bad5-77a60532ef04";

		this.getInvoiceSupportData().then( () => {
			const invType = this._invoiceSupportData.find(sdType => sdType.id === invTypeSupportDataTypeId);
			if (invType) {
				this.allInvestigationTypes = invType.supportData;

				this.allInvestigationTypes.forEach( item => {
					this.filter.categories.add( item.id );
				});
			}
		});
	}

	private search() {
		this.changed.next();
	}

	public clearSearch(): void {
		this.filter.search = "";
		this.search();
	}

	public searchOnReturn(event: KeyboardEvent): void {
		if (event.key === 'Enter') {
			this.search();
		}
	}

	public invoiceSearch(): void {
		this.search();
	}
}
