import { AttachedFile } from "./files";
import { Region } from "./regions";
import { SupportCategory, SupportItem } from "./supports";
import { DateUtils } from "@classes/utils";
import { PlanStatus } from "@classes/planStatus";
import { ServiceAgreement } from "@classes/serviceAgreement";
import { PlanBudget, BudgetType } from "@classes/budget";
import { assert, EAssertionFailed } from "@classes/errors";
import moment from "moment";

export { PlanStatus } from "@classes/planStatus";
export { PlanBudget, BudgetType } from "@classes/budget";

// <region> Interfaces ---------------------------------------------------------
export interface ProjectedSpend {
	supportCategory: number[];
	budget: number;
	currentSpend: number;
	progress: number;
	overUnder: number;
	projectedSpend: number;
	warningLevel: number;
}

// export interface ProviderToPlan {
// 	id: string;
// 	providerId: string;
// 	providerName: string;
// 	providerABN?: string;
// 	supportCategoryNumber: number;
// 	supportItemId: string;
// 	total: number
// 	budget?: PlanBudget;
// }


// export interface ProviderList {
// 	providers?: ProviderToPlan[];
// }

// export interface PlanSupportItem extends ProviderList  {
export interface PlanSupportItem  {
	supportItemNumber: string;
	supportItem?: SupportItem;
	total?: number;
	itemBudget?: PlanBudget;
	bookingCode?: string;
	specifiedItem: boolean;
	quantity?: number;
	servicesDelivered?: number;
}

// export interface PlanSupportCategory extends ProviderList {
export interface PlanSupportCategory {
	supportCategory: SupportCategory;
	total: number;
	categoryBudget: PlanBudget;
	exclusiveBudget?: PlanBudget;
	bookingCode?: string;
	supportItems: PlanSupportItem[];
	servicesDelivered?: number;
	readonly variance?: number;
	readonly varianceLevel?: number;
}

interface IPlan {
	id?: string;
	client: string;
	startDate: Date;
	endDate: Date;
	status: PlanStatus;
	total: number;
	budget: PlanBudget;                      // Budget details set out by the plan
	exclusiveBudget: PlanBudget;             // Budget details used for invoice entry only.
	projectedSpend: ProjectedSpend[];
	dashboardInclusionDate: Date;
	dashboardComment: string;
	region: Region;
	supportCategories: PlanSupportCategory[];
	serviceAgreements: ServiceAgreement[];
	attachments: AttachedFile[];
	pace: boolean;
}

export interface Plan extends IPlan {}
// </region>


// <region> Namespaces ---------------------------------------------------------
namespace ProjectedSpend {
	export function parse(src: any): ProjectedSpend {
		if (!src) {
			return undefined;
		}

		return {
			"supportCategory": Array.isArray(src.supportCategory) ? [...src.supportCategory] : [],
			"budget": src.budget,
			"currentSpend": src.currentSpend,
			"progress": src.progress,
			"overUnder": src.overUnder,
			"projectedSpend": src.projectedSpend,
			"warningLevel": src.warningLevel
		};
	}

	export function toJSON(src: ProjectedSpend): any {
		if (!src) {
			return undefined;
		}

		return {
			"supportCategory": Array.isArray(src.supportCategory) ? [...src.supportCategory] : [],
			"budget": src.budget,
			"currentSpend": src.currentSpend,
			"progress": src.progress,
			"overUnder": src.overUnder,
			"projectedSpend": src.projectedSpend,
			"warningLevel": src.warningLevel
		};
	}

	export function clone(src: ProjectedSpend): ProjectedSpend {
		if (!src) {
			return undefined;
		}

		return {
			"supportCategory": Array.isArray(src.supportCategory) ? [...src.supportCategory] : [],
			"budget": src.budget,
			"currentSpend": src.currentSpend,
			"progress": src.progress,
			"overUnder": src.overUnder,
			"projectedSpend": src.projectedSpend,
			"warningLevel": src.warningLevel
		};
	}

	export function blank(): ProjectedSpend {
		return {
			"supportCategory": [],
			"budget": undefined,
			"currentSpend": undefined,
			"progress": undefined,
			"overUnder": undefined,
			"projectedSpend": undefined,
			"warningLevel": undefined
		};
	}
}

// export namespace ProviderToPlan {
// 	export function parse(src: any): ProviderToPlan {
// 		return {
// 			"id": src.id,
// 			"providerId": src.providerId,
// 			"providerName": src.providerName,
// 			"providerABN": src.providerABN,
// 			"supportCategoryNumber": src.supportCategoryNumber,
// 			"supportItemId": src.supportItemId,
// 			"total": src.total,
// 			"budget": PlanBudget.parse(src.budget)
// 		};
// 	}

// 	export function toJSON(src: ProviderToPlan): any {
// 		return {
// 			"id": src.id,
// 			"providerId": src.providerId,
// 			"providerName": src.providerName,
// 			"providerABN": src.providerABN,
// 			"supportCategoryNumber": src.supportCategoryNumber,
// 			"supportItemId": src.supportItemId,
// 			"total": src.total,
// 			"budget": PlanBudget.toJSON(src.budget)
// 		};
// 	}

// 	export function clone(src: ProviderToPlan): ProviderToPlan {
// 		return {
// 			"id": src.id,
// 			"providerId": src.providerId,
// 			"providerName": src.providerName,
// 			"providerABN": src.providerABN,
// 			"supportCategoryNumber": src.supportCategoryNumber,
// 			"supportItemId": src.supportItemId,
// 			"total": src.total,
// 			"budget": PlanBudget.clone(src.budget)
// 		};
// 	}
// }

export namespace PlanSupportItem {
	export function parse(src: any) {
		if (!src) {
			return undefined;
		}

		return {
			"supportItemNumber": src.supportItem.supportItemNumber,
			"supportItem": SupportItem.parse( src.supportItem ),
			"total": src.total,
			"itemBudget": PlanBudget.parse(src.budget, BudgetType.supportItem),
			"bookingCode": src.bookingCode,
			"specifiedItem": src.specifiedItem,
			"quantity": src.quantity,
			"servicesDelivered": src.servicesDelivered
			// "providers": (src.providers || []).map( ProviderToPlan.parse )
		}
	}

	export function clone(src: PlanSupportItem): PlanSupportItem {
		if (!src) {
			return undefined;
		}

		return {
			"supportItemNumber": src.supportItem.supportItemNumber,
			"supportItem": SupportItem.clone( src.supportItem ),
			"total": src.total,
			"itemBudget": PlanBudget.clone(src.itemBudget, BudgetType.supportItem),
			"bookingCode": src.bookingCode,
			"specifiedItem": src.specifiedItem,
			"quantity": src.quantity,
			"servicesDelivered": src.servicesDelivered
			// "providers": (src.providers || []).map( ProviderToPlan.clone )
		}
	}

	export function toJSON(src: PlanSupportItem): any {
		if (!src) {
			return undefined;
		}

		return {
			"categoryNumber": src.supportItem.supportCategoryId,
			"supportItem": src.supportItemNumber,
			"total": src.total,
			"itemBudget":  PlanBudget.toJSON(src.itemBudget),
			"bookingCode": src.bookingCode,
			"specifiedItem": src.specifiedItem,
			"quantity": src.quantity,
			"servicesDelivered": src.servicesDelivered
			// "providers": (src.providers || []).map( ProviderToPlan.toJSON )
		};
	}
}

export namespace PlanSupportCategory {

	export function parse(src: any): PlanSupportCategory {

		return {
			"supportCategory": {
				"id": src.categoryNumber,
				"name": src.supportCategory
			},
			"total": src.total,
			"categoryBudget": PlanBudget.parse(src.budget, BudgetType.category),
			"exclusiveBudget": PlanBudget.parse(src.exclusiveBudget, BudgetType.category),
			"bookingCode": src.bookingCode,
			"supportItems": (src.supportItems || []).map( PlanSupportItem.parse ),
			// "providers": (src.providers || []).map( ProviderToPlan.parse ),
			"servicesDelivered": src.servicesDelivered,
			"variance": src.variance,
			"varianceLevel": src.varianceLevel
		};
	}

	export function clone(src: PlanSupportCategory): PlanSupportCategory {
		return {
			"supportCategory": {...src.supportCategory},
			"total": src.total,
			"categoryBudget": PlanBudget.clone(src.categoryBudget, BudgetType.category),
			"exclusiveBudget": PlanBudget.clone(src.exclusiveBudget, BudgetType.category),
			"bookingCode": src.bookingCode,
			"supportItems": (src.supportItems || []).map( PlanSupportItem.clone ),
			"servicesDelivered": src.servicesDelivered,
			// "providers": (src.providers || []).map( ProviderToPlan.clone ),
			"variance": src.variance,
			"varianceLevel": src.varianceLevel
		};
	}

	export function toJSON(src: PlanSupportCategory): any {
		return {
			"supportCategory": SupportCategory.toJSON(src.supportCategory),
			"categoryNumber": src.supportCategory.id,
			"name": src.supportCategory.name,
			"total": src.total,
			"budget": PlanBudget.toJSON(src.categoryBudget), // Won't be used by the server, but included for completeness
			"exclusiveBudget": PlanBudget.toJSON(src.exclusiveBudget), // As above, not used by the server.
			"bookingCode": src.bookingCode,
			"servicesDelivered": src.servicesDelivered,
			// "providers": (src.providers || []).map( ProviderToPlan.toJSON ),
			"variance": src.variance,
			"varianceLevel": src.varianceLevel,
			"supportItems": (src.supportItems || []).map( PlanSupportItem.toJSON )
		};
	}
}

// </region>

export class Plan implements IPlan {
	public id?: string;
	public client: string;
	public startDate: Date;
	public endDate: Date;
	public status: PlanStatus;
	public total: number;
	public budget: PlanBudget;
	public exclusiveBudget: PlanBudget;
	public projectedSpend: ProjectedSpend[] = [];
	public dashboardInclusionDate: Date;
	public dashboardComment: string;
	public region: Region;
	public supportCategories: PlanSupportCategory[] = [];
	public serviceAgreements: ServiceAgreement[] = [];
	public attachments: AttachedFile[] = [];
	public pace: boolean;

	private constructor(plan?: IPlan) {
		if (!!plan) {
			this.id = plan.id;
			this.client = plan.client;
			this.startDate = plan.startDate;
			this.endDate = plan.endDate;
			this.status = plan.status;
			this.total = plan.total;
			this.budget = PlanBudget.clone(plan.budget, BudgetType.plan);
			this.exclusiveBudget = PlanBudget.clone(plan.exclusiveBudget, BudgetType.plan);
			this.dashboardInclusionDate = plan.dashboardInclusionDate;
			this.dashboardComment = plan.dashboardComment;
			this.region = plan.region;
			this.projectedSpend = (plan.projectedSpend || []).map( ProjectedSpend.clone );
			this.supportCategories = (plan.supportCategories || []).map( PlanSupportCategory.clone );
			this.serviceAgreements = (plan.serviceAgreements || []).map( ServiceAgreement.clone );
			this.attachments = (plan.attachments || []).map( (item: AttachedFile) => {
				return AttachedFile.fromMetaData(item);
			} );
			this.pace = plan.pace;
		}
	}

	public clone(src: Plan): Plan {
		return Plan.clone(src);
	}

	/**
	* Attempts to find a service agreement for the given provider, service date and support item
	*/
	private findMatchingServiceAgreement(providerId: string, serviceDate: Date, supportItem: SupportItem, exclusiveBudget: boolean): PlanBudget {
		if (!serviceDate || !providerId) {
			return undefined;
		}

		// Convert the service date to a moment instance
		const serviceDateMoment = moment(serviceDate);

		// Find all agreements that are for the specified provider and cover the service date
		const agreements = (this.serviceAgreements || []).filter( agreement => {
			const agreementStart = moment(agreement.dateFrom);
			const agreementEnd = moment(agreement.dateTo);

			return agreement.provider.id === providerId && serviceDateMoment.isBetween(agreementStart, agreementEnd, undefined, "[]");
		});

		// At this point, we could still have multiple service agreements, but only one
		// of them (if any) cancontain a budget for the specified category.
		const serviceAgreementCategory = agreements.map( agreement => agreement.category( supportItem.supportCategoryId ) ) // map agreement to the matching agreement category (if any)
		                                           .filter( x => !!x )                                                      // Remove missing items from the array. Should be left with an array with zero or one items only
		                                           .pop();                                                                  // Pop the item from the array (will return undefined if the array is empty)

		try {
			assert(!!serviceAgreementCategory);
		}
		catch (e) {
			// If a suitable service agreement cannot be found, exit now.
			return undefined;
		}

		// We have a matching service agreement, check for a budget on individual items.
		const serviceAgreementItem = serviceAgreementCategory.items.find( item => item.supportItemNumber === supportItem.supportItemNumber );
		if (serviceAgreementItem) {
			return serviceAgreementItem.budget;
		}
		else {
			return exclusiveBudget ? serviceAgreementCategory.exclusiveBudget : serviceAgreementCategory.budget;
		}
	}

	/**
	* For a given category, support item, service date and provider, locate the appropriate budget against which the the support
	* item will be billed. The budget will be one of a plan category, plan item, service agreement category or service agreement item.
	*/
	public findBudget(supportItem: SupportItem, serviceDate: Date, providerId?: string, exclusiveBudget: boolean = false): PlanBudget {

		try {

			// The service date must be covered by this plan. If not, return undefined.
			assert(moment(serviceDate).isBetween(moment(this.startDate), moment(this.endDate), undefined, '[]'));

			const supportCategory = this.supportCategories.find( category => category.supportCategory.id === supportItem.supportCategoryId );

			// If there's no budget for the support category, abandon ship here
			assert(!!supportCategory);

			const budget: PlanBudget = this.findMatchingServiceAgreement(providerId, serviceDate, supportItem, exclusiveBudget);
			if (budget) {
				return budget;
			}

			const planSupportItem = (supportCategory.supportItems || []).find( item => item.supportItemNumber === supportItem.supportItemNumber );
			if (planSupportItem) {
				return planSupportItem.itemBudget;
			}

			return exclusiveBudget ? supportCategory.exclusiveBudget : supportCategory.categoryBudget;
		}
		catch (e) {
			if (e instanceof EAssertionFailed) {
				return undefined;
			}

			throw e;
		}
	}

	public findCategoryBudget(supportCategoryId: number, exclusiveBudget: boolean = false): PlanBudget {
		try {
			const supportCategory = this.supportCategories.find( category => category.supportCategory.id === supportCategoryId );
			assert(!!supportCategory);
			return exclusiveBudget ? supportCategory.exclusiveBudget : supportCategory.categoryBudget;
		}
		catch (e) {
			return undefined;
		}
	}

	public static parse(src: any): Plan {
		const result = new Plan();

		result.id = src.id;
		result.client = src.userId;
		result.startDate = DateUtils.parse(src.startDate);
		result.endDate = DateUtils.parse(src.endDate);
		result.status = PlanStatus.parse(src.status);
		result.total = src.total;
		result.budget = PlanBudget.parse(src.budget, BudgetType.plan);
		result.exclusiveBudget = PlanBudget.parse(src.exclusiveBudget, BudgetType.plan);
		result.projectedSpend = (src.projectedSpend || []).map( ProjectedSpend.parse );
		result.dashboardInclusionDate = src.dashboardInclusionDate;
		result.dashboardComment = src.dashboardComment;
		result.region = Region.parse(src.region);
		result.supportCategories = (src.category || []).map( category => PlanSupportCategory.parse(category) );
		result.serviceAgreements = (src.serviceAgreements || []).map( ServiceAgreement.parse );
		result.attachments = (src.attachments || []).map( AttachedFile.parse );
		result.pace = src.pace;

		return result;
	}

	public static toJSON(src: Plan): any {
		return {
			"id": src.id,
			"userId": src.client,
			"startDate": DateUtils.toString(src.startDate),
			"endDate": DateUtils.toString(src.endDate),
			"status": PlanStatus.toPostgresEnum(src.status),
			"total": src.total,
			"budget": PlanBudget.toJSON(src.budget),
			"exclusiveBudget": PlanBudget.toJSON(src.exclusiveBudget),
			"projectedSpend": (src.projectedSpend || []).map( ProjectedSpend.toJSON ),
			"dashboardInclusionDate": src.dashboardInclusionDate,
			"dashboardComment": src.dashboardComment,
			"region": Region.toPostgresEnum(src.region),
			"category": (src.supportCategories || []).map( PlanSupportCategory.toJSON ),
			"serviceAgreements": (src.serviceAgreements || []).map( ServiceAgreement.toJSON ),
			"attachments": src.attachments || [],
			"pace": src.pace,
		};
	}

	public static clone(src: Plan): Plan {
		return new Plan(src);
	}

	public static newPlan(): Plan {
		return new Plan();
	}

}
