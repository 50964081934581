import { Plan, PlanSupportCategory, PlanSupportItem, PlanStatus } from "@classes/plans";
import { ServiceAgreement } from "@classes/serviceAgreement";
import moment from "moment";

export interface PlanError {
	text: string;
	supportCategoryNumber?: number;
	supportItemNumber?: string;
	providerName?: string;
	hidden?: boolean;
}

interface DateRange {
	"startDate": moment.Moment,
	"endDate": moment.Moment
}

namespace DateRange {
	export function fromPlan(plan: Plan): DateRange {
		return {
			"startDate": moment(plan.startDate).startOf('day'),
			"endDate": moment(plan.endDate).startOf('day')
		};
	}
}

export class PlanErrorChecker {

	private static readonly roundingErrorCutoff = 0.001;

	private hiddenError(msg: string = "Not displayed"): PlanError {
		return {
			"text": msg,
			"hidden": true
		};
	}

	private _planErrors: PlanError[] = [];
	private _categoryBudgets: number = undefined;
	private datesValid: boolean = false;

	private get categoryBudgetsTotal(): number {
		if (this._categoryBudgets === undefined) {
			this._categoryBudgets = this.calcCategoryBudgets();
		}
		return this._categoryBudgets;
	}

	private get planBudget(): number {
		const planBudget = Number(this.plan.total);
		return isNaN(planBudget) ? 0 : planBudget;
	}

	private calcCategoryBudgets(): number {
		if (!this.plan.supportCategories) {
			return 0;
		}

		const supportCategories = Array.from(this.plan.supportCategories.values());
		return supportCategories.reduce( (total: number, category: PlanSupportCategory) => {
			const categoryTotal = Number(category.total);
			return total + (isNaN(categoryTotal) ? 0 : categoryTotal);
		}, 0);
	}

	private constructor(private plan: Plan, private serviceAgreements: ServiceAgreement[], private otherPlans: Plan[]) {
		this.datesValid = [this.plan.startDate, this.plan.endDate].every(date => date && moment(date).isValid() );
	}

	private checkPlanBudget(): void {

		// Skip the visible check if we haven't yet entered plan start and end dates, or a client
		if (!this.datesValid || !this.plan.client) {
			this._planErrors.push(this.hiddenError("Missing budget value"));
			return;
		}

		if (this.planBudget < 0) {
			this._planErrors.push({
				"text": "Plan must specify a budget"
			});
		}
	}

	private checkPlanDates(): void {
		if (!this.datesValid) {
			this._planErrors.push(this.hiddenError("Missing plan dates"));
			return;
		}

		const planStartDate = moment(this.plan.startDate);
		const planEndDate = moment(this.plan.endDate);
		if (planStartDate.isAfter(planEndDate)) {
			this._planErrors.push({
				"text": "Plan end date must be after start date"
			});
		}
		if (planStartDate.isBefore(moment('2012-12-31', 'YYYY-MM-DD')) || planStartDate.isAfter(moment('2050-12-31', 'YYYY-MM-DD'))){
			this._planErrors.push({
				"text": "Plan start date is not valid, plan dates should be within range 2012-2050."
			});
		}
		if (planEndDate.isBefore(moment('2012-12-31', 'YYYY-MM-DD')) || planEndDate.isAfter(moment('2050-12-31', 'YYYY-MM-DD'))){
			this._planErrors.push({
				"text": "Plan end date is not valid, plan dates should be within range 2012-2050."
			});
		}
	}

	private checkRegion(): void {
		if (!this.datesValid) {
			this._planErrors.push(this.hiddenError("Plan must specify a region"));
			return;
		}
		if (this.plan.region === undefined) {
			this._planErrors.push({
				"text": "Plan must specify a region"
			});
		}
	}

	private checkPlanClient(): void {
		if (!this.plan.client) {
			this._planErrors.push(this.hiddenError("Client not specified"));
		}
	}

	private checkCategoryBudgets(): void {

		const supportCategories = Array.from(this.plan.supportCategories.values());
		if (supportCategories.some( category => category.total < 0)) {
			this._planErrors.push({
				"text": `Please enter a budget for all support categories in this plan`
			});
			return;
		}

		const budgetDifference = this.categoryBudgetsTotal - this.planBudget;
		const displayValue = Math.abs(budgetDifference).toFixed(2);

		if (this.isNotRoundingError(budgetDifference)) {
			if (budgetDifference > 0) {

				this._planErrors.push({
					"text": `Category budgets exceeds plan budget (excess of $${displayValue})`
				});
			}
			else if (budgetDifference < 0) {
				this._planErrors.push({
					"text": `Category budgets total is less than plan budget (difference of $${displayValue})`
				});
			}
		}
	}

	// private calcProvidersBudget(providers: ProviderToPlan[]): number {
	// 	return providers.reduce( (acc: number, cur: ProviderToPlan) => {
	// 		return acc + Number(cur.total) || 0;
	// 	}, 0);
	// }

	private checkHasSupportCategories(): void {
		if (this.plan.supportCategories.length === 0 && this.plan.status !== PlanStatus.proposed) {
			this._planErrors.push({
				"text": "There are no support categories defined for this plan"
			});
		}
	}

	private isNotRoundingError(difference: number): boolean {
		return Math.abs(difference) > PlanErrorChecker.roundingErrorCutoff;
	}

	private checkCategorySupportItems(supportItems: PlanSupportItem[], category: PlanSupportCategory): void {

		// Make sure the value we've got for the category budget is numeric
		const categoryBudget = Number(category.total);

		// Can't do anything here if there is no valid budget set
		if (isNaN(categoryBudget)) {
			return;
		}

		if (supportItems.length === 0) {
			return;
		}

		// Check each individual budget to ensure it's not more than the category budget
		supportItems.forEach( item => {
			const itemBudget = Number(item.total);
			if (!isNaN(itemBudget) && itemBudget > categoryBudget) {
				this._planErrors.push({
					"text": `Budget for support item in "${item.supportItem.name}" exceeds category total`,
					"supportItemNumber": item.supportItem.id,
					"supportCategoryNumber": category.supportCategory.id
				});
			}
		});

		// Make sure the total item bugdets don't exceed the category budget
		const itemsTotal = supportItems.map( item => Number(item.total) || 0 ).reduce( (acc, cur) => { return acc + cur; }, 0);
		if (itemsTotal > categoryBudget && this.isNotRoundingError(categoryBudget - itemsTotal)) {
			this._planErrors.push({
				"text": `Budget for support items in "${category.supportCategory.name}" exceeds category total`,
				"supportCategoryNumber": category.supportCategory.id
			});
		}
	}

	/**
	* Iterates through the service contracts on the plan, and totals the budgets
	* that have been specified for the given support category.
	*
	* This approach is used instead of the "category.budget.quarantined" value, as that property is not
	* immediately updated when the user edits a service agreement
	*/
	private calcQuarantinedTotal(categoryNumber: number): number {
		return this.serviceAgreements.reduce( (acc: number, serviceAgreement: ServiceAgreement) => {

			const category = serviceAgreement.categories.find( item => item.categoryNumber === categoryNumber );
			if (!!category) {

				// Despite declaring the category.budget.total property as a number, it's still being interpreted as a string after user input.
				// Force into numeric format here.
				const value = Number(category.budget.total);
				acc += Number.isNaN(value) ? 0 : value;
			}

			return acc;
		}, 0);
	}

	private checkSufficientBudgetForServiceAgreements(category: PlanSupportCategory): void {

		// Make sure the value we've got for the category budget is numeric
		const categoryTotal = Number(category.total);
		// const quarantineTotal = category.categoryBudget.quarantined;

		// Calculate the total amount in quarantine. Don't rely on the value provided on the category budget here,
		// because the user may have edited the value
		const quarantineTotal = this.calcQuarantinedTotal(category.supportCategory.id);


		// If no quarantined total is specified, exit here.
		if (quarantineTotal < PlanErrorChecker.roundingErrorCutoff) {
			return;
		}

		const budget = category.exclusiveBudget;
		const allocatedAmount = budget.paid + budget.pending + budget.draft + budget.unknown;
		if (quarantineTotal > categoryTotal - allocatedAmount) {
			this._planErrors.push({
				"text": `Insufficient funds to cover service agreements in category ${category.supportCategory.id}. ${category.supportCategory.name}`,
				"supportCategoryNumber": category.supportCategory.id
			});
		}
	}

	/**
	* Checks that the currently allocated funds (paid, pending, draft) doesn't exceed the overall budget
	* for a category. Ignores quarantined funds, and uses the exclusive budget (spending outside of quarantined items).
	*/
	private checkExpenditureDoesntExceedBudget(category: PlanSupportCategory): void {

		// Make sure the value we've got for the category budget is numeric
		const categoryTotal = Number(category.total);

		const budget = category.exclusiveBudget;
		if (categoryTotal < budget.paid + budget.pending + budget.draft + budget.unknown) {
			this._planErrors.push({
				"text": `Budget for ${category.supportCategory.id}. ${category.supportCategory.name} is less than current spend for this category`,
				"supportCategoryNumber": category.supportCategory.id
			});
		}
	}

	private rangeIntersects(a: DateRange, b: DateRange): boolean {
		const noIntersect = a.endDate.isBefore(b.startDate) || b.endDate.isBefore(a.startDate);
		return !noIntersect;
	}

	private checkForOverlappingPlans() {
		if (this.otherPlans.length < 1)
			return;
		
		const planRanges = this.otherPlans.filter( plan => this.plan.id !== plan.id ).map( DateRange.fromPlan );
		const thisPlanRange = DateRange.fromPlan(this.plan);

		const hasOverlap: boolean = planRanges.reduce( (acc, range) => {
			return acc || this.rangeIntersects(thisPlanRange, range);
		}, false);

		if (hasOverlap) {
			this._planErrors.push({
				"text": "Plan overlaps with another plan for this client.",
				"supportCategoryNumber": undefined
			});
		}
	}


	private run(): PlanError[] {

		// Run basic checks to ensure we have a client, budget and date range
		this.checkPlanClient();
		this.checkPlanBudget();
		this.checkPlanDates();
		this.checkForOverlappingPlans();
		this.checkRegion();

		if (this.plan.id) {

			this.checkHasSupportCategories();

			if (this.plan.supportCategories.length > 0) {

				//this.checkCategoryBudgets();

				this.plan.supportCategories.forEach( supportCategory => {
					// this.checkCategoryProviders(supportCategory);

					this.checkExpenditureDoesntExceedBudget(supportCategory);
					this.checkSufficientBudgetForServiceAgreements(supportCategory);

					// Get a list of support items for this category
					const supportItems: PlanSupportItem[] = Array.from( supportCategory.supportItems.values() );

					this.checkCategorySupportItems(supportItems, supportCategory);

					// supportItems.forEach( supportItem => {
					// 	this.checkSupportItemProviders(supportItem);
					// });
				});
			}
		}

		return this._planErrors;
	}

	public static validate(plan: Plan, serviceAgreements: ServiceAgreement[], otherPlansForClient: Plan[] = []): PlanError[] {
		// const instance = new PlanErrorChecker(planBudget, supportCategories, categoryProviders, supportItemProviders);
		const instance = new PlanErrorChecker(plan, serviceAgreements, otherPlansForClient);
		return instance.run();
	}

}
