const urlBase = "https://api.qa.nappa.ndsp.com.au";

/**
* AWS config for dev environment
*/
const awsSettings = {
	"Auth": {
		"region": "ap-southeast-2",
		"identityPoolId": "ap-southeast-2:2f553a9e-4e5a-485d-9022-3588476d3393",
		"userPoolId": "ap-southeast-2_8dFWG3t50",
		"userPoolWebClientId": "4v390df9nr0nq76hg40r3gcou8"
	},
	"API": {
		"endpoints": [{
			"name": "system",
			"endpoint": `${urlBase}/system/`,
			"region": "ap-southeast-2"
		}, {
			"name": "admin",
			"endpoint": `${urlBase}/admin/`,
			"region": "ap-southeast-2"
		}, {
			"name": "proda",
			"endpoint": `${urlBase}/proda/`,
			"region": "ap-southeast-2"
		}, {
			"name": "supportcoordinator",
			"endpoint": `${urlBase}/supportcoordinator/`,
			"region": "ap-southeast-2"
		}, {
			"name": "user",
			"endpoint": `${urlBase}/user/`,
			"region": "ap-southeast-2"
		}, {
			"name": "invoices",
			"endpoint": `${urlBase}/invoices/`,
			"region": "ap-southeast-2"
		}, {
			"name": "plans",
			"endpoint": `${urlBase}/plans/`,
			"region": "ap-southeast-2"
		}, {
			"name": "attachments",
			"endpoint": `${urlBase}/attachments/`,
			"region": "ap-southeast-2"
		}, {
			"name": "providers",
			"endpoint": `${urlBase}/providers/`,
			"region": "ap-southeast-2"
		}, {
			"name": "dashboards",
			"endpoint": `${urlBase}/dashboards/`,
			"region": "ap-southeast-2"
		}, {
			"name": "billing",
			"endpoint": `${urlBase}/billing/`,
			"region": "ap-southeast-2"
		}, {
			"name": "reports",
			"endpoint": `${urlBase}/reports/`,
			"region": "ap-southeast-2"
		}, {
			"name": "xero",
			"endpoint": `${urlBase}/xero/`,
			"region": "ap-southeast-2"
		}, {
			"name": "superuser",
			"endpoint": `${urlBase}/super/`,
			"region": "ap-southeast-2"
		}, {
			"name": "statements",
			"endpoint": `${urlBase}/statements/`,
			"region": "ap-southeast-2"
		}, {
			"name": "filenotes",
			"endpoint": `${urlBase}/filenotes/`,
			"region": "ap-southeast-2"
		}, {
			"name": "supports",
			"endpoint": `${urlBase}/supports/`,
			"region": "ap-southeast-2"
		}, {
			"name": "mobile",
			"endpoint": `${urlBase}/mobile/`,
			"region": "ap-southeast-2"
		}]
	}
};


export default awsSettings;
