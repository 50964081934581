import { Component, OnInit, Input, Output, ViewChild, TemplateRef, ElementRef, EventEmitter } from '@angular/core';
import { PrivateComponent } from "@classes/private.component";
import { LinkedProvider, Provider, ProviderService } from "@services/provider.service";
import { OverlayService } from "@services/overlay.service";
import { Table, TableColumnHeading, SortType, Alignment, StaticDataSource } from "@classes/tables";
import { AttachedFile } from "@classes/files";
import { AttachmentTargetType, AttachmentTargetUtils  } from '@classes/attachments';
import { FileManager } from "@classes/filemanager";
import { Utils } from "@classes/utils";

@Component({
	"selector": "linked-providers",
	"styleUrls": ["./linkedproviders.component.scss"],
	"templateUrl": "./linkedproviders.component.html"
})
export class LinkedProvidersComponent extends PrivateComponent implements OnInit {

	@ViewChild('addProviderTemplate')
	private linkProviderDialog: TemplateRef<any>;

	@ViewChild('newDocumentDialog')
	private documentDialogTemplate: TemplateRef<any>;

	@ViewChild('attachmentDialog')
	private attachmentDialog: TemplateRef<any>;

	@ViewChild('filectl')
	private fileInput: ElementRef;

	// Table headings for list of plans
	private readonly tableHeadings: TableColumnHeading[] = [
		{"propName": "name", "displayName": "Provider Name", "sortType": SortType.text },
		{"propName": "attachments", "displayName": "Attachments", "sortType": SortType.none, "alignment": Alignment.center},
		{"propName": "lastModified", "displayName": "Last Updated", "sortType": SortType.date },
		{"propName": undefined, "displayName": "", "sortType": SortType.none }
	];

	private _filemanager: FileManager;
	private _linkedProviders: LinkedProvider[] = [];
	private _clientId: string;
	private _newProvider: Provider = undefined;

	protected linkedProvidersTable: Table<LinkedProvider> = new Table('linkedproviders', this.tableHeadings);

	private model = {
		"providerId": undefined,
		"fileDescription": undefined
	};

	@Output()
	onSave = new EventEmitter<any>();

	constructor(private providerService: ProviderService) {
		super();
	}

	ngOnInit() {
		super.ngOnInit();
	}

	get fileManager(): FileManager {
		return this._filemanager;
	}

	@Input()
	set clientId(value: string) {
		this._clientId = value;
		this._filemanager = new FileManager(undefined, undefined);
	}

	@Input()
	set linkedProviders(value: LinkedProvider[]) {
		this._linkedProviders = value;
		this.linkedProvidersTable.sourceData = StaticDataSource.from(this._linkedProviders);
	}

	@Output()
	linkedProvidersChange = new EventEmitter<LinkedProvider[]>();

	get linkedProviders(): LinkedProvider[] {
		return this._linkedProviders;
	}

	showProvider(providerId: string): void {
		this.router.navigate([`/provider/${providerId}`]);
	}

	unlinkProvider(providerId: string): void {
		this.linkedProviders = this._linkedProviders.filter( linkedProvider => linkedProvider.provider.id !== providerId );
		this.providerService.unlinkClient(providerId, this._clientId);
		this.linkedProvidersChange.emit( this._linkedProviders );
	}

	showAddDocumentDialog(provider: LinkedProvider, attachments: AttachedFile[]): void {
		const targets = [
			AttachmentTargetUtils.target(this._clientId, AttachmentTargetType.client),
			AttachmentTargetUtils.target(provider.id, AttachmentTargetType.provider)
		];

		OverlayService.showTemplate(this.attachmentDialog, {"targets": targets, "files": attachments});
	}

	closeDialog(): void {
		OverlayService.hide();
		this._newProvider = undefined;
	}

	showProvidersDialog() {
		OverlayService.showTemplate(this.linkProviderDialog, {"$implicit": null});
	}

	private makeLinkedProvider(provider: Provider): LinkedProvider {
		return {
			"id": provider.id,
			"name": provider.name,
			"lastModified": new Date(),
			"provider": provider,
			"attachments": []
		};
	}

	async linkProviders(): Promise<void> {
		const newProvider = this._newProvider;
		const newLinkedProvider = this.makeLinkedProvider(newProvider);

		this.closeDialog();

		OverlayService.show(`Linking providers${Utils.ellipsis}`);
		try {
			await this.providerService.linkProviders(this._clientId, [newProvider]);
			this._linkedProviders.push(newLinkedProvider);
			this.linkedProvidersTable.sourceData = StaticDataSource.from(this._linkedProviders);
		}
		catch (e) {
			console.log(e);
		}
		finally {
			OverlayService.hide();
			this.linkedProvidersChange.emit( this._linkedProviders );
		}
	}

	public navigateToQuarantinedFunds() {
		this.router.navigate([`/account/${this._clientId}/quarantined-funds`]);
	}

	protected async saveAttachments($event: any) {
		// Passthrough to parent component
		this.onSave.emit($event);
	}

	public selectProvider(provider: Provider) {
		this._newProvider = provider;
	}

	public get validProviderSelected(): boolean {
		return this._newProvider !== undefined;
	}
}
