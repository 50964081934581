import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CacheSignalService {

	private subjectList: Map<string, Subject<any>> = new Map<string, Subject<any>>();
	private observableList: Map<string, Observable<any>> = new Map<string, Observable<any>>();


	constructor() {
		const items = ['User Created', 'Invoice Saved', 'Invoice Deleted', 'Settings Loaded', 'Attachments'];

		items.forEach( item => {
			const subject = new Subject<any>();
			this.subjectList.set(item, subject );
			this.observableList.set(item, subject.asObservable() );
		});
	}

	public signal(item: string, value?: any): void {
		if (this.subjectList.has(item)) {
			this.subjectList.get(item).next(value);
		}
	}

	public observable(item: string): Observable<any> {
		return this.observableList.get(item);
	}
}
