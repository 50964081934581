import { PhoneNumberUtil, PhoneNumberType, PhoneNumberFormat } from 'google-libphonenumber';

export class E164Service {
	static toE164(src: string, mobileOnly: boolean = true, countryCode: string = 'AU'): string {

		const input = `${src}`.trim();

		if (input !== '') {
			try {

				const phoneUtil = PhoneNumberUtil.getInstance();
				const phoneNumber = phoneUtil.parse(input, countryCode);

				if (mobileOnly) {
					if (phoneUtil.getNumberType(phoneNumber) !== PhoneNumberType.MOBILE) {
						return undefined;
					}
				}

				if ( phoneUtil.isValidNumber(phoneNumber) ) {
					return phoneUtil.format(phoneNumber, PhoneNumberFormat.E164);
				}
			}
			catch (e) {
				if (e.message !== "The string supplied did not seem to be a phone number") {
					console.log(e);
				}
			}
		}

		return undefined;
	}
}
