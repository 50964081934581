import { Injectable } from '@angular/core';
import { RestService, API } from './rest.service';
import { User } from '@classes/user';
import moment from 'moment';

@Injectable({ "providedIn": 'root' })
export class SupportCoordinatorService {

	private static cutoffTimeInSeconds = 5 * 60 * 60; // 5 minutes

	private cache = new Map<string, User[]>();
	private lastFetched = new Map<string, number>();

	constructor(private restService: RestService) {}

	getClients(supportCoordinatorId: string, forceReload?: boolean): Promise<User[]> {

		// Invalidate the cache if a force-reload is requested
		if (forceReload) {
			this.cache.delete(supportCoordinatorId);
		}

		// Invalidate the cache if it is too old
		if (this.lastFetched.has(supportCoordinatorId) && this.cache.has(supportCoordinatorId)) {
			const now = moment().unix();
			const prev = this.lastFetched.get(supportCoordinatorId);

			if (prev < now - SupportCoordinatorService.cutoffTimeInSeconds) {
				this.cache.delete(supportCoordinatorId);
			}
		}

		// Returned cached data if it is present
		if (this.cache.has(supportCoordinatorId)) {
			return Promise.resolve( this.cache.get(supportCoordinatorId) );
		}

		// Request data from the server
		return this.restService.get(API.supportcoordinator, `clients/${supportCoordinatorId}`).then( response => {
			if (response.clients) {
				this.cache.set(supportCoordinatorId, response.clients);
				return Promise.resolve( this.cache.get(supportCoordinatorId) );
			}
			else {
				return Promise.reject(undefined);
			}
		});
	}
}
