import { Injectable } from '@angular/core';
import { config } from '../../config';

/**
* Stores the identityId of the currently logged in user in a separate service.
* Helps avoid circular dependancy issues with user.service and rest.service.
*/
@Injectable({ providedIn: 'root' })
export class OfflineAuthService {
	private identityId: string;

	setIdentity(value: string): void {
		this.identityId = this.isOffline() ? value : undefined;
	}

	getIdentity(): string {
		return this.isOffline() ? this.identityId : undefined;
	}

	isOffline(): boolean {
		return config.stage === 'local'
	}
}
