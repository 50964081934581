import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlanAllocationService, PlanCategoryAllocation } from '@services/planallocation.service';
import { DataExchangeService } from '@services/dataexchange.service';
import { PrivateComponent } from "@classes/private.component";
import { UserType } from "@classes/user";
import { colourList } from "@classes/colours";
import { Chart, DoughnutController, Tooltip, ArcElement } from 'chart.js';
import { Plan, PlanBudget, ProjectedSpend, PlanSupportCategory, PlanStatus } from "@classes/plans";
import { ServiceAgreementCategory, ServiceAgreementItem } from "@classes/serviceAgreement";
import { RegionUtils } from "@classes/regions";
import * as tables from "@classes/tables";
import moment from 'moment';

enum ChartType {
	TotalFunding, Paid, RemainingFunding, Allocated
}

interface ChartTypeOption {
	id: ChartType;
	name: string;
	propName: string;
}

@Component({
	"selector": "plan-category-allocation-chart",
	"styleUrls": ["./allocationchart.component.scss"],
	"templateUrl": "./allocationchart.component.html"
})
export class PlanCategoryAllocationChartComponent extends PrivateComponent implements OnInit {

	private static pluginRegistered: boolean = false;

	private defaultColours: any[] = [];
	private _dataLoaded: boolean = false;

	private _clientId: string = undefined;
	private _plans: Plan[] = undefined;

	private readonly clientTableHeadings: tables.TableColumnHeading[] = [
		{"propName": "supportCategoryId", "displayName": "#", "sortType": tables.SortType.numeric },
		{"propName": "supportCategory", "displayName": "Category", "sortType": tables.SortType.text },
		// {"propName": "variance", "displayName": "Variance %", "sortType": tables.SortType.numeric, alignment: tables.Alignment.center },
		{"propName": "categoryAllocation", "displayName": "Total", "sortType": tables.SortType.numeric, alignment: tables.Alignment.right },
		// {"propName": "allocated", "displayName": "Allocated", "sortType": tables.SortType.numeric, alignment: tables.Alignment.right },
		{"propName": "delivered", "displayName": "Paid", "sortType": tables.SortType.numeric, alignment: tables.Alignment.right },
		{"propName": "clientRemaining", "displayName": "Remaining", "sortType": tables.SortType.numeric, alignment: tables.Alignment.right },
		{"propName": undefined, "displayName": "", "sortType": tables.SortType.none}
	];

	private readonly adminTableHeadings: tables.TableColumnHeading[] = [
		{"propName": "supportCategoryId", "displayName": "#", "sortType": tables.SortType.numeric },
		{"propName": "supportCategory", "displayName": "Category", "sortType": tables.SortType.text },
		{"propName": "categoryAllocation", "displayName": "Total", "sortType": tables.SortType.numeric, alignment: tables.Alignment.right },
		// {"propName": "allocated", "displayName": "Allocated", "sortType": tables.SortType.numeric, alignment: tables.Alignment.right },
		{"propName": "delivered", "displayName": "Paid", "sortType": tables.SortType.numeric, alignment: tables.Alignment.right },
		{"propName": "pending", "displayName": "Pending", "sortType": tables.SortType.numeric, alignment: tables.Alignment.right },
		{"propName": "remaining", "displayName": "Remaining", "sortType": tables.SortType.numeric, alignment: tables.Alignment.right },
		{"propName": undefined, "displayName": "", "sortType": tables.SortType.none},
		{"propName": "variance", "displayName": "Variance %", "sortType": tables.SortType.none, alignment: tables.Alignment.center }
	];

	public get isAdmin(): boolean {
		return this.user.accountType === UserType.Admin;
	}

	private get tableHeadings(): tables.TableColumnHeading[] {
		return this.isAdmin ? this.adminTableHeadings : this.clientTableHeadings;
	}

	protected allocationTable: tables.Table<PlanCategoryAllocation> = new tables.Table('user.allocationTable', this.tableHeadings);


	@Input()
	set clientId(value: string) {
		this._clientId = value;
	}

	get plans()	: Plan[] {
		return this._plans;
	}

	get hasPlan(): boolean {
		return this._dataLoaded && this._plans.length > 0;
	}

	get coreBudget(): number {
		const ps = this.model.plan.projectedSpend.find( item => item.supportCategory.length > 1);
		if (!ps) {
			return null;
		}

		return ps.budget;
	}

	get corePaid(): number {
		const coreCats = this.model.plan.supportCategories.filter( cat => cat.supportCategory.id <= 4 || cat.supportCategory.id == 16);
		return coreCats.reduce((s, a) => s + a.categoryBudget.paid + a.categoryBudget.unknown, 0);
	}
	get corePending(): number {
		const coreCats = this.model.plan.supportCategories.filter( cat => cat.supportCategory.id <= 4 || cat.supportCategory.id == 16);
		return coreCats.reduce((s, a) => s + a.categoryBudget.pending + a.categoryBudget.draft, 0);
	}
	get coreRemaining(): number {
		const coreCats = this.model.plan.supportCategories.filter( cat => cat.supportCategory.id <= 4 || cat.supportCategory.id == 16);
		return coreCats.reduce((s, a) => s +  this.remaining(a.categoryBudget), 0);
	}

	get totalBudget(): number {
		const ps = this.model.plan.projectedSpend.find( item => item.supportCategory.length === 0);
		if (!ps) {
			return null;
		}

		return ps.budget;
	}

	get totalPaid(): number {
		const totalCats = this.model.plan.supportCategories;
		return totalCats.reduce((s, a) => s + a.categoryBudget.paid + a.categoryBudget.unknown, 0);
	}
	get totalPending(): number {
		const totalCats = this.model.plan.supportCategories;
		return totalCats.reduce((s, a) => s + a.categoryBudget.pending + a.categoryBudget.draft, 0);
	}
	get totalRemaining(): number {
		const totalCats = this.model.plan.supportCategories;
		return totalCats.reduce((s, a) => s + this.remaining(a.categoryBudget), 0);
	}

	get coreProjectedSpend(): ProjectedSpend {
		return this.model.plan.projectedSpend.find( item => item.supportCategory.length > 1);
	}

	get totalProjectedSpend(): ProjectedSpend {
		return this.model.plan.projectedSpend.find( item => item.supportCategory.length === 0);
	}

	public projectedSpend(supportCategoryId: number): ProjectedSpend {
		const category = Number(supportCategoryId);

		return this.model.plan.projectedSpend.find( item => item.supportCategory.length === 1 && item.supportCategory[0] === category);
	}

	@Input()
	set plans(value: Plan[]) {

		if (!value) {
			this._plans = undefined;
			this._dataLoaded = false;
			this.model.plan = undefined;
			return;
		}

		const allowedPlanStatuses = [PlanStatus.current, PlanStatus.expired, PlanStatus.terminated, PlanStatus.deceased];
		this._plans = value.filter( plan => allowedPlanStatuses.includes(plan.status) );

		if (this._plans.length > 0) {
			this.model.plan = this._plans[0];

			const currentPlans = this._plans.filter( plan => plan.status === PlanStatus.current );
			if (currentPlans.length === 1) {
				this.model.plan = currentPlans[0];
			}

			this.loadChartData().then( nothing => {
				this.setTableData();
				this.createChart();
			});
		}

		this._dataLoaded = true;
	}

	get clientId(): string {
		return this._clientId;
	}

	get dataLoaded(): boolean {
		return this._dataLoaded;
	}

	model: any = {
		"chartType": ChartType.RemainingFunding,
		"plan": undefined
	};

	get modelPropName(): string {
		let props = {};
		props[ChartType.TotalFunding] = "categoryAllocation";
		props[ChartType.Paid] = "delivered";
		props[ChartType.RemainingFunding] = "remaining";
		return props[this.model.chartType];
	}

	readonly chartTypes: ChartTypeOption[] = [
		{"id": ChartType.TotalFunding, "name": "Total Funding", "propName": "categoryAllocation"},
		{"id": ChartType.Paid, "name": "Funds Paid", "propName": "paid"},
		{"id": ChartType.RemainingFunding, "name": "Remaining Funding", "propName": "remaining"}
		// {"id": ChartType.Allocated, "name": "Allocated Funds", "propName": "none"}
	];

	chart: any;
	data: PlanCategoryAllocation[] = [];

	constructor(private planAllocationService: PlanAllocationService, private route: ActivatedRoute, private elementRef: ElementRef) {
		super();
	}

	public planLabel(plan: Plan): string {
		const formatString = "Do MMM YYYY";
		const startDate = moment(plan.startDate).startOf('day').format(formatString);
		const endDate = moment(plan.endDate).startOf('day').format(formatString);
		const status = PlanStatus.toString(plan.status);
		const region = RegionUtils.toString(plan.region).toUpperCase();
		const pace = plan.pace ? ' (PACE)' : '';
		return `${startDate} - ${endDate} (${status}) (Region: ${region})${pace}`;
	}

	changeChartType(): void {
		this.createChart();
	}

	protected changePlan(): void {
		this._dataLoaded = false;
		this.loadChartData().then( nothing => {
			this.setTableData();
			this.createChart();
			this._dataLoaded = true;
		});
	}

	private byteToHex(value: number): string {
		let result = value.toString(16);
		return `0${result}`.slice(-2);
	}

	private loadChartData(): Promise<any> {

		if (!this.user || !this.model.plan) {
			return;
		}

		const categories: PlanSupportCategory[] = Array.from(this.model.plan.supportCategories.values());
		this.data = categories.map( (item: PlanSupportCategory) => {

			const remaining = this.remaining(item.categoryBudget);

			const result: PlanCategoryAllocation = {
				"id": `${item.supportCategory.id}`,
				"supportCategoryId": `${item.supportCategory.id}`,
				"supportCategory": `(${item.supportCategory.id}) ` + item.supportCategory.name.replace(/\s\(\d+\)$/, ''),
				"variance": item.categoryBudget.variance,
				"varianceLevel": item.categoryBudget.varianceLevel,
				"categoryAllocation": item.categoryBudget.total,
				"pending": Number(item.categoryBudget.pending) + (this.isAdmin ? item.categoryBudget.draft : 0),
				"remaining": remaining,
				"delivered": item.categoryBudget.paid + item.categoryBudget.unknown
			};
			return result;
		});

		this.defaultColours = colourList(this.data.length).map( colour => {
			return {
				"rgb": `rgb(${colour.red}, ${colour.green}, ${colour.blue})`,
				"html": `#${this.byteToHex(colour.red)}${this.byteToHex(colour.green)}${this.byteToHex(colour.blue)}`
			};
		});

		return Promise.resolve();
	}

	private setTableData(): void {
		const supportCategories: PlanSupportCategory[] = Array.from(this.model.plan.supportCategories.values());

		this.allocationTable.sourceData = tables.StaticDataSource.from( supportCategories.map( (item: PlanSupportCategory) => {

			const remaining = this.remaining(item.categoryBudget);

			return <PlanCategoryAllocation>{
				"id": `${item.supportCategory.id}`,
				"supportCategoryId": `${item.supportCategory.id}`,
				"supportCategory": item.supportCategory.name.replace(/\s\(\d+\)$/, ''),
				"categoryAllocation": item.categoryBudget.total,
				"variance": item.categoryBudget.variance,
				"varianceLevel": item.categoryBudget.varianceLevel,
				"pending": Number(item.categoryBudget.pending) + (this.isAdmin ? item.categoryBudget.draft : 0),
				"allocated": item.categoryBudget.allocated,
				"remaining": remaining,
				"delivered": item.categoryBudget.paid + item.categoryBudget.unknown
			};
		}) );

		this.allocationTable.displayData.forEach((row) => {
			 row.serviceAgreements = this.serviceAgreements(row.supportCategoryId);
		}, this);
	}

	ngOnInit() {
		super.ngOnInit();
	}

	formatCategoryName(name: string): string {
		return name;
		// return name.replace(/ \(/, '<br /> (');
	}

	getColour(idx: number): string {
		return this.defaultColours[idx].html;
	}

	private createChart() {
		Chart.register(DoughnutController, Tooltip, ArcElement);

		const htmlRef = this.elementRef.nativeElement.querySelector('#chartcanvas');
		const propName = this.modelPropName;

		if (htmlRef !== null) {

			const allocationDataset = {
				"data": this.data.map(item => {
					switch (this.model.chartType) {
						case ChartType.TotalFunding: return item.categoryAllocation;
						case ChartType.Paid: return item.delivered;
						case ChartType.RemainingFunding: return item.remaining;
						case ChartType.Allocated: return item.delivered + item.pending;
					}
					// return item[propName]
				}),
				"backgroundColor": this.data.map( (item, idx) => {
					return this.defaultColours[idx].rgb;
				}),
				"borderColor": this.data.map( () => "#ccc" ),
			};

			const totalValue = allocationDataset.data.reduce( (acc, cur) => {
				return acc += Number(cur), acc;
			}, 0);

			const formatter = new Intl.NumberFormat('en-AU', {
				"style": "currency",
				"currency": "AUD",
				"minimumFractionDigits": 2,
			});

			const legend = {
				display: false
			};

			const getOrCreateTooltip = (chart) => {
				let tooltipEl = chart.canvas.parentNode.querySelector('div');
			  
				if (!tooltipEl) {
				  tooltipEl = document.createElement('div');
				  tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
				  tooltipEl.style.borderRadius = '3px';
				  tooltipEl.style.color = 'white';
				  tooltipEl.style.opacity = 1;
				  tooltipEl.style.pointerEvents = 'none';
				  tooltipEl.style.position = 'absolute';
				  tooltipEl.style.transform = 'translate(-50%, 0)';
				  tooltipEl.style.transition = 'all .1s ease';
				  tooltipEl.style.whiteSpace = 'nowrap';
			  
				  const table = document.createElement('table');
				  table.style.margin = '0px';
			  
				  tooltipEl.appendChild(table);
				  chart.canvas.parentNode.appendChild(tooltipEl);
				}
			  
				return tooltipEl;
			  };
			  
			  const externalTooltipHandler = (context) => {
				// Tooltip Element
				const {chart, tooltip} = context;
				const tooltipEl = getOrCreateTooltip(chart);
			  
				// Hide if no tooltip
				if (tooltip.opacity === 0) {
				  tooltipEl.style.opacity = 0;
				  return;
				}
			  
				// Set Text
				if (tooltip.body) {
				  const titleLines = tooltip.title || [];
				  const bodyLines = tooltip.body.map(b => b.lines);
			  
				  const tableHead = document.createElement('thead');
			  
				  titleLines.forEach(title => {
					const tr = document.createElement('tr');
					tr.style.borderWidth = '0';
			  
					const th = document.createElement('th');
					th.style.borderWidth = '0';
					const text = document.createTextNode(title);
			  
					th.appendChild(text);
					tr.appendChild(th);
					tableHead.appendChild(tr);
				  });
			  
				  const tableBody = document.createElement('tbody');
				  bodyLines.forEach((body, i) => {
					const colors = tooltip.labelColors[i];
			  
					const span = document.createElement('span');
					span.style.background = colors.backgroundColor;
					span.style.borderColor = colors.borderColor;
					span.style.borderWidth = '2px';
					span.style.marginRight = '10px';
					span.style.height = '10px';
					span.style.width = '10px';
					span.style.display = 'inline-block';
			  
					const tr = document.createElement('tr');
					tr.style.backgroundColor = 'inherit';
					tr.style.borderWidth = '0';
			  
					const td = document.createElement('td');
					td.style.borderWidth = '0';
			  
					const text = document.createTextNode(body);
			  
					td.appendChild(span);
					td.appendChild(text);
					tr.appendChild(td);
					tableBody.appendChild(tr);
				  });
			  
				  const tableRoot = tooltipEl.querySelector('table');
			  
				  // Remove old children
				  while (tableRoot.firstChild) {
					tableRoot.firstChild.remove();
				  }
			  
				  // Add new children
				  tableRoot.appendChild(tableHead);
				  tableRoot.appendChild(tableBody);
				}
			  
				const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
			  
				// Display, position, and set styles for font
				tooltipEl.style.opacity = 1;
				tooltipEl.style.position = 'absolute';
				tooltipEl.style.left = positionX + tooltip.caretX + 'px';
				tooltipEl.style.top = positionY + tooltip.caretY + 'px';
				tooltipEl.style.font = tooltip.options.bodyFont.string;
				tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
			  };

			const tooltip = {
				// Disable the on-canvas tooltip
                enabled: false,
                external: externalTooltipHandler
			}

			const plugin = {
				id: 'donutCenterText',
				beforeDraw: (chart, args, options) => {

					if (options.elements.center) {
						const datasetCount = chart.getVisibleDatasetCount();
						const chartMeta = chart.getDatasetMeta(datasetCount-1).data.at(0);
						const innerRadius: number = Number(chartMeta.getProps(['innerRadius']));

						//Get ctx from string
						let ctx = chart.ctx;

						//Get options from the center object in options
						let centerConfig = options.elements.center;
						let fontStyle = centerConfig.fontStyle || 'Arial';
						let txt = centerConfig.text;
						let color = centerConfig.color || '#000';
						let sidePadding = centerConfig.sidePadding || 20;
						let sidePaddingCalculated = (sidePadding/100) * (innerRadius * 2)
						//Start with a base font of 30px
						ctx.font = "20px " + fontStyle;

						//Get the width of the string and also the width of the element minus 10 to give it 5px side padding
						let stringWidth = ctx.measureText(txt).width;
						let elementWidth = (innerRadius * 2) - sidePaddingCalculated;

						// Find out how much the font can grow in width.
						let widthRatio = elementWidth / stringWidth;
						let newFontSize = Math.floor(30 * widthRatio);
						let elementHeight = (innerRadius * 2);

						// Pick a new font size so it will not be larger than the height of label.
						let fontSizeToUse = Math.min(newFontSize, elementHeight);

						//Set font settings to draw it correctly.
						ctx.textAlign = 'center';
						ctx.textBaseline = 'middle';
						let centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
						let centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
						ctx.font = fontSizeToUse+"px " + fontStyle;
						ctx.fillStyle = color;

						//Draw text in center
						ctx.fillText(txt, centerX, centerY);
					}
				}
			};

			const chartOptions = {
				plugins: {
					legend: legend,
					tooltip: tooltip,
					donutCenterText: {
						elements: {
							center: {
								text: formatter.format(totalValue)
							}
						}
					}
				},
			};

			PlanCategoryAllocationChartComponent.pluginRegistered = true;

			if (this.chart) {
				this.chart.data.labels.pop();
				this.chart.data.datasets.pop();
				this.chart.data.datasets = [allocationDataset];
				this.chart.data.labels = this.data.map(item => item.supportCategory);
				this.chart.options = chartOptions;
				this.chart.update();
			}
			else {
				this.chart = new Chart(htmlRef, {
					type: "doughnut",
					data: {
						labels: this.data.map(item => item.supportCategory),
						datasets: [allocationDataset]
					},
					options: chartOptions,
					plugins: [plugin]
				});
			}

		}
	}

	showCategoryTransactions(category: PlanCategoryAllocation) {
		const supportCategoryId = category.supportCategoryId;
		DataExchangeService.set(category, `supportcategory.${this.clientId}`);
		let args = ["/transactions"];

		if (this.clientId !== this.user.id) {
			args.push(this.clientId);
		}

		args.push(this.model.plan.id);
		args.push(supportCategoryId);

		this.router.navigate(args);
	}

	public remaining(budget: PlanBudget): number {
		return this.isAdmin
           ? budget.total - budget.paid - budget.pending - budget.draft - budget.unknown
           : budget.total - budget.paid - budget.unknown;

	}

	public supportItems(category: ServiceAgreementCategory): ServiceAgreementItem[] {
		if (!category) {
			return [];
		}

		return (!category.items || category.items.length === 0) ? [] : category.items;
	}

	public serviceAgreements(categoryNumber: number): any[] {
		if (!this.model.plan || !this.model.plan.serviceAgreements) {
			return [];
		}

		categoryNumber = Number(categoryNumber);

		return this.model.plan.serviceAgreements.map( serviceAgreement => {
			const category = serviceAgreement.categories.find( cat => cat.categoryNumber === categoryNumber );
			if (category) {
				return {
					"startDate": serviceAgreement.dateFrom,
					"endDate": serviceAgreement.dateTo,
					"provider": serviceAgreement.provider,
					"budget": category.budget,
					"category": category
				};
			}
			else {
				return undefined;
			}
		}).filter( x => !!x )
	}
}
